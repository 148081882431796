import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import { Images } from "../../../../themes";
import { CardLoader, ProfileImage } from "../../../../components";
import { useCustomDispatch } from "../../../../helpers/useCustomDispatch";
import { getCompanyAdminsListRequest } from "../../../../redux/slicers/company";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { setUserDetailDrawer } from "../../../../redux/slicers/general";
import "./styles.scss";

function EnterpriseAdmin() {
  // STATES
  const [selectedAdmin, setSelectedAdmin] = useState(0);

  //CONST VALS
  const dispatch = useDispatch();

  // REDUX DATA
  const { companyAdmins } = useSelector((state) => state.company);

  // DISPATCH CALLS
  const [getCompanyAdmins, adminsLoader] = useCustomDispatch(
    getCompanyAdminsListRequest
  );

  // HOOKS
  useEffect(() => {
    getCompanyAdmins();
  }, []);

  // HANDLERS
  const selectAdminHandler = (index) => {
    setSelectedAdmin(index);
  };

  // CUSTOM COMPONENT

  const detailBox = (label, val, isPhone, isWeb, col = 7, key) => {
    const value = (
      <span>{isPhone ? <PhoneInput value={val} disabled /> : val}</span>
    );
    return (
      val && (
        <Col xs={12} lg={col} key={key}>
          <div className="detail-box">
            <h5>{label}</h5>
            {isWeb ? (
              <a href={val} target="_blank">
                {value}
              </a>
            ) : (
              value
            )}
          </div>
        </Col>
      )
    );
  };

  return (
    <section className="enterprise-admin-management">
      <div className="title-wrapper">
        <h3>Enterpise Admin</h3>
        <p>View All Admins In Detail</p>
      </div>
      <Row className="enterprise-detail-wrapper" gutter={[16, 16]}>
        <Col xs={24} lg={24} xl={7}>
          <div className="admins-list card">
            {adminsLoader ? (
              <CardLoader height={400} size={20} />
            ) : (
              <ul>
                {companyAdmins.map((item, index) => (
                  <li
                    className={`admin-box ${
                      index === selectedAdmin ? "active" : ""
                    }`}
                    onClick={() => {
                      selectAdminHandler(index);
                    }}
                    key={index}
                  >
                    <ProfileImage
                      username={item.username}
                      color={item.profilebg}
                      image={item.profilePic}
                      isBlocked={!item?.isActive}
                      size={18}
                      onClick={() => {
                        dispatch(
                          setUserDetailDrawer({
                            visibility: true,
                            id: item.id,
                          })
                        );
                      }}
                    />
                    <div className="detail">
                      <h4>{item.username}</h4>
                      <p>Enterprise Admin</p>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </Col>
        <Col xs={24} lg={24} xl={17}>
          <div className="admin-detail card">
            {adminsLoader ? (
              <CardLoader height={450} />
            ) : (
              <>
                <h3>Personal Information</h3>
                <div className="profile-detail">
                  <div className="profile-box">
                    <ProfileImage
                      classname="profile-pic"
                      image={companyAdmins?.[selectedAdmin]?.profilePic}
                      color={companyAdmins?.[selectedAdmin]?.profilebg}
                      username={companyAdmins?.[selectedAdmin]?.username}
                      size={28}
                      isBlocked={!companyAdmins?.[selectedAdmin]?.isActive}
                    />
                    <div className="social-wrapper">
                      {companyAdmins[selectedAdmin]?.facebook && (
                        <a
                          href={companyAdmins[selectedAdmin]?.facebook}
                          target="_blank"
                        >
                          <Images.Facebook />
                        </a>
                      )}
                      {companyAdmins[selectedAdmin]?.twitter && (
                        <a
                          href={companyAdmins[selectedAdmin]?.twitter}
                          target="_blank"
                        >
                          <Images.Twitter />
                        </a>
                      )}
                      {companyAdmins[selectedAdmin]?.linkedIn && (
                        <a
                          href={companyAdmins[selectedAdmin]?.linkedIn}
                          target="_blank"
                        >
                          <Images.Linkedin />
                        </a>
                      )}
                    </div>
                    {!companyAdmins?.[selectedAdmin]?.isActive && (
                      <p className="deactivated">Deactivated</p>
                    )}
                  </div>
                  <Row className="detail-wrapper" gutter={[10, 25]}>
                    {detailBox("Name", companyAdmins[selectedAdmin]?.username)}
                    {detailBox(
                      "Phone",
                      companyAdmins[selectedAdmin]?.phone,
                      true
                    )}
                    {detailBox("Email", companyAdmins[selectedAdmin]?.email)}
                    {detailBox(
                      "Work Phone",
                      companyAdmins[selectedAdmin]?.workPhone,
                      true
                    )}
                    {detailBox(
                      "Job Title",
                      companyAdmins[selectedAdmin]?.jobTitle
                    )}
                  </Row>
                </div>
                <h3 className="mt-30">Company Information</h3>
                <div className="profile-detail">
                  <div className="profile-box">
                    <ProfileImage
                      classname="profile-pic"
                      image={companyAdmins[selectedAdmin]?.company?.logo}
                      color={companyAdmins[selectedAdmin]?.company?.profilebg}
                      username={companyAdmins[selectedAdmin]?.company?.name}
                      size={28}
                    />
                    <div className="social-wrapper">
                      {companyAdmins[selectedAdmin]?.company?.facebook && (
                        <a
                          href={companyAdmins[selectedAdmin]?.company?.facebook}
                          target="_blank"
                        >
                          <Images.Facebook />
                        </a>
                      )}
                      {companyAdmins[selectedAdmin]?.company?.twitter && (
                        <a
                          href={companyAdmins[selectedAdmin]?.company?.twitter}
                          target="_blank"
                        >
                          <Images.Twitter />
                        </a>
                      )}
                      {companyAdmins[selectedAdmin]?.company?.linkedIn && (
                        <a
                          href={companyAdmins[selectedAdmin]?.company?.linkedIn}
                          target="_blank"
                        >
                          <Images.Linkedin />
                        </a>
                      )}
                    </div>
                  </div>
                  <Row className="detail-wrapper" gutter={[10, 25]}>
                    {detailBox(
                      "Name",
                      companyAdmins[selectedAdmin]?.company?.name
                    )}
                    {detailBox(
                      "Website",
                      companyAdmins[selectedAdmin]?.company?.website,
                      false,
                      true
                    )}
                    {detailBox(
                      "Phone",
                      companyAdmins[selectedAdmin]?.company?.phone,
                      true
                    )}
                    {companyAdmins[selectedAdmin]?.company?.addresses?.map(
                      (item, index) =>
                        detailBox(
                          item?.branchName,
                          item?.completeAddress,
                          false,
                          false,
                          10,
                          index
                        )
                    )}
                    {}
                  </Row>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
    </section>
  );
}

export default EnterpriseAdmin;
