import React, { useEffect } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Input, Modal, Select } from "antd";
import { useCustomDispatch } from "../../helpers/useCustomDispatch";
import { updateTeamMemberRequest } from "../../redux/slicers/company";
import { inputFieldRule, toastAlert } from "../../services/utils";
import ButtonComponent from "../ButtonComponent";
import "./styles.scss";
import { useSelector } from "react-redux";

const UpdateTeamMemberModal = ({
  handleClose,
  modalPreview,
  addresses,
  selectedData,
  handleSuccess = () => {},
}) => {
  // CUSTOM DISPATCH
  const [updateTeamMember, isLoading] = useCustomDispatch(
    updateTeamMemberRequest
  );

  // CONST VALS
  const [form] = Form.useForm();
  const isBlocked = selectedData?.role === "Member" && selectedData?.isBlocked;
  const { user_id: id } = useSelector((state) => state.user.data);
  const isAdminLoggedIn = selectedData?.id === id;

  // HOOKS
  useEffect(() => {
    if (selectedData) {
      form.setFieldsValue({
        name: selectedData.username,
        email: selectedData.email,
        status: selectedData.active,
        branch: selectedData.branch,
        isAdmin: selectedData.isAdmin,
      });
    }
  }, [selectedData, modalPreview]);

  // HANDLERS
  const closeHandler = () => {
    handleClose();
    form.resetFields();
  };

  const handleFormFinish = (values) => {
    const payload = {
      addressId: values.branch,
      isActive: isAdminLoggedIn ? true : values.status === 1 ? true : false,
      isAdmin: values.isAdmin,
    };
    updateTeamMember({
      payload,
      pathParams: selectedData.id,
      logic(response) {
        toastAlert(response.message);
        handleSuccess();
        handleClose();
      },
    });
  };

  return (
    <Modal
      destroyOnClose
      visible={modalPreview}
      centered
      footer={null}
      width={490}
      getContainer={false}
      className="checklist-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>Update Member</h3>
            <p>Fill The Details Below</p>
          </div>
          <Form form={form} className="form" onFinish={handleFormFinish}>
            <Form.Item name={"name"}>
              <Input name={"Name"} placeholder={"Name"} disabled />
            </Form.Item>
            <Form.Item name={"email"}>
              <Input
                type={"email"}
                placeholder={"john.smith@domain.com"}
                disabled
              />
            </Form.Item>
            <Form.Item
              name="branch"
              rules={inputFieldRule({ name: "Branch", isWhiteSpace: false })}
            >
              <Select
                placeholder="Select office"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {addresses?.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {!isAdminLoggedIn && (
              <>
                <Form.Item
                  name={"status"}
                  rules={inputFieldRule({
                    name: "Status",
                    isWhiteSpace: false,
                  })}
                >
                  <Select>
                    <Select.Option value={1}>Active</Select.Option>
                    <Select.Option value={2}>Inactive</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name={"isAdmin"}
                  rules={inputFieldRule({ name: "Users", isWhiteSpace: false })}
                  style={{ opacity: `${isBlocked ? "0.5" : "1"}` }}
                >
                  <Select placeholder="Select Users" disabled={isBlocked}>
                    <Select.Option value={false}>
                      Enterprise's Users
                    </Select.Option>
                    <Select.Option value={true}>
                      Enterprise's Admin
                    </Select.Option>
                  </Select>
                </Form.Item>
              </>
            )}

            <div className="create-button">
              <Form.Item>
                <ButtonComponent
                  className="login-btn"
                  isLoading={isLoading}
                  text={"Update"}
                  style={{ marginTop: "0px" }}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateTeamMemberModal;
