import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import { Images } from "../../../themes";
import { useLocation, useNavigate } from "react-router-dom";
import { DASHBOARD_ROUTE } from "../../../constants";
import {
  TransactionDetailCard,
  DashboardInviteModal,
} from "../../../components";
import {
  Transactions,
  ActivityLogs,
  Milestones,
  TeamMembers,
} from "./partials";
import { useCustomDispatch } from "../../../helpers/useCustomDispatch";
import { getDashboardStatsRequest } from "../../../redux/slicers/general";
import { formatValue } from "../../../services/utils";
import "./styles.scss";

const TRANSACTIONS = [
  {
    title: "Total Transactions",
    icon: Images.TotalTransaction,
    numbers: "total",
  },
  {
    title: "Pending Transactions",
    icon: Images.PendingTransaction,
    numbers: "pending",
  },
  {
    title: "Completed Transactions",
    icon: Images.TotalTransaction,
    numbers: "complete",
    isCompleted: true,
  },
];

function Dashboard() {
  // CONST VALS
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isTransactonInvite = pathname.includes("invite");

  // STATES
  const [isInvitationAccepted, setInvitationAccepted] = useState(false);
  const [inviteModalPreview, setInviteModalPreview] =
    useState(isTransactonInvite);

  // REDUX DATA
  const { isEnterpriseUser, name } = useSelector((state) => state.user.data);
  const { dashboardStatistics } = useSelector((state) => state.general);

  // CUSTOM DISPATCH
  const [getDashboardStats] = useCustomDispatch(getDashboardStatsRequest);

  // HOOKS
  useEffect(() => {
    getDashboardStats();
  }, []);

  useEffect(() => {
    setInviteModalPreview(isTransactonInvite);
  }, [isTransactonInvite]);

  // HANDLERS
  const handleInviteClose = () => {
    setInviteModalPreview(false);
    // navigate(DASHBOARD_ROUTE, { replace: true });
  };
  const invitationAcceptHandler = () => {
    setInvitationAccepted(!isInvitationAccepted);
  };
  return (
    <section style={{ padding: "0 20px 20px 20px" }}>
      <div className="top-heading">
        <p>Create and manage your transactions below</p>
        <h3>Hello, {name}</h3>
      </div>
      <div className="transaction-details">
        {TRANSACTIONS.map((a, i) => (
          <TransactionDetailCard
            key={i}
            completedTransactions={a.isCompleted}
            title={a.title}
            Icons={a.icon}
            number={formatValue(dashboardStatistics?.[a.numbers])}
          />
        ))}
      </div>
      <Transactions isDataUpdated={isInvitationAccepted} />
      <Row gutter={[16, 0]} className="dashboard-data-col">
        <Col xs={24} md={isEnterpriseUser ? 6 : 12}>
          <ActivityLogs />
        </Col>
        {isEnterpriseUser && (
          <Col xs={24} md={6}>
            <TeamMembers />
          </Col>
        )}
        <Col xs={24} md={12}>
          <Milestones />
        </Col>
      </Row>
      <DashboardInviteModal
        invitationAcceptHandler={invitationAcceptHandler}
        open={inviteModalPreview}
        handleClose={handleInviteClose}
      />
    </section>
  );
}

export default Dashboard;
