import React from "react";
import { Images } from "../../themes";
import "./styles.scss";

function EmptyBox({
  height = 300,
  imgwidth = 100,
  size = 16,
  text = "No Data Found",
}) {
  return (
    <div className="card-empty-box" style={{ height: height }}>
      <img src={Images.NoData} style={{ width: imgwidth }} alt="" />
      <h4 style={{ fontSize: size }}>{text}</h4>
    </div>
  );
}

export default EmptyBox;
