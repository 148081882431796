import React from "react";
import { useDispatch } from "react-redux";
import { CardLoader, ProfileImage } from "../../../../components";
import { setCompanyDetailDrawer } from "../../../../redux/slicers/general";
import { Images } from "../../../../themes";
import { Col, Row } from "antd";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { ACCOUNT_ROUTE } from "../../../../constants";

function EnterpriseSettingProfile({ isLoading, companyProfile }) {
  // CONST VALS
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // HANDLERS
  const navigateToProfile = () => {
    if (!isLoading) {
      navigate(ACCOUNT_ROUTE);
    }
  };
  // CUSTOM COMPONENT
  const detailBox = (label, val, isPhone, isWeb, col = 12) => {
    const value = (
      <span>{isPhone ? <PhoneInput value={val} disabled /> : val}</span>
    );
    return (
      val && (
        <Col span={col}>
          <div className="detail-box">
            <h5>{label}</h5>
            {isWeb ? (
              <a href={val} target="_blank">
                {value}
              </a>
            ) : (
              value
            )}
          </div>
        </Col>
      )
    );
  };

  return (
    <div className="card company-info c-p" onClick={navigateToProfile}>
      {isLoading ? (
        <CardLoader height={390} size={20} />
      ) : (
        <>
          <div className="company-logo-wrapper">
            <ProfileImage
              classname="company-logo"
              size={10}
              image={companyProfile.logo}
              onClick={() =>
                dispatch(
                  setCompanyDetailDrawer({
                    visibility: true,
                    id: companyProfile.id,
                  })
                )
              }
            />
            <h3>{companyProfile?.name}</h3>
          </div>
          <Row gutter={[10, 10]} style={{ width: "100%" }}>
            {detailBox("Website", companyProfile?.website, false, true)}
            {detailBox("Phone", companyProfile?.phone, true)}
            {detailBox(
              companyProfile?.address?.branchName,
              companyProfile?.address?.completeAddress,
              false,
              false,
              24
            )}
          </Row>
          <div className="social-icons-wrapper">
            {companyProfile?.facebook && (
              <a
                href={companyProfile?.facebook}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
              >
                <Images.Facebook />
              </a>
            )}
            {companyProfile?.twitter && (
              <a
                href={companyProfile?.twitter}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
              >
                <Images.Twitter />
              </a>
            )}
            {companyProfile?.linkedIn && (
              <a
                href={companyProfile?.linkedIn}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
              >
                <Images.Linkedin />
              </a>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default EnterpriseSettingProfile;
