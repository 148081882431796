import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select, DatePicker } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import {
  getAllRoomsMembersRequest,
  getTransactionRoomsRequest,
} from "../../../../../../../redux/slicers/transactiondetail";
import {
  createMileStoneRequest,
  updateMileStoneRequest,
} from "../../../../../../../redux/slicers/transactionmilestone";
import { ButtonComponent, ProfileImage } from "../../../../../../../components";
import {
  getFormattedDateTime,
  getLetterFromName,
  inputFieldRule,
  toastAlert,
} from "../../../../../../../services/utils";
import {
  DATA_PER_PAGE_LIMIT,
  DATE_FORMAT2,
  DATE_FORMAT3,
} from "../../../../../../../constants";
import moment from "moment";
import "./styles.scss";
import { setUserDetailDrawer } from "../../../../../../../redux/slicers/general";

const CreateMilestoneModal = ({
  transactionslug,
  data,
  isUpdate,
  handleClose,
  handleSucess = () => {},
  preview,
}) => {
  // STATES
  const [isChanged, setChanged] = useState(false);
  const [open, setOpen] = useState("");
  const [visibilityOpen, setVisibilityOpen] = useState("");
  const [selectedParties, setselectedParties] = useState([]);
  const [selectedRooms, setSelectedRooms] = useState([]);

  // CUSTOM DISPATCH
  const [getRoomMembers, isMembersLoading] = useCustomDispatch(
    getAllRoomsMembersRequest
  );
  const [getTransactionRooms, isRoomsLoading] = useCustomDispatch(
    getTransactionRoomsRequest
  );
  const [createMileStone, isCreateLoading] = useCustomDispatch(
    createMileStoneRequest
  );
  const [updateMileStone, isUpdateLoading] = useCustomDispatch(
    updateMileStoneRequest
  );
  // REDUX DATA
  const { rooms, allRoomsMembers } = useSelector(
    (state) => state.transactiondetail
  );
  // CONST VALS
  const [form] = Form.useForm();
  const isLoading = isCreateLoading || isUpdateLoading;
  const isDisabled = isUpdate && !isChanged;
  const dispatch = useDispatch();

  // HELPERS
  const getRoomMembersHelper = (vals) => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: 0,
      room: vals.toString(),
      isActive: true,
    };
    getRoomMembers({
      queryParams,
    });
  };

  const requestHelper = (request, payload, pathParams) => {
    request({
      payload,
      pathParams,
      logic({ data, message }) {
        toastAlert(message);
        closeHandler();
        handleSucess(isUpdate ? null : data?.slug);
      },
    });
  };

  // HOOKS
  useEffect(() => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: 0,
    };
    if (preview && !isUpdate) {
      getTransactionRooms({
        pathParams: transactionslug,
        queryParams,
      });
    }
    if (data && isUpdate) {
      form.setFieldsValue({
        name: data.name,
        endDate: data.endDate ? moment(data.endDate) : null,
        notes: data.notes,
      });
    }
  }, [preview, isUpdate, data]);

  // HANDLERS
  const handleSubmit = (values) => {
    const payload = { ...values };
    // payload["responsibleParties"] = values.responsibleParties ?? [];

    payload["endDate"] = getFormattedDateTime(payload.endDate, DATE_FORMAT3);
    if (isUpdate) {
      requestHelper(updateMileStone, payload, data.slug);
    } else {
      payload["transactionSlug"] = transactionslug;
      requestHelper(createMileStone, payload);
    }
  };

  const handleRemoveMember = (isRoom, id) => {
    if (isRoom) {
      const tempdata = [...selectedRooms].filter((x) => x !== id);
      setSelectedRooms(tempdata);
      form.setFieldsValue({
        visibility: tempdata,
      });
    } else {
      const tempdata = [...selectedParties].filter((x) => x !== id);
      setselectedParties(tempdata);
      form.setFieldsValue({
        responsibleParties: tempdata,
      });
    }
  };

  const closeHandler = () => {
    form.resetFields();
    setselectedParties([]);
    setSelectedRooms([]);
    handleClose();
  };

  // CUSTOM COMPONENT
  const partyMemberItem = (data, index) => {
    const userdetail = allRoomsMembers.find((x) => x.id === data);
    return (
      <div className="member-box" key={index}>
        <ProfileImage
          username={userdetail.username}
          color={userdetail.profilebg}
          image={userdetail.image}
          isBlocked={!userdetail?.isActive}
          onClick={() => {
            dispatch(
              setUserDetailDrawer({
                visibility: true,
                id: userdetail.userid ?? userdetail.id,
              })
            );
          }}
          size={10}
        />
        <b>{userdetail.username}</b>
        <span
          className="close-btn"
          onClick={() => {
            handleRemoveMember(false, data);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>
    );
  };
  const selectedRoomItem = (data, index) => {
    const roomdetail = rooms.find((x) => x.id === data);
    return (
      <div className="member-box" key={index}>
        <ProfileImage
          username={roomdetail.name}
          color={roomdetail.bgColor}
          size={10}
        />
        <b>{roomdetail.name}</b>
        <span
          className="close-btn"
          onClick={() => {
            handleRemoveMember(true, data);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>
    );
  };
  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={522}
      className="create-milestone"
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        className="modal-wrapper"
        onFieldsChange={() => {
          setChanged(true);
        }}
      >
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>{isUpdate ? "Update" : "Create"} Milestones</h3>
            <p>{isUpdate ? "Edit" : "Add"} Details Below</p>
          </div>
          <Form.Item
            name="name"
            rules={inputFieldRule({
              name: "Milestone name",
              isMax: true,
              max: 120,
            })}
          >
            <Input
              placeholder="Milestone Name"
              className="form-select"
              autoFocus
            />
          </Form.Item>
          <Form.Item
            name="endDate"
            rules={inputFieldRule({
              name: "End date",
              isWhiteSpace: false,
              isRequired: false,
            })}
          >
            <DatePicker
              placeholder="End Date"
              className="form-select"
              format={DATE_FORMAT2}
              disabledDate={(current) =>
                current.isBefore(moment().subtract(1, "day"))
              }
            />
          </Form.Item>
          {!isUpdate && (
            <>
              <Form.Item
                name="visibility"
                rules={inputFieldRule({
                  name: "Visibility",
                  isWhiteSpace: false,
                })}
              >
                <Select
                  mode="multiple"
                  loading={isRoomsLoading}
                  placeholder="Visibility"
                  className="form-select"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(val) => {
                    form.resetFields(["responsibleParties"]);
                    setselectedParties([]);
                    getRoomMembersHelper(val);
                    setVisibilityOpen(false);
                    setSelectedRooms(val);
                  }}
                  optionLabelProp="label"
                  maxTagCount={4}
                  maxTagTextLength={10}
                  open={visibilityOpen}
                  onClick={() => {
                    setOpen(false);
                    setVisibilityOpen(!visibilityOpen);
                  }}
                  onBlur={() => setVisibilityOpen(false)}
                >
                  {rooms.map((item, index) => {
                    const isEven = index % 2 == 0;
                    return (
                      <Select.Option
                        value={item.id}
                        key={index}
                        label={item.name}
                      >
                        <div className="visibility-options">
                          <span
                            role="img"
                            style={{
                              backgroundColor: item.bgColor,
                            }}
                          >
                            {getLetterFromName(item.name)}
                          </span>
                          <p>{item.name}</p>
                        </div>
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {selectedRooms.length >= 1 && (
                <div className="selected-partymembers">
                  {selectedRooms.map((item, index) =>
                    selectedRoomItem(item, index)
                  )}
                </div>
              )}
              <Form.Item
                name="responsibleParties"
                rules={inputFieldRule({
                  name: "Responsible parties",
                  isWhiteSpace: false,
                  isRequired: false,
                })}
              >
                <Select
                  loading={isMembersLoading}
                  mode="multiple"
                  placeholder="Responsible Person/Party(ies)"
                  className="form-select"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  open={open}
                  onClick={() => {
                    setOpen(!open);
                    setVisibilityOpen(false);
                  }}
                  onFocus={() => setOpen(true)}
                  onBlur={() => setOpen(false)}
                  onSearch={() => setOpen(true)}
                  onChange={(e) => {
                    setOpen(false);
                    setselectedParties(e);
                  }}
                  maxTagCount={4}
                  maxTagTextLength={10}
                  optionLabelProp="label"
                >
                  {allRoomsMembers.map((item, index) => (
                    <Select.Option
                      value={item.id}
                      key={index}
                      label={item.username}
                    >
                      <div className="invite-modal-user-autocomplete-options">
                        <ProfileImage
                          color={item.profilebg}
                          image={item.image}
                          username={item.username}
                          isBlocked={!item?.isActive}
                          size={10}
                          classname="thumb"
                        />
                        <div className="option-user-info">
                          <h3>{item.username}</h3>
                          <p>{item.email}</p>
                        </div>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {selectedParties.length >= 1 && (
                <div className="selected-partymembers">
                  {selectedParties.map((item, index) =>
                    partyMemberItem(item, index)
                  )}
                </div>
              )}
            </>
          )}
          <Form.Item
            name="notes"
            rules={inputFieldRule({
              name: "Notes",
              isMax: true,
              max: 1000,
            })}
          >
            <Input.TextArea
              placeholder="Add Notes"
              className="form-select textarea"
            />
          </Form.Item>
        </div>
        <div className="modal-footer">
          <ButtonComponent
            disabled={isDisabled}
            text={isUpdate ? "Update Milestone" : "Create Milestone +"}
            isLoading={isLoading}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default CreateMilestoneModal;
