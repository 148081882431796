import React, { useState, useEffect, useMemo } from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import { DATA_PER_PAGE_LIMIT } from "../../../../../constants";
import { useCustomDispatch } from "../../../../../helpers/useCustomDispatch";
import {
  getTransactionsAdvisoryRequest,
  getTransactionTypeRequest,
  getTransactionsPartiesRequest,
} from "../../../../../redux/slicers/transactions";
import { getCompaniesListRequest } from "../../../../../redux/slicers/company";
import {
  ButtonComponent,
  CardLoader,
  TransactionTypeWarningModal,
} from "../../../../../components";
import { Images } from "../../../../../themes";
import DetailFields from "./fields/index";
import { ROLE_TYPES } from "../../../../../constants";
import { getAutoCompleteValue } from "../../../../../services/utils";

function Details({
  handleBack,
  handleFormFinish,
  style,
  isEdit,
  transactiondata,
  isLoading,
  dataLoading,
  setIsTypeChange,
}) {
  // CONSTANT VALS
  const [form] = Form.useForm();

  // REDUX DATA
  const { companiesList } = useSelector((state) => state.company);
  const { types, parties, advisories } = useSelector(
    (state) => state.transactions
  );
  const { data } = useSelector((state) => state.user);

  const companies = useMemo(
    () => companiesList?.filter((obj) => obj.name !== data?.companyName),
    [companiesList]
  );

  // STATES
  const [typeOffset, settypeOffset] = useState(0);
  const [typeTotal, settypeTotal] = useState(0);
  const [advisoryOffset, setadvisoryOffset] = useState(0);
  const [advisoryTotal, setadvisoryTotal] = useState(0);
  const [partyOffset, setpartyOffset] = useState(0);
  const [partyTotal, setpartyTotal] = useState(0);
  const [companySearchText, setCompanySearchText] = useState("");
  const [selectedRole, setselectedRole] = useState(null);
  const [savClose, setSave] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [warningModalPreview, setWarningModalPreview] = useState(false);
  const [isWarningShown, setWarningShown] = useState(false);
  const [isQuestion, setIsQuestion] = useState(false);

  // CUSTOM DISPATCH
  const [getCompanies, isCompanyLoading] = useCustomDispatch(
    getCompaniesListRequest
  );

  const [getTypes, isTypesLoading] = useCustomDispatch(
    getTransactionTypeRequest
  );

  const [getAdvisory, isAdvisoryLoading] = useCustomDispatch(
    getTransactionsAdvisoryRequest
  );

  const [getParties, isPartiesLoading] = useCustomDispatch(
    getTransactionsPartiesRequest
  );

  // HELPERS
  const getCompaniesHelper = (isSearched) => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: 0,
    };
    if (isSearched) queryParams["search"] = companySearchText;
    getCompanies({ queryParams });
  };

  const getTransactiontypesHelper = () => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: typeOffset,
      status: true,
    };
    if (isEdit) {
      queryParams["transactiontypeId"] = transactiondata.typeid;
      queryParams["transactionSlug"] = isEdit;
    }
    getTypes({
      queryParams,
      logic({ total }) {
        settypeTotal(total);
      },
    });
  };

  const getAdvisoriesHelper = () => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: advisoryOffset,
      status: true,
    };
    if (isEdit) {
      queryParams["transactionSlug"] = isEdit;
    }
    getAdvisory({
      queryParams,
      logic({ total }) {
        setadvisoryTotal(total);
      },
    });
  };

  const getPartiesHelper = () => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: partyOffset,
      status: true,
    };
    if (isEdit) {
      queryParams["transactionSlug"] = isEdit;
    }
    getParties({
      queryParams,
      logic({ total }) {
        setpartyTotal(total);
      },
    });
  };

  // HOOKS
  useEffect(() => {
    getCompaniesHelper(companySearchText !== "");
  }, [companySearchText]);

  useEffect(() => {
    getTransactiontypesHelper();
  }, [typeOffset, transactiondata]);

  useEffect(() => {
    getAdvisoriesHelper();
  }, [advisoryOffset]);

  useEffect(() => {
    getPartiesHelper();
  }, [partyOffset]);

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        name: transactiondata.name,
        transactionTypeId: transactiondata.type,
      });
    }
  }, [transactiondata]);

  useEffect(() => {
    setIsQuestion(checkTransactionTypeQuestion());
  }, [types]);

  // HANDLERS

  const setselectedRoleHandler = (val) => {
    setselectedRole(val);
    form.setFieldsValue({
      role: null,
      company: null,
      companyRole: null,
      firstname: null,
      lastname: null,
      email: null,
    });
  };

  const setTypeOffsetHandler = () => {
    settypeOffset(typeOffset + DATA_PER_PAGE_LIMIT);
  };
  const setadvisoryOffsetHandler = () => {
    setadvisoryOffset(advisoryOffset + DATA_PER_PAGE_LIMIT);
  };
  const setpartyOffsetHandler = () => {
    setpartyOffset(partyOffset + DATA_PER_PAGE_LIMIT);
  };

  const setCompanySearchTextHandler = (text) => {
    setCompanySearchText(text);
  };

  const handleClose = () => {
    setWarningModalPreview(false);
  };

  const handleTransactionTypeWarning = () => {
    setWarningShown(true);
    setWarningModalPreview(false);
    form.submit();
  };
  const handleSubmit = () => {
    const values = form.getFieldsValue();
    values.isAdvisor = values.isAdvisor === ROLE_TYPES[1];

    // TRANSACTION TYPE
    values.transactionTypeId = getAutoCompleteValue({
      value: values.transactionTypeId,
      isInt: true,
    });
    const isOldType = types.find(
      (x) => x.title === values.transactionTypeId
    )?.id;
    if (isOldType) {
      values.transactionTypeId = isOldType;
    }
    // COMPANY
    values.company = getAutoCompleteValue({
      value: values.company,
      key: "value",
    });

    handleFormFinish(values, savClose);
  };

  const handleSetFieldValue = (name, value) => {
    form.setFieldValue(name, value);
    setIsQuestion(checkTransactionTypeQuestion());
  };

  const checkTransactionTypeQuestion = () => {
    const selectedType = form.getFieldValue("transactionTypeId");
    const type = types?.find(
      (item) => (item.id === selectedType) | (item.title === selectedType)
    );

    if (type?.Question && transactiondata?.answers?.length >= 1) {
      return true;
    }

    return false;
  };

  const isBlocked = isEdit && !isWarningShown;

  return (
    <span style={style}>
      <div className="top-heading-wrapper">
        <div className="top-heading">
          <div className="back-btn-wrapper" onClick={handleBack}>
            <Images.BackArrow style={{ height: "15px" }} /> <p>Back</p>
          </div>
          <h3>{isEdit ? "Edit" : "Create"} Transaction</h3>
        </div>
        <div className="submit-btn">
          {isEdit && (
            <ButtonComponent
              text="Save & Close"
              className="continue-btn mr-10"
              onClick={() => {
                setSave(true);
                form.submit();
              }}
              disabled={saveDisabled}
              isLoading={savClose && isLoading}
            />
          )}
          <ButtonComponent
            text="Continue"
            isLoading={!savClose && isLoading}
            className="continue-btn"
            onClick={() =>
              isBlocked && isQuestion
                ? setWarningModalPreview(true)
                : form.submit()
            }
          />
        </div>
      </div>
      {dataLoading ? (
        <CardLoader />
      ) : (
        <Form
          form={form}
          layout="vertical"
          className="create-form"
          onFinish={handleSubmit}
          onChange={() => {
            setSaveDisabled(false);
          }}
        >
          <DetailFields
            form={form}
            setIsTypeChanged={setIsTypeChange}
            isEdit={isEdit}
            selectedRole={selectedRole}
            setselectedRole={setselectedRoleHandler}
            advisories={advisories}
            isAdvisoryLoading={isAdvisoryLoading}
            advisoryTotal={advisoryTotal}
            setAdvisoryOffset={setadvisoryOffsetHandler}
            types={types}
            selectedtype={isEdit && transactiondata?.type}
            isTypesLoading={isTypesLoading}
            typeTotal={typeTotal}
            setTypeOffset={setTypeOffsetHandler}
            parties={parties}
            isPartiesLoading={isPartiesLoading}
            partyTotal={partyTotal}
            setPartyOffset={setpartyOffsetHandler}
            companies={companies}
            isCompaniesLoading={isCompanyLoading}
            setCompanySearchText={setCompanySearchTextHandler}
            handleSetField={handleSetFieldValue}
            resetTransactionType={() => {
              // form.resetFields(["transactionTypeId"]);
            }}
          />
        </Form>
      )}
      <TransactionTypeWarningModal
        modalPreview={warningModalPreview}
        handleClose={handleClose}
        handleOkay={handleTransactionTypeWarning}
        subtext="If you change the selection, the templates and forms provided for this transaction may no longer be appropriate."
        text="You can change answers"
      />
    </span>
  );
}

export default Details;
