import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setUserDetailDrawer } from "../../../../../../../redux/slicers/general";
import { Images } from "../../../../../../../themes";
import {
  EditDeleteDropdown,
  ProfileImage,
  VisibleToPeople,
} from "../../../../../../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { downloadImage } from "../../../../../../../helpers/downloadImage";

//CONST VALS
const NO_DATA_VAL = "------------";

export const TableRow = ({
  item,
  index,
  expandExhibitCondition,
  expandVersionCondition,
  isSecondChild,
  isThildChild,
  isExtraChild,
  isVersion,
  expandTreeHandler,
  isDeletedView,
  deleteModalPreview,
  editModalPreview,
  shareModalPreview,
  historyModalHandler,
  roomAccess,
  isEditAllowed,
  isDeleteAllowed,
  isShareAllowed,
}) => {
  // STATES
  const [downloadLoader, setDownloadLoader] = useState(false);

  // CONST VALS
  const isChildRow = isSecondChild || isThildChild;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // HANDLERS
  const setUserDrawerHandler = (userid) => {
    dispatch(
      setUserDetailDrawer({
        visibility: true,
        id: userid,
      })
    );
  };
  const navigateToDocDetail = () => {
    !isChildRow && !isDeletedView && navigate(pathname + `/${item.slug}`);
  };
  const deleteHandler = () => {
    const isChildExhibit = isChildRow && !isVersion;
    const isChildVersion = isChildRow && isVersion;
    deleteModalPreview(item, isChildExhibit, isChildVersion);
  };

  const shareHandler = () => {
    const isChildExhibit = isChildRow && !isVersion;
    const isChildVersion = isChildRow && isVersion;
    shareModalPreview(item, isChildExhibit, isChildVersion);
  };
  const editHandler = () => {
    editModalPreview(item, isChildRow, isVersion);
  };
  const downloadHandler = () => {
    downloadImage(item.file, setDownloadLoader, item.file);
  };
  const historyHandler = () => {
    const isChildExhibit = isChildRow && !isVersion;
    const isChildVersion = isChildRow && isVersion;
    historyModalHandler(item, isChildExhibit, isChildVersion);
  };

  return (
    <>
      <div
        className={`detail-row c-p ${
          isSecondChild ? "sec" : isThildChild ? "third" : ""
        }`}
      >
        <div
          className={`body-col tbl-col-1 ${isChildRow ? "na-p" : ""}`}
          onClick={navigateToDocDetail}
        >
          {!isChildRow && (
            <div className="doc-thumb">
              <span>
                <Images.DocThumb />
              </span>
            </div>
          )}
          <span className="doc-title">{item.name}</span>
        </div>
        <div
          className={`body-col tbl-col-2 ${isChildRow ? "na-p" : ""}`}
          onClick={navigateToDocDetail}
        >
          <span className="tbl-detail">{item.uploadDate}</span>
        </div>
        <div className="body-col tbl-col-3">
          <div className="uploaded-by">
            <ProfileImage
              size={12}
              classname="thumb"
              image={item.uploadedBy?.image}
              color={item.uploadedBy?.profilebg}
              isBlocked={!item.uploadedBy?.isActive}
              username={item.uploadedBy?.username}
              onClick={() => {
                setUserDrawerHandler(item.uploadedBy?.id);
              }}
            />
            <p>{item.uploadedBy?.username}</p>
          </div>
        </div>
        <div className="body-col tbl-col-4">
          {isVersion ? (
            <span className="no-data">{NO_DATA_VAL}</span>
          ) : item.exhibitCount > 0 ? (
            <span
              className={`tbl-detail ${expandExhibitCondition && "active"}`}
              onClick={() => expandTreeHandler(1, item.treeid)}
            >
              {("0" + item.exhibitCount).slice(-2)} Exhibit
              {item.exhibitCount > 1 ? "s" : ""}
              {expandExhibitCondition ? (
                <Images.UpArrow className="sort-btn" />
              ) : (
                <Images.DownArrow className="sort-btn" />
              )}
            </span>
          ) : (
            <span className="no-data">{NO_DATA_VAL}</span>
          )}
        </div>
        <div className="body-col tbl-col-5">
          {isVersion ? (
            <span className="tbl-detail">Versions {index + 1}</span>
          ) : item.versionCount > 0 ? (
            <span
              className={`tbl-detail ${expandVersionCondition && "active"}`}
              onClick={() => expandTreeHandler(0, item.treeid)}
            >
              {("0" + item.versionCount).slice(-2)} Version
              {item.versionCount > 1 ? "s" : ""}
              {expandVersionCondition ? (
                <Images.UpArrow className="sort-btn" />
              ) : (
                <Images.DownArrow className="sort-btn" />
              )}
            </span>
          ) : (
            <span className="no-data">{NO_DATA_VAL}</span>
          )}
        </div>
        {!isDeletedView ? (
          <>
            <div className="body-col tbl-col-6">
              <div className="visible-to">
                {item?.history?.length >= 1 ? (
                  <VisibleToPeople
                    data={item?.history}
                    maxLength={3}
                    isRoom
                    detailModalHandler={historyHandler}
                  />
                ) : (
                  <span className="no-data">{NO_DATA_VAL}</span>
                )}
              </div>
            </div>
            <div className="body-col tbl-col-7">
              {!roomAccess ? (
                <span
                  className="action"
                  style={{ minWidth: 35 }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {!item.status &&
                    (!isChildRow && !isEditAllowed && !isDeleteAllowed ? (
                      <></>
                    ) : (
                      <EditDeleteDropdown
                        isDelete={
                          isChildRow
                            ? isEditAllowed
                              ? true
                              : false
                            : isDeleteAllowed
                            ? true
                            : false
                        }
                        isEditable={isEditAllowed ? true : false}
                        isShare={isVersion && isShareAllowed ? true : false}
                        isDownload={isVersion}
                        onDownloadClick={downloadHandler}
                        onDeleteClick={deleteHandler}
                        onEditClick={editHandler}
                        isDownloadLoading={downloadLoader}
                        onShareClick={shareHandler}
                        style={{
                          display: `${
                            !isEditAllowed && !isVersion && isChildRow
                              ? "none"
                              : ""
                          }`,
                        }}
                      />
                    ))}
                </span>
              ) : (
                <>
                  {isVersion && (
                    <Images.DocumentDownload
                      onClick={downloadHandler}
                      style={{
                        pointerEvents: downloadLoader ? "none" : "unset",
                        opacity: downloadLoader ? 0.5 : 1,
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </>
        ) : (
          <div className="body-col tbl-col-7">
            {isVersion && (
              <Images.DocumentDownload
                onClick={downloadHandler}
                style={{
                  pointerEvents: downloadLoader ? "none" : "unset",
                  opacity: downloadLoader ? 0.5 : 1,
                }}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};
export const tableRowWrapper = (
  data,
  key,
  selectedDoc,
  expandTreeHandler,
  isVersion,
  isDeletedView,
  deleteModalPreview,
  editModalPreview,
  shareModalPreview,
  historyModalHandler,
  roomAccess,
  checkUserStatusHandler,
  isEditAllowed,
  isDeleteAllowed,
  isShareAllowed
) =>
  data?.map((item, index) => {
    const expandVersionCondition = selectedDoc.find(
      (i) => i.id === item.treeid && i.key === 0
    );
    const expandExhibitCondition = selectedDoc.find(
      (i) => i.id === item.treeid && i.key === 1
    );
    const isOpen = expandVersionCondition || expandExhibitCondition;
    const isParent = key < 2;

    const rowData = (index) => (
      <React.Fragment key={index}>
        <TableRow
          item={item}
          index={index}
          expandExhibitCondition={expandExhibitCondition}
          expandVersionCondition={expandVersionCondition}
          isVersion={isVersion}
          isSecondChild={key === 2}
          isThildChild={key > 2}
          isExtraChild={key > 3}
          expandTreeHandler={expandTreeHandler}
          isDeletedView={isDeletedView}
          deleteModalPreview={deleteModalPreview}
          editModalPreview={editModalPreview}
          shareModalPreview={shareModalPreview}
          roomAccess={roomAccess}
          historyModalHandler={historyModalHandler}
          isEditAllowed={isEditAllowed}
          isDeleteAllowed={isDeleteAllowed}
          isShareAllowed={isShareAllowed}
        />
        {isOpen && (
          <div className={`expanded-box ${key > 2 ? "p-0" : ""}`}>
            {tableRowWrapper(
              item?.[expandVersionCondition ? "versions" : "exhibits"],
              key + 1,
              selectedDoc,
              expandTreeHandler,
              expandVersionCondition,
              isDeletedView,
              deleteModalPreview,
              editModalPreview,
              shareModalPreview,
              historyModalHandler,
              roomAccess,
              checkUserStatusHandler,
              isEditAllowed,
              isDeleteAllowed,
              isShareAllowed
            )}
          </div>
        )}
      </React.Fragment>
    );
    return isParent ? (
      <div className="body-row c-p" key={index}>
        {rowData(index)}
      </div>
    ) : (
      rowData(index)
    );
  });
