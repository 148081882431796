import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCustomDispatch } from "../../../../../../helpers/useCustomDispatch";
import {
  ALERT_TYPES,
  DATA_PER_PAGE_LIMIT,
  NO_DATA_FOUND_ERROR,
} from "../../../../../../constants";
import { MilestonDetailView } from "../../milestones/components";
import {
  CardLoader,
  CustomInfiniteScroll,
  EmptyBox,
  NoAccessBox,
  ProfileImage,
} from "../../../../../../components";
import {
  getActivityLogsRequest,
  setUserDetailDrawer,
} from "../../../../../../redux/slicers/general";
import { toastAlert } from "../../../../../../services/utils";

const ActivityLogs = ({
  searchText,
  isRoom,
  roomslug,
  isCurved,
  checkApiUserStatusHandler,
  isAccess = true,
}) => {
  //STATES
  const [total, setTotal] = useState(null);
  const [offset, setOffset] = useState(0);
  const [isMoreData, setMoreData] = useState(false);
  const [isMilestonePreview, setMilestonePreview] = useState(false);
  const [milestoneSlug, setMilestoneSlug] = useState(null);

  //CONST VALS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const BOX_HEIGHT = 430;

  //REDUX DATA
  const { activitylogs } = useSelector((state) => state.general);
  const { roomAccess } = useSelector((state) => state.transactiondetail);

  //CUSTOM DISPATCH
  const [getActivityLogs, isLoading] = useCustomDispatch(
    getActivityLogsRequest
  );

  //HELPERS
  const getActivityLogsHelper = (isSearch) => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: isSearch ? 0 : offset,
    };
    if (isSearch) {
      queryParams["search"] = searchText;
    }
    if (isRoom) {
      queryParams["room"] = roomslug;
    } else {
      queryParams["dealRoom"] = roomslug;
    }

    getActivityLogs({
      queryParams,
      logic(res) {
        setTotal(res.total);
      },
    });
  };

  //HOOKS
  useEffect(() => {
    if (!isMilestonePreview)
      checkApiUserStatusHandler(() => getActivityLogsHelper(searchText !== ""));
  }, [searchText, offset, roomslug, roomAccess, isMilestonePreview]);

  // HANDLERS
  const handleNextData = () => {
    setOffset(offset + DATA_PER_PAGE_LIMIT);
    setMoreData(true);
  };
  const navigateLogHandler = ({ isDeleted, isMilestone, route }) => {
    if (isDeleted) {
      toastAlert(NO_DATA_FOUND_ERROR, ALERT_TYPES.ERROR);
      return;
    }
    if (isMilestone) {
      setMilestonePreview(true);
      setMilestoneSlug(route);
      return;
    }
    navigate(route);
  };
  const setMilestonePreviewHandler = () => {
    setMilestonePreview(!isMilestonePreview);
  };

  return (
    <div
      className="card"
      style={{
        paddingTop: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: isCurved ? 0 : 12,
      }}
    >
      <div className="tbl-list">
        {isAccess ? (
          isLoading & !isMoreData ? (
            <CardLoader size={20} height={BOX_HEIGHT} />
          ) : (
            <CustomInfiniteScroll
              dataLength={activitylogs?.length}
              total={total}
              next={handleNextData}
              maxHeight={BOX_HEIGHT}
              minHeight={BOX_HEIGHT}
              isWholePage={false}
            >
              {activitylogs.length >= 1 ? (
                activitylogs?.map((item, index) => (
                  <ul key={index}>
                    <li>
                      <ProfileImage
                        username={item.username}
                        color={item.profilebg}
                        image={item.thumb}
                        isBlocked={!item?.isActive}
                        onClick={() => {
                          dispatch(
                            setUserDetailDrawer({
                              visibility: true,
                              id: item.userid ?? item.id,
                            })
                          );
                        }}
                      />
                      <div className="detail">
                        <p
                          className="point c-p"
                          onClick={() => {
                            navigateLogHandler(item);
                          }}
                        >
                          {item.detail}
                        </p>
                        <p className="point">{item.date}</p>
                      </div>
                    </li>
                  </ul>
                ))
              ) : (
                <EmptyBox height={BOX_HEIGHT} />
              )}
            </CustomInfiniteScroll>
          )
        ) : (
          <NoAccessBox height={BOX_HEIGHT} />
        )}
      </div>
      <MilestonDetailView
        milestoneslug={milestoneSlug}
        modalPreview={isMilestonePreview}
        handleClose={setMilestonePreviewHandler}
      />
    </div>
  );
};

export default ActivityLogs;
