import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Error } from "../modules";
import { PAGE_ROUTES, ACCESS_TYPES } from "../constants";
import Helmet from "react-helmet";
import {
  AuthRoute,
  PrivateRoute,
  EnterpriseAdminPrivateRoute,
  PublicRoute,
} from "../config/privateRoute";

const renderRouteSharedLayout = (title, access, component) => (
  <React.Fragment>
    <Helmet>
      <title>
        {title ? `${title} |` : ""} {process.env.REACT_APP_WEB_TITLE}
      </title>
    </Helmet>
    {access === ACCESS_TYPES.PRIVATE ? (
      <PrivateRoute>{component}</PrivateRoute>
    ) : access === ACCESS_TYPES.ENTERPRISE_ADMIN ? (
      <PrivateRoute>
        <EnterpriseAdminPrivateRoute>{component}</EnterpriseAdminPrivateRoute>
      </PrivateRoute>
    ) : access === ACCESS_TYPES.AUTH ? (
      <AuthRoute>{component}</AuthRoute>
    ) : (
      <PublicRoute>{component}</PublicRoute>
    )}
  </React.Fragment>
);

const PageRoutes = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Routes>
        {PAGE_ROUTES.map((item, index) =>
          typeof item.route === "string" ? (
            <Route
              path={item.route}
              element={renderRouteSharedLayout(
                item.title,
                item.access,
                item.component
              )}
              key={index}
            />
          ) : (
            item.route.map((x, i) => (
              <Route
                path={x}
                element={renderRouteSharedLayout(
                  item.title,
                  item.access,
                  item.component
                )}
                key={i}
              />
            ))
          )
        )}
        <Route path="*" element={<Error />} />
      </Routes>
    </>
  );
};

export default PageRoutes;
