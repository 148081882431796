import React from "react";
import PhoneInput from "react-phone-input-2";
import { Images } from "../../themes";

function CompanyFields({ data }) {
  return (
    <>
      {data.phone && (
        <>
          <p className="user-profile-title">Phone</p>
          <div className="user-profile-detail">
            <PhoneInput value={data.phone} disabled />
          </div>
        </>
      )}
      {data?.addresses?.map((item, index) => (
        <React.Fragment key={index}>
          <p className="user-profile-title">{item.name}</p>
          <div className="user-profile-detail">
            <p>{`${item?.address}${
              item?.address2 ? ", " + item?.address2 : ""
            }, ${item?.city}, ${item?.state}, ${item?.zip}, ${
              item?.country
            }`}</p>
          </div>
        </React.Fragment>
      ))}
      {(data?.facebook || data?.linkedIn || data?.twitter) && (
        <>
          <p className="user-profile-title">Social</p>
          <div className="user-profile-detail">
            {data.facebook && (
              <a href={data.facebook} target="_blank">
                <Images.Facebook />
              </a>
            )}
            {data.twitter && (
              <a href={data.twitter} target="_blank">
                <Images.Twitter />
              </a>
            )}
            {data.linkedIn && (
              <a href={data.linkedIn} target="_blank">
                <Images.Linkedin />
              </a>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default CompanyFields;
