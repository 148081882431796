import React from "react";
import { Images } from "../../../../../themes";
import "./styles.scss";

function FilterCard({ title, isSelected, onClick }) {
  return (
    <div
      className={`filter-card-wrapper c-p ${
        isSelected ? "selected-filter" : ""
      }`}
      onClick={onClick}>
      <div className={`content-wrapper ${isSelected ? "selected-text" : ""}`}>
        <h3>{title}</h3>
        <p>Type: Document Template</p>
      </div>
      {isSelected ? (
        <Images.CloseIcon className="cancel-icon" />
      ) : (
        <Images.SuggestionPlus />
      )}
    </div>
  );
}

export default FilterCard;
