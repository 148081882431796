import React, { useState } from "react";
import { Row, Col, Form, Select, DatePicker } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { CreateChecklistModal } from "../../components";
import { ShareDocModal } from "../../../documents/components";
import {
  ButtonComponent,
  DisabledTooltip,
} from "../../../../../../../components";
import { Images } from "../../../../../../../themes";
import { useNavigate } from "react-router-dom";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { getChecklistTypesRequest } from "../../../../../../../redux/slicers/transactionchecklist";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getFormattedDateTime } from "../../../../../../../services/utils";
import { DATE_FORMAT3 } from "../../../../../../../constants";

const TopHeader = ({
  isSubView,
  handleSearch,
  setFilters,
  handleDataUpdate,
  isCreateAllowed,
  isShareAllowed,
}) => {
  // STATES
  const [filterView, setfilterView] = useState(false);
  const [createModalPreview, setCreateModalPreview] = useState(false);
  const [shareModalPreview, setShareModalPreview] = useState(false);

  //REDUX DATA
  const { types } = useSelector((state) => state.transactionchecklist);

  //CUSTOM DIPATCH
  const [getChecklistTypes, typeLoader] = useCustomDispatch(
    getChecklistTypesRequest
  );

  //  CONST VALS
  const [form] = Form.useForm();
  const navigate = useNavigate();

  //HOOKS
  useEffect(() => {
    getChecklistTypes();
  }, [filterView]);

  //CUSTOM DIPATC

  // HANDLERS
  const createModalPreviewHandler = () => {
    setCreateModalPreview(!createModalPreview);
  };
  const shareModalPreviewHandler = () => {
    setShareModalPreview(!shareModalPreview);
  };
  const handleSubmit = (values) => {
    values.date = getFormattedDateTime(values.date, DATE_FORMAT3);
    setFilters(values);
    setfilterView(!filterView);
  };

  return (
    <>
      <div className="room-detail">
        {isSubView ? (
          <div className="dtl-wrapper">
            <Images.BackArrow className="c-p" onClick={() => navigate(-1)} />
            <div className="top-heading">
              <h3>Deleted Checklist</h3>
              <p>View Details Below</p>
            </div>
          </div>
        ) : (
          <>
            <div className="left-wrapper">
              <div className="search-box">
                <FontAwesomeIcon icon={faSearch} />
                <input
                  type="text"
                  placeholder="Search any checklist..."
                  onChange={(e) => handleSearch(e.target.value)}
                />
                <Images.Setting
                  className="filter-btn"
                  onClick={() => {
                    setfilterView(!filterView);
                  }}
                />
              </div>
            </div>
            <div className="right-wrapper">
              <DisabledTooltip
                innerSec={
                  <button onClick={isShareAllowed && shareModalPreviewHandler}>
                    Share Documents
                  </button>
                }
                visible={!isShareAllowed}
              />
              <DisabledTooltip
                innerSec={
                  <button
                    onClick={isCreateAllowed && createModalPreviewHandler}
                  >
                    Checklist Item +
                  </button>
                }
                visible={!isCreateAllowed}
              />
            </div>
            {filterView && (
              <div className="filter-sec">
                <h3>Filter</h3>
                <div
                  className="close-btn"
                  onClick={() => {
                    setfilterView(!filterView);
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
                <Form form={form} onFinish={handleSubmit}>
                  <Row gutter={[16, 10]}>
                    <Col xs={24} md={8}>
                      <Form.Item name="type">
                        <Select
                          placeholder="Select Type"
                          className="form-select"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          loading={typeLoader}
                        >
                          {types?.map((item) => (
                            <Select.Option value={item?.key} key={item?.key}>
                              {item?.value}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                      <Form.Item name="date">
                        <DatePicker
                          placeholder="Select Date"
                          className="form-select"
                          format="YYYY-MM-DD"
                          clearIcon={true}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                      <ButtonComponent text="Apply Filter" />
                    </Col>
                  </Row>
                </Form>
              </div>
            )}
          </>
        )}
      </div>
      <CreateChecklistModal
        preview={createModalPreview}
        handleClose={() => {
          setCreateModalPreview(false);
        }}
        handleSucess={handleDataUpdate}
      />
      <ShareDocModal
        preview={shareModalPreview}
        handleClose={shareModalPreviewHandler}
      />
    </>
  );
};

export default TopHeader;
