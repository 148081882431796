import { call, fork, put, take, takeLatest } from "redux-saga/effects";
import {
  callRequest,
  GET_COMPANY_DETAIL,
  GET_DASHBOARD_STATS,
  GET_USER_DETAIL,
  GET_ACTIVITY_LOGS,
  UPLOAD_IMAGE,
  GET_APPROVAL_REQUEST_DETAIL,
  GET_PENNDING_REQUEST_DETAIL,
  GET_REVIEWS,
  CONTACT_FORM_SUBMISSION,
  GET_NOTIFCATIONS,
  OPEN_NOTIFCATION,
  READ_ALL_NOTIFCATION,
  READ_NOTIFCATION,
  TRANSACTION_ADMIN_RESPONSE,
  PARTY_ADMIN_RESPONSE,
  GET_DASHBOARD_ACTIVITY_LOGS,
} from "../../config/webService";
import { ALERT_TYPES } from "../../constants";
import {
  manipulateCompanyData,
  manipulateProfileData,
} from "../../dataManipulator/user";
import {
  manipulateActivtyLogsList,
  manipulatePendingApprovalRequestData,
  manipulateConfirmApprovalRequestData,
  manipulateNotificationsList,
  manipulateReviewsList,
} from "../../dataManipulator/general";
import { toastAlert } from "../../services/utils";
import {
  uploadImageRequest,
  getActivityLogsRequest,
  getActivityLogsSuccess,
  getUserDetailRequest,
  getUserDetailSuccess,
  getCompanyDetailRequest,
  getCompanyDetailSuccess,
  getDashboardStatsRequest,
  getDashboardStatsSuccess,
  getReviewsRequest,
  getReviewsSuccess,
  getPendingRequesDetailRequest,
  getPendingRequesDetailSuccess,
  contactFormSubmitRequest,
  contactFormSubmitSuccess,
  getNotificationsRequest,
  getNotificationsSuccess,
  markAllAsReadRequest,
  markAsOpenRequest,
  markAsReadRequest,
  markAsReadSuccess,
  getConfirmApprovalRequesDetailRequest,
  getConfirmApprovalRequesDetailSuccess,
  transactionAdminResponseRequest,
  partyAdminResponseRequest,
  getDashboardActivityLogsRequest,
} from "../slicers/general";

// CONTACT FORM SUBMISSION
function* contactFormSubmit() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(contactFormSubmitRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CONTACT_FORM_SUBMISSION,
        data: payload,
      });
      if (response.status) {
        yield put(contactFormSubmitSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// REVIEWS LIST
function* getReviews() {
  while (true) {
    const {
      payload: { queryParams, responseCallback },
    } = yield take(getReviewsRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_REVIEWS,
        queryParams,
      });
      if (response.status) {
        yield put(getReviewsSuccess(manipulateReviewsList(response.data)));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// ACTIVITY LOGS
function* getActivityLogs(action) {
  const {
    payload: { queryParams, isMilestone, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_ACTIVITY_LOGS,
      queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;

    if (response.status) {
      yield put(
        getActivityLogsSuccess({
          data: manipulateActivtyLogsList(response.data),
          isPaginated: isMoreData,
          isMilestone,
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    console.error(err);
    responseCallback?.(false, err);
  }
}

// DASHBOARD ACTIVITY LOGS
function* getDashboardActivityLogs(action) {
  const {
    payload: { isMilestone, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_DASHBOARD_ACTIVITY_LOGS,
    });

    if (response.status) {
      yield put(
        getActivityLogsSuccess({
          data: manipulateActivtyLogsList(response.data),
          isPaginated: false,
          isMilestone,
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    console.error(err);
    responseCallback?.(false, err);
  }
}

// NOTIFICATIONS
function* getNotifcations(action) {
  const {
    payload: { queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_NOTIFCATIONS,
      queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;

    if (response.status) {
      yield put(
        getNotificationsSuccess({
          ...manipulateNotificationsList(response.data),
          isPaginated: isMoreData,
          total: response.total,
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    console.error(err);
    responseCallback?.(false, err);
  }
}

function* markNotificationOpen() {
  while (true) {
    const {
      payload: { responseCallback },
    } = yield take(markAsOpenRequest.type);

    try {
      const response = yield call(callRequest, {
        url: OPEN_NOTIFCATION,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* markAllNotificationRead() {
  while (true) {
    const {
      payload: { responseCallback },
    } = yield take(markAllAsReadRequest.type);

    try {
      const response = yield call(callRequest, {
        url: READ_ALL_NOTIFCATION,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* markNotificationRead() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(markAsReadRequest.type);

    try {
      const response = yield call(callRequest, {
        url: READ_NOTIFCATION,
        pathParams,
      });
      if (response.status) {
        yield put(markAsReadSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// USER DETAIL
function* getUserDetail() {
  while (true) {
    const {
      payload: { queryParams, pathParams, responseCallback },
    } = yield take(getUserDetailRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_USER_DETAIL,
        pathParams,
        queryParams,
      });
      if (response.status) {
        yield put(getUserDetailSuccess(manipulateProfileData(response.data)));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// COMPANY DETAIL
function* getCompanyDetail() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getCompanyDetailRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_COMPANY_DETAIL,
        pathParams,
      });
      if (response.status) {
        yield put(
          getCompanyDetailSuccess(manipulateCompanyData(response.data))
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// PENDING APPROVAL REQUEST
function* getPendingApprovalRequestDetail() {
  while (true) {
    const {
      payload: { pathParams, queryParams, responseCallback },
    } = yield take(getPendingRequesDetailRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_PENNDING_REQUEST_DETAIL,
        pathParams,
        queryParams,
      });
      if (response.status) {
        yield put(
          getPendingRequesDetailSuccess(
            manipulatePendingApprovalRequestData(response.data)
          )
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// CONFIRM APPROVAL REQUEST
function* getConfirmApprovalRequestDetail() {
  while (true) {
    const {
      payload: { queryParams, pathParams, responseCallback },
    } = yield take(getConfirmApprovalRequesDetailRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_APPROVAL_REQUEST_DETAIL,
        pathParams,
        queryParams,
      });
      if (response.status) {
        yield put(
          getConfirmApprovalRequesDetailSuccess(
            manipulateConfirmApprovalRequestData(response.data)
          )
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}
// DASHBOARD STATS
function* getDashboardStats() {
  while (true) {
    const {
      payload: { responseCallback },
    } = yield take(getDashboardStatsRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_DASHBOARD_STATS,
      });
      if (response.status) {
        yield put(getDashboardStatsSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// UPLOAD IMAGE
function* uploadImage() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(uploadImageRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPLOAD_IMAGE,
        data: payload,
        header: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// TRANSACTION ADMIN RESPONSE
function* transactionAdminResponse() {
  while (true) {
    const {
      payload: { queryParams, pathParams, responseCallback },
    } = yield take(transactionAdminResponseRequest.type);

    try {
      const response = yield call(callRequest, {
        url: TRANSACTION_ADMIN_RESPONSE,
        pathParams,
        queryParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        response?.message && toastAlert(response?.message, ALERT_TYPES.SUCCESS);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// PARTY ADMIN RESPONSE
function* partyAdminResponse() {
  while (true) {
    const {
      payload: { queryParams, pathParams, responseCallback },
    } = yield take(partyAdminResponseRequest.type);

    try {
      const response = yield call(callRequest, {
        url: PARTY_ADMIN_RESPONSE,
        pathParams,
        queryParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        response?.message && toastAlert(response?.message, ALERT_TYPES.SUCCESS);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

export default function* root() {
  yield fork(getUserDetail);
  yield fork(getCompanyDetail);
  yield takeLatest(getActivityLogsRequest.type, getActivityLogs);
  yield takeLatest(
    getDashboardActivityLogsRequest.type,
    getDashboardActivityLogs
  );
  yield takeLatest(getNotificationsRequest.type, getNotifcations);
  yield fork(markNotificationOpen);
  yield fork(markAllNotificationRead);
  yield fork(markNotificationRead);
  yield fork(getDashboardStats);
  yield fork(getReviews);
  yield fork(getPendingApprovalRequestDetail);
  yield fork(getConfirmApprovalRequestDetail);
  yield fork(contactFormSubmit);
  yield fork(uploadImage);
  yield fork(transactionAdminResponse);
  yield fork(partyAdminResponse);
}
