import _ from "lodash";
import { DATE_FORMAT6 } from "../constants";
import { getFormattedDateByDay, getFormattedDateTime } from "../services/utils";
import { manipulateUserData } from "./user";

export const manipulateVersionVisibilityData = (data) => {
  let history = {};

  history["roomname"] = data?.name ?? "";
  history["bgColor"] = data?.bgcolor ?? "";
  history["slug"] = data?.slug ?? "";

  return history;
};

export const manipulateVersionVisibility = (data) => {
  const history = [];
  data?.forEach((item) => {
    history.push(manipulateVersionVisibilityData(item.roomTo));
  });
  return history;
};

export const manipulateVersionData = (data, isDocument) => {
  if (_.isEmpty(data)) return {};
  let version = {};
  version["id"] = data?.id ?? 0;
  version["treeid"] = `ver${data?.id}`;
  version["slug"] = data.slug ?? "";
  version["name"] = data.name ?? "";
  version["notes"] = data.notes ?? "";
  version["file"] = data.file ?? "";
  version["isDocument"] = isDocument;
  version["uploadedFrom"] = data?.uploadFrom ?? "Computer";
  version["uploadedBy"] = manipulateUserData(data.createdBy);
  version["uploadDate"] = getFormattedDateTime(data?.createdAt, DATE_FORMAT6);
  version["createdAt"] = getFormattedDateByDay(data?.createdAt);
  version["visibility"] = [];
  version["number"] = data.version ?? 0;
  version["history"] = manipulateVersionVisibility(data?.sharedFrom) ?? [];

  return version;
};

export const manipulateVersionList = (data, isDocument) => {
  let versions = [];
  data?.forEach((item) => {
    versions.push(manipulateVersionData(item, isDocument));
  });
  versions.sort((a, b) => parseFloat(a.number) - parseFloat(b.number));
  return versions;
};

export const manipulateExhibitData = (data, isDocument) => {
  if (_.isEmpty(data)) return {};
  let exhibit = {};
  exhibit["id"] = data?.id ?? 0;
  exhibit["treeid"] = `exhibit${data?.id}`;
  exhibit["slug"] = data.slug ?? "";
  exhibit["name"] = data.name ?? "Exhibit";
  exhibit["isDocument"] = isDocument;
  exhibit["uploadDate"] = getFormattedDateTime(data?.createdAt, DATE_FORMAT6);
  exhibit["createdAt"] = getFormattedDateByDay(data?.createdAt);
  exhibit["uploadedBy"] = manipulateUserData(data.createdBy);
  exhibit["visibility"] = [];
  exhibit["exhibits"] = manipulateExhibitList(data?.exhibits, false);
  exhibit["versions"] = manipulateVersionList(data?.versions, false);
  exhibit["exhibitCount"] = parseInt(("0" + data?.exhibits?.length).slice(-2));
  exhibit["versionCount"] = parseInt(("0" + data?.versions?.length).slice(-2));

  return exhibit;
};

export const manipulateExhibitList = (data, isDocument) => {
  let exhibits = [];
  data?.forEach((item) => {
    exhibits.push(manipulateExhibitData(item, isDocument));
  });
  return exhibits;
};

export const manipulateDocumentData = (data) => {
  if (_.isEmpty(data)) return {};
  let document = {};
  document["id"] = data?.id ?? 0;
  document["treeid"] = `doc${data?.id}`;
  document["slug"] = data.slug ?? "";
  document["name"] = data.name ?? "Document";
  document["notes"] = data.notes ?? "Note";
  document["uploadedBy"] = manipulateUserData(data.createdBy);
  document["uploadDate"] = getFormattedDateTime(data?.createdAt, DATE_FORMAT6);
  document["createdAt"] = getFormattedDateByDay(data?.createdAt);
  document["visibility"] = [];
  document["exhibits"] = manipulateExhibitList(data?.exhibits, true);
  document["versions"] = manipulateVersionList(data?.versions, true);
  document["exhibitCount"] = parseInt(("0" + data?.exhibits?.length).slice(-2));
  document["versionCount"] = parseInt(("0" + data?.versions?.length).slice(-2));

  return document;
};

export const manipulateDocumentList = (data) => {
  let documents = [];
  data?.forEach((item) => {
    documents.push(manipulateDocumentData(item));
  });
  return documents;
};

export const manipulateDocVersionData = (data, isDocument) => {
  if (_.isEmpty(data)) return {};
  let version = {};
  version["id"] = isDocument ? data?.documentId : data?.id;
  version["name"] = data?.documentName ?? data?.name ?? "";
  version["notes"] = data?.notes ?? "";
  version["slug"] = isDocument ? `document-${data.documentId}` : data.slug;
  version["file"] = data?.File ?? "";
  version["isDocument"] = isDocument;
  version["isMainVersion"] = data?.isDocument;
  return version;
};

export const manipulateAllVersionsList = (data) => {
  let versions = [];
  data?.forEach((item) => {
    versions.push(manipulateDocVersionData(item, true));
    item?.versions?.forEach((item) => {
      versions.push(manipulateDocVersionData(item, false));
    });
  });
  return versions;
};

export const manipulateVersionHistoryData = (data, isDocument) => {
  if (_.isEmpty(data)) return {};
  let history = {};
  history["currentRoom"] = data?.currentRoomName ?? "";
  history["currentVersion"] = data?.currentVersionNumber ?? 0;
  history["currentVersionName"] = data?.currentRoomVersionName ?? "";
  history["currentRoomBg"] = data?.currentRoomBgColor ?? "red";
  history["previousRoom"] = data?.PreviousRoomName ?? "";
  history["previousVersion"] = data?.PreviousVersionNumber ?? 0;
  history["previousVersionName"] = data?.PreviousRoomVersionName ?? "";
  history["sharedBy"] = manipulateUserData(data?.sharedBy) ?? {};
  history["name"] = data?.document ?? "";

  return history;
};

export const manipulateVersionHistoryList = (data) => {
  let histories = [];
  data?.forEach((item) => {
    histories.push(manipulateVersionHistoryData(item, true));
  });
  return histories;
};
