import { Mentions } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProfileImage, VisibleToPeople } from "../../../../../../../components";
import {
  CUSTOM_ALL,
  DEFAULT_PROFILE_COLOR,
} from "../../../../../../../constants";
import { setUserDetailDrawer } from "../../../../../../../redux/slicers/general";
import {
  convertUrlsToAnchors,
  convertUserToMentionUser,
  isContainLinks,
} from "../../../../../../../services/utils";
import { Images } from "../../../../../../../themes";
import { Emojis } from "../../../../components";
import DocumentAttachmentWrapper from "../documentAttachmentWrapper";
import ImageAttachment from "../imageAttachment";
import LinkPreview from "../linkPreview";
import Reactions from "../reactions";
import "./styles.scss";

function MessageBox({
  data,
  chat,
  setEmojiOpen,
  setReactOpen,
  setSelectedMessage,
  handleThread,
  handleAddReact,
  emojiOpen,
  reactOpen,
  selectedMessage,
  onEmojiClick,
  handleRemoveReact,
  removeReactLoader,
  isThread = false,
  isParent = false,
}) {
  //REDUX DATA
  const { profilePic, chat_id, user_id, name, profilebg } = useSelector(
    (state) => state.user.data
  );
  const { selectedRoom } = useSelector((state) => state.chat);

  //CONST VALS
  const dispatch = useDispatch();
  const hasLink = isContainLinks(data?.content)?.length > 0 ? true : false;

  //HANDLERS
  const userDetailHandler = (userid, item) => {
    dispatch(
      setUserDetailDrawer({
        visibility: true,
        id: userid,
        isGroup: selectedRoom?.isGroup,
      })
    );
  };

  document.addEventListener("click", function (event) {
    const target = event.target;

    if (target.classList.contains("message_mention")) {
      const userId = target.getAttribute("data-user-id");

      if (userId && userId !== "all") {
        userDetailHandler(userId, {});
      }
    }
  });

  let messageText = data?.content
    ? convertUrlsToAnchors(data?.content)
    : data?.content;

  messageText = data?.content
    ? convertUserToMentionUser(messageText, userDetailHandler)
    : messageText;

  return (
    <div
      className={`message-item ${
        data?.isSender ? "" : isThread ? "thread-sent" : "sent"
      }`}
    >
      <div className="message-content-wrapper">
        <div className="deets">
          <ProfileImage
            size={isThread ? 8 : 12}
            // image={data?.isSender ? chat?.image : profilePic}
            // username={data?.isSender ? chat?.username : name}
            // color={data?.isSender ? chat?.profilebg : profilebg}
            image={data?.sender?.image}
            username={data?.sender?.username}
            onClick={() => {
              userDetailHandler(data?.sender?.id, data?.sender);
            }}
            classname={`${data?.reacts?.length > 0 ? "margin-img" : ""}`}
            color={data?.sender?.bgColor ?? DEFAULT_PROFILE_COLOR}
          />
          <div className="message-box">
            {data?.content !== "" && (
              <span
                id="messageContainer"
                dangerouslySetInnerHTML={{
                  __html: messageText,
                }}
                className="message-content"
              />
            )}

            {hasLink && <LinkPreview data={data?.content} />}

            {data?.attachments?.length > 0 && (
              <div className="attachment-wrapper">
                {data?.attachments?.map((file, f_ind) =>
                  file?.isImage ? (
                    <ImageAttachment
                      src={file?.url}
                      fileName={file?.fileName}
                      key={f_ind}
                    />
                  ) : (
                    <DocumentAttachmentWrapper
                      fileName={file?.fileName}
                      isDownload={true}
                      url={file?.url}
                      key={f_ind}
                    />
                  )
                )}
              </div>
            )}

            <div className="bubble-arrow" />
            {!isParent &&
              !selectedRoom?.isBlocked &&
              !selectedRoom?.isArchived &&
              (selectedRoom?.isGroup
                ? true
                : selectedRoom?.individual?.isActive) && (
                <>
                  <Images.ThreeDotV
                    className="c-p message-option"
                    onClick={() => {
                      if (selectedMessage === data) {
                        setSelectedMessage({});
                        setReactOpen(false);
                        return;
                      }
                      setSelectedMessage(data);
                      setReactOpen(true);
                    }}
                  />
                  {reactOpen && selectedMessage === data && (
                    <div className="message-react-options">
                      <Images.React
                        className="c-p"
                        onClick={() => handleAddReact("😃")}
                      />
                      <Emojis
                        isReact={true}
                        onEmojiSelect={onEmojiClick}
                        open={emojiOpen}
                        setOpen={setEmojiOpen}
                        perLine={isThread ? 4 : 9}
                      />
                      {!isThread && (
                        <Images.Thread
                          className="c-p"
                          onClick={() => {
                            handleThread(data?.id);
                            setReactOpen(false);
                          }}
                        />
                      )}
                    </div>
                  )}
                </>
              )}
          </div>
        </div>
        <div className="date-wrapper" onClick={() => setSelectedMessage(data)}>
          {!isThread && data?.replyCount > 0 && (
            <div
              className="reply-wrapper c-p"
              onClick={() => {
                handleThread(data?.id);
                setReactOpen(false);
              }}
            >
              <VisibleToPeople data={data?.repliers} size={7} maxLength={2} />
              <p>
                {data?.replyCount > 9 ? "9+" : data?.replyCount} Repl
                {data?.replyCount > 1 ? "ies" : "y"}{" "}
              </p>
              <Images.ForwardOutlined />
            </div>
          )}
          {data?.reacts?.length > 0 && (
            <Reactions
              reactions={data?.reacts}
              handleRemoveReaction={handleRemoveReact}
              removeLoader={removeReactLoader}
              isBlocked={selectedRoom?.isBlocked || selectedRoom?.isArchived}
            />
          )}
          <p>{data?.date}</p>
        </div>
      </div>
    </div>
  );
}

export default MessageBox;
