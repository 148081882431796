import _ from "lodash";
import {
  DATE_FORMAT2,
  DATETIME_FORMAT3,
  DATE_FORMAT3,
  DATE_FORMAT5,
  DEFAULT_PROFILE_COLOR,
} from "../constants";
import { getFormattedDateTime } from "../services/utils";
import { manipulatePermissionsList } from "./transactiondetail";
import { manipulateUserData } from "./user";

export const manipulateVisibilityData = (data) => {
  if (_.isEmpty(data)) return {};
  let visibility = {};
  visibility["id"] = data?.id ?? 0;
  visibility["roomname"] = data.name ?? "";
  visibility["bgColor"] = data?.bgcolor ?? DEFAULT_PROFILE_COLOR;
  visibility["slug"] = data?.slug ?? "";

  return visibility;
};

export const manipulateVisibilityList = (data) => {
  let visibilities = [];
  data?.forEach((item) => {
    visibilities.push(manipulateVisibilityData(item.visibilityRooms));
  });
  return visibilities;
};

export const manipulateMilestoneResponsiblePartyData = (data, isNoCompany) => {
  if (_.isEmpty(data)) return {};
  let party = { ...manipulateUserData(data) };
  if (!isNoCompany) {
    party["companyid"] = data?.company?.id ?? 0;
    party["companyname"] = data?.company?.name ?? "";
    party["companylogo"] = data?.company?.logo ?? "";
  }

  return party;
};

export const manipulateMilestoneResponsiblePartyList = (data) => {
  let parties = [];
  data?.forEach((item) => {
    parties.push(
      manipulateMilestoneResponsiblePartyData(
        item.responsiblePerson ?? item.users
      )
    );
  });
  parties.sort(function (a, b) {
    return a.firstName.localeCompare(b.firstName);
  });
  return parties;
};

export const manipulateMilestoneResponsiblePartyGroup = (data) => {
  let groups = [];
  data?.forEach((item) => {
    const user = {
      email: item.email,
      image: item.image,
      id: item.id,
      username: item.username,
      profilebg: item.profilebg,
      isActive: item.isActive,
    };
    const group = {
      name: item.companyname,
      id: item.companyid,
      logo: item.companylogo,
      users: [user],
    };
    let isExist = groups.findIndex((x) => x.id === item.companyid);
    if (isExist >= 0) {
      groups[isExist].users.push(user);
    } else {
      groups.push(group);
    }
  });
  return groups;
};

export const manipulateMilestoneData = (data, isRequest) => {
  if (_.isEmpty(data)) return {};
  let milestone = {};
  milestone["id"] = data?.id ?? 0;
  milestone["slug"] = data.slug ?? "";
  milestone["name"] = data.name ?? "Milestone";
  milestone["transactionslug"] = data?.TransactionDetails?.slug ?? "";
  milestone["startDate"] = getFormattedDateTime(
    data?.createdAt,
    DATETIME_FORMAT3
  );
  milestone["startDate_date"] = getFormattedDateTime(
    data?.startDate,
    DATE_FORMAT2
  );
  milestone["lastUpdated"] = getFormattedDateTime(
    data?.updatedAt,
    DATE_FORMAT5
  );
  milestone["endDate"] = getFormattedDateTime(data?.endDate, DATE_FORMAT2);
  milestone["status"] = data?.isCompleted ?? false;
  milestone["notes"] = data?.notes ?? "";
  milestone["responsibleParties"] = manipulateMilestoneResponsiblePartyList(
    data?.responsibities
  );
  milestone["responsiblePartiesGroups"] =
    manipulateMilestoneResponsiblePartyGroup(
      manipulateMilestoneResponsiblePartyList(data?.responsibities)
    );
  milestone["visibilities"] = manipulateVisibilityList(data?.visibilities);
  milestone["permissions"] =
    manipulatePermissionsList(data?.permissions, true) ?? [];
  if (isRequest) {
    milestone["responsibleParties"] = manipulateMilestoneResponsiblePartyGroup(
      manipulateMilestoneResponsiblePartyList(data?.responsibities)
    );
    milestone["visibility"] = manipulateVisibilityList(data?.visibilities);
    milestone["status"] = data?.isCompleted ? "Completed" : "Pending";
  }
  milestone["transactionStatus"] =
    data?.TransactionDetails?.currentStatus ?? false;

  return milestone;
};

export const manipulateMilestoneList = (data) => {
  let milestones = [];
  data?.forEach((item) => {
    milestones.push(manipulateMilestoneData(item));
  });
  // milestones.filter(x => x.permissions)
  const filteredMilestones = milestones.filter((item) => {
    const viewMilestonePermission = item.permissions.find(
      (permission) => permission.slug === "can_view_milestone"
    );
    return viewMilestonePermission && item;
  });
  return milestones;
};
