import React from "react";
import { Modal, Row, Col } from "antd";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  ButtonComponent,
  VisibleToPeople,
} from "../../../../../../../components";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { deleteChecklistRequest } from "../../../../../../../redux/slicers/transactionchecklist";
import {
  getFormattedDateTime,
  toastAlert,
} from "../../../../../../../services/utils";
import { ALERT_TYPES, DATE_FORMAT1 } from "../../../../../../../constants";
import { useState } from "react";
import ChecklistVisibilityModal from "../visibilityModal";
const DeleteChecklistModal = ({
  handleClose,
  data,
  preview,
  handleSuccess,
}) => {
  //STATES
  const [selectedVisibility, setSelectedVisibility] = useState(null);
  const [visibilityModalPreview, setVisibilityModalPreview] = useState(false);

  //CUSTOM DISPATCH
  const [deleteChecklist, deleteLoader] = useCustomDispatch(
    deleteChecklistRequest
  );

  //HANDLERS

  const visiblityModalPreviewHandler = () => {
    setVisibilityModalPreview(!visibilityModalPreview);
  };

  const handleDelete = () => {
    deleteChecklist({
      pathParams: data?.slug,
      queryParams: { method: data?.isDeleted ? "restore" : "delete" },
      logic(res) {
        handleClose();
        handleSuccess && handleSuccess();
        toastAlert(res?.message, ALERT_TYPES.SUCCESS);
      },
    });
  };

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={522}
      className="delete-checklist"
      getContainer={false}>
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>{data?.isDeleted ? "Restore" : "Delete"} Checklist</h3>
            <p>
              Are You Sure To {data?.isDeleted ? "Restore" : "Delete"} Checklist
            </p>
          </div>
          <div className="checklist-detail">
            <h4>{data?.name}</h4>
            <span className={`status ${!data?.status ? "pending" : ""}`}>
              {data?.status ? "Completed" : "Pending"}
            </span>
            <div className={`progress ${!data?.status ? "pending" : ""}`} />
            <div className="config">
              <Row gutter={[10, 0]}>
                {data?.targetDate && (
                  <Col xs={12}>
                    <span>
                      Target Date:{" "}
                      <b>
                        {getFormattedDateTime(data?.targetDate, DATE_FORMAT1)}
                      </b>
                    </span>
                  </Col>
                )}
                <Col xs={12}>
                  <span>
                    Type: <b>{data?.type?.value}</b>
                  </span>
                </Col>
                <Col xs={12}>
                  <div className="visibility-wrapper">
                    <p>Visible to: </p>
                    <VisibleToPeople
                      isRoom
                      data={data?.visibilities ?? data?.visibility}
                      size={8}
                      maxLength={4}
                      borderColor={"#fff"}
                      borderWidth={2}
                      detailModalHandler={() => {
                        setSelectedVisibility(
                          data?.visibilities ?? data?.visibility
                        );
                        visiblityModalPreviewHandler();
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="cancel-btn" onClick={handleClose}>
            Cancel
          </div>
          <ButtonComponent
            onClick={handleDelete}
            text={data?.isDeleted ? "Restore" : "Delete"}
            className={`${data?.isDeleted ? "restore" : ""} delete-btn  `}
            isLoading={deleteLoader}
          />
        </div>
      </div>
      <ChecklistVisibilityModal
        preview={visibilityModalPreview}
        handleClose={visiblityModalPreviewHandler}
        data={selectedVisibility}
        isRemoveAll={false}
        isRoom={true}
      />
    </Modal>
  );
};

export default DeleteChecklistModal;
