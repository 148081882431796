import React, { useState } from "react";
import { Dropdown, Menu } from "antd";
import { Images } from "../../../../../../../themes";
import { DeleteModal, ProfileImage } from "../../../../../../../components";
import AddNoteModal from "../addNoteModal";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { deleteNoteRequest } from "../../../../../../../redux/slicers/transactiondataroom";
import { toastAlert } from "../../../../../../../services/utils";
import { useDispatch } from "react-redux";
import { setUserDetailDrawer } from "../../../../../../../redux/slicers/general";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

// NOTE CARD
function Card({
  data,
  isDataRoom,
  optionsDisabled,
  editPreviewHandler,
  deletePreviewHandler,
  isOwnerLogin,
  isTransactionComplete,
  isEditAllowed,
}) {
  // CONST VALS
  const dispatch = useDispatch();
  const { isOwner, creatorInfo, link } = data;
  const navigate = useNavigate();
  const editCondition = (!optionsDisabled && isOwner) || isOwnerLogin;
  // CUSTOM COMPONENT
  const userMenu = () => (
    <Menu
      items={[
        ...(isOwner
          ? [
              {
                key: "1",
                label: (
                  <span
                    className="working-groups-options"
                    onClick={editPreviewHandler}
                  >
                    <p>Edit</p>
                  </span>
                ),
              },
            ]
          : []),
        {
          key: "2",
          label: (
            <span
              className="working-groups-options"
              onClick={deletePreviewHandler}
            >
              <p>Delete</p>
            </span>
          ),
        },
      ]}
    />
  );

  //HANDLERS
  const handleLink = () => {
    navigate(data.link.url);
  };
  return (
    <div className={`notes-card-wrapper ${isOwner ? "owner" : ""}`}>
      <div className="card-fold" />
      <div className={`notes-card-top ${isDataRoom ? "transparent" : ""}`}>
        <div className="creator-info">
          <ProfileImage
            username={creatorInfo.username}
            color={creatorInfo.profilebg}
            isBlocked={!creatorInfo.isActive}
            image={creatorInfo.image}
            onClick={() => {
              dispatch(
                setUserDetailDrawer({
                  visibility: true,
                  id: creatorInfo.id,
                })
              );
            }}
            size={10}
          />
          <p
            className="c-p"
            onClick={() => {
              dispatch(
                setUserDetailDrawer({
                  visibility: true,
                  id: data.creatorInfo.id,
                })
              );
            }}
          >
            {creatorInfo.username}
          </p>
        </div>
        <p>{data.note}</p>
      </div>
      <div className="notes-card-bottom">
        {!isDataRoom && (
          <a href={link.url} target="_blank" className="note-link">
            {link.url}
          </a>
        )}
        <div className="time-options-wrapper">
          <p className="notes-time">{data.createdAt}</p>
          {editCondition && !isTransactionComplete && isEditAllowed && (
            <Dropdown
              getPopupContainer={(trigger) => trigger.parentElement}
              trigger={"click"}
              overlay={userMenu()}
              placement="bottom"
            >
              <Images.threeDot />
            </Dropdown>
          )}
        </div>
      </div>
    </div>
  );
}

function NotesCard({
  data,
  isDataRoom,
  onDelete,
  isOwner,
  isTransactionComplete,
  isEditAllowed,
}) {
  //STATES
  const [editModalPreview, seteditModalPreview] = useState(false);
  const [deleteModalPreview, setDeleteModalPreview] = useState(false);

  //CUSTOM DISPATCH
  const [deleteNote, isLoading] = useCustomDispatch(deleteNoteRequest);

  //HANDLERS
  const editModalPreviewHandler = () => {
    seteditModalPreview(!editModalPreview);
  };

  const deleteModalPreviewHandler = () => {
    setDeleteModalPreview(!deleteModalPreview);
  };

  const handleDelete = () => {
    deleteNote({
      pathParams: data.id,
      otherkeys: { isDataRoom },
      logic({ message }) {
        toastAlert(message);
        deleteModalPreviewHandler();
        onDelete();
      },
    });
  };
  return (
    <>
      <Card
        data={data}
        isDataRoom={isDataRoom}
        editPreviewHandler={editModalPreviewHandler}
        deletePreviewHandler={deleteModalPreviewHandler}
        isOwnerLogin={isOwner}
        isTransactionComplete={isTransactionComplete}
        isEditAllowed={isEditAllowed}
      />
      <AddNoteModal
        isEdit
        data={data}
        isDataRoom={isDataRoom}
        preview={editModalPreview}
        handleClose={editModalPreviewHandler}
      />
      <DeleteModal
        preview={deleteModalPreview}
        handleClose={deleteModalPreviewHandler}
        title={"Delete Note"}
        description={"Are you sure you want to delete this note?"}
        innerSec={<Card data={data} optionsDisabled isDataRoom={isDataRoom} />}
        isLoading={isLoading}
        handleDelete={handleDelete}
      />
    </>
  );
}

export default NotesCard;
