import _ from "lodash";
import { DEFAULT_PROFILE_COLOR } from "../constants";
import { manipulateAddressList } from "./company";

export const manipulateUserData = (data, company) => {
  if (_.isEmpty(data)) return {};
  let user = {};
  user["id"] = data?.id ?? 0;
  user["username"] = data.firstName?.trim() + " " + data.lastName?.trim();
  user["firstName"] = data?.firstName ?? "";
  user["lastName"] = data?.lastName ?? "";
  user["email"] = data?.email ?? "";
  user["image"] = data?.image ?? "";
  user["company"] = company ?? "";
  user["isAdmin"] = data.isAdmin ?? false;
  user["profilebg"] = data.bgcolor ?? data?.profilebg ?? DEFAULT_PROFILE_COLOR;
  user["isActive"] = data?.isActive && !data?.isBlock;

  return user;
};

export const manipulateUsersList = (data, company, isProfile = false) => {
  let users = [];
  data?.forEach((item) => {
    users.push(
      isProfile
        ? manipulateProfileData(item)
        : manipulateUserData(item, company)
    );
  });
  return users;
};

export const manipulateCompanyData = (data) => {
  if (_.isEmpty(data)) return {};
  let company = {};

  company["id"] = data.id ?? 1;
  company["name"] = data.name;
  company["logo"] = data.logo ?? "";
  company["logoPath"] = data.logoPath ?? "";
  company["phone"] = data.workPhone ?? "";
  company["linkedIn"] = data?.linkedIn !== "" ? data?.linkedIn : null;
  company["facebook"] = data?.facebook !== "" ? data?.facebook : null;
  company["twitter"] = data?.twitter !== "" ? data?.twitter : null;
  company["website"] = data?.website;
  company["addresses"] = manipulateAddressList(data?.addresses);

  return company;
};

export const manipulateConfirmOtpData = (data) => {
  if (_.isEmpty(data)) return {};

  if (data.isActive === false) {
    return {};
  }

  const dataObj = {
    access_token: data.accessToken,
    refresh_token: data.refreshToken,
    isEnterpriseUser: data.isAdmin,
    name: data.firstname?.trim() + " " + data.lastname?.trim(),
    email: data.email,
    profilePic: data.image,
    profilebg: data?.bgcolor ?? DEFAULT_PROFILE_COLOR,
    companyName: data.companyName ?? "",
    companyLogo: data.logo ?? "",
    user_id: data.id ?? null,
    tfa: data?.allowTFA ?? true,
    chat_id: parseInt(data?.chat_user_id) ?? null,
  };

  return dataObj;
};

export const manipulateProfileData = (data) => {
  if (_.isEmpty(data)) return {};
  let userprofile = {};

  userprofile["username"] =
    data.firstName?.trim() + " " + data.lastName?.trim();
  userprofile["id"] = data.id ?? 0;
  userprofile["chat_id"] = parseInt(data.chat_user_id) ?? 0;
  userprofile["profilePic"] = data.image ?? "";
  userprofile["profilePicPath"] = data.imagePath ?? "";
  userprofile["profilebg"] = data.bgcolor ?? DEFAULT_PROFILE_COLOR;
  userprofile["firstName"] = data?.firstName?.trim() ?? "";
  userprofile["lastName"] = data?.lastName?.trim() ?? "";
  userprofile["email"] = data?.email ?? "";
  userprofile["workPhone"] = data?.workPhone ?? false;
  userprofile["phone"] = data?.phone ?? false;
  userprofile["jobTitle"] = data?.jobTitle ?? "";
  userprofile["linkedIn"] = data?.linkedIn;
  userprofile["facebook"] = data?.facebook;
  userprofile["twitter"] = data?.twitter;
  userprofile["tfa"] = data?.allowTFA ?? false;
  userprofile["publicProfile"] = data?.privacy ?? false;
  userprofile["passwordLastmodified"] = data?.passwordUpdatedAt;
  userprofile["company"] = manipulateCompanyData(data?.company);
  userprofile["isActive"] = data?.isActive && !data?.isBlock;
  userprofile["role"] = data?.role ?? false;
  userprofile["workAddress"] = data?.companyAddress ?? {};
  userprofile["isGroupChatAdmin"] = data?.chatRoom?.isAdmin ?? false;
  userprofile["isGroupChatMember"] = data?.chatRoom?.isMember ?? false;
  return userprofile;
};
