import { call, fork, put, take } from "redux-saga/effects";
import {
  callRequest,
  ADD_DEVICE_TOKEN,
  CHANGE_PASSWORD,
  CONFIRM_OTP,
  INVITATION,
  LOGOUT,
  RESET_PASSWORD,
  SEND_OTP,
  SIGN_IN,
  SIGN_UP,
  VERIFY_OTP,
  VERIFY_USER,
  GET_PROFILE_DETAIL,
  CREATE_ADDRESS,
  UPDATE_ADDRESS,
  DELETE_ADDRESS,
  UPDATE_PROFILE,
  UPDATE_2FA,
  UPDATE_PROFLE_VISIBILITY,
  SIGN_UP_OTP,
  SEND_PHONE_OTP,
  CONFIRM_PHONE_OTP,
  CHECK_CURRENT_PASSWORD,
} from "../../config/webService";
import { ALERT_TYPES } from "../../constants";
import {
  manipulateConfirmOtpData,
  manipulateProfileData,
} from "../../dataManipulator/user";
import { manipulateAddressData } from "../../dataManipulator/company";
import { toastAlert } from "../../services/utils";
import {
  addDeviceTokenRequest,
  addDeviceTokenSuccess,
  changePasswordRequest,
  confirmOtpRequest,
  confirmOtpSuccess,
  invitationRequest,
  resetPasswordRequest,
  sendOtpRequest,
  userLogOutRequest,
  userLogOutSuccess,
  userSignInRequest,
  userSignInSuccess,
  userSignUpRequest,
  verifyOtpRequest,
  verifyUserRequest,
  getProfileDetailRequest,
  getProfileDetailSuccess,
  updateProfileDetailRequest,
  createAddressRequest,
  createAddressSuccess,
  updateAddressRequest,
  updateAddressSuccess,
  deleteAddressRequest,
  deleteAddressSuccess,
  update2faRequest,
  update2faSuccess,
  updateProfileVisibilityRequest,
  userSignUpSendOtpRequest,
  sendOtpOnNumberRequest,
  confirmNumberOTPRequest,
  confirmNumberOTPSuccess,
  checkCurrentPasswordRequest,
  sendOtpSuccess,
  sendOtpOnNumberSuccess,
} from "../slicers/user";
import { removeChatDataRequest } from "../slicers/chat";
import { resetGeneralStates } from "../slicers/general";

function* addDeviceToken() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(addDeviceTokenRequest.type);

    try {
      const response = yield call(callRequest, {
        url: ADD_DEVICE_TOKEN,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
        yield put(addDeviceTokenSuccess(response.data));
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* signUp() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(userSignUpRequest.type);

    try {
      const response = yield call(callRequest, {
        url: SIGN_UP,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* signUpSendOtp() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(userSignUpSendOtpRequest.type);

    try {
      const response = yield call(callRequest, {
        url: SIGN_UP_OTP,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* confirmOtp() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(confirmOtpRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CONFIRM_OTP,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        yield put(
          confirmOtpSuccess({
            ...manipulateConfirmOtpData(response.data),
            uniqueId: payload?.deviceToken,
          })
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* sendOtp() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(sendOtpRequest.type);

    try {
      const response = yield call(callRequest, {
        url: SEND_OTP,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        yield put(sendOtpSuccess(response?.data));
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* verifyOtp() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(verifyOtpRequest.type);

    try {
      const response = yield call(callRequest, {
        url: VERIFY_OTP,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* changePassword() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(changePasswordRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CHANGE_PASSWORD,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* verifyUser() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(verifyUserRequest.type);

    try {
      const response = yield call(callRequest, {
        url: VERIFY_USER,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* resetPassword() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(resetPasswordRequest.type);

    try {
      const response = yield call(callRequest, {
        url: RESET_PASSWORD,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* invitation() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(invitationRequest.type);

    try {
      const response = yield call(callRequest, {
        url: INVITATION,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* signIn() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(userSignInRequest.type);

    try {
      const response = yield call(callRequest, {
        url: SIGN_IN,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        yield put(
          userSignInSuccess({
            ...manipulateConfirmOtpData(response.data),
            uniqueId: payload?.deviceToken,
          })
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* logOut() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(userLogOutRequest.type);

    try {
      const response = yield call(callRequest, {
        url: LOGOUT,
        data: payload,
        queryParams,
        pathParams,
      });
      yield put(userLogOutSuccess());
      yield put(removeChatDataRequest());
      yield put(resetGeneralStates());
      responseCallback?.(true, response);
    } catch (err) {
      yield put(userLogOutSuccess());
      yield put(removeChatDataRequest());
      responseCallback?.(true, err);
    }
  }
}

function* getProfileDetail() {
  while (true) {
    const {
      payload: { responseCallback },
    } = yield take(getProfileDetailRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_PROFILE_DETAIL,
      });
      if (response.status) {
        yield put(
          getProfileDetailSuccess(manipulateProfileData(response.data))
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updateProfile() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(updateProfileDetailRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPDATE_PROFILE,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* update2fa() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(update2faRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPDATE_2FA,
        data: payload,
      });
      if (response.status) {
        yield put(update2faSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updateProfileVisibility() {
  while (true) {
    const {
      payload: { responseCallback },
    } = yield take(updateProfileVisibilityRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPDATE_PROFLE_VISIBILITY,
      });
      if (response.status) {
        responseCallback?.(true, response);
        toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* createAddress() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(createAddressRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CREATE_ADDRESS,
        data: payload,
      });
      if (response.status) {
        yield put(createAddressSuccess(manipulateAddressData(response.data)));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updateAddress() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(updateAddressRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPDATE_ADDRESS,
        data: payload,
        pathParams,
      });
      if (response.status) {
        yield put(updateAddressSuccess(manipulateAddressData(response.data)));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* deleteAddress() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(deleteAddressRequest.type);

    try {
      const response = yield call(callRequest, {
        url: DELETE_ADDRESS,
        pathParams,
      });
      if (response.status) {
        yield put(deleteAddressSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* sendPhoneOtp() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(sendOtpOnNumberRequest.type);

    try {
      const response = yield call(callRequest, {
        url: SEND_PHONE_OTP,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        yield put(sendOtpOnNumberSuccess(response?.data));
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* confirmPhoneOtp() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(confirmNumberOTPRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CONFIRM_PHONE_OTP,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        yield put(confirmNumberOTPSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* checkCurrentPassword() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(checkCurrentPasswordRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CHECK_CURRENT_PASSWORD,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

export default function* root() {
  yield fork(addDeviceToken);
  yield fork(signUp);
  yield fork(signUpSendOtp);
  yield fork(confirmOtp);
  yield fork(sendOtp);
  yield fork(verifyOtp);
  yield fork(changePassword);
  yield fork(resetPassword);
  yield fork(verifyUser);
  yield fork(invitation);
  yield fork(signIn);
  yield fork(logOut);
  yield fork(getProfileDetail);
  yield fork(updateProfile);
  yield fork(update2fa);
  yield fork(updateProfileVisibility);
  yield fork(createAddress);
  yield fork(updateAddress);
  yield fork(deleteAddress);
  yield fork(sendPhoneOtp);
  yield fork(confirmPhoneOtp);
  yield fork(checkCurrentPassword);
}
