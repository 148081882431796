import React, { useState } from "react";
import { AutoComplete, Form } from "antd";
import { inputFieldRule } from "../../services/utils";
import "./styles.scss";
import ProfileImage from "../ProfileImage";

const SelectBox = ({
  name = "companyName",
  options = [{ value: "", text: "" }],
  onselectOptionHandler,
  resetCompanyRelatedFields,
  companyName,
  setCompanyName,
  disabled,
  setSearchText,
  placeholder,

  ...rest
}) => {
  //STATES
  const [dropdownOpen, setdropdownOpen] = useState(false);

  return (
    <Form.Item
      rules={inputFieldRule({
        name: "Company Name",
        isRequired: companyName === "",
      })}
      name={name}
      className={`custom-trans-select required-field ${disabled ? "input-disabled" : ""
        }`}
    >
      <AutoComplete
        open={dropdownOpen}
        maxLength={80}
        // options={options}
        value={companyName}
        className={`ant-input-affix-wrapper `}
        onChange={(text) => {
          resetCompanyRelatedFields(text);
          setCompanyName(text);
        }}
        disabled={disabled}
        placeholder={placeholder}
        onSelect={(selectedValue) => {
          setCompanyName(selectedValue);
          onselectOptionHandler(selectedValue);
          setdropdownOpen(false);
        }}
        filterOption={(inputValue, option) =>
          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        onSearch={(val) => {
          if (val !== "") {
            setdropdownOpen(true);
            setSearchText(val);
            return;
          }
          setdropdownOpen(false);
        }}
        getPopupContainer={(trigger) => trigger.parentElement}
        dropdownClassName="company-autocomplete-dropdown"
        onBlur={() => setdropdownOpen(false)}
      >
        {options?.map((data) => (
          <AutoComplete.Option value={data.value} key={data.value}>
            <div className="autocomplete-option-box">
              <ProfileImage
                image={data.logo}
                username={data.value}
                size={10}
                classname="thumb"
              />
              <div className="name">
                {data?.value}
              </div>
            </div>
          </AutoComplete.Option>
        ))}
      </AutoComplete>
    </Form.Item>
  );
};

export default SelectBox;
