import React, { useState } from "react";
import {
  EditDeleteDropdown,
  ProfileImage,
} from "../../../../../../../../components";
import {
  DeleteDocumentModal,
  EditExhibitModal,
  ShareDocModal,
  VersionPreviewModal,
} from "../../../components";
import { Images } from "../../../../../../../../themes";
import { downloadImage } from "../../../../../../../../helpers/downloadImage";
import { Tooltip } from "antd";
import { setUserDetailDrawer } from "../../../../../../../../redux/slicers/general";
import { useDispatch } from "react-redux";

function ExhibitCard({
  data,
  roomAccess,
  handleSuccess,
  isEditAllowed,
  isShareAllowed,
}) {
  //STATES
  const [activeVersions, setVersions] = useState([]);
  const [activeExhibits, setExhibits] = useState([]);
  const [selectedChild, setSelectedChild] = useState([]);
  const [selectedData, setselectedData] = useState(null);
  const [isVersionSelected, setVersionSelected] = useState(false);
  const [deleteModalPreview, setDeleteModalPreview] = useState(false);
  const [editModalPreview, seteditModalPreview] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [shareModalPreview, setShareModalPreview] = useState(false);

  //CONST VALS
  const isVersionActive = activeVersions.includes(data.id);
  const isExhibitActive = activeExhibits.includes(data.id);
  const isVersionDisabled = data.versions.length < 1;
  const isExhibitDisabled = data.exhibits.length < 1;
  const dispatch = useDispatch();

  //HANDLERS
  const setVersionHandler = (id) => {
    const isExist = activeVersions.find((x) => x === id);
    if (isExist) {
      let temp = [...activeVersions];
      const index = temp.indexOf(isExist);
      temp.splice(index, 1);
      setVersions(temp);
      return;
    }
    setVersions([...activeVersions, id]);
  };

  const setExhibitHandler = (id) => {
    const isExist = activeExhibits.find((x) => x === id);
    if (isExist) {
      let temp = [...activeExhibits];
      const index = temp.indexOf(isExist);
      temp.splice(index, 1);
      setExhibits(temp);
      return;
    }
    setExhibits([...activeExhibits, id]);
  };

  const childOptionHandler = (key, id) => {
    const isExist = selectedChild.find((x) => x.id === id);
    if (isExist) {
      let temp = [...selectedChild];
      const index = temp.indexOf(isExist);
      if (isExist.key === key) {
        temp.splice(index, 1);
        setSelectedChild(temp);
      } else {
        temp[index] = { id, key };
        setSelectedChild(temp);
      }
      return;
    }
    setSelectedChild([...selectedChild, { id, key }]);
  };

  const editModalHandler = (data, isVersion) => {
    setVersionSelected(isVersion);
    setselectedData(data);
    seteditModalPreview(true);
  };

  const editModalPreviewHandler = () => {
    seteditModalPreview(!editModalPreview);
  };

  const deleteModalHandler = (data, isVersion) => {
    setselectedData(data);
    setVersionSelected(isVersion);
    setDeleteModalPreview(true);
  };

  const deleteModalPreviewHandler = () => {
    setDeleteModalPreview(!deleteModalPreview);
  };

  const downloadHandler = (file) => {
    downloadImage(file, setDownloadLoader, file);
  };

  const shareModalHandler = (data) => {
    setselectedData(data);
    setShareModalPreview(true);
  };

  const previewModalHandler = (data) => {
    !previewModal && setselectedData(data);
    setPreviewModal(!previewModal);
  };

  const shareModalPreviewHandler = () => {
    setShareModalPreview(!shareModalPreview);
  };

  const userDetailHandler = (userid) => {
    dispatch(
      setUserDetailDrawer({
        visibility: true,
        id: userid,
      })
    );
  };

  //CUSTOM COMPONENTS
  const attachmentWrapper = (index, item, isVersion, isExhibit, isChild) => {
    const isActiveVersion = selectedChild.find(
      (x) => x.id === item.id && x.key === 1
    );
    const isActiveExhibit = selectedChild.find(
      (x) => x.id === item.id && x.key === 0
    );

    const isDropdownActive = isActiveVersion || isActiveExhibit;

    const expandExhibitCondition = item?.exhibits?.length > 0;
    const expandVersionCondition = item?.versions?.length > 0;
    const toolTipCondition = item?.name?.length > 36;
    return (
      <div
        className={`attachment-wrapper ${isChild ? "child-attach" : ""}`}
        key={index}
      >
        <div className="attachment-info-wrapper">
          <div className="attachment-left-wrapper">
            <div className="doc-icon">
              <Images.DocThumb />
            </div>
            <div className="doc-detail">
              {isVersion && (
                <p className="uploaded-text">
                  {`Upload From ${item?.uploadedFrom} By `}
                  <ProfileImage
                    username={item.uploadedBy?.username}
                    image={item.uploadedBy?.image}
                    isBlocked={!item.uploadedBy?.isActive}
                    color={item.uploadedBy?.profilebg}
                    size={4}
                    onClick={() => {
                      userDetailHandler(item.uploadedBy?.id);
                    }}
                  />
                  <span className="username">
                    {" " + item.uploadedBy?.username}
                  </span>
                </p>
              )}
              <Tooltip
                title={item.name}
                trigger={`${toolTipCondition && "hover"} `}
              >
                <h3 className={`${toolTipCondition ? "c-p" : ""}`}>
                  {item.name}
                </h3>
              </Tooltip>
              <p>{item.createdAt}</p>
            </div>
          </div>
          {!roomAccess ? (
            !isEditAllowed && !isVersion ? (
              <></>
            ) : (
              <EditDeleteDropdown
                isEditable={isEditAllowed ? true : false}
                isDelete={isEditAllowed ? true : false}
                isDownload={isVersion}
                isShare={isVersion && isShareAllowed}
                isPreview={isVersion}
                isDownloadLoading={downloadLoader}
                onDownloadClick={() => {
                  downloadHandler(item?.file);
                }}
                onDeleteClick={() => {
                  deleteModalHandler(item, isVersion);
                }}
                onEditClick={() => {
                  editModalHandler(item, isVersion);
                }}
                onShareClick={() => {
                  shareModalHandler(item);
                }}
                onPreviewClick={() => {
                  previewModalHandler(item);
                }}
              />
            )
          ) : (
            <>
              {isVersion && (
                <EditDeleteDropdown
                  isDelete={isEditAllowed ? true : false}
                  isEditable={false}
                  isDownload={true}
                  isShare={false}
                  isDownloadLoading={downloadLoader}
                  onDownloadClick={() => {
                    downloadHandler(item?.file);
                  }}
                />
              )}
            </>
          )}
          {/* <Images.threeDot className="c-p" /> */}
        </div>
        {isExhibit && (
          <>
            <div className="child-option-wrapper">
              <div
                className={`option c-p ${
                  isActiveVersion ? "active-option" : ""
                } ${!expandVersionCondition ? "option-disabled" : ""}`}
                onClick={() =>
                  expandVersionCondition && childOptionHandler(1, item.id)
                }
              >
                <p>{item?.versions?.length ?? 0} Versions</p>
                <Images.DownArrow />
              </div>
              <div
                className={`option c-p ${isActiveExhibit ? "active-option" : ""}
                ${!expandExhibitCondition ? "option-disabled" : ""}
                `}
                onClick={() =>
                  expandExhibitCondition && childOptionHandler(0, item.id)
                }
              >
                <p>{item?.exhibits?.length ?? 0} Exhibits</p>
                <Images.DownArrow />
              </div>
            </div>
            {isDropdownActive &&
              (isActiveExhibit
                ? item.exhibits.map((item, index) =>
                    attachmentWrapper(index, item, false, true, true)
                  )
                : item.versions.map((item, index) =>
                    attachmentWrapper(index, item, true, false, true)
                  ))}
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="exhibit-card-wrapper">
        <div className="uploader-info">
          <ProfileImage
            username={data.uploadedBy?.username}
            image={data.uploadedBy?.image}
            isBlocked={!data.uploadedBy?.isActive}
            color={data.uploadedBy?.profilebg}
            size={14}
          />
          <div className="name">
            <h3>{data.uploadedBy?.username}</h3>
            <p>{data.createdAt}</p>
          </div>
        </div>
        {attachmentWrapper(0, data, false)}
        <div
          className={`option c-p ${isVersionActive ? "active-option" : ""} ${
            isVersionDisabled ? "option-disabled" : ""
          }`}
          onClick={() => !isVersionDisabled && setVersionHandler(data.id)}
          style={{ marginBottom: "6px" }}
        >
          <p>{data.versions.length ?? 0} Versions</p>
          <Images.DownArrow />
        </div>
        {isVersionActive &&
          data.versions.map((item, index) =>
            attachmentWrapper(index, item, true)
          )}
        <div
          className={`option c-p ${isExhibitActive ? "active-option" : ""} ${
            isExhibitDisabled ? "option-disabled" : ""
          }`}
          onClick={() => !isExhibitDisabled && setExhibitHandler(data.id)}
        >
          <p>{data.exhibits.length ?? 0} Exhibits</p>
          <Images.DownArrow />
        </div>
        {isExhibitActive &&
          data.exhibits.map((item, index) =>
            attachmentWrapper(index, item, false, true)
          )}
      </div>
      <DeleteDocumentModal
        data={selectedData}
        preview={deleteModalPreview}
        handleSuccess={handleSuccess}
        handleClose={deleteModalPreviewHandler}
        isVersion={isVersionSelected}
        isExhibit={!isVersionSelected}
      />
      <EditExhibitModal
        preview={editModalPreview}
        handleClose={editModalPreviewHandler}
        handleSuccess={handleSuccess}
        data={selectedData}
        isVersion={isVersionSelected}
      />
      <ShareDocModal
        data={selectedData}
        preview={shareModalPreview}
        handleClose={shareModalPreviewHandler}
      />
      <VersionPreviewModal
        preview={previewModal}
        handleClose={previewModalHandler}
        data={selectedData}
        handleEdit={() => {
          setVersionSelected(true);
          editModalPreviewHandler();
        }}
        isEditAllowed={isEditAllowed}
        roomAccess={roomAccess}
      />
    </>
  );
}

export default ExhibitCard;
