import React, { useState, useEffect } from "react";
import { Modal, Select } from "antd";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import {
  getDataroomUsersRequest,
  updateDataroomPermissionRequest,
} from "../../../../../../../redux/slicers/transactiondataroom";
import {
  CardLoader,
  CustomInfiniteScroll,
  EmptyBox,
  ProfileImage,
} from "../../../../../../../components";
import { Images } from "../../../../../../../themes";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DATA_PER_PAGE_LIMIT } from "../../../../../../../constants";
import { setUserDetailDrawer } from "../../../../../../../redux/slicers/general";
import "./styles.scss";

function DataroomPermissionModal({ handleClose, preview, permissionLoader }) {
  //STATES
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchtext, setSearchText] = useState("");
  const [isMoreData, setMoreData] = useState(false);

  //REDUX DATA
  const { permissions, userPermissions } = useSelector(
    (state) => state.transactiondataroom
  );

  //CUSTOM DISPATCH
  const [getUsers, userLoader] = useCustomDispatch(getDataroomUsersRequest);
  const [updatePermission, updateLoader] = useCustomDispatch(
    updateDataroomPermissionRequest
  );

  //CONST VALS
  const isLoading = userLoader || permissionLoader;
  const { dataid } = useParams();
  const dispatch = useDispatch();

  //HOOKS
  useEffect(() => {
    if (preview) {
      getUsers({
        queryParams: {
          roomSlug: dataid,
          limit: DATA_PER_PAGE_LIMIT,
          offset: offset,
          search: searchtext,
        },
        logic(res) {
          setTotal(res.count);
        },
      });
    }
  }, [preview, offset, searchtext]);

  //HANDLERS
  const closeHandler = () => {
    handleClose();
  };

  const handleSearch = (val) => {
    setOffset(0);
    setSearchText(val);
    setMoreData(false);
  };

  const handlePermissionChange = (key, id) => {
    updatePermission({
      pathParams: dataid,
      payload: { visibleUserId: id, permissionKey: key },
    });
  };

  const handleNextData = () => {
    setOffset(offset + DATA_PER_PAGE_LIMIT);
    setMoreData(true);
  };

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      getContainer={false}
      width={525}
      className="permission-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <Images.CloseIcon className="c-p" />
          </div>
          <div className="title">
            <h3>Permissions</h3>
            <p>Edit Data Room Permissions</p>
          </div>
          <div className="search-box">
            <input
              type="text"
              placeholder={"Search Here"}
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
            />
          </div>
          <div className="room-users-wrapper">
            {isLoading && !isMoreData ? (
              <CardLoader height={260} />
            ) : userPermissions?.length > 0 ? (
              <CustomInfiniteScroll
                dataLength={userPermissions.length}
                total={total}
                isWholePage={false}
                next={handleNextData}
                maxHeight={360}
              >
                {userPermissions.map((item, index) => {
                  const isOdd = index % 2 === 0;
                  return (
                    <div
                      className={`permission-item ${isOdd ? "odd" : ""}`}
                      key={index}
                    >
                      <div className="user-info">
                        <ProfileImage
                          image={item?.image}
                          username={item?.username}
                          color={item?.bgColor}
                          isBlocked={!item?.isActive}
                          size={12}
                          onClick={() =>
                            dispatch(
                              setUserDetailDrawer({
                                visibility: true,
                                id: item?.id,
                              })
                            )
                          }
                        />
                        <div className="info">
                          <h3>{item?.username}</h3>
                          <p>{item?.email}</p>
                        </div>
                      </div>
                      <Select
                        options={permissions}
                        defaultValue={item.permission}
                        getPopupContainer={(trigger) => trigger.parentElement}
                        dropdownClassName="permission-dropdown"
                        className="dropdown-select"
                        showSearch={false}
                        suffixIcon={<Images.SelectArrow />}
                        placement="bottomRight"
                        onSelect={(val) => {
                          handlePermissionChange(val, item.id);
                        }}
                        loading={updateLoader}
                      />
                    </div>
                  );
                })}
              </CustomInfiniteScroll>
            ) : (
              <EmptyBox imgwidth={85} size={14} height={260} />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default DataroomPermissionModal;
