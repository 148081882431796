import React, { useEffect, useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, Input, Modal, Row, Select, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ButtonComponent, ProfileImage } from "../../../../../components";
import { TRANSACTION_DETAIL_ROUTE } from "../../../../../constants";
import { useCustomDispatch } from "../../../../../helpers/useCustomDispatch";
import {
  createBreakoutRoomRequest,
  getEnterpriseMembersRequest,
  getTransactionEnterprisesRequest,
  updateBreakoutRoomRequest,
} from "../../../../../redux/slicers/transactiondetail";
import { Images } from "../../../../../themes";
import { useNavigate } from "react-router-dom";
import {
  inputFieldRule,
  replaceValInString,
  toastAlert,
} from "../../../../../services/utils";
import { getSideNavTransactionsRequest } from "../../../../../redux/slicers/transactions";
import "./styles.scss";
import { setUserDetailDrawer } from "../../../../../redux/slicers/general";

const BreakoutRoomModal = ({
  handleClose,
  preview,
  title,
  selectedData,
  isRoom,
}) => {
  // STATES
  const [openMember, setOpenMember] = useState("");
  const [isEnterpriseSelected, setEnterpriseSelected] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);

  // REDUX DATA
  const { id, slug } = useSelector((state) => state.transactiondetail.data);
  const {
    transactionenterprises: transactionCompanies,
    transactionenterprisemembers: members,
  } = useSelector((state) => state.transactiondetail);

  // CUSTOM DISPATCH
  const [createBreakoutRoom, createLoader] = useCustomDispatch(
    createBreakoutRoomRequest
  );
  const [updateBreakoutRoom, updateLoader] = useCustomDispatch(
    updateBreakoutRoomRequest
  );
  const [getTransactionEnterprises] = useCustomDispatch(
    getTransactionEnterprisesRequest
  );
  const [getCompanyMembers] = useCustomDispatch(getEnterpriseMembersRequest);
  const [getSideNavTransactions] = useCustomDispatch(
    getSideNavTransactionsRequest
  );

  // CONST VALS
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const isLoading = createLoader || updateLoader;
  const isMemberInputDisabled = isEnterpriseSelected && members.length < 1;
  const companyid = Form.useWatch("enterprise", form);
  const companymembers = selectedMembers.filter((x) => x.company === companyid);
  const selectAllCondition = companymembers.length === members.length;
  const dispatch = useDispatch();

  // HELPERS
  const requestHelper = (request, payload, pathParams) => {
    request({
      payload,
      pathParams,
      logic({ message, data }) {
        handleClose();
        toastAlert(message);
        if (!selectedData) {
          const vals = { ":id": slug, ":room": data.slug };
          navigate(replaceValInString(TRANSACTION_DETAIL_ROUTE, vals));
        }
        form.resetFields();
        getSideNavTransactions({ queryParams: { limit: 5 } });
        setSelectedMembers([]);
      },
    });
  };

  // HOOKS
  useEffect(() => {
    if (id && preview) {
      getTransactionEnterprises({
        pathParams: id,
      });
    }
    return () => {
      setSelectedMembers([]);
      setEnterpriseSelected(false);
      form.resetFields();
    };
  }, [id, preview]);

  useEffect(() => {
    if (selectedData) {
      form.setFieldsValue({
        title: isRoom ? selectedData.name : selectedData.room.name,
      });
    }
  }, [selectedData]);

  // HANDLERS
  const handleSubmit = () => {
    const values = form.getFieldsValue();
    const payload = {
      name: values.title,
      transactionId: id,
    };
    if (!selectedData) {
      const memberIds = selectedMembers.map((value) => value.id);
      payload["memberIds"] = memberIds;
      requestHelper(createBreakoutRoom, payload);
      return;
    }
    requestHelper(
      updateBreakoutRoom,
      payload,
      isRoom ? selectedData.slug : selectedData.room.slug
    );
  };
  const handleGetMembersByEnterprise = (company_id) => {
    setEnterpriseSelected(true);
    const queryParams = {
      transactionId: id,
      companyId: company_id,
      isActive: true,
    };
    getCompanyMembers({
      queryParams,
    });
  };
  const handleRemoveMember = (index, isName) => {
    const nextFieldVal = [];
    const tempdata = isName
      ? selectedMembers.filter((x) => x.username !== index)
      : selectedMembers.filter((x) => x.id !== index);
    setSelectedMembers(tempdata);
    const updatedmembers = tempdata.map((x) => x.username);
    tempdata.forEach((item) => nextFieldVal.push(item.username));
    form.setFieldsValue({
      members: updatedmembers,
    });
  };

  const removeDuplicates = (array) => {
    const set = new Set(array);
    const arr = Array.from(set);
    return arr;
  };

  const handleChange = (val) => {
    let temp = [];
    const prevmembers = form.getFieldValue("members");
    const prevselectedmembers = prevmembers.filter((x) => x !== "all");

    if (val.includes("none")) {
      const compnanymembers = [...selectedMembers].filter(
        (x) => x.company !== companyid
      );
      const updatedmembers = compnanymembers.map((x) => x.username);
      setSelectedMembers(compnanymembers);
      form.setFieldsValue({ members: updatedmembers });
    } else if (val.includes("all")) {
      members.forEach((item) => temp.push(item.username));
      setSelectedMembers(removeDuplicates([...members, ...selectedMembers]));
      form.setFieldsValue({
        members: removeDuplicates([...prevselectedmembers, ...temp]),
      });
    }
  };

  // CUSTOM COMPONENTS
  const memberInput = () => (
    <span className="select-form-item">
      <Form.Item
        name="members"
        style={{ width: "100%" }}
        rules={inputFieldRule({
          name: "Member",
          isWhiteSpace: false,
          requiredMessage: "Atleast one member is required",
        })}
      >
        <Select
          disabled={isMemberInputDisabled}
          mode="multiple"
          placeholder="User Name"
          className="form-select"
          filterOption={(input, option) =>
            (option.label === "Select All") |
            (option.label === "De-Select All") |
            option.label.toLowerCase().includes(input.toLowerCase())
          }
          open={openMember}
          onClick={() => {
            setOpenMember(!openMember);
          }}
          onFocus={() => setOpenMember(true)}
          onBlur={() => setOpenMember(false)}
          onSearch={() => setOpenMember(true)}
          onChange={(e) => {
            setOpenMember(false);
            handleChange(e);
          }}
          onSelect={(val) => {
            if (val !== "all" && val !== "none") {
              const userdetail = members.find((x) => x.username === val);
              setSelectedMembers([...selectedMembers, userdetail]);
            }
          }}
          onDeselect={(val) => {
            handleRemoveMember(val, true);
          }}
          maxTagCount={4}
          optionLabelProp="label"
          maxTagTextLength={10}
        >
          <Select.Option
            values={selectAllCondition ? "none" : "all"}
            key={selectAllCondition ? "none" : "all"}
            label={selectAllCondition ? "De-Select All" : "Select All"}
          >
            {selectAllCondition ? "De-Select All" : "Select All"}
          </Select.Option>
          {members.map((item, index) => (
            <Select.Option
              value={item.username}
              key={index}
              label={item.username}
            >
              <div className="invite-modal-user-autocomplete-options">
                <ProfileImage
                  color={item.profilebg}
                  image={item.image}
                  username={item.username}
                  isBlocked={!item?.isActive}
                  size={10}
                  classname="thumb"
                />
                <div className="option-user-info">
                  <h3>{item.username}</h3>
                  <p>{item.email}</p>
                </div>
              </div>{" "}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Tooltip
        title="After selecting participants from one enterprise you can switch enterprises to select more participants from other enterprises."
        placement="bottomLeft"
        overlayClassName="create-breakout-tooltip"
        color="#F8FAFB"
      >
        <Images.Info style={{ cursor: "pointer" }} />
      </Tooltip>
    </span>
  );
  const memberDetailBox = (data, index) => {
    return (
      <div className="member-box" key={index}>
        <ProfileImage
          image={data?.image}
          username={data?.username}
          isBlocked={!data?.isActive}
          color={data?.profilebg}
          onClick={() => {
            dispatch(
              setUserDetailDrawer({
                visibility: true,
                id: data.id,
              })
            );
          }}
          size={10}
        />
        <span className="user-details">
          <b>{data?.username}</b>
          <p>{data?.email}</p>
        </span>
        <span
          className="close-btn"
          onClick={() => {
            handleRemoveMember(data?.id);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>
    );
  };

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={522}
      className="create-breakout-room"
      getContainer={false}
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div
            className="close-btn"
            onClick={() => {
              handleClose();
              form.resetFields();
              setSelectedMembers([]);
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>{title}</h3>
            {/* {!selectedData && <p>Fill The Details Below</p>} */}
          </div>
          <Form form={form} onFinish={handleSubmit}>
            <Row gutter={[0, 15]} wrap={true}>
              <Col xs={24}>
                <Form.Item
                  name="title"
                  rules={inputFieldRule({
                    name: "Room name",
                    isMax: true,
                    max: 80,
                  })}
                >
                  <Input
                    placeholder="Name of Breakout Room"
                    className="form-select"
                    autoFocus
                  />
                </Form.Item>
              </Col>
              {!selectedData && (
                <>
                  <h3 className="add-user-label">Add User</h3>
                  <Col xs={24} className="select-form-item">
                    <Form.Item
                      name="enterprise"
                      style={{ width: "100%" }}
                      rules={inputFieldRule({
                        name: "Enterprise",
                        isWhiteSpace: false,
                      })}
                    >
                      <Select
                        // defaultValue={selectedParties}
                        placeholder="Add Enterprise"
                        className="form-select"
                        filterOption={(input, option) =>
                          option.children.props.children[1].props.children
                            ?.toLowerCase()
                            ?.includes(input.toLowerCase())
                        }
                        showSearch
                        onSelect={(val, option) => {
                          handleGetMembersByEnterprise(option.value);
                        }}
                      >
                        {transactionCompanies.map((item, index) => (
                          <Select.Option
                            value={item.id}
                            key={index}
                            className="company-selection-item"
                          >
                            <span className="company-select-options">
                              <ProfileImage
                                image={item.logo}
                                username={item.name}
                                size={10}
                              />
                              <p>{item.name}</p>
                            </span>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Tooltip
                      title="Participants listed in the below field will be based on the enterprise selected here."
                      placement="bottomLeft"
                      overlayClassName="create-breakout-tooltip"
                      color="#F8FAFB"
                    >
                      <Images.Info style={{ cursor: "pointer" }} />
                    </Tooltip>
                  </Col>
                  <Col xs={24}>
                    {isEnterpriseSelected && isMemberInputDisabled ? (
                      <Tooltip
                        title="No participant from this enterprise is part of the transaction."
                        placement="bottomLeft"
                      >
                        {memberInput()}
                      </Tooltip>
                    ) : (
                      memberInput()
                    )}
                    <div className="selected-partymembers">
                      {selectedMembers.map((item, index) =>
                        memberDetailBox(item, index)
                      )}
                    </div>
                  </Col>
                </>
              )}
            </Row>
            <div className="modal-footer">
              <Form.Item>
                <ButtonComponent
                  text={selectedData ? "Update Room" : "Create Room"}
                  isLoading={isLoading}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default BreakoutRoomModal;
