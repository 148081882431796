import React from "react";
import { Form, Input } from "antd";
import { PASSWORD } from "../../constants";
import {
  inputFieldRule,
  isPasswordValid,
  customPassIcons,
} from "../../services/utils";

export default function PasswordFields({ autoFocus = false }) {
  return (
    <>
      <Form.Item
        className="required-field"
        rules={[
          ...inputFieldRule({
            name: PASSWORD.PASSWORD_ERROR,
            isWhiteSpace: false,
          }),
          () => ({
            validator(_, value) {
              if (!value || isPasswordValid(value)) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(PASSWORD.INVALID_STRONG_PASSWORD)
              );
            },
          }),
        ]}
        name={PASSWORD.PASSWORD_NAME}
      >
        <Input.Password
          iconRender={customPassIcons}
          prefix={PASSWORD.PASSWORD_ICON}
          name={PASSWORD.PASSWORD_NAME}
          placeholder={PASSWORD.PASSWORD_PLACEHOLDER}
          maxLength={30}
          autoFocus={autoFocus}
        />
      </Form.Item>
      <Form.Item
        className="required-field"
        dependencies={[PASSWORD.PASSWORD_NAME]}
        name={PASSWORD.CONFIRM_PASS_NAME}
        rules={[
          ...inputFieldRule({
            name: PASSWORD.CONFIRM_PASS_ERROR,
            isWhiteSpace: false,
          }),
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("The two passwords that you entered do not match!")
              );
            },
          }),
        ]}
      >
        <Input.Password
          iconRender={customPassIcons}
          prefix={PASSWORD.CONFIRM_PASS_ICON}
          name={PASSWORD.CONFIRM_PASS_NAME}
          placeholder={PASSWORD.CONFIRM_PASS_PLACEHOLDER}
          maxLength={30}
        />
      </Form.Item>
    </>
  );
}
