import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { EmptyBox } from "../../../../../../../../components";
import { ExhibitCard } from "../components";

function Exhibits({
  data,
  isData,
  handleSuccess,
  roomAccess,
  isEditAllowed,
  isShareAllowed,
}) {
  return (
    <div className="exhibits-wrapper">
      {isData ? (
        <ResponsiveMasonry columnsCountBreakPoints={{ 1440: 2 }}>
          <Masonry gutter="10px">
            {data.map((item, index) => (
              <ExhibitCard
                data={item}
                key={index}
                handleSuccess={handleSuccess}
                roomAccess={roomAccess}
                isEditAllowed={isEditAllowed}
                isShareAllowed={isShareAllowed}
              />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      ) : (
        <EmptyBox height={280} text="No Exhibits Found" />
      )}
    </div>
  );
}

export default Exhibits;
