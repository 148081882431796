import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { DataRoomDashboard, DataRoomLinks, DataRoomDetail } from "./partials";
import {
  PERMISSIONS_ACCESS,
  TRANSACTION_LINK_DATAROOM_SLUG,
} from "../../../../../constants";
import "./styles.scss";
import { getActionPermission } from "../../../../../services/utils";

export default function DataRoom({ transactionslug, roomslug }) {
  // REDUX DATA
  const { data: transactiondetail } = useSelector(
    (state) => state.transactiondetail
  );
  //CONST VALS
  const { dataid, folder } = useParams();
  const isRoom =
    !_.isEmpty(transactiondetail) && roomslug !== transactiondetail.roomSlug;
  const { TRANSACTION_ACTIVITY_LOGS, VIEW_LINK, MANAGE_LINK } =
    PERMISSIONS_ACCESS;
  const isLinkViewAllowed = getActionPermission(VIEW_LINK);
  const isLinkManageAllowed = getActionPermission(MANAGE_LINK);
  const activityLogsVisible = getActionPermission(TRANSACTION_ACTIVITY_LOGS);
  const isTransactionComplete = transactiondetail?.status;

  // CUSTOM COMPONENETS
  const StepComponent = useMemo(() => {
    if (dataid === TRANSACTION_LINK_DATAROOM_SLUG) {
      return (
        <DataRoomLinks
          transactionslug={transactionslug}
          isLinkManageAllowed={isLinkManageAllowed && !isTransactionComplete}
          linkActivityLogsVisible={activityLogsVisible}
          isTransactionComplete={isTransactionComplete}
        />
      );
    }
    if (dataid || folder) {
      return (
        <DataRoomDetail
          transactionslug={transactionslug}
          slug={folder || dataid}
          isFolder={folder}
          roomslug={dataid}
          activityLogsVisible={activityLogsVisible}
          isTransactionComplete={isTransactionComplete}
        />
      );
    }
    return (
      <DataRoomDashboard
        isRoom={isRoom}
        roomslug={roomslug}
        transactionslug={transactionslug}
        isLinkViewAllowed={isLinkViewAllowed}
        activityLogsVisible={activityLogsVisible}
        isTransactionComplete={isTransactionComplete}
      />
    );
  }, [dataid, isRoom, roomslug, folder, isTransactionComplete]);

  return <section className="data-room">{StepComponent}</section>;
}
