import React, { useState } from "react";
import { Col, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { Images } from "../../../../../themes";
import {
  ActivityLogs,
  ButtonComponent,
  DisabledTooltip,
} from "../../../../../components";
import { CreateDocumentsModal, ShareDocModal } from "./components";
import { DocumentDetail, DocumentsDashboard } from "./partials";
import {
  PERMISSIONS_ACCESS,
  ROOM_ACCESS_TYPES,
} from "../../../../../constants";
import "./styles.scss";
import { getActionPermission } from "../../../../../services/utils";

const Documents = ({
  isDeleted,
  roomAccess,
  checkUserStatusHandler,
  checkApiUserStatusHandler,
}) => {
  //STATES
  const [createModalPreview, setCreateModalPreview] = useState(false);
  const [shareModalPreview, setShareModalPreview] = useState(false);
  const [isDataUpdated, setDataUpdated] = useState(false);

  //CONST VALS
  const { room, dataid } = useParams();
  const navigate = useNavigate();
  const isSubView = isDeleted || dataid;
  const deletedRoomView = roomAccess !== ROOM_ACCESS_TYPES.DELETED;

  const LISTING_ACTIVITYLOGS_PARAMS = {
    isDocument: true,
    room,
  };
  const DETAIL_ACTIVITYLOGS_PARAMS = {
    document: dataid,
  };

  const { ADD_DOCS, TRANSACTION_ACTIVITY_LOGS, EDIT_DOCS, DELETE_DOCS } =
    PERMISSIONS_ACCESS;
  const isEditAllowed = getActionPermission(EDIT_DOCS);
  const isCreateAllowed = getActionPermission(ADD_DOCS);
  const isDeleteAllowed = getActionPermission(DELETE_DOCS);
  const activityLogsVisible = getActionPermission(TRANSACTION_ACTIVITY_LOGS);

  //HANDLERS
  const setDataUpdatedHandler = () => {
    setDataUpdated(!isDataUpdated);
  };
  const createModalPreviewHandler = () => {
    setCreateModalPreview(!createModalPreview);
  };
  const shareModalPreviewHandler = () => {
    setShareModalPreview(!shareModalPreview);
  };

  // CUSTOM COMPONENT
  const topHeader = () => (
    <div className={`top-container ${isSubView ? "subpage-header" : ""}`}>
      {isSubView && (
        <Images.BackArrow className="c-p" onClick={() => navigate(-1)} />
      )}
      <div className="top-heading">
        <h3>{isDeleted ? "Deleted " : ""}Documents</h3>
        {isSubView && <p>View Details Below</p>}
      </div>
      {!isSubView && !roomAccess ? (
        <div className="doc-btn-container">
          <DisabledTooltip
            innerSec={
              <ButtonComponent
                text="Share Documents"
                className="share-btn"
                onClick={isCreateAllowed && shareModalPreviewHandler}
              />
            }
            visible={!isCreateAllowed}
          />
          <DisabledTooltip
            innerSec={
              <ButtonComponent
                text="Add Documents +"
                onClick={isCreateAllowed && createModalPreviewHandler}
              />
            }
            visible={!isCreateAllowed}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
  return (
    <section className={"transaction-detail-documents"}>
      <Row>
        <Col xs={24} xl={18}>
          {topHeader()}
          <div className="card">
            {dataid ? (
              <DocumentDetail
                roomAccess={roomAccess}
                checkUserStatusHandler={checkUserStatusHandler}
                isEditAllowed={isEditAllowed}
                isDeleteAllowed={isDeleteAllowed}
                isShareAllowed={isCreateAllowed}
              />
            ) : (
              <DocumentsDashboard
                roomAccess={roomAccess}
                checkUserStatusHandler={checkUserStatusHandler}
                updateDataHandler={setDataUpdatedHandler}
                isUpdated={isDataUpdated}
                isDeleted={isDeleted}
                isEditAllowed={isEditAllowed}
                isDeleteAllowed={isDeleteAllowed}
                isShareAllowed={isCreateAllowed}
              />
            )}
          </div>
        </Col>
        <Col xs={24} xl={6}>
          {deletedRoomView && (
            <div
              className={`activtybox-wrapper ${roomAccess ? "disabled" : ""}`}
            >
              <ActivityLogs
                title="Documents"
                height={300}
                isWholePage
                isDataUpdated={dataid}
                roomAccess={roomAccess}
                checkUserStatusHandler={checkUserStatusHandler}
                parentFunc={checkApiUserStatusHandler}
                customqueryParams={
                  dataid
                    ? DETAIL_ACTIVITYLOGS_PARAMS
                    : LISTING_ACTIVITYLOGS_PARAMS
                }
                isAccess={activityLogsVisible}
              />
            </div>
          )}
        </Col>
      </Row>
      <CreateDocumentsModal
        handleSuccess={setDataUpdatedHandler}
        handleClose={createModalPreviewHandler}
        preview={createModalPreview}
      />
      <ShareDocModal
        preview={shareModalPreview}
        handleClose={shareModalPreviewHandler}
      />
    </section>
  );
};
export default Documents;
