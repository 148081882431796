import React, { useEffect, useRef, useState } from "react";
import { Form } from "antd";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import OtpInput from "react-otp-input";
import { useNavigate, useParams } from "react-router-dom";
import { ApprovalRequestModal, ButtonComponent } from "../../../components";
import Header from "../../../components/header";
import {
  DASHBOARD_ROUTE,
  LOGIN_ROUTE,
  RESETPASS_ROUTE,
  COPYRIGHTSTRING,
  TWO_FACTOR_KEYS,
} from "../../../constants";
import { useCustomDispatch } from "../../../helpers/useCustomDispatch";
import {
  confirmOtpRequest,
  sendOtpRequest,
  userSignUpSendOtpRequest,
  verifyOtpRequest,
} from "../../../redux/slicers/user";
import { inputFieldRule } from "../../../services/utils";
import images from "../../../themes/images";
import { v4 as uuidv4 } from "uuid";
import "../styles.scss";
import { useSelector } from "react-redux";

const EmailVerification = ({
  useremail,
  fromSignup,
  selectedOption,
  userid,
  closeHandler,
}) => {
  // STATES
  const [approvalModal, setApprovalModal] = useState(false);
  const [enableResendBtn, setEnableResendBtn] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [confirmOtpResponse, setConfirmOtpResponse] = useState({});
  const [counterKey, setCounterKey] = useState(0);
  const [_otp, setOtp] = useState("");

  //REDUX DATA
  const { otpId } = useSelector((state) => state.user);

  // CUSTOM DISPATCH
  const [confirmOtpReq, confirmOtpReqLoader] =
    useCustomDispatch(confirmOtpRequest);
  const [verifyForgotPassOtpReq, verifyForgotPassOtpReqLoader] =
    useCustomDispatch(verifyOtpRequest);
  const [resendOtp] = useCustomDispatch(userSignUpSendOtpRequest);
  const [sendOtpReq] = useCustomDispatch(sendOtpRequest);

  // CONST VALS
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { token } = useParams();
  const deviceToken = uuidv4();

  // REFS
  const _otpRef = useRef(null);

  // HANDLERS
  const handleOtpFormFinish = (values) => {
    const payload = { email: useremail, otp: +values.otp };
    if (!fromSignup) {
      if (selectedOption === "phone") {
        payload["request_id"] = otpId;
      }
      verifyForgotPassOtpReq({
        payload,
        logic(response) {
          navigate(RESETPASS_ROUTE, {
            replace: true,
            state: { email: payload.email, otp: payload.otp },
          });
        },
      });

      return;
    }

    payload["action"] = "signup";
    payload["deviceToken"] = deviceToken;
    if (token) {
      payload["transactionInviteRequestId"] = token;
    }
    confirmOtpReq({
      payload,
      logic(response) {
        setConfirmOtpResponse(response.data);
        if (response.data.isActive) {
          navigate(DASHBOARD_ROUTE, { replace: true });
        } else if (response.data.isActive === false) {
          setApprovalModal(true);
        }
      },
    });
  };

  const resendHandler = () => {
    setIsFirstTime(false);
    const logic = () => {
      setCounterKey((prevKey) => prevKey + 1);
      setEnableResendBtn(false);
    };

    if (!fromSignup) {
      const payload = {
        id: userid,
        key: selectedOption,
        to: "reset",
        too: TWO_FACTOR_KEYS.RESEND,
      };
      if (selectedOption === "phone") {
        payload["request_id"] = otpId;
      }
      sendOtpReq({
        payload: payload,
        logic: ({ data }) => logic(data.otp),
      });
      return;
    }

    resendOtp({
      payload: { email: useremail },
      logic: ({ data }) => logic(data.otp),
    });
  };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <span>00:00</span>;
    }

    return (
      <span className="timer-text">
        0{Math.floor((remainingTime % 3600) / 60)}:
        {remainingTime < 10 ? "0" + (remainingTime % 60) : remainingTime % 60}
      </span>
    );
  };

  //HOOKS
  useEffect(() => {
    if (_otp?.length === 6) {
      handleOtpFormFinish({ otp: _otp });
    }
  }, [_otp]);
  return (
    <>
      <Header />
      <section className="signing-wrapper">
        <div className="form-wrapper verification">
          <div className="back-btn">
            <span onClick={closeHandler}>
              <img src={images.backArrow} alt="back Arraow" /> Back
            </span>
          </div>
          <h3 className="auth-title">
            <span>
              Please Check Your
              {selectedOption === "phone" ? " Phone Number" : " Email"}
            </span>
          </h3>
          <p className="auth-subtitle">
            Please retrieve the code and enter below
          </p>
          <Form form={form} className="form" onFinish={handleOtpFormFinish}>
            <Form.Item
              rules={inputFieldRule({ name: "Otp", isWhiteSpace: false })}
              name="otp"
              className="otp-wrapper"
            >
              <OtpInput
                ref={_otpRef}
                isInputNum={true}
                shouldAutoFocus={true}
                className={`otpCodeInput custom-otp-input`}
                numInputs={6}
                value={_otp}
                separator={<span></span>}
                onChange={(e) => setOtp(e)}
              />
            </Form.Item>
            <div className="register-here">
              <span style={{ opacity: 0.7, fontSize: "16px" }}>
                {useremail}
              </span>
              <label onClick={closeHandler}> not you?</label>
            </div>
            <div className="time-container flex-center flex-column mt-25">
              <CountdownCircleTimer
                isPlaying
                key={counterKey}
                duration={59}
                colors={"#6F7CED"}
                size={100}
                trailColor={"transparent"}
                colorsTime={[7, 5, 2, 0]}
                onComplete={(a) => {
                  setEnableResendBtn(true);
                }}
              >
                {renderTime}
              </CountdownCircleTimer>
              <p
                onClick={resendHandler}
                style={
                  enableResendBtn
                    ? {}
                    : {
                        pointerEvents: "none",
                        opacity: 0.5,
                      }
                }
              >
                Resend Code
              </p>
            </div>
            <Form.Item>
              <ButtonComponent
                className="login-btn"
                isLoading={confirmOtpReqLoader || verifyForgotPassOtpReqLoader}
                text="Verify"
              />
            </Form.Item>
          </Form>
        </div>
        <div>
          <p className="copyright-text">{COPYRIGHTSTRING}</p>
        </div>
      </section>
      <ApprovalRequestModal
        modalPreview={approvalModal}
        data={{
          username:
            confirmOtpResponse.firstname + " " + confirmOtpResponse.lastname,
          email: confirmOtpResponse.email,
          jobTitle: confirmOtpResponse.jobTitle,
          phone: confirmOtpResponse.phone,
          thumb: confirmOtpResponse.image,
          profilebg: confirmOtpResponse.bgcolor,
        }}
        handleClose={() => {
          setApprovalModal(false);
          navigate(LOGIN_ROUTE, { replace: true });
        }}
      />
    </>
  );
};

export default EmailVerification;
