import React from "react";
import { DEFAULT_PROFILE_COLOR } from "../../constants";
import { getLetterFromName } from "../../services/utils";
import { Images } from "../../themes";
import "./styles.scss";

function ProfileImage({
  username,
  image,
  color = DEFAULT_PROFILE_COLOR,
  size = 14,
  classname = "",
  border,
  isBlocked = false,
  fontColor = "#fff",
  ...rest
}) {
  return (
    <div
      className={`thumb profile-thumbbox c-p ${classname}`}
      style={{
        backgroundColor: !image && color,
        fontSize: size,
        border: border,
        color: fontColor ?? "",
      }}
      {...rest}
    >
      {isBlocked && <Images.UserBlock className="blocked-icon" />}
      {image ? <img src={image} alt={username} /> : getLetterFromName(username)}
    </div>
  );
}

export default ProfileImage;
