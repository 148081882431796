import React from "react";
import { Link } from "react-router-dom";
import { CONTACTUS_ROUTE } from "../../../../constants";
import "./styles/contactBanner.scss";

function ContactBanner() {
  return (
    <section className="home-contact-sec">
      <div className="container">
        <div className="contact_us_sec">
          <h3>Don't Get Left Behind Learn More Now</h3>
          <Link to={CONTACTUS_ROUTE}>Contact Us</Link>
        </div>
      </div>
    </section>
  );
}

export default ContactBanner;
