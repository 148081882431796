import React, { useState } from "react";
import { Row, Col, Tooltip } from "antd";
import { Images } from "../../../../../../../themes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { CreateChecklistModal } from "../../components";
import {
  CardLoader,
  DisabledTooltip,
  EmptyBox,
} from "../../../../../../../components";
import { PERMISSIONS_ACCESS } from "../../../../../../../constants";
import { getActionPermission } from "../../../../../../../services/utils";

const Suggestions = ({ data, isLoading, handleDataUpdate }) => {
  //STATES
  const [isOpen, setOpen] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [createModalPreview, setCreateModalPreview] = useState(null);

  //CONST VALS
  const { ADD_CHECKLIST } = PERMISSIONS_ACCESS;
  const createPermission = getActionPermission(ADD_CHECKLIST);

  //HANDLERS
  const setOpenHandler = () => {
    setOpen(!isOpen);
  };

  const createModalPreviewHandler = (item) => {
    selectedTemplate ? setSelectedTemplate(null) : setSelectedTemplate(item);
    setCreateModalPreview(!createModalPreview);
  };

  return (
    <div className="suggestions-box card">
      <h2 onClick={setOpenHandler}>
        Suggested Checklist Items
        <button className={isOpen ? "active" : ""}>
          <FontAwesomeIcon icon={faChevronDown} />
        </button>
      </h2>
      <div className={`suggestions-list ${isOpen ? "active" : ""}`}>
        {/* {isLoading ? (
          <CardLoader height={151} />
        ) : ( */}
        <Row gutter={[24, 0]}>
          {data?.map((item, index) => {
            const toolTipCondition =
              item?.name?.length >= 30 && createPermission;
            return (
              <Col xs={16} md={8} key={index}>
                <DisabledTooltip
                  innerSec={
                    <div className="suggestion-card">
                      <div className="suggestion-card-info">
                        <Tooltip
                          title={item?.name}
                          trigger={`${toolTipCondition && "hover"} `}
                        >
                          <h4 className={`${toolTipCondition ? "c-p" : ""}`}>
                            {item?.name}
                          </h4>
                        </Tooltip>
                        <p>Type: {item?.type?.value}</p>
                      </div>
                      <Images.SuggestionPlus
                        className="c-p edit"
                        onClick={() =>
                          createPermission && createModalPreviewHandler(item)
                        }
                      />
                    </div>
                  }
                  visible={!createPermission}
                />
              </Col>
            );
          })}
        </Row>
        {/* )} */}
      </div>
      <CreateChecklistModal
        preview={createModalPreview}
        handleClose={createModalPreviewHandler}
        data={selectedTemplate}
        handleSucess={handleDataUpdate}
      />
    </div>
  );
};

export default Suggestions;
