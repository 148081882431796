import React, { useState, useEffect } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Input, Modal } from "antd";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { uploadImageRequest } from "../../../../../../../redux/slicers/general";
import {
  createDocumentRequest,
  updateDocumentRequest,
} from "../../../../../../../redux/slicers/transactiondataroom";
import {
  documentValidation,
  inputFieldRule,
  toastAlert,
} from "../../../../../../../services/utils";
import { ButtonComponent, CardLoader } from "../../../../../../../components";
import { FileUploader } from "react-drag-drop-files";
import { FILE_TYPES } from "../../../../../../../constants";
import { Images } from "../../../../../../../themes";
import "./styles.scss";

function UploadDocumentModal({
  handleClose,
  preview,
  isEdit,
  isFolder,
  roomid,
  data,
  handleSuccess = () => {},
}) {
  //STATES
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [file, setFile] = useState(null);
  const [fileName, setfileName] = useState(null);

  // CUSTOM DISPATCH
  const [uploadImage, uploadLoading] = useCustomDispatch(uploadImageRequest);
  const [createDocument, createLoading] = useCustomDispatch(
    createDocumentRequest
  );
  const [updateDocument, updateLoading] = useCustomDispatch(
    updateDocumentRequest
  );

  //CONST VALS
  const [form] = Form.useForm();
  const isLoading = createLoading || updateLoading;

  // HELPERS
  const requestHelper = (request, payload, pathParams) => {
    request({
      payload,
      pathParams,
      logic({ message }) {
        toastAlert(message);
        closeHandler();
        handleSuccess();
      },
    });
  };

  // HOOKS
  useEffect(() => {
    if (isEdit && preview) {
      form.setFieldsValue({
        file: data.name,
      });
    }
  }, [isEdit, preview]);

  //HANDLERS
  const closeHandler = () => {
    handleClose();
    form.resetFields();
    setFile(null);
    setfileName(null);
    setButtonDisabled(true);
  };

  const handleChange = (file) => {
    if (documentValidation(file)) {
      const data = new FormData();
      data.append("file", file);
      data.append("directory", "users");
      uploadImage({
        payload: data,
        logic({ key }) {
          setFile(key);
          setfileName(file.name);
        },
      });
    }
  };

  const setButtonDisabledHandler = () => {
    setButtonDisabled(false);
  };

  const handleFormFinish = (values) => {
    values["url"] = file;
    if (isEdit) {
      requestHelper(updateDocument, values, data.slug);
    } else {
      values[isFolder ? "dataRoomFolderId" : "dataRoomId"] = roomid;
      requestHelper(createDocument, values);
    }
  };

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={525}
      className="upload-document-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>{isEdit ? "Edit" : "Upload"} File</h3>
            <p>{isEdit ? "Edit" : "Add"} Details Below</p>
          </div>
          <Form
            form={form}
            className="form"
            onFinish={handleFormFinish}
            onFieldsChange={setButtonDisabledHandler}
          >
            {!isEdit && (
              <Form.Item
                name={"url"}
                rules={inputFieldRule({
                  name: "File",
                  isWhiteSpace: false,
                })}
              >
                <div className={`upload-doc-box ${file ? "active" : ""}`}>
                  {uploadLoading ? (
                    <CardLoader size={14} height="100%" />
                  ) : (
                    <>
                      <FileUploader
                        classes="drop_area"
                        handleChange={handleChange}
                        name="file"
                        types={FILE_TYPES}
                      />
                      <div className="content">
                        <Images.Upload />
                        <p>{fileName ?? "Drag and Drop File From Computer"}</p>
                      </div>
                    </>
                  )}
                </div>
              </Form.Item>
            )}
            <Form.Item
              name={"file"}
              rules={inputFieldRule({
                name: "Name",
                isMax: true,
                max: 100,
              })}
            >
              <Input placeholder="File Name" autoFocus />
            </Form.Item>
            <div className="create-button">
              <Form.Item>
                <ButtonComponent
                  disabled={isButtonDisabled}
                  isLoading={isLoading}
                  text={isEdit ? "Update" : "Upload"}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
}

export default UploadDocumentModal;
