import React, { useState, useEffect } from "react";
import { Form, Input, Modal } from "antd";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import {
  createNoteRequest,
  updateNoteRequest,
} from "../../../../../../../redux/slicers/transactiondataroom";
import { ButtonComponent } from "../../../../../../../components";
import {
  toastAlert,
  inputFieldRule,
} from "../../../../../../../services/utils";
import "./styles.scss";

function AddNoteModal({
  handleClose,
  preview,
  data,
  isEdit,
  isDataRoom,
  isFolder,
  linkid,
  roomid,
  handleSuccess = () => {},
}) {
  // STATES
  const [isButtonDisabled, setButtonDisabled] = useState(true);

  // CUSTOM DISPATCH
  const [createNote, createLoading] = useCustomDispatch(createNoteRequest);
  const [updateNote, updateLoading] = useCustomDispatch(updateNoteRequest);

  //CONST VALS
  const [form] = Form.useForm();
  const isLoading = createLoading || updateLoading;

  // HELPERS
  const requestHelper = (request, payload, pathParams) => {
    request({
      payload,
      pathParams,
      otherkeys: { isDataRoom },
      logic({ message }) {
        toastAlert(message);
        closeHandler();
        handleSuccess();
      },
    });
  };
  //HOOKS
  useEffect(() => {
    if (isEdit && preview) {
      form.setFieldsValue({
        note: data.note,
      });
    }
  }, [isEdit, preview]);

  //HANDLERS
  const closeHandler = () => {
    handleClose();
    form.resetFields();
    setButtonDisabled(true);
  };
  const setButtonDisabledHandler = () => {
    setButtonDisabled(false);
  };
  const handleFormFinish = (values) => {
    if (isEdit) {
      requestHelper(updateNote, values, data.id);
    } else {
      values[isFolder ? "folderId" : isDataRoom ? "dataRoomId" : "linkId"] =
        isDataRoom ? roomid : linkid;
      requestHelper(createNote, values);
    }
  };
  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      getContainer={false}
      width={490}
      className="add-notes-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>{isEdit ? "Update" : "Add"} Note</h3>
            <p>{isEdit ? "Edit" : "Add"} Details Below</p>
          </div>
          <Form
            form={form}
            className="form"
            onFinish={handleFormFinish}
            onFieldsChange={setButtonDisabledHandler}
          >
            <Form.Item
              name="note"
              rules={inputFieldRule({
                name: "Note",
                isMax: true,
                max: 1000,
              })}
            >
              <Input.TextArea
                placeholder="Type here"
                className="form-select textarea"
                autoFocus
              />
            </Form.Item>
            <div className="create-button">
              <Form.Item>
                <ButtonComponent
                  disabled={isButtonDisabled}
                  isLoading={isLoading}
                  text={isEdit ? "Update" : "Add +"}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
}

export default AddNoteModal;
