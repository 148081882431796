import React from "react";
import { Modal } from "antd";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.scss";
import { Images } from "../../../../../themes";
import { useCustomDispatch } from "../../../../../helpers/useCustomDispatch";
import { getChangeRequestsListRequest } from "../../../../../redux/slicers/transactiondetail";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useSelection from "antd/lib/table/hooks/useSelection";
import { useDispatch, useSelector } from "react-redux";
import { CardLoader, EmptyBox } from "../../../../../components";
import { setPendingApprovalRequestModal } from "../../../../../redux/slicers/general";

const RequestsListModal = ({ handleClose, preview }) => {
  //CONST VALS
  const { id } = useParams();
  const dispatch = useDispatch();
  //REDUX DATA
  const { requests } = useSelector((state) => state.transactiondetail);

  //CUSTOM DISPATCH
  const [getRequests, requestLoader] = useCustomDispatch(
    getChangeRequestsListRequest
  );

  //HANDLERS
  const handleRequestDetails = (item) => {
    handleClose();
    dispatch(
      setPendingApprovalRequestModal({ id: item?.id, isAdmin: item?.isAdmin })
    );
  };

  //HOOKS
  useEffect(() => {
    if (preview) {
      getRequests({ pathParams: id });
    }
  }, [preview]);

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={430}
      className="requests-list-modal">
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <Images.CloseIcon />
          </div>
          <div className="title">
            <h3>Request Changes by you</h3>
          </div>
          <div className="request-list">
            {requestLoader ? (
              <CardLoader height={200} />
            ) : requests.length > 0 ? (
              <div className="list-wrapper">
                {requests?.map((item) => (
                  <div
                    className="list-item c-p"
                    key={item?.id}
                    onClick={() => handleRequestDetails(item)}>
                    <div className="item-logo">
                      <Images.MilestoneSolid />
                    </div>
                    <div>
                      <h3>{item?.title}</h3>
                      <p>View More Detail</p>
                    </div>
                    <Images.ArrowSolid className="nav-arrow" />
                  </div>
                ))}
              </div>
            ) : (
              <EmptyBox height={200} />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RequestsListModal;
