// @flow
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  milestones: [],
  data: {},
};

const MilestonesReducer = createSlice({
  name: "milestones",
  initialState,
  reducers: {
    // MILESTONES LISTING
    getMileStonesRequest() {},
    getMileStonesSuccess(state, action) {
      let temp = [...state.milestones];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.milestones = action.payload.data;
        return;
      }
      state.milestones = temp;
    },

    // MILESTONE DETAIL
    getMileStoneDetailRequest() {},
    getMileStoneDetailSuccess(state, action) {
      state.data = action.payload;
    },

    // CREATE MILESTONE
    createMileStoneRequest() {},

    // UPDATE MILESTONE
    updateMileStoneRequest() {},
    updateMileStoneSuccess(state, action) {
      let temp = [...state.milestones];
      const dataindex = temp.findIndex((x) => x.id == action.payload.id);
      temp[dataindex] = action.payload;
      state.milestones = temp;
      state.data = action.payload;
    },

    // CHANGE MILESTONE STATUS
    changeMileStoneStatusRequest() {},
    changeMileStoneStatusSuccess(state, action) {
      let temp = [...state.milestones];
      const dataindex = temp.findIndex((x) => x.id == action.payload.id);
      temp[dataindex] = action.payload;
      state.milestones = temp;
      state.data = action.payload;
    },

    // ASSIGN RESPONSIBLE PARTIES IN MILESTONE
    assignResponsiblePartiesRequest() {},

    // ASSIGN VISIBILITY TO ROOMS
    assignVisibilityToRoomsRequest() {},

    // REMOVE RESPONSIBLE PARTIES IN MILESTONE
    removeResponsiblePartiesRequest() {},

    // REMOVE VISBILITY IN MILESTONE
    removeVisibilitiesRequest() {},

    // DELETE MILESTONE
    deleteMileStoneRequest() {},
    deleteMileStoneSuccess() {},
  },
});

export const {
  getMileStonesRequest,
  getMileStonesSuccess,
  getMileStoneDetailRequest,
  getMileStoneDetailSuccess,
  createMileStoneRequest,
  updateMileStoneRequest,
  updateMileStoneSuccess,
  changeMileStoneStatusRequest,
  changeMileStoneStatusSuccess,
  assignResponsiblePartiesRequest,
  removeResponsiblePartiesRequest,
  deleteMileStoneRequest,
  deleteMileStoneSuccess,
  assignVisibilityToRoomsRequest,
  removeVisibilitiesRequest,
} = MilestonesReducer.actions;

export default MilestonesReducer.reducer;
