// @flow
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
const initialState = {
  data: {},
  profile: {},
  isError: null,
  deviceToken: null,
  otpId: null,
};

const UserReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    // ADD DEVICE TOKEN
    addDeviceTokenRequest() {},
    addDeviceTokenSuccess(state, action) {
      state.data.access_token = action.payload.accessToken;
      state.data.refresh_token = action.payload.refreshToken;
    },

    addChatDeviceTokenRequest(state, action) {
      state.deviceToken = action?.payload?.payload?.deviceToken;
    },
    // GET REFRESH TOKEN
    refreshToken(state, action) {
      state.data.access_token = action.payload.accessToken;
      state.data.refresh_token = action.payload.refreshToken;
    },

    // AUTH ERROR
    setAuthError(state, action) {
      state.isError = action.payload;
    },
    removeAuthError(state) {
      state.isError = null;
    },

    // SIGNUP
    userSignUpRequest() {},

    // SEND OTP
    userSignUpSendOtpRequest() {},

    // CONFIRM OTP
    confirmOtpRequest() {},
    confirmOtpSuccess(state, action) {
      state.data = action.payload;
    },

    // SEND OTP ON MOBILE
    sendOtpOnNumberRequest() {},
    sendOtpOnNumberSuccess(state, action) {
      state.otpId = action.payload.request_id;
    },
    // CONFIRM NUMBER OTP
    confirmNumberOTPRequest() {},
    confirmNumberOTPSuccess(state, action) {
      if (action.payload.isWorkPhone) {
        state.profile.company.phone = action.payload.phone;
      } else {
        state.profile.phone = action.payload.phone;
      }
    },
    // SEND AGAIN OTP
    sendOtpRequest() {},
    sendOtpSuccess(state, action) {
      state.otpId = action.payload.request_id;
    },

    // VERIFY OTP
    verifyOtpRequest() {},

    // RESET PASSWORD
    resetPasswordRequest() {},

    // CHANGE PASSWORD
    changePasswordRequest() {},

    // VERIFY USER
    verifyUserRequest() {},

    // SEND INVITATION
    invitationRequest() {},

    // LOGIN
    userSignInRequest() {},
    userSignInSuccess(state, action) {
      if (!action.payload?.tfa) {
        state.data = action.payload;
      }
    },

    // LOGOUT
    userLogOutRequest() {},
    userLogOutSuccess(state) {
      state.data = {};
      state.profile = {};
    },

    // ENTERPRISE USER
    setEnterpriseUser(state, action) {
      state.data.isEnterpriseUser = action.payload;
    },

    // CHECK CURRENT PASSWORD
    checkCurrentPasswordRequest() {},

    // LOGIN USER PROFILE
    getProfileDetailRequest() {},
    getProfileDetailSuccess(state, action) {
      state.profile = action.payload;
      state.data.name = action.payload.username;
      state.data.profilePic = action.payload.profilePic;
      state.data.companyName = action.payload.company?.name;
      state.data.companyLogo = action.payload.company?.logo;
    },

    //  UPDATE PROFILE
    updateProfileDetailRequest() {},

    //  CHANGE 2FA
    update2faRequest() {},
    update2faSuccess(state, action) {
      state.data.tfa = action.payload;
    },

    //  CHANGE PROFILE VISIBILITY
    updateProfileVisibilityRequest() {},

    // CREATE ADDRESS
    createAddressRequest() {},
    createAddressSuccess(state, action) {
      state.profile.company.addresses.push(action.payload);
    },

    // UPDATE ADDRESS
    updateAddressRequest() {},
    updateAddressSuccess(state, action) {
      const addressIndex = _.findIndex(state.profile.company.addresses, {
        id: action.payload.id,
      });
      state.profile.company.addresses[addressIndex] = action.payload;
    },

    // DELETE ADDRESS
    deleteAddressRequest() {},
    deleteAddressSuccess(state, action) {
      const addressIndex = _.findIndex(state.profile.company.addresses, {
        id: action.payload,
      });
      if (addressIndex >= 0) {
        state.profile.company.addresses.splice(addressIndex, 1);
      }
    },

    //UPDATE ENTERPRISE ADMIN DATA
    updateEnterpriseAdminDataRequest(state, action) {
      state.data.isEnterpriseUser = action.payload.isAdmin;
      state.data.companyName = action.payload.name;
      state.data.companyLogo = action.payload.logo;
    },
  },
});

export const {
  addDeviceTokenRequest,
  addDeviceTokenSuccess,
  refreshToken,
  setAuthError,
  setEnterpriseUser,
  removeAuthError,
  userLogOutSuccess,
  userSignUpRequest,
  userSignUpSendOtpRequest,
  confirmOtpRequest,
  confirmOtpSuccess,
  sendOtpRequest,
  sendOtpSuccess,
  verifyOtpRequest,
  changePasswordRequest,
  update2faRequest,
  update2faSuccess,
  updateProfileVisibilityRequest,
  verifyUserRequest,
  resetPasswordRequest,
  invitationRequest,
  userSignInRequest,
  userSignInSuccess,
  userLogOutRequest,
  getProfileDetailRequest,
  getProfileDetailSuccess,
  updateProfileDetailRequest,
  createAddressRequest,
  createAddressSuccess,
  updateAddressRequest,
  updateAddressSuccess,
  deleteAddressRequest,
  deleteAddressSuccess,
  checkCurrentPasswordRequest,
  sendOtpOnNumberRequest,
  sendOtpOnNumberSuccess,
  confirmNumberOTPRequest,
  confirmNumberOTPSuccess,
  updateEnterpriseAdminDataRequest,
  addChatDeviceTokenRequest,
} = UserReducer.actions;

export default UserReducer.reducer;
