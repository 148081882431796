import React from "react";
import { Images } from "../../themes";
import "./styles.scss";

function NoAccessBox({
  height = 300,
  imgwidth = 100,
  size = 16,
  text = "Access Restricted",
}) {
  return (
    <div className="no-access-box" style={{ height: height }}>
      <img src={Images.NoAccess} style={{ width: imgwidth }} alt="" />
      <h4 style={{ fontSize: size }}>{text}</h4>
    </div>
  );
}

export default NoAccessBox;
