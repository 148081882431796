import React from "react";
import { ButtonComponent } from "../../../components";
import { SIGNUP_ROUTE } from "../../../constants";
import { Images } from "../../../themes";
import { Features, ContactBanner, Reviews } from "./components";
import "./styles.scss";

function Home() {
  //CONST VALS
  const heroContent = [
    "Single view of all transactions in play",
    "Structured data set to fuel next generation learning about transactions",
    "Automation of repetitive administrative tasks",
    "Knowledge that helps everyone perform better",
  ];
  return (
    <div className="home-wrapper">
      <section className="hero-section">
        <div className="container">
          <div className="content">
            <h1>
              DealTru Digitizes <span>Transactions</span>
            </h1>
            <h3>
              Your <span>Deal</span> Hub
            </h3>
            {/* <p>Organize your deals, gain insight, save time and money</p> */}

            <div className="list-wrap">
              <div className="list-inner-wrap">
                <h6 className="list-title">
                  Investment Bankers, Brokers, Lawyers, Accountants and Other
                  Deal Professionals are Experiencing the Benefits:
                </h6>

                <ul className="hero-bullets">
                  {heroContent?.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
              <ButtonComponent
                text="Get Started"
                isLink
                link={SIGNUP_ROUTE}
                className="get-started-btn"
              />
            </div>

            {/* <img src={Images.heroImage} alt="Dummy" /> */}
          </div>

          <div className="home-hero-img">
            <div className="bg-opacity"></div>
          </div>
        </div>
        <div className="blur-effect" />
      </section>
      <Features />
      <ContactBanner />
      <Reviews />
    </div>
  );
}

export default Home;
