import React, { useState, useEffect } from "react";
import { Images } from "../../../../../../../themes";
import { useNavigate, useParams } from "react-router-dom";
import {
  ButtonComponent,
  CardLoader,
  DisabledTooltip,
  Tabs,
} from "../../../../../../../components";
import { DOCUMENT_DETAIL_TABS } from "../../../../../../../constants";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { getDocumentDetailRequest } from "../../../../../../../redux/slicers/transactiondocuments";
import { Exhibits, Versions } from "./partials";
import {
  UploadDocumentsModal,
  CreateDocumentsModal,
  DeleteDocumentModal,
} from "../../components";
import "./styles.scss";
import { useSelector } from "react-redux";

function DocumentDetail({
  roomAccess,
  checkUserStatusHandler,
  isDeleteAllowed,
  isEditAllowed,
  isShareAllowed,
}) {
  //STATES
  const [selectedTab, setSelectedTab] = useState(DOCUMENT_DETAIL_TABS[0].id);
  const [isDataUpdated, setDataUpdated] = useState(false);
  const [uploadModalPreview, setUploadModalPreview] = useState(false);
  const [deleteModalPreview, setDeleteModalPreview] = useState(false);
  const [editModalPreview, seteditModalPreview] = useState(false);
  const [isAllowed, setAllowed] = useState(true);

  // REDUX DATA
  const { data } = useSelector((state) => state.transactiondocuments);

  // CUSTOM DISPATCH
  const [getDocumentDetail, isLoading] = useCustomDispatch(
    getDocumentDetailRequest
  );

  //CONST VALS
  const { dataid } = useParams();
  const navigate = useNavigate();

  // HOOKS
  useEffect(() => {
    getDocumentDetail({
      pathParams: dataid,
      error() {
        setAllowed(false);
      },
    });
  }, [isDataUpdated]);

  //HANDLERS
  const deleteDataHandler = () => {
    navigate(-1);
  };
  const updateDataHandler = () => {
    setDataUpdated(!isDataUpdated);
  };
  const uploadModalPreviewHandler = () => {
    setUploadModalPreview(!uploadModalPreview);
  };
  const editModalPreviewHandler = () => {
    seteditModalPreview(!editModalPreview);
  };
  const deleteModalPreviewHandler = () => {
    setDeleteModalPreview(!deleteModalPreview);
  };

  return (
    <div className="detail-wrapper">
      {isLoading ? (
        <CardLoader height={450} size={20} />
      ) : (
        <>
          <div className="top-box">
            <div className="thumb">
              <Images.DocThumb />
            </div>
            <h3>{data?.name}</h3>
            <div className="action-btns">
              {!roomAccess && isAllowed && (
                <>
                  <DisabledTooltip
                    innerSec={
                      <Images.Edit
                        onClick={isEditAllowed && editModalPreviewHandler}
                      />
                    }
                    visible={!isEditAllowed}
                  />
                  {/* <Images.Eye /> */}
                  <DisabledTooltip
                    innerSec={
                      <Images.Delete
                        className="del-icon"
                        onClick={isDeleteAllowed && deleteModalPreviewHandler}
                      />
                    }
                    visible={!isDeleteAllowed}
                  />
                </>
              )}
            </div>
          </div>
          <div className="note-box">
            <h3>Notes</h3>
            <p>{data?.notes}</p>
          </div>
          <div className="exhibits-version-wrapper">
            <div className="options-wrapper">
              <div className="left">
                <h3>Documents</h3>
                <Tabs
                  buttonsList={DOCUMENT_DETAIL_TABS}
                  setState={setSelectedTab}
                  state={selectedTab}
                  className="options-tabs"
                />
              </div>
              {!roomAccess && isAllowed && (
                <DisabledTooltip
                  innerSec={
                    <ButtonComponent
                      text="Add +"
                      className="add-btn"
                      onClick={isEditAllowed && uploadModalPreviewHandler}
                    />
                  }
                  visible={!isEditAllowed}
                />
              )}
            </div>
            {selectedTab === DOCUMENT_DETAIL_TABS[0].id ? (
              <Exhibits
                handleSuccess={updateDataHandler}
                data={data.exhibits}
                isData={data.exhibitCount >= 1}
                roomAccess={roomAccess}
                isEditAllowed={isEditAllowed}
                isShareAllowed={isShareAllowed}
              />
            ) : (
              <Versions
                handleSuccess={updateDataHandler}
                data={data}
                versions={data.versions}
                isData={data.versionCount >= 1}
                owner={data?.uploadedBy}
                roomAccess={roomAccess}
                isEditAllowed={isEditAllowed}
                isShareAllowed={isShareAllowed}
              />
            )}
          </div>
        </>
      )}
      <UploadDocumentsModal
        selecteddoc={dataid}
        handleSuccess={updateDataHandler}
        preview={uploadModalPreview}
        handleClose={uploadModalPreviewHandler}
      />
      <DeleteDocumentModal
        data={data}
        preview={deleteModalPreview}
        handleSuccess={deleteDataHandler}
        handleClose={deleteModalPreviewHandler}
      />
      <CreateDocumentsModal
        isUpdate={true}
        data={data}
        preview={editModalPreview}
        handleSuccess={updateDataHandler}
        handleClose={editModalPreviewHandler}
      />
    </div>
  );
}

export default DocumentDetail;
