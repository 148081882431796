import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Modal } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ButtonComponent, ProfileImage } from "../../../../../../../components";
import { setUserDetailDrawer } from "../../../../../../../redux/slicers/general";
import { getLetterFromName } from "../../../../../../../services/utils";
import { Images } from "../../../../../../../themes";
import ConfirmActionModal from "../confirmationModal";
import "./styles.scss";

const EditResponsibleModal = ({
  handleClose,
  preview,
  parties,
  handleSave,
  isLoading,
  isEditVisibility,
  isEdit,
  isRemoveAllVisible,
}) => {
  //STATES
  const [selectedParties, setSelectedParties] = useState([]);
  const [confirmationModalPreview, setConfirmationModalPreview] =
    useState(false);

  //HELPER
  const manuplateMembersData = () => {
    const tempdata = [];
    parties.forEach((element) => {
      if (!selectedParties.includes(element.id)) {
        tempdata.push(element);
      }
    });
    return tempdata;
  };

  // CONST VALS
  const membersData = manuplateMembersData();
  const dispatch = useDispatch();
  const lastItem =
    selectedParties?.length !== membersData?.length - 1 &&
    membersData?.length > 1;

  //HOOKS
  useEffect(() => {
    if (!preview) {
      setSelectedParties([]);
    }
  }, [preview]);

  //HANDLERS
  const closeHandler = () => {
    handleClose();
    setSelectedParties([]);
  };

  const removePartyHandler = (id) => {
    if (parties.length > 1) {
      setSelectedParties([...selectedParties, id]);
    } else {
      setConfirmationModalPreview(true);
    }
  };

  const removeAllPartiesHandler = () => {
    let temp = [];
    parties.map((item) => temp.push(item.id));

    handleSave(temp);
    setConfirmationModalPreview(false);
  };
  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={460}
      className="edit-responsible-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>
              {isEdit ? "Edit" : "View"}{" "}
              {isEditVisibility ? "Visibility" : "Responsible"}
            </h3>
            <p>
              {isEdit
                ? `
              Are You Sure To Remove  
              ${isEditVisibility ? "Visible Party" : "Responsible Individuals"}`
                : ""}
            </p>
          </div>
          <div className="responsible-parties-wrapper">
            {membersData.map((item, index) => (
              <div className="responsible-parties" key={index}>
                <div className="reponsible-party-info">
                  {isEditVisibility ? (
                    <ProfileImage
                      username={item?.roomname}
                      image={item?.image}
                      color={item?.bgColor}
                      size={10}
                    />
                  ) : (
                    <ProfileImage
                      username={item.username}
                      image={item.image}
                      color={item.profilebg}
                      isBlocked={!item?.isActive}
                      onClick={() => {
                        dispatch(
                          setUserDetailDrawer({
                            visibility: true,
                            id: item.userid ?? item.id,
                          })
                        );
                      }}
                      size={10}
                    />
                  )}
                  <div className="user-info">
                    <p className="name">
                      {isEditVisibility ? item.roomname : item.username}
                    </p>
                    <p className="email">{item.email}</p>
                  </div>
                </div>
                {isEdit && (isEditVisibility ? lastItem : true) && (
                  <Images.CloseIcon
                    onClick={() => {
                      removePartyHandler(item.id);
                    }}
                  />
                )}
              </div>
            ))}
          </div>

          {isEdit && (
            <div className="modal-footer">
              {isRemoveAllVisible && (
                <span
                  className="remove-all-btn"
                  onClick={() => setConfirmationModalPreview(true)}
                >
                  Remove All
                </span>
              )}
              <ButtonComponent
                text="Save"
                isLoading={isLoading}
                style={{ width: "50%" }}
                onClick={() => handleSave(selectedParties)}
                disabled={selectedParties.length < 1}
              />
            </div>
          )}
        </div>
      </div>
      <ConfirmActionModal
        preview={confirmationModalPreview}
        handleClose={() => {
          setConfirmationModalPreview(false);
        }}
        description={"Are you sure you want to remove all parties?"}
        title={"Remove All Parties"}
        handleConfirm={removeAllPartiesHandler}
      />
    </Modal>
  );
};

export default EditResponsibleModal;
