import _ from "lodash";
import { DATE_FORMAT5, TIME_FORMAT1 } from "../constants";
import user from "../redux/slicers/user";
import { getFormattedDateTime } from "../services/utils";
import { manipulateUserData } from "./user";

export const manipulateVisibilityList = (data, isName) => {
  let visibilties = [];
  let sortkey = isName ? "firstName" : "email";
  data?.forEach((item) => {
    visibilties.push(manipulateUserData(item));
  });
  visibilties.sort(function (a, b) {
    return a[sortkey].localeCompare(b[sortkey]);
  });
  return visibilties;
};

export const manipulateDataroomData = (data) => {
  if (_.isEmpty(data)) return {};
  let visibilitiesdata = manipulateVisibilityList(data?.visibility, true);
  let room = {};
  room["id"] = data?.id ?? 0;
  room["slug"] = data.slug ?? "";
  room["name"] = data.name ?? "Data Room";
  room["url"] = data.url ?? "";
  room["isVisibleToAll"] = data.isVisibleToAll ?? false;
  room["isOwner"] = data.isOwner ?? false;
  room["isAdmin"] = data.isAdmin ?? false;
  room["creatorInfo"] = manipulateUserData(data.createdBy);
  room["isEdit"] = data?.Permission?.includes("edit") ?? true;
  room["visibilities"] = [...visibilitiesdata].sort(function (a, b) {
    if (a.isAdmin) {
      return -1;
    } else {
      return a.firstName.localeCompare(b.firstName);
    }
  });
  return room;
};

export const manipulateDataroomsList = (data) => {
  let rooms = [];
  data?.forEach((item) => {
    rooms.push(manipulateDataroomData(item));
  });
  return rooms;
};

export const manipulateFolderData = (data) => {
  if (_.isEmpty(data)) return {};
  let folder = {};
  folder["id"] = data?.id ?? 0;
  folder["slug"] = data.slug ?? "";
  folder["name"] = data.name ?? "Folder";
  folder["isOwner"] = data.isOwner ?? false;
  folder["uuid"] = data.uuid ?? "";
  folder["isRoomOwner"] =
    (data.isDataRoomOwner || data?.isDataRoomAdmin) ?? false;
  folder["isVisibleToAll"] = data.isVisibleToAll ?? false;
  folder["visibilities"] = manipulateVisibilityList(data?.visibility, true);
  folder["creatorInfo"] = manipulateUserData(data.createdBy);
  folder["path"] = data?.path ?? "Folder Path";
  folder["isDataRoom"] = data?.isDataRoom ?? false;
  folder["lastUpdated"] =
    getFormattedDateTime(data?.updatedAt, DATE_FORMAT5) ?? "";

  folder["isEdit"] = data?.permission?.includes("edit") ?? true;

  return folder;
};

export const manipulateFoldersList = (data) => {
  let folders = [];
  data?.forEach((item) => {
    folders.push(manipulateFolderData(item));
  });
  return folders;
};

export const manipulateDocumentData = (data) => {
  if (_.isEmpty(data)) return {};
  let document = {};
  document["id"] = data?.id ?? 0;
  document["slug"] = data.slug ?? "";
  document["name"] = data.file ?? "Document";
  document["url"] = data.url ?? "";
  document["isOwner"] = data.isOwner ?? false;
  document["creatorInfo"] = manipulateUserData(data.uploadedBy);
  document["lastUpdated"] =
    getFormattedDateTime(data?.updatedAt, DATE_FORMAT5) ?? "";

  return document;
};

export const manipulateDocumentList = (data) => {
  let documents = [];
  data?.forEach((item) => {
    documents.push(manipulateDocumentData(item));
  });
  return documents;
};

export const manipulateLinkData = (data, isRequest) => {
  if (_.isEmpty(data)) return {};
  let link = {};
  link["id"] = data?.id ?? 0;
  link["slug"] = data.slug ?? "";
  link["name"] = data.linkName ?? "Link";
  link["url"] = data.url ?? "";
  link["isOwner"] = data.isOwner ?? false;
  link["isVisibleToAll"] = data.isVisibleToAll ?? false;
  link["visibilities"] = manipulateVisibilityList(data?.visibilties);
  link["creatorInfo"] = manipulateUserData(data.createdBy);
  if (isRequest) {
    link["visibilities"] = data.isVisibleToAll
      ? true
      : manipulateVisibilityList(data?.visibilties);
  }

  return link;
};

export const manipulateLinksList = (data) => {
  let links = [];
  data?.forEach((item) => {
    links.push(manipulateLinkData(item));
  });
  return links;
};

export const manipulateNoteData = (data) => {
  if (_.isEmpty(data)) return {};
  let note = {};
  note["id"] = data?.id ?? 0;
  note["note"] = data.note ?? "";
  note["linkId"] = data.linkId ?? "";
  note["creatorInfo"] = manipulateUserData(data.createdBy);
  note["link"] = manipulateLinkData(data.link);
  note["createdAt"] = getFormattedDateTime(data.createdAt, TIME_FORMAT1);
  note["isOwner"] = data.isOwner ?? false;

  return note;
};

export const manipulateNotesList = (data) => {
  let notes = [];
  data?.forEach((item) => {
    notes.push(manipulateNoteData(item));
  });
  return notes;
};

export const manipulateDataroomPermissionList = (data) => {
  let permissions = [];
  Object.keys(data).map((item) => {
    permissions.push({ label: data[item], value: item });
  });
  return permissions;
};

export const manipulateUserPermissionData = (data) => {
  if (_.isEmpty(data)) return {};

  let user = {};

  user["id"] = data?.id ?? 0;
  user["username"] = data?.firstName + " " + data?.lastName ?? "";
  user["image"] = data?.image ?? null;
  user["permission"] = data?.permission ?? "view";
  user["email"] = data?.email ?? "";
  user["bgColor"] = data?.bgcolor ?? "";
  user["isActive"] = (data?.isActive && !data?.isBlock) ?? true;

  return user;
};

export const manipulateUserPermissionsList = (data) => {
  let users = [];

  data?.forEach((item) => {
    users.push(manipulateUserPermissionData(item));
  });

  return users;
};
