import React from "react";
import { Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ButtonComponent, ProfileImage } from "../../../../../../../components";
import "./styles.scss";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { removeChecklistResponsibilityRequest } from "../../../../../../../redux/slicers/transactionchecklist";
import { useParams } from "react-router-dom";

const DeleteResponsibleGroupModal = ({
  handleClose,
  preview,
  selectedGroup,
}) => {
  //CUSTOM DISPATCH
  const { dataid } = useParams();

  //CUSTOM DISPATCH
  const [removeResponsible, isLoading] = useCustomDispatch(
    removeChecklistResponsibilityRequest
  );

  //HANDLERS
  const handleDelete = () => {
    const payload = { ids: selectedGroup?.users?.map((x) => x.id) };
    removeResponsible({
      payload,
      pathParams: dataid,
      logic(res) {
        handleClose();
      },
    });
  };
  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={450}
      className="remove-responsible-group-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>Remove Responsible Individuals</h3>
            <p>Are You Sure To Remove Responsible Individuals</p>
          </div>
          <div className="member-detail">
            <ProfileImage
              username={selectedGroup?.name}
              image={selectedGroup?.logo}
              color={selectedGroup?.profilebg}
              isBlocked={false}
            />
            <div className="detail">
              <h4>{selectedGroup?.name}</h4>
              <p>
                {selectedGroup?.users?.length} Participant
                {selectedGroup?.users?.length > 1 && "s"}
              </p>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <ButtonComponent
            text="Cancel"
            onClick={handleClose}
            className="cancel"
          />
          <ButtonComponent
            onClick={handleDelete}
            text="Delete"
            className="delete-btn"
            isLoading={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteResponsibleGroupModal;
