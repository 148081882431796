import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { faChevronRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "antd";
import { CardLoader, ButtonComponent, ProfileImage } from "../";
import { useDispatch, useSelector } from "react-redux";
import {
  setCompanyDetailDrawer,
  setUserDetailDrawer,
} from "../../redux/slicers/general";
import { useCustomDispatch } from "../../helpers/useCustomDispatch";
import { Images } from "../../themes";
import {
  ALERT_TYPES,
  DASHBOARD_ROUTE,
  TRANSACTION_DETAIL_ROUTE,
} from "../../constants";
import {
  transactionInviteResponseRequest,
  getTransactionInvitationDetail,
} from "../../redux/slicers/transactiondetail";
import { replaceValInString, toastAlert } from "../../services/utils";
import "./styles.scss";

const DashboardInviteModal = ({
  handleClose,
  open,
  invitationAcceptHandler,
  notiToken,
}) => {
  //STATE
  const [inviteInfo, setInviteInfo] = useState({});
  const [acceptLoader, setAcceptLoader] = useState(false);
  const [rejectLoader, setRejectLoader] = useState(false);
  const [isInvitationValid, setIsInvitationValid] = useState(false);

  //CONST VALS
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  //REDUX DATA
  const { data } = useSelector((state) => state.user);
  //CUSTOM DISPATCH
  const [getTransactionInviteDetail, inviteLoading] = useCustomDispatch(
    getTransactionInvitationDetail
  );
  const [inviteResponse] = useCustomDispatch(transactionInviteResponseRequest);

  // HELPERS
  const invitationDataManuplator = (data) => {
    let response = {
      transactionName: data.transaction.name,
      transactionRole: data.transaction.transactionType.title,
      companyName: data.invitedBy.company.name,
      companyId: data.invitedBy.company.id,
      invitedBy: data.invitedBy.firstName + " " + data.invitedBy.lastName,
      invitedById: data.invitedBy.id,
      thumb: data.invitedBy.image,
      profilebg: data.invitedBy.bgcolor,
    };
    return response;
  };

  //HOOKS
  useEffect(() => {
    if (open) {
      getTransactionInviteDetail({
        pathParams: notiToken ?? token,
        queryParams: { email: data.email },
        logic(res) {
          if (res.isAccepted) {
            toastAlert(res.message, ALERT_TYPES.ERROR);
          } else {
            if (res.data.deletedAt) {
              toastAlert(res.message, ALERT_TYPES.ERROR);
              return;
            }
            if (res.status) {
              setIsInvitationValid(true);
              setInviteInfo(invitationDataManuplator(res.data));
              return;
            }
            toastAlert(res.message, ALERT_TYPES.ERROR);
            handleClose();
          }
          toastAlert(res.message, ALERT_TYPES.ERROR);
        },
      });
    }
  }, [open]);

  //HANDLERS
  const handleInvitationResponse = (response) => {
    const payload = { isAccepted: response };
    inviteResponse({
      payload,
      pathParams: notiToken ?? token,
      callback() {
        setAcceptLoader(false);
        setRejectLoader(false);
      },
      logic(res) {
        const { data } = res;
        if (response) {
          invitationAcceptHandler && invitationAcceptHandler();
          navigateToTransaction(data.slug, data.rooms[0].slug);
        } else {
          if (token) {
            navigate(DASHBOARD_ROUTE, { replace: true });
          }
        }
        toastAlert(res.message, ALERT_TYPES.SUCCESS);
        setIsInvitationValid(false);
        handleClose();
      },
    });
  };

  const navigateToTransaction = (transaction, room) => {
    const vals = { ":id": transaction, ":room": room };
    navigate(replaceValInString(TRANSACTION_DETAIL_ROUTE, vals), {
      replace: true,
    });
  };

  // CUSTOM COMPONENT
  const inviteDetail = () => (
    <div className="invitation-detail">
      <div className="invitedby-detail">
        <div className="user-dtl">
          <ProfileImage
            image={inviteInfo?.thumb}
            color={inviteInfo?.profilebg}
            username={inviteInfo?.invitedBy}
            onClick={() => {
              dispatch(
                setUserDetailDrawer({
                  visibility: true,
                  id: inviteInfo.invitedById,
                })
              );
            }}
          />
          <div className="dtl">
            <p>Invited By</p>
            <h4
              onClick={() => {
                dispatch(
                  setUserDetailDrawer({
                    visibility: true,
                    id: inviteInfo.invitedById,
                  })
                );
              }}
              className="c-p"
            >
              {inviteInfo?.invitedBy}
            </h4>
          </div>
        </div>
        <div
          className="user-company c-p"
          onClick={() => {
            dispatch(
              setCompanyDetailDrawer({
                visibility: true,
                id: inviteInfo.companyId,
              })
            );
          }}
        >
          <span>{inviteInfo?.companyName}</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </div>
      <div className="transaction-dtl">
        <div className="tr-role">
          <Images.AuthUser />
          <h3>
            Transaction Role
            <span>{inviteInfo?.transactionRole}</span>
          </h3>
        </div>
        <div className="tr-dtl">
          <Images.AuthDocument />
          <h3>
            Transaction
            <span>{inviteInfo?.transactionName}</span>
          </h3>
        </div>
      </div>
    </div>
  );
  return (
    <Modal
      destroyOnClose
      visible={open && isInvitationValid}
      centered
      footer={null}
      width={522}
      className="dashboard-invite-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div
            className="close-btn"
            onClick={() => {
              handleClose();
              setIsInvitationValid(false);
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>

          <div className="title-wrapper">
            <h3>Invitation Request</h3>
            <p>Waiting For Your Response</p>
          </div>
          {inviteLoading ? (
            <CardLoader height={150} />
          ) : (
            // <div style={{ marginBottom: 20 }}>
            //   <EmptyBox height={150} imgwidth={80} text="No Invitation Found" />
            // </div>
            inviteDetail()
          )}
          <div className="modal-footer">
            <ButtonComponent
              text={`Reject`}
              className="reject-btn"
              isLoading={rejectLoader}
              onClick={() => {
                setRejectLoader(true);
                handleInvitationResponse(false);
              }}
            />
            <ButtonComponent
              text={`Accept`}
              className="accept-btn"
              isLoading={acceptLoader}
              onClick={() => {
                setAcceptLoader(true);
                handleInvitationResponse(true);
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DashboardInviteModal;
