import React from "react";
import { Dropdown, Menu, Modal } from "antd";
import { Images } from "../../../../../themes";
import "./styles.scss";
import { useState } from "react";
import { useCustomDispatch } from "../../../../../helpers/useCustomDispatch";
import {
  checkLastGroupAdminRequest,
  getDirectChatUsersRequest,
  getGroupParticipantsRequest,
  loadRoomRequest,
  makeGroupAdminRequest,
  removeGroupAdminRequest,
  removeGroupMembersRequest,
  setMembersOffset,
} from "../../../../../redux/slicers/chat";
import { useEffect } from "react";
import { DATA_PER_PAGE_LIMIT } from "../../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  CardLoader,
  CustomInfiniteScroll,
  DeleteModal,
  EmptyBox,
  ProfileImage,
} from "../../../../../components";
import { setUserDetailDrawer } from "../../../../../redux/slicers/general";
import CreateGroupModal from "../CreateGroupModal";
import LeaveRoomModal from "../LeaveRoomModal";

const GroupMembersModal = ({ handleClose, preview, isTransaction }) => {
  //STATES
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(null);
  const [isMoreData, setMoreData] = useState(false);
  const [selected, setSelected] = useState(null);
  const [addMemberModalPreview, setAddMemberModalPreview] = useState(false);
  const [removeMemberModalPreview, setRemoveMemberModalPreview] =
    useState(false);
  const [adminModalPreview, setAdminModalPreview] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isLastAdmin, setIsLastAdmin] = useState(false);

  // CONST VALS
  const dispatch = useDispatch();

  //REDUX DATA
  const { participants, selectedRoom, memberOffset } = useSelector(
    (state) => state.chat
  );
  const { chat_id } = useSelector((state) => state.user.data);
  const isAdminLoggedIn = participants?.find(
    (x) => x.chat_id === chat_id
  )?.isGroupAdmin;

  //CUSTOM DISPATCH
  const [getUsers, isLoading] = useCustomDispatch(getGroupParticipantsRequest);
  const [removeMember, removeLoader] = useCustomDispatch(
    removeGroupMembersRequest
  );
  const [makeAdmin, makeAdminLoader] = useCustomDispatch(makeGroupAdminRequest);
  const [removeAdmin, removeAdminLoader] = useCustomDispatch(
    removeGroupAdminRequest
  );
  const [checkAdmin, checkLoader] = useCustomDispatch(
    checkLastGroupAdminRequest
  );

  //HANDLERS
  const setOffset = (val) => {
    dispatch(setMembersOffset(val));
  };

  const userDetailHandler = (item) => {
    dispatch(
      setUserDetailDrawer({
        visibility: true,
        id: item?.id,
        isGroup: selectedRoom?.isGroup,
      })
    );
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setMoreData(false);
    setOffset(0);
  };

  const closeHandler = () => {
    setSearch("");
    setOffset(0);
    handleClose();
  };

  const getUsersHelper = (search) => {
    setLoader(true);
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: memberOffset,
      room_id: selectedRoom?.id,
      user_id: chat_id,
      filter: false,
    };
    if (search) {
      queryParams["search"] = search;
    }
    getUsers({
      queryParams: queryParams,
      logic(res) {
        setTotal(res.total);
        setLoader(false);
      },
    });
  };

  const handleNextData = () => {
    setOffset(memberOffset + DATA_PER_PAGE_LIMIT);
    setMoreData(true);
  };

  const addMemberModalPreviewHandler = () => {
    setAddMemberModalPreview(!addMemberModalPreview);
  };

  const removeMemberModalPreviewHandler = (item) => {
    setSelected(item);
    setRemoveMemberModalPreview(!removeMemberModalPreview);
  };

  const adminModalPreviewHandler = (item) => {
    setSelected(item);
    if (item?.isGroupAdmin) {
      checkAdmin({
        queryParams: { room_id: selectedRoom?.id },
        logic(res) {
          setIsLastAdmin(res.status);
          setAdminModalPreview(!adminModalPreview);
        },
      });
      return;
    }
    setAdminModalPreview(!adminModalPreview);
    setIsLastAdmin(false);
  };

  const onMemberAddHAndler = () => {
    setSearch("");
    setOffset(0);
    // getUsersHelper("");
  };

  const removeMemberHandler = () => {
    removeMember({
      payload: { room_id: selectedRoom?.id, user_id: selected?.chat_id },
      logic() {
        removeMemberModalPreviewHandler(null);
        onMemberAddHAndler();
      },
    });
  };

  const changeAdminHelper = (newAdmin) => {
    const payload = { room_id: selectedRoom?.id, user_id: selected?.chat_id };
    if (selected?.isGroupAdmin) {
      if (newAdmin) {
        payload["new_admin"] = newAdmin;
      }
      removeAdmin({
        payload,
        logic() {
          adminModalPreviewHandler(null);
          onMemberAddHAndler();
        },
      });
      return;
    }
    makeAdmin({
      payload,
      logic() {
        adminModalPreviewHandler(null);
        onMemberAddHAndler();
      },
    });
  };

  //HOOKS
  useEffect(() => {
    if (preview) {
      getUsersHelper(search);
    } else {
      setSearch("");
    }
  }, [search, preview, memberOffset]);

  //CUSTOM COMPONENTS
  const userMenu = (item) => (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <span className="group-member-options">
              <p>
                {item.isGroupAdmin
                  ? "Remove Group Chat Admin"
                  : "Make Group Chat Admin"}
              </p>
            </span>
          ),
          onClick: () => {
            adminModalPreviewHandler(item);
          },
        },
        ...[
          item?.chat_id === chat_id
            ? null
            : {
                key: "2",
                label: (
                  <span className="group-member-options">
                    <p>Remove from Group</p>
                  </span>
                ),
                onClick: () => {
                  // removeMemberHander(item.id);
                  removeMemberModalPreviewHandler(item);
                },
              },
        ],
      ]}
    />
  );
  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={276}
      className="group-members-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="title">
            <h3>People</h3>
            <div className="modal-options-wrapper">
              {!isTransaction && isAdminLoggedIn && (
                <Images.GroupPlus onClick={addMemberModalPreviewHandler} />
              )}
              <Images.CloseIcon onClick={closeHandler} />
            </div>
          </div>

          <div className="search-box">
            <Images.Search />
            <input
              type="text"
              placeholder="Search"
              onChange={handleSearch}
              className="search-input"
              value={search}
            />
          </div>
          {isLoading & !isMoreData ? (
            loader && <CardLoader size={10} height={240} />
          ) : participants?.length < 1 ? (
            <EmptyBox />
          ) : (
            <CustomInfiniteScroll
              dataLength={participants?.length}
              total={total}
              next={handleNextData}
              maxHeight={300}
              isWholePage={false}
              className="people-wrapper"
            >
              {participants.map((item, index) => (
                <div className="people-item" key={index}>
                  <div className="info">
                    <div className="user-thumb">
                      <ProfileImage
                        image={item?.image}
                        username={item?.username}
                        color={item?.bgColor}
                        isBlocked={!item?.isActive}
                        onClick={() => {
                          userDetailHandler(item);
                        }}
                      />
                      {item?.isGroupAdmin && !isTransaction && (
                        <img
                          src={Images.isAdminCrown}
                          alt=""
                          className="admin-crown"
                        />
                      )}
                    </div>

                    <div className="name">
                      <h3
                        onClick={() => {
                          userDetailHandler(item);
                        }}
                        className="c-p"
                      >
                        {item?.username}
                      </h3>
                      <p>{item?.email}</p>
                    </div>
                  </div>
                  {isAdminLoggedIn && !isTransaction && (
                    <Dropdown
                      getPopupContainer={(trigger) => trigger.parentElement}
                      trigger={"click"}
                      overlay={userMenu(item)}
                    >
                      <Images.threeDot className="c-p" />
                    </Dropdown>
                  )}
                </div>
              ))}
            </CustomInfiniteScroll>
          )}
        </div>
      </div>
      <CreateGroupModal
        isAddMember={true}
        preview={addMemberModalPreview}
        handleClose={addMemberModalPreviewHandler}
        handleSuccess={onMemberAddHAndler}
        isEdit={false}
      />
      <DeleteModal
        btnText="Remove"
        preview={removeMemberModalPreview}
        title={"Remove Participant"}
        description={"Are you sure you want to remove this participant?"}
        handleClose={() => removeMemberModalPreviewHandler(null)}
        innerSec={<></>}
        isLoading={removeLoader}
        handleDelete={removeMemberHandler}
      />
      <LeaveRoomModal
        open={adminModalPreview}
        handleClose={() => adminModalPreviewHandler(null)}
        title={`${selected?.isGroupAdmin ? "Remove" : "Make"} Admin`}
        description={`Are you sure you want to remove selected participant from admin?`}
        btnText={`${selected?.isGroupAdmin ? "Remove" : "Make"} Admin`}
        tooltip="Selected person will be assigned as an admin."
        isAdmin={isLastAdmin}
        onSubmit={changeAdminHelper}
        isAlreadyAdmin={selected?.isGroupAdmin}
        btnLoader={makeAdminLoader || removeAdminLoader}
      />
    </Modal>
  );
};

export default GroupMembersModal;
