import React, { useState, useEffect } from "react";
import TopHeader from "./header";
import Suggestions from "./suggestions";
import { Images } from "../../../../../../../themes";
import { ChecklistDataTable } from "../../components";
import { CardHeader } from "../../../../../../../components";
import { useNavigate, useParams } from "react-router-dom";
import {
  DATA_PER_PAGE_LIMIT,
  TRANSACTION_TABS_ROUTE,
  TRANSACTION_TABS_SLUGS,
} from "../../../../../../../constants";
import { replaceValInString } from "../../../../../../../services/utils";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import {
  getChecklistRequest,
  getChecklistSuggestionsRequest,
} from "../../../../../../../redux/slicers/transactionchecklist";
import { useSelector } from "react-redux";
import "./styles.scss";

const ChecklistDashboard = ({
  isDeleted,
  isEditAllowed,
  isCreateAllowed,
  isDeleteAllowed,
  isShareAllowed,
}) => {
  // STATES
  const [status, setStatus] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedSortKey, setselectedSortKey] = useState(null);
  const [selectedSortFilter, setselectedSortFilter] = useState(true);
  const [total, setTotal] = useState(null);
  const [offset, setOffset] = useState(0);
  const [isMoreData, setMoreData] = useState(false);
  const [isDataUpdated, setDataUpdated] = useState(false);

  //REDUX DATA
  const { checklist, suggestions } = useSelector(
    (state) => state.transactionchecklist
  );

  // CONST VALS
  const { id, room } = useParams();
  const navigate = useNavigate();

  //CUSTOM DISPATCH
  const [getChecklists, isLoading] = useCustomDispatch(getChecklistRequest);
  const [getSuggestions, suggestionsLoader] = useCustomDispatch(
    getChecklistSuggestionsRequest
  );

  // HELPERS
  const getChecklistHelper = (isSearch) => {
    let queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: isSearch ? 0 : offset,
      status,
      sort: selectedSortKey,
      order: selectedSortFilter ? "asc" : "desc",
      isDeleted: isDeleted,
      ...selectedFilters,
    };

    if (isSearch !== "") {
      queryParams["search"] = isSearch;
    }
    if (isSearch) {
      setMoreData(false);
    }
    getChecklists({
      queryParams,
      pathParams: room,
      logic(res) {
        setTotal(res.total);
      },
    });
  };

  //HOOKS
  useEffect(() => {
    getChecklistHelper();
  }, [
    isDataUpdated,
    offset,
    selectedSortKey,
    selectedSortFilter,
    selectedFilters,
    status,
    isDeleted,
  ]);

  useEffect(() => {
    getSuggestions({ pathParams: id });
  }, []);

  // HANDLERS
  const setDataUpdatedHandler = () => {
    setOffset(0);
    setDataUpdated(!isDataUpdated);
  };
  const setStatusHandler = (status) => {
    setStatus(status);
  };
  const setSortKeyHandler = (key) => {
    const isAlreadyExist = selectedSortKey === key;
    setselectedSortKey(key);
    setselectedSortFilter(isAlreadyExist ? !selectedSortFilter : false);
    setMoreData(false);
    setOffset(0);
  };

  const handleNextData = () => {
    setOffset(offset + DATA_PER_PAGE_LIMIT);
    setMoreData(true);
  };

  const handleSearch = (val) => {
    setOffset(0);
    getChecklistHelper(val);
  };

  const navigateToDeleted = () => {
    const val = {
      ":tab": TRANSACTION_TABS_SLUGS.DELETED_CHECKLIST,
      ":id": id,
      ":room": room,
    };
    navigate(replaceValInString(TRANSACTION_TABS_ROUTE, val));
  };
  return (
    <section className="checklist-dashboard">
      <TopHeader
        isSubView={isDeleted}
        handleSearch={handleSearch}
        setFilters={setSelectedFilters}
        handleDataUpdate={setDataUpdatedHandler}
        isCreateAllowed={isCreateAllowed}
        isShareAllowed={isShareAllowed}
      />
      {!isDeleted && !suggestionsLoader && suggestions.length > 0 && (
        <Suggestions
          data={suggestions}
          isLoading={suggestionsLoader}
          handleDataUpdate={setDataUpdatedHandler}
        />
      )}
      <div className="card mt-3">
        <div className={`card-topbar ${isDeleted ? "deleted" : ""}`}>
          <CardHeader
            isLg
            isSearch={false}
            isFilterTab={!isDeleted}
            selectedFilter={status}
            setFilter={setStatusHandler}
            title={`${isDeleted ? "Deleted" : ""} Checklist Items`}
            placeholder="Search any checklist item..."
          />
          {!isDeleted && (
            <div className="right-box">
              <Images.Delete className="c-p" onClick={navigateToDeleted} />
            </div>
          )}
        </div>
        <ChecklistDataTable
          isLoading={isLoading && !isMoreData}
          isDeleted={isDeleted}
          total={total}
          data={checklist}
          selectedSort={selectedSortKey}
          selectedSortFilter={selectedSortFilter}
          sortData={setSortKeyHandler}
          handleFetch={handleNextData}
          handleDataUpdate={setDataUpdatedHandler}
          isEditAllowed={isEditAllowed}
          isDeleteAllowed={isDeleteAllowed}
        />
      </div>
    </section>
  );
};

export default ChecklistDashboard;
