import React, { useState, useEffect } from "react";
import { Form, Select, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useCustomDispatch } from "../../../../../../helpers/useCustomDispatch";
import { getCompanyMembersRequest } from "../../../../../../redux/slicers/company";
import {
  autoCompleteFieldRule,
  getAutoCompleteValue,
  inputFieldRule,
} from "../../../../../../services/utils";
import {
  ButtonComponent,
  CustomAutoComplete,
  CustomOtherSelect,
  CustomUserAutoComplete,
} from "../../../../../../components";
import {
  DATA_PER_PAGE_LIMIT,
  TRANSACTION_ROLE_TYPE,
} from "../../../../../../constants";
import { Images } from "../../../../../../themes";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CompanyTab = ({
  isLoading,
  onSubmit,
  setCompanySearchTextHandler,
  setRoleSearchTextHandler,
  companies,
  transactionparties,
  parties,
  partyTotal,
  setPartyOffset,
  isPartyLoading,
  advisories,
  advisoryTotal,
  setAdvisoryOffset,
  isAdvisoryLoading,
  selectedType,
  setSelectedType,
}) => {
  // STATES
  const [isCompanySelected, setSelectedCompany] = useState(null);
  const [isUserSelected, setUserSelected] = useState(null);

  // CUSTOM DISPATCH
  const [getCompanyMembers, isMembersLoading] = useCustomDispatch(
    getCompanyMembersRequest
  );

  //REDUX DATA
  const { companyMembers } = useSelector((state) => state.company);

  // CONST VALS
  const [form] = Form.useForm();
  const isMembersOptionsVisible = !isMembersLoading && isCompanySelected;

  //HELPERS
  const getCompanyMembersHelper = (company, val) => {
    const queryParams = {
      offset: 0,
      limit: DATA_PER_PAGE_LIMIT,
      company,
      isBlocked: false,
    };
    if (val) queryParams["search"] = val;
    getCompanyMembers({
      queryParams,
    });
  };
  // HOOKS
  useEffect(() => {
    if (!isUserSelected) form.resetFields(["firstName", "lastName"]);
  }, [isUserSelected]);

  useEffect(() => {
    return () => {
      setSelectedType(null);
    };
  }, []);
  // HANDLERS
  const handleCompanySearch = (val) => {
    setCompanySearchTextHandler(val);
    setSelectedCompany(null);
  };
  const handleUserSearch = (val) => {
    getCompanyMembersHelper(isCompanySelected, val);
    setUserSelected(null);
  };

  const handleSetUser = (val, name) => {
    const member = companyMembers?.find((x) => x[name] === val.value);
    setUserSelected(member);
    form.setFieldsValue({
      email: member?.email,
      firstName: member?.firstName,
      lastName: member?.lastName,
    });
  };
  const handleSetField = (name, value) => {
    form.setFieldValue(name, value);
  };
  const handleGetMembersByEnterprise = ({ key }) => {
    let company = companies.find((x) => x.name == key);
    setSelectedCompany(company?.id);
    getCompanyMembersHelper(company?.id);
  };
  const handleSubmit = (values) => {
    const userId = companyMembers?.find((x) => x.email === values.email)?.id;
    const users = [
      {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        id: userId ?? null,
      },
    ];
    const payload = {
      users,
      roleId: values.roleId,
      advisedPartyId: values.advisedPartyId,
      isAdvisor: values.isAdvisor,
      company: getAutoCompleteValue({
        value: values.company,
        key: "value",
      }),
    };
    onSubmit(payload);
  };
  // CUSTOM COMPONENT
  const roleField = (options, total, handlenext, isLoading) => (
    <CustomOtherSelect
      disabled={selectedType === null}
      options={options}
      total={total}
      handleNextData={handlenext}
      isLoading={isLoading}
      handleSet={handleSetField}
      name="roleId"
      rules={[
        () => ({
          validator(_, value) {
            return autoCompleteFieldRule({ value, name: "Role", isMax: true });
          },
        }),
      ]}
      placeholder="Select Type"
      // placeholder={
      //   selectedType == TRANSACTION_ROLE_TYPE[1].value
      //     ? "Select Type"
      //     : "Select Type"
      // }
      labelProp={"name"}
      valueProp={"id"}
    />
  );
  return (
    <Form form={form} className="add-company-form form" onFinish={handleSubmit}>
      <span className="company-tab-tooltip-item">
        <Form.Item
          name="isAdvisor"
          rules={inputFieldRule({
            name: "Role type",
            isWhiteSpace: false,
          })}
        >
          <Select
            placeholder="Party or Advisor?"
            options={TRANSACTION_ROLE_TYPE}
            onChange={(val) => {
              setSelectedType(val);
              form.setFieldValue("role", undefined);
            }}
          />
        </Form.Item>
        <Tooltip
          title="A Party to a transaction is a principal who typically signs documents, such as a buyer, seller or lender. An Advisor helps a Party, such as a lawyer, accountant or financial advisor."
          placement="bottomLeft"
          overlayClassName="invite-participant-tooltip"
          color="#F8FAFB"
          getPopupContainer={(trigger) => trigger.parentElement}
        >
          <Images.InviteInfo className="invite-info-icon c-p" />
        </Tooltip>
      </span>
      {selectedType == TRANSACTION_ROLE_TYPE[0].value ? (
        roleField(parties, partyTotal, setPartyOffset, isPartyLoading)
      ) : selectedType == TRANSACTION_ROLE_TYPE[1].value ? (
        <>
          {roleField(
            advisories,
            advisoryTotal,
            setAdvisoryOffset,
            isAdvisoryLoading
          )}
          <div className="invite-advised-party-wrapper">
            <Form.Item
              name="advisedPartyId"
              rules={inputFieldRule({
                name: "Advised party",
                isWhiteSpace: false,
              })}
            >
              <Select placeholder="Party you are Advising">
                {transactionparties?.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Tooltip
              title="if party not visible, please add the party to the transaction first, then add the advisor"
              placement="bottomLeft"
              overlayClassName="invite-participant-tooltip"
              color="#F8FAFB"
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              <Images.InviteInfo className="invite-info-icon" />
            </Tooltip>
          </div>
        </>
      ) : (
        roleField(parties)
      )}
      <CustomUserAutoComplete
        options={companies}
        setSearch={handleCompanySearch}
        handleSet={handleSetField}
        onOptionSelect={handleGetMembersByEnterprise}
        isTriggered
        name="company"
        rules={[
          () => ({
            validator(_, value) {
              return autoCompleteFieldRule({
                value,
                name: "Company",
                isMax: true,
                max: 150,
              });
            },
          }),
        ]}
        placeholder={
          selectedType == TRANSACTION_ROLE_TYPE[1].value
            ? "Name of Advisor Enterprise"
            : "Name of Enterprise"
        }
        labelProp={"name"}
        valueProp={"name"}
      />
      <CustomUserAutoComplete
        options={isMembersOptionsVisible ? companyMembers : []}
        isTriggered
        setSearch={handleUserSearch}
        handleSet={handleSetField}
        onOptionSelect={handleSetUser}
        name="email"
        rules={[
          () => ({
            validator(_, value) {
              return autoCompleteFieldRule({
                value,
                name: "Email address",
                isEmail: true,
                isMax: true,
                max: 150,
              });
            },
          }),
        ]}
        placeholder={
          selectedType == TRANSACTION_ROLE_TYPE[1].value
            ? "Email of Person at Advisor to Invite"
            : "Email of Person at Enterprise to Invite"
        }
        labelProp={"email"}
        valueProp={"id"}
      />

      <CustomUserAutoComplete
        options={isMembersOptionsVisible ? companyMembers : []}
        isTriggered
        disabled={isUserSelected}
        setSearch={handleUserSearch}
        handleSet={handleSetField}
        onOptionSelect={handleSetUser}
        name="firstName"
        rules={[
          () => ({
            validator(_, value) {
              return autoCompleteFieldRule({
                value,
                name: "First name",
                isMax: true,
              });
            },
          }),
        ]}
        placeholder={
          selectedType == TRANSACTION_ROLE_TYPE[1].value
            ? "First Name of Person at Advisor to Invite"
            : "First Name of Person at Enterprise to Invite"
        }
        labelProp={"firstName"}
        valueProp={"id"}
      />

      <CustomUserAutoComplete
        options={isMembersOptionsVisible ? companyMembers : []}
        isTriggered
        setSearch={handleUserSearch}
        handleSet={handleSetField}
        onOptionSelect={handleSetUser}
        disabled={isUserSelected}
        name="lastName"
        rules={[
          () => ({
            validator(_, value) {
              return autoCompleteFieldRule({
                value,
                name: "Last name",
                isMax: true,
              });
            },
          }),
        ]}
        placeholder={
          selectedType == TRANSACTION_ROLE_TYPE[1].value
            ? "Last Name of Person at Advisor to Invite"
            : "Last Name of Person at Enterprise to Invite"
        }
        labelProp={"lastName"}
        valueProp={"id"}
      />

      <div className="Add-company-button">
        <Form.Item>
          <ButtonComponent text={"Add +"} isLoading={isLoading} />
        </Form.Item>
      </div>
    </Form>
  );
};

export default CompanyTab;
