import React from "react";
import { CustomOtherSelect } from "../../../../../../components";
import { autoCompleteFieldRule } from "../../../../../../services/utils";

const PartyFields = ({
  parties,
  isPartiesLoading,
  partyTotal,
  setPartyOffset,
  handleSetField,
}) => {
  return (
    <CustomOtherSelect
      required
      options={parties}
      total={partyTotal}
      handleNextData={setPartyOffset}
      isLoading={isPartiesLoading}
      handleSet={handleSetField}
      label={"Role"}
      name="role"
      rules={[
        () => ({
          validator(_, value) {
            return autoCompleteFieldRule({
              value,
              name: "Role",
              isMax: true,
            });
          },
        }),
      ]}
      placeholder="Select Your Role"
      labelProp={"name"}
      valueProp={"id"}
    />
  );
};

export default PartyFields;
