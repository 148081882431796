import React, { useState } from "react";
import { Form, Input } from "antd";
import { Link } from "react-router-dom";
import { ButtonComponent, TwoFactorAuthModal } from "../../../components";
import Header from "../../../components/header";
import EmailVerification from "../emailVerification";
import {
  ANTD_SCROLL_TO_ERROR_FIELDS_CONFIG,
  COPYRIGHTSTRING,
} from "../../../constants";
import { useCustomDispatch } from "../../../helpers/useCustomDispatch";
import { verifyUserRequest } from "../../../redux/slicers/user";
import { Images } from "../../../themes";
import { inputFieldRule } from "../../../services/utils";
import "../styles.scss";

const ForgotPass = () => {
  // CONST VALS
  const [form] = Form.useForm();

  // STATES
  const [verifyUserPreview, setVerifyUserPreview] = useState(false);
  const [verifyUserResponse, setVerifyUserResponse] = useState({});
  const [emailVerificationPreview, setEmailVerificationPreview] =
    useState(false);
  const [selectedData, setselectedData] = useState({});

  // CUSTOM DISPATCH
  const [verifyUserReq, verifyUserReqLoader] =
    useCustomDispatch(verifyUserRequest);

  // HANDLERS
  const handleFormFinish = (values) => {
    const payload = { email: values.email };
    verifyUserReq({
      payload,
      logic(response) {
        setVerifyUserPreview(true);
        setVerifyUserResponse(response.data);
      },
    });
  };

  const setEmailVerificationPreviewHandler = () => {
    setEmailVerificationPreview(!emailVerificationPreview);
    window.scrollTo(0, 0);
  };

  const handleConfirmOtp = (data) => {
    setselectedData(data);
    setVerifyUserPreview(false);
    setEmailVerificationPreviewHandler();
  };

  return (
    <>
      <Header />
      {emailVerificationPreview ? (
        <EmailVerification
          useremail={selectedData?.email}
          selectedOption={selectedData?.selectedOption}
          userid={verifyUserResponse?.id}
          closeHandler={setEmailVerificationPreviewHandler}
        />
      ) : (
        <section className="signing-wrapper">
          <div className="form-wrapper">
            <div className="back-btn">
              <Link to={-1}>
                <img src={Images.backArrow} alt="back Arraow" /> Back
              </Link>
            </div>
            <h3 className="auth-title">
              <span>forgot your password</span>
            </h3>
            <p className="auth-subtitle">
              Please enter your registered email below to reset your password
            </p>
            <Form
              style={{ marginTop: 60 }}
              scrollToFirstError={ANTD_SCROLL_TO_ERROR_FIELDS_CONFIG}
              form={form}
              className="form"
              onFinish={handleFormFinish}>
              <Form.Item
                name="email"
                rules={inputFieldRule({
                  name: "Email address",
                  isEmail: true,
                })}>
                <Input
                  name="email"
                  prefix={<Images.PhoneEmail className="input-icon" />}
                  placeholder="Email Address"
                  maxLength={80}
                  autoFocus
                />
              </Form.Item>
              <Form.Item>
                <ButtonComponent
                  className="login-btn"
                  isLoading={verifyUserReqLoader}
                  text="Send"
                />
              </Form.Item>
            </Form>
          </div>
          <div>
            <p className="copyright-text">{COPYRIGHTSTRING}</p>
          </div>
          <TwoFactorAuthModal
            forgotPassView
            modalPreview={verifyUserPreview}
            apiData={verifyUserResponse}
            confirmHandler={handleConfirmOtp}
            handleClose={() => {
              setVerifyUserPreview(false);
              setVerifyUserResponse({});
            }}
          />
        </section>
      )}
    </>
  );
};

export default ForgotPass;
