import _ from "lodash";

export const manipulateResourceFolderData = (data) => {
  if (_.isEmpty(data)) return {};
  let folder = {};
  const fileCount =
    (data?.attachment < 10) & (data?.attachment > 0)
      ? `0${data.attachment}`
      : data.attachment;
  folder["name"] = data?.name ?? "";
  folder["id"] = data?.id ?? 0;
  folder["slug"] = data?.slug ?? "";
  folder["fileCount"] = fileCount ?? "";

  return folder;
};

export const manipulateResourceFoldersList = (data) => {
  let folders = [];
  data?.forEach((item) => {
    folders.push(manipulateResourceFolderData(item));
  });
  return folders;
};

export const manipulateResourceFileData = (data) => {
  if (_.isEmpty(data)) return {};
  let file = {};
  file["name"] = data?.filename ?? "";
  file["url"] = data?.url ?? "";
  file["id"] = data?.id ?? "";

  return file;
};

export const manipulateResourceFilesList = (data) => {
  let files = [];
  data?.forEach((item) => {
    files.push(manipulateResourceFileData(item));
  });
  return files;
};
