import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Modal, Select } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonComponent, ProfileImage } from "../../../../../../../components";
import {
  ALERT_TYPES,
  DATA_PER_PAGE_LIMIT,
} from "../../../../../../../constants";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { setUserDetailDrawer } from "../../../../../../../redux/slicers/general";
import { addChecklistResponsibilityRequest } from "../../../../../../../redux/slicers/transactionchecklist";
import { getAllRoomsMembersRequest } from "../../../../../../../redux/slicers/transactiondetail";
import { assignResponsiblePartiesRequest } from "../../../../../../../redux/slicers/transactionmilestone";
import {
  inputFieldRule,
  toastAlert,
} from "../../../../../../../services/utils";
import { Images } from "../../../../../../../themes";
import "./styles.scss";

const AddResponsiblePartiesModal = ({
  handleClose,
  preview,
  slug,
  visibilities,
  lastUpdated,
}) => {
  //STATES
  const [open, setOpen] = useState("");
  const [selectedParties, setselectedParties] = useState([]);

  //CONST VALS
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  // CUSTOM DISPATCH
  const [getRoomMembers, isMembersLoading] = useCustomDispatch(
    getAllRoomsMembersRequest
  );
  const [assignResponsibleParties, assignPartiesLoader] = useCustomDispatch(
    addChecklistResponsibilityRequest
  );

  // REDUX DATA
  const { allRoomsMembers } = useSelector((state) => state.transactiondetail);

  //HANDLERS
  const closeHandler = () => {
    handleClose();
    form.resetFields();
    setselectedParties([]);
  };
  const handleSubmit = (values) => {
    const payload = {
      ids: values.parties,
    };
    assignResponsibleParties({
      payload,
      pathParams: slug,
      logic(res) {
        closeHandler();
        toastAlert(res.message, ALERT_TYPES.SUCCESS);
      },
    });
  };
  const handleRemoveMember = (index) => {
    const tempdata = [...selectedParties];
    tempdata.splice(index, 1);
    setselectedParties(tempdata);
    form.setFieldsValue({
      parties: tempdata,
    });
  };

  //HOOKS
  useEffect(() => {
    let visibility = [];
    visibilities?.map((item) => visibility.push(item.id));
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: 0,
      checklistSlug: slug,
      room: visibility.toString(),
      isActive: true,
    };
    if (preview) {
      getRoomMembers({ queryParams });
    }
  }, [preview]);

  const partyMemberItem = (data, index) => {
    const userdetail = allRoomsMembers.find((x) => x.id === data);
    return (
      <div className="member-box" key={index}>
        <ProfileImage
          username={userdetail.username}
          color={userdetail.profilebg}
          image={userdetail.image}
          isBlocked={!userdetail?.isActive}
          onClick={() => {
            dispatch(
              setUserDetailDrawer({
                visibility: true,
                id: userdetail.userid ?? userdetail.id,
              })
            );
          }}
          size={10}
        />
        <div className="detail">
          <h4>{userdetail.username}</h4>
          <p>{userdetail.email}</p>
        </div>
        <span
          className="close-btn"
          onClick={() => {
            handleRemoveMember(index);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>
    );
  };

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={522}
      className="add-responsible-modal"
      getContainer={false}
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div
            className="close-btn"
            onClick={() => {
              handleClose();
              form.resetFields();
              setselectedParties([]);
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>Assign Responsibility Parties</h3>
            <p>Last Modified on {lastUpdated}</p>
          </div>
          <Form form={form} onFinish={handleSubmit}>
            <Form.Item
              name="parties"
              rules={inputFieldRule({
                name: "Parties",
                isWhiteSpace: false,
                isRequired: true,
              })}
            >
              <Select
                mode="multiple"
                loading={isMembersLoading}
                placeholder="Email/Name"
                className="form-select"
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase()) ||
                  option.key.toLowerCase().includes(input.toLowerCase())
                }
                open={open}
                onClick={() => {
                  setOpen(!open);
                }}
                onFocus={() => setOpen(true)}
                onBlur={() => setOpen(false)}
                onSearch={() => setOpen(true)}
                onChange={(e) => {
                  setOpen(false);
                  setselectedParties(e);
                }}
                maxTagTextLength={10}
                maxTagCount={4}
                optionLabelProp="label"
              >
                {allRoomsMembers.map((item, index) => (
                  <Select.Option
                    value={item.id}
                    key={item.email}
                    label={item.username}
                  >
                    <div className="invite-modal-user-autocomplete-options">
                      <ProfileImage
                        color={item.profilebg}
                        image={item.image}
                        username={item.username}
                        isBlocked={!item?.isActive}
                        size={10}
                        classname="thumb"
                      />
                      <div className="option-user-info">
                        <h3>{item.username}</h3>
                        <p>{item.email}</p>
                      </div>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <div className="selected-partymembers">
              {selectedParties.map((item, index) =>
                partyMemberItem(item, index)
              )}
            </div>
            <div className="modal-footer">
              <ButtonComponent text="Add +" isLoading={assignPartiesLoader} />
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default AddResponsiblePartiesModal;
