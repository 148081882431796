import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import { useCustomDispatch } from "../../../../../helpers/useCustomDispatch";
import {
  changeMileStoneStatusRequest,
  deleteMileStoneRequest,
  getMileStonesRequest,
} from "../../../../../redux/slicers/transactionmilestone";
import {
  DATA_PER_PAGE_LIMIT,
  PERMISSIONS_ACCESS,
} from "../../../../../constants";
import { ActivityLogs } from "../../../../../components";
import {
  MilestoneHeader,
  MilestonDetailView,
  DeleteMilestoneModal,
  UpdateMilestoneModal,
  MilestoneDataTable,
} from "./components";
import _ from "lodash";
import "./styles.scss";
import { getActionPermission } from "../../../../../services/utils";

const Milestones = ({ transactionslug, roomslug }) => {
  // STATES
  const [searchText, setSearchText] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedSortKey, setselectedSortKey] = useState(null);
  const [selectedSortFilter, setselectedSortFilter] = useState(true);
  const [total, setTotal] = useState(null);
  const [offset, setOffset] = useState(0);
  const [isMoreData, setMoreData] = useState(false);
  const [isDataUpdated, setDataUpdated] = useState(false);
  const [isActivityDataUpdated, setActivityDataUpdated] = useState(false);
  const [isStatusChange, setStatusChange] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [milestoneDetails, showMilestoneDetails] = useState(false);
  const [updateModalPreview, setUpdateModalPreview] = useState(false);
  const [deleteModalPreview, setDeleteModalPreview] = useState(false);
  const [createModalPreview, setCreateModalPreview] = useState(false);

  // REDUX DATA
  const { data: transactiondetail } = useSelector(
    (state) => state.transactiondetail
  );
  const { milestones } = useSelector((state) => state.transactionmilestone);

  // CUSTOM DISPATCH
  const [getMileStones, milestoneLoading] =
    useCustomDispatch(getMileStonesRequest);
  const [milestoneStatusChange, statusChangeLoader] = useCustomDispatch(
    changeMileStoneStatusRequest
  );
  const [deleteMilestone, deleteMileStoneLoader] = useCustomDispatch(
    deleteMileStoneRequest
  );
  // CONST VALS
  const isRoom =
    !_.isEmpty(transactiondetail) && roomslug !== transactiondetail.roomSlug;
  const isLoading = milestoneLoading && !isMoreData;
  const {
    TRANSACTION_ACTIVITY_LOGS,
    CREATE_MILESTONE,
    EDIT_MILESTONE,
    DELETE_MILESTONE,
  } = PERMISSIONS_ACCESS;
  const isEditAllowed = getActionPermission(EDIT_MILESTONE);
  const isCreateAllowed = getActionPermission(CREATE_MILESTONE);
  const isDeleteAllowed = getActionPermission(DELETE_MILESTONE);
  const activityLogsVisible = getActionPermission(TRANSACTION_ACTIVITY_LOGS);

  // HELPERS
  const getMilestonesHelper = (isSearch) => {
    let queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: isSearch ? 0 : offset,
      roomSlug: roomslug,
      sort: selectedSortKey,
      order: selectedSortFilter ? "asc" : "desc",
      ...selectedFilters,
    };

    if (searchText !== "") {
      queryParams["keyword"] = searchText;
    }
    if (isSearch) {
      setMoreData(false);
    }
    getMileStones({
      queryParams: queryParams,
      logic(res) {
        setTotal(res.total);
      },
    });
  };

  //HOOKS
  useEffect(() => {
    getMilestonesHelper(false);
  }, [
    offset,
    selectedSortKey,
    selectedSortFilter,
    roomslug,
    isRoom,
    isDataUpdated,
    selectedFilters,
    isStatusChange,
  ]);

  useEffect(() => {
    getMilestonesHelper(true);
  }, [searchText]);

  // HANDLERS
  const handleSearch = (val) => {
    setSearchText(val);
  };
  const setSortKeyHandler = (key) => {
    const isAlreadyExist = selectedSortKey === key;
    setselectedSortKey(key);
    setselectedSortFilter(isAlreadyExist ? !selectedSortFilter : false);
    setMoreData(false);
    setOffset(0);
  };
  const handleApplyFilter = (filters) => {
    setSelectedFilters(filters);
  };
  const handleNextData = () => {
    setOffset(offset + DATA_PER_PAGE_LIMIT);
    setMoreData(true);
  };
  const setDataUpdatedHandler = (slug) => {
    if (slug) {
      setSelectedData(slug);
      showMilestoneDetails(true);
    }
    setDataUpdated(!isDataUpdated);
  };
  const createModalPreviewHandler = () => {
    setCreateModalPreview(!createModalPreview);
  };
  const showMilestoneDetailHandler = (slug) => {
    setSelectedData(slug);
    showMilestoneDetails(true);
  };
  const showMilestoneDetailCloseHandler = () => {
    showMilestoneDetails(false);
    setActivityDataUpdated(!isActivityDataUpdated);
  };
  const updateModalPreviewHandler = (data) => {
    setSelectedData(data);
    setUpdateModalPreview(true);
  };
  const deleteModalPreviewHandler = (data) => {
    setSelectedData(data);
    setDeleteModalPreview(true);
  };
  const milestoneStatusChangeHandler = (slug) => {
    milestoneStatusChange({
      pathParams: slug,
      logic() {
        setStatusChange(!isStatusChange);
      },
    });
  };
  const deleteMilstoneHandler = (id) => {
    deleteMilestone({
      pathParams: id,
      logic() {
        getMilestonesHelper(false);
        setDeleteModalPreview(false);
      },
    });
  };

  return (
    <section className="milestones">
      <Row>
        <Col xs={24} xl={18}>
          <MilestoneHeader
            handleSearch={handleSearch}
            transactionslug={transactionslug}
            handleApplyFilter={handleApplyFilter}
            createModalHandler={createModalPreviewHandler}
            createModalPreview={createModalPreview}
            dataUpdateHandler={setDataUpdatedHandler}
            isCreateAllowed={isCreateAllowed}
          />
          <div className="card mt-3">
            <MilestoneDataTable
              sortData={setSortKeyHandler}
              selectedSort={selectedSortKey}
              selectedSortFilter={selectedSortFilter}
              handleFetch={handleNextData}
              showMilestoneDetail={showMilestoneDetailHandler}
              milestoneStatusChange={milestoneStatusChangeHandler}
              deleteModalHandler={deleteModalPreviewHandler}
              updateModalHandler={updateModalPreviewHandler}
              isLoading={isLoading}
              total={total}
              data={milestones}
              statusChangeLoader={statusChangeLoader}
              isEditAllowed={isEditAllowed}
              isDeleteAllowed={isDeleteAllowed}
            />
          </div>
        </Col>
        <Col xs={24} xl={6}>
          <ActivityLogs
            title="Milestones"
            height={300}
            isWholePage
            isRoom={true}
            roomSlug={roomslug}
            transactionslug={transactionslug}
            isDataUpdated={isActivityDataUpdated}
            customqueryParams={{ isMilestone: true }}
            isAccess={activityLogsVisible}
          />
        </Col>
      </Row>
      <MilestonDetailView
        milestoneslug={selectedData}
        modalPreview={milestoneDetails}
        handleClose={showMilestoneDetailCloseHandler}
        handleDeleteResponse={() => getMilestonesHelper(false)}
        isEditAllowed={isEditAllowed}
        isDeleteAllowed={isDeleteAllowed}
        activityLogsVisible={activityLogsVisible}
      />
      <UpdateMilestoneModal
        isUpdate
        data={selectedData}
        handleClose={() => {
          setUpdateModalPreview(false);
        }}
        preview={updateModalPreview}
      />
      <DeleteMilestoneModal
        data={selectedData}
        handleClose={() => {
          setDeleteModalPreview(false);
        }}
        handleDelete={deleteMilstoneHandler}
        preview={deleteModalPreview}
        loader={deleteMileStoneLoader}
      />
    </section>
  );
};

export default Milestones;
