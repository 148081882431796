import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Modal, Select } from "antd";
import { ButtonComponent } from "../../../../../../../components";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { createDocumentRequest } from "../../../../../../../redux/slicers/transactiondocuments";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./styles.scss";
import { useEffect } from "react";
import { Images } from "../../../../../../../themes";
import { useState } from "react";
import { getRoomsWithMembersRequest } from "../../../../../../../redux/slicers/transactiondetail";
import { DATA_PER_PAGE_LIMIT } from "../../../../../../../constants";
import { inputFieldRule } from "../../../../../../../services/utils";

const ConfirmDocModal = ({
  handleClose,
  handleSuccess,
  data,
  preview,
  isAsked,
}) => {
  //CUSTOM DISPATCH
  const [createDocument, createLoader] = useCustomDispatch(
    createDocumentRequest
  );
  const [getTransactionRooms, isRoomsLoading] = useCustomDispatch(
    getRoomsWithMembersRequest
  );

  //REDUX DATA
  const { rooms, data: trans } = useSelector(
    (state) => state.transactiondetail
  );

  //CONST VALS
  const [form] = Form.useForm();

  //HOOKS
  useEffect(() => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: 0,
      transactionId: trans?.id,
    };
    getTransactionRooms({
      queryParams,
      logic(res) {
        form.setFieldValue("room", data?.roomSlug);
      },
    });
  }, [preview]);

  //HANDLERS

  const handleConfirm = () => {
    data["roomSlug"] = form.getFieldValue("room");
    createDocument({
      payload: data,
      logic(res) {
        !res.isAsked ? handleSuccess(res?.data) : isAsked();
        handleClose();
      },
    });
  };

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={522}
      className="confirm-doc-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>Upload Verfication</h3>
            <p>You Are uploading this Document to following Room </p>
          </div>
          <Form form={form} className="confirmation-form">
            <Form.Item
              name={"room"}
              rules={inputFieldRule({
                name: "Room",
              })}
            >
              <Select
                suffixIcon={<Images.SelectArrow />}
                loading={isRoomsLoading}
              >
                {rooms?.map((item, index) => (
                  <Select.Option value={item.slug} key={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </div>
        <div className="modal-footer">
          <div className="cancel-btn" onClick={handleClose}>
            Cancel
          </div>
          <ButtonComponent
            text="Continue"
            isLoading={createLoader}
            onClick={handleConfirm}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDocModal;
