import React, { useState, useEffect } from "react";
import { Radio, Row, Col } from "antd";
import {
  CardLoader,
  EmptyBox,
  HorizontalLine,
  ProfileImage,
  Tabs,
} from "../../../../../components";
import { TRANSACTIONADMIN_PAGE_TABS } from "../../../../../constants";
import { useCustomDispatch } from "../../../../../helpers/useCustomDispatch";
import {
  getUserCompanyMembersRequest,
  getUserPermissionsRequest,
  updateUserPermissionsRequest,
} from "../../../../../redux/slicers/transactiondetail";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

export default function TransactionAdminSetting({ data }) {
  //STATES
  const [selectedUser, setSelectedUser] = useState(0);
  const [selectedTab, setSelectedTab] = useState(
    TRANSACTIONADMIN_PAGE_TABS[0].id
  );
  const [selectAllCheckBox, setSelectAllCheckBox] = useState(false);

  //CUSTOM DISPATCH
  const [getCompanyMembers, membersLoader] = useCustomDispatch(
    getUserCompanyMembersRequest
  );
  const [getPermissions, permissionsLoader] = useCustomDispatch(
    getUserPermissionsRequest
  );
  const [updatePermissions, updateLoader] = useCustomDispatch(
    updateUserPermissionsRequest
  );

  //CONST VALS
  const { id } = useParams();
  const isLoading = membersLoader || permissionsLoader || updateLoader;
  const selectedTabText = TRANSACTIONADMIN_PAGE_TABS?.find(
    (x) => x.id === selectedTab
  ).text;

  //REDUX DATA
  const { companyMembers, permissions } = useSelector(
    (state) => state.transactiondetail
  );

  const { companyName } = useSelector((state) => state.user.data);

  //HANDLERS
  const handlePermissionChange = (val, key) => {
    const queryParams = { module: selectedTab };
    const payload = {
      userId: companyMembers?.[selectedUser]?.id,
      permissionValue: val,
      permissionKey: key,
    };
    updatePermissions({ pathParams: id, payload, queryParams });
  };

  //HOOKS
  useEffect(() => {
    getCompanyMembers({ pathParams: id });
  }, []);

  useEffect(() => {
    if (companyMembers.length > 0) {
      getPermissions({
        pathParams: id,
        queryParams: {
          module: selectedTab,
          userId: companyMembers?.[selectedUser]?.id,
        },
      });
    }
  }, [selectedTab, selectedUser, companyMembers]);

  //CUSTOM COMPONENTS
  const MemberListItem = ({ index, data }) => {
    const condition = selectedUser == index;
    const prevCondition = selectedUser - 1 == index;
    return (
      <>
        <div
          onClick={() => setSelectedUser(index)}
          className={`user-card flex-center c-p ${condition && "active"}`}
        >
          <ProfileImage
            image={data?.image}
            username={data?.username}
            color={data?.profilebg}
            isBlocked={!data?.isActive}
          />
          <div className="info">
            <h4>{data?.username}</h4>
            <p>{data?.email}</p>
          </div>
        </div>
        <HorizontalLine
          style={{
            visibility: condition || prevCondition ? "hidden" : "visible",
          }}
          className="horizontal-line"
        />
      </>
    );
  };

  return (
    <div className="settings-wapper">
      {membersLoader ? (
        <CardLoader height={450} />
      ) : companyMembers?.length > 0 ? (
        <>
          <div className="card tabs-container">
            <h4>{data?.name}</h4>
            <Tabs
              buttonsList={TRANSACTIONADMIN_PAGE_TABS}
              setState={setSelectedTab}
              state={selectedTab}
              disabled={isLoading}
            />
          </div>
          {/* <div className="transaction-admin"> */}
          <Row gutter={[15, 0]} className="transaction-admin">
            <Col span={6}>
              <div className="rooms-list">
                {membersLoader ? (
                  <CardLoader height={350} />
                ) : (
                  companyMembers?.map((item, index) => (
                    <MemberListItem key={index} data={item} index={index} />
                  ))
                )}
              </div>
            </Col>
            <Col span={18}>
              <div className="card links">
                {permissionsLoader || membersLoader ? (
                  <CardLoader height={350} />
                ) : (
                  <>
                    <h4 className="link-heading">
                      Do following actions for {selectedTabText} performed by
                      the selected participant of {companyName} <br /> need
                      approval of Transaction Admin?
                    </h4>
                    {permissions.map((item, index) => (
                      <div
                        key={index}
                        className={`radio-wrap link ${
                          index % 2 && "link-background"
                        }`}
                      >
                        <h4>{item?.text}</h4>
                        <Radio.Group
                          buttonStyle="solid"
                          name="radiogroup"
                          defaultValue={item?.selectedOption}
                          onChange={(val) =>
                            handlePermissionChange(val.target.value, item?.slug)
                          }
                          disabled={data?.status}
                        >
                          {item?.options?.map((item, index) => (
                            <Radio value={item?.key} key={index}>
                              {item?.value}
                            </Radio>
                          ))}
                        </Radio.Group>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </Col>
          </Row>
          {/* </div> */}
        </>
      ) : (
        <EmptyBox height={600} text={"No Users Found"} />
      )}
    </div>
  );
}
