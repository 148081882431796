import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "antd";
import React from "react";
import { ButtonComponent } from "../../../../../../../components";
import "./styles.scss";

const ConfirmActionModal = ({
  handleClose,
  preview,
  description,
  loader,
  handleConfirm,
  title,
}) => {
  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={460}
      className="confirmation-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>{title}</h3>
            <p>{description}</p>
          </div>

          <div className="modal-footer">
            <ButtonComponent
              text="Confirm"
              isLoading={loader}
              style={{ width: "50%", marginTop: "20px" }}
              onClick={handleConfirm}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmActionModal;
