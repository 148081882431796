import React from "react";
import { Images } from "../../../../themes";
import EmojiPicker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { useState } from "react";

function Emojis({
  onEmojiSelect,
  open,
  setOpen,
  isReact = false,
  emojiSize = 24,
  perLine = 9,
}) {
  //HANDLERS
  const emojiOpenHandler = () => {
    setOpen(!open);
  };
  return (
    <div className="emoji-wrapper">
      {isReact ? (
        <Images.AddReact className="c-p" onClick={emojiOpenHandler} />
      ) : (
        <Images.Emoji className="c-p" onClick={emojiOpenHandler} />
      )}
      {open && (
        <div className="emoji-dropdown-wrapper">
          <EmojiPicker
            className="em"
            data={data}
            theme="light"
            onClickOutside={emojiOpenHandler}
            onEmojiSelect={onEmojiSelect}
            emojiSize={emojiSize}
            perLine={perLine}
          />
        </div>
      )}
    </div>
  );
}

export default Emojis;
