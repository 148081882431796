import React, { useState, useRef } from "react";
import { Row, Col } from "antd";
import { ActivityLogs } from "../../../../../components";
import { ChecklistDashboard, ChecklistDetail } from "./partials";
import { useParams } from "react-router-dom";
import "./styles.scss";
import { getActionPermission } from "../../../../../services/utils";
import { PERMISSIONS_ACCESS } from "../../../../../constants";

const Checklist = ({ isDeleted }) => {
  // CONST VALS
  const { room, dataid } = useParams();
  const LISTING_ACTIVITYLOGS_PARAMS = {
    isChecklist: true,
    room,
  };
  const DETAIL_ACTIVITYLOGS_PARAMS = {
    checklistSlug: dataid,
  };
  const {
    TRANSACTION_ACTIVITY_LOGS,
    ADD_CHECKLIST,
    EDIT_CHECKLIST,
    DELETE_CHECKLIST,
    ADD_DOCS,
    VIEW_DOCS,
  } = PERMISSIONS_ACCESS;

  const isEditAllowed = getActionPermission(EDIT_CHECKLIST);
  const isCreateAllowed = getActionPermission(ADD_CHECKLIST);
  const isDeleteAllowed = getActionPermission(DELETE_CHECKLIST);
  const activityLogsVisible = getActionPermission(TRANSACTION_ACTIVITY_LOGS);
  const docShareAllowed = getActionPermission(ADD_DOCS);
  const isDocViewAllowed = getActionPermission(VIEW_DOCS);
  return (
    <section className="checklist-wrapper">
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={18}>
          {dataid ? (
            <ChecklistDetail
              isEditAllowed={isEditAllowed}
              isDeleteAllowed={isDeleteAllowed}
              isDocViewAllowed={isDocViewAllowed}
            />
          ) : (
            <ChecklistDashboard
              isDeleted={isDeleted}
              isCreateAllowed={isCreateAllowed}
              isEditAllowed={isEditAllowed}
              isDeleteAllowed={isDeleteAllowed}
              isShareAllowed={docShareAllowed}
            />
          )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={6}>
          <div className="activtybox-wrapper">
            <ActivityLogs
              height={300}
              isWholePage
              isDataUpdated={dataid}
              customqueryParams={
                dataid
                  ? DETAIL_ACTIVITYLOGS_PARAMS
                  : LISTING_ACTIVITYLOGS_PARAMS
              }
              isAccess={activityLogsVisible}
            />
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Checklist;
