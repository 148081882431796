import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "antd";
import React from "react";
import { Images } from "../../themes";
import ButtonComponent from "../ButtonComponent";
import "./styles.scss";

function SkipQuestionModal({ modalPreview, handleClose, handleSkip }) {
  return (
    <Modal
      destroyOnClose
      visible={modalPreview}
      centered
      footer={null}
      width={490}
      className="skip-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <Images.SkipQuestion />
          <h3>You Can Skip The General Question</h3>
          <p>
            But If you skip general questions you will not get some checklist
            suggestion based on questions and more. but you can edit this again
            after you created transaction.
          </p>
          <ButtonComponent
            text="Skip The Questions"
            className="skip-btn"
            onClick={handleSkip}
          />
        </div>
      </div>
    </Modal>
  );
}

export default SkipQuestionModal;
