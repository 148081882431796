// @flow
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  rooms: [],
  folders: [],
  documents: [],
  links: [],
  notes: [],
  roomdata: {},
  folderdata: {},
  roomsfolders: [],
  currentVisibility: [],
  remainingVisibility: [],
  permissions: [],
  userPermissions: [],
};

const DataroomReducer = createSlice({
  name: "datarooms",
  initialState,
  reducers: {
    // DATA ROOM LISTING
    getDataRoomsRequest() {},
    getDataRoomsSuccess(state, action) {
      let temp = [...state.rooms];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.rooms = action.payload.data;
        return;
      }
      state.rooms = temp;
    },

    // DATA ROOM DETAIL
    getDataRoomDetailRequest() {},
    getDataRoomDetailSuccess(state, action) {
      state.roomdata = action.payload;
    },

    // CREATE DATA ROOM
    createDataRoomRequest() {},

    // UPDATE DATA ROOM
    updateDataRoomRequest() {},
    updateDataRoomSuccess(state, action) {
      let temp = [...state.rooms];
      const dataindex = temp.findIndex((x) => x.id == action.payload.id);
      temp[dataindex] = action.payload;
      state.rooms = temp;
      state.roomdata.name = action.payload.name;
    },

    // DELETE DATA ROOM
    deleteDataRoomRequest() {},
    deleteDataRoomSuccess(state, action) {
      const linkIndex = _.findIndex(state.rooms, {
        id: action.payload,
      });
      if (linkIndex >= 0) {
        state.rooms.splice(linkIndex, 1);
      }
    },

    // FOLDER LISTING OF ALL ROOM
    getDataRoomsFoldersRequest() {},
    getDataRoomsFoldersSuccess(state, action) {
      state.roomsfolders = action.payload;
    },

    // FOLDER LISTING OF ROOM
    getFoldersRequest() {},
    getFoldersSuccess(state, action) {
      let temp = [...state.folders];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.folders = action.payload.data;
        return;
      }
      state.folders = temp;
    },

    // FOLDER DETAIL
    getFolderDetailRequest() {},
    getFolderDetailSuccess(state, action) {
      state.folderdata = action.payload;
    },

    // CREATE FOLDER
    createFolderRequest() {},

    // UPDATE FOLDER
    updateFolderRequest() {},
    updateFolderSuccess(state, action, isDetail) {
      let temp = [...state.folders];
      const dataindex = temp.findIndex((x) => x.id == action.payload.id);
      temp[dataindex] = action.payload;
      state.folders = temp;
      if (isDetail) {
        state.folderdata = action.payload;
      }
    },

    // MOVE FOLDER
    moveFolderRequest() {},

    // DELETE FOLDER
    deleteFolderRequest() {},
    deleteFolderSuccess(state, action) {
      const linkIndex = _.findIndex(state.folders, {
        id: action.payload,
      });
      if (linkIndex >= 0) {
        state.folders.splice(linkIndex, 1);
      }
    },

    // GET DOCUMENTS
    getDocumentsRequest() {},
    getDocumentsSuccess(state, action) {
      let temp = [...state.documents];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.documents = [...action.payload.data];
        return;
      }
      state.documents = [...temp];
    },

    // CREATE DOCUMENT
    createDocumentRequest() {},

    // UPDATE DOCUMENT
    updateDocumentRequest() {},
    updateDocumentSuccess(state, action) {
      let temp = [...state.documents];
      const dataindex = temp.findIndex((x) => x.id == action.payload.id);
      temp[dataindex] = action.payload;
      state.documents = temp;
    },

    // MOVE DOCUMENT
    moveDocumentRequest() {},

    // DELETE DOCUMENT
    deleteDocumentRequest() {},
    deleteDocumentSuccess(state, action) {
      const dataIndex = _.findIndex(state.documents, {
        id: action.payload,
      });
      if (dataIndex >= 0) {
        state.documents.splice(dataIndex, 1);
      }
    },

    // GET LINKS
    getLinksRequest() {},
    getLinksSuccess(state, action) {
      let temp = [...state.links];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.links = [...action.payload.data];
        return;
      }
      state.links = [...temp];
    },

    // CREATE LINK
    createLinkRequest() {},

    // UPDATE LINK
    updateLinkRequest() {},
    updateLinkSuccess(state, action) {
      let temp = [...state.links];
      const dataindex = temp.findIndex((x) => x.id == action.payload.id);
      temp[dataindex] = action.payload;
      state.links = temp;
    },

    // DELETE LINK
    deleteLinkRequest() {},
    deleteLinkSuccess(state, action) {
      const linkIndex = _.findIndex(state.links, {
        id: action.payload,
      });
      if (linkIndex >= 0) {
        state.links.splice(linkIndex, 1);
      }
      let tempNotes = [...state.notes];

      state.notes = tempNotes.filter((x) => x.linkId !== action.payload);
    },

    // GET NOTES
    getNotesRequest() {},
    getNotesSuccess(state, action) {
      let temp = [...state.notes];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.notes = action.payload.data;
        return;
      }
      state.notes = temp;
    },
    // CREATE NOTE
    createNoteRequest() {},

    // UPDATE NOTE
    updateNoteRequest() {},
    updateNoteSuccess(state, action) {
      let temp = [...state.notes];
      const dataindex = temp.findIndex((x) => x.id == action.payload.id);
      temp[dataindex] = action.payload;
      state.notes = temp;
    },

    // DELETE NOTE
    deleteNoteRequest() {},
    deleteNoteSuccess(state, action) {
      const noteIndex = _.findIndex(state.notes, {
        id: action.payload,
      });
      if (noteIndex >= 0) {
        state.notes.splice(noteIndex, 1);
      }
    },

    // GET VISIBILITY
    getRoomVisibilitiesRequest() {},
    getRoomVisibilitiesSuccess(state, action) {
      state.currentVisibility = action.payload;
    },

    // GET REAMAINING ROOM VISIBILITY
    getRemainingRoomVisibilitiesRequest() {},
    getRemainingRoomVisibilitiesSuccess(state, action) {
      state.remainingVisibility = action.payload;
    },

    //ADD ROOM VISIBILITY
    addRoomVisibilityRequest() {},
    addRoomVisibilitySuccess(state, action) {
      state.roomdata = action.payload;
    },

    //REMOVE ROOM VISIBILITY
    removeRoomVisibilityRequest() {},
    removeRoomVisibilitySuccess(state, action) {
      state.roomdata = action.payload;
    },

    // GET REAMAINING FOLDER VISIBILITY
    getRemainingFolderVisibilitiesRequest() {},
    getRemainingFolderVisibilitiesSuccess(state, action) {
      state.remainingVisibility = action.payload;
    },

    //ADD FOLDER VISIBILITY
    addFolderVisibilityRequest() {},
    addFolderVisibilitySuccess(state, action) {
      state.folderdata = action.payload;
    },

    //REMOVE FOLDER VISIBILITY
    removeFolderVisibilityRequest() {},
    removeFolderVisibilitySuccess(state, action) {
      state.folderdata = action.payload;
    },

    //CHANGE ADMIN STATUS
    makeRoomAdminRequest() {},
    makeRoomAdminSuccess(state, action) {
      state.roomdata = action.payload;
    },

    //CHECK LAST ADMIN
    checkLastAdminRequest() {},

    //LEAVE DATAROOM
    leaveDataRoomRequest() {},

    //REMOVE ADMIN
    removeDataroomAdminRequest() {},
    removeDataroomAdminSuccess(state, action) {
      state.roomdata = action.payload;
    },

    //GET PERMISSION LIST
    getDataroomPermissionsRequest() {},
    getDataroomPermissionsSuccess(state, action) {
      state.permissions = action.payload;
    },

    //GET USER PERMISSION LIST
    getDataroomUsersRequest() {},
    getDataroomUsersSuccess(state, action) {
      let temp = [...state.userPermissions];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.userPermissions = action.payload.data;
        return;
      }
      state.userPermissions = temp;
    },

    //UPDATE PERMISSION
    updateDataroomPermissionRequest() {},
  },
});

export const {
  getDataRoomsRequest,
  getDataRoomsSuccess,
  getDataRoomDetailRequest,
  getDataRoomDetailSuccess,
  createDataRoomRequest,
  updateDataRoomRequest,
  updateDataRoomSuccess,
  deleteDataRoomRequest,
  deleteDataRoomSuccess,
  getDataRoomsFoldersRequest,
  getDataRoomsFoldersSuccess,
  getFoldersRequest,
  getFoldersSuccess,
  getFolderDetailRequest,
  getFolderDetailSuccess,
  createFolderRequest,
  updateFolderRequest,
  updateFolderSuccess,
  moveFolderRequest,
  deleteFolderRequest,
  deleteFolderSuccess,
  getDocumentsRequest,
  getDocumentsSuccess,
  createDocumentRequest,
  updateDocumentRequest,
  updateDocumentSuccess,
  moveDocumentRequest,
  deleteDocumentRequest,
  deleteDocumentSuccess,
  getLinksRequest,
  getLinksSuccess,
  createLinkRequest,
  updateLinkRequest,
  updateLinkSuccess,
  deleteLinkRequest,
  deleteLinkSuccess,
  getNotesRequest,
  getNotesSuccess,
  createNoteRequest,
  updateNoteRequest,
  updateNoteSuccess,
  deleteNoteRequest,
  deleteNoteSuccess,
  getRoomVisibilitiesRequest,
  getRoomVisibilitiesSuccess,
  addRoomVisibilityRequest,
  addRoomVisibilitySuccess,
  getRemainingRoomVisibilitiesRequest,
  getRemainingRoomVisibilitiesSuccess,
  removeRoomVisibilityRequest,
  removeRoomVisibilitySuccess,
  addFolderVisibilityRequest,
  addFolderVisibilitySuccess,
  getRemainingFolderVisibilitiesRequest,
  getRemainingFolderVisibilitiesSuccess,
  removeFolderVisibilityRequest,
  removeFolderVisibilitySuccess,
  makeRoomAdminRequest,
  makeRoomAdminSuccess,
  checkLastAdminRequest,
  leaveDataRoomRequest,
  removeDataroomAdminRequest,
  removeDataroomAdminSuccess,
  getDataroomPermissionsRequest,
  getDataroomPermissionsSuccess,
  getDataroomUsersRequest,
  getDataroomUsersSuccess,
  updateDataroomPermissionRequest,
} = DataroomReducer.actions;

export default DataroomReducer.reducer;
