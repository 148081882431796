import { Form } from "antd";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ButtonComponent, PassWordsFields } from "../../../components";
import Header from "../../../components/header";
import images from "../../../themes/images";

import {
  ALERT_TYPES,
  ANTD_SCROLL_TO_ERROR_FIELDS_CONFIG,
  LOGIN_ROUTE,
  COPYRIGHTSTRING,
} from "../../../constants";
import { useCustomDispatch } from "../../../helpers/useCustomDispatch";
import { resetPasswordRequest } from "../../../redux/slicers/user";
import { toastAlert } from "../../../services/utils";
import "../styles.scss";

const ResetPass = () => {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const [resetPassReq, resetPassReqLoader] =
    useCustomDispatch(resetPasswordRequest);
  const [form] = Form.useForm();
  const handleFormFinish = (values) => {
    const payload = {
      email: locationState?.email,
      otp: locationState?.otp,
      password: values.password,
    };

    resetPassReq({
      payload,
      logic(response) {
        toastAlert("Password reset successfully", ALERT_TYPES.SUCCESS);
        navigate(LOGIN_ROUTE);
      },
    });
  };

  return (
    <>
      <Header />
      <section className="signing-wrapper">
        <div className="form-wrapper">
          <div className="back-btn">
            <Link to={-1}>
              <img src={images.backArrow} alt="back Arraow" /> Back
            </Link>
          </div>
          <h3 className="auth-title">
            <span>Reset Your Password DealTru</span>
          </h3>
          <Form
            scrollToFirstError={ANTD_SCROLL_TO_ERROR_FIELDS_CONFIG}
            form={form}
            className="form"
            onFinish={handleFormFinish}>
            <PassWordsFields autoFocus={true} />
            <Form.Item>
              <ButtonComponent
                className="login-btn"
                isLoading={resetPassReqLoader}
                text="Reset Password"
              />
            </Form.Item>
          </Form>
        </div>
        <div>
          <p className="copyright-text">{COPYRIGHTSTRING}</p>
        </div>
      </section>
    </>
  );
};

export default ResetPass;
