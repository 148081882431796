import { call, fork, put, take, takeLatest } from "redux-saga/effects";
import {
  ADD_CHECKLIST_RESPONSIBILITY,
  ADD_CHECKLIST_VISIBILITY,
  ADD_DOC_TO_CHECKLIST,
  callRequest,
  CHANGE_CHECKLIST_STATUS,
  CREATE_CHECKLIST,
  DELETE_CHECKLIST,
  GET_CHECKLIST_DETAILS,
  GET_CHECKLIST_LISTING,
  GET_CHECKLIST_SUGGESTIONS,
  GET_CHECKLIST_TYPES,
  GET_TRANSACTION_CHECKLIST,
  REMOVE_CHECKLIST_DOC,
  REMOVE_CHECKLIST_RESPONSIBILITY,
  REMOVE_CHECKLIST_VISIBILITY,
  RESTORE_CHECKLIST,
  UPDATE_CHECKLIST,
} from "../../config/webService";
import { ALERT_TYPES } from "../../constants";
import {
  manipulateChecklistDetail,
  manipulateChecklistList,
  manipulateSuggestionsList,
} from "../../dataManipulator/transactionchecklist";
import { toastAlert } from "../../services/utils";
import {
  addChecklistResponsibilityRequest,
  addChecklistResponsibilitySuccess,
  addChecklistVisibilityRequest,
  addChecklistVisibilitySuccess,
  addDocToChecklistRequest,
  addDocToChecklistSuccess,
  changeChecklistStatusRequest,
  changeChecklistStatusSuccess,
  createChecklistRequest,
  createChecklistSuccess,
  deleteChecklistRequest,
  deleteChecklistSuccess,
  getChecklistDetailRequest,
  getChecklistDetailSuccess,
  getChecklistRequest,
  getChecklistSuccess,
  getChecklistSuggestionsRequest,
  getChecklistSuggestionsSuccess,
  getChecklistTypesRequest,
  getChecklistTypesSuccess,
  getTransactionChecklistsRequest,
  getTransactionChecklistsSuccess,
  removeChecklistDocRequest,
  removeChecklistDocSuccess,
  removeChecklistResponsibilityRequest,
  removeChecklistResponsibilitySuccess,
  removeChecklistVisibilityRequest,
  removeChecklistVisibilitySuccess,
  updateChecklistRequest,
  updateChecklistSuccess,
} from "../slicers/transactionchecklist";

//CREATE CHECKLIST
function* createChecklist() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(createChecklistRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CREATE_CHECKLIST,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked) yield put(createChecklistSuccess(response.data));
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

//UPDATE CHECKLIST
function* updateChecklist() {
  while (true) {
    const {
      payload: { pathParams, payload, responseCallback },
    } = yield take(updateChecklistRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPDATE_CHECKLIST,
        pathParams,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked)
          yield put(
            updateChecklistSuccess(manipulateChecklistDetail(response.data))
          );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

//DELETE CHECKLIST
function* deleteChecklist() {
  while (true) {
    const {
      payload: { pathParams, queryParams, responseCallback },
    } = yield take(deleteChecklistRequest.type);

    try {
      const response = yield call(callRequest, {
        url: DELETE_CHECKLIST,
        queryParams,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked) yield put(deleteChecklistSuccess(response.data));
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

//GET CHECKLIST DETAIL
function* getChecklistDetail() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getChecklistDetailRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_CHECKLIST_DETAILS,
        pathParams,
      });
      if (response.status) {
        yield put(
          getChecklistDetailSuccess(manipulateChecklistDetail(response.data))
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

//GET CHECKLIST LIST BY ROOM
function* getChecklistListing(action) {
  const {
    payload: { pathParams, queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_CHECKLIST_LISTING,
      pathParams,
      queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getChecklistSuccess({
          isPaginated: isMoreData,
          data: manipulateChecklistList(response.data),
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

//GET CHECKLIST LIST BY TRANSACTION
function* getTransactionChecklistListing() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getTransactionChecklistsRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_TRANSACTION_CHECKLIST,
        pathParams,
      });
      if (response.status) {
        yield put(
          getTransactionChecklistsSuccess(
            manipulateChecklistList(response.data, true)
          )
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

//GET CHECKLIST SUGGESTIONS
function* getChecklistSuggestions() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getChecklistSuggestionsRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_CHECKLIST_SUGGESTIONS,
        pathParams,
      });
      if (response.status) {
        yield put(
          getChecklistSuggestionsSuccess(
            manipulateSuggestionsList(response.data)
          )
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

//GET CHECKLIST TYPES
function* getChecklistTypes() {
  while (true) {
    const {
      payload: { responseCallback },
    } = yield take(getChecklistTypesRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_CHECKLIST_TYPES,
      });
      if (response.status) {
        yield put(getChecklistTypesSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

//ADD DOC TO CHECKLIST
function* addDoctoChecklist() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(addDocToChecklistRequest.type);

    try {
      const response = yield call(callRequest, {
        url: ADD_DOC_TO_CHECKLIST,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked)
          yield put(
            addDocToChecklistSuccess(manipulateChecklistDetail(response.data))
          );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

//REMOVE DOC FROM CHECKLIST
function* removeChecklistDoc() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(removeChecklistDocRequest.type);

    try {
      const response = yield call(callRequest, {
        url: REMOVE_CHECKLIST_DOC,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked)
          yield put(
            removeChecklistDocSuccess(manipulateChecklistDetail(response.data))
          );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

//ADD VISIBILITY TO CHECKLIST
function* addChecklistVisibility() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(addChecklistVisibilityRequest.type);

    try {
      const response = yield call(callRequest, {
        url: ADD_CHECKLIST_VISIBILITY,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked)
          yield put(
            addChecklistVisibilitySuccess(
              manipulateChecklistDetail(response.data)
            )
          );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

//REMOVE VISIBILITY FROM CHECKLIST
function* removeChecklistVisibility() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(removeChecklistVisibilityRequest.type);

    try {
      const response = yield call(callRequest, {
        url: REMOVE_CHECKLIST_VISIBILITY,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked)
          yield put(
            removeChecklistVisibilitySuccess(
              manipulateChecklistDetail(response.data)
            )
          );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

//ADD RESPONSIBILITY TO CHECKLIST
function* addChecklistResponsibility() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(addChecklistResponsibilityRequest.type);

    try {
      const response = yield call(callRequest, {
        url: ADD_CHECKLIST_RESPONSIBILITY,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked)
          yield put(
            addChecklistResponsibilitySuccess(
              manipulateChecklistDetail(response.data)
            )
          );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

//REMOVE RESPONSIBILITY FROM CHECKLIST
function* removeChecklistResponsibility() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(removeChecklistResponsibilityRequest.type);

    try {
      const response = yield call(callRequest, {
        url: REMOVE_CHECKLIST_RESPONSIBILITY,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked)
          yield put(
            removeChecklistResponsibilitySuccess(
              manipulateChecklistDetail(response.data)
            )
          );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

//CHANGE CHECKLIST STATUS
function* changeChecklistStatus() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback, isList, id },
    } = yield take(changeChecklistStatusRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CHANGE_CHECKLIST_STATUS,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked)
          yield put(
            changeChecklistStatusSuccess({
              data: response.data,
              isList: isList,
              id: id,
            })
          );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

export default function* root() {
  yield takeLatest(getChecklistRequest.type, getChecklistListing);
  yield fork(getTransactionChecklistListing);
  yield fork(getChecklistDetail);
  yield fork(getChecklistTypes);
  yield fork(createChecklist);
  yield fork(updateChecklist);
  yield fork(deleteChecklist);
  yield fork(addDoctoChecklist);
  yield fork(removeChecklistDoc);
  yield fork(addChecklistVisibility);
  yield fork(removeChecklistVisibility);
  yield fork(addChecklistResponsibility);
  yield fork(removeChecklistResponsibility);
  yield fork(changeChecklistStatus);
  yield fork(getChecklistSuggestions);
}
