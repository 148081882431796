import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { getWorkingGroupsRequest } from "../../../../../../../redux/slicers/transactiondetail";
import RoomWorkingGroups from "./room";
import TransactionWorkingGroups from "./transaction";
import "./styles.scss";

const WorkingGroup = ({
  isRoom,
  roomslug,
  adminLogin,
  onWorkingGroupChange,
  isTransactionAdminLogin,
  checkUserStatusHandler,
  checkApiUserStatusHandler,
  canEdit,
}) => {
  //REDUX DATA
  const {
    data: transactiondetail,
    roomAccess,
    workingGroups,
  } = useSelector((state) => state.transactiondetail);

  //CUSTOM DISPATCH
  const [getWorkingGroups, workingGroupsLoader] = useCustomDispatch(
    getWorkingGroupsRequest
  );

  // HELPERS
  const getWorkingGroupsHelper = () => {
    getWorkingGroups({
      pathParams: transactiondetail?.id,
      queryParams: { room: isRoom ? roomslug : null },
    });
  };

  //HOOKS
  useEffect(() => {
    if (transactiondetail?.id) {
      checkApiUserStatusHandler(() => {
        getWorkingGroupsHelper();
      });
    }
  }, [transactiondetail?.id, roomslug, roomAccess]);

  // HANDLERS
  const getUpdatedData = () => {
    getWorkingGroupsHelper();
    isRoom && onWorkingGroupChange();
  };
  return (
    <div className="card work-group-detail">
      {isRoom ? (
        <RoomWorkingGroups
          checkUserStatusHandler={checkUserStatusHandler}
          isAdminLogin={adminLogin}
          groups={workingGroups}
          roomAcess={roomAccess}
          loader={workingGroupsLoader}
          onAddMember={getUpdatedData}
          canEdit={canEdit}
        />
      ) : (
        <TransactionWorkingGroups
          groups={workingGroups}
          loader={workingGroupsLoader}
          isAdminLogin={isTransactionAdminLogin}
          onAddMember={getUpdatedData}
          canEdit={canEdit}
        />
      )}
    </div>
  );
};

export default WorkingGroup;
