import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import { Images } from "../../../../themes";
import { FileCard } from "../components";
import { useNavigate, useParams } from "react-router-dom";
import {
  CardLoader,
  CustomInfiniteScroll,
  EmptyBox,
} from "../../../../components";
import { useCustomDispatch } from "../../../../helpers/useCustomDispatch";
import {
  getResourceFilesListRequest,
  getResourceFolderDetailRequest,
} from "../../../../redux/slicers/resources";
import { useSelector } from "react-redux";
import { DATA_PER_PAGE_LIMIT } from "../../../../constants";

function FolderDetails() {
  //STATES
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchtext, setSearchText] = useState("");
  const [isMoreData, setMoreData] = useState(false);

  //CONST VALS
  const navigate = useNavigate();
  const { folder } = useParams();

  //CUSTOM DISPATCH
  const [getFolderDetails, folderDetailsLoader] = useCustomDispatch(
    getResourceFolderDetailRequest
  );
  const [getFilesList, getFileLoader] = useCustomDispatch(
    getResourceFilesListRequest
  );

  //REDUX DATA
  const { data, fileList } = useSelector((state) => state.resources);

  // HELPERS
  const getFilesHelper = (isSearched) => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: offset,
    };
    if (isSearched) {
      queryParams["search"] = searchtext;
      setMoreData(false);
    }

    getFilesList({
      queryParams,
      pathParams: folder,
      logic: (response) => {
        setTotal(response.total);
      },
    });
  };

  //HOOKS
  useEffect(() => {
    getFolderDetails({ pathParams: folder });
  }, []);

  useEffect(() => {
    getFilesHelper(searchtext !== "");
  }, [searchtext, offset]);

  //HANDLERS
  const handleSearch = (val) => {
    setOffset(0);
    setSearchText(val);
  };

  const handleNextData = (filter) => {
    setOffset(offset + DATA_PER_PAGE_LIMIT);
    setMoreData(true);
  };

  return folderDetailsLoader ? (
    <CardLoader height={"100vh"} size={20} />
  ) : (
    <>
      <div className="top-container">
        <Images.BackArrow className="c-p" onClick={() => navigate(-1)} />
        <div className="top-heading-nav">
          <h3>{data?.name}</h3>
          <p>View Details Below</p>
        </div>
      </div>
      <div className="card">
        <>
          <div className="search-box">
            <Images.Search />
            <input
              type="text"
              placeholder={"Search any File..."}
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
            />
          </div>
          <div className="resource-content">
            <div className="folder-container">
              <h3>Resources</h3>
              {getFileLoader && !isMoreData ? (
                <CardLoader />
              ) : fileList.length > 0 ? (
                <CustomInfiniteScroll
                  dataLength={fileList.length}
                  total={total}
                  next={handleNextData}
                  isWholePage={true}
                  minHeight={300}
                  maxHeight={420}
                  className="rsources-infinite-scroll">
                  <Row gutter={[16, 16]}>
                    {fileList.map((item, index) => (
                      <Col
                        xxl={8}
                        xl={12}
                        lg={24}
                        md={24}
                        sm={24}
                        xs={24}
                        key={index}>
                        <FileCard title={item.name} url={item.url} />
                      </Col>
                    ))}
                  </Row>
                </CustomInfiniteScroll>
              ) : (
                <EmptyBox height={300} size={16} imgwidt={30} />
              )}
            </div>
          </div>
        </>
      </div>
    </>
  );
}

export default FolderDetails;
