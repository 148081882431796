import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { useCustomDispatch } from "../../../../../../helpers/useCustomDispatch";
import {
  getChecklistRequest,
  changeChecklistStatusRequest,
} from "../../../../../../redux/slicers/transactionchecklist";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  CustomInfiniteScroll,
  EditDeleteDropdown,
  CardHeader,
  EmptyBox,
  StatusToggle,
  VisibleToPeople,
  CardLoader,
  NoAccessBox,
  DisabledTooltip,
} from "../../../../../../components";
import { Images } from "../../../../../../themes";
import {
  DeleteChecklistModal,
  CreateChecklistModal,
  ChecklistVisibilityModal,
} from "../../checklist/components";
import {
  DATA_PER_PAGE_LIMIT,
  TRANSACTION_TABS_SLUGS,
} from "../../../../../../constants";

const Checklist = ({ roomslug, canView, canEdit, canDelete }) => {
  //STATES
  const [selectedFilter, setselectedFilter] = useState(null);
  const [isRoomDetail, setisRoomDetail] = useState(false);
  const [selectedVisibility, setSelectedVisibility] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [visiblityModal, setvisiblityModal] = useState(false);
  const [updateModal, setupdateModal] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [selectedSortKey, setselectedSortKey] = useState(null);
  const [selectedSortFilter, setselectedSortFilter] = useState(true);
  const [total, setTotal] = useState(null);
  const [offset, setOffset] = useState(0);
  const [isMoreData, setMoreData] = useState(false);

  //REDUX DATA
  const { checklist } = useSelector((state) => state.transactionchecklist);

  // CONST VALS
  const HEIGHT = 400;
  const DATA_SIZE = 6;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const TABLE_COLUMNS = [
    {
      title: "Detail",
      isSort: true,
      key: "name",
    },
    {
      title: "Type",
      isSort: true,
      key: "type",
    },
    {
      title: "Target date",
      isSort: true,
      key: "targetDate",
    },
    {
      title: "Visible",
      isSort: false,
      isIcon: true,
      icon: <Images.Visibility />,
    },
    {
      title: "Actions",
      isSort: false,
      key: "status",
    },
  ];

  //CUSTOM DISPATCH
  const [getChecklists, isLoading] = useCustomDispatch(getChecklistRequest);
  const [changeChecklistStatus, statusLoader] = useCustomDispatch(
    changeChecklistStatusRequest
  );

  // HELPERS
  const getChecklistHelper = (isSearch) => {
    let queryParams = {
      limit: DATA_SIZE,
      offset: 0,
      status: selectedFilter,
      sort: selectedSortKey,
      order: selectedSortFilter ? "asc" : "desc",
    };

    if (isSearch !== "") {
      queryParams["search"] = isSearch;
    }
    if (isSearch) {
      setMoreData(false);
    }
    getChecklists({
      queryParams,
      pathParams: roomslug,
      logic(res) {
        setTotal(res.total);
      },
    });
  };

  //HOOKS
  useEffect(() => {
    getChecklistHelper();
  }, [offset, selectedSortKey, selectedSortFilter, selectedFilter, roomslug]);

  //HANDLERS
  const sortData = (key) => {
    const isAlreadyExist = selectedSortKey === key;
    setselectedSortKey(key);
    setselectedSortFilter(isAlreadyExist ? !selectedSortFilter : false);
    setMoreData(false);
    setOffset(0);
  };

  const setFilterHandler = (val) => {
    setselectedFilter(val);
    setMoreData(false);
  };

  const handleSearch = (val) => {
    getChecklistHelper(val);
  };

  const handleNextData = () => {
    setOffset(offset + DATA_PER_PAGE_LIMIT);
    setMoreData(true);
  };

  const viewAllChecklist = () => {
    navigate(`${pathname}/${TRANSACTION_TABS_SLUGS.CHECKLIST}`);
  };

  const detailPageNavigator = (slug) => {
    navigate(`${pathname}/${TRANSACTION_TABS_SLUGS.CHECKLIST}/${slug}`);
  };

  const visiblityModalHandler = (item, isRoom) => {
    setvisiblityModal(true);
    setSelectedVisibility(item);
    setisRoomDetail(isRoom);
  };

  const visiblityModalPreviewHandler = () => {
    setvisiblityModal(!visiblityModal);
  };

  const updateModalHandler = (item) => {
    setupdateModal(true);
    setSelectedData(item);
  };

  const updateModalPreviewHandler = () => {
    setupdateModal(!updateModal);
  };

  const deleteModalHandler = (item) => {
    setdeleteModal(true);
    setSelectedData(item);
  };

  const deleteModalPreviewHandler = () => {
    setdeleteModal(!deleteModal);
  };

  const checklistStatusChangeHandler = (slug, id) => {
    changeChecklistStatus({
      pathParams: slug,
      otherkeys: { isList: true, id: id },
    });
  };

  // CUSTOM COMPONENET
  const checklistBox = () => (
    <div className="checklist-datatable">
      <div className="table-head">
        {TABLE_COLUMNS.map((item, index) => {
          const isSelected = item.key === selectedSortKey;
          const selectedCaret = isSelected && !selectedSortFilter;
          return (
            <span
              className={`head-ttl ${isSelected ? "active" : ""}`}
              key={index}
            >
              <span
                className={item.isSort ? "c-p" : ""}
                onClick={() => {
                  item.isSort && sortData(item.key);
                }}
              >
                {item.title}
                {item?.isIcon && item?.icon}
                {item.isSort && (
                  <FontAwesomeIcon
                    icon={selectedCaret ? faCaretUp : faCaretDown}
                    className="sort-icon"
                  />
                )}
              </span>
            </span>
          );
        })}
      </div>
      <div className="table-body">
        {!canView ? (
          <NoAccessBox height={HEIGHT} />
        ) : isLoading && !isMoreData ? (
          <CardLoader height={HEIGHT} size={18} />
        ) : (
          <CustomInfiniteScroll
            dataLength={checklist?.length}
            total={total}
            maxHeight={HEIGHT}
            minHeight={HEIGHT}
          >
            {checklist?.length >= 1 ? (
              checklist?.map((item, index) => (
                <div
                  className="body-row c-p"
                  key={index}
                  onClick={() => {
                    detailPageNavigator(item.slug);
                  }}
                >
                  <div className="body-col">
                    <div className="detail-col">
                      <div className="title">{item.name}</div>
                      <div className="listed">
                        <span className="label">Listed</span>
                        <VisibleToPeople
                          size={6}
                          data={item.visibility}
                          maxLength={4}
                          isRoom
                          detailModalHandler={() => {
                            visiblityModalHandler(item.visibility, true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="body-col">
                    <span className="date">{item.type?.value}</span>
                  </div>
                  <div className="body-col">
                    <span className="date">
                      {item.targetDate ? item.targetDate : "---------------"}
                    </span>
                  </div>
                  <div className="body-col">
                    <VisibleToPeople
                      data={item.responsibleParties}
                      maxLength={5}
                      isCompany
                      detailModalHandler={() => {
                        visiblityModalHandler(item.responsibleParties);
                      }}
                    />
                  </div>
                  <div className="body-col">
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <DisabledTooltip
                        innerSec={
                          <StatusToggle
                            check={item.status}
                            handleStatusChange={() => {
                              checklistStatusChangeHandler(item.slug, item.id);
                            }}
                            confirmLoader={statusLoader}
                            confirmDescription={
                              "Are you sure you want to change this checklist's status?"
                            }
                            isStatic={!canEdit}
                          />
                        }
                        visible={!canEdit}
                      />
                    </span>
                    <span
                      className="action"
                      style={{ minWidth: 35 }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {(canDelete || canEdit) && !item.status && (
                        <EditDeleteDropdown
                          isEditable={canEdit}
                          onEditClick={() => {
                            updateModalHandler(item);
                          }}
                          isDelete={canDelete}
                          onDeleteClick={() => {
                            deleteModalHandler(item);
                          }}
                        />
                      )}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <EmptyBox height={HEIGHT} />
            )}
          </CustomInfiniteScroll>
        )}
      </div>
    </div>
  );

  return (
    <>
      <div className="card mt-3">
        <CardHeader
          viewAllNavigation={viewAllChecklist}
          title="Checklist"
          handleSearch={handleSearch}
          selectedFilter={selectedFilter}
          setFilter={setFilterHandler}
          viewAll={total > DATA_SIZE}
          placeholder="Search any checklist item..."
        />
        {checklistBox()}
      </div>
      <ChecklistVisibilityModal
        preview={visiblityModal}
        handleClose={visiblityModalPreviewHandler}
        data={selectedVisibility}
        isRoom={isRoomDetail}
        isCompany={!isRoomDetail}
        title={isRoomDetail ? "Listed groups" : "Visible"}
      />
      <CreateChecklistModal
        isUpdate
        data={selectedData}
        preview={updateModal}
        handleClose={updateModalPreviewHandler}
      />
      <DeleteChecklistModal
        data={selectedData}
        preview={deleteModal}
        handleClose={deleteModalPreviewHandler}
      />
    </>
  );
};

export default Checklist;
