import React, { useEffect, useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Modal, Select } from "antd";
import { useSelector } from "react-redux";
import { ButtonComponent, ProfileImage } from "../../../../../../../components";
import { DATA_PER_PAGE_LIMIT } from "../../../../../../../constants";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { getTransactionRoomsRequest } from "../../../../../../../redux/slicers/transactiondetail";
import { assignVisibilityToRoomsRequest } from "../../../../../../../redux/slicers/transactionmilestone";
import {
  getLetterFromName,
  inputFieldRule,
} from "../../../../../../../services/utils";
import "./styles.scss";

const TABBAR = ["Add Individuals ", "Assign Parties"];

const AddMilestoneVisibilityModal = ({
  transactionslug,
  handleClose,
  preview,
  slug,
  onVisibilityChange,
  lastUpdated,
}) => {
  //STATES
  const [open, setOpen] = useState("");
  const [selectedRooms, setselectedRooms] = useState([]);

  //CONST VALS
  const [form] = Form.useForm();

  // REDUX DATA
  const { rooms } = useSelector((state) => state.transactiondetail);

  //CUSTOM DISPATCH
  const [getTransactionRooms, isRoomsLoading] = useCustomDispatch(
    getTransactionRoomsRequest
  );
  const [addVisibility, visibilityLoader] = useCustomDispatch(
    assignVisibilityToRoomsRequest
  );

  //HANDLERS
  const handleSubmit = (values) => {
    const payload = {
      ids: values.visibility,
    };
    addVisibility({
      payload,
      pathParams: slug,
      logic() {
        onVisibilityChange();
        handleClose();
        form.resetFields();
        setselectedRooms([]);
      },
    });
  };

  const handleRemoveRoom = (index) => {
    const tempdata = [...selectedRooms];
    tempdata.splice(index, 1);
    setselectedRooms(tempdata);
    form.setFieldsValue({
      visibility: tempdata,
    });
  };

  //HOOKS
  useEffect(() => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: 0,
      milestoneSlug: slug,
    };
    if (preview) {
      getTransactionRooms({
        pathParams: transactionslug,
        queryParams,
      });
    }
  }, [preview]);

  //CUSTOM COMPONENTS
  const selectedRoomItem = (data, index) => {
    const roomDetail = rooms.find((x) => x.id === data);
    return (
      <div className="member-box" key={index}>
        <ProfileImage
          username={roomDetail.name}
          color={roomDetail.bgColor}
          size={12}
        />
        <div className="detail">
          <h4>{roomDetail.name}</h4>
        </div>
        <span
          className="close-btn"
          onClick={() => {
            handleRemoveRoom(index);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>
    );
  };

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={522}
      className="assign-member"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div
            className="close-btn"
            onClick={() => {
              form.resetFields();
              handleClose();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>Modify Milestone Visibility</h3>
            <p>Last Modified on {lastUpdated}</p>
          </div>
          <Form form={form} onFinish={handleSubmit}>
            <Form.Item
              name="visibility"
              rules={inputFieldRule({
                name: "Visibility",
                isWhiteSpace: false,
              })}
            >
              <Select
                mode="multiple"
                loading={isRoomsLoading}
                placeholder="Visibility"
                className="form-select"
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                maxTagCount={4}
                maxTagTextLength={10}
                open={open}
                onClick={() => {
                  setOpen(!open);
                }}
                onChange={(e) => {
                  setOpen(false);
                  setselectedRooms(e);
                }}
                onBlur={() => setOpen(false)}
              >
                {rooms.map((item, index) => {
                  const isEven = index % 2 == 0;
                  return (
                    <Select.Option
                      value={item.id}
                      key={index}
                      label={item.name}
                    >
                      <div className="visibility-options">
                        <span
                          role="img"
                          style={{
                            backgroundColor: item.bgColor,
                          }}
                        >
                          {getLetterFromName(item.name)}
                        </span>
                        <p>{item.name}</p>
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            {selectedRooms.length > 0 && (
              <div className="selected-partymembers">
                {selectedRooms.map((item, index) =>
                  selectedRoomItem(item, index)
                )}
              </div>
            )}
            <div className="modal-footer">
              <ButtonComponent text="Add" isLoading={visibilityLoader} />
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default AddMilestoneVisibilityModal;
