import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ViewAllBtn,
  CardLoader,
  EmptyBox,
  CustomInfiniteScroll,
  ProfileImage,
} from "../../../../components";
import { useCustomDispatch } from "../../../../helpers/useCustomDispatch";
import { setUserDetailDrawer } from "../../../../redux/slicers/general";
import { getTeamMembersRequest } from "../../../../redux/slicers/company";
import {
  DATA_PER_PAGE_LIMIT,
  ENTERPRISE_TEAM_MANAGEMENT_ROUTE,
} from "../../../../constants";

function TeamMembers() {
  //STATES
  const [total, setTotal] = useState(null);

  //CONST VALS
  const dispatch = useDispatch();
  const BOX_HEIGHT = 255;
  const DATA_LENGTH = 10;

  // REDUX DATA
  const { teamMembers } = useSelector((state) => state.company);

  // CUSTOM DISPATCH
  const [getTeamMembers, isLoading] = useCustomDispatch(getTeamMembersRequest);

  // HOOKS
  useEffect(() => {
    const params = {
      active: true,
      limit: 10,
      offset: 0,
    };
    getTeamMembers({
      queryParams: params,
      logic(res) {
        setTotal(res.total);
      },
    });
  }, []);

  //HANDLERS

  return (
    <div className="card">
      <ViewAllBtn
        disableViewAll={total < 10}
        title={"Team Member"}
        size={20}
        link={ENTERPRISE_TEAM_MANAGEMENT_ROUTE}
      />
      <div className="activity-logs-container">
        {isLoading ? (
          <CardLoader size={14} height={BOX_HEIGHT} />
        ) : (
          <CustomInfiniteScroll
            dataLength={DATA_LENGTH}
            total={DATA_LENGTH}
            isWholePage={false}
            maxHeight={BOX_HEIGHT}
            minHeight={BOX_HEIGHT}
          >
            {teamMembers.length >= 1 ? (
              teamMembers.map((item, i) => (
                <div
                  className="activity-log"
                  key={i}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ProfileImage
                    image={item.thumb}
                    color={item.profilebg}
                    username={item.username}
                    classname="user-thumb"
                    isBlocked={!item?.isActive}
                    size={12}
                    onClick={() =>
                      dispatch(
                        setUserDetailDrawer({
                          visibility: true,
                          id: item.id,
                        })
                      )
                    }
                  />
                  <div className="activity-detail">
                    <p
                      className="detail member c-p"
                      onClick={() =>
                        dispatch(
                          setUserDetailDrawer({
                            visibility: true,
                            id: item.id,
                          })
                        )
                      }
                    >
                      {item.username}
                    </p>
                    <p className="date">{item.jobTitle}</p>
                  </div>
                </div>
              ))
            ) : (
              <EmptyBox height={BOX_HEIGHT} imgwidth={80} size={15} />
            )}
          </CustomInfiniteScroll>
        )}
      </div>
    </div>
  );
}

export default TeamMembers;
