import React, { useState, useEffect } from "react";
import { Images } from "../../../../themes";
import { useSelector } from "react-redux";
import { useCustomDispatch } from "../../../../helpers/useCustomDispatch";
import {
  changeMileStoneStatusRequest,
  deleteMileStoneRequest,
  getMileStonesRequest,
} from "../../../../redux/slicers/transactionmilestone";
import {
  CardHeader,
  EditDeleteDropdown,
  EmptyBox,
  CustomInfiniteScroll,
  CardLoader,
  StatusToggle,
  DisabledTooltip,
} from "../../../../components";
import {
  MilestonDetailView,
  DeleteMilestoneModal,
  UpdateMilestoneModal,
} from "../../transactionDetail/partials/milestones/components";
import { DATA_PER_PAGE_LIMIT, PERMISSIONS_ACCESS } from "../../../../constants";
import { getActionPermission } from "../../../../services/utils";

function Milestones() {
  // CONST VALS
  const BOX_HEIGHT = 260;
  const { EDIT_MILESTONE, DELETE_MILESTONE } = PERMISSIONS_ACCESS;

  // STATES
  const [total, setTotal] = useState(null);
  const [offset, setOffset] = useState(0);
  const [isMoreData, setMoreData] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [milestoneDetails, showMilestoneDetails] = useState(false);
  const [updateModalPreview, setUpdateModalPreview] = useState(false);
  const [deleteModalPreview, setDeleteModalPreview] = useState(false);
  const [editAllowed, setEditAllowed] = useState(false);
  const [deleteAllowed, setDeleteAllowed] = useState(false);

  // REDUX DATA
  const { milestones } = useSelector((state) => state.transactionmilestone);

  // CUSTOM DISPATCH
  const [getMileStones, isLoading] = useCustomDispatch(getMileStonesRequest);
  const [milestoneStatusChange, statusChangeLoader] = useCustomDispatch(
    changeMileStoneStatusRequest
  );
  const [deleteMilestone, deleteMileStoneLoader] = useCustomDispatch(
    deleteMileStoneRequest
  );

  // HELPERS
  const getMilestonessHelper = (isSearch) => {
    const queryParams = { limit: DATA_PER_PAGE_LIMIT, offset: offset };
    if (isSearch) {
      queryParams["keyword"] = isSearch;
      setMoreData(false);
    }
    getMileStones({
      queryParams: queryParams,
      logic(res) {
        setTotal(res.total);
      },
    });
  };

  //HOOKS
  useEffect(() => {
    getMilestonessHelper(false);
  }, [offset]);

  //HANDLERS
  const showMilestoneDetailHandler = (slug) => {
    setSelectedData(slug);
    showMilestoneDetails(true);
  };
  const updateModalPreviewHandler = (data) => {
    setSelectedData(data);
    setUpdateModalPreview(true);
  };
  const deleteeModalPreviewHandler = (data) => {
    setSelectedData(data);
    setDeleteModalPreview(true);
  };
  const handleSearch = (val) => {
    setOffset(0);
    getMilestonessHelper(val);
  };
  const handleNextData = () => {
    setOffset(offset + DATA_PER_PAGE_LIMIT);
    setMoreData(true);
  };
  const milestoneStatusChangeHandler = (slug) => {
    milestoneStatusChange({ pathParams: slug });
  };
  const deleteMilstoneHandler = (id) => {
    deleteMilestone({
      pathParams: id,
      logic() {
        getMilestonessHelper(false);
        setDeleteModalPreview(false);
      },
    });
  };
  return (
    <>
      <div className="card">
        <CardHeader
          isFilter
          handleSearch={handleSearch}
          title="Milestones"
          placeholder="Search any milestone..."
        />
        <div className="activity-logs-container">
          {isLoading & !isMoreData ? (
            <CardLoader size={14} height={BOX_HEIGHT} />
          ) : (
            <CustomInfiniteScroll
              dataLength={milestones?.length}
              total={total}
              next={handleNextData}
              isWholePage={false}
              minHeight={BOX_HEIGHT}
              maxHeight={BOX_HEIGHT}
            >
              {milestones.length >= 1 ? (
                milestones.map((item, i) => {
                  const isEditAllowed =
                    getActionPermission(EDIT_MILESTONE, item?.permissions) &&
                    !item?.transactionStatus;
                  const isDeleteAllowed =
                    getActionPermission(DELETE_MILESTONE, item?.permissions) &&
                    !item?.transactionStatus;
                  return (
                    <div className="activity-log milestone-log" key={i}>
                      <div
                        onClick={() => {
                          setDeleteAllowed(isDeleteAllowed);
                          setEditAllowed(isEditAllowed);
                          showMilestoneDetailHandler(item.slug);
                        }}
                        className="milestone-tile-wrap c-p"
                      >
                        <Images.Flag />
                        <div className="detail">
                          <p className="milestone-title">{item.name}</p>
                          <p className="date">{item.startDate}</p>
                        </div>
                      </div>
                      <p
                        className="typeDatePera"
                        style={{ flex: 1, marginLeft: "25px" }}
                      >
                        End Date:{" "}
                        <span>
                          {item.endDate ? item.endDate : "---------------"}
                        </span>
                      </p>
                      <DisabledTooltip
                        innerSec={
                          <StatusToggle
                            check={item.status}
                            handleStatusChange={() =>
                              milestoneStatusChangeHandler(item.slug)
                            }
                            confirmLoader={statusChangeLoader}
                            confirmDescription={
                              "Are you sure you want to change this milestone's status?"
                            }
                            isStatic={!isEditAllowed}
                          />
                        }
                        visible={!isEditAllowed}
                      />
                      <span style={{ minWidth: 35 }}>
                        {(isEditAllowed || isDeleteAllowed) && !item.status && (
                          <EditDeleteDropdown
                            isDelete={isDeleteAllowed}
                            isEditable={isEditAllowed}
                            style={{ marginRight: "15px" }}
                            onDeleteClick={() => {
                              deleteeModalPreviewHandler(item);
                            }}
                            onEditClick={() => {
                              updateModalPreviewHandler(item);
                            }}
                          />
                        )}
                      </span>
                    </div>
                  );
                })
              ) : (
                <EmptyBox height={BOX_HEIGHT} imgwidth={80} size={15} />
              )}
            </CustomInfiniteScroll>
          )}
        </div>
      </div>
      <MilestonDetailView
        milestoneslug={selectedData}
        modalPreview={milestoneDetails}
        handleClose={() => showMilestoneDetails(false)}
        handleDeleteResponse={() => getMilestonessHelper(false)}
        isEditAllowed={editAllowed}
        isDeleteAllowed={deleteAllowed}
      />
      <UpdateMilestoneModal
        isUpdate
        data={selectedData}
        handleClose={() => {
          setUpdateModalPreview(false);
        }}
        preview={updateModalPreview}
      />
      <DeleteMilestoneModal
        data={selectedData}
        handleClose={() => {
          setDeleteModalPreview(false);
        }}
        handleDelete={deleteMilstoneHandler}
        preview={deleteModalPreview}
        loader={deleteMileStoneLoader}
      />
    </>
  );
}

export default Milestones;
