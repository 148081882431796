import React, { useState } from "react";
import { Col, Form, Modal, Row, Select } from "antd";
import { ButtonComponent } from "../../../../../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Images } from "../../../../../../../themes";
import { CustomRoomSelect, DocVisibilityBox } from "../";
import "./styles.scss";
import { useEffect } from "react";
import {
  getAllDocsRoomVersionsRequest,
  getVersionHistoryRequest,
  getVersionHistorySucccess,
  shareDocRequest,
} from "../../../../../../../redux/slicers/transactiondocuments";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  inputFieldRule,
  toastAlert,
} from "../../../../../../../services/utils";
import { ALERT_TYPES } from "../../../../../../../constants";

function ShareDocModal({ handleClose, data, preview, handleSuccess }) {
  //STATES
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [finalRoom, setFinalRoom] = useState(null);
  const [getHistory, setGetHistory] = useState(false);
  const [selected, setSelected] = useState(null);

  //CONST VALS
  const [form] = Form.useForm();
  const { room } = useParams();
  const dispatch = useDispatch();

  //REDUX DATA
  const { versions, versionHistory } = useSelector(
    (state) => state.transactiondocuments
  );

  //CUSTOM DISPATCH
  const [getAllVersions, versionsLoader] = useCustomDispatch(
    getAllDocsRoomVersionsRequest
  );
  const [shareDoc, shareLoader] = useCustomDispatch(shareDocRequest);
  const [getVersionHistory, historyLoader] = useCustomDispatch(
    getVersionHistoryRequest
  );

  //HOOKS
  useEffect(() => {
    if (getHistory && data && preview) {
      form.setFieldValue("document", data?.slug);
      handleVersionChange(data?.slug);
    }
  }, [preview, data, getHistory]);

  useEffect(() => {
    preview &&
      getAllVersions({
        pathParams: room,
        logic() {
          setGetHistory(true);
        },
      });
  }, [preview]);

  useEffect(() => {
    if (finalRoom) {
      form.setFieldValue("rooms", finalRoom);
      form.validateFields();
    }
  }, [finalRoom]);

  //HANDLERS
  const closeHandler = () => {
    form.resetFields();
    setFinalRoom(null);
    setSelectedRoom(null);
    dispatch(getVersionHistorySucccess([]));
    setGetHistory(false);
    handleClose();
  };

  const handleSubmit = (val) => {
    const isDocument = versions?.find(
      (x) => x.slug === val.document
    )?.isMainVersion;
    const payload = {
      isDocument,
      versionSlug: val.document,
      roomId: finalRoom?.id,
    };

    shareDoc({
      payload,
      logic() {
        closeHandler();
        handleSuccess && handleSuccess();
      },
    });
  };

  const handleVersionChange = (val) => {
    const isDocument = versions?.find((x) => x?.slug === val)?.isMainVersion;
    getVersionHistory({
      queryParams: {
        isDocument: isDocument,
        versionSlug: val,
      },
    });
  };

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={803}
      className="share-document-modal"
      getContainer={false}
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <Row>
            <Col span={14} className="share-form-col">
              <div className="title">
                <h3>Share Document</h3>
                <p>Select Details Below</p>
              </div>

              <Form form={form} onFinish={handleSubmit}>
                <Form.Item
                  name={"document"}
                  rules={inputFieldRule({
                    name: "Document",
                    isWhiteSpace: false,
                  })}
                >
                  <Select
                    placeholder="Select Document"
                    className="doc-select-option"
                    suffixIcon={<Images.SelectArrow />}
                    loading={versionsLoader}
                    onChange={(val) => handleVersionChange(val)}
                    disabled={versionsLoader}
                  >
                    {versions?.map((item, index) => {
                      const isOdd = (index + 1) % 2 !== 0;
                      return (
                        <Select.Option
                          value={item.slug}
                          label={item.name}
                          key={index}
                          disabled={item.isDocument}
                          className={`${isOdd ? "share-option-odd" : ""}`}
                        >
                          <div className="exhibit-option">
                            {!item.isDocument ? (
                              <>
                                <Images.DocThumb />
                                <div className="exhibit-info">
                                  <h3>{item.name}</h3>
                                  <p>Version</p>
                                </div>
                              </>
                            ) : (
                              <h3 style={{ pointerEvents: "none" }}>
                                {item.name} (Main Document){" "}
                              </h3>
                            )}
                          </div>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="rooms"
                  style={{ marginBottom: "7px" }}
                  rules={inputFieldRule({
                    name: "Room",
                    isWhiteSpace: false,
                  })}
                >
                  <CustomRoomSelect
                    selectedRoom={selectedRoom}
                    setSelectedRoom={setSelectedRoom}
                    finalRoom={finalRoom}
                    setFinalRoom={setFinalRoom}
                  />
                </Form.Item>
                <p className="note">
                  New version will be created and made visible to the room(s)
                  selected
                </p>
                <div className="modal-footer">
                  <ButtonComponent
                    text="Share Document"
                    isLoading={shareLoader}
                  />
                </div>
              </Form>
            </Col>
            <Col span={10} className="visibility-wrapper">
              <div className="title visible">
                <h3>Visible To</h3>
                <p>All Rooms Details Below</p>
              </div>
              <DocVisibilityBox
                data={versionHistory}
                isLoading={historyLoader || versionsLoader}
                selected={selected}
                setSelected={setSelected}
              />
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
}

export default ShareDocModal;
