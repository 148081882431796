import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import {
  CardLoader,
  EmptyBox,
  StatusToggle,
  CustomInfiniteScroll,
} from "../../../components";
import {
  DATA_PER_PAGE_LIMIT,
  TRANSACTION_TABS_ROUTE,
  TRANSACTION_TABS_SLUGS,
} from "../../../constants";
import { useCustomDispatch } from "../../../helpers/useCustomDispatch";
import { getTransactionsRequest } from "../../../redux/slicers/transactions";
import { replaceValInString } from "../../../services/utils";
import { Images } from "../../../themes";
import "./styles.scss";

const TABLE_COLUMNS = [
  { title: "Name", key: "name" },
  { title: "Transaction Type", key: "type" },
  { title: "Status", key: "status" },
  { title: "Created Date", key: "created" },
  { title: "", key: "name" },
];

function TransactionAdmin() {
  // CONST VALS
  const navigate = useNavigate();

  // STATES
  const [selectedSortKey, setselectedSortKey] = useState(null);
  const [selectedSortFilter, setselectedSortFilter] = useState(true);
  const [offset, setOffset] = useState(0);
  const [isMoreData, setMoreData] = useState(false);
  const [total, setTotal] = useState(0);

  // REDUX DATA
  const { transactions } = useSelector((state) => state.transactions);

  // CUSTOM DISPATCH
  const [getTransactions, transactionLoader] = useCustomDispatch(
    getTransactionsRequest
  );

  // HELPERS
  const getTransactionsHelper = (isSearched) => {
    const queryParams = {
      isAdmin: true,
      limit: DATA_PER_PAGE_LIMIT,
      offset: offset,
      sort: selectedSortKey,
      order: selectedSortFilter ? "asc" : "desc",
    };
    if (isSearched) {
      queryParams["name"] = isSearched;
      setMoreData(false);
    }
    getTransactions({
      queryParams,
      logic: (res) => {
        setTotal(res.total);
      },
    });
  };

  // HOOKS
  useEffect(() => {
    getTransactionsHelper(false);
  }, [selectedSortKey, selectedSortFilter, offset]);

  // HANDLERS
  const handleSearch = (val) => {
    setOffset(0);
    getTransactionsHelper(val);
  };
  const setSortKeyHandler = (key) => {
    const isAlreadyExist = selectedSortKey === key;
    setselectedSortKey(key);
    setselectedSortFilter(isAlreadyExist ? !selectedSortFilter : false);
    setMoreData(false);
    setOffset(0);
  };
  const handleNextData = () => {
    setOffset(offset + DATA_PER_PAGE_LIMIT);
    setMoreData(true);
  };

  const navigateToTransaction = (transaction, room) => {
    const vals = {
      ":id": transaction,
      ":room": room,
      ":tab": TRANSACTION_TABS_SLUGS.SETTING,
    };
    navigate(replaceValInString(TRANSACTION_TABS_ROUTE, vals), {
      state: { navigateTab: 7 },
    });
  };

  return (
    <section className="enterprise-setting" style={{ padding: "0 20px" }}>
      <div className="top-container">
        <div className="top-heading">
          <h3>You are the transaction admin of:</h3>
          <p>All Transactions Below</p>
        </div>
      </div>
      <div className="card">
        <div className="search-box">
          <Images.Search />
          <input
            type="text"
            placeholder={"Search any Transactions..."}
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
          />
        </div>
        <div className="table-container">
          <div className="table-container-row border-bottom">
            {TABLE_COLUMNS.map((item, index) => {
              const isSelected = selectedSortKey === item.key;
              const selectedCaret = isSelected && !selectedSortFilter;
              return (
                <li
                  className={`table-title c-p ${isSelected ? "active" : ""}`}
                  key={index}
                  onClick={() => {
                    setSortKeyHandler(item.key);
                  }}
                >
                  {item.title}
                  {index !== TABLE_COLUMNS.length - 1 && (
                    <FontAwesomeIcon
                      icon={selectedCaret ? faCaretUp : faCaretDown}
                      className="sort-icon"
                    />
                  )}
                </li>
              );
            })}
          </div>
          {transactionLoader & !isMoreData ? (
            <CardLoader height={350} size={20} />
          ) : (
            <>
              {transactions?.length >= 1 ? (
                <CustomInfiniteScroll
                  dataLength={transactions?.length}
                  next={handleNextData}
                  total={total}
                  minHeight={400}
                  maxHeight={400}
                >
                  {transactions.map((item, i) => {
                    const isOdd = i % 2 == 1;
                    return (
                      <div
                        key={i}
                        className={`table-container-row c-p ${
                          isOdd && "back-gray"
                        }`}
                        onClick={() =>
                          navigateToTransaction(item.slug, item.roomSlug)
                        }
                      >
                        <li className="c-p">{item.name}</li>
                        <li>{item.type}</li>
                        <li>
                          <StatusToggle
                            check={item.status}
                            isStatic={true}
                            confirmDescription={
                              "Are you sure you want to change this transaction's status?"
                            }
                          />
                        </li>
                        <li>{item.createdAt}</li>
                        <li className="c-p">
                          {/* <Images.Edit
                            onClick={() =>
                              navigate(EDIT_TRANSACTION_ROUTE + item.slug)
                            }
                          /> */}
                        </li>
                      </div>
                    );
                  })}
                </CustomInfiniteScroll>
              ) : (
                <EmptyBox height={350} size={16} imgwidt={30} />
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default TransactionAdmin;
