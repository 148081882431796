import React from "react";
import { Table } from "antd";
function DataTable({ data, columns, pagination, height, isLoading }) {
  const filteredData = data?.map((item, index) => {
    return { ...item, key: index + 1 };
  });
  return (
    <div className={`ant-list-box data-tbl`} style={{ marginTop: 10 }}>
      <Table
        scroll={{
          y: height || "400px",
          x: "max-content",
        }}
        columns={columns}
        loading={isLoading}
        dataSource={isLoading ? [] : filteredData}
        pagination={false}
      />
    </div>
  );
}

export default DataTable;
