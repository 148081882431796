import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useCustomDispatch } from "../../../../../../helpers/useCustomDispatch";
import { getCompanyMembersRequest } from "../../../../../../redux/slicers/company";
import { DATA_PER_PAGE_LIMIT } from "../../../../../../constants";
import {
  CustomAutoComplete,
  CustomOtherSelect,
} from "../../../../../../components";
import { autoCompleteFieldRule } from "../../../../../../services/utils";

const AdvisorFields = ({
  form,
  parties,
  isPartiesLoading,
  partyTotal,
  setPartyOffset,
  advisories,
  isAdvisoryLoading,
  advisoryTotal,
  setAdvisoryOffset,
  companies,
  setCompanySearchText,
  handleSetField,
}) => {
  // STATES
  const [isCompanySelected, setSelectedCompany] = useState(null);
  const [isUserSelected, setUserSelected] = useState(null);
  // setSelectedCompany
  // CUSTOM DISPATCH
  const [getCompanyMembers, isMembersLoading] = useCustomDispatch(
    getCompanyMembersRequest
  );

  //REDUX DATA
  const { companyMembers } = useSelector((state) => state.company);

  // CONST VALS
  const isMembersOptionsVisible = !isMembersLoading && isCompanySelected;

  // HOOKS
  useEffect(() => {
    if (!isUserSelected) {
      form.resetFields(["firstName", "lastName"]);
    }
  }, [isUserSelected]);

  //HELPERS
  const getCompanyMembersHelper = (company, val) => {
    const queryParams = {
      offset: 0,
      limit: DATA_PER_PAGE_LIMIT,
      company,
    };
    if (val) queryParams["search"] = val;
    getCompanyMembers({
      queryParams,
    });
  };

  // HANDLERS
  const handleCompanySearch = (val) => {
    setCompanySearchText(val);
    setSelectedCompany(null);
  };

  const handleGetMembersByEnterprise = (value) => {
    let company = value.key;
    setSelectedCompany(company);
    getCompanyMembersHelper(company);
  };

  const handleUserSearch = (val) => {
    getCompanyMembersHelper(isCompanySelected, val);
    setUserSelected(null);
  };

  const handleSetUser = (val, name) => {
    const member = companyMembers?.find((x) => x[name] === val.value);
    setUserSelected(member);
    form.setFieldsValue({
      email: member?.email,
      firstName: member?.firstName,
      lastName: member?.lastName,
    });
  };

  return (
    <>
      <CustomOtherSelect
        required
        options={advisories}
        total={advisoryTotal}
        handleNextData={setAdvisoryOffset}
        isLoading={isAdvisoryLoading}
        handleSet={handleSetField}
        label={"Role"}
        name="role"
        rules={[
          () => ({
            validator(_, value) {
              return autoCompleteFieldRule({
                value,
                name: "Role",
                isMax: true,
              });
            },
          }),
        ]}
        placeholder="Select Your Role"
        labelProp={"name"}
        valueProp={"id"}
      />
      <hr className="hr-style" />

      <CustomAutoComplete
        required
        options={companies}
        setSearch={handleCompanySearch}
        handleSet={handleSetField}
        onOptionSelect={handleGetMembersByEnterprise}
        label="Party you are advising"
        name="company"
        rules={[
          () => ({
            validator(_, value) {
              return autoCompleteFieldRule({
                value,
                name: "Advised party",
                isMax: true,
              });
            },
          }),
        ]}
        placeholder="Party you are advising"
        labelProp={"name"}
        valueProp={"id"}
        isLogo={true}
      />
      <CustomOtherSelect
        required
        options={parties}
        total={partyTotal}
        handleNextData={setPartyOffset}
        isLoading={isPartiesLoading}
        handleSet={handleSetField}
        label={"Party Role"}
        name="companyRole"
        rules={[
          () => ({
            validator(_, value) {
              return autoCompleteFieldRule({
                value,
                name: "Advised Party's Role",
                isMax: true,
              });
            },
          }),
        ]}
        placeholder="Select Role"
        labelProp={"name"}
        valueProp={"id"}
      />
      <CustomAutoComplete
        label="Email"
        required
        options={isMembersOptionsVisible ? companyMembers : []}
        setSearch={handleUserSearch}
        onOptionSelect={handleSetUser}
        handleSet={handleSetField}
        isFilterDisabled
        name="email"
        rules={[
          () => ({
            validator(_, value) {
              return autoCompleteFieldRule({
                value,
                name: "Advised party's email",
                isEmail: true,
                isMax: true,
                max: 150,
              });
            },
          }),
        ]}
        placeholder="Email of party representative to initiate this transaction"
        labelProp={"email"}
        valueProp={"id"}
      />
      <CustomAutoComplete
        label="First Name"
        required
        options={isMembersOptionsVisible ? companyMembers : []}
        isFilterDisabled
        disabled={isUserSelected}
        setSearch={handleUserSearch}
        handleSet={handleSetField}
        onOptionSelect={handleSetUser}
        name="firstName"
        rules={[
          () => ({
            validator(_, value) {
              return autoCompleteFieldRule({
                value,
                name: "Advised party's first name",
                isMax: true,
              });
            },
          }),
        ]}
        placeholder="First Name of party representative to initiate transaction"
        labelProp={"firstName"}
        valueProp={"id"}
      />

      <CustomAutoComplete
        label="Last Name"
        required
        options={isMembersOptionsVisible ? companyMembers : []}
        setSearch={handleUserSearch}
        handleSet={handleSetField}
        onOptionSelect={handleSetUser}
        disabled={isUserSelected}
        name="lastName"
        rules={[
          () => ({
            validator(_, value) {
              return autoCompleteFieldRule({
                value,
                name: "Advised party's last name",
                isMax: true,
              });
            },
          }),
        ]}
        placeholder="Last Name of party representative to initiate transaction"
        labelProp={"lastName"}
        valueProp={"id"}
      />
    </>
  );
};

export default AdvisorFields;
