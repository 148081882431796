import React, { useState, useEffect } from "react";
import { Switch, Row, Col, Tooltip } from "antd";
import {
  ButtonComponent,
  TwoFactorAuthModal,
  HorizontalLine,
  CardLoader,
  ProfileImage,
} from "../../../components";
import { EDIT_ACCOUNT_ROUTE } from "../../../constants";
import { useCustomDispatch } from "../../../helpers/useCustomDispatch";
import {
  getProfileDetailRequest,
  update2faRequest,
  updateProfileVisibilityRequest,
} from "../../../redux/slicers/user";
import Infoitem from "./infoitem";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Images from "../../../themes/images";
import "./styles.scss";
import {
  getChatNotificationsRequest,
  updateChatNotiSettingRequest,
} from "../../../redux/slicers/chat";

const info = [
  {
    key: "user",
    title: "Name",
    data: "username",
    icon: null,
    action: null,
  },
  {
    key: "user",
    title: "Mobile Phone",
    data: "phone",
    icon: null,
    isNumberInput: true,
    action: (e) => (window.location.href = `mailto:${e}`),
  },
  {
    key: "user",
    title: "Work Phone",
    data: "workPhone",
    icon: null,
    isOptional: true,
    isNumberInput: true,
    action: (e) => (window.location.href = `mailto:${e}`),
  },
  {
    key: "user",
    title: "Email",
    data: "email",
    icon: null,
    action: (e) => (window.location.href = `mailto:${e}`),
  },
  {
    key: "company",
    title: "Company",
    data: "name",
    icon: "logo",
    action: null,
  },
  {
    isOptional: true,
    key: "user",
    title: "Job Title",
    data: "jobTitle",
    icon: null,
    action: null,
  },
  {
    key: "address",
    title: "Work Address",
    data: "detailedaddress",
    icon: null,
    action: null,
  },
];

const enterPriceInfo = [
  {
    key: "user",
    title: "Name",
    data: "username",
    icon: null,
    action: null,
  },
  {
    key: "user",
    title: "Mobile Phone",
    data: "phone",
    icon: null,
    isNumberInput: true,
    action: (e) => (window.location.href = `tel:${e}`),
  },
  {
    key: "user",
    title: "Work Phone",
    data: "workPhone",
    icon: null,
    isOptional: true,
    isNumberInput: true,
    action: (e) => (window.location.href = `tel:${e}`),
  },
  {
    key: "user",
    title: "Email",
    data: "email",
    icon: null,
    action: (e) => (window.location.href = `mailto:${e}`),
  },
  {
    isOptional: true,
    key: "user",
    title: "Job Title",
    data: "jobTitle",
    icon: null,
    action: null,
  },
];

const companyInfo = [
  {
    key: "company",
    title: "Name",
    data: "name",
    icon: null,
    action: null,
  },
  {
    isOptional: true,
    key: "company",
    title: "Website",
    data: "website",
    icon: null,
    action: (e) => window.open(e, "_blank"),
  },
  {
    key: "company",
    title: "Phone",
    data: "phone",
    icon: null,
    isOptional: true,
    isNumberInput: true,
    action: (e) => (window.location.href = `tel:${e}`),
  },
];

function Account() {
  // STATES
  const [tempStatus, settempStatus] = useState(false);
  const [status, setStatus] = useState(false);
  const [twoFaModalPreview, settwoFaModalPreview] = useState(false);
  const [isPublicProfile, setPublicProfile] = useState(false);
  const [changePassModalPreview, setChangePassModalPreview] = useState(false);
  const [notiKey, setNotiKey] = useState(null);

  //  CUSTOM DISPATCH
  const [updateTwoFactor, isSwitchLoading] =
    useCustomDispatch(update2faRequest);
  const [getProfileDetail, isDataLoading] = useCustomDispatch(
    getProfileDetailRequest
  );
  const [updateProfileVisibility, isLoading] = useCustomDispatch(
    updateProfileVisibilityRequest
  );
  const [getNotificationSettings, notificationsLoader] = useCustomDispatch(
    getChatNotificationsRequest
  );
  const [updateNotificationSettings, updatenotificationsLoader] =
    useCustomDispatch(updateChatNotiSettingRequest);

  // REDUX DATA
  const { isEnterpriseUser, user_id, tfa } = useSelector(
    (state) => state.user.data
  );
  const { notificationSettings } = useSelector((state) => state.chat);
  const userData = useSelector((state) => state.user.profile);

  // CONST VALS
  const navigate = useNavigate();

  // HOOKS
  useEffect(() => {
    settempStatus(userData.tfa);
    setStatus(userData.tfa);
  }, [userData]);

  useEffect(() => {
    getProfileDetail();
    getNotificationSettings();
  }, []);

  useEffect(() => {
    setPublicProfile(userData.publicProfile);
  }, [userData]);

  // HANDLERS
  const profileViewHandler = (data) => {
    setPublicProfile(data);
    updateProfileVisibility({
      logic(res) {
        setPublicProfile(res.data);
      },
      error() {
        setPublicProfile(userData.publicProfile);
      },
    });
  };
  const twoFactorHandler = (otp, selectedOption, requestId) => {
    const payload = { otp };
    if (selectedOption === "phone") {
      payload["request_id"] = requestId;
    }
    setStatus(tempStatus);
    updateTwoFactor({
      payload,
      logic(res) {
        setStatus(res.data);
        toastAlert(res.message);
      },
    });
  };

  const changePassModalHandler = () => {
    setChangePassModalPreview(!changePassModalPreview);
  };

  const twoFaModalPreviewCloseHandler = () => {
    settwoFaModalPreview(!twoFaModalPreview);
  };

  const twoFaModalPreviewHandler = (data) => {
    settempStatus(data);
    settwoFaModalPreview(true);
  };

  const handleUpdateSetting = (key, value) => {
    setNotiKey(key);
    const payload = { key, value };
    updateNotificationSettings({ payload });
  };

  // COMPONENTS
  const TWOFA_BUTTON = (
    <div style={{ marginRight: 10 }} className="two-factor-button">
      <div className="edit-profile-btn">
        <label>Two factor</label>
        <Switch
          disabled={isSwitchLoading}
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          checked={status}
          onClick={(data) => {
            twoFaModalPreviewHandler(data);
          }}
        />
      </div>
    </div>
  );

  return (
    <section className="parent-section">
      <div className="top-heading" style={{ marginTop: 30 }}>
        <h3 className="m-0">My Account</h3>
      </div>
      <div className="card design-shadow personal-info-card">
        {isDataLoading ? (
          <CardLoader height={350} />
        ) : (
          <>
            {/* personal info heading section */}
            <div className="sub-heading-parent">
              <div className="sub-heading">Personal Information</div>
              {/* toggle & button section */}
              <div>
                <div className="public-profile">
                  <span className="public-profile-toggle-wrapper">
                    <div className="pp-text">Public Profile</div>
                    <Switch
                      disabled={isLoading}
                      // style={{ backgroundColor: "#6F7CED" }}
                      checked={isPublicProfile}
                      onClick={(data) => {
                        profileViewHandler(data);
                      }}
                    />
                    <Tooltip
                      title="You can make your profile private so that no one else can view your information"
                      placement="bottomRight"
                      overlayClassName="create-transaction-tooltip"
                      color="#F8FAFB"
                    >
                      <Images.InviteInfo className="create-transaction-info-icon" />
                    </Tooltip>
                  </span>
                  {TWOFA_BUTTON}
                  <ButtonComponent
                    onClick={() => {
                      navigate(EDIT_ACCOUNT_ROUTE);
                    }}
                    className="edit-profile-btn"
                    text="Edit Profile"
                  />
                </div>
              </div>
            </div>
            {/* line divider */}
            <HorizontalLine />
            {/* detail info section */}
            <div className="detail-section">
              {/* profile picture section */}
              <div className="profile-picture-section">
                {/* profile picture wrapper */}
                <ProfileImage
                  classname="profile-picture-wrapper"
                  image={userData?.profilePic}
                  color={userData?.profilebg}
                  username={userData?.username}
                  size={30}
                />
                <div className="social-section-Wrapper">
                  <div className="social-icons-wrapper">
                    <a href={userData?.facebook} target="_blank">
                      <Images.Facebook />
                    </a>
                    <a href={userData?.twitter} target="_blank">
                      <Images.Twitter />
                    </a>
                    <a href={userData?.linkedIn} target="_blank">
                      <Images.Linkedin />
                    </a>
                  </div>
                </div>
              </div>
              {/* info section */}
              <Row className="info-section">
                {[isEnterpriseUser ? enterPriceInfo : info]
                  .flat()
                  .map((item, i) => {
                    if (!item.isOptional) {
                      return (
                        <Col
                          lg={item.data === "detailedaddress" ? 12 : 6}
                          md={12}
                          key={i}
                        >
                          <div className="child">
                            <Infoitem
                              datakey={item.key}
                              action={item.action}
                              data={item.data}
                              icon={item.icon}
                              title={item.title}
                              isNumberInput={item.isNumberInput}
                            />
                          </div>
                        </Col>
                      );
                    } else {
                      let data =
                        item.key === "user" ? userData?.[item.data] : item.data;
                      if (data) {
                        return (
                          <Col lg={6} md={12} key={i}>
                            <div className="child">
                              <Infoitem
                                datakey={item.key}
                                action={item.action}
                                data={item.data}
                                icon={item.icon}
                                title={item.title}
                                isNumberInput={item.isNumberInput}
                              />
                            </div>
                          </Col>
                        );
                      }
                    }
                  })}
              </Row>
            </div>
            {isEnterpriseUser && (
              <>
                <div className="sub-heading mt-3">Company Information</div>
                <HorizontalLine />
                <div className="detail-section">
                  <div className="profile-picture-section">
                    <ProfileImage
                      classname="profile-picture-wrapper"
                      image={userData?.company?.logo}
                      username={userData?.company?.name}
                      size={30}
                    />
                    <div className="social-section-Wrapper">
                      <div className="social-icons-wrapper">
                        <a href={userData?.company?.facebook} target="_blank">
                          <Images.Facebook />
                        </a>
                        <a href={userData?.company?.twitter} target="_blank">
                          <Images.Twitter />
                        </a>
                        <a href={userData?.company?.linkedIn} target="_blank">
                          <Images.Linkedin />
                        </a>
                      </div>
                    </div>
                  </div>
                  <Row className="info-section">
                    {companyInfo.map((item, i) => {
                      if (!item.isOptional) {
                        return (
                          <Col lg={6} md={12} key={i}>
                            <div className="child">
                              <Infoitem
                                datakey={item.key}
                                action={item.action}
                                data={item.data}
                                icon={item.icon}
                                title={item.title}
                                isNumberInput={item.isNumberInput}
                              />
                            </div>
                          </Col>
                        );
                      } else {
                        let data =
                          item.key === "company"
                            ? userData?.company?.[item.data]
                            : item.data;
                        if (data) {
                          return (
                            <Col lg={6} md={12} key={i}>
                              <div className="child">
                                <Infoitem
                                  datakey={item.key}
                                  action={item.action}
                                  data={item.data}
                                  icon={item.icon}
                                  title={item.title}
                                  isNumberInput={item.isNumberInput}
                                />
                              </div>
                            </Col>
                          );
                        }
                      }
                    })}
                    {userData.company?.addresses?.map((item, i) => {
                      return (
                        <Col lg={6} md={12} key={i}>
                          <div className="child">
                            <Infoitem
                              data={`${item?.address}${
                                item?.address2 ? ", " + item?.address2 : ""
                              }  ${item?.city}, ${item?.state}, ${item?.zip}, ${
                                item?.country
                              }`}
                              title={item.name}
                            />
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </>
            )}
          </>
        )}
      </div>
      {/* change password section */}
      <div className="card design-shadow change-password-card">
        {/*change password heading section */}
        <div className="sub-heading-parent">
          <div className="sub-heading">Change Password</div>
        </div>
        {/* line divider */}
        <HorizontalLine />
        {/* current-password section */}
        <div className="current-password">
          <p className="current-password-text font-poppins">Current Password</p>
          <div
            className="current-password-field"
            onClick={changePassModalHandler}
          >
            <>*************</>
            <Images.Edit />
          </div>
        </div>
      </div>
      <div className="card design-shadow change-password-card">
        <div className="sub-heading-parent">
          <div className="sub-heading">Chat Notification</div>
        </div>
        {/* line divider */}
        <HorizontalLine />
        {notificationsLoader ? (
          <CardLoader height={232} />
        ) : (
          <Row gutter={[60, 15]}>
            {notificationSettings?.map((item, index) => (
              <Col span={12}>
                <div className="noti-setting-item" key={index}>
                  <h3 className="text">{item?.text}</h3>
                  <Switch
                    defaultChecked={item?.value}
                    className="noti-setting-switch"
                    onChange={() =>
                      handleUpdateSetting(item?.key, !item?.value)
                    }
                    loading={updatenotificationsLoader && notiKey === item?.key}
                  />
                </div>
              </Col>
            ))}
          </Row>
        )}
      </div>
      <TwoFactorAuthModal
        apiData={{
          phone: userData.phone,
          email: userData.email,
          id: user_id,
        }}
        status={status}
        passwordLastmodified={userData.passwordLastmodified}
        isTfaDisabled={!tfa ?? false}
        isFormattedNumber={true}
        isChangePassView={changePassModalPreview}
        isChangeTwoFactor={twoFaModalPreview}
        modalPreview={changePassModalPreview || twoFaModalPreview}
        confirmHandler={twoFactorHandler}
        handleClose={() => {
          twoFaModalPreview
            ? twoFaModalPreviewCloseHandler()
            : changePassModalHandler();
        }}
      />
    </section>
  );
}

export default Account;
