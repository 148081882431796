import React from "react";
import { Tooltip } from "antd";
import "./styles.scss";
import { useSelector } from "react-redux";

function DisabledTooltip({
  visible,
  innerSec,
  className = "",
  style,
  placement,
}) {
  const { data } = useSelector((state) => state?.transactiondetail);
  return (
    <Tooltip
      trigger={`${!data?.status && visible ? "hover" : ""} `}
      title="You don't have permission to perform this action."
      getPopupContainer={(trigger) => trigger.parentElement}
      placement={placement ? placement : "bottomRight"}
      showArrow={false}
    >
      <div
        className={`enabled ${visible ? "disabled-wrapper" : ""} ${className}`}
        style={style}
      >
        {innerSec}
      </div>
    </Tooltip>
  );
}

export default DisabledTooltip;
