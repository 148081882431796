// @flow
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  types: [],
  parties: [],
  advisories: [],
  sideNavtransaction: [],
  transactions: [],
};

const TransactionsReducer = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    // TRANSACTION TYPE
    getTransactionTypeRequest() {},
    getTransactionTypeSuccess(state, action) {
      let temp = [...state.types];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.types = action.payload.data;
        return;
      }
      state.types = temp;
    },

    // TRANSACTION PARTIES
    getTransactionsPartiesRequest() {},
    getTransactionsPartiesSuccess(state, action) {
      let temp = [...state.parties];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.parties = action.payload.data;
        return;
      }
      state.parties = temp;
    },

    // TRANSACTION ADVISORY
    getTransactionsAdvisoryRequest() {},
    getTransactionsAdvisorySuccess(state, action) {
      let temp = [...state.advisories];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.advisories = action.payload.data;
        return;
      }
      state.advisories = temp;
    },

    // TRANSACTION QUESTION
    getTransactionQuestionRequest() {},

    //GET TRANSACTIONS
    getTransactionsRequest() {},
    getTransactionsSuccess(state, action) {
      let temp = [...state.transactions];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.transactions = action.payload.data;
        return;
      }
      state.transactions = temp;
    },
    getSideNavTransactionsRequest() {},
    getSideNavTransactionsSuccess(state, action) {
      state.sideNavtransaction = action.payload;
    },

    //CREATE TRANSCTION
    createTransactionRequest() {},

    //UPDATE TRANSACTION
    updateTransactionRequest() {},

    //CHECK TRANSACTION
    checkTransactionExistenceRequest() {},

    //CHANGE STATUS
    changeTransactionStatusRequest() {},
    changeTransactionStatusSuccess(state, action) {
      let transObj = state.transactions.find((x) => x.slug === action.payload);
      let index = state.transactions.indexOf(transObj);
      state.transactions[index].status = !state.transactions[index].status;
    },
  },
});

export const {
  getTransactionsRequest,
  getTransactionsSuccess,
  getTransactionTypeRequest,
  getTransactionTypeSuccess,
  getTransactionsPartiesRequest,
  getTransactionsPartiesSuccess,
  getTransactionsAdvisoryRequest,
  getTransactionsAdvisorySuccess,
  getSideNavTransactionsRequest,
  getSideNavTransactionsSuccess,
  getTransactionQuestionRequest,
  createTransactionRequest,
  updateTransactionRequest,
  checkTransactionExistenceRequest,
  changeTransactionStatusRequest,
  changeTransactionStatusSuccess,
} = TransactionsReducer.actions;

export default TransactionsReducer.reducer;
