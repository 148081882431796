import React from "react";
import { useDispatch } from "react-redux";
import { setUserDetailDrawer } from "../../redux/slicers/general";
import ProfileImage from "../ProfileImage";
import "./styles.scss";

export default function UserApprovalCard({
  data = {
    id: 1,
    email: "Courtney@example.com",
    username: "Courtney",
    jobTitle: "Lawyer",
    phone: "+0 123 456 7890",
  },
  setMemberApprovalModalPreview = () => {},
  setMemberRemoveModalPreview = () => {},
  setData = () => {},
  buttons,
  style,
}) {
  const dispatch = useDispatch();
  const memberApprovalHandler = () => {
    setMemberApprovalModalPreview();
    setData(data);
  };
  const memberRemoveHandler = () => {
    setMemberRemoveModalPreview();
    setData(data);
  };
  return (
    <div className="request-card" style={style}>
      <div className="bag-image"></div>
      <div className="content-view">
        <ProfileImage
          image={data.thumb}
          color={data.profilebg}
          username={data.username}
          size={21}
          onClick={() =>
            buttons &&
            dispatch(
              setUserDetailDrawer({
                visibility: true,
                id: data.id,
              })
            )
          }
        />
        <div className="details">
          <div>
            <p className="name">{data.email}</p>
            <p>{data.jobTitle}</p>
          </div>
          <div>
            <p
              onClick={() =>
                buttons &&
                dispatch(
                  setUserDetailDrawer({
                    visibility: true,
                    id: data.id,
                  })
                )
              }
              className="c-p"
            >
              {data.username}
            </p>
            <p>{data.phone}</p>
          </div>
        </div>
        {buttons && (
          <div className="buttons">
            <div onClick={memberApprovalHandler} className="approve">
              Approve
            </div>
            <div className="remove" onClick={memberRemoveHandler}>
              Remove
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
