import React, { useEffect, useRef, useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Input, Modal } from "antd";
import PhoneInput from "react-phone-input-2";
import OtpInput from "react-otp-input";
import { useNavigate, useParams } from "react-router-dom";
import {
  DASHBOARD_ROUTE,
  DASHBOARD_TRANSACTION_INVITE_ROUTE,
  DATE_FORMAT5,
  PASSWORD,
  TWO_FACTOR_KEYS,
  LOGIN_ROUTE,
} from "../../constants";
import { useCustomDispatch } from "../../helpers/useCustomDispatch";
import {
  confirmOtpRequest,
  sendOtpRequest,
  verifyOtpRequest,
  changePasswordRequest,
  userLogOutRequest,
} from "../../redux/slicers/user";
import {
  customPassIcons,
  toastAlert,
  isPasswordValid,
  getFormattedDateTime,
  inputFieldRule,
} from "../../services/utils";
import Images from "../../themes/images";
import { ButtonComponent } from "../";
import "./styles.scss";
import { removeChatTokenRequest } from "../../redux/slicers/chat";
import { useSelector } from "react-redux";

const TwoFactorAuthModal = ({
  handleClose,
  modalPreview,
  isChangePassView,
  isChangeTwoFactor,
  confirmHandler,
  apiData = {},
  forgotPassView,
  status,
  isTfaDisabled,
  passwordLastmodified,
  isFormattedNumber,
  deviceToken,
}) => {
  // STATES
  const [selectedOption, setSelectedOption] = useState("phone");
  const [confirmOtpEmail, setConfirmOtpEmailPhone] = useState("");
  const [_otp, setOtp] = useState("");
  const [modifiedDate, setmodifiedDate] = useState(null);
  const [step, setStep] = useState(1);
  const [enableResendBtn, setEnableResendBtn] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [isFirstTime, setIsFirstTime] = useState(true);

  // CUSTOM DISPATCH
  const [confirmOtpReq, confirmOtpReqLoader] =
    useCustomDispatch(confirmOtpRequest);
  const [sendLoginOtpReq, sendLoginOtpReqLoader] =
    useCustomDispatch(sendOtpRequest);
  const [verifyOtpReq, verifyOtpReqLoader] =
    useCustomDispatch(verifyOtpRequest);
  const [logoutReq] = useCustomDispatch(userLogOutRequest);
  const [removeChatToken] = useCustomDispatch(removeChatTokenRequest);
  const [changePassReq, changePassReqLoader] = useCustomDispatch(
    changePasswordRequest
  );

  //REDUX DATA
  const { otpId } = useSelector((state) => state.user);

  // CONST VALS
  const { token } = useParams();
  const [form] = Form.useForm();
  const _otpRef = useRef(null);
  const navigate = useNavigate();
  const twoFactorOptions = [
    { value: apiData.phone, key: "phone" },
    { value: apiData.email, key: "email" },
  ];

  // HOOKS
  useEffect(() => {
    if (step !== 2) return;
    if (seconds === 0) return setEnableResendBtn(true);
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds, step]);

  useEffect(() => {
    if (isTfaDisabled && isChangePassView) setStep(3);
    setmodifiedDate(passwordLastmodified);
  }, [isTfaDisabled, isChangePassView, passwordLastmodified, modalPreview]);

  // HANDLERS
  const closeHandler = () => {
    handleClose();
    setIsFirstTime(true);
    setOtp("");
    setSelectedOption("phone");
    setStep(1);
    form.resetFields();
  };

  const handleOtpFormFinish = (value) => {
    if (isChangePassView || isChangeTwoFactor) {
      const payload = { otp: value.otp, email: confirmOtpEmail, deviceToken };
      if (selectedOption === "phone") {
        payload["request_id"] = otpId;
      }
      verifyOtpReq({
        payload: payload,
        logic({ data }) {
          if (isChangeTwoFactor) {
            confirmHandler(value.otp, selectedOption, otpId);
            closeHandler();
          } else {
            setStep(3);
          }
          setmodifiedDate(data.lastModified);
        },
      });
    } else {
      const payload = { otp: value.otp, email: confirmOtpEmail, deviceToken };
      if (selectedOption === "phone") {
        payload["request_id"] = otpId;
      }
      confirmOtpReq({
        payload: payload,
        logic(response) {
          if (token) {
            navigate(
              DASHBOARD_TRANSACTION_INVITE_ROUTE.replace(":token", token)
            );
            return;
          }
          navigate(DASHBOARD_ROUTE);
        },
      });
    }
  };

  const resendLogic = () => {
    setSeconds(60);
    setEnableResendBtn(false);
  };

  const sendOtpHandler = (resendLogic) => {
    const payload = {
      id: apiData.id,
      key: selectedOption,
      to: forgotPassView
        ? TWO_FACTOR_KEYS.RESET
        : isChangePassView
        ? TWO_FACTOR_KEYS.CHANGE
        : isChangeTwoFactor
        ? TWO_FACTOR_KEYS.TFA
        : TWO_FACTOR_KEYS.LOGIN,
    };
    if (!isFirstTime && selectedOption === "phone") {
      payload["request_id"] = otpId;
    }
    if (resendLogic) {
      payload["too"] = TWO_FACTOR_KEYS.RESEND;
    }
    sendLoginOtpReq({
      payload: payload,
      logic({ data }) {
        setIsFirstTime(false);
        setConfirmOtpEmailPhone(data.email);
        if (resendLogic) return resendLogic();
        if (forgotPassView) {
          confirmHandler({ selectedOption, email: data.email });
          return;
        }
        setStep(2);
      },
    });
  };

  const handlePassFormSubmit = (value) => {
    let payload = {
      password: value.password,
      newPassword: value.newPassword,
    };
    if (_otp !== "") {
      payload["otp"] = _otp;
    }
    if (selectedOption === "phone") {
      payload["request_id"] = otpId;
    }
    changePassReq({
      payload,
      logic(response) {
        closeHandler();
        toastAlert(response.message);
        removeChatToken();
        logoutReq({
          logic() {
            navigate(LOGIN_ROUTE);
          },
        });
      },
    });
  };

  //HOOKS
  useEffect(() => {
    if (_otp?.length === 6) {
      handleOtpFormFinish({ otp: _otp });
    }
  }, [_otp]);

  return (
    <Modal
      destroyOnClose
      visible={modalPreview}
      centered
      footer={null}
      className="checklist-modal"
      getContainer={false}
    >
      <div className="close-btn" onClick={closeHandler}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      {step == 1 && (
        <div className="confirm-pass-container">
          <h2 className="heading">Two Factor Verfication</h2>
          <p className="description">Select Any To Receive Code</p>
          <div className="two-factor-options">
            {twoFactorOptions.map((item, i) => (
              <div
                key={i}
                className={`two-factor-options-items ${
                  selectedOption == item.key &&
                  "two-factor-options-items-selected"
                }`}
                onClick={() => {
                  setSelectedOption(item.key);
                }}
              >
                <p>
                  {item.key === "phone" && isFormattedNumber ? (
                    <PhoneInput value={item.value} disabled />
                  ) : (
                    item.value
                  )}
                </p>
              </div>
            ))}
          </div>
          <div className="submit-wrapper">
            <ButtonComponent
              className="submit-btn"
              onClick={() => sendOtpHandler()}
              isLoading={sendLoginOtpReqLoader}
              text={"Submit"}
            />
          </div>
        </div>
      )}
      {step == 2 && (
        <div className="opt-container">
          <div className="heading-container">
            <Images.BackArrow onClick={() => setStep(1)} />
            <div>
              <h2 className="heading">
                {isChangePassView
                  ? "Change Password"
                  : isChangeTwoFactor
                  ? `${status ? "Inactive" : "Active"} Two Factor?`
                  : "Login"}
              </h2>
              <p style={{ marginBottom: 0 }} className="description">
                Please enter 6 digit code sent to you
              </p>
            </div>
          </div>
          {/* <p className="email">{confirmOtpEmail}</p> */}

          <Form form={form} className="form" onFinish={handleOtpFormFinish}>
            <Form.Item
              rules={inputFieldRule({ name: "Otp", isWhiteSpace: false })}
              name="otp"
            >
              <OtpInput
                ref={_otpRef}
                isInputNum={true}
                shouldAutoFocus={true}
                value={_otp}
                className={`otpCodeInput custom-otp-input`}
                onChange={(e) => setOtp(e)}
                numInputs={6}
                separator={<span></span>}
              />
            </Form.Item>

            <div className="resend-wrapper">
              <span
                onClick={() => {
                  sendOtpHandler(resendLogic);
                }}
                style={
                  enableResendBtn
                    ? {}
                    : {
                        pointerEvents: "none",
                        opacity: 0.5,
                      }
                }
                className="resend-text"
              >
                Resend Code
              </span>
              <span>00:{`${seconds}`.padStart(2, 0)}</span>
            </div>

            <div className="submit-wrapper">
              <Form.Item>
                <ButtonComponent
                  className="submit-btn"
                  isLoading={confirmOtpReqLoader || verifyOtpReqLoader}
                  text={"Submit"}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      )}

      {step == 3 && (
        <div className="confirm-pass-container">
          <h2 className="heading">Change Password</h2>
          <p className="description">
            {modifiedDate &&
              `Last Modified on
              ${getFormattedDateTime(modifiedDate, DATE_FORMAT5)}`}
          </p>
          <Form
            form={form}
            className="form"
            onFinish={handlePassFormSubmit}
            style={{ marginBottom: 10 }}
          >
            <Form.Item
              name={"password"}
              rules={inputFieldRule({
                name: PASSWORD.OLD_PASS_ERROR,
                isWhiteSpace: false,
              })}
            >
              <Input.Password
                name={"password"}
                placeholder={"Current Password"}
                iconRender={customPassIcons}
                className="password-field"
                autoFocus
              />
            </Form.Item>
            <Form.Item
              name={"newPassword"}
              rules={[
                ...inputFieldRule({
                  name: PASSWORD.NEW_PASS_ERROR,
                  isWhiteSpace: false,
                }),
                () => ({
                  validator(_, value) {
                    if (!value || isPasswordValid(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(PASSWORD.INVALID_STRONG_PASSWORD)
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                name={"newPassword"}
                placeholder={"New Password"}
                iconRender={customPassIcons}
                className="password-field"
              />
            </Form.Item>
            <Form.Item
              name={"cnfrmNewPassword"}
              rules={[
                ...inputFieldRule({
                  name: PASSWORD.CONFIRM_NEW_PASS_ERROR,
                  isWhiteSpace: false,
                }),
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                name={"cnfrmNewPassword"}
                placeholder={"Confirm Password"}
                iconRender={customPassIcons}
                className="password-field"
              />
            </Form.Item>
            <div className="submit-wrapper">
              <Form.Item>
                <ButtonComponent
                  className="submit-btn"
                  isLoading={changePassReqLoader}
                  text={"Submit"}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      )}
    </Modal>
  );
};

export default TwoFactorAuthModal;
