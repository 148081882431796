import React from "react";
import { BeatLoader } from "react-spinners";
import "./styles.scss";

function CardLoader({ size = 16, height = 300, color = "#6f7ced" }) {
  return (
    <div className="card-loading-box" style={{ height: height }}>
      <BeatLoader size={size} color={color} />
    </div>
  );
}

export default CardLoader;
