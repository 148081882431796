import React, { useState } from "react";
import { Col, Row } from "antd";
import { Images } from "../../../../../themes";
import PhoneInput from "react-phone-input-2";
import { useCustomDispatch } from "../../../../../helpers/useCustomDispatch";
import { getAllTransactionAdminsListRequest } from "../../../../../redux/slicers/transactiondetail";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { CardLoader, ProfileImage } from "../../../../../components";

export default function TransactionAdminList() {
  //STATES
  const [selectedAdmin, setSelectedAdmin] = useState(0);
  const [isLoading, setLoading] = useState(true);

  //REDUX DATA
  const { transactionAdmins } = useSelector((state) => state.transactiondetail);

  //CUSTOM DISPATCH
  const [getAdminsList] = useCustomDispatch(getAllTransactionAdminsListRequest);

  //CONST VALS
  const { id } = useParams();

  //HOOKS
  useEffect(() => {
    getAdminsList({
      pathParams: id,
      logic() {
        setLoading(false);
      },
    });
  }, []);

  //CUSTOM COMPONENTS
  const detailBox = (label, val, isPhone, isWeb, col = 7) => {
    const value = (
      <span>{isPhone ? <PhoneInput value={val} disabled /> : val}</span>
    );
    return (
      val && (
        <Col xs={12} lg={col}>
          <div className="detail-box">
            <h5>{label}</h5>
            {isWeb ? (
              <a href={val} target="_blank">
                {value}
              </a>
            ) : (
              value
            )}
          </div>
        </Col>
      )
    );
  };

  return (
    <div className="admins-wrapper">
      {isLoading ? (
        <CardLoader size={20} height={400} />
      ) : (
        <Row gutter={[16, 16]}>
          <Col xs={24} md={24} lg={9} xl={7} xxl={7}>
            <div className="transaction-admins-list card">
              <ul>
                {transactionAdmins.map((item, index) => (
                  <li
                    className={`transaction-admin-box ${
                      index === selectedAdmin ? "active" : ""
                    }`}
                    onClick={() => {
                      setSelectedAdmin(index);
                    }}
                    key={index}
                  >
                    <ProfileImage
                      classname="profile-pic"
                      image={item?.profilePic}
                      color={item?.profilebg}
                      username={item?.username}
                      isBlocked={!item?.isActive}
                      size={18}
                    />
                    <div className="detail">
                      <h4>{item.username}</h4>
                      <p>Role: {item.role}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col xs={24} md={24} lg={15} xl={17} xxl={17}>
            <div className="admin-detail card">
              <>
                <h3>Personal Information</h3>
                <div className="profile-detail">
                  <div className="profile-box">
                    <ProfileImage
                      classname="profile-pic"
                      image={transactionAdmins?.[selectedAdmin]?.profilePic}
                      color={transactionAdmins?.[selectedAdmin]?.profilebg}
                      username={transactionAdmins?.[selectedAdmin]?.username}
                      size={28}
                    />

                    <div className="social-wrapper">
                      {transactionAdmins[selectedAdmin]?.facebook && (
                        <a
                          href={transactionAdmins[selectedAdmin]?.facebook}
                          target="_blank"
                        >
                          <Images.Facebook />
                        </a>
                      )}
                      {transactionAdmins[selectedAdmin]?.twitter && (
                        <a
                          href={transactionAdmins[selectedAdmin]?.twitter}
                          target="_blank"
                        >
                          <Images.Twitter />
                        </a>
                      )}
                      {transactionAdmins[selectedAdmin]?.linkedIn && (
                        <a
                          href={transactionAdmins[selectedAdmin]?.linkedIn}
                          target="_blank"
                        >
                          <Images.Linkedin />
                        </a>
                      )}
                    </div>
                    {!transactionAdmins?.[selectedAdmin]?.isActive && (
                      <p className="deactivated">Deactivated</p>
                    )}
                  </div>
                  <Row className="detail-wrapper" gutter={[10, 25]}>
                    {detailBox(
                      "Name",
                      transactionAdmins[selectedAdmin]?.username
                    )}
                    {detailBox(
                      "Phone",
                      transactionAdmins[selectedAdmin]?.phone,
                      true
                    )}
                    {detailBox(
                      "Email",
                      transactionAdmins[selectedAdmin]?.email
                    )}
                    {detailBox(
                      "Work Phone",
                      transactionAdmins[selectedAdmin]?.workPhone,
                      true
                    )}
                    {detailBox(
                      "Job Title",
                      transactionAdmins[selectedAdmin]?.jobTitle
                    )}
                  </Row>
                </div>
                <h3 className="mt-30">Company Information</h3>
                <div className="profile-detail">
                  <div className="profile-box">
                    <ProfileImage
                      classname="profile-pic"
                      image={transactionAdmins[selectedAdmin]?.company?.logo}
                      color={
                        transactionAdmins[selectedAdmin]?.company?.profilebg
                      }
                      username={transactionAdmins[selectedAdmin]?.company?.name}
                      size={28}
                    />
                    <div className="social-wrapper">
                      {transactionAdmins[selectedAdmin]?.company?.facebook && (
                        <a
                          href={
                            transactionAdmins[selectedAdmin]?.company?.facebook
                          }
                          target="_blank"
                        >
                          <Images.Facebook />
                        </a>
                      )}
                      {transactionAdmins[selectedAdmin]?.company?.twitter && (
                        <a
                          href={
                            transactionAdmins[selectedAdmin]?.company?.twitter
                          }
                          target="_blank"
                        >
                          <Images.Twitter />
                        </a>
                      )}
                      {transactionAdmins[selectedAdmin]?.company?.linkedIn && (
                        <a
                          href={
                            transactionAdmins[selectedAdmin]?.company?.linkedIn
                          }
                          target="_blank"
                        >
                          <Images.Linkedin />
                        </a>
                      )}
                    </div>
                  </div>
                  <Row className="detail-wrapper" gutter={[10, 25]}>
                    {detailBox(
                      "Name",
                      transactionAdmins[selectedAdmin]?.company?.name
                    )}
                    {detailBox(
                      "Website",
                      transactionAdmins[selectedAdmin]?.company?.website,
                      false,
                      true
                    )}
                    {detailBox(
                      "Phone",
                      transactionAdmins[selectedAdmin]?.company?.phone,
                      true
                    )}
                    {detailBox(
                      transactionAdmins[selectedAdmin]?.company?.addresses[0]
                        ?.branchName,
                      transactionAdmins[selectedAdmin]?.company?.addresses[0]
                        ?.completeAddress,
                      false,
                      false,
                      10
                    )}
                  </Row>
                </div>
              </>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}
