import { call, fork, put, take, takeLatest } from "redux-saga/effects";
import {
  callRequest,
  GET_MILESTONES,
  GET_MILESTONE_DETAIL,
  CREATE_MILESTONE,
  UPDATE_MILESTONE,
  CHANGE_MILESTONE_STATUS,
  ASSIGN_MILESTONE_PARTY,
  REMOVE_MILESTONE_PARTY,
  DELETE_MILESTONE,
  ASSIGN_MILESTONE_VISIBILITY,
  REMOVE_MILESTONE_VISIBILITY,
} from "../../config/webService";
import { ALERT_TYPES } from "../../constants";
import {
  manipulateMilestoneList,
  manipulateMilestoneData,
} from "../../dataManipulator/transactionmilestones";
import { toastAlert } from "../../services/utils";
import {
  getMileStonesRequest,
  getMileStonesSuccess,
  getMileStoneDetailRequest,
  getMileStoneDetailSuccess,
  createMileStoneRequest,
  updateMileStoneRequest,
  updateMileStoneSuccess,
  changeMileStoneStatusRequest,
  changeMileStoneStatusSuccess,
  assignResponsiblePartiesRequest,
  removeResponsiblePartiesRequest,
  deleteMileStoneRequest,
  deleteMileStoneSuccess,
  assignVisibilityToRoomsRequest,
  removeVisibilitiesRequest,
} from "../slicers/transactionmilestone";

function* getMilestones(action) {
  const {
    payload: { queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_MILESTONES,
      queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getMileStonesSuccess({
          isPaginated: isMoreData,
          data: manipulateMilestoneList(response.data),
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* getMilestoneDetail() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getMileStoneDetailRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_MILESTONE_DETAIL,
        pathParams,
      });
      if (response.status) {
        yield put(
          getMileStoneDetailSuccess(manipulateMilestoneData(response.data))
        );
        responseCallback?.(true, response);
      } else if (response.statusCode) {
        responseCallback?.(false, response);
        yield put(getMileStoneDetailSuccess({}));
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* createMilestone() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(createMileStoneRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CREATE_MILESTONE,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updateMilestone() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(updateMileStoneRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPDATE_MILESTONE,
        pathParams,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked)
          yield put(
            updateMileStoneSuccess(manipulateMilestoneData(response.data))
          );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* changeMileStoneStatus() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(changeMileStoneStatusRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CHANGE_MILESTONE_STATUS,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked)
          yield put(
            changeMileStoneStatusSuccess(manipulateMilestoneData(response.data))
          );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* assignResponsibleParties() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(assignResponsiblePartiesRequest.type);

    try {
      const response = yield call(callRequest, {
        url: ASSIGN_MILESTONE_PARTY,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* assignVisibilityToRooms() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(assignVisibilityToRoomsRequest.type);

    try {
      const response = yield call(callRequest, {
        url: ASSIGN_MILESTONE_VISIBILITY,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* removeResponsibleParties() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(removeResponsiblePartiesRequest.type);

    try {
      const response = yield call(callRequest, {
        url: REMOVE_MILESTONE_PARTY,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* deleteMileStone() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(deleteMileStoneRequest.type);

    try {
      const response = yield call(callRequest, {
        url: DELETE_MILESTONE,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked) yield put(deleteMileStoneSuccess(response.data));
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* removeVisibilities() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(removeVisibilitiesRequest.type);

    try {
      const response = yield call(callRequest, {
        url: REMOVE_MILESTONE_VISIBILITY,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

export default function* root() {
  yield takeLatest(getMileStonesRequest.type, getMilestones);
  yield fork(getMilestoneDetail);
  yield fork(createMilestone);
  yield fork(updateMilestone);
  yield fork(changeMileStoneStatus);
  yield fork(assignResponsibleParties);
  yield fork(removeResponsibleParties);
  yield fork(deleteMileStone);
  yield fork(assignVisibilityToRooms);
  yield fork(removeVisibilities);
}
