import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col } from "antd";
import { useCustomDispatch } from "../../../../../../helpers/useCustomDispatch";
import { getTransactionMembersRequest } from "../../../../../../redux/slicers/transactiondetail";
import { getDataRoomsRequest } from "../../../../../../redux/slicers/transactiondataroom";
import { ClipLoader } from "react-spinners";
import {
  EmptyBox,
  VisibleToPeople,
  ActivityLogs,
  CardLoader,
  CustomInfiniteScroll,
  NoAccessBox,
} from "../../../../../../components";
import {
  CreateDataroomModal,
  DataroomNavbar,
  LinkCard,
  MemberAccessModal,
} from "../components";
import { Images } from "../../../../../../themes";
import {
  DATA_PER_PAGE_LIMIT,
  PERMISSIONS_ACCESS,
  TRANSACTION_LINK_DATAROOM_SLUG,
} from "../../../../../../constants";
import { getActionPermission } from "../../../../../../services/utils";

export default function DataRoomDashboard({
  isRoom,
  roomslug,
  transactionslug,
  isLinkViewAllowed,
  activityLogsVisible,
  isTransactionComplete,
}) {
  //STATES
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [isMoreData, setMoreData] = useState(false);
  const [isRoomCreated, setRoomCreated] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [createDataRoomModalPreview, setCreateDataRoomModalPreview] =
    useState(false);
  const [membersModalPreview, setMembersModalPreview] = useState(false);

  // REDUX DATA
  const { rooms } = useSelector((state) => state.transactiondataroom);
  const { data: transactiondetail, members } = useSelector(
    (state) => state.transactiondetail
  );

  // CUSTOM DISPATCH
  const [getDataRooms, roomsLoading] = useCustomDispatch(getDataRoomsRequest);
  const [getTransactionMembers, isLoading] = useCustomDispatch(
    getTransactionMembersRequest
  );

  //CONST VALS
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const HEIGHT = 450;
  const BOX_HEIGHT = `calc(100vh - ${HEIGHT}px)`;
  const isDataLoading = roomsLoading && !isMoreData;
  const { VIEW_DATAROOM, CREATE_DATAROOM } = PERMISSIONS_ACCESS;
  const isCreateAllowed = getActionPermission(CREATE_DATAROOM);
  const isViewAllowed = getActionPermission(VIEW_DATAROOM);

  // HELPERS
  const getDataRoomsHelper = () => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: offset,
      search: searchText,
    };
    getDataRooms({
      pathParams: transactionslug,
      queryParams,
      logic({ total }) {
        setTotal(total);
      },
    });
  };
  const getTransactionMembersHelper = () => {
    getTransactionMembers({
      pathParams: transactiondetail?.id,
      queryParams: { room: isRoom ? roomslug : null },
    });
  };

  // HOOKS
  useEffect(() => {
    getTransactionMembersHelper();
  }, []);
  useEffect(() => {
    getDataRoomsHelper();
  }, [isRoomCreated, searchText, offset]);

  //HANDLERS
  const roomCreatedHandler = () => {
    setRoomCreated(!isRoomCreated);
  };
  const handleDataroomDetails = (slug) => {
    navigate(pathname + "/" + slug);
  };
  const handleNextData = () => {
    setOffset(offset + DATA_PER_PAGE_LIMIT);
    setMoreData(true);
  };
  const searchTextHandler = (val) => {
    setSearchText(val);
  };
  const membersModalPreviewHandler = () => {
    setMembersModalPreview(!membersModalPreview);
  };

  return (
    <>
      <Row>
        <Col xs={24} xl={18}>
          <DataroomNavbar
            title="Data Room"
            searchPlaceholder="Search any Data Room..."
            btnText="Data Room +"
            searchText={searchText}
            searchHandler={searchTextHandler}
            btnFunc={() => {
              setCreateDataRoomModalPreview(true);
            }}
            isActionAllowed={isCreateAllowed && isViewAllowed}
          />
          <div className="card mt-3" style={{ height: "calc(100vh - 300px)" }}>
            {isLinkViewAllowed && (
              <div
                className="data-room-link-box c-p"
                onClick={() => {
                  !isLoading && navigate(TRANSACTION_LINK_DATAROOM_SLUG);
                }}
              >
                <div className="link-left-wrapper">
                  <Images.LinkIcon />
                  <p>Links</p>
                  {!isLoading && (
                    <VisibleToPeople
                      data={members}
                      maxLength={10}
                      largeSize
                      borderWidth={3}
                      borderColor={"#6F7CED"}
                      bgColor="#5967DD"
                      detailModalHandler={membersModalPreviewHandler}
                    />
                  )}
                </div>
                <div
                  className="link-right-wrapper"
                  onClick={() => {
                    !isLoading && navigate(TRANSACTION_LINK_DATAROOM_SLUG);
                  }}
                >
                  {isLoading ? (
                    <ClipLoader color="#fff" size={14} />
                  ) : (
                    <img src={Images.ForwardWhite} alt="" />
                  )}
                </div>
              </div>
            )}
            <div>
              {isViewAllowed ? (
                isDataLoading ? (
                  <CardLoader size={20} height={BOX_HEIGHT} />
                ) : (
                  <CustomInfiniteScroll
                    dataLength={rooms.length}
                    total={total}
                    next={handleNextData}
                    maxHeight={HEIGHT}
                    minHeight={BOX_HEIGHT}
                    infiniteclass="ant-row rowgap-15 gap-15"
                  >
                    {rooms.length > 0 ? (
                      rooms.map((item, index) => (
                        <Col
                          xxl={8}
                          md={12}
                          sm={24}
                          style={{ padding: "0 7.5px" }}
                          key={index}
                        >
                          <LinkCard
                            data={item}
                            isDataRoom
                            onCardClick={handleDataroomDetails}
                            setTotal={() => setTotal(total - 1)}
                            isLinkManageAllowed={!isTransactionComplete}
                          />
                        </Col>
                      ))
                    ) : (
                      <EmptyBox height={BOX_HEIGHT} size={20} />
                    )}
                  </CustomInfiniteScroll>
                )
              ) : (
                <NoAccessBox height={BOX_HEIGHT} size={20} />
              )}
            </div>
          </div>
        </Col>
        <Col xs={24} xl={6}>
          <ActivityLogs
            title="Data room"
            height={300}
            isWholePage={true}
            transactionslug={transactionslug}
            customqueryParams={{ isDataroom: true }}
            isAccess={activityLogsVisible}
          />
        </Col>
      </Row>
      <CreateDataroomModal
        transactionslug={transactionslug}
        handleSuccess={roomCreatedHandler}
        preview={createDataRoomModalPreview}
        handleClose={() => setCreateDataRoomModalPreview(false)}
        title={"Data Room"}
        placeholder="Data Room Name"
      />
      <MemberAccessModal
        preview={membersModalPreview}
        handleClose={membersModalPreviewHandler}
        members={members}
        isEditable={false}
      />
    </>
  );
}
