import React from "react";
import { Modal } from "antd";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonComponent from "../ButtonComponent";
import "./styles.scss";

const DeleteModal = ({
  handleClose,
  preview,
  description,
  title,
  handleDelete,
  isLoading,
  width = 522,
  innerSec,
  btnText = "Delete",
  btnPrimary,
}) => {
  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={width}
      className="delete-modal">
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
          {innerSec}
          <div className="modal-footer">
            <ButtonComponent
              text={btnText}
              isLoading={isLoading}
              className={btnPrimary ? "primary-btn" : ""}
              // style={{ background: `${btnPrimary && "#6F7CED"}` }}
              onClick={handleDelete}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
