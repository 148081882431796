import React, { useState } from "react";
import { Images } from "../../../../../../../themes";
import { TRANSACTION_DATAROOM_FOLDER_DETAIL_ROUTE } from "../../../../../../../constants";
import {
  replaceValInString,
  toastAlert,
} from "../../../../../../../services/utils";
import { useDispatch } from "react-redux";
import { setUserDetailDrawer } from "../../../../../../../redux/slicers/general";
import {
  DeleteModal,
  EditDeleteDropdown,
  ProfileImage,
  VisibleToPeople,
} from "../../../../../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { deleteFolderRequest } from "../../../../../../../redux/slicers/transactiondataroom";
import { MoveFileModal, CreateDataroomModal, MemberAccessModal } from "..";
import "./styles.scss";

// NOTE CARD
function Card({
  isGreaterLength,
  data,
  optionsDisabled,
  editPreviewHandler,
  deletePreviewHandler,
  movePreviewHandler,
  membersModalPreviewHandler,
  isEditAllowed,
}) {
  // CONST VALS
  const dispatch = useDispatch();
  const { creatorInfo } = data;
  const navigate = useNavigate();
  const { id, room, tab, dataid } = useParams();

  //HANDLERS
  const handleFolderDetails = () => {
    const vals = {
      ":dataid": dataid,
      ":id": id,
      ":tab": tab,
      ":room": room,
      ":folder": data.slug,
    };
    navigate(
      replaceValInString(TRANSACTION_DATAROOM_FOLDER_DETAIL_ROUTE, vals)
    );
  };

  return (
    <div
      className="folders-card-wrapper c-p"
      style={{ marginBottom: isGreaterLength ? 60 : 0 }}
      onClick={handleFolderDetails}
    >
      <div className="card-left-wrapper">
        <div className="folder-name-wrapper">
          <Images.DataRoomIcon />
          <p>{data.name}</p>
        </div>
      </div>
      <div className="options-wrapper">
        <div
          className="creator-info"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ProfileImage
            username={creatorInfo.username}
            image={creatorInfo.image}
            color={creatorInfo.profilebg}
            isBlocked={!creatorInfo.isActive}
            onClick={() => {
              dispatch(
                setUserDetailDrawer({
                  visibility: true,
                  id: creatorInfo.id,
                })
              );
            }}
            size={10}
          />
          <div>
            <p className="created">Created By</p>
            <p
              className="c-p"
              onClick={() => {
                dispatch(
                  setUserDetailDrawer({
                    visibility: true,
                    id: data.creatorInfo.id,
                  })
                );
              }}
            >
              {creatorInfo.username}
            </p>
          </div>
        </div>
        {data?.isVisibleToAll ? (
          <p className="visibility-text">Visible To All</p>
        ) : (
          <VisibleToPeople
            data={data.visibilities}
            maxLength={5}
            className="data-room-visibility-group"
            detailModalHandler={membersModalPreviewHandler}
          />
        )}
        {!optionsDisabled && isEditAllowed && (
          <span
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <EditDeleteDropdown
              style={{ right: "15px", top: "22px", position: "absolute" }}
              isMove={isEditAllowed}
              onMoveClick={movePreviewHandler}
              onEditClick={editPreviewHandler}
              onDeleteClick={deletePreviewHandler}
              isEditable={isEditAllowed}
              isDelete={isEditAllowed}
            />
          </span>
        )}
      </div>
    </div>
  );
}

function DeleteCard({ data, membersModalPreviewHandler }) {
  const dispatch = useDispatch();
  const { creatorInfo } = data;

  return (
    <div className="delete-folder-card-wrapper">
      <div className="card-top">
        <Images.DataRoomIcon />
        <p>{data.name}</p>
      </div>
      <div className="card-bottom">
        <div className="creator-info">
          <ProfileImage
            username={creatorInfo.username}
            image={creatorInfo.image}
            isBlocked={!creatorInfo.isActive}
            color={creatorInfo.profilebg}
            onClick={() => {
              dispatch(
                setUserDetailDrawer({
                  visibility: true,
                  id: creatorInfo.id,
                })
              );
            }}
            size={10}
          />
          <div>
            <p className="created">Created By</p>
            <p
              className="c-p"
              onClick={() => {
                dispatch(
                  setUserDetailDrawer({
                    visibility: true,
                    id: creatorInfo.id,
                  })
                );
              }}
            >
              {creatorInfo.username}
            </p>
          </div>
        </div>
        {data?.isVisibleToAll ? (
          <p className="visibility-text">Visible To All</p>
        ) : (
          <VisibleToPeople
            data={data.visibilities}
            maxLength={5}
            className="data-room-visibility-group"
            detailModalHandler={membersModalPreviewHandler}
          />
        )}
      </div>
    </div>
  );
}

function FolderCard({
  data,
  roomslug,
  parentslug,
  isGreaterLength,
  isRoomOwner,
  onDelete,
  onFolderMove,
  isTransactionComplete,
  isEditAllowed,
}) {
  // STATES
  const [moveModalPreview, setMoveModalPreview] = useState(false);
  const [editModalPreview, seteditModalPreview] = useState(false);
  const [deleteModalPreview, setDeleteModalPreview] = useState(false);
  const [membersModalPreview, setMembersModalPreview] = useState(false);

  //CUSTOM DISPATCH
  const [deleteFolder, deleteLoader] = useCustomDispatch(deleteFolderRequest);

  //HANDLERS
  const editModalPreviewHandler = () => {
    seteditModalPreview(!editModalPreview);
  };
  const deleteModalPreviewHandler = () => {
    setDeleteModalPreview(!deleteModalPreview);
  };
  const moveModalPreviewHandler = () => {
    setMoveModalPreview(!moveModalPreview);
  };
  const membersModalPreviewHandler = () => {
    setMembersModalPreview(!membersModalPreview);
  };

  const handleFolderDelete = () => {
    deleteFolder({
      pathParams: data.slug,
      logic({ message }) {
        toastAlert(message);
        deleteModalPreviewHandler();
        onDelete();
      },
    });
  };

  return (
    <>
      <Card
        data={data}
        isGreaterLength={isGreaterLength}
        optionsDisabled={
          (!data?.isOwner && !isRoomOwner) || isTransactionComplete
        }
        movePreviewHandler={moveModalPreviewHandler}
        editPreviewHandler={editModalPreviewHandler}
        deletePreviewHandler={deleteModalPreviewHandler}
        membersModalPreviewHandler={membersModalPreviewHandler}
        isEditAllowed={isEditAllowed}
      />
      <DeleteModal
        preview={deleteModalPreview}
        handleClose={deleteModalPreviewHandler}
        title={`Delete Folder`}
        description={`Are you sure you want to delete this folder?`}
        innerSec={
          <DeleteCard
            data={data}
            membersModalPreviewHandler={membersModalPreviewHandler}
          />
        }
        isDataRoom
        handleDelete={handleFolderDelete}
        isLoading={deleteLoader}
      />

      <CreateDataroomModal
        handleClose={editModalPreviewHandler}
        preview={editModalPreview}
        isEdit={true}
        data={data}
        title={"Folder"}
        placeholder="Folder Name"
        isFolder
        isDetail={false}
      />

      <MoveFileModal
        preview={moveModalPreview}
        handleClose={moveModalPreviewHandler}
        slug={data.slug}
        roomslug={roomslug}
        parentslug={parentslug}
        handleSuccess={onFolderMove}
        isFolder
        lastUpdated={data.lastUpdated}
      />

      <MemberAccessModal
        preview={membersModalPreview}
        handleClose={membersModalPreviewHandler}
        members={data.visibilities}
        isEditable={false}
      />
    </>
  );
}

export default FolderCard;
