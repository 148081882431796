import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCustomDispatch } from "../../../../../../helpers/useCustomDispatch";
import {
  changeMileStoneStatusRequest,
  deleteMileStoneRequest,
  getMileStonesRequest,
} from "../../../../../../redux/slicers/transactionmilestone";
import { Images } from "../../../../../../themes";
import {
  CardHeader,
  EditDeleteDropdown,
  EmptyBox,
  CustomInfiniteScroll,
  CardLoader,
  StatusToggle,
  NoAccessBox,
  DisabledTooltip,
} from "../../../../../../components";
import {
  MilestonDetailView,
  DeleteMilestoneModal,
  UpdateMilestoneModal,
} from "../../milestones/components";
import { replaceValInString } from "../../../../../../services/utils";
import {
  TRANSACTION_TABS_ROUTE,
  TRANSACTION_TABS_SLUGS,
  TRANSACTIONDETAIL_STATUS_FILTERS,
} from "../../../../../../constants";

const Milestones = ({
  isRoom,
  roomslug,
  transactionslug,
  checkUserStatusHandler,
  checkApiUserStatusHandler,
  canView,
  canEdit,
  canDelete,
}) => {
  // CONST VALS
  const navigate = useNavigate();
  const BOX_HEIGHT = 250;
  const DATA_SIZE = 5;

  //STATES
  const [selectedFilter, setselectedFilter] = useState(null);
  const [total, setTotal] = useState(null);
  const [isMoreData, setMoreData] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [milestoneDetails, showMilestoneDetails] = useState(false);
  const [updateMilstonModalPreview, setUpdateMilstonModalPreview] =
    useState(false);
  const [deleteMilstonModalPreview, setDeleteMilstonModalPreview] =
    useState(false);

  // REDUX DATA
  const { roomAccess } = useSelector((state) => state.transactiondetail);
  const { milestones } = useSelector((state) => state.transactionmilestone);

  // CUSTOM DISPATCH
  const [getMileStones, isLoading] = useCustomDispatch(getMileStonesRequest);
  const [milestoneStatusChange, statusChangeLoader] = useCustomDispatch(
    changeMileStoneStatusRequest
  );
  const [deleteMilestone, deleteMileStoneLoader] = useCustomDispatch(
    deleteMileStoneRequest
  );
  // HELPERS
  const getMilestonessHelper = (isSearch) => {
    const queryParams = {
      limit: DATA_SIZE,
      offset: 0,
      roomSlug: roomslug,
    };
    if (isSearch) {
      queryParams["keyword"] = isSearch;
      setMoreData(false);
    }
    if (selectedFilter !== null) {
      queryParams["status"] = selectedFilter;
    }
    getMileStones({
      queryParams: queryParams,
      logic(res) {
        setTotal(res.total);
      },
    });
  };

  //HOOKS
  useEffect(() => {
    checkApiUserStatusHandler(() => {
      getMilestonessHelper(false);
    });
  }, [roomslug, isRoom, roomAccess, selectedFilter]);

  // HANDLERS
  const showMilestoneDetailHandler = (slug) => {
    setSelectedData(slug);
    showMilestoneDetails(true);
  };
  const updateModalPreviewHandler = (data) => {
    setSelectedData(data);
    setUpdateMilstonModalPreview(true);
  };
  const deleteeModalPreviewHandler = (data) => {
    setSelectedData(data);
    setDeleteMilstonModalPreview(true);
  };
  const handleSearch = (val) => {
    getMilestonessHelper(val);
  };
  const setFilterHandler = (val) => {
    setselectedFilter(val);
    setMoreData(false);
  };
  const viewAllMilestone = () => {
    const vals = {
      ":id": transactionslug,
      ":room": roomslug,
      ":tab": TRANSACTION_TABS_SLUGS.MILESTONES,
    };
    navigate(replaceValInString(TRANSACTION_TABS_ROUTE, vals));
  };
  const milestoneStatusChangeHandler = (slug) => {
    checkUserStatusHandler(() => {
      milestoneStatusChange({ pathParams: slug });
    });
  };
  const deleteMilstoneHandler = (id) => {
    deleteMilestone({
      pathParams: id,
      logic() {
        getMilestonessHelper(false);
        setDeleteMilstonModalPreview(false);
      },
    });
  };

  //CUSTOM COMPONENTS
  const milestoneItems = (item, index) => (
    <ul key={index}>
      <li className="c-p" onClick={() => showMilestoneDetailHandler(item.slug)}>
        <div className="thumb">
          <Images.TransactionMilestoneThumb />
        </div>
        <div className="detail">
          <span className="title">{item.name}</span>
          <span className="created">{item.startDate}</span>
        </div>
      </li>
      <li>
        <span className="type">
          Target Date: <b>{item.endDate ? item.endDate : "---------------"}</b>
        </span>
      </li>
      <li style={{ display: "flex", gap: "20px" }}>
        <DisabledTooltip
          innerSec={
            <StatusToggle
              check={item.status}
              handleStatusChange={() =>
                canEdit && milestoneStatusChangeHandler(item.slug)
              }
              confirmLoader={statusChangeLoader}
              confirmDescription={
                "Are you sure you want to change this milestone's status?"
              }
              isStatic={!canEdit}
            />
          }
          visible={!canEdit}
        />
        <div className="action mr-10" style={{ width: "30px" }}>
          {(canEdit || canDelete) && !item.status && (
            <EditDeleteDropdown
              isEditable={canEdit}
              onEditClick={() => {
                updateModalPreviewHandler(item);
              }}
              isDelete={canDelete}
              onDeleteClick={() => {
                deleteeModalPreviewHandler(item);
              }}
            />
          )}
        </div>
      </li>
    </ul>
  );
  return (
    <>
      <div className="card mt-3">
        <CardHeader
          viewAllNavigation={viewAllMilestone}
          handleSearch={handleSearch}
          title="Milestones"
          selectedFilter={selectedFilter}
          setFilter={setFilterHandler}
          viewAll={total > DATA_SIZE}
          placeholder="Search any milestone..."
        />
        {!canView ? (
          <NoAccessBox height={BOX_HEIGHT} />
        ) : isLoading & !isMoreData ? (
          <CardLoader size={14} height={BOX_HEIGHT} />
        ) : (
          <CustomInfiniteScroll
            className="mt-2"
            dataLength={DATA_SIZE}
            total={DATA_SIZE}
            isWholePage={false}
            minHeight={BOX_HEIGHT}
            maxHeight={BOX_HEIGHT}
            infiniteclass="tbl-list bordered"
          >
            {milestones.length >= 1 ? (
              milestones.map((item, index) => milestoneItems(item, index))
            ) : (
              <EmptyBox height={BOX_HEIGHT} />
            )}
          </CustomInfiniteScroll>
        )}
      </div>
      <UpdateMilestoneModal
        isUpdate
        data={selectedData}
        handleClose={() => {
          setUpdateMilstonModalPreview(false);
        }}
        preview={updateMilstonModalPreview}
      />
      <DeleteMilestoneModal
        data={selectedData}
        handleClose={() => {
          setDeleteMilstonModalPreview(false);
        }}
        preview={deleteMilstonModalPreview}
        handleDelete={deleteMilstoneHandler}
        loader={deleteMileStoneLoader}
      />
      <MilestonDetailView
        milestoneslug={selectedData}
        modalPreview={milestoneDetails}
        handleClose={() => showMilestoneDetails(false)}
        handleDeleteResponse={() => getMilestonessHelper(false)}
        isEditAllowed={canEdit}
        isDeleteAllowed={canDelete}
      />
    </>
  );
};

export default Milestones;
