import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { getDocumentsRequest } from "../../../../../../../redux/slicers/transactiondataroom";
import { DATA_PER_PAGE_LIMIT } from "../../../../../../../constants";
import DocumentCard from "../documentCard";
import {
  CustomInfiniteScroll,
  EmptyBox,
  CardLoader,
} from "../../../../../../../components";

function DataRoomDocuments({
  slug,
  roomslug,
  isDataUpdated,
  minheight,
  maxheight,
  searchText,
  isFolder,
  isRoomOwner,
  onFileMove,
  isTransactionComplete,
  isEditAllowed,
}) {
  // STATES
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [isMoreData, setMoreData] = useState(false);

  // REDUX DATA
  const { documents } = useSelector((state) => state.transactiondataroom);

  // CUSTOM DISPATCH
  const [getDocuments, docsLoading] = useCustomDispatch(getDocumentsRequest);

  //CONST VALS
  const isLoading = docsLoading && !isMoreData;

  // HELPERS
  const getDocumentsHelper = () => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: offset,
      search: searchText,
      isDataRoom: isFolder ? false : true,
    };
    getDocuments({
      pathParams: slug,
      queryParams,
      logic({ total }) {
        setTotal(total);
      },
    });
  };

  // HOOKS
  useEffect(() => {
    getDocumentsHelper();
  }, [isDataUpdated, slug, searchText, isFolder]);

  //HANDLERS
  const handleNextData = () => {
    setOffset(offset + DATA_PER_PAGE_LIMIT);
    setMoreData(true);
  };

  return (
    <div className="room-detail-document-wrapper">
      <h3 className="card-title">Documents</h3>
      {isLoading ? (
        <CardLoader height={minheight} />
      ) : (
        <CustomInfiniteScroll
          dataLength={documents.length}
          total={total}
          next={handleNextData}
          maxHeight={maxheight}
          minHeight={0}
          isWholePage={false}
          isPercent={true}
        >
          {documents.length >= 1 ? (
            documents.map((item, index) => {
              return (
                <DocumentCard
                  key={index}
                  data={item}
                  parentslug={slug}
                  roomslug={roomslug}
                  isRoomOwner={isRoomOwner}
                  isGreaterLength={documents.length === 1}
                  onDelete={() => setTotal(total - 1)}
                  onFileMove={onFileMove}
                  isTransactionComplete={isTransactionComplete}
                  isEditAllowed={isEditAllowed}
                />
              );
            })
          ) : (
            <EmptyBox height={minheight} imgwidth={80} size={14} />
          )}
        </CustomInfiniteScroll>
      )}
    </div>
  );
}

export default DataRoomDocuments;
