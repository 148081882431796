import React from "react";
import { Col, Row } from "antd";
import { EmptyBox, ProfileImage } from "../../../../../../../../components";
import { VersionCard } from "../components";

function Versions({
  data,
  versions,
  isData,
  owner,
  handleSuccess,
  roomAccess,
  isEditAllowed,
  isShareAllowed,
}) {
  return (
    <div className="versions-wrapper">
      <div className="uploader-info">
        <ProfileImage
          username={owner?.username}
          image={owner?.image}
          isBlocked={!owner?.isActive}
          color={owner?.profilebg}
        />
        <div className="uploaded-info">
          <h3>{owner?.username}</h3>
          <p>{data?.createdAt}</p>
        </div>
      </div>
      {isData ? (
        <Row gutter={[10, 10]}>
          {versions.map((item, index) => (
            <Col span={12} key={index}>
              <VersionCard
                data={item}
                handleSuccess={handleSuccess}
                roomAccess={roomAccess}
                isEditAllowed={isEditAllowed}
                isShareAllowed={isShareAllowed}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <EmptyBox height={280} />
      )}
    </div>
  );
}

export default Versions;
