import React from "react";
import { Modal } from "antd";
import { Images } from "../../../../../themes";
import "./styles.scss";
import { useState } from "react";
import { useCustomDispatch } from "../../../../../helpers/useCustomDispatch";
import {
  getDirectChatUsersRequest,
  loadRoomRequest,
} from "../../../../../redux/slicers/chat";
import { useEffect } from "react";
import { DATA_PER_PAGE_LIMIT } from "../../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  CardLoader,
  CustomInfiniteScroll,
  ProfileImage,
} from "../../../../../components";
import { setUserDetailDrawer } from "../../../../../redux/slicers/general";
import CreateGroupModal from "../CreateGroupModal";

const DirectMessageModal = ({ handleClose, preview, setSelected }) => {
  //STATES
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(null);
  const [isMoreData, setMoreData] = useState(false);

  // CONST VALS
  const dispatch = useDispatch();

  //REDUX DATA
  const { directUsersList } = useSelector((state) => state.chat);
  const { chat_id } = useSelector((state) => state.user.data);

  //CUSTOM DISPATCH
  const [getUsers, isLoading] = useCustomDispatch(getDirectChatUsersRequest);
  const [loadRoom, loadRoomLoader] = useCustomDispatch(loadRoomRequest);

  //HANDLERS
  const userDetailHandler = (userid) => {
    dispatch(
      setUserDetailDrawer({
        visibility: true,
        id: userid,
      })
    );
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setMoreData(false);
    setOffset(0);
  };

  const closeHandler = () => {
    setSearch("");
    setOffset(0);
    handleClose();
  };

  const handleNextData = () => {
    setOffset(offset + DATA_PER_PAGE_LIMIT);
    setMoreData(true);
  };

  //HELPERS
  const getUsersHelper = (search) => {
    const queryParams = { limit: DATA_PER_PAGE_LIMIT, offset: offset };
    if (search) {
      queryParams["search"] = search;
    }
    getUsers({
      queryParams: queryParams,
      logic(res) {
        setTotal(res.total);
      },
    });
  };

  //HOOKS
  useEffect(() => {
    if (preview) {
      getUsersHelper(search);
    } else {
      setSearch("");
    }
  }, [search, preview, offset]);

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={522}
      className="direct-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <Images.CloseIcon className="close-btn" onClick={closeHandler} />
          <div className="title">
            <h3>Direct Message +</h3>
          </div>
          <input
            type="text"
            placeholder="Type Name of Person"
            onChange={handleSearch}
            className="search-input"
            value={search}
          />
          {/* <div className="people-wrapper"> */}
          {isLoading & !isMoreData ? (
            <CardLoader size={10} height={240} />
          ) : (
            <CustomInfiniteScroll
              dataLength={directUsersList?.length}
              total={total}
              next={handleNextData}
              maxHeight={300}
              isWholePage={false}
              className="people-wrapper"
            >
              {directUsersList.map((item, index) => (
                <div className="people-item" key={index}>
                  <div className="info">
                    <ProfileImage
                      image={item?.image}
                      username={item?.username}
                      color={item?.profilebg}
                      isBlocked={!item?.isActive}
                      onClick={() => {
                        userDetailHandler(item.id);
                      }}
                    />
                    <div className="name">
                      <h3
                        onClick={() => {
                          userDetailHandler(item.id);
                        }}
                        className="c-p"
                      >
                        {item?.username}
                      </h3>
                      <p>{item?.email}</p>
                    </div>
                  </div>
                  <Images.Conversation
                    className="c-p"
                    onClick={() => {
                      setSelected(item);
                      closeHandler();
                    }}
                  />
                </div>
              ))}
            </CustomInfiniteScroll>
          )}
          {/* </div> */}
        </div>
      </div>
    </Modal>
  );
};

export default DirectMessageModal;
