import { fork } from "redux-saga/effects";
import general from "./general";
import user from "./user";
import company from "./company";
import transactions from "./transactions";
import transactiondetail from "./transactiondetail";
import transactionmilestone from "./transactionmilestone";
import transactiondocuments from "./transactiondocuments";
import transactiondataroom from "./transactiondataroom";
import transactionchecklist from "./transactionchecklist";
import resources from "./resources";
import chat from "./chat";

export default function* root() {
  yield fork(general);
  yield fork(user);
  yield fork(company);
  yield fork(transactions);
  yield fork(transactiondetail);
  yield fork(transactionmilestone);
  yield fork(transactiondataroom);
  yield fork(resources);
  yield fork(transactiondocuments);
  yield fork(transactionchecklist);
  yield fork(chat);
}
