import React, { useRef } from "react";
import { ALERT_TYPES } from "../../../../constants";
import { Images } from "../../../../themes";
import { toastAlert } from "../../../../services/utils";

function FilePicker({ handleAddFiles }) {
  //REFS
  const fileInputRef = useRef(null);

  //HANDLERS
  const handleFilePick = (e) => {
    const files = e.target?.files;
    if (fileInputRef?.current) {
      fileInputRef.current.files = null;
    }

    const validFiles = [];
    const maxFileSize = 15 * 1024 * 1024; // 5MB

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (file.size <= maxFileSize) {
        validFiles.push(file);
      } else {
        toastAlert("File size should be less than 15 mb.", ALERT_TYPES.ERROR);
      }
    }

    handleAddFiles(validFiles);
  };

  return (
    <div className="file-picker-wrapper">
      <Images.Attachment
        className="c-p"
        onClick={() => {
          fileInputRef?.current?.click?.();
        }}
      />
      <input
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFilePick}
        type="file"
        multiple
        value={[]}
        accept=".png, .jpg, .jpeg, .doc, .docx, .odt, .pdf, .tex, .txt, .rtf, .wps, .wks, .wpd, .ppt, .pptx, .xls, .xlsx"
      />
    </div>
  );
}

export default FilePicker;
