import _ from "lodash";
import {
  APPROVAL_REQUEST_LABELS,
  APPROVAL_REQUEST_REMOVABLE_KEYS,
  DATE_FORMAT2,
  DEFAULT_PROFILE_COLOR,
  PERMISSIONS_ACCESS,
  PERMISSIONS_CATEGORY,
  TRANSACTION_ROLE_TYPE,
  STATUS_CONST,
} from "../constants";
import { getFormattedDateTime } from "../services/utils";
import { manipulateChecklistDetail } from "./transactionchecklist";
import {
  manipulateVisibilityList as manipulateDataroomVisibilityList,
  manipulateLinkData,
} from "./transactiondataroom";
import {
  manipulateRoomData,
  manipulateTransactionData,
} from "./transactiondetail";
import {
  manipulateDocumentData,
  manipulateDocVersionData,
  manipulateExhibitData,
} from "./transactiondocument";
import { manipulateMilestoneData } from "./transactionmilestones";
import { manipulateUserData } from "./user";

// =================================== REQUEST OLD DATA
// COMPARE TWO ARRAYS
const compareObjects = (obj1, obj2) => {
  return JSON.stringify(obj1) !== JSON.stringify(obj2);
};
export const compareAndRemoveDuplicates = (arr1, arr2) => {
  const uniqueArr1 = arr1?.filter((obj1) =>
    arr2.every((obj2) => compareObjects(obj1, obj2))
  );
  const uniqueArr2 = arr2?.filter((obj2) =>
    arr1.every((obj1) => compareObjects(obj1, obj2))
  );
  const sortedData1 = [...uniqueArr1]?.sort((a, b) =>
    a.label.localeCompare(b.label)
  );
  const sortedData2 = [...uniqueArr2]?.sort((a, b) =>
    a.label.localeCompare(b.label)
  );
  return [sortedData1, sortedData2];
};

// REMOVE UNWANTED KEYS
const removeUnwantedKeys = (data, removeablekeys) => {
  let tempdata = data.filter((item) =>
    removeablekeys.some((x) => x.label === item.label)
  );
  return tempdata;
};
// OLD DATA ARRAY CONVERTER
const convertOldDataToArray = (data, newdata, permission) => {
  let tempdata = { ...data };
  let details = [];
  Object.keys(tempdata).forEach(function (key) {
    let object = {
      key: key,
      label: APPROVAL_REQUEST_LABELS[permission][key],
      value: data[key],
    };
    details.push(object);
  });
  details = removeUnwantedKeys(details, newdata);
  return details;
};

// MILESTONE REQUEST OLD DATA
const manipulateMilestoneRequestOldData = (data, newdata, permission) => {
  let details = convertOldDataToArray(data, newdata, permission);
  return details;
};

// CHECKLIST REQUEST OLD DATA
const manipulateChecklistRequestOldData = (data, newdata, permission) => {
  let details = convertOldDataToArray(data, newdata, permission);
  return details;
};

// ROOM REQUEST OLD DATA
const manipulateRoomRequestOldData = (data, newdata, permission) => {
  let details = convertOldDataToArray(data, newdata, permission);
  return details;
};

// DOCUMENT REQUEST OLD DATA
const manipulateDocumentRequestOldData = (data, newdata, permission) => {
  let details = convertOldDataToArray(data, newdata, permission);
  return details;
};

// DATAROOM REQUEST OLD DATA
const manipulateDataroomRequestOldData = (data, newdata, permission) => {
  let details = convertOldDataToArray(data, newdata, permission);
  return details;
};

// TRANSACTION REQUEST OLD DATA
const manipulateTransactionRequestOldData = (data, newdata, permission) => {
  let details = convertOldDataToArray(data, newdata, permission);
  return details;
};
export const manipulateRequestDetailOldData = (
  data,
  newdata,
  permission,
  access
) => {
  const tempdata = { ...data };
  let details = [];
  switch (permission) {
    case PERMISSIONS_CATEGORY.DATAROOM:
      details = manipulateDataroomRequestOldData(
        manipulateLinkData(tempdata, true),
        newdata,
        permission
      );
      break;
    case PERMISSIONS_CATEGORY.MILESTONE:
      details = manipulateMilestoneRequestOldData(
        manipulateMilestoneData(tempdata, true),
        newdata,
        permission
      );
      break;
    case PERMISSIONS_CATEGORY.CHECKLIST:
      details = manipulateChecklistRequestOldData(
        manipulateChecklistDetail(tempdata, true),
        newdata,
        permission
      );
      break;
    case PERMISSIONS_CATEGORY.DOCUMENT:
      details = manipulateDocumentRequestOldData(
        tempdata.isExhibit
          ? manipulateExhibitData(tempdata, true)
          : tempdata.isVersion
          ? manipulateDocVersionData(tempdata)
          : manipulateDocumentData(tempdata, true),
        newdata,
        permission
      );
      break;
    case PERMISSIONS_CATEGORY.BREAKOUT:
      details = manipulateRoomRequestOldData(
        manipulateRoomData(tempdata, true),
        newdata,
        permission
      );
      break;
    case PERMISSIONS_CATEGORY.TRANSACTION:
      details = manipulateTransactionRequestOldData(
        manipulateTransactionData(tempdata, true),
        newdata,
        permission
      );
      break;

    default:
      break;
  }
  return details;
};

// =================================== REQUEST NEW DATA

// DATA ROOM DATA
const manipulateDataRoomRequestData = (data, olddata, permission) => {
  let details = [];
  Object.keys(data).forEach(function (key) {
    let object = {
      key: key,
      label: APPROVAL_REQUEST_LABELS[permission][key],
      value: data[key],
    };
    if (key === "visibilities") {
      object.value = olddata?.isVisibleToAll
        ? true
        : manipulateDataroomVisibilityList(data[key]);
    }
    details.push(object);
  });
  return details;
};

// MILESTONE DATA
const manipulateVisibilityData = (data) => {
  if (_.isEmpty(data)) return {};
  let visibility = {};
  visibility["id"] = data?.id ?? 0;
  visibility["roomname"] = data.name ?? "";
  visibility["bgColor"] = data?.bgcolor ?? DEFAULT_PROFILE_COLOR;
  visibility["slug"] = data?.slug ?? "";

  return visibility;
};
const manipulateVisibilityList = (data) => {
  let visibilities = [];
  data?.forEach((item) => {
    visibilities.push(manipulateVisibilityData(item));
  });
  return visibilities;
};
const manipulateMilestoneResponsiblePartyData = (data) => {
  if (_.isEmpty(data)) return {};
  let party = { ...manipulateUserData(data) };
  party["companyname"] = data?.company?.name ?? "";
  party["companylogo"] = data?.company?.logo ?? "";
  return party;
};
const manipulateMilestoneResponsiblePartyList = (data) => {
  let parties = [];
  data?.forEach((item) => {
    parties.push(manipulateMilestoneResponsiblePartyData(item));
  });
  return parties;
};
const manipulateMilestoneResponsiblePartyGroup = (data) => {
  let groups = [];
  data?.forEach((item) => {
    const user = {
      email: item.email,
      image: item.image,
      id: item.id,
      username: item.username,
      profilebg: item.profilebg,
      isActive: item.isActive,
    };
    const group = {
      name: item.companyname,
      logo: item.companylogo,
      users: [user],
    };
    let isExist = groups.findIndex((x) => x.name === item.companyname);
    if (isExist >= 0) {
      groups[isExist].users.push(user);
    } else {
      groups.push(group);
    }
  });
  return groups;
};
const manipulateMilestoneRequestData = (data, permission) => {
  let details = [];
  Object.keys(data).forEach(function (key) {
    let object = {
      key: key,
      label: APPROVAL_REQUEST_LABELS[permission][key],
      value: data[key],
    };
    if (key === "visibility") {
      object.value = manipulateVisibilityList(data[key]);
    }
    if (key === "responsibleParties") {
      object.value = manipulateMilestoneResponsiblePartyGroup(
        manipulateMilestoneResponsiblePartyList(data[key])
      );
    }
    if (key === "status") {
      object.value = data[key] ? "Completed" : "Pending";
    }
    if (key === "endDate") {
      object.value =
        data[key] === ""
          ? null
          : data[key] && getFormattedDateTime(data[key], DATE_FORMAT2);
    }
    details.push(object);
  });
  return details;
};

// CHECKLIST DATA
const manipulateChecklistRequestData = (data, permission, permkey, isDoc) => {
  let details = [];

  // DELETE UNWANTED KEYS
  delete data["visibleToAll"];
  delete data["id"];
  delete data["isExisted"];
  Object.keys(data).forEach(function (key) {
    let label = APPROVAL_REQUEST_LABELS[permission][key];
    if (isDoc) {
      label = APPROVAL_REQUEST_LABELS[permission][permkey][key];
    }
    let object = {
      key: key,
      label,
      value: data[key],
    };
    if (key === "visibility") {
      object.value = manipulateVisibilityList(data[key]);
    }
    if (key === "responsibleParties") {
      object.value = manipulateMilestoneResponsiblePartyGroup(
        manipulateMilestoneResponsiblePartyList(data[key])
      );
    }
    if (key === "status") {
      object.value = data[key] ? "Completed" : "Pending";
    }
    if (key === "targetDate") {
      object.value = data[key] && getFormattedDateTime(data[key], DATE_FORMAT2);
    }
    details.push(object);
  });
  return details;
};

// DOCUMENT DATA
const manipulateDocumentRequestData = (data, permission) => {
  let details = [];
  Object.keys(data).forEach(function (key) {
    let object = {
      key: key,
      label: APPROVAL_REQUEST_LABELS[permission][key],
      value: data[key],
    };
    if (key === PERMISSIONS_CATEGORY.CHECKLIST) {
      details.push(
        ...manipulateChecklistRequestData(data[key], permission, key, true)
      );
      return;
    }
    details.push(object);
  });
  return details;
};

// BREAKOUT ROOM DATA
const manipulateRoomRequestData = (data, permission) => {
  let details = [];
  Object.keys(data).forEach(function (key) {
    let object = {
      key: key,
      label: APPROVAL_REQUEST_LABELS[permission][key],
      value: data[key],
    };
    if (key === "memberIds") {
      object.value = manipulateDataroomVisibilityList(data[key]);
    }
    details.push(object);
  });
  return details;
};

// TRANSACTION DATA
const manipulateTransactionRequestData = (data, permission, action) => {
  let details = [];
  if (action === PERMISSIONS_ACCESS.EDIT_TRANSACTION) {
    delete data["isAdvisor"];
  }
  Object.keys(data).forEach(function (key) {
    let object = {
      key: key,
      label: APPROVAL_REQUEST_LABELS[permission][key],
      value: data[key],
    };
    if (key === "status") {
      object.value = data[key] ? "Completed" : "Pending";
    }
    if (key === "isAdvisor") {
      object.value = TRANSACTION_ROLE_TYPE.find(
        (x) => x.value === data[key]
      )?.label;
    }
    if (key === "users") {
      object.value = manipulateDataroomVisibilityList(data[key]);
    }
    details.push(object);
  });
  return details;
};
// APPROVAL REQUEST ADMINS
export const manipulateRequestAdminsData = (data) => {
  let admins = [];
  data.forEach((item) => {
    let object = {
      ...manipulateUserData(item),
      companyName: item.company.name,
      status: STATUS_CONST[item?.isPending] ?? STATUS_CONST.pending,
    };
    admins.push(object);
  });

  return admins;
};

// APPROVAL REQUEST DETAIL

export const manipulateRequestDetailData = (data, permission, access) => {
  const tempdata = { ...data };
  let details = [];
  // REMOVE UNWANTED KEYS
  APPROVAL_REQUEST_REMOVABLE_KEYS.forEach((key) => {
    if (tempdata.hasOwnProperty(key)) {
      delete tempdata[key];
    }
  });

  switch (permission) {
    case PERMISSIONS_CATEGORY.DATAROOM:
      details = manipulateDataRoomRequestData(tempdata, data, permission);
      break;
    case PERMISSIONS_CATEGORY.MILESTONE:
      details = manipulateMilestoneRequestData(tempdata, permission);
      break;
    case PERMISSIONS_CATEGORY.CHECKLIST:
      details = manipulateChecklistRequestData(tempdata, permission);
      break;
    case PERMISSIONS_CATEGORY.DOCUMENT:
      details = manipulateDocumentRequestData(tempdata, permission);
      break;
    case PERMISSIONS_CATEGORY.BREAKOUT:
      details = manipulateRoomRequestData(tempdata, permission);
      break;
    case PERMISSIONS_CATEGORY.TRANSACTION:
      details = manipulateTransactionRequestData(tempdata, permission, access);
      break;

    default:
      break;
  }
  return details;
};
