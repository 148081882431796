import {
  call,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  ADD_GROUP_MEMBERS,
  ADD_REACT,
  ARCHIVE_ROOM,
  BLOCK_ROOM,
  callRequest,
  CHAT_URL,
  CHECK_LAST_GROUP_ADMIN,
  CREATE_CHAT_GROUP,
  EDIT_CHAT_GROUP,
  GET_CHAT_DETAILS,
  GET_CHAT_LIST,
  GET_CHAT_NOTIFICATIONS,
  GET_GROUP_PARTICIPANTS,
  GET_LINK_PREVIEW,
  GET_REMAINING_USERS_FOR_CHAT,
  GET_ROOM_MESSAGES,
  GET_THREADS,
  GET_TRANS_CHATS,
  GET_USERS_FOR_CHAT,
  LEAVE_CHAT,
  LOAD_CHAT_ROOM,
  LOAD_TRANSACTION_ROOM,
  MAKE_GROUP_ADMIN,
  REMOVE_CHAT_DEVICE_TOKEN,
  REMOVE_GROUP_ADMIN,
  REMOVE_GROUP_MEMBER,
  REMOVE_REACT,
  SEND_MESSAGE,
  UNARCHIVE_ROOM,
  UNBLOCK_ROOM,
  UPDATE_CHAT_NOTIFICATION,
} from "../../config/webService";
import { ALERT_TYPES } from "../../constants";
import {
  manipulateChatData,
  manipulateChatDetails,
  manipulateChatMessageData,
  manipulateChatMessages,
  manipulateChatsList,
  manipulateChatUsersList,
  manipulateIndividualChatRoomData,
  manipulateReactData,
  manipulateReactMessageData,
  manipulateThreadData,
  manipulateRoomMembersList,
  manipulateNotificationSettingList,
  manipulateTransactionChatList,
} from "../../dataManipulator/chat";
import { toastAlert } from "../../services/utils";
import {
  addGroupMembersRequest,
  addGroupMembersSuccess,
  addRoomToChatList,
  archiveRoomRequest,
  archiveRoomSuccess,
  blockRoomRequest,
  blockRoomSuccess,
  checkLastGroupAdminRequest,
  checkLastGroupAdminSuccess,
  createGroupRequest,
  createGroupSuccess,
  editGroupRequest,
  editGroupSuccess,
  getChatDetailsRequest,
  getChatDetailsSuccess,
  getChatListRequest,
  getChatListSuccess,
  getChatMessagesRequest,
  getChatMessagesSuccess,
  getChatNotificationsRequest,
  getChatNotificationsSuccess,
  getChatUsersRequest,
  getChatUsersSuccess,
  getDirectChatUsersRequest,
  getDirectChatUsersSuccess,
  getGroupParticipantsRequest,
  getGroupParticipantsSuccess,
  getLinkPreviewRequest,
  getMentionsRequest,
  getMentionsSuccess,
  getPossibleAdminsRequest,
  getPossibleAdminsSuccess,
  getRemainingChatUsersRequest,
  getRemainingChatUsersSuccess,
  getSidebarChatListRequest,
  getSidebarChatListSuccess,
  getThreadsRequest,
  getThreadsSuccess,
  getTransactionChatsListRequest,
  getTransactionChatsListSuccess,
  leaveChatRequest,
  leaveChatSuccess,
  loadRoomRequest,
  loadRoomSuccess,
  loadTransactionRoomRequest,
  loadTransactionRoomSuccess,
  makeGroupAdminRequest,
  makeGroupAdminSuccess,
  removeChatTokenRequest,
  removeChatTokenSuccess,
  removeGroupAdminRequest,
  removeGroupAdminSuccess,
  removeGroupMembersRequest,
  removeGroupMembersSuccess,
  removeReactMsgRequest,
  removeReactMsgSuccess,
  sendMessageRequest,
  sendMessageSuccess,
  sendReactMsgRequest,
  sendReactMsgSuccess,
  unarchiveRoomRequest,
  unarchiveRoomSuccess,
  unblockRoomRequest,
  unblockRoomSuccess,
  updateChatNotiSettingRequest,
  updateChatNotiSettingSuccess,
} from "../slicers/chat";

//GET CHAT USERS
function* getChatUsers(action) {
  const {
    payload: { queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_USERS_FOR_CHAT,
      queryParams: queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getChatUsersSuccess({
          isPaginated: isMoreData,
          data: manipulateChatUsersList(response.data),
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

//GET DIRECT CHAT USERS
function* getDirectChatUsers(action) {
  const {
    payload: { queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_USERS_FOR_CHAT,
      queryParams: queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getDirectChatUsersSuccess({
          isPaginated: isMoreData,
          data: manipulateChatUsersList(response.data),
        })
      );
      responseCallback?.(true, manipulateChatUsersList(response.data));
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

//LOAD CHAT
function* loadRoom() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(loadRoomRequest.type);

    try {
      const response = yield call(callRequest, {
        baseURL: CHAT_URL,
        url: LOAD_CHAT_ROOM,
        data: payload,
      });
      if (response) {
        yield put(loadRoomSuccess(manipulateChatData(response.data)));
        yield put(addRoomToChatList(manipulateChatData(response.data)));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

//GET CHATS LIST
function* getChatList(action) {
  const {
    payload: { queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      baseURL: CHAT_URL,
      url: GET_CHAT_LIST,
      queryParams: queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getChatListSuccess({
          isPaginated: isMoreData,
          data: manipulateChatsList(response.data),
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    console.error(err, "err");
    responseCallback?.(false, err);
  }
}

//GET MESSAGES
function* getChatMessages(action) {
  const {
    payload: { queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_ROOM_MESSAGES,
      baseURL: CHAT_URL,
      queryParams: queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getChatMessagesSuccess({
          isPaginated: isMoreData,
          total: response.data?.total,
          data: manipulateChatMessages(response.data?.messages),
        })
      );
      responseCallback?.(true, response.data);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

//SEND MESSAGE
function* sendMessage() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(sendMessageRequest.type);

    try {
      const response = yield call(callRequest, {
        baseURL: CHAT_URL,
        url: SEND_MESSAGE,
        data: payload,
      });
      if (response.status) {
        yield put(sendMessageSuccess(manipulateChatMessageData(response.data)));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err, "error");
      responseCallback?.(false, err);
    }
  }
}

//ADD REACT
function* reactMessage() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(sendReactMsgRequest.type);

    try {
      const response = yield call(callRequest, {
        baseURL: CHAT_URL,
        url: ADD_REACT,
        data: payload,
      });
      if (response.status) {
        yield put(sendReactMsgSuccess(manipulateReactData(response.data)));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}
//REMOVE REACT
function* removeMessageReact() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(removeReactMsgRequest.type);

    try {
      const response = yield call(callRequest, {
        baseURL: CHAT_URL,
        url: REMOVE_REACT,
        data: payload,
      });
      if (response.status) {
        yield put(removeReactMsgSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

//REMOVE REACT
function* getChatDetails() {
  while (true) {
    const {
      payload: { queryParams, responseCallback },
    } = yield take(getChatDetailsRequest.type);

    try {
      const response = yield call(callRequest, {
        baseURL: CHAT_URL,
        url: GET_CHAT_DETAILS,
        queryParams: queryParams,
      });
      if (response.status) {
        yield put(getChatDetailsSuccess(manipulateChatDetails(response.data)));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

//GET LINK PREVIEW
function* getLinkPreview(action) {
  const {
    payload: { payload, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_LINK_PREVIEW,
      baseURL: CHAT_URL,
      data: payload,
    });
    if (response.status) {
      responseCallback?.(true, response?.data);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

//REMOVE REACT
function* getThreads(action) {
  while (true) {
    const {
      payload: { queryParams, responseCallback },
    } = yield take(getThreadsRequest.type);
    // const {
    //   payload: { queryParams, responseCallback },
    // } = action;
    try {
      const response = yield call(callRequest, {
        baseURL: CHAT_URL,
        url: GET_THREADS,
        queryParams: queryParams,
      });
      if (response.status) {
        yield put(getThreadsSuccess(manipulateThreadData(response.data)));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

//CREATE GROUP
function* createGroup() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(createGroupRequest.type);

    try {
      const response = yield call(callRequest, {
        baseURL: CHAT_URL,
        url: CREATE_CHAT_GROUP,
        data: payload,
      });
      if (response.status) {
        yield put(createGroupSuccess(manipulateChatData(response.data)));
        yield put(loadRoomSuccess(manipulateChatData(response.data)));
        responseCallback?.(true, manipulateChatData(response.data));
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err, "RESS");
      responseCallback?.(false, err);
    }
  }
}

//GET GROUP PARTICIPANTS
function* getParticipants(action) {
  const {
    payload: { queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      baseURL: CHAT_URL,
      url: GET_GROUP_PARTICIPANTS,
      queryParams: queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getGroupParticipantsSuccess({
          isPaginated: isMoreData,
          data: manipulateRoomMembersList(response.data),
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

//GET REMAINING CHAT USERS
function* getRemainingChatUsers(action) {
  const {
    payload: { queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_REMAINING_USERS_FOR_CHAT,
      queryParams: queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getRemainingChatUsersSuccess({
          isPaginated: isMoreData,
          data: manipulateChatUsersList(response.data),
        })
      );
      responseCallback?.(true, manipulateChatUsersList(response.data));
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

//ADD ROOM MEMBERS
function* addRoomMembers() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(addGroupMembersRequest.type);

    try {
      const response = yield call(callRequest, {
        baseURL: CHAT_URL,
        url: ADD_GROUP_MEMBERS,
        data: payload,
      });
      if (response?.status) {
        yield put(addGroupMembersSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

//REMOVE ROOM MEMBERS
function* removeRoomMember() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(removeGroupMembersRequest.type);

    try {
      const response = yield call(callRequest, {
        baseURL: CHAT_URL,
        url: REMOVE_GROUP_MEMBER,
        data: payload,
      });
      if (response?.status) {
        yield put(removeGroupMembersSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

//MAKE GROUP ADMIN
function* makeGroupAdmin() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(makeGroupAdminRequest.type);

    try {
      const response = yield call(callRequest, {
        baseURL: CHAT_URL,
        url: MAKE_GROUP_ADMIN,
        data: payload,
      });
      if (response?.status) {
        yield put(makeGroupAdminSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

//REMOVE GROUP ADMIN
function* removeGroupAdmin() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(removeGroupAdminRequest.type);

    try {
      const response = yield call(callRequest, {
        baseURL: CHAT_URL,
        url: REMOVE_GROUP_ADMIN,
        data: payload,
      });
      if (response?.status) {
        yield put(removeGroupAdminSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

//GET MENTIONS
function* getMentions(action) {
  const {
    payload: { queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      baseURL: CHAT_URL,
      url: GET_GROUP_PARTICIPANTS,
      queryParams: queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getMentionsSuccess({
          isPaginated: isMoreData,
          data: manipulateRoomMembersList(response.data),
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    console.error(err);
    responseCallback?.(false, err);
  }
}

//GET POSSIBLE ADMINS
function* getPossibleAdmins(action) {
  const {
    payload: { queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      baseURL: CHAT_URL,
      url: GET_GROUP_PARTICIPANTS,
      queryParams: queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getPossibleAdminsSuccess({
          isPaginated: isMoreData,
          data: manipulateRoomMembersList(response.data),
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    console.error(err);
    responseCallback?.(false, err);
  }
}

//LOAD CHAT
function* loadTransactionRoom() {
  while (true) {
    const {
      payload: { queryParams, responseCallback },
    } = yield take(loadTransactionRoomRequest.type);
    try {
      const response = yield call(callRequest, {
        baseURL: CHAT_URL,
        url: LOAD_TRANSACTION_ROOM,
        queryParams: queryParams,
      });
      if (response.status) {
        yield put(
          loadTransactionRoomSuccess(manipulateChatData(response.data))
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

//BLOCK ROOM
function* blockRoom() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(blockRoomRequest.type);
    try {
      const response = yield call(callRequest, {
        baseURL: CHAT_URL,
        url: BLOCK_ROOM,
        data: payload,
      });
      if (response.status) {
        yield put(blockRoomSuccess(response?.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

//UNBLOCK ROOM
function* unblockRoom() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(unblockRoomRequest.type);
    try {
      const response = yield call(callRequest, {
        baseURL: CHAT_URL,
        url: UNBLOCK_ROOM,
        data: payload,
      });
      if (response.status) {
        yield put(unblockRoomSuccess(response?.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

//ARCHIVE ROOM
function* archiveRoom() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(archiveRoomRequest.type);
    try {
      const response = yield call(callRequest, {
        baseURL: CHAT_URL,
        url: ARCHIVE_ROOM,
        data: payload,
      });
      if (response.status) {
        yield put(archiveRoomSuccess(response?.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

//UARCHIVE ROOM
function* unarchiveRoom() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(unarchiveRoomRequest.type);
    try {
      const response = yield call(callRequest, {
        baseURL: CHAT_URL,
        url: UNARCHIVE_ROOM,
        data: payload,
      });
      if (response.status) {
        yield put(unarchiveRoomSuccess(response?.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

//LEAVE ROOM
function* leaveRoom() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(leaveChatRequest.type);
    try {
      const response = yield call(callRequest, {
        baseURL: CHAT_URL,
        url: LEAVE_CHAT,
        data: payload,
      });
      if (response.status) {
        yield put(leaveChatSuccess(response?.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

//CHECK LAST CHAT ADMIN
function* checkAdmin() {
  while (true) {
    const {
      payload: { queryParams, responseCallback },
    } = yield take(checkLastGroupAdminRequest.type);
    try {
      const response = yield call(callRequest, {
        baseURL: CHAT_URL,
        url: CHECK_LAST_GROUP_ADMIN,
        queryParams,
      });
      if (response) {
        yield put(checkLastGroupAdminSuccess(response?.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

//GET SIDEBAR CHATS LIST
function* getSidebarChatlist(action) {
  const {
    payload: { queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      baseURL: CHAT_URL,
      url: GET_CHAT_LIST,
      queryParams: queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getSidebarChatListSuccess({
          isPaginated: isMoreData,
          data: manipulateChatsList(response.data),
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    console.error(err, "err");
    responseCallback?.(false, err);
  }
}

//EDIT GROUP
function* editGroup() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(editGroupRequest.type);
    try {
      const response = yield call(callRequest, {
        baseURL: CHAT_URL,
        url: EDIT_CHAT_GROUP,
        data: payload,
      });
      if (response.status) {
        yield put(editGroupSuccess(response?.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

//GET CHAT NOTIFICATIONS
function* getChatNotificationSettings() {
  while (true) {
    const {
      payload: { responseCallback },
    } = yield take(getChatNotificationsRequest.type);
    try {
      const response = yield call(callRequest, {
        baseURL: CHAT_URL,
        url: GET_CHAT_NOTIFICATIONS,
      });
      if (response.status) {
        yield put(
          getChatNotificationsSuccess(
            manipulateNotificationSettingList(response?.data)
          )
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

//UPDATE NOTI SETTINGS
function* updateNotiSetting(action) {
  const {
    payload: { payload, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: UPDATE_CHAT_NOTIFICATION,
      baseURL: CHAT_URL,
      data: payload,
    });
    if (response.status) {
      yield put(
        updateChatNotiSettingSuccess(
          manipulateNotificationSettingList(response?.data)
        )
      );
      responseCallback?.(true, response?.data);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

//REMOVE CHAT TOKEN
function* removeChatToken() {
  while (true) {
    const {
      payload: { responseCallback },
    } = yield take(removeChatTokenRequest.type);
    try {
      const response = yield call(callRequest, {
        baseURL: CHAT_URL,
        url: REMOVE_CHAT_DEVICE_TOKEN,
      });
      if (response.status) {
        yield put(removeChatTokenSuccess());
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

//GET TRANSACTION CHAT LIST
function* getTransChatList() {
  while (true) {
    const {
      payload: { responseCallback },
    } = yield take(getTransactionChatsListRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_TRANS_CHATS,
      });
      if (response.status) {
        yield put(
          getTransactionChatsListSuccess(
            manipulateTransactionChatList(response.data)
          )
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}
export default function* root() {
  yield takeLatest(getChatUsersRequest.type, getChatUsers);
  yield takeLatest(getDirectChatUsersRequest.type, getDirectChatUsers);
  yield fork(loadRoom);
  yield takeEvery(getChatListRequest.type, getChatList);
  yield takeLatest(getChatMessagesRequest.type, getChatMessages);
  yield fork(sendMessage);
  yield fork(reactMessage);
  yield fork(removeMessageReact);
  yield fork(getChatDetails);
  yield takeEvery(getLinkPreviewRequest.type, getLinkPreview);
  yield fork(getThreads);
  // yield takeLatest(getThreadsRequest.type, getThreads);
  yield fork(createGroup);
  yield takeLatest(getGroupParticipantsRequest.type, getParticipants);
  yield takeLatest(getRemainingChatUsersRequest.type, getRemainingChatUsers);
  yield fork(addRoomMembers);
  yield fork(removeRoomMember);
  yield fork(makeGroupAdmin);
  yield fork(removeGroupAdmin);
  yield takeLatest(getMentionsRequest.type, getMentions);
  yield fork(loadTransactionRoom);
  yield fork(blockRoom);
  yield fork(unblockRoom);
  yield fork(archiveRoom);
  yield fork(unarchiveRoom);
  yield fork(leaveRoom);
  yield fork(checkAdmin);
  yield takeLatest(getSidebarChatListRequest.type, getSidebarChatlist);
  yield fork(editGroup);
  yield fork(getChatNotificationSettings);
  yield takeEvery(updateChatNotiSettingRequest.type, updateNotiSetting);
  yield takeLatest(getPossibleAdminsRequest.type, getPossibleAdmins);
  yield fork(removeChatToken);
  yield fork(getTransChatList);
}
