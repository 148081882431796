import React, { useState, useEffect, useRef } from "react";
import { Form, Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { ClipLoader } from "react-spinners";
import CustomInfiniteScroll from "../customInfiniteScroll";
import "./styles.scss";

const CustomOtherSelect = ({
  required,
  options,
  label,
  name,
  rules,
  placeholder,
  valueProp,
  labelProp,
  handleSet = () => {},
  handleChange = () => {},
  onOptionSelect = () => {},
  disabled,
  isLoading,
  handleNextData,
  total,
  value,
}) => {
  // STATES
  const [isOpen, setOpen] = useState(false);
  const [isOtherOpen, setOtherOpen] = useState(false);
  const [isOther, setOther] = useState(false);
  const [selectedVal, setselectedVal] = useState(null);

  // CONST VALS
  const HEIGHT = 45;
  const BOX_HEIGHT = `calc(100% - ${HEIGHT}px)`;
  const OTHER_HEIGHT = 91;
  const OTHER_BOX_HEIGHT = `calc(100% - ${OTHER_HEIGHT}px)`;

  // REF
  const dropdownRef = useRef();

  // HOOKS
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);

  useEffect(() => {
    if (value) {
      const isOption = options.find((x) => x[labelProp] === value);
      setselectedVal(value);
      setOther(isOption ? false : true);
    }
  }, [value, options]);

  // HANDLERS
  const openHandler = () => {
    !disabled && setOpen(!isOpen);
  };
  const otherOpenHandler = () => {
    !disabled && setOtherOpen(!isOtherOpen);
  };
  const setValHandler = (val) => {
    setOther(false);
    const label = options.find((x) => x[valueProp] === val)?.[labelProp];
    setselectedVal(label);
    handleSet(name, val);
    onOptionSelect(val);
    handleChange();
    setOpen(false);
  };
  const setOtherValHandler = (val) => {
    setOther(true);
    setselectedVal(val);
    handleSet(name, val);
    handleChange();
  };

  return (
    <Form.Item
      label={label}
      rules={rules}
      required={required}
      name={name}
      className="create-form-item">
      <div
        className={`other-select-input-box ${isOpen ? "active" : ""} ${
          disabled ? "disabled" : ""
        }`}
        ref={dropdownRef}>
        <div
          className={`placeholder-box ${selectedVal ? "active" : ""}`}
          onClick={openHandler}>
          <span>
            {isOther ? "Other: " : ""}
            {selectedVal || placeholder}
          </span>
          {isLoading ? (
            <ClipLoader size={15} color="#1C2B48" />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </div>
        {isOpen && (
          <div className="other-dropdown-wrapper">
            <div className="options-wrapper">
              <div className="options-box">
                <CustomInfiniteScroll
                  dataLength={options?.length}
                  total={total}
                  next={handleNextData}
                  maxHeight={isOtherOpen ? OTHER_HEIGHT : HEIGHT}
                  minHeight={isOtherOpen ? OTHER_BOX_HEIGHT : BOX_HEIGHT}
                  className="options-list"
                  isWholePage={false}
                  isWholeParent={true}
                  isLoaderNull>
                  {options?.map((data, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        setValHandler(data[valueProp]);
                      }}
                      className={
                        selectedVal == data[labelProp] ? "selected" : ""
                      }>
                      {data[labelProp]}
                    </li>
                  ))}
                </CustomInfiniteScroll>
                <li className={`other ${isOtherOpen ? "active" : ""}`}>
                  <span className="txt" onClick={otherOpenHandler}>
                    Other {isOtherOpen ? ":" : ""}
                  </span>
                  {isOtherOpen && (
                    <span className="other-box">
                      <Input
                        onChange={(e) => setOtherValHandler(e.target.value)}
                        onPressEnter={openHandler}
                      />
                    </span>
                  )}
                </li>
              </div>
            </div>
          </div>
        )}
      </div>
    </Form.Item>
  );
};
export default CustomOtherSelect;
