import _ from "lodash";
import { getFormattedDateTime } from "../services/utils";
import { manipulatePermissionsList } from "./transactiondetail";

// TRANSACTION
export const manipulateTransactionsData = (data) => {
  if (_.isEmpty(data)) return {};
  let transactions = {};

  transactions["id"] = data.id ?? 1;
  transactions["name"] = data.transactionName ?? "";
  transactions["type"] = data.transactiontype.title ?? "";
  transactions["createdAt"] = getFormattedDateTime(data.createdAt) ?? "";
  transactions["lastUpdated"] = getFormattedDateTime(data.updatedAt) ?? "";
  transactions["isTransactionAdmin"] = data.isAdmin ?? "";
  transactions["notificationCount"] = data.notificationCount ?? 0;
  transactions["status"] = data?.status ?? 0;
  transactions["role"] = data?.role ?? "";
  transactions["slug"] = data.transactionSlug ?? "";
  transactions["roomSlug"] = data?.room?.slug ?? "";
  transactions["permissions"] = manipulatePermissionsList(
    data?.permissions,
    true,
    false
  );
  return transactions;
};
export const manipulateTransactionsList = (data) => {
  let transactions = [];
  data.forEach((item, index) => {
    transactions.push(manipulateTransactionsData(item));
  });
  return transactions;
};
