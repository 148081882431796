import React, { useState, useEffect } from "react";
import { Col } from "antd";
import {
  CardLoader,
  CustomInfiniteScroll,
  EmptyBox,
  HorizontalLine,
  ProfileImage,
  Tabs,
} from "../../../../../components";
import { Images } from "../../../../../themes";
import {
  CHAT_TABS,
  DATA_PER_PAGE_LIMIT,
  DEFAULT_PROFILE_COLOR,
  GROUP_MESSAGES_ROUTE,
  GROUP_MESSAGE_ROUTE,
  MESSAGES_ROUTE,
  MESSAGE_ROUTE,
  TIME_FORMAT1,
} from "../../../../../constants";
import "./styles.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { replaceValInString } from "../../../../../services/utils";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setIsSelectFirst, setThread } from "../../../../../redux/slicers/chat";

function ChatList({
  chatItems,
  selected,
  handleInitChat,
  getUsers,
  isLoading,
  setSelected,
  firstLoad,
  setFirstLoad,
  handleSelect,
  isGroupChat,
  offset,
  search,
  total,
  isMoreData,
  setSearch,
  setOffset,
  setTotal,
  setMoreData,
  tab,
  setTab,
  addGroupHandler,
  newLoader,
  setNewLoader,
}) {
  //REDUX DATA
  const { selectedRoom, isSelect } = useSelector((state) => state.chat);

  //CONST VALS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { chat_id: id } = useParams();
  const { pathname, state } = useLocation();

  //HANDLERS
  const handleSearch = (value) => {
    setSearch(value);
    setMoreData(false);
    setOffset(0);
  };

  const getUsersHelper = (search) => {
    setNewLoader(true);
    const queryParams = { limit: DATA_PER_PAGE_LIMIT, offset: offset };
    if (search) {
      queryParams["search"] = search;
    }
    getUsers({
      queryParams: queryParams,
      logic(res) {
        setNewLoader(false);
        setTotal(res.total);
        setFirstLoad(false);
      },
    });
  };

  const handleNextData = () => {
    setOffset(offset + DATA_PER_PAGE_LIMIT);
    setMoreData(true);
  };

  const navigateChat = (item) => {
    dispatch(setThread(null));
    setSelected(item);
    if (id !== item?.slug) {
      navigate(
        replaceValInString(
          isGroupChat ? GROUP_MESSAGES_ROUTE : MESSAGES_ROUTE,
          {
            ":chat_id": item?.slug,
          }
        )
      );
    }
    handleSelect(item);
  };

  //HOOKS
  useEffect(() => {
    getUsersHelper(search);
  }, [search, offset]);

  useEffect(() => {
    const chatItem = chatItems?.find((x) => x.slug === id);
    if (chatItems?.length > 0 && firstLoad) {
      setSelected(chatItem);
      handleSelect(chatItem);
    } else if (firstLoad) {
      setSelected(null);
    }
  }, [chatItems, id]);

  useEffect(() => {
    const chatItem = chatItems?.find((x) => x.slug === id);
    if (chatItem) {
      handleSelect(chatItem);
    }
  }, [id]);

  // useEffect(() => {
  //   if (selectedRoom?.id && selectedRoom?.id !== +id && isGroupChat) {
  //     const chatItem = chatItems?.find((x) => x.id === +id);
  //     if (!chatItem) {
  //       navigate(
  //         replaceValInString(
  //           isGroupChat ? GROUP_MESSAGES_ROUTE : MESSAGES_ROUTE,
  //           {
  //             ":chat_id": selectedRoom?.id,
  //           }
  //         )
  //       );
  //     }
  //   }
  // }, [selectedRoom]);

  useEffect(() => {
    if (!id && chatItems?.length > 0 && !selectedRoom?.id) {
      setTab(isGroupChat);
      setSelected(chatItems?.[0]);
      handleSelect(chatItems?.[0]);
      navigate(
        replaceValInString(
          isGroupChat ? GROUP_MESSAGES_ROUTE : MESSAGES_ROUTE,
          {
            ":chat_id": chatItems?.[0]?.slug,
          }
        )
      );
    }
  }, [id, chatItems]);

  useEffect(() => {
    handleSearch("");
    if (!firstLoad) {
      if (!isSelect) {
        handleSelect({});
        dispatch(setThread(null));
        dispatch(setIsSelectFirst(false));
      }
      navigate(tab ? GROUP_MESSAGE_ROUTE : MESSAGE_ROUTE);
      dispatch(setIsSelectFirst(false));
    }
  }, [tab]);

  // useEffect(() => {
  // }, []);

  return (
    <Col xxl={6} xs={8}>
      <div className="chat-list-container">
        <Tabs buttonsList={CHAT_TABS} state={tab} setState={setTab} />
        <div className="search-box">
          <Images.Search />
          <input
            type="text"
            placeholder={"Search"}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        {isGroupChat && (
          <div className="my-groups">
            <p>My Groups</p>
            <Images.AddGroup className="c-p" onClick={addGroupHandler} />
          </div>
        )}
        {isLoading && !isMoreData && newLoader ? (
          <CardLoader size={10} height={"52vh"} />
        ) : (
          <CustomInfiniteScroll
            dataLength={chatItems?.length}
            total={total}
            next={handleNextData}
            isWholePage
            maxHeight={isGroupChat ? 402 : 390}
            minHeight={"51vh"}
            className="chat-list"
          >
            {chatItems?.length >= 1 ? (
              chatItems?.map((item, index) => {
                const isSelected = selectedRoom?.id === item?.id;
                const prevCondition =
                  chatItems?.findIndex((x) => selectedRoom?.id === x?.id) -
                    1 ===
                    index || index === chatItems?.length - 1;
                return (
                  <React.Fragment key={index}>
                    <div
                      className={`chat-item c-p ${
                        isSelected ? "selected" : ""
                      }`}
                      onClick={() => navigateChat(item)}
                    >
                      <div className="user-info">
                        <ProfileImage
                          image={item?.isGroup ? null : item?.individual?.image}
                          username={item?.name}
                          color={
                            item?.individual?.bgColor ?? DEFAULT_PROFILE_COLOR
                          }
                          isBlocked={
                            item?.isGroup ? false : !item?.individual?.isActive
                          }
                        />
                        <div className="name">
                          <h3>{item?.name}</h3>
                          <p>
                            {isGroupChat ? (
                              item?.membersCount + " People +"
                            ) : item?.lastMessage?.isMedia ? (
                              <span>
                                <Images.Attachment /> Media
                              </span>
                            ) : (
                              item?.lastMessage?.formattedMessage
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="time">
                        <p>
                          {moment(item?.lastMessage?.createdAt).format(
                            TIME_FORMAT1
                          )}
                        </p>
                        {item?.unreadCount > 0 && !isSelected && (
                          <span>
                            {item?.unreadCount > 9 ? "9+" : item?.unreadCount}
                          </span>
                        )}
                      </div>
                    </div>
                    <HorizontalLine
                      style={{
                        visibility:
                          isSelected || prevCondition ? "hidden" : "visible",
                      }}
                      className="horizontal-line"
                    />
                  </React.Fragment>
                );
              })
            ) : (
              <EmptyBox height={"100%"} />
            )}
          </CustomInfiniteScroll>
        )}
      </div>
      {/* </div> */}
    </Col>
  );
}

export default ChatList;
