import React, { useState, useEffect, useMemo } from "react";
import { Messages } from "../../../modules";
import {
  DealRoom,
  Checklist,
  Milestones,
  Documents,
  DataRoom,
  TransactionAdmin,
  TransactionMessages,
} from "./partials";
import {
  InviteModal,
  TransactionHeader,
  TransactionTopNav,
} from "./components";
import { useNavigate, useParams } from "react-router-dom";
import { useCustomDispatch } from "../../../helpers/useCustomDispatch";
import {
  getTransactionDetailRequest,
  getRoomDetailRequest,
  removeTransactionDetailRequest,
} from "../../../redux/slicers/transactiondetail";
import { useSelector } from "react-redux";
import { CardLoader } from "../../../components";
import _ from "lodash";
import {
  PERMISSIONS,
  PERMISSIONS_ACCESS,
  ROOM_ACCESS_TYPES,
  TRANSACTION_DETAIL_ROUTE,
  TRANSACTION_TABS_SLUGS,
} from "../../../constants";
import "./styles.scss";
import {
  getActionPermission,
  replaceValInString,
} from "../../../services/utils";

const TransactionDetail = () => {
  // STATES
  const [isLoading, setLoading] = useState(true);
  const [isRoomLoading, setRoomLoading] = useState(true);
  const [inviteModalPreview, setInviteModalPreview] = useState(false);

  // REDUX DATA
  const { data, roomDetail, roomAccess } = useSelector(
    (state) => state.transactiondetail
  );

  // CUSTOM DISPATCH
  const [getBreakoutRoomDetail, isBrLoading] =
    useCustomDispatch(getRoomDetailRequest);
  const [getTransactionDetail] = useCustomDispatch(getTransactionDetailRequest);
  const [removeTransactionDetail] = useCustomDispatch(
    removeTransactionDetailRequest
  );

  // CONST VAL
  const { room, id, tab } = useParams();
  const userPermissions = data?.permissions;
  const isRoom = !_.isEmpty(data) && room !== data.roomSlug;
  const isRoomAccessable = isRoom ? roomAccess : null;
  const isBrRoomLoading = isRoomLoading;
  const tabPermission = PERMISSIONS.find((x) => x.route === tab)?.access;
  const linkOnly =
    tab === "datarooms" &&
    !getActionPermission(PERMISSIONS_ACCESS.VIEW_DATAROOM) &&
    getActionPermission(PERMISSIONS_ACCESS.VIEW_LINK);
  const isAccess =
    userPermissions?.find((x) => x.slug === tabPermission) || linkOnly;
  const navigate = useNavigate();

  // HELPERS
  const getBreakoutRoomDetailHelper = () => {
    getBreakoutRoomDetail({
      pathParams: room,
      callback() {
        setRoomLoading(false);
      },
    });
  };
  // HOOKS
  useEffect(() => {
    getTransactionDetail({
      pathParams: id,
      callback() {
        setLoading(false);
      },
    });
  }, [id, room]);

  useEffect(() => {
    isRoom && getBreakoutRoomDetailHelper();
  }, [room, isRoom]);

  useEffect(() => {
    return () => {
      removeTransactionDetail();
    };
  }, []);

  useEffect(() => {
    if (!isAccess && tabPermission && !isLoading) {
      const vals = {
        ":id": id,
        ":room": room,
      };
      navigate(replaceValInString(TRANSACTION_DETAIL_ROUTE, vals));
      return;
    }
    return;
  }, [isAccess, isLoading, tab]);

  // HANDLERS
  const checkUserStatusHandler = (func) => {
    if (!isRoomAccessable) {
      func();
    }
  };

  const checkApiUserStatusHandler = (func) => {
    const isRoomUpdated = roomDetail?.slug === room;
    if (isRoomAccessable === ROOM_ACCESS_TYPES.DELETED) {
      return;
    }
    if (isRoom) {
      // isRoomUpdated &&
      func();
      return;
    }
    func();
  };

  // CUSTOM COMPONENETS
  const StepComponent = useMemo(() => {
    switch (tab) {
      case TRANSACTION_TABS_SLUGS.CHECKLIST:
      case TRANSACTION_TABS_SLUGS.DELETED_CHECKLIST:
        return (
          <Checklist
            isDeleted={tab === TRANSACTION_TABS_SLUGS.DELETED_CHECKLIST}
          />
        );
      case TRANSACTION_TABS_SLUGS.DOCUMENTS:
      case TRANSACTION_TABS_SLUGS.DELETED_DOCUMENTS:
        return (
          <Documents
            isDeleted={tab === TRANSACTION_TABS_SLUGS.DELETED_DOCUMENTS}
            roomAccess={isRoomAccessable}
            checkUserStatusHandler={checkUserStatusHandler}
            checkApiUserStatusHandler={checkApiUserStatusHandler}
          />
        );
      case TRANSACTION_TABS_SLUGS.MILESTONES:
        return <Milestones transactionslug={id} roomslug={room} />;
      case TRANSACTION_TABS_SLUGS.MESSAGES:
        return (
          <TransactionMessages
            isRoom={isRoom}
            isLoading={isRoom ? isBrRoomLoading : isBrLoading}
          />
        );
      case TRANSACTION_TABS_SLUGS.DATAROOMS:
        return <DataRoom transactionslug={id} roomslug={room} />;
      case TRANSACTION_TABS_SLUGS.SETTING:
      case TRANSACTION_TABS_SLUGS.ADMIN:
        return (
          <TransactionAdmin
            isSetting={
              tab === TRANSACTION_TABS_SLUGS.SETTING && data.isAdminLoggedIn
            }
            isAdmin={data.isAdminLoggedIn}
            data={data}
          />
        );
      default:
        return (
          <DealRoom
            roomslug={room}
            isLoading={isBrRoomLoading}
            getBreakoutRoomDetail={getBreakoutRoomDetailHelper}
            isRoom={isRoom}
            transactionslug={id}
            setInviteModalPreview={setInviteModalPreview}
            isAdminLoggedIn={data.isAdminLoggedIn}
            roomAccess={isRoomAccessable}
            checkUserStatusHandler={checkUserStatusHandler}
            checkApiUserStatusHandler={checkApiUserStatusHandler}
          />
        );
    }
  }, [tab, room, data, isBrRoomLoading, isRoomAccessable]);

  return (
    <section className="transaction-detail">
      {isLoading ? (
        <CardLoader height={600} size={20} />
      ) : (
        <>
          <TransactionHeader data={data} transactionslug={id} />
          <TransactionTopNav
            roomSlug={room}
            currentTab={tab}
            transactionSlug={id}
            transactionId={data?.id}
            isTransactionAdmin={data?.isAdminLoggedIn}
            roomAccess={isRoomAccessable}
          />
          {StepComponent}
        </>
      )}
      {inviteModalPreview && (
        <InviteModal
          lastModified={data?.inviteModified}
          modalVisible={inviteModalPreview}
          setModalVisible={setInviteModalPreview}
          roomslug={room}
          isRoom={isRoom}
        />
      )}
    </section>
  );
};

export default TransactionDetail;
