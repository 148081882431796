import React, { useEffect } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Input, Modal, Select } from "antd";
import { useCustomDispatch } from "../../helpers/useCustomDispatch";
import { addTeamMemberRequest } from "../../redux/slicers/company";
import { inputFieldRule, toastAlert } from "../../services/utils";
import { ButtonComponent } from "../";
import "./styles.scss";

const AddTeamMemberModal = ({
  handleClose,
  modalPreview,
  addresses,
  handleSuccess = () => {},
}) => {
  const [addTeamMember, isLoading] = useCustomDispatch(addTeamMemberRequest);
  const [form] = Form.useForm();
  const closeHandler = () => {
    handleClose();
    form.resetFields();
  };
  const handleFormFinish = (values) => {
    const payload = {
      email: values.email,
      addressId: values.branch,
    };
    addTeamMember({
      payload,
      logic(response) {
        toastAlert(response.message);
        form.resetFields();
        handleSuccess();
        handleClose();
      },
    });
  };
  useEffect(() => {
    if (addresses) {
      form.setFieldsValue({
        branch: addresses?.[0]?.id,
      });
    }
  }, [addresses, modalPreview]);

  return (
    <Modal
      destroyOnClose
      visible={modalPreview}
      centered
      footer={null}
      width={490}
      getContainer={false}
      className="checklist-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>Add Team Members</h3>
            <p>Fill The Details Below</p>
          </div>
          <Form form={form} className="form" onFinish={handleFormFinish}>
            <Form.Item
              name="email"
              rules={inputFieldRule({ name: "Email", isEmail: true })}
            >
              <Input
                type="email"
                placeholder={"Enter email address"}
                autoFocus
              />
            </Form.Item>
            <Form.Item
              name="branch"
              rules={inputFieldRule({ name: "Branch", isWhiteSpace: false })}
            >
              <Select
                placeholder="Select branch"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {addresses?.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <div className="create-button">
              <Form.Item>
                <ButtonComponent
                  className="login-btn"
                  isLoading={isLoading}
                  disabled={isLoading}
                  text={"Invite"}
                  style={{ marginTop: "0px" }}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default AddTeamMemberModal;
