import React from "react";
import Images from "../../../../../themes/images";
import { useNavigate } from "react-router-dom";
import {
  EDIT_TRANSACTION_ROUTE,
  PERMISSIONS_ACCESS,
} from "../../../../../constants";
import { DisabledTooltip, StatusToggle } from "../../../../../components";
import { getActionPermission, toastAlert } from "../../../../../services/utils";
import { useCustomDispatch } from "../../../../../helpers/useCustomDispatch";
import { changeTransactionStatusRequest } from "../../../../../redux/slicers/transactions";
import RequestsListModal from "../requestsListModal";
import { useState } from "react";

const TransactionHeader = ({ data, transactionslug }) => {
  //STATES
  const [requestModalPreview, setRequestModalPreview] = useState(false);

  // CONST VAL
  const navigate = useNavigate();
  const isEditAccess = getActionPermission(PERMISSIONS_ACCESS.EDIT_TRANSACTION);
  const isStatusChangeAccess = getActionPermission(
    PERMISSIONS_ACCESS.UPDATE_TRANSACTION_STATUS
  );

  //CUSTOM DISPATCH
  const [changeStatus, statusLoader] = useCustomDispatch(
    changeTransactionStatusRequest
  );

  //HANDLERS
  const changeStatusHandler = () => {
    changeStatus({
      pathParams: transactionslug,
      logic(res) {
        toastAlert(res.message);
      },
    });
  };

  const requestModalPreviewHandler = () => {
    setRequestModalPreview(!requestModalPreview);
  };

  return (
    <div className="transaction-header">
      <div className="detail-box">
        <h5>Transaction:</h5>
        <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <h2>{data?.name ?? ""}</h2>
          <DisabledTooltip
            innerSec={
              <Images.Edit
                className="c-p"
                onClick={() => {
                  isEditAccess &&
                    navigate(EDIT_TRANSACTION_ROUTE + transactionslug);
                }}
              />
            }
            visible={!isEditAccess}
          />
          {!data?.status && data?.requests && (
            <Images.Requests
              className="c-p"
              onClick={requestModalPreviewHandler}
            />
          )}
        </span>

        <DisabledTooltip
          innerSec={
            <StatusToggle
              check={data.status}
              style={{ marginLeft: "20px" }}
              confirmDescription={
                "Are you sure you want to change this transaction's status?"
              }
              isStatic={!isStatusChangeAccess}
              confirmLoader={statusLoader}
              handleStatusChange={changeStatusHandler}
            />
          }
          visible={!isStatusChangeAccess}
        />
      </div>
      {data.isSkip && !data?.status && (
        <DisabledTooltip
          innerSec={
            <div
              onClick={() => {
                isEditAccess &&
                  navigate(EDIT_TRANSACTION_ROUTE + transactionslug, {
                    state: { isQuestionEdit: true },
                  });
              }}
              className="skip-preview"
            >
              <span>Incomplete Transaction</span>
              <Images.IncompleteTr />
            </div>
          }
          visible={!isEditAccess}
          className="dis"
        />
      )}
      <RequestsListModal
        preview={requestModalPreview}
        handleClose={requestModalPreviewHandler}
      />
    </div>
  );
};

export default TransactionHeader;
