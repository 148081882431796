import React from "react";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { ProfileImage } from "../../../../components";
import "./styles.scss";

const InfoItem = ({ datakey, isNumberInput, title, data, icon, action }) => {
  // REDUX DATA
  const userData = useSelector((state) => state.user.profile);

  // CONST VALS
  const company = userData?.company;
  const address = userData?.company?.addresses[0];

  // HELPERS
  const dataManuplator = () => {
    return datakey === "user"
      ? userData?.[data]
      : datakey === "company"
      ? company?.[data]
      : datakey === "address"
      ? data === "detailedaddress"
        ? `${address?.address}${
            address?.address2 ? "," + address?.address2 : ""
          }  ${address?.city}, ${address?.state}, ${address?.zip}, ${
            address?.country
          }`
        : address?.[data]
      : data;
  };
  return (
    <>
      <div className="accout-input-parent">
        <p className="title">{title}</p>
        <div className={`icon-data-wrapper ${action ? "cursor-pointer" : ""}`}>
          {icon && (
            <ProfileImage
              image={datakey === "company" ? company?.[icon] : icon}
              username={datakey === "company" ? company?.[data] : data}
              size={11}
            />
          )}
          <p
            className="data"
            onClick={() => {
              action(
                datakey === "user"
                  ? userData?.[data]
                  : datakey === "company"
                  ? company?.[data]
                  : data
              );
            }}>
            {isNumberInput ? (
              <PhoneInput value={dataManuplator()} disabled />
            ) : (
              dataManuplator()
            )}
          </p>
        </div>
      </div>
    </>
  );
};

export default InfoItem;
