import React from "react";
import { Link } from "react-router-dom";
import Images from "../../themes/images";
import "./styles.scss";

export default function ViewAllBtn({ title, size, link, disableViewAll }) {
  return (
    <div className="btn-container">
      <h4 style={{ fontSize: `${size}px` }}>{title}</h4>
      {!disableViewAll &&
        (link ? (
          <Link to={link}>
            <div className="view-all">
              <p>View All</p>
              <Images.ViewAll />
            </div>
          </Link>
        ) : (
          <div className="view-all">
            <p>View All</p>
            <Images.ViewAll />
          </div>
        ))}
    </div>
  );
}
