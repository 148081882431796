import { call, fork, put, take, takeLatest } from "redux-saga/effects";
import {
  callRequest,
  GET_COMPANIES_LIST,
  GET_ADDRESSES,
  GET_TEAM_MEMBERS,
  GET_PENDING_REQUESTS,
  ADD_TEAM_MEMBER,
  UPDATE_TEAM_MEMBER,
  APPROVE_TEAM_MEMBER,
  REMOVE_TEAM_MEMBER,
  GET_INVITATION_DETAIL,
  GET_COMPANY_MEMBERS,
  GET_TRANSACTION_COMPANIES,
  GET_INDIVIDUAL_INVITATION_DETAIL,
  GET_LOGGED_IN_USER_COMPANY,
  GET_COMPANY_ADMIN_LIST,
  CHECK_LAST_ENTERPRISE_ADMIN,
  REMOVE_SELF_FROM_ADMIN,
} from "../../config/webService";
import { ALERT_TYPES } from "../../constants";
import { toastAlert } from "../../services/utils";
import {
  getCompaniesListRequest,
  getCompaniesListSuccess,
  getAddressesListRequest,
  getAddressesListSuccess,
  getTeamMembersRequest,
  getTeamMembersSuccess,
  getPendingMembersRequest,
  getPendingMembersSuccess,
  addTeamMemberRequest,
  updateTeamMemberRequest,
  approveTeamMemberRequest,
  removeTeamMemberRequest,
  getEnterpriseInvitationDetail,
  getCompanyMembersRequest,
  getCompanyMembersSuccess,
  getLoggedInUserCompanyRequest,
  getLoggedInUserCompanySuccess,
  getCompanyAdminsListRequest,
  getCompanyAdminsListSuccess,
  checkLastEnterpriseAdminRequest,
  removeSelfAdminRequest,
} from "../slicers/company";
import {
  manipulateTeamMembersList,
  manipulateAddressList,
  manipulatecompanyProfileData,
} from "../../dataManipulator/company";
import {
  manipulateCompanyData,
  manipulateUsersList,
} from "../../dataManipulator/user";
import {
  setEnterpriseUser,
  updateEnterpriseAdminDataRequest,
} from "../slicers/user";

function* getCompaniesList(action) {
  const {
    payload: { queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_COMPANIES_LIST,
      queryParams: queryParams,
    });
    if (response.status) {
      yield put(getCompaniesListSuccess(response.data));
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* getAddresses() {
  while (true) {
    const {
      payload: { responseCallback },
    } = yield take(getAddressesListRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_ADDRESSES,
      });
      if (response.status) {
        yield put(
          getAddressesListSuccess(manipulateAddressList(response.data))
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getTeamMembers(action) {
  const {
    payload: { queryParams, responseCallback, isSort },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_TEAM_MEMBERS,
      queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getTeamMembersSuccess({
          isPaginated: isMoreData,
          data: manipulateTeamMembersList(response.data, isSort),
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* getPendingRequests() {
  while (true) {
    const {
      payload: { responseCallback },
    } = yield take(getPendingMembersRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_PENDING_REQUESTS,
      });
      if (response.status) {
        yield put(
          getPendingMembersSuccess(
            manipulateTeamMembersList(response.data, true)
          )
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* addTeamMember() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(addTeamMemberRequest.type);

    try {
      const response = yield call(callRequest, {
        url: ADD_TEAM_MEMBER,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updateTeamMember() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(updateTeamMemberRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPDATE_TEAM_MEMBER,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* approveTeamMember() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(approveTeamMemberRequest.type);

    try {
      const response = yield call(callRequest, {
        url: APPROVE_TEAM_MEMBER,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* removeTeamMember() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(removeTeamMemberRequest.type);

    try {
      const response = yield call(callRequest, {
        url: REMOVE_TEAM_MEMBER,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getInviteDetail() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getEnterpriseInvitationDetail.type);

    try {
      const response = yield call(callRequest, {
        url: GET_INVITATION_DETAIL,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response.data);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getCompanyMembers(action) {
  const {
    payload: { queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_COMPANY_MEMBERS,
      queryParams,
    });
    if (response.status) {
      yield put(
        getCompanyMembersSuccess(manipulateTeamMembersList(response.data, true))
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* getLoggedInUserCompany() {
  while (true) {
    const {
      payload: { responseCallback },
    } = yield take(getLoggedInUserCompanyRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_LOGGED_IN_USER_COMPANY,
      });
      if (response.status) {
        yield put(
          getLoggedInUserCompanySuccess(
            manipulatecompanyProfileData(response.data)
          )
        );
        yield put(
          updateEnterpriseAdminDataRequest(
            manipulatecompanyProfileData(response.data)
          )
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}
function* getCompanyAdmins() {
  while (true) {
    const {
      payload: { responseCallback },
    } = yield take(getCompanyAdminsListRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_COMPANY_ADMIN_LIST,
      });
      if (response.status) {
        yield put(
          getCompanyAdminsListSuccess(
            manipulateUsersList(response.data, [], true)
          )
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* checkLastAdmin() {
  while (true) {
    const {
      payload: { responseCallback },
    } = yield take(checkLastEnterpriseAdminRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CHECK_LAST_ENTERPRISE_ADMIN,
      });
      if (response.status) {
        responseCallback?.(true, response.data);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* removeSelfAdmin() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(removeSelfAdminRequest.type);

    try {
      const response = yield call(callRequest, {
        url: REMOVE_SELF_FROM_ADMIN,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
        yield put(setEnterpriseUser(false));
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

export default function* root() {
  yield takeLatest(getCompaniesListRequest.type, getCompaniesList);
  yield fork(getAddresses);
  yield takeLatest(getTeamMembersRequest.type, getTeamMembers);
  yield fork(getPendingRequests);
  yield fork(addTeamMember);
  yield fork(updateTeamMember);
  yield fork(approveTeamMember);
  yield fork(removeTeamMember);
  yield fork(getInviteDetail);
  yield takeLatest(getCompanyMembersRequest.type, getCompanyMembers);
  yield fork(getLoggedInUserCompany);
  yield fork(getCompanyAdmins);
  yield fork(checkLastAdmin);
  yield fork(removeSelfAdmin);
}
