import React, { useRef, useState } from "react";
import { Form, Input, Radio, Tooltip } from "antd";
import AdvisorFields from "./advisorfields";
import PartyFields from "./partyfields";
import { ROLE_TYPES } from "../../../../../../constants";
import {
  CustomAutoComplete,
  CustomOtherSelect,
  TransactionTypeWarningModal,
} from "../../../../../../components";
import {
  autoCompleteFieldRule,
  inputFieldRule,
} from "../../../../../../services/utils";
import { Images } from "../../../../../../themes";

function DetailFields({
  form,
  isEdit,
  selectedRole,
  setselectedRole,
  types,
  isTypesLoading,
  selectedtype,
  typeTotal,
  setTypeOffset,
  parties,
  isPartiesLoading,
  partyTotal,
  setPartyOffset,
  advisories,
  isAdvisoryLoading,
  advisoryTotal,
  setAdvisoryOffset,
  companies,
  isCompaniesLoading,
  setCompanySearchText,
  handleSetField,
  setIsTypeChanged,
  resetTransactionType,
}) {
  //STATES
  const [warningModalPreview, setWarningModalPreview] = useState(false);
  const [isWarningShown, setWarningShown] = useState(false);

  // CONST VAL
  const isBlocked = isEdit && !isWarningShown;

  //HANDLERS
  const handleTransactionTypeWarning = () => {
    setWarningShown(true);
    setWarningModalPreview(false);
    resetTransactionType();
  };
  const handleClose = () => {
    setWarningModalPreview(false);
  };
  const handleWarningModalPreview = () => {
    if (isBlocked) {
      setWarningModalPreview(true);
    }
  };

  return (
    <>
      <span className="create-transaction-tooltip-wrapper">
        <Form.Item
          required
          label="Transaction Name"
          className="create-form-item"
          name="name"
          rules={[
            () => ({
              validator(_, value) {
                return autoCompleteFieldRule({
                  value,
                  name: "Transaction Name",
                  isMax: true,
                  max: 150,
                });
              },
            }),
          ]}
        >
          <Input
            placeholder="Transaction Name"
            className="create-form-input"
            autoFocus
          />
        </Form.Item>
        <Tooltip
          title="Please provide a name. Make it as specific as possible."
          placement="bottomLeft"
          overlayClassName="create-transaction-tooltip"
          color="#F8FAFB"
        >
          <Images.InviteInfo className="create-transaction-info-icon" />
        </Tooltip>
      </span>
      <div className="fields-wrapper">
        <CustomOtherSelect
          required
          options={types}
          total={typeTotal}
          value={selectedtype}
          handleNextData={setTypeOffset}
          isLoading={isTypesLoading}
          handleSet={handleSetField}
          handleChange={() => setIsTypeChanged(true)}
          label={"Type of Transaction"}
          name="transactionTypeId"
          rules={[
            () => ({
              validator(_, value) {
                return autoCompleteFieldRule({
                  value,
                  name: "Transaction Type",
                  isMax: true,
                });
              },
            }),
          ]}
          placeholder="Select Type"
          labelProp={"title"}
          valueProp={"id"}
        />
        {isBlocked && (
          <span className="blocker" onClick={handleWarningModalPreview} />
        )}
      </div>
      {!isEdit && (
        <>
          <Form.Item
            label="Type Of Role"
            name="isAdvisor"
            className="create-form-item"
            rules={inputFieldRule({ name: "Role Type", isWhiteSpace: false })}
          >
            <Radio.Group
              value={selectedRole}
              onChange={(e) => {
                setselectedRole(e.target.value);
              }}
              className="create-form-radio-wrapper"
            >
              <Radio value={ROLE_TYPES[0]} className="create-form-radio">
                Party To Transaction
              </Radio>
              <Radio value={ROLE_TYPES[1]} className="create-form-radio">
                Advisor To Party
              </Radio>
            </Radio.Group>
          </Form.Item>
          {selectedRole === ROLE_TYPES[0] ? (
            <PartyFields
              parties={parties}
              isPartiesLoading={isPartiesLoading}
              partyTotal={partyTotal}
              setPartyOffset={setPartyOffset}
              handleSetField={handleSetField}
            />
          ) : selectedRole === ROLE_TYPES[1] ? (
            <AdvisorFields
              form={form}
              advisories={advisories}
              isAdvisoryLoading={isAdvisoryLoading}
              advisoryTotal={advisoryTotal}
              setAdvisoryOffset={setAdvisoryOffset}
              parties={parties}
              isPartiesLoading={isPartiesLoading}
              partyTotal={partyTotal}
              setPartyOffset={setPartyOffset}
              companies={companies}
              isCompaniesLoading={isCompaniesLoading}
              setCompanySearchText={setCompanySearchText}
              handleSetField={handleSetField}
            />
          ) : (
            ""
          )}
        </>
      )}
      <TransactionTypeWarningModal
        modalPreview={warningModalPreview}
        handleClose={handleClose}
        handleOkay={handleTransactionTypeWarning}
        subtext="After changing, the templates and questions provided for this transaction may no longer be appropriate."
        text="You can change the Transaction Type"
      />
    </>
  );
}

export default DetailFields;
