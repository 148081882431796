import React, { useState, useEffect } from "react";
import { Details, Questions } from "./partials";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useCustomDispatch } from "../../../helpers/useCustomDispatch";
import {
  checkTransactionExistenceRequest,
  createTransactionRequest,
  updateTransactionRequest,
} from "../../../redux/slicers/transactions";
import {
  getTransactionDetailRequest,
  removeTransactionDetailRequest,
} from "../../../redux/slicers/transactiondetail";
import { ALERT_TYPES, TRANSACTION_DETAIL_ROUTE } from "../../../constants";
import { replaceValInString, toastAlert } from "../../../services/utils";
import "./styles.scss";

function CreateTransaction() {
  // STATES
  const [currentStep, setcurrentStep] = useState(1);
  const [details, setDetails] = useState(null);
  const [selectedQuestion, setselectedQuestion] = useState(null);
  const [isTypeChanged, setIsTypeChanged] = useState(false);

  // REDUX DATA
  const { types } = useSelector((state) => state.transactions);
  const { data } = useSelector((state) => state.transactiondetail);

  // CUSTOM DISPATCH
  const [createTransaction, createLoading] = useCustomDispatch(
    createTransactionRequest
  );
  const [updateTransaction, updateLoading] = useCustomDispatch(
    updateTransactionRequest
  );
  const [getTransactionDetail, dataLoading] = useCustomDispatch(
    getTransactionDetailRequest
  );
  const [removeTransactionDetail] = useCustomDispatch(
    removeTransactionDetailRequest
  );
  const [checkTransactionExistence, checkLoader] = useCustomDispatch(
    checkTransactionExistenceRequest
  );

  // CONST VALS
  const { slug } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const isLoading = createLoading || updateLoading || checkLoader;

  // HELPERS
  const requestHelper = (request, payload, pathParams) => {
    request({
      payload,
      pathParams,
      logic({ data, message }) {
        toastAlert(message);
        const vals = { ":id": data.slug, ":room": data?.room?.slug };
        navigate(replaceValInString(TRANSACTION_DETAIL_ROUTE, vals));
      },
    });
  };

  //HANDLERS
  const handleBack = () => {
    navigate(-1);
  };
  const handlePrevious = () => {
    setcurrentStep(currentStep - 1);
  };

  const handleStep1 = (transactiondata, save) => {
    const queryParams = {
      transactionName: transactiondata.name,
      ...(slug && { slug: slug }),
      ...(transactiondata.email && { email: transactiondata.email }),
      ...(transactiondata.company && { company: transactiondata.company }),
    };
    setDetails(transactiondata);
    if (save) {
      checkTransactionExistence({
        queryParams,
        logic(res) {
          if (res.status) {
            handleCreate(
              isTypeChanged ? [] : data?.answers,
              false,
              transactiondata
            );
          }
        },
      });
      return;
    }
    const question =
      types.find((x) => x.id == transactiondata.transactionTypeId)?.Question ??
      types.find((x) => x.title == transactiondata.transactionTypeId)?.Question;
    setselectedQuestion(question?.id);
    checkTransactionExistence({
      queryParams,
      logic(res) {
        if (res.status) {
          if (question) {
            setcurrentStep(2);
            return;
          }
          handleCreate([], false, transactiondata);
        } else {
          toastAlert(res.message, ALERT_TYPES.ERROR);
        }
      },
    });
  };

  const handleCreate = (answers, isSkip = false, basicdetail) => {
    const payload = basicdetail || details || { ...data };
    if (answers !== undefined) {
      payload.answers = answers;
    }
    payload.isSkip = isSkip;

    if (slug) {
      requestHelper(updateTransaction, payload, slug);
      return;
    }
    requestHelper(createTransaction, payload);
  };

  // HOOKS
  useEffect(() => {
    slug &&
      getTransactionDetail({
        pathParams: slug,
      });
  }, [slug]);

  useEffect(() => {
    if (state?.isQuestionEdit) setcurrentStep(2);
  }, [state]);

  useEffect(() => {
    return () => {
      removeTransactionDetail();
    };
  }, []);

  return (
    <section className="create-transaction-wrapper">
      <div
        className="progress-bar"
        style={{
          width: `${currentStep * 50}%`,
        }}
      />
      <Details
        transactiondata={data}
        isLoading={isLoading}
        dataLoading={dataLoading}
        isEdit={slug}
        handleBack={handleBack}
        handleFormFinish={handleStep1}
        style={{ display: `${currentStep === 1 ? "block" : "none"}` }}
        setIsTypeChange={setIsTypeChanged}
      />
      <Questions
        data={data}
        isEdit={slug}
        isLoading={isLoading}
        selectedQuestion={selectedQuestion}
        handleFormFinish={handleCreate}
        handlePrevious={handlePrevious}
        style={{ display: `${currentStep === 2 ? "block" : "none"}` }}
        isTypeChanged={isTypeChanged}
        isEditedPromptVisible={currentStep === 2}
        previousDisabled={state?.isQuestionEdit}
      />
    </section>
  );
}

export default CreateTransaction;
