import React from "react";
import "./styles.scss";

export default function index({ className, style }) {
  return (
    <div
      style={style}
      className={`horizontal-line ${className ? className : ""}`}></div>
  );
}
