// @flow
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companiesList: [],
  addresses: [],
  teamMembers: [],
  pendingRequests: [],
  companyMembers: [],
  companyProfile: {},
  companyAdmins: [],
};

const CompanyReducer = createSlice({
  name: "company",
  initialState,
  reducers: {
    // COMPANIES LIST
    getCompaniesListRequest() {},
    getCompaniesListSuccess(state, action) {
      state.companiesList = action.payload;
    },

    // ADDRESSES LIST
    getAddressesListRequest() {},
    getAddressesListSuccess(state, action) {
      state.addresses = action.payload;
    },

    // TEAM MEMBERS LIST
    getTeamMembersRequest() {},
    getTeamMembersSuccess(state, action) {
      let temp = [...state.teamMembers];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.teamMembers = action.payload.data;
        return;
      }
      state.teamMembers = temp;
    },

    // PENDING REQUESTS LIST
    getPendingMembersRequest() {},
    getPendingMembersSuccess(state, action) {
      state.pendingRequests = action.payload;
    },

    // ADD TEAM MEMBER
    addTeamMemberRequest() {},

    // UPDATE TEAM MEMBER
    updateTeamMemberRequest() {},

    // APPROVE TEAM MEMBER
    approveTeamMemberRequest() {},

    // REMOVE TEAM MEMBER
    removeTeamMemberRequest() {},

    // GET ENTERPRISE INVITATION DETAIL
    getEnterpriseInvitationDetail() {},

    // GET COMPANIES MEMBERS
    getCompanyMembersRequest() {},
    getCompanyMembersSuccess(state, action) {
      state.companyMembers = action.payload;
    },

    // GET LOGGED IN USER'S COMPANY
    getLoggedInUserCompanyRequest() {},
    getLoggedInUserCompanySuccess(state, action) {
      state.companyProfile = action.payload;
    },

    // GET COMPANY ADMIN LIST
    getCompanyAdminsListRequest() {},
    getCompanyAdminsListSuccess(state, action) {
      state.companyAdmins = action.payload;
    },

    // CHECK LAST ENTERPRISE ADMIN
    checkLastEnterpriseAdminRequest() {},

    //REMOVE SELF AS ADMIN
    removeSelfAdminRequest() {},
  },
});

export const {
  getCompaniesListRequest,
  getCompaniesListSuccess,
  getAddressesListRequest,
  getAddressesListSuccess,
  getTeamMembersRequest,
  getTeamMembersSuccess,
  getPendingMembersRequest,
  getPendingMembersSuccess,
  addTeamMemberRequest,
  updateTeamMemberRequest,
  approveTeamMemberRequest,
  removeTeamMemberRequest,
  getEnterpriseInvitationDetail,
  getCompanyMembersRequest,
  getCompanyMembersSuccess,
  getLoggedInUserCompanyRequest,
  getLoggedInUserCompanySuccess,
  getCompanyAdminsListRequest,
  getCompanyAdminsListSuccess,
  checkLastEnterpriseAdminRequest,
  removeSelfAdminRequest,
} = CompanyReducer.actions;

export default CompanyReducer.reducer;
