import {
  call,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  callRequest,
  GET_DATAROOMS,
  GET_DATAROOM_DETAIL,
  CREATE_DATAROOM,
  UPDATE_DATAROOM,
  DELETE_DATAROOM,
  CREATE_DATAROOM_NOTE,
  GET_DATAROOM_NOTES,
  UPDATE_DATAROOM_NOTE,
  DELETE_DATAROOM_NOTE,
  GET_FOLDERS,
  GET_FOLDER_DETAIL,
  CREATE_FOLDER,
  UPDATE_FOLDER,
  DELETE_FOLDER,
  GET_DOCUMENTS,
  CREATE_DOCUMENT,
  UPDATE_DOCUMENT,
  DELETE_DOCUMENT,
  GET_LINKS,
  CREATE_LINK,
  UPDATE_LINK,
  DELETE_LINK,
  GET_NOTES,
  CREATE_NOTE,
  UPDATE_NOTE,
  DELETE_NOTE,
  GET_ALL_DATAROOMS_FOLDERS,
  MOVE_FOLDER,
  MOVE_DOCUMENT,
  GET_VISIBILITIES,
  GET_REMAINING_VISIBILITIES,
  ADD_VISIBILITIES,
  REMOVE_VISIBILITY,
  REMOVE_FOLDER_VISIBILITY,
  GET_REMAINING_FOLDER_VISIBILITIES,
  ADD_FOLDER_VISIBILITIES,
  MAKE_DATAROOM_ADMIN,
  CHECK_LAST_DATAROOM_ADMIN,
  LEAVE_DATAROOM,
  REMOVE_DATAROOM_ADMIN,
  GET_DATAROOM_PERMISSIONS,
  GET_USER_PERMISSIONS,
  UPDATE_DATAROOM_PERMISSION,
} from "../../config/webService";
import { ALERT_TYPES } from "../../constants";
import {
  manipulateDataroomData,
  manipulateDataroomPermissionList,
  manipulateDataroomsList,
  manipulateDocumentData,
  manipulateDocumentList,
  manipulateFolderData,
  manipulateFoldersList,
  manipulateLinkData,
  manipulateLinksList,
  manipulateNoteData,
  manipulateNotesList,
  manipulateUserPermissionsList,
  manipulateVisibilityList,
} from "../../dataManipulator/transactiondataroom";
import { toastAlert } from "../../services/utils";
import {
  getDataRoomsRequest,
  getDataRoomsSuccess,
  getDataRoomDetailRequest,
  getDataRoomDetailSuccess,
  createDataRoomRequest,
  updateDataRoomRequest,
  updateDataRoomSuccess,
  deleteDataRoomRequest,
  deleteDataRoomSuccess,
  getFoldersRequest,
  getFoldersSuccess,
  getFolderDetailRequest,
  getFolderDetailSuccess,
  createFolderRequest,
  updateFolderRequest,
  updateFolderSuccess,
  deleteFolderRequest,
  deleteFolderSuccess,
  getDocumentsRequest,
  getDocumentsSuccess,
  createDocumentRequest,
  updateDocumentRequest,
  updateDocumentSuccess,
  deleteDocumentRequest,
  deleteDocumentSuccess,
  createLinkRequest,
  createNoteRequest,
  deleteLinkRequest,
  deleteLinkSuccess,
  deleteNoteRequest,
  deleteNoteSuccess,
  getLinksRequest,
  getLinksSuccess,
  getNotesRequest,
  getNotesSuccess,
  updateLinkRequest,
  updateLinkSuccess,
  updateNoteRequest,
  updateNoteSuccess,
  getDataRoomsFoldersRequest,
  getDataRoomsFoldersSuccess,
  moveFolderRequest,
  moveDocumentRequest,
  getRoomVisibilitiesRequest,
  getRoomVisibilitiesSuccess,
  getRemainingRoomVisibilitiesRequest,
  getRemainingRoomVisibilitiesSuccess,
  addRoomVisibilityRequest,
  addRoomVisibilitySuccess,
  removeRoomVisibilitySuccess,
  removeRoomVisibilityRequest,
  removeFolderVisibilityRequest,
  removeFolderVisibilitySuccess,
  getRemainingFolderVisibilitiesRequest,
  getRemainingFolderVisibilitiesSuccess,
  addFolderVisibilityRequest,
  addFolderVisibilitySuccess,
  makeRoomAdminRequest,
  makeRoomAdminSuccess,
  checkLastAdminRequest,
  leaveDataRoomRequest,
  removeDataroomAdminRequest,
  removeDataroomAdminSuccess,
  getDataroomPermissionsRequest,
  getDataroomPermissionsSuccess,
  getDataroomUsersRequest,
  getDataroomUsersSuccess,
  updateDataroomPermissionRequest,
} from "../slicers/transactiondataroom";

function* getDataRooms(action) {
  const {
    payload: { pathParams, queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_DATAROOMS,
      pathParams,
      queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getDataRoomsSuccess({
          isPaginated: isMoreData,
          data: manipulateDataroomsList(response.data),
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* getDataRoomDetail() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getDataRoomDetailRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_DATAROOM_DETAIL,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        yield put(
          getDataRoomDetailSuccess(manipulateDataroomData(response.data))
        );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* createDataRoom() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(createDataRoomRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CREATE_DATAROOM,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updateDataRoom() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(updateDataRoomRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPDATE_DATAROOM,
        pathParams,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked)
          yield put(
            updateDataRoomSuccess(manipulateDataroomData(response.data))
          );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* deleteDataRoom() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(deleteDataRoomRequest.type);

    try {
      const response = yield call(callRequest, {
        url: DELETE_DATAROOM,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked)
          yield put(deleteDataRoomSuccess(response.data.id));
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getDataroomsFolders() {
  while (true) {
    const {
      payload: { pathParams, queryParams, responseCallback },
    } = yield take(getDataRoomsFoldersRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_ALL_DATAROOMS_FOLDERS,
        pathParams,
        queryParams,
      });
      if (response.status) {
        yield put(
          getDataRoomsFoldersSuccess(manipulateFoldersList(response.data))
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getFolders(action) {
  const {
    payload: { queryParams, pathParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_FOLDERS,
      pathParams,
      queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getFoldersSuccess({
          isPaginated: isMoreData,
          data: manipulateFoldersList(response.data),
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* getFolderDetail() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getFolderDetailRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_FOLDER_DETAIL,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        yield put(getFolderDetailSuccess(manipulateFolderData(response.data)));
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* createFolder() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(createFolderRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CREATE_FOLDER,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updateFolder() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback, isDetail },
    } = yield take(updateFolderRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPDATE_FOLDER,
        pathParams,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked)
          yield put(
            updateFolderSuccess(manipulateFolderData(response.data), isDetail)
          );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* moveFolder() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(moveFolderRequest.type);

    try {
      const response = yield call(callRequest, {
        url: MOVE_FOLDER,
        pathParams,
        data: payload,
      });
      if (response.status) {
        // yield put(updateFolderSuccess(manipulateFolderData(response.data)));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* deleteFolder() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(deleteFolderRequest.type);

    try {
      const response = yield call(callRequest, {
        url: DELETE_FOLDER,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked) yield put(deleteFolderSuccess(response.data.id));
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getDocuments(action) {
  const {
    payload: { pathParams, queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_DOCUMENTS,
      pathParams,
      queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getDocumentsSuccess({
          isPaginated: isMoreData,
          data: manipulateDocumentList(response.data),
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* createDocument() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(createDocumentRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CREATE_DOCUMENT,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updateDocument() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(updateDocumentRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPDATE_DOCUMENT,
        pathParams,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked)
          yield put(
            updateDocumentSuccess(manipulateDocumentData(response.data))
          );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* moveDocument() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(moveDocumentRequest.type);

    try {
      const response = yield call(callRequest, {
        url: MOVE_DOCUMENT,
        pathParams,
        data: payload,
      });
      if (response.status) {
        // yield put(updateDocumentSuccess(manipulateDocumentData(response.data)));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* deleteDocument() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(deleteDocumentRequest.type);

    try {
      const response = yield call(callRequest, {
        url: DELETE_DOCUMENT,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked)
          yield put(deleteDocumentSuccess(response.data.id));
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getLinks(action) {
  const {
    payload: { queryParams, pathParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_LINKS,
      pathParams,
      queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getLinksSuccess({
          isPaginated: isMoreData,
          data: manipulateLinksList(response.data),
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* createLink() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(createLinkRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CREATE_LINK,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updateLink() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(updateLinkRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPDATE_LINK,
        pathParams,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked)
          yield put(updateLinkSuccess(manipulateLinkData(response.data)));
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* deleteLink() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(deleteLinkRequest.type);

    try {
      const response = yield call(callRequest, {
        url: DELETE_LINK,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked) yield put(deleteLinkSuccess(response.data.id));
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getNotes(action) {
  const {
    payload: { queryParams, pathParams, isDataRoom, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: isDataRoom ? GET_DATAROOM_NOTES : GET_NOTES,
      pathParams,
      queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getNotesSuccess({
          isPaginated: isMoreData,
          data: manipulateNotesList(response.data),
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* createNote() {
  while (true) {
    const {
      payload: { payload, isDataRoom, responseCallback },
    } = yield take(createNoteRequest.type);

    try {
      const response = yield call(callRequest, {
        url: isDataRoom ? CREATE_DATAROOM_NOTE : CREATE_NOTE,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updateNote() {
  while (true) {
    const {
      payload: { payload, pathParams, isDataRoom, responseCallback },
    } = yield take(updateNoteRequest.type);

    try {
      const response = yield call(callRequest, {
        url: isDataRoom ? UPDATE_DATAROOM_NOTE : UPDATE_NOTE,
        pathParams,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked)
          yield put(updateNoteSuccess(manipulateNoteData(response.data)));
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* deleteNote() {
  while (true) {
    const {
      payload: { pathParams, isDataRoom, responseCallback },
    } = yield take(deleteNoteRequest.type);

    try {
      const response = yield call(callRequest, {
        url: isDataRoom ? DELETE_DATAROOM_NOTE : DELETE_NOTE,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked) yield put(deleteNoteSuccess(response.data.id));
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getVisibilities() {
  while (true) {
    const {
      payload: { queryParams, pathParams, responseCallback },
    } = yield take(getRoomVisibilitiesRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_VISIBILITIES,
        pathParams,
        queryParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        yield put(
          getRoomVisibilitiesSuccess(manipulateVisibilityList(response.data))
        );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* addVisibility() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(addRoomVisibilityRequest.type);

    try {
      const response = yield call(callRequest, {
        url: ADD_VISIBILITIES,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked)
          yield put(
            addRoomVisibilitySuccess(manipulateDataroomData(response.data))
          );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getRemainingVisibilities() {
  while (true) {
    const {
      payload: { queryParams, pathParams, responseCallback },
    } = yield take(getRemainingRoomVisibilitiesRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_REMAINING_VISIBILITIES,
        pathParams,
        queryParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        yield put(
          getRemainingRoomVisibilitiesSuccess(
            manipulateVisibilityList(response.data)
          )
        );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* removeVisiblity() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(removeRoomVisibilityRequest.type);

    try {
      const response = yield call(callRequest, {
        url: REMOVE_VISIBILITY,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked)
          yield put(
            removeRoomVisibilitySuccess(manipulateDataroomData(response.data))
          );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* addFolderVisibility() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(addFolderVisibilityRequest.type);

    try {
      const response = yield call(callRequest, {
        url: ADD_FOLDER_VISIBILITIES,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked)
          yield put(
            addFolderVisibilitySuccess(manipulateFolderData(response.data))
          );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getRemainingFolderVisibilities() {
  while (true) {
    const {
      payload: { queryParams, pathParams, responseCallback },
    } = yield take(getRemainingFolderVisibilitiesRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_REMAINING_FOLDER_VISIBILITIES,
        pathParams,
        queryParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        yield put(
          getRemainingFolderVisibilitiesSuccess(
            manipulateVisibilityList(response.data)
          )
        );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* removeFolderVisiblity() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(removeFolderVisibilityRequest.type);

    try {
      const response = yield call(callRequest, {
        url: REMOVE_FOLDER_VISIBILITY,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked)
          yield put(
            removeFolderVisibilitySuccess(manipulateFolderData(response.data))
          );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* makeAdmin() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(makeRoomAdminRequest.type);

    try {
      const response = yield call(callRequest, {
        url: MAKE_DATAROOM_ADMIN,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked)
          yield put(
            makeRoomAdminSuccess(manipulateDataroomData(response.data))
          );
        toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* checkLastAdmin() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(checkLastAdminRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CHECK_LAST_DATAROOM_ADMIN,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* leaveDataRoom() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(leaveDataRoomRequest.type);

    try {
      const response = yield call(callRequest, {
        url: LEAVE_DATAROOM,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* removeDataroomAdmin() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(removeDataroomAdminRequest.type);

    try {
      const response = yield call(callRequest, {
        url: REMOVE_DATAROOM_ADMIN,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked)
          yield put(
            removeDataroomAdminSuccess(manipulateDataroomData(response.data))
          );
        toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

//GET PERMISSIONS
function* getDataroomPermissionsList() {
  while (true) {
    const {
      payload: { responseCallback },
    } = yield take(getDataroomPermissionsRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_DATAROOM_PERMISSIONS,
      });
      if (response.statue) {
        responseCallback?.(true, response);
        yield put(
          getDataroomPermissionsSuccess(
            manipulateDataroomPermissionList(response.data)
          )
        );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

//UPDATE USER PERMISSION
function* updatePermission(action) {
  const {
    payload: { payload, pathParams, responseCallback },
  } = action;
  try {
    const response = yield call(callRequest, {
      url: UPDATE_DATAROOM_PERMISSION,
      data: payload,
      pathParams,
    });
    if (response.status) {
      responseCallback?.(true, response);
      toastAlert(response?.message, ALERT_TYPES.SUCCESS);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

//GET USERS WITH PERMISSIONS
function* getDataroomUserPermissions(action) {
  const {
    payload: { queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_USER_PERMISSIONS,
      queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getDataroomUsersSuccess({
          isPaginated: isMoreData,
          data: manipulateUserPermissionsList(response.data),
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

export default function* root() {
  yield takeLatest(getDataRoomsRequest.type, getDataRooms);
  yield fork(getDataRoomDetail);
  yield fork(createDataRoom);
  yield fork(updateDataRoom);
  yield fork(deleteDataRoom);
  yield fork(getDataroomsFolders);
  yield takeLatest(getFoldersRequest.type, getFolders);
  yield fork(getFolderDetail);
  yield fork(createFolder);
  yield fork(updateFolder);
  yield fork(moveFolder);
  yield fork(deleteFolder);
  yield takeLatest(getDocumentsRequest.type, getDocuments);
  yield fork(createDocument);
  yield fork(updateDocument);
  yield fork(moveDocument);
  yield fork(deleteDocument);
  yield takeLatest(getLinksRequest.type, getLinks);
  yield fork(createLink);
  yield fork(updateLink);
  yield fork(deleteLink);
  yield takeLatest(getNotesRequest.type, getNotes);
  yield fork(createNote);
  yield fork(updateNote);
  yield fork(deleteNote);
  yield fork(getVisibilities);
  yield fork(addVisibility);
  yield fork(getRemainingVisibilities);
  yield fork(removeVisiblity);
  yield fork(addFolderVisibility);
  yield fork(getRemainingFolderVisibilities);
  yield fork(removeFolderVisiblity);
  yield fork(makeAdmin);
  yield fork(checkLastAdmin);
  yield fork(leaveDataRoom);
  yield fork(removeDataroomAdmin);
  yield fork(getDataroomPermissionsList);
  yield takeLatest(getDataroomUsersRequest.type, getDataroomUserPermissions);
  yield takeEvery(updateDataroomPermissionRequest.type, updatePermission);
}
