import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "antd";
import { CardLoader, DeleteModal } from "../../../../../components";
import { ALERT_TYPES, DATA_PER_PAGE_LIMIT } from "../../../../../constants";
import {
  compareFilesByExtension,
  convertMentionToUsers,
  encryptMessage,
  getChatAccessToken,
  isValidLink,
  toastAlert,
} from "../../../../../services/utils";
import { useDispatch, useSelector } from "react-redux";
import { useCustomDispatch } from "../../../../../helpers/useCustomDispatch";
import {
  archiveRoomRequest,
  blockRoomRequest,
  checkLastGroupAdminRequest,
  getChatMessagesRequest,
  getTransactionChatsListRequest,
  leaveChatRequest,
  sendMessageRequest,
  setMessagesOffsetRequest,
  setThread,
  unarchiveRoomRequest,
  unblockRoomRequest,
} from "../../../../../redux/slicers/chat";
import {
  ChatBlocked,
  ChatInfo,
  ChatInput,
  ChatWrapper,
  Threads,
} from "./components";
import SocketIO from "../../../../../services/SocketIO";
import "./styles.scss";
import { Images } from "../../../../../themes";
import {
  ChatDetailModal,
  CreateGroupModal,
  GroupMembersModal,
} from "../../components";
import LeaveRoomModal from "../../components/LeaveRoomModal";
import { useParams } from "react-router-dom";

function ChatBox({
  selected,
  isLoading,
  handleDetail,
  isTransaction = false,
  roomBg,
}) {
  //STATES
  const [value, setValue] = useState("");
  const [emojiOpen, setEmojiOpen] = useState(false);
  const [isMoreData, setMoreData] = useState(false);
  const [allFiles, setAllFiles] = useState([]);
  // const [Chatoffset, setOffset] = useState(0);
  const [blockModalPreview, setBlockModalPreview] = useState(false);
  const [groupMemberModalPreview, setGroupMemberModalPreview] = useState(false);
  const [detailModalPreview, setDetailModalPreview] = useState(false);
  const [leaveRoomModalPreview, setLeaveRoomModalPreview] = useState(false);
  const [editRoomModalPreview, setEditRoomModalPreview] = useState(false);
  const [isLastAdmin, setIsLastAdmin] = useState(false);

  //REFS
  const _allFilesRef = useRef(allFiles);
  const inputRef = useRef(null);
  const scrollRef = useRef(null);
  const wrapperRef = useRef(null);

  //CUSTOM DISPATCH
  const [getMessages, messagesLoader] = useCustomDispatch(
    getChatMessagesRequest
  );
  const [sendMessage, sendLoader] = useCustomDispatch(sendMessageRequest);
  const [blockRoom, blockLoader] = useCustomDispatch(blockRoomRequest);
  const [unblockRoom, unblockLoader] = useCustomDispatch(unblockRoomRequest);
  const [archiveRoom, archiveLoader] = useCustomDispatch(archiveRoomRequest);
  const [leaveChat, leaveLoader] = useCustomDispatch(leaveChatRequest);
  const [unarchiveRoom, unarchiveLoader] =
    useCustomDispatch(unarchiveRoomRequest);
  const [checkAdmin, checkLoader] = useCustomDispatch(
    checkLastGroupAdminRequest
  );
  const [getTransChats] = useCustomDispatch(getTransactionChatsListRequest);

  //REDUX DATA
  const {
    selectedRoom,
    groupedMessages,
    offset,
    typing,
    threadId,
    typer,
    messages,
  } = useSelector((state) => state.chat);
  const { chat_id, name: username } = useSelector((state) => state.user.data);

  //CONST VALS
  const chat = selected;
  const dispatch = useDispatch();
  const { chat_id: chatParam } = useParams();
  const removeThreeDot =
    !selectedRoom?.isGroup &&
    getChatAccessToken() !== selectedRoom?.blockedBy &&
    selectedRoom?.isBlocked;
  const blockedTitle = selectedRoom?.isBlocked ? "Unblock User" : "Block User";
  const blockedDesc = `Are you sure you want to ${
    selectedRoom?.isBlocked ? "unblock" : "block"
  } this user from chat?`;
  const blockBtnTxt = selectedRoom?.isBlocked ? "Unblock" : "Block";
  const archiveBtnTxt = selectedRoom?.isArchived ? "Unarchive" : "Archive";
  const archiveTitle = selectedRoom?.isArchived
    ? "Unarchive Group"
    : "Archive Group";
  const archiveDesc = `Are you sure you want to ${
    selectedRoom?.isArchived ? "unarchive" : "archive"
  } this group chat?`;
  const chatStatusLoader =
    archiveLoader || unarchiveLoader || unblockLoader || blockLoader;
  // HELPERS
  const requestHelper = (request, payload) => {
    request({
      payload,
      logic() {
        setBlockModalPreview(false);
        setLeaveRoomModalPreview(false);
        setDetailModalPreview(false);
      },
    });
  };

  //HANDLERS
  const onEmojiClick = (e) => {
    inputRef.current.focus();
    const { selectionStart } = inputRef.current;
    const newVal =
      value.slice(0, selectionStart) + e.native + value.slice(selectionStart);
    setValue(newVal);
    const newCursor = selectionStart + e.native.length;
    setTimeout(
      () => inputRef.current.setSelectionRange(newCursor, newCursor),
      10
    );
  };

  const sendMessageHandler = (val) => {
    if (value?.trim() !== "" || allFiles?.length > 0) {
      const payload = new FormData();
      const mentionPayload = convertMentionToUsers(value.trim() ?? "");
      payload.append("message_text", encryptMessage(value.trim() ?? ""));
      payload.append("room_id", selectedRoom?.id);
      payload.append("senderID", chat_id);
      payload.append("receiverID", undefined);
      payload.append(
        "message_type",
        isValidLink(value.trim()) ? "url" : "text"
      );
      if (mentionPayload?.length > 0) {
        payload.append("mentioned_users", JSON.stringify(mentionPayload));
      }
      const files = [...allFiles];

      if (files?.length > 0) {
        files?.forEach((file) => {
          payload.append("files", file);
        });
      }

      sendMessage({
        payload: payload,
        logic() {
          setValue("");
          setEmojiOpen(false);
          setAllFiles([]);
          scrollRef.current?.scrollIntoView({ behavior: "smooth" });
          inputRef.current.focus();
          getTransChats();
        },
      });
    }
  };

  const handleEnter = (e) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13 && !e.shiftKey) {
      // Don't generate a new line
      e.preventDefault();
      //Send message on enter
      sendMessageHandler(e.target.value);
    }
  };

  const handleAddFiles = (files) => {
    let temp = [...allFiles, ...files];
    if (temp.length > 10) {
      toastAlert("Maximum 10 files are allowed.", ALERT_TYPES.ERROR);
    }
    setAllFiles(temp.splice(0, 10)?.sort(compareFilesByExtension));

    if (_allFilesRef?.current) {
      _allFilesRef.current = [...allFiles, ...files];
    }
    inputRef.current.focus();
  };

  const handleRemoveFiles = (index) => {
    let temp = [...allFiles];
    temp.splice(index, 1);
    setAllFiles(temp);
  };

  const handleNextMessages = () => {
    // setOffset(DATA_PER_PAGE_LIMIT + offset);
    setOffset(messages?.length);
    setMoreData(true);
  };

  const sendTypingEvent = (text) => {
    const payload = {
      senderID: chat_id,
      receiverID: undefined,
      roomID: selectedRoom?.id,
      typer: username,
    };

    SocketIO.typingEvent({
      ...payload,
      type: text,
    });
  };

  const onKeyUp = () => {
    setTimeout(() => {
      sendTypingEvent(false);
    }, 1000);
  };

  const onKeyDown = (e) => {
    const isSpecial = e.key.length > 1;
    if (!isSpecial) {
      sendTypingEvent(true);
    }
  };

  const handleThread = (id) => {
    dispatch(setThread(id));
  };

  const blockModalPreviewHandler = () => {
    setBlockModalPreview(!blockModalPreview);
  };

  const groupMemberModalPreviewHandler = () => {
    setGroupMemberModalPreview(!groupMemberModalPreview);
  };
  const detailModalPreviewHandler = () => {
    setDetailModalPreview(!detailModalPreview);
  };

  const handleBlockStatusChange = () => {
    const payload = {
      blocker_id: getChatAccessToken(),
      room_id: selectedRoom?.id,
    };
    requestHelper(selectedRoom?.isBlocked ? unblockRoom : blockRoom, payload);
  };

  const handleArchiveStatusChange = () => {
    const payload = {
      room_id: selectedRoom?.id,
    };
    requestHelper(
      selectedRoom?.isArchived ? unarchiveRoom : archiveRoom,
      payload
    );
  };

  const handleLeaveRoom = (newAdmin) => {
    const payload = {
      room_id: selectedRoom?.id,
      new_admin: newAdmin,
    };
    requestHelper(leaveChat, payload);
  };

  const leaveRoomModalPreviewHandler = () => {
    if (selectedRoom?.isAdminLoggedIn) {
      checkAdmin({
        queryParams: { room_id: selectedRoom?.id },
        logic(res) {
          setIsLastAdmin(res.status);
          setLeaveRoomModalPreview(!leaveRoomModalPreview);
        },
      });
      return;
    }
    setLeaveRoomModalPreview(!leaveRoomModalPreview);
  };

  const editRoomModalPreviewHandler = () => {
    setEditRoomModalPreview(!editRoomModalPreview);
  };

  const setOffset = (val) => {
    dispatch(setMessagesOffsetRequest(val));
  };
  //HOOKS
  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [selected]);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.style.height = "25px";
      inputRef.current.style.maxHeight = "52px";
      const scrollHeight = inputRef.current.scrollHeight;
      inputRef.current.style.height = scrollHeight + "px";
    }
  }, [inputRef, value]);

  useEffect(() => {
    // if (chatParam !== selectedRoom?.id && !isTransaction) {
    //   setOffset(0);
    // }
    if (selectedRoom?.id) {
      getMessages({
        queryParams: {
          limit: DATA_PER_PAGE_LIMIT,
          offset: offset,
          room_id: selectedRoom.id,
        },
        logic() {
          if (isMoreData) {
            const scrollAmount = 600; // Scroll down by 100 pixels
            wrapperRef.current.scrollTop += scrollAmount;
          }
        },
      });
    }
  }, [selectedRoom?.id, offset]);

  useEffect(() => {
    setMoreData(false);
    setOffset(0);
    setValue("");
  }, [selectedRoom]);

  return (
    <Col
      xxl={isTransaction ? 24 : 18}
      xs={isTransaction ? 24 : 16}
      className="chat-box"
    >
      <div className="chat-box-container">
        {selectedRoom?.id ? (
          <>
            {isLoading ? (
              <CardLoader height={"calc(100vh - 400px)"} size={15} />
            ) : (
              <>
                <ChatInfo
                  selectedRoom={selectedRoom}
                  chat={chat}
                  handleDetail={() =>
                    handleDetail ? handleDetail() : detailModalPreviewHandler()
                  }
                  isTransaction={isTransaction}
                  removeThreeDot={removeThreeDot}
                  blockModalPreviewHandler={blockModalPreviewHandler}
                  groupMemberModalPreviewHandler={
                    groupMemberModalPreviewHandler
                  }
                  leaveRoomModalPreviewHandler={leaveRoomModalPreviewHandler}
                  handleEdit={editRoomModalPreviewHandler}
                  roomBg={roomBg}
                />
                <Row>
                  <Col span={threadId ? 16 : 24} className="mess-wrapper">
                    <ChatWrapper
                      chat={chat}
                      groupedMessages={groupedMessages}
                      loader={messagesLoader}
                      isMoreData={isMoreData}
                      handleNext={handleNextMessages}
                      wrapperRef={wrapperRef}
                      scrollRef={scrollRef}
                      handleThread={handleThread}
                      isTransaction={isTransaction}
                    />
                    {!selectedRoom?.isBlocked &&
                      !selectedRoom?.isArchived &&
                      (selectedRoom?.isGroup
                        ? true
                        : selectedRoom?.individual?.isActive) &&
                      selectedRoom?.id &&
                      !isLoading && (
                        <ChatInput
                          allFiles={allFiles}
                          emojiOpen={emojiOpen}
                          handleAddFiles={handleAddFiles}
                          handleEnter={handleEnter}
                          inputRef={inputRef}
                          handleRemoveFiles={handleRemoveFiles}
                          sendLoader={sendLoader}
                          onEmojiClick={onEmojiClick}
                          setEmojiOpen={setEmojiOpen}
                          setValue={setValue}
                          value={value}
                          sendMessageHandler={sendMessageHandler}
                          onKeyUp={onKeyUp}
                          onKeyDown={onKeyDown}
                          isTyping={typing}
                          selectedRoom={selectedRoom}
                          typer={typer}
                        />
                      )}
                  </Col>
                  {threadId && selectedRoom && (
                    <Col span={8}>
                      <Threads
                        handleClose={handleThread}
                        chat={chat}
                        room={selectedRoom}
                      />
                    </Col>
                  )}
                </Row>
              </>
            )}
          </>
        ) : (
          <div style={{ height: "63vh" }} />
        )}
        {(selectedRoom?.isBlocked ||
          selectedRoom?.isArchived ||
          (selectedRoom?.isGroup
            ? false
            : !selectedRoom?.individual?.isActive)) &&
          selectedRoom?.id &&
          !isLoading && (
            <ChatBlocked
              isBlockedByOther={removeThreeDot}
              onUnblock={blockModalPreviewHandler}
              isGroup={selectedRoom?.isGroup}
              isAdmin={selectedRoom?.isAdminLoggedIn}
              selectedRoom={selectedRoom}
            />
          )}
      </div>
      <DeleteModal
        title={selectedRoom?.isGroup ? archiveTitle : blockedTitle}
        description={selectedRoom?.isGroup ? archiveDesc : blockedDesc}
        innerSec={<></>}
        btnText={selectedRoom?.isGroup ? archiveBtnTxt : blockBtnTxt}
        preview={blockModalPreview}
        handleClose={blockModalPreviewHandler}
        isLoading={chatStatusLoader}
        handleDelete={() => {
          selectedRoom?.isGroup
            ? handleArchiveStatusChange()
            : handleBlockStatusChange();
        }}
      />
      <GroupMembersModal
        preview={groupMemberModalPreview}
        handleClose={groupMemberModalPreviewHandler}
        isTransaction={isTransaction}
      />
      <ChatDetailModal
        preview={detailModalPreview}
        handleClose={detailModalPreviewHandler}
        chat={selectedRoom}
        isTransaction={isTransaction}
        blockTitle={blockedTitle}
        archiveTitle={archiveTitle}
        handleClick={blockModalPreviewHandler}
        handleEdit={editRoomModalPreviewHandler}
      />
      <LeaveRoomModal
        open={leaveRoomModalPreview}
        handleClose={() => setLeaveRoomModalPreview(false)}
        title={"Leave Chat"}
        description={"Are you sure you want to leave this chat?"}
        isAdmin={isLastAdmin}
        btnLoader={leaveLoader}
        onSubmit={handleLeaveRoom}
      />
      <CreateGroupModal
        preview={editRoomModalPreview}
        handleClose={editRoomModalPreviewHandler}
        isAddMember={false}
        isEdit={true}
        // handleSuccess={editRoomModalPreviewHandler}
      />
    </Col>
  );
}

export default ChatBox;
