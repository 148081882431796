import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "antd";
import React from "react";
import { Images } from "../../themes";
import ButtonComponent from "../ButtonComponent";
import "./styles.scss";

function RestrictQuestionModal({ modalPreview, handleClose, handleApprove }) {
  return (
    <Modal
      destroyOnClose
      visible={modalPreview}
      centered
      footer={null}
      width={490}
      focusTriggerAfterClose={true}
      className="restrict-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <Images.SkipQuestion />
          <h3>You Can Change The Answer</h3>
          <p>
            After changing, previously given answers may become irrelevant and
            you need to provide new answers based on the updated questions
          </p>
          <ButtonComponent
            text="Ok"
            className="skip-btn"
            onClick={handleApprove}
          />
        </div>
      </div>
    </Modal>
  );
}

export default RestrictQuestionModal;
