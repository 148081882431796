import React, { useState, useEffect } from "react";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Avatar, Row, Col } from "antd";
import { Images } from "../../../../../../../themes";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import {
  changeMileStoneStatusRequest,
  deleteMileStoneRequest,
  getMileStoneDetailRequest,
  removeResponsiblePartiesRequest,
  removeVisibilitiesRequest,
} from "../../../../../../../redux/slicers/transactionmilestone";
import {
  CreateMilestoneMemberModal,
  DeleteMilestoneMemberModal,
  DeleteMilestoneModal,
  UpdateMilestoneModal,
  AddMilestoneVisibilityModal,
  EditResponsibleModal,
} from "../";
import {
  ButtonComponent,
  StatusToggle,
  ActivityLogs,
  CardLoader,
  EmptyBox,
  HorizontalLine,
  VisibleToPeople,
  DisabledTooltip,
} from "../../../../../../../components";
import { useSelector } from "react-redux";
import _ from "lodash";
import { getLetterFromName } from "../../../../../../../services/utils";
import "./styles.scss";

const MilestonDetailView = ({
  handleClose,
  modalPreview,
  milestoneslug,
  handleDeleteResponse = () => {},
  isEditAllowed = true,
  isDeleteAllowed = true,
  activityLogsVisible,
}) => {
  // STATES
  const [isLoading, setLoading] = useState(true);
  const [updateModalPreview, setUpdateModalPreview] = useState(false);
  const [deleteModalPreview, setDeleteModalPreview] = useState(false);
  const [removeMemberModalPreview, setremoveMemberModalPreview] =
    useState(false);
  const [assignMemberModalPreview, setassignMemberModalPreview] =
    useState(false);
  const [addVisibilityModalPreview, setAddVisibilityModalPreview] =
    useState(false);
  const [
    editResponsiblePartiesModalPreview,
    setEditResponsiblePartiesModalPreview,
  ] = useState(false);
  const [selectedResponsibilityGroup, setSelectedResponsibilityGroup] =
    useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [isEditVisibility, setEditVisibility] = useState(false);

  // REDUX DATA
  const { data } = useSelector((state) => state.transactionmilestone);
  // CUSTOM DISPATCH
  const [getMileStoneDetail] = useCustomDispatch(getMileStoneDetailRequest);
  const [milestoneStatusChange, statusChangeLoader] = useCustomDispatch(
    changeMileStoneStatusRequest
  );
  const [deleteMilestone, deleteMileStoneLoader] = useCustomDispatch(
    deleteMileStoneRequest
  );
  const [removeResponsibleParties, removePartiesLoader] = useCustomDispatch(
    removeResponsiblePartiesRequest
  );
  const [removeVisibilities, removeVisibilitiesLoader] = useCustomDispatch(
    removeVisibilitiesRequest
  );

  //HELPERS
  const getMilestoneDetailHelper = () => {
    getMileStoneDetail({
      pathParams: milestoneslug,
      callback() {
        setLoading(false);
      },
    });
  };

  // HOOKS
  useEffect(() => {
    if (modalPreview && milestoneslug) {
      getMilestoneDetailHelper();
    }
  }, [modalPreview, milestoneslug]);
  useEffect(() => {
    return () => {
      setLoading(true);
    };
  }, [modalPreview]);

  //HANDLERS
  const milestoneStatusChangeHandler = (slug) => {
    milestoneStatusChange({ pathParams: slug });
  };

  const deleteMilstoneHandler = (id) => {
    deleteMilestone({
      pathParams: id,
      logic() {
        handleDeleteResponse();
        setDeleteModalPreview(false);
        handleClose();
      },
    });
  };

  const handleEditResponsibleParties = (data) => {
    if (!isEditVisibility) {
      removeResponsibleParties({
        payload: { ids: data },
        pathParams: milestoneslug,
        logic() {
          getMilestoneDetailHelper();
          setEditResponsiblePartiesModalPreview(false);
          setremoveMemberModalPreview(false);
        },
      });
      return;
    }
    removeVisibilities({
      payload: { ids: data },
      pathParams: milestoneslug,
      logic() {
        setEditVisibility(false);
        getMilestoneDetailHelper();
        setEditResponsiblePartiesModalPreview(false);
        setremoveMemberModalPreview(false);
      },
    });
  };

  const handleRemoveResponsibleEnterprise = () => {
    let temp = [];
    selectedGroup?.users?.map((item) => temp.push(item.id));
    handleEditResponsibleParties(temp);
  };

  const setEditPartiesModalPreviewHandler = (val) => {
    setSelectedResponsibilityGroup(val);
    setEditResponsiblePartiesModalPreview(true);
  };

  const setRemoveMemberModalPreviewHandler = (e, item) => {
    e.stopPropagation();
    setSelectedGroup(item);
    setremoveMemberModalPreview(true);
  };
  const setEditVisibilityModalPreviewHandler = (item) => {
    setEditVisibility(true);
    setSelectedResponsibilityGroup(item);
    setEditResponsiblePartiesModalPreview(true);
  };

  return (
    <Modal
      destroyOnClose
      visible={modalPreview}
      centered
      footer={null}
      width={1090}
      className="checklist-modal milestone-detail-modal"
    >
      <div className="close-btn" onClick={() => handleClose()}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      {isLoading ? (
        <CardLoader height={600} size={22} />
      ) : (
        <div className="milestone-detail-modal">
          {_.isEmpty(data) ? (
            <EmptyBox height={600} imgwidth={140} size={20} />
          ) : (
            <div className="card">
              <div className="milestones-details activity-log">
                <Images.Flag className="flag" />
                <div style={{ flex: 1 }}>
                  <p className="detail milestone-title">{data.name}</p>
                  <div className="start-end-date-container">
                    <p className="typeDatePera">
                      Start Date: <span>{data.startDate_date}</span>
                    </p>
                    {data?.endDate && (
                      <p className="typeDatePera">
                        End Date: <span>{data.endDate}</span>
                      </p>
                    )}
                  </div>
                </div>
                <DisabledTooltip
                  innerSec={
                    <StatusToggle
                      check={data.status}
                      handleStatusChange={() =>
                        milestoneStatusChangeHandler(data.slug)
                      }
                      confirmLoader={statusChangeLoader}
                      confirmDescription={
                        "Are you sure you want to change this milestone's status?"
                      }
                      isStatic={!isEditAllowed}
                    />
                  }
                  visible={!isEditAllowed}
                />
                {!data.status && (
                  <>
                    <DisabledTooltip
                      innerSec={
                        <Images.Edit
                          onClick={() =>
                            isEditAllowed && setUpdateModalPreview(true)
                          }
                          className="milestone-edit c-p"
                        />
                      }
                      visible={!isEditAllowed}
                    />
                    <DisabledTooltip
                      innerSec={
                        <Images.Delete
                          onClick={() =>
                            isDeleteAllowed && setDeleteModalPreview(true)
                          }
                          className="milestone-delete c-p"
                        />
                      }
                      visible={!isDeleteAllowed}
                    />
                  </>
                )}
              </div>
              <HorizontalLine />
              <div className="responsible-individuals">
                <h3>Responsible Parties</h3>
                {!data.status && (
                  <DisabledTooltip
                    innerSec={
                      <ButtonComponent
                        className="edit-profile-btn"
                        text={"Assign Parties"}
                        onClick={() => {
                          isEditAllowed && setassignMemberModalPreview(true);
                        }}
                      />
                    }
                    visible={!isEditAllowed}
                  />
                )}
              </div>
              <Row gutter={[10, 10]}>
                {data?.responsiblePartiesGroups?.length < 1 ? (
                  <EmptyBox
                    height={100}
                    imgwidth={50}
                    size={12}
                    text={"No one is assigned"}
                  />
                ) : (
                  data.responsiblePartiesGroups.map((item, index) => (
                    <Col xs={24} md={12} key={index}>
                      <div
                        className="responsible-parties-card"
                        onClick={(e) => {
                          setEditPartiesModalPreviewHandler(item.users);
                        }}
                      >
                        <VisibleToPeople
                          size={42}
                          data={item.users}
                          maxLength={3}
                          largeSize
                          detailModalHandler={() => {
                            setEditPartiesModalPreviewHandler(item.users);
                          }}
                        />
                        <div className="text-container">
                          <p className="name">{item.name}</p>
                          <p className="profession">
                            {item.users.length} Participant
                            {item.users.length > 1 ? "s" : ""}
                          </p>
                        </div>
                        {!data.status && (
                          <DisabledTooltip
                            innerSec={
                              <Images.Delete
                                className="c-p delete-icon"
                                onClick={(e) => {
                                  isEditAllowed &&
                                    setRemoveMemberModalPreviewHandler(e, item);
                                }}
                              />
                            }
                            visible={!isEditAllowed}
                          />
                        )}
                        {/* {!data.status &&
                        data.responsiblePartiesGroups?.length > 1 && (
                          <DisabledTooltip
                            innerSec={
                              <Images.Delete
                                className="c-p delete-icon"
                                onClick={(e) => {
                                  isEditAllowed &&
                                    setRemoveMemberModalPreviewHandler(e, item);
                                }}
                              />
                            }
                            visible={!isEditAllowed}
                          />
                        )} */}
                      </div>
                    </Col>
                  ))
                )}
              </Row>
              <HorizontalLine />
              <div className="visibility-rooms">
                <h3>Visibilty</h3>
                <div className="rooms">
                  <Avatar.Group maxCount={5}>
                    {data.visibilities.map((item, index) => (
                      <Avatar
                        style={{ backgroundColor: item.bgColor }}
                        onClick={() => {
                          setEditVisibilityModalPreviewHandler(
                            data.visibilities
                          );
                        }}
                        children={getLetterFromName(item.roomname)}
                        key={index}
                      />
                    ))}
                  </Avatar.Group>
                  {!data.status && (
                    <DisabledTooltip
                      innerSec={
                        <span
                          className="add-btn"
                          onClick={() =>
                            isEditAllowed && setAddVisibilityModalPreview(true)
                          }
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </span>
                      }
                      visible={!isEditAllowed}
                    />
                  )}
                </div>
              </div>
              <HorizontalLine />
              <div className="responsible-individuals">
                <h3>Note</h3>
              </div>
              <p className="note-para">{data.notes}</p>
            </div>
          )}
          <ActivityLogs
            title="Milestone"
            isMilestoneDetail
            isWholeParent
            height={75}
            customqueryParams={{ milestone: data.slug }}
            isAccess={activityLogsVisible}
          />
        </div>
      )}
      <UpdateMilestoneModal
        isUpdate
        data={data}
        handleClose={() => {
          setUpdateModalPreview(false);
        }}
        preview={updateModalPreview}
      />
      <DeleteMilestoneModal
        handleClose={() => {
          setDeleteModalPreview(false);
          handleEditResponsibleParties;
        }}
        preview={deleteModalPreview}
        handleDelete={deleteMilstoneHandler}
        loader={deleteMileStoneLoader}
        data={data}
      />
      <DeleteMilestoneMemberModal
        handleClose={() => {
          setremoveMemberModalPreview(false);
        }}
        preview={removeMemberModalPreview}
        selectedGroup={selectedGroup}
        handleDelete={handleRemoveResponsibleEnterprise}
        isLoading={removePartiesLoader}
      />
      <CreateMilestoneMemberModal
        handleClose={() => {
          setassignMemberModalPreview(false);
        }}
        preview={assignMemberModalPreview}
        slug={data.slug}
        visibilities={data}
        lastUpdated={data?.lastUpdated}
        onSubmit={getMilestoneDetailHelper}
      />
      <AddMilestoneVisibilityModal
        transactionslug={data.transactionslug}
        preview={addVisibilityModalPreview}
        handleClose={() => setAddVisibilityModalPreview(false)}
        slug={data.slug}
        onVisibilityChange={getMilestoneDetailHelper}
        lastUpdated={data?.lastUpdated}
      />
      <EditResponsibleModal
        preview={editResponsiblePartiesModalPreview}
        parties={selectedResponsibilityGroup}
        handleClose={() => {
          setEditResponsiblePartiesModalPreview(false);
          setEditVisibility(false);
        }}
        isEdit={!data.status && isEditAllowed}
        isRemoveAllVisible={
          !isEditVisibility &&
          // data.responsiblePartiesGroups?.length > 1 &&
          isEditAllowed
        }
        handleSave={handleEditResponsibleParties}
        isLoading={removePartiesLoader || removeVisibilitiesLoader}
        isEditVisibility={isEditVisibility}
      />
    </Modal>
  );
};

export default MilestonDetailView;
