import React from "react";
import { Images } from "../../../../../themes";
import "./styles.scss";

function FolderCard({ title, fileCount, onClick }) {
  return (
    <div className={`folder-card-wrapper c-p`} onClick={onClick}>
      <Images.FolderIcon />
      <div className="folder-info">
        <h3>{title}</h3>
        <p>Documents Folder</p>
      </div>

      <div className="attachment-wrapper">
        <Images.ResourcesLinkIcon /> <p>{fileCount} Attachments</p>
      </div>
    </div>
  );
}

export default FolderCard;
