import React from "react";
import { Modal } from "antd";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.scss";
import { Images } from "../../../../../../../themes";
import { ProfileImage } from "../../../../../../../components";

const DocNoAccessModal = ({ handleClose, preview, data }) => {
  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={522}
      className="doc-no-access-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>Can’t Access</h3>
            <p>Request access to admin to view the document</p>
          </div>
          <div className="doc-wrapper">
            <div className="doc-thumb">
              <Images.DocThumb />
            </div>
            <div className="doc-info">
              <h3>{data?.name}</h3>
              <div className="upload-text">
                {`Uploaded From ${data?.uploadedFrom} By`}
                <ProfileImage
                  color={data?.bgColor}
                  image={data?.creatorImage}
                  username={data?.creatorName}
                  size={6}
                />{" "}
                <span className="username">{data?.creatorName}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DocNoAccessModal;
