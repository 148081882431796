import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { getNotesRequest } from "../../../../../../../redux/slicers/transactiondataroom";
import { NoteCard } from "../";
import {
  EmptyBox,
  CustomInfiniteScroll,
  CardLoader,
  ButtonComponent,
  DisabledTooltip,
} from "../../../../../../../components";
import { DATA_PER_PAGE_LIMIT } from "../../../../../../../constants";
import AddNoteModal from "../addNoteModal";
import "./styles.scss";

export default function DataRoomNotesBox({
  slug,
  isDataUpdated,
  height,
  isDataRoom,
  isFolder,
  dataroomid,
  isOwner,
  maxHeight,
  isTransactionComplete,
  isEditAllowed = true,
}) {
  //STATES
  const [total, setTotal] = useState(null);
  const [offset, setOffset] = useState(0);
  const [isMoreData, setMoreData] = useState(false);
  const [isNoteCreated, setNoteCreated] = useState(false);
  const [addNoteModalPreview, setAddNoteModalPreview] = useState(false);

  // REDUX DATA
  const { notes } = useSelector((state) => state.transactiondataroom);

  // CUSTOM DISPATCH
  const [getNotes, notesLoading] = useCustomDispatch(getNotesRequest);

  //CONST VALSz
  const HEIGHT = height;
  const BOX_HEIGHT = `calc(100vh - ${HEIGHT}px)`;
  const isLoading = notesLoading && !isMoreData;

  // HELPERS
  const getNotesHelper = () => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset,
    };
    if (isDataRoom) {
      queryParams["isDataRoom"] = isFolder ? false : true;
    }
    getNotes({
      queryParams,
      pathParams: slug,
      otherkeys: { isDataRoom },
      logic({ total }) {
        setTotal(total);
      },
    });
  };

  // HOOKS
  useEffect(() => {
    getNotesHelper();
  }, [slug, offset, isDataUpdated, isNoteCreated]);

  // HANDLERS
  const noteCreatedHandler = () => {
    setNoteCreated(!isNoteCreated);
  };
  const handleNextData = () => {
    setOffset(offset + DATA_PER_PAGE_LIMIT);
    setMoreData(true);
  };

  return (
    <>
      <div className="dataroom-notes-wrapper">
        <h3>Notes</h3>
        {isLoading ? (
          <CardLoader height={BOX_HEIGHT} />
        ) : (
          <CustomInfiniteScroll
            dataLength={notes.length}
            total={total}
            next={handleNextData}
            maxHeight={maxHeight ? maxHeight : HEIGHT}
            minHeight={BOX_HEIGHT}
            isWholePage={false}
            // isPercent={maxHeight ? true : false}
          >
            {notes.length >= 1 ? (
              notes.map((item, index) => (
                <NoteCard
                  key={index}
                  data={item}
                  isDataRoom={isDataRoom}
                  onDelete={() => setTotal(total - 1)}
                  isOwner={isOwner}
                  isTransactionComplete={isTransactionComplete}
                  isEditAllowed={isEditAllowed}
                />
              ))
            ) : (
              <EmptyBox height={BOX_HEIGHT} />
            )}
          </CustomInfiniteScroll>
        )}
        {isDataRoom && !isTransactionComplete && (
          <>
            <DisabledTooltip
              innerSec={
                <ButtonComponent
                  text="Add Note"
                  className="add-note-btn"
                  onClick={() => isEditAllowed && setAddNoteModalPreview(true)}
                />
              }
              visible={!isEditAllowed}
            />
            <AddNoteModal
              isDataRoom={isDataRoom}
              roomid={dataroomid}
              isFolder={isFolder}
              preview={addNoteModalPreview}
              handleClose={() => setAddNoteModalPreview(false)}
              handleSuccess={noteCreatedHandler}
            />
          </>
        )}
      </div>
    </>
  );
}
