// @flow
import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  checklist: [],
  types: [],
  data: {},
  transChecklist: [],
  suggestions: [],
};

const ChecklistReducer = createSlice({
  name: "checklist",
  initialState,
  reducers: {
    //CREATE CHECKLIST
    createChecklistRequest() {},
    createChecklistSuccess(state, action) {},

    //UPDATE CHECKLIST
    updateChecklistRequest() {},
    updateChecklistSuccess(state, action) {
      state.data = action.payload;
    },

    //DELETE CHECKLIST
    deleteChecklistRequest() {},
    deleteChecklistSuccess(state, action) {
      state.data.isDeleted = !state.data.isDeleted;
    },

    //GET CHECKLIST LISTING
    getChecklistRequest() {},
    getChecklistSuccess(state, action) {
      let temp = [...state.checklist];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.checklist = action.payload.data;
        return;
      }
      state.checklist = temp;
    },

    //GET TRANSACTION CHECKLISTS
    getTransactionChecklistsRequest() {},
    getTransactionChecklistsSuccess(state, action) {
      state.transChecklist = action.payload;
    },

    //GET CHECKLIST DETAIL
    getChecklistDetailRequest() {},
    getChecklistDetailSuccess(state, action) {
      state.data = action.payload;
    },

    //GET CHECKLIST TYPES
    getChecklistTypesRequest() {},
    getChecklistTypesSuccess(state, action) {
      state.types = action.payload;
    },

    //ADD DOC TO CHECKLIST
    addDocToChecklistRequest() {},
    addDocToChecklistSuccess(state, action) {
      state.data = action.payload;
    },

    //REMOVE DOC FROM CHECKLIST
    removeChecklistDocRequest() {},
    removeChecklistDocSuccess(state, action) {
      state.data = action.payload;
    },

    //ADD VISIBILITY TO CHECKLIST
    addChecklistVisibilityRequest() {},
    addChecklistVisibilitySuccess(state, action) {
      state.data = action.payload;
    },

    //REMOVE VISIBILITY FROM CHECKLIST
    removeChecklistVisibilityRequest() {},
    removeChecklistVisibilitySuccess(state, action) {
      state.data = action.payload;
    },

    //ADD RESPONSIBILITY TO CHECKLIST
    addChecklistResponsibilityRequest() {},
    addChecklistResponsibilitySuccess(state, action) {
      state.data = action.payload;
    },

    //REMOVE VISIBILITY FROM CHECKLIST
    removeChecklistResponsibilityRequest() {},
    removeChecklistResponsibilitySuccess(state, action) {
      state.data = action.payload;
    },

    //CHANGE CHECKLIST STATUS
    changeChecklistStatusRequest() {},
    changeChecklistStatusSuccess(state, action) {
      if (action.payload.isList) {
        const index = state.checklist.indexOf(
          state.checklist.find((x) => x.id === action.payload.id)
        );
        state.checklist[index].status = !state.checklist[index].status;
        return;
      }
      state.data.status = !state.data.status;
    },

    //GET SUGGESTIONS
    getChecklistSuggestionsRequest() {},
    getChecklistSuggestionsSuccess(state, action) {
      state.suggestions = action.payload;
    },
  },
});

export const {
  getChecklistRequest,
  getChecklistSuccess,
  getChecklistDetailRequest,
  getChecklistDetailSuccess,
  getTransactionChecklistsRequest,
  getTransactionChecklistsSuccess,
  getChecklistTypesRequest,
  getChecklistTypesSuccess,
  createChecklistRequest,
  createChecklistSuccess,
  deleteChecklistRequest,
  deleteChecklistSuccess,
  updateChecklistRequest,
  updateChecklistSuccess,
  addDocToChecklistRequest,
  addDocToChecklistSuccess,
  removeChecklistDocRequest,
  removeChecklistDocSuccess,
  addChecklistResponsibilityRequest,
  addChecklistResponsibilitySuccess,
  addChecklistVisibilityRequest,
  addChecklistVisibilitySuccess,
  removeChecklistResponsibilityRequest,
  removeChecklistResponsibilitySuccess,
  removeChecklistVisibilityRequest,
  removeChecklistVisibilitySuccess,
  changeChecklistStatusRequest,
  changeChecklistStatusSuccess,
  getChecklistSuggestionsRequest,
  getChecklistSuggestionsSuccess,
} = ChecklistReducer.actions;

export default ChecklistReducer.reducer;
