import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Col, Modal, Row } from "antd";
import { ButtonComponent } from "../../../../../../../components";
import { Images } from "../../../../../../../themes";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import {
  deleteDocumentRequest,
  deleteExhibitRequest,
  deleteVersionRequest,
} from "../../../../../../../redux/slicers/transactiondocuments";
import "./styles.scss";
import { useParams } from "react-router-dom";

const DeleteDocumentModal = ({
  handleClose,
  handleSuccess,
  data,
  preview,
  isVersion,
  isExhibit,
}) => {
  // CUSTOM DISPATCH
  const [deleteDocument, docLoader] = useCustomDispatch(deleteDocumentRequest);
  const [deleteExhibit, exLoader] = useCustomDispatch(deleteExhibitRequest);
  const [deleteVersion, verLoader] = useCustomDispatch(deleteVersionRequest);

  // REDUX DATA
  const { data: transactiondetail, roomDetail } = useSelector(
    (state) => state.transactiondetail
  );

  // CONST VALS
  const { room } = useParams();
  const isDocument = !isExhibit && !isVersion;
  const isRoom = room !== transactiondetail?.roomSlug;
  const isLoading = docLoader || exLoader || verLoader;
  const roomdetail = isRoom ? roomDetail : transactiondetail?.room;

  // HELPERS
  const requestHelper = (request) => {
    const payload = {
      isDocument: data?.isDocument,
    };
    if (request === deleteDocument) {
      payload["roomSlug"] = room;
    }
    request({
      pathParams: data.slug,
      payload,
      logic() {
        handleClose();
        handleSuccess();
      },
    });
  };

  // HANDLERS
  const deleteDocumentHandler = () => {
    requestHelper(
      isExhibit ? deleteExhibit : isVersion ? deleteVersion : deleteDocument
    );
  };
  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={522}
      className="delete-document"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>
              Delete{" "}
              {isExhibit ? "Exhibit" : isVersion ? "Version" : "Document"}
            </h3>
            <p>
              Are You Sure To Delete{" "}
              {isExhibit ? "Exhibit" : isVersion ? "Version" : "Document"}
            </p>
          </div>
          <div className="document-detail">
            <div className="ttl">
              <span className="thumb">
                <Images.AuthDocument />
              </span>
              <div>
                <h4>{data?.name}</h4>
                <p className="room">
                  <span>Room: </span> {roomdetail?.name}
                </p>
              </div>
            </div>
            {isDocument && (
              <div className="config">
                <Row gutter={[10, 0]}>
                  <Col xs={24}>
                    <span className="note">{data?.notes}</span>
                  </Col>
                  <Col xs={24}>
                    <div className="attach-box">
                      <Images.AttachmentPin />
                      <p className="attached">
                        {(
                          (data?.exhibitCount > 0 ? "0" : "") +
                          data?.exhibitCount
                        ).slice(-2)}{" "}
                        Exhibit
                        {data?.exhibitCount > 1 ? "s" : ""}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <div className="cancel-btn" onClick={handleClose}>
            Cancel
          </div>
          <ButtonComponent
            text="Delete"
            isLoading={isLoading}
            className="delete-btn"
            onClick={deleteDocumentHandler}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteDocumentModal;
