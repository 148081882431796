import React, { useState, useEffect } from "react";
import { Form, Select } from "antd";
import { useCustomDispatch } from "../../../../../helpers/useCustomDispatch";
import { getTransactionQuestionRequest } from "../../../../../redux/slicers/transactions";
import {
  ButtonComponent,
  SkipQuestionModal,
  RestrictQuestionModal,
  CardLoader,
  EditQuestionsPrompt,
} from "../../../../../components";
import { Images } from "../../../../../themes";
import { inputFieldRule } from "../../../../../services/utils";

function Questions({
  isLoading,
  selectedQuestion,
  handleFormFinish,
  handlePrevious,
  style,
  data,
  isEdit,
  isTypeChanged,
  isEditedPromptVisible,
  previousDisabled,
}) {
  // CONSTANT VALS
  const [form] = Form.useForm();

  // STATES
  const [skipModalPreview, setSkipModalPreview] = useState(false);
  const [restrictModalPreview, setrestrictModalPreview] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [preventedQuestions, setpreventedQuestions] = useState([]);
  const [selectedQuestionId, setselectedQuestionId] = useState(null);
  const [editedPromptPreview, setEditedPromptPreview] = useState(false);
  const [isEditedPrompPreviewed, setIsEditedPrompPreviewed] = useState(false);
  const [skipDisabled, setSkipDisabled] = useState(false);

  // CUSTOM DISPATCH
  const [getQuestion, isQuestionLoading] = useCustomDispatch(
    getTransactionQuestionRequest
  );

  // HELPERS
  const getQuestionDetailHandler = (quesid, allquestions) => {
    let questionsdata = allquestions || questions;
    if (!questionsdata.some((x) => x.id === quesid)) {
      getQuestion({
        pathParams: quesid,
        logic({ data }) {
          setQuestions((questions) => [...questions, data]);
        },
      });
    }
  };
  const answerManipulator = (data) => {
    const keys = Object.keys(data);
    const manipulatedAnswers = [];
    for (let key of keys) {
      const id = parseInt(key?.split("_")?.[1]); // quest ID
      const answerId = data[key]; // answer Id
      manipulatedAnswers.push({ id, answerId });
    }

    return manipulatedAnswers;
  };

  // HOOKS
  useEffect(() => {
    const questionsCondition =
      isEdit && !isTypeChanged && data?.questions?.length > 0;
    setQuestions([]);
    form.resetFields();
    if (questionsCondition) {
      setQuestions(data.questions);
      data?.answers?.map((item) => {
        form.setFieldValue(`question_${item.id}`, item.answerId);
      });
      setSkipDisabled(data?.questions?.length > 1);
    } else {
      setSkipDisabled(false);
      if (selectedQuestion) {
        getQuestionDetailHandler(selectedQuestion, []);
      }
    }
  }, [selectedQuestion, data]);
  useEffect(() => {
    if (data.isEdited && isEditedPromptVisible && !isEditedPrompPreviewed) {
      setEditedPromptPreview(true);
      setIsEditedPrompPreviewed(true);
    }
  }, [isEditedPromptVisible, data.isEdited]);

  // HANDLERS
  const handleNextQuestion = (answerid, questionid) => {
    // REMOVE PREVENTATION
    const updatedPreventedQuestions = [...preventedQuestions].filter(
      (x) => x !== questionid
    );
    setpreventedQuestions(updatedPreventedQuestions);

    // REMOVE FURTHER QUESTIONS
    const findQuestionIndex = [...questions].findIndex(
      (q) => q?.id == questionid
    );
    const newQuestions = [...questions].slice(0, findQuestionIndex + 1);
    setQuestions([...newQuestions]);
    // FIND QUESTION
    const question = questions.find((x) => x.id == questionid)?.answer;
    const nextQuestion = question?.find(
      (x) => x.id == answerid
    )?.nextQuestionId;
    nextQuestion && getQuestionDetailHandler(nextQuestion, newQuestions);
    //DISABLING SKIP ON QUESTIONS END

    setSkipDisabled(nextQuestion ? false : true);
  };

  const handleSkip = () => {
    const val = form.getFieldsValue();
    const result = [...answerManipulator(val)].filter(
      (x) => x.answerId !== undefined
    );
    handleFormFinish(result, true);
    setSkipModalPreview(false);
  };

  const handleShowRestriction = (question) => {
    setselectedQuestionId(question);
    setrestrictModalPreview(true);
  };

  const handleCloseRestriction = () => {
    setselectedQuestionId(null);
    setrestrictModalPreview(false);
  };

  const handleApproveRestriction = () => {
    setselectedQuestionId(null);
    setrestrictModalPreview(false);
    setpreventedQuestions((preventedQuestions) => [
      ...preventedQuestions,
      selectedQuestionId,
    ]);
  };

  const handleSubmit = (values) => {
    const result = answerManipulator(values);
    handleFormFinish(result);
  };

  return (
    <span style={style}>
      <div className="top-heading-wrapper">
        <div className="top-heading">
          <h3>General Questions</h3>
        </div>
        <div className="questions-top-options">
          <div
            className="back-btn-wrapper"
            onClick={() => !skipDisabled && setSkipModalPreview(true)}
            style={{ cursor: `${!skipDisabled ? "pointer" : "not-allowed"}` }}
          >
            <p>Skip</p>
            <Images.BackArrow
              style={{ height: "15px", transform: "rotate(-180deg)" }}
            />{" "}
          </div>
          <span>
            {!previousDisabled && (
              <div
                className="back-btn-wrapper previous"
                onClick={handlePrevious}
              >
                <Images.BackArrow style={{ height: "15px" }} />{" "}
                <p>Previous Step</p>
              </div>
            )}
            <ButtonComponent
              isLoading={isLoading}
              text="Submit"
              className="continue-btn"
              onClick={() => form.submit()}
            />
          </span>
        </div>
      </div>

      <Form
        form={form}
        layout="vertical"
        className="create-form question-form"
        onFinish={handleSubmit}
      >
        {questions?.map((item, index) => {
          const isDisabled =
            index < questions.length - 2 &&
            !preventedQuestions.includes(item.id);
          return (
            <div className="question-box" key={index}>
              {isDisabled && (
                <span
                  className="popover"
                  onClick={() => {
                    handleShowRestriction(item.id);
                  }}
                />
              )}
              <Form.Item
                label={item.question}
                className="create-form-item"
                name={`question_${item.id}`}
                rules={inputFieldRule({ name: "Answer", isWhiteSpace: false })}
                preserve={false}
              >
                <Select
                  className="create-form-select"
                  placeholder="Select Answer"
                  // open={preventedQuestions.includes(item.id) ? true : undefined}
                  onChange={(val) => {
                    handleNextQuestion(val, item.id);
                  }}
                  getPopupContainer={(trigger) => trigger.parentElement}
                >
                  {item?.answer?.map((a, i) => (
                    <Select.Option value={a.id} key={i}>
                      {a.answer}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          );
        })}
      </Form>
      {isQuestionLoading && (
        <CardLoader height={questions?.length >= 1 ? 50 : 200} />
      )}
      <SkipQuestionModal
        modalPreview={skipModalPreview}
        handleClose={() => setSkipModalPreview(false)}
        handleSkip={handleSkip}
      />
      <RestrictQuestionModal
        modalPreview={restrictModalPreview}
        handleClose={handleCloseRestriction}
        handleApprove={handleApproveRestriction}
      />
      <EditQuestionsPrompt
        modalPreview={editedPromptPreview}
        handleClose={() => setEditedPromptPreview(false)}
        handleSave={handleSkip}
      />
    </span>
  );
}

export default Questions;
