import React, { useEffect, useMemo, useState } from "react";
import { Row } from "antd";
import { ButtonComponent } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { ChatBox, ChatList } from "./partials";
import {
  ChatDetailModal,
  CreateGroupModal,
  DirectMessageModal,
} from "./components";
import { useCustomDispatch } from "../../../helpers/useCustomDispatch";
import {
  getChatListRequest,
  getChatListSuccess,
  getChatUsersRequest,
  getThreadsSuccess,
  loadRoomRequest,
  loadRoomSuccess,
  setChatListOffset,
  setIsSelectFirst,
  setThread,
} from "../../../redux/slicers/chat";
import "./styles.scss";
import { DATA_PER_PAGE_LIMIT, MESSAGES_ROUTE } from "../../../constants";
import { replaceValInString } from "../../../services/utils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { manipulateChatData } from "../../../dataManipulator/chat";

function Messages() {
  //CONST VALS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { chat_id: id } = useParams();
  const { pathname } = useLocation();
  const isGroupChat = pathname?.includes("/messages/group");

  //STATES
  const [selected, setSelected] = useState(null);
  const [directModalPreview, setDirectModalPreview] = useState(false);
  const [createGroupModalPreview, setCreateGroupModalPreview] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(null);
  const [isMoreData, setMoreData] = useState(false);
  const [newLoader, setNewLoader] = useState(false);
  const [tab, setTab] = useState(isGroupChat);

  //REDUX DATA
  const { chat_id, user_id } = useSelector((state) => state.user.data);
  const {
    usersList,
    chatList,
    singleChats,
    groupChats,
    chatListOffset: offset,
    selectedRoom,
  } = useSelector((state) => state.chat);
  //CUSTOM DISPATCH
  const [loadRoom, loadRoomLoader] = useCustomDispatch(loadRoomRequest);
  const [getChats, chatsLoader] = useCustomDispatch(getChatListRequest);

  //HANDLERS
  const directModalPreviewHandler = () => {
    setDirectModalPreview(!directModalPreview);
  };

  const setOffset = (val) => {
    dispatch(setChatListOffset(val));
  };

  const createGroupModalPreviewHandler = () => {
    setCreateGroupModalPreview(!createGroupModalPreview);
  };

  const handleInitChat = (id) => {
    loadRoom({
      payload: {
        room_type: "individual",
        receiverID: chat_id,
        senderID: id,
      },
      logic(resp) {
        setOffset(0);
        setMoreData(false);
        const queryParams = { limit: DATA_PER_PAGE_LIMIT, offset: offset };
        setTab(isGroupChat);
        setSelected(manipulateChatData(resp?.data));
        setNewLoader(true);
        getChats({
          queryParams,
          logic(res) {
            setNewLoader(false);
            setTotal(res.total);
            navigate(
              replaceValInString(MESSAGES_ROUTE, {
                ":chat_id": resp?.data?.slug,
              })
            );
          },
        });
      },
    });
  };

  const loadMessageHandler = (item) => {
    // dispatch(setThread(null));
    if (item !== selected) {
      setSelected(item);
      dispatch(loadRoomSuccess(item));
    }
  };

  const handleDirectSelect = (data) => {
    const temp = chatList?.find((x) => x?.individual?.id === data?.id);
    setSelected(temp);
    if (temp) {
      navigate(replaceValInString(MESSAGES_ROUTE, { ":chat_id": temp?.slug }));
      loadMessageHandler(temp);
      setTab(isGroupChat);
    } else {
      handleInitChat(data?.chat_id);
    }
  };

  // HOOKS
  useEffect(() => {
    if (id === selected?.slug) {
      loadMessageHandler(selected);
    }
    setTab(isGroupChat);
  }, [id, selected, pathname]);

  useEffect(() => {
    if (firstLoad) {
      loadMessageHandler({});
    }
  }, [firstLoad]);

  useEffect(() => {
    setDirectModalPreview(false);
  }, [selectedRoom]);

  useEffect(() => {
    dispatch(setIsSelectFirst(false));

    return () => {
      dispatch(setThread(null));
      dispatch(loadRoomSuccess({}));
      dispatch(getThreadsSuccess({}));
      dispatch(getChatListSuccess([]));
    };
  }, []);

  return (
    <section className="messages-container">
      <div className="messages-header">
        <div className="title-wrapper">
          <h3>Chat Room</h3>
          <p>View Details Below</p>
        </div>
        <div className="chat-btn-wrapper">
          <ButtonComponent
            text="Direct Message +"
            onClick={directModalPreviewHandler}
          />
          <ButtonComponent
            text="Group Chat +"
            className="secondary"
            onClick={createGroupModalPreviewHandler}
          />
        </div>
      </div>
      <Row gutter={[20, 0]} className="chat-row">
        <ChatList
          chatItems={isGroupChat ? groupChats : singleChats}
          setSelected={setSelected}
          selected={selected}
          getUsers={getChats}
          isLoading={chatsLoader}
          firstLoad={firstLoad}
          setFirstLoad={setFirstLoad}
          handleSelect={loadMessageHandler}
          isGroupChat={isGroupChat}
          isMoreData={isMoreData}
          search={search}
          setMoreData={setMoreData}
          setSearch={setSearch}
          offset={offset}
          setOffset={setOffset}
          setTotal={setTotal}
          total={total}
          tab={tab}
          setTab={setTab}
          addGroupHandler={createGroupModalPreviewHandler}
          newLoader={newLoader}
          setNewLoader={setNewLoader}
        />
        <ChatBox
          chatItems={usersList}
          selected={selected}
          isLoading={loadRoomLoader || (firstLoad && chatsLoader)}
        />
      </Row>
      <DirectMessageModal
        preview={directModalPreview}
        handleClose={directModalPreviewHandler}
        setSelected={handleDirectSelect}
      />

      <CreateGroupModal
        preview={createGroupModalPreview}
        handleClose={createGroupModalPreviewHandler}
      />
    </section>
  );
}

export default Messages;
