import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_ROUTE } from "../constants";

export const useCustomDispatch = (request) => {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sendRequest = (args = {}) => {
    const {
      payload,
      queryParams,
      pathParams,
      logic,
      error,
      callback,
      otherkeys = {},
    } = args;
    setLoading(true);
    dispatch(
      request({
        payload,
        queryParams,
        pathParams,
        ...otherkeys,
        responseCallback(isApiSucceed, response) {
          if (response?.isUnAuthorized) {
            navigate(DASHBOARD_ROUTE);
          } else {
            callback && callback(response);
            setLoading(false);
            if (isApiSucceed == false) {
              error && error(response);
              return;
            }
            logic && logic(response);
          }
        },
      })
    );
  };

  return [sendRequest, isLoading];
};
