import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  DeleteModal,
  DisabledTooltip,
  EmptyBox,
  NoAccessBox,
  ProfileImage,
} from "../../../../../../../../components";
import {
  ALERT_TYPES,
  TRANSACTION_DOCUMENT_DETAIL_ROUTE,
  TRANSACTION_TABS_SLUGS,
} from "../../../../../../../../constants";
import { useCustomDispatch } from "../../../../../../../../helpers/useCustomDispatch";
import { setUserDetailDrawer } from "../../../../../../../../redux/slicers/general";
import { removeChecklistDocRequest } from "../../../../../../../../redux/slicers/transactionchecklist";
import {
  replaceValInString,
  toastAlert,
} from "../../../../../../../../services/utils";
import { Images } from "../../../../../../../../themes";
import { DocNoAccessModal } from "../../../components";

function DocTable({ data, isEditable, isEditAllowed, isViewAllowed }) {
  //STATES
  const [unlinkModalPreview, setUnlinkModalPreview] = useState(false);
  const [docAccessModalPreview, setDocAccessModalPreview] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);

  //CUSTOM DISPATCH
  const [unlinkDocument, unlinkLoader] = useCustomDispatch(
    removeChecklistDocRequest
  );

  //CONST VALS
  const { id, room, dataid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const TABLE_COLUMNS = [
    {
      title: "Name",
      key: "name",
    },
    {
      title: "Upload By",
      key: "uploadBy",
    },
    {
      title: "Actions",
      key: "actions",
    },
  ];

  //HANDLERS
  const docNavHandler = (item) => {
    if (item?.accessible) {
      const val = {
        ":id": id,
        ":room": room,
        ":tab": TRANSACTION_TABS_SLUGS.DOCUMENTS,
        ":dataid": item?.slug,
      };
      navigate(replaceValInString(TRANSACTION_DOCUMENT_DETAIL_ROUTE, val));
      return;
    }
    docAccessModalPreviewHandler(item);
  };

  const unlinkModalPreviewHandler = (id) => {
    selectedDoc ? setSelectedDoc(null) : setSelectedDoc(id);
    setUnlinkModalPreview(!unlinkModalPreview);
  };

  const handleUnlink = () => {
    unlinkDocument({
      payload: { ids: [selectedDoc], roomSlug: room },
      pathParams: dataid,
      logic(res) {
        unlinkModalPreviewHandler(null);
        toastAlert(res.message, ALERT_TYPES.SUCCESS);
      },
    });
  };

  const docAccessModalPreviewHandler = (item) => {
    selectedDoc ? setSelectedDoc(null) : setSelectedDoc(item);
    setDocAccessModalPreview(!docAccessModalPreview);
  };

  const userDetailHandler = (userid) => {
    dispatch(
      setUserDetailDrawer({
        visibility: true,
        id: userid,
      })
    );
  };

  return (
    <div className="checklist-doc-table">
      <div className="table-head">
        {TABLE_COLUMNS.map((item, index) => (
          <span className="head-ttl" key={index}>
            <span>{item.title}</span>
          </span>
        ))}
      </div>
      <div className="table-body">
        {isViewAllowed ? (
          data?.length > 0 ? (
            data?.map((item, index) => (
              <div className="body-row " key={index}>
                <div className="body-col">
                  <div className="doc-icon">
                    <Images.DocThumb />
                  </div>
                  <div className="doc-name">
                    <h3>{item.name}</h3>
                  </div>
                </div>
                <div className="body-col">
                  <div className="uploadBy">
                    <ProfileImage
                      username={item?.creatorName}
                      image={item?.creatorImage}
                      size={10}
                      color={item?.bgColor}
                      onClick={() => {
                        userDetailHandler(item.creatorId);
                      }}
                      isBlocked={!item?.isActive}
                    />
                    <div className="upload-info">
                      <p>{item?.creatorName}</p>
                      <p className="upload-from">
                        Uploaded From {item?.uploadedFrom}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="body-col">
                  <div className="action">
                    <div
                      className="view-btn c-p"
                      onClick={() => docNavHandler(item)}
                    >
                      <p>View Document</p>
                    </div>
                    {isEditable && (
                      <DisabledTooltip
                        innerSec={
                          <div
                            className=" unlink c-p"
                            onClick={(e) => {
                              if (isEditAllowed) {
                                e.stopPropagation();
                                unlinkModalPreviewHandler(item.id);
                              }
                            }}
                          >
                            <Images.Unlink />
                          </div>
                        }
                        visible={!isEditAllowed}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <EmptyBox height={170} size={12} imgwidth={80} />
          )
        ) : (
          <NoAccessBox height={170} size={12} imgwidth={80} />
        )}
        <DeleteModal
          preview={unlinkModalPreview}
          btnText="Remove"
          title={"Remove Document"}
          description={
            "Are you sure you want to remove this document from this checklist?"
          }
          handleClose={unlinkModalPreviewHandler}
          isLoading={unlinkLoader}
          handleDelete={handleUnlink}
        />
        <DocNoAccessModal
          preview={docAccessModalPreview}
          handleClose={docAccessModalPreviewHandler}
          data={selectedDoc}
        />
      </div>
    </div>
  );
}

export default DocTable;
