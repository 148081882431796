import React, { useEffect } from "react";
import { Col, Modal, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Images } from "../../themes";
import { CardLoader, ProfileImage } from "..";
import { useDispatch, useSelector } from "react-redux";
import { useCustomDispatch } from "../../helpers/useCustomDispatch";
import {
  closePendingRequestModal,
  getPendingRequesDetailRequest,
  setUserDetailDrawer,
} from "../../redux/slicers/general";
import "./styles.scss";

function PendingApprovalModal() {
  // REDUX DATA
  const { visibility, dataid, isAdmin, data } = useSelector(
    (state) => state.general.pendingApprovalRequest
  );
  const { data: transactionData } = useSelector(
    (state) => state.transactiondetail
  );

  // CONST VALS
  const dispatch = useDispatch();

  //CUSTOM DISPATCH
  const [getRequestDetail, isLoading] = useCustomDispatch(
    getPendingRequesDetailRequest
  );

  // HOOKS
  useEffect(() => {
    dataid &&
      getRequestDetail({
        pathParams: dataid,
        queryParams: { isAdmin },
      });
  }, [dataid]);

  //HANDLERS
  const closeHandler = () => {
    dispatch(closePendingRequestModal());
  };

  return (
    <Modal
      destroyOnClose
      visible={visibility}
      centered
      footer={null}
      width={654}
      className="pending-approval-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          {isLoading ? (
            <CardLoader height={240} />
          ) : (
            <>
              <Row>
                <Col span={11} className="left-wrapper">
                  <Images.AdminApproval />
                  <p>
                    Approvals required and requested from Transaction Admins
                  </p>
                </Col>
                <Col span={13} className="right-wrapper">
                  <h3 className="title">{isAdmin ? "Party Group" : "Transaction"} Admins</h3>
                  <div className="admin-wrapper">
                    {data?.map((item, index) => (
                      <div className="admin-list-item" key={index}>
                        <div className="left">
                          <ProfileImage
                            image={item.image}
                            username={item.username}
                            color={item.profilebg}
                            isBlocked={!item.isActive}
                            onClick={() =>
                              dispatch(
                                setUserDetailDrawer({
                                  visibility: true,
                                  id: item.id,
                                })
                              )
                            }
                          />
                          <div className="info">
                            <h3>
                              {item.username} | {item.companyName}
                            </h3>
                            <p>{item.email}</p>
                          </div>
                        </div>
                        <span className={`status`} style={item?.status?.style}>
                          {item.status.title}
                        </span>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default PendingApprovalModal;
