import React, { useState, useEffect } from "react";
import { Col, Form, Switch, Row } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AccountInput,
  AddAddressModal,
  DeleteAddress,
  ButtonComponent,
  CardLoader,
  HorizontalLine,
  ImageUploader,
  ChangeNumberModal,
  ProfileImage,
} from "../../../components";
import { ACCOUNT_ROUTE } from "../../../constants";
import { useCustomDispatch } from "../../../helpers/useCustomDispatch";
import {
  getProfileDetailRequest,
  updateProfileDetailRequest,
} from "../../../redux/slicers/user";
import Images from "../../../themes/images";
import { inputFieldRule, toastAlert } from "../../../services/utils";
import "./styles.scss";

// PARTICIPANT ACCOUNT DETAIL
function EditParticipantAccount({ buttons, userdata, isLoading, submit }) {
  const [form] = Form.useForm();

  // FIELDS DATA
  const PERSONALINFO_FIELDS = [
    {
      NAME: "firstName",
      TITLE: "First Name",
      PLACEHOLDER: "Enter first name",
      ERRORMESSAGE: "First name",
      DISABLED: false,
      REQUIRED: true,
      WHITESPACE: true,
      ISMAX: true,
      MAX: 80,
      TYPE: "text",
    },
    {
      NAME: "lastName",
      TITLE: "Last Name",
      PLACEHOLDER: "Enter last name",
      ERRORMESSAGE: "Last name",
      DISABLED: false,
      REQUIRED: true,
      WHITESPACE: true,
      ISMAX: true,
      MAX: 80,
      TYPE: "text",
    },
    {
      NAME: "phone",
      TITLE: "Mobile Phone",
      PLACEHOLDER: "Enter phone number",
      ERRORMESSAGE: "Mobile Phone",
      DISABLED: true,
      REQUIRED: true,
      WHITESPACE: false,
      TYPE: "tel",
      // IS_CLICKABLE: true,
      IS_CUSTOM: true,
      IS_CUSTOM_CLICKABLE: true,
      ONCLICK: () => {
        setChangeNumberModalPreview(true);
      },
    },
    {
      NAME: "work_phone",
      TITLE: "Work Phone",
      PLACEHOLDER: "Enter work phone number",
      ERRORMESSAGE: "Work Phone",
      DISABLED: false,
      REQUIRED: false,
      WHITESPACE: false,
      TYPE: "tel",
      IS_CUSTOM: true,
    },
    {
      NAME: "email",
      TITLE: "Email",
      PLACEHOLDER: "Enter email address",
      ERRORMESSAGE: "Email",
      DISABLED: true,
      REQUIRED: true,
      WHITESPACE: false,
      TYPE: "email",
    },
    {
      NAME: "company_name",
      TITLE: "Company Name",
      PLACEHOLDER: "Enter company name",
      ERRORMESSAGE: "Company name",
      DISABLED: true,
      REQUIRED: true,
      WHITESPACE: true,
      TYPE: "text",
    },
    {
      NAME: "jobTitle",
      TITLE: "Job Title",
      PLACEHOLDER: "Enter job title",
      ERRORMESSAGE: "Job title",
      DISABLED: false,
      REQUIRED: false,
      WHITESPACE: true,
      ISMAX: true,
      MAX: 80,
      TYPE: "text",
    },
    {
      NAME: "linkedin",
      TITLE: "Linkedin Profile",
      PLACEHOLDER: "Enter linkedin link",
      ERRORMESSAGE: "Link",
      DISABLED: false,
      REQUIRED: false,
      WHITESPACE: false,
      TYPE: "url",
    },
    {
      NAME: "facebook",
      TITLE: "Facebook",
      PLACEHOLDER: "Enter facebook link",
      ERRORMESSAGE: "Link",
      DISABLED: false,
      REQUIRED: false,
      WHITESPACE: false,
      TYPE: "url",
    },
    {
      NAME: "twitter",
      TITLE: "Twitter",
      PLACEHOLDER: "Enter twitter link",
      ERRORMESSAGE: "Link",
      DISABLED: false,
      REQUIRED: false,
      WHITESPACE: false,
      TYPE: "url",
    },
  ];
  // STATES
  const [isEdit, setEdit] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [oldProfilePic, setoldProfilePic] = useState(null);
  const [changeNumberModalPreview, setChangeNumberModalPreview] =
    useState(false);

  // HANDLERS
  const setProfilePicHandler = (thumb) => {
    setProfilePic(thumb);
    setEdit(true);
  };
  const handleSubmit = () => {
    const values = form.getFieldsValue();
    let payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      image: profilePic,
      workPhone: values.work_phone,
      jobTitle: values.jobTitle,
      linkedIn: values.linkedin,
      facebook: values.facebook,
      twitter: values.twitter,
    };
    submit(payload);
  };
  // HOOKS
  useEffect(() => {
    if (_.isEmpty(userdata)) return;
    form.setFieldsValue({
      firstName: userdata.firstName,
      lastName: userdata.lastName,
      phone: userdata.phone,
      work_phone: userdata.workPhone,
      email: userdata.email,
      company_name: userdata?.company.name,
      jobTitle: userdata.jobTitle,
      linkedin: userdata.linkedIn,
      twitter: userdata.twitter,
      facebook: userdata.facebook,
    });
    setProfilePic(userdata.profilePic);
    setoldProfilePic(userdata.profilePic);
  }, [userdata]);

  return (
    <>
      <Form form={form} onFinish={handleSubmit}>
        <div className="card design-shadow personal-info-card">
          <div className="profile-picture-section-wrapper">
            <div className="edit-profile-picture-wrapper">
              <ProfileImage
                size={35}
                classname="profile-picture"
                image={profilePic}
                color={userdata?.profilebg}
                username={userdata?.username}
              />
              <ImageUploader
                isIcon={true}
                image={profilePic}
                setImage={setProfilePicHandler}
                oldImage={oldProfilePic}
                iconClassName="edit-img-icon"
              />
            </div>
          </div>
          <div className="inputs-section">
            <div className="edit-account-form">
              <Row>
                {PERSONALINFO_FIELDS.map((ITEM, i) => {
                  return (
                    <Col md={12} lg={8} key={i}>
                      <Form.Item
                        className="child"
                        name={ITEM.NAME}
                        rules={inputFieldRule({
                          name: ITEM.ERRORMESSAGE,
                          isRequired: ITEM.REQUIRED,
                          isWhiteSpace: ITEM.WHITESPACE,
                          isType: true,
                          type: ITEM.TYPE,
                          isMax: ITEM.ISMAX,
                          max: ITEM.MAX,
                        })}
                      >
                        <AccountInput
                          onClick={ITEM.ONCLICK}
                          isClickable={ITEM.IS_CLICKABLE}
                          name={ITEM.TITLE}
                          placeholder={ITEM.PLACEHOLDER}
                          type={ITEM.TYPE}
                          title={ITEM.TITLE}
                          disabled={ITEM.DISABLED}
                          onChange={() => {
                            setEdit(true);
                          }}
                          isCustom={ITEM.IS_CUSTOM}
                          isCustomClickable={ITEM.IS_CUSTOM_CLICKABLE}
                          isRequired={ITEM.REQUIRED}
                        />
                      </Form.Item>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </div>
        <div className="account-update-button-wrapper">
          <ButtonComponent
            text={"Update"}
            disabled={!isEdit}
            isLoading={isLoading}
          />
        </div>
      </Form>
      <ChangeNumberModal
        preview={changeNumberModalPreview}
        handleClose={() => {
          setChangeNumberModalPreview(false);
        }}
      />
    </>
  );
}

// ENTERPRISE ACCOUNT DETAIL
function EditEnterPriceAccount({ userdata, isLoading, submit }) {
  const [form] = Form.useForm();

  // CONST VALS
  const PERSONALINFO_FIELDS = [
    {
      NAME: "firstName",
      TITLE: "First Name",
      PLACEHOLDER: "Enter first name",
      ERRORMESSAGE: "First name",
      DISABLED: false,
      REQUIRED: true,
      WHITESPACE: true,
      ISMAX: true,
      MAX: 80,
      TYPE: "text",
    },
    {
      NAME: "lastName",
      TITLE: "Last Name",
      PLACEHOLDER: "Enter last name",
      ERRORMESSAGE: "Last name",
      DISABLED: false,
      REQUIRED: true,
      WHITESPACE: true,
      ISMAX: true,
      MAX: 80,
      TYPE: "text",
    },
    {
      NAME: "phone",
      TITLE: "Mobile Phone",
      PLACEHOLDER: "Enter phone number",
      ERRORMESSAGE: "Phone",
      DISABLED: false,
      REQUIRED: true,
      WHITESPACE: false,
      TYPE: "tel",
      IS_CUSTOM: true,
      IS_CUSTOM_CLICKABLE: true,
      // IS_CLICKABLE: true,
      ONCLICK: () => {
        setChangeNumberModalPreview(true);
      },
    },
    {
      NAME: "workPhone",
      TITLE: "Work Phone",
      PLACEHOLDER: "Enter work phone number",
      ERRORMESSAGE: "Work phone",
      DISABLED: false,
      REQUIRED: false,
      WHITESPACE: false,
      TYPE: "tel",
      IS_CUSTOM: true,
    },
    {
      NAME: "email",
      TITLE: "Email",
      PLACEHOLDER: "Enter email address",
      ERRORMESSAGE: "Email",
      DISABLED: true,
      REQUIRED: true,
      WHITESPACE: false,
      TYPE: "email",
    },
    {
      NAME: "jobTitle",
      TITLE: "Job Title",
      PLACEHOLDER: "Enter job title",
      ERRORMESSAGE: "Job title",
      DISABLED: false,
      REQUIRED: false,
      WHITESPACE: true,
      ISMAX: true,
      MAX: 80,
      TYPE: "text",
    },
    {
      NAME: "facebook",
      TITLE: "Facebook",
      PLACEHOLDER: "Enter Facebook link",
      ERRORMESSAGE: "link",
      DISABLED: false,
      REQUIRED: false,
      WHITESPACE: false,
      TYPE: "url",
    },
    {
      NAME: "twitter",
      TITLE: "Twitter",
      PLACEHOLDER: "Enter Twitter link",
      ERRORMESSAGE: "link",
      DISABLED: false,
      REQUIRED: false,
      WHITESPACE: false,
      TYPE: "url",
    },
    {
      NAME: "linkedin",
      TITLE: "Linkedin",
      PLACEHOLDER: "Enter Linkedin link",
      ERRORMESSAGE: "link",
      DISABLED: false,
      REQUIRED: false,
      WHITESPACE: false,
      TYPE: "url",
    },
  ];
  const COMPANY_INFORMATION_FIELDS = [
    {
      NAME: "company_name",
      TITLE: "Name",
      PLACEHOLDER: "Enter company name",
      ERRORMESSAGE: "Title",
      DISABLED: false,
      REQUIRED: true,
      WHITESPACE: true,
      ISMAX: true,
      MAX: 80,
      TYPE: "text",
    },
    {
      NAME: "company_web",
      TITLE: "Website",
      PLACEHOLDER: "Enter Website link",
      ERRORMESSAGE: "link",
      DISABLED: false,
      REQUIRED: false,
      WHITESPACE: false,
      ISMAX: true,
      MAX: 120,
      TYPE: "url",
    },
    {
      NAME: "company_number",
      TITLE: "Phone number",
      PLACEHOLDER: "Enter company phone number",
      ERRORMESSAGE: "Work phone number",
      DISABLED: false,
      REQUIRED: false,
      WHITESPACE: false,
      TYPE: "tel",
      IS_CUSTOM: true,
    },
    {
      NAME: "company_facebook",
      TITLE: "Facebook",
      PLACEHOLDER: "Enter Facebook link",
      ERRORMESSAGE: "link",
      DISABLED: false,
      REQUIRED: false,
      WHITESPACE: false,
      TYPE: "url",
    },
    {
      NAME: "company_twitter",
      TITLE: "Twitter",
      PLACEHOLDER: "Enter Twitter link",
      ERRORMESSAGE: "link",
      DISABLED: false,
      REQUIRED: false,
      WHITESPACE: false,
      TYPE: "url",
    },
    {
      NAME: "company_linkedin",
      TITLE: "Linkedin",
      PLACEHOLDER: "Enter Linkedin link",
      ERRORMESSAGE: "link",
      DISABLED: false,
      REQUIRED: false,
      WHITESPACE: false,
      TYPE: "url",
    },
  ];
  // STATES
  const [addAddressPreview, seAddAddressPreview] = useState(false);
  const [isEditAddressModal, SetEditAddressModal] = useState(false);
  const [deleteAddressPreview, setDeleteAddressPreview] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [profilePicKey, setProfilePicKey] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyLogoKey, setCompanyLogoKey] = useState(null);
  const [oldProfilePic, setoldProfilePic] = useState(null);
  const [oldCompanyLogo, setOldCompanyLogo] = useState(null);
  const [selectedData, setselectedData] = useState(null);
  const [isEdit, setEdit] = useState(false);
  const [isWorkPhoneNumber, setWorkPhoneNumber] = useState(false);
  const [changeNumberModalPreview, setChangeNumberModalPreview] =
    useState(false);

  // HANDLERS
  const setProfilePicHandler = (thumb) => {
    setProfilePic(thumb);
    setEdit(true);
  };
  const setcompanyLogoHandler = (logo) => {
    setCompanyLogo(logo);
    setEdit(true);
  };
  const setProfilePicKeyHandler = (thumb) => {
    setProfilePicKey(thumb);
    setEdit(true);
  };
  const setcompanyLogoKeyHandler = (logo) => {
    setCompanyLogoKey(logo);
    setEdit(true);
  };
  const setEditAddressModalHandler = (data) => {
    setselectedData(data);
    SetEditAddressModal(true);
  };
  const setDeleteAddressModalHandler = (data) => {
    setselectedData(data);
    setDeleteAddressPreview(true);
  };

  const handleSubmit = () => {
    const values = form.getFieldsValue();

    let payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      workPhone: values.workPhone,
      image: profilePicKey,
      jobTitle: values.jobTitle,
      linkedIn: values.linkedin === "" ? null : values.linkedin,
      facebook: values.facebook === "" ? null : values.facebook,
      twitter: values.twitter === "" ? null : values.twitter,
      updateCompany: {
        companyName: values.company_name,
        logo: companyLogoKey,
        linkedIn: values.company_linkedin,
        facebook: values.company_facebook,
        twitter: values.company_twitter,
        website: values.company_web,
        workPhone: values.company_number,
      },
    };
    submit(payload);
  };
  // HOOKS
  useEffect(() => {
    if (_.isEmpty(userdata)) return;
    form.setFieldsValue({
      firstName: userdata.firstName,
      lastName: userdata.lastName,
      phone: userdata.phone,
      workPhone: userdata?.workPhone,
      email: userdata.email,
      company_name: userdata?.company.name,
      company_web: userdata?.company.website,
      company_number: userdata?.company.phone,
      jobTitle: userdata.jobTitle,
      linkedin: userdata.linkedIn,
      twitter: userdata.twitter,
      facebook: userdata.facebook,
      company_linkedin: userdata?.company.linkedIn,
      company_twitter: userdata?.company.twitter,
      company_facebook: userdata?.company.facebook,
    });
    setProfilePic(userdata.profilePic);
    setProfilePicKey(userdata?.profilePicPath);
    setCompanyLogo(userdata.company?.logo);
    setCompanyLogoKey(userdata?.company?.logoPath);
    setoldProfilePic(userdata.profilePic);
    setOldCompanyLogo(userdata.company?.logo);
  }, [userdata]);

  return (
    <>
      {/* parent */}
      <Form form={form} onFinish={handleSubmit}>
        <div className="card design-shadow personal-info-card">
          <h3 className="personal-info-heading">Personal Information</h3>
          <HorizontalLine />
          <div className="profile-picture-section-wrapper">
            <div className=" edit-profile-picture-wrapper ">
              <ProfileImage
                size={35}
                classname="profile-picture"
                image={profilePic}
                color={userdata?.profilebg}
                username={userdata?.username}
              />
              <ImageUploader
                isIcon={true}
                image={profilePic}
                setImage={setProfilePicKeyHandler}
                setPreviewImage={setProfilePicHandler}
                oldImage={oldProfilePic}
                iconClassName="edit-img-icon"
              />
            </div>
          </div>
          <div className="inputs-section">
            <Row className="edit-account-form">
              {PERSONALINFO_FIELDS.map((ITEM, i) => {
                return (
                  <Col md={12} lg={8} key={i}>
                    <Form.Item
                      required
                      requiredMark
                      className="child"
                      name={ITEM.NAME}
                      rules={inputFieldRule({
                        name: ITEM.ERRORMESSAGE,
                        isRequired: ITEM.REQUIRED,
                        isWhiteSpace: ITEM.WHITESPACE,
                        isType: true,
                        type: ITEM.TYPE,
                        isMax: ITEM.ISMAX,
                        max: ITEM.MAX,
                      })}
                    >
                      <AccountInput
                        isRequired={ITEM.REQUIRED}
                        onClick={ITEM.ONCLICK}
                        isClickable={ITEM.IS_CLICKABLE}
                        name={ITEM.TITLE}
                        placeholder={ITEM.PLACEHOLDER}
                        type={ITEM.TYPE}
                        title={ITEM.TITLE}
                        disabled={ITEM.DISABLED}
                        onChange={() => {
                          setEdit(true);
                        }}
                        isCustom={ITEM.IS_CUSTOM}
                        isCustomClickable={ITEM.IS_CUSTOM_CLICKABLE}
                      />
                    </Form.Item>
                  </Col>
                );
              })}
            </Row>
          </div>
          <h3 className="personal-info-heading mt-2">Company Information</h3>
          <HorizontalLine />
          <div className="profile-picture-section-wrapper">
            <div className=" edit-profile-picture-wrapper">
              <ProfileImage
                size={35}
                classname="profile-picture"
                image={companyLogo}
                username={userdata?.company?.name}
              />
              <ImageUploader
                isIcon={true}
                image={companyLogo}
                setImage={setcompanyLogoKeyHandler}
                setPreviewImage={setcompanyLogoHandler}
                oldImage={oldCompanyLogo}
                iconClassName="edit-img-icon"
              />
            </div>
          </div>
          {/* inputs section */}
          <div className="inputs-section">
            <Row className="edit-account-form">
              {COMPANY_INFORMATION_FIELDS.map((ITEM, i) => {
                return (
                  <Col md={12} lg={8} key={i}>
                    <Form.Item
                      className="child"
                      name={ITEM.NAME}
                      rules={inputFieldRule({
                        name: ITEM.ERRORMESSAGE,
                        isRequired: ITEM.REQUIRED,
                        isWhiteSpace: ITEM.WHITESPACE,
                        isType: true,
                        type: ITEM.TYPE,
                        isMax: ITEM.ISMAX,
                        max: ITEM.MAX,
                      })}
                    >
                      <AccountInput
                        isClickable={ITEM.IS_CLICKABLE}
                        onClick={ITEM.ONCLICK}
                        name={ITEM.TITLE}
                        placeholder={ITEM.PLACEHOLDER}
                        type={ITEM.TYPE}
                        title={ITEM.TITLE}
                        disabled={ITEM.DISABLED}
                        onChange={() => {
                          setEdit(true);
                        }}
                        isCustom={ITEM.IS_CUSTOM}
                        isRequired={ITEM.REQUIRED}
                      />
                    </Form.Item>
                  </Col>
                );
              })}
            </Row>
          </div>

          <Row className="address-container">
            {userdata?.company.addresses.map((item, i) => (
              <Col lg={8} md={12} key={i}>
                <div className="address-card">
                  <div className="text">
                    <h4>{item.name}</h4>
                    <p>
                      {`${item?.address}${
                        item?.address2 ? "," + item?.address2 : ""
                      }  ${item?.city}, ${item?.state}, ${item?.zip}, ${
                        item?.country
                      }`}
                    </p>
                  </div>
                  <div className="icons">
                    {userdata?.company.addresses.length > 1 && (
                      <Images.Delete
                        className="c-p"
                        onClick={() => {
                          setDeleteAddressModalHandler(item);
                        }}
                      />
                    )}
                    <Images.Edit
                      className="edit c-p"
                      onClick={() => {
                        setEditAddressModalHandler(item);
                      }}
                    />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <div
            onClick={() => seAddAddressPreview(true)}
            className="add-address-icon"
          >
            <Images.AddressPlus />
            <p>Address</p>
          </div>
        </div>
        <div className="account-update-button-wrapper">
          <ButtonComponent
            text={"Save"}
            disabled={!isEdit}
            isLoading={isLoading}
          />
        </div>
      </Form>
      <AddAddressModal
        selectedData={selectedData}
        isEdit={isEditAddressModal}
        company={userdata?.company.id}
        preview={addAddressPreview || isEditAddressModal}
        handleClose={() => {
          seAddAddressPreview(false);
          SetEditAddressModal(false);
        }}
      />
      <DeleteAddress
        data={selectedData}
        preview={deleteAddressPreview}
        handleClose={() => {
          setDeleteAddressPreview(false);
          setselectedData(null);
        }}
      />

      <ChangeNumberModal
        preview={changeNumberModalPreview}
        handleClose={() => {
          setChangeNumberModalPreview(false);
          setWorkPhoneNumber(false);
        }}
        isWorkPhone={isWorkPhoneNumber}
      />
    </>
  );
}

function EditAccount() {
  // CONST VALS
  const navigate = useNavigate();

  // REDUX DATA
  const { isEnterpriseUser, user_id } = useSelector((state) => state.user.data);
  const userData = useSelector((state) => state.user.profile);

  // CUSTOM DISPATCH
  const [getProfileDetail, isDataLoading] = useCustomDispatch(
    getProfileDetailRequest
  );
  const [updateProfile, isLoading] = useCustomDispatch(
    updateProfileDetailRequest
  );

  // HANDLERS
  const handleSubmit = (payload) => {
    updateProfile({
      payload,
      logic(res) {
        toastAlert(res.message);
        navigate(ACCOUNT_ROUTE);
      },
    });
  };

  // HOOKS
  useEffect(() => {
    getProfileDetail();
  }, []);

  return (
    <>
      <section className="parent-section">
        <div className="top-heading" style={{ marginTop: 30 }}>
          <h3 className="m-0">Edit Account</h3>
          <p className="m-0" style={{ paddingTop: 3 }}>
            Edit The Details Below
          </p>
        </div>
        {isDataLoading ? (
          <div className="card design-shadow personal-info-card">
            <CardLoader />
          </div>
        ) : (
          <>
            {isEnterpriseUser ? (
              <EditEnterPriceAccount
                userdata={userData}
                isLoading={isLoading}
                submit={handleSubmit}
              />
            ) : (
              <EditParticipantAccount
                userdata={userData}
                isLoading={isLoading}
                submit={handleSubmit}
              />
            )}
          </>
        )}
      </section>
    </>
  );
}

export default EditAccount;
