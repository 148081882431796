import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { Images } from "../../../../../../../themes";
import {
  CustomInfiniteScroll,
  StatusToggle,
  EditDeleteDropdown,
  CardLoader,
  EmptyBox,
  DisabledTooltip,
} from "../../../../../../../components";
import "./styles.scss";

export default function MilestoneDataTable({
  sortData,
  selectedSort,
  selectedSortFilter,
  isLoading,
  total,
  data,
  handleFetch,
  showMilestoneDetail,
  milestoneStatusChange,
  statusChangeLoader,
  updateModalHandler,
  deleteModalHandler,
  isDeleteAllowed,
  isEditAllowed,
}) {
  //CONST VALS
  const HEIGHT = 400;
  const BOX_HEIGHT = `calc(100vh - ${HEIGHT}px)`;
  const TABLE_COLUMNS = [
    {
      title: "Detail",
      isSort: false,
      key: "detail",
    },
    {
      title: "End Date",
      isSort: true,
      key: "endDate",
    },
    {
      title: "Status",
      isSort: true,
      key: "status",
    },
  ];

  return (
    <div className="milestone-datatable">
      <div className="table-head">
        {TABLE_COLUMNS.map((item, index) => {
          const isSelected = item.key === selectedSort;
          const selectedCaret = isSelected && !selectedSortFilter;
          return (
            <span
              className={`head-ttl ${isSelected ? "active" : ""}`}
              key={index}
            >
              <span
                className={item.isSort ? "c-p" : ""}
                onClick={() => {
                  item.isSort && sortData(item.key);
                }}
              >
                {item.title}
                {item.isSort && (
                  <FontAwesomeIcon
                    icon={selectedCaret ? faCaretUp : faCaretDown}
                    className="sort-icon"
                  />
                )}
              </span>
            </span>
          );
        })}
      </div>
      <div className="table-body">
        {isLoading ? (
          <CardLoader height={BOX_HEIGHT} size={18} />
        ) : (
          <CustomInfiniteScroll
            dataLength={data.length}
            total={total}
            next={handleFetch}
            maxHeight={HEIGHT}
            minHeight={BOX_HEIGHT}
          >
            {data.length >= 1 ? (
              data.map((item, index) => (
                <div
                  className="body-row c-p"
                  key={index}
                  onClick={() => {
                    showMilestoneDetail(item.slug);
                  }}
                >
                  <div className="body-col">
                    <div className="thumb">
                      <Images.TransactionMilestoneThumb />
                    </div>
                    <div className="detail">
                      <h4>{item.name}</h4>
                      <p>{item.startDate}</p>
                    </div>
                  </div>
                  <div className="body-col">
                    <span className="date">
                      {item.endDate ? item.endDate : "---------------"}
                    </span>
                  </div>
                  <div className="body-col">
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <DisabledTooltip
                        innerSec={
                          <StatusToggle
                            check={item.status}
                            handleStatusChange={() => {
                              milestoneStatusChange(item.slug);
                            }}
                            confirmLoader={statusChangeLoader}
                            confirmDescription={
                              "Are you sure you want to change this milestone's status?"
                            }
                            isStatic={!isEditAllowed}
                          />
                        }
                        visible={!isEditAllowed}
                      />
                    </span>
                    <span
                      className="action"
                      style={{ minWidth: 35 }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {(isDeleteAllowed || isEditAllowed) && !item.status && (
                        <EditDeleteDropdown
                          isEditable={isEditAllowed ?? false}
                          isDelete={isDeleteAllowed ?? false}
                          onEditClick={() => {
                            updateModalHandler(item);
                          }}
                          onDeleteClick={() => {
                            deleteModalHandler(item);
                          }}
                        />
                      )}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <EmptyBox height={BOX_HEIGHT} />
            )}
          </CustomInfiniteScroll>
        )}
      </div>
    </div>
  );
}
