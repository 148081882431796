import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AddTeamMembersModal, ButtonComponent } from "../../../../components";
import { ENTERPRISE_PENDINGREQUESTS_ROUTE } from "../../../../constants";
import { useCustomDispatch } from "../../../../helpers/useCustomDispatch";
import {
  getAddressesListRequest,
  getPendingMembersRequest,
} from "../../../../redux/slicers/company";
import EnterpriseMembers from "./members";
import EnterpriseRequests from "./requests";
import "./styles.scss";

function EnterpriseTeamManagement() {
  // CONST VALS
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isRequestView = pathname === "/pending-requests";

  // STATES
  const [addMemberModalPreview, setAddMemberModalPreview] = useState(false);
  const [isMemberAdded, setMemberAdded] = useState(false);

  // REDUX DATA
  const { addresses, pendingRequests } = useSelector((state) => state.company);

  // DISPATCH CALLS
  const [getAddresses] = useCustomDispatch(getAddressesListRequest);
  const [getPendingMembers, isLoading] = useCustomDispatch(
    getPendingMembersRequest
  );

  // HOOKS
  useEffect(() => {
    getAddresses();
    getPendingMembers();
  }, []);

  // HANDLERS
  const addMemberModalPreviewHandler = () => {
    setAddMemberModalPreview(!addMemberModalPreview);
  };

  const isMemberAddedHandler = () => {
    setMemberAdded(!isMemberAdded);
  };

  return (
    <section className="enterprise-team-management">
      <div className="top-container">
        <div className="top-heading">
          <h3>Team Management</h3>
          <p>Manage Your Team Members</p>
        </div>
        <div className="btns">
          <div className="requestbtn-wrapper">
            <ButtonComponent
              onClick={() => navigate(ENTERPRISE_PENDINGREQUESTS_ROUTE)}
              className={`edit-profile-btn ${isRequestView && "selected-btn"}`}
              text={"Pending requests"}
            />
            {!isRequestView && pendingRequests.length >= 1 && (
              <span className="noti-icon" />
            )}
          </div>
          <ButtonComponent
            onClick={addMemberModalPreviewHandler}
            className={`edit-profile-btn ${
              addMemberModalPreview && "selected-btn"
            }`}
            text={"Add Team Members"}
          />
        </div>
      </div>
      <div className="card">
        {isRequestView ? (
          <EnterpriseRequests
            addresses={addresses}
            pendingRequests={pendingRequests}
            isLoading={isLoading}
          />
        ) : (
          <EnterpriseMembers
            addresses={addresses}
            isMemberAdded={isMemberAdded}
          />
        )}
      </div>
      <AddTeamMembersModal
        addresses={addresses}
        modalPreview={addMemberModalPreview}
        handleClose={addMemberModalPreviewHandler}
        handleSuccess={isMemberAddedHandler}
      />
    </section>
  );
}

export default EnterpriseTeamManagement;
