import React, { useState, useEffect, useMemo } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { getTransactionMembersRequest } from "../../../../../../../redux/slicers/transactiondetail";
import {
  createLinkRequest,
  updateLinkRequest,
} from "../../../../../../../redux/slicers/transactiondataroom";
import { Form, Input, Modal } from "antd";
import {
  inputFieldRule,
  toastAlert,
} from "../../../../../../../services/utils";
import {
  ButtonComponent,
  CardLoader,
  CustomSelectDropdown,
  ProfileImage,
} from "../../../../../../../components";
import "./styles.scss";
import { setUserDetailDrawer } from "../../../../../../../redux/slicers/general";

function UploadLinkModal({
  handleClose,
  preview,
  transactionslug,
  isEdit,
  data,
  handleSuccess = () => {},
}) {
  //STATES
  const [selectedParties, setselectedParties] = useState([]);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [isDrodownVisible, setDrodownVisible] = useState(false);

  // REDUX DATA
  const { members, data: transactiondetail } = useSelector(
    (state) => state.transactiondetail
  );
  const { data: userdetail } = useSelector((state) => state.user);

  // CUSTOM DISPATCH
  const [createLink, createLoading] = useCustomDispatch(createLinkRequest);
  const [updateLink, updateLoading] = useCustomDispatch(updateLinkRequest);
  const [getTransactionMembers, transactionMemberLoader] = useCustomDispatch(
    getTransactionMembersRequest
  );

  //CONST VALS
  const [form] = Form.useForm();
  const isLoading = createLoading || updateLoading;
  const transactionmembers = useMemo(
    () => members?.filter((obj) => obj.id !== userdetail.user_id),
    [members]
  );
  const dispatch = useDispatch();

  // HELPERS
  const getTransactionMembersHelper = () => {
    getTransactionMembers({
      pathParams: transactiondetail?.id,
      queryParams: { isActive: true },
    });
  };
  const requestHelper = (request, payload, pathParams) => {
    request({
      payload,
      pathParams,
      logic({ message }) {
        toastAlert(message);
        closeHandler();
        handleSuccess();
      },
    });
  };

  // HOOKS
  useEffect(() => {
    preview && getTransactionMembersHelper();
  }, [preview]);

  useEffect(() => {
    if (isEdit && preview) {
      const visibilities = data.visibilities.map((item) => {
        return item.id;
      });
      form.setFieldsValue({
        name: data.name,
        url: data.url,
      });
      setselectedParties(visibilities);
    }
  }, [isEdit, preview]);

  //HANDLERS
  const closeHandler = () => {
    handleClose();
    form.resetFields();
    setselectedParties([]);
    setButtonDisabled(true);
    setDrodownVisible(false);
  };
  const drodownVisibleHandler = () => {
    setDrodownVisible(!isDrodownVisible);
  };
  const setButtonDisabledHandler = () => {
    setButtonDisabled(false);
  };
  const handleSelectMemberToggle = (id) => {
    setButtonDisabledHandler();
    const isExist = selectedParties.some((x) => x === id);
    if (isExist) {
      const tempdata = [...selectedParties].filter((x) => x !== id);
      setselectedParties(tempdata);
      return;
    }
    setselectedParties([...selectedParties, id]);
  };

  const handleSelectAll = (temp) => {
    setButtonDisabledHandler();
    const tempData = [];
    transactionmembers.forEach((element) => {
      tempData.push(element.id);
    });
    if (temp) {
      return tempData;
    }
    setselectedParties(tempData);
    setDrodownVisible(false);
  };

  const handleFormFinish = (values) => {
    values["visibilities"] =
      selectedParties.length > 0 ? selectedParties : handleSelectAll(true);
    values["isVisibleToAll"] =
      values["visibilities"].length === transactionmembers.length;
    values["transactionSlug"] = transactionslug;
    if (isEdit) {
      requestHelper(updateLink, values, data.slug);
    } else {
      requestHelper(createLink, values);
    }
  };

  //CUSTOM COMPONENTS
  const partyMemberItem = (data, index) => {
    const userdetail = transactionmembers.find((x) => x?.id === data);
    return (
      <div className="member-box" key={index}>
        <ProfileImage
          username={userdetail?.username}
          color={userdetail?.profilebg}
          image={userdetail?.image}
          isBlocked={!userdetail?.isActive}
          onClick={() => {
            dispatch(
              setUserDetailDrawer({
                visibility: true,
                id: userdetail?.id,
              })
            );
          }}
          size={10}
        />
        <span className="user-detail">
          <b>{userdetail?.username}</b>
        </span>
        <span
          className="close-btn"
          onClick={() => {
            handleSelectMemberToggle(data);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>
    );
  };

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={490}
      className="upload-link-modal"
    >
      {transactionMemberLoader ? (
        <CardLoader />
      ) : (
        <div className="modal-wrapper">
          <div className="modal-body">
            <div className="close-btn" onClick={closeHandler}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <div className="title">
              <h3>{isEdit ? "Edit" : "Upload"} Link</h3>
              <p>{isEdit ? "Edit" : "Add"} Details Below</p>
            </div>
            <Form
              form={form}
              className="form"
              onFinish={handleFormFinish}
              onFieldsChange={setButtonDisabledHandler}
            >
              <Form.Item
                name={"name"}
                rules={inputFieldRule({
                  name: "Name",
                  isMax: true,
                  max: 80,
                })}
              >
                <Input placeholder="Link Name" autoFocus />
              </Form.Item>
              <Form.Item
                name={"url"}
                rules={inputFieldRule({
                  name: "URL",
                  isType: true,
                  type: "url",
                  isWhiteSpace: false,
                })}
              >
                <Input type="url" placeholder="URL" />
              </Form.Item>
              {selectedParties.length >= 1 && (
                <div className="selected-partymembers">
                  {selectedParties.map((item, index) =>
                    partyMemberItem(item, index)
                  )}
                </div>
              )}
              <CustomSelectDropdown
                options={transactionmembers}
                visibleToggle={drodownVisibleHandler}
                visible={isDrodownVisible}
                selectedUsers={selectedParties}
                handleSelectAll={() => handleSelectAll(false)}
                handleUserToggle={handleSelectMemberToggle}
              />
              <div className="create-button">
                <Form.Item>
                  <ButtonComponent
                    disabled={isButtonDisabled}
                    isLoading={isLoading}
                    text={isEdit ? "Update" : "Upload"}
                  />
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default UploadLinkModal;
