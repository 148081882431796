import React, { useState, useEffect, useMemo } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import {
  DATA_PER_PAGE_LIMIT,
  TRANSACTION_ROLE_TYPE,
  DATE_FORMAT5,
} from "../../../../../constants";
import { getCompaniesListRequest } from "../../../../../redux/slicers/company";
import {
  getTransactionsAdvisoryRequest,
  getTransactionsPartiesRequest,
} from "../../../../../redux/slicers/transactions";
import {
  getTransactionPartiesRequest,
  getTransactionEnterprisesRequest,
  invitePartyRequest,
  getWorkingGroupsRequest,
} from "../../../../../redux/slicers/transactiondetail";
import { Tabs } from "../../../../../components";
import { useCustomDispatch } from "../../../../../helpers/useCustomDispatch";
import { CompanyTab, IndividualTab } from "./tabs";
import "./styles.scss";
import {
  toastAlert,
  getFormattedDateTime,
} from "../../../../../services/utils";
import { useParams } from "react-router-dom";

const TAB_BAR_LIST = [
  { text: "Add Company", id: 1 },
  { text: "Add Individual", id: 2 },
];

const InviteModal = ({
  modalVisible,
  setModalVisible,
  lastModified,
  isRoom,
  roomslug,
}) => {
  // STATES
  const [selectedTab, setSelectedTab] = useState(TAB_BAR_LIST[0].id);
  const [selectedType, setSelectedType] = useState(null);
  const [advisoryOffset, setadvisoryOffset] = useState(0);
  const [advisoryTotal, setadvisoryTotal] = useState(0);
  const [advisorySearchText, setAdvisorySearchText] = useState("");
  const [partySearchText, setPartySearchText] = useState("");
  const [partyOffset, setpartyOffset] = useState(0);
  const [partyTotal, setpartyTotal] = useState(0);
  const [companySearchText, setCompanySearchText] = useState("");
  const [transactionCompanySearchText, setTransactionCompanySearchText] =
    useState("");

  // REDUX DATA
  const { companiesList } = useSelector((state) => state.company);
  const { parties, advisories } = useSelector((state) => state.transactions);
  const { data, transactionparties, transactionenterprises } = useSelector(
    (state) => state.transactiondetail
  );
  const user = useSelector((state) => state.user.data);
  // CONST VALS
  const companies = useMemo(
    () => companiesList?.filter((obj) => obj.name !== user?.companyName),
    [companiesList]
  );
  const { id } = useParams();

  // CUSTOM DISPATCH
  const [getWorkingGroups, workingGroupsLoader] = useCustomDispatch(
    getWorkingGroupsRequest
  );

  const [getCompanies] = useCustomDispatch(getCompaniesListRequest);

  // *** for getting all companies of transaction
  const [getTransactionCompanies] = useCustomDispatch(
    getTransactionEnterprisesRequest
  );

  const [getAdvisory, isAdvisoryLoading] = useCustomDispatch(
    getTransactionsAdvisoryRequest
  );

  const [getParties, isPartyLoading] = useCustomDispatch(
    getTransactionsPartiesRequest
  );

  const [inviteParty, isLoading] = useCustomDispatch(invitePartyRequest);

  // *** for getting companies of party role in transaction
  const [getTransactionParties] = useCustomDispatch(
    getTransactionPartiesRequest
  );

  // HELPERS
  const getCompaniesHelper = (isSearched) => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: 0,
    };
    if (isSearched) queryParams["search"] = companySearchText;
    getCompanies({ queryParams });
  };

  const getTransactionCompaniesHelper = (isSearched) => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: 0,
    };
    if (isSearched) queryParams["search"] = transactionCompanySearchText;
    getTransactionCompanies({
      pathParams: data.id,
      queryParams,
    });
  };

  const getAdvisoriesHelper = () => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: 0,
      status: true,
      transactionSlug: id,
    };
    getAdvisory({
      queryParams,
      logic({ total }) {
        setadvisoryTotal(total);
      },
    });
  };

  const getPartiesHelper = () => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: 0,
      status: true,
      transactionSlug: id,
    };

    getParties({
      queryParams,
      logic({ total }) {
        setpartyTotal(total);
      },
    });
  };

  const getWorkingGroupsHelper = () => {
    getWorkingGroups({
      pathParams: data?.id,
      queryParams: { room: isRoom ? roomslug : null },
    });
  };

  // HOOKS
  useEffect(() => {
    modalVisible && getCompaniesHelper(companySearchText !== "");
  }, [companySearchText, modalVisible]);

  useEffect(() => {
    modalVisible && data.id;
    getTransactionCompaniesHelper(transactionCompanySearchText !== "");
  }, [transactionCompanySearchText, modalVisible, data]);

  useEffect(() => {
    modalVisible && getAdvisoriesHelper();
  }, [advisoryOffset, modalVisible]);

  useEffect(() => {
    modalVisible && getPartiesHelper();
  }, [partyOffset, modalVisible]);

  useEffect(() => {
    modalVisible && data.id && getTransactionParties({ pathParams: data.id });
  }, [modalVisible, data]);

  // HANDLERS
  const handleModalClose = () => {
    setModalVisible(false);
  };
  const setCompanySearchTextHandler = (text) => {
    setCompanySearchText(text);
  };

  const setadvisoryOffsetHandler = () => {
    setadvisoryOffset(advisoryOffset + DATA_PER_PAGE_LIMIT);
  };
  const setpartyOffsetHandler = () => {
    setpartyOffset(partyOffset + DATA_PER_PAGE_LIMIT);
  };

  const setTransactionCompanySearchTextHandler = (text) => {
    setTransactionCompanySearchText(text);
  };
  const setRoleSearchTextHandler = (text) => {
    selectedType == TRANSACTION_ROLE_TYPE[0].value
      ? setPartySearchText(text)
      : setAdvisorySearchText(text);
  };
  const handleSubmit = (payload) => {
    payload["transactionId"] = data?.id;
    payload["isIndividual"] = selectedTab !== TAB_BAR_LIST[0].id;
    inviteParty({
      payload,
      logic(res) {
        toastAlert(res.message);
        handleModalClose();
        getWorkingGroupsHelper();
      },
    });
  };

  return (
    <Modal
      destroyOnClose
      visible={modalVisible}
      centered
      footer={null}
      getContainer={false}
      width={523}
      className="invite-modal"
    >
      <div className="close-btn" onClick={() => handleModalClose()}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <h2 className="heading">Invite</h2>
      <p className="last-modified-text">
        {lastModified &&
          `Last Modified on
              ${getFormattedDateTime(lastModified, DATE_FORMAT5)}`}
      </p>
      <Tabs
        tabStyle={{ width: "50%" }}
        buttonsList={TAB_BAR_LIST}
        setState={setSelectedTab}
        state={selectedTab}
      />
      <div className="invite-form-wrapper">
        {selectedTab == 1 ? (
          <CompanyTab
            companies={companies}
            transactionparties={transactionparties}
            parties={parties}
            partyTotal={partyTotal}
            setPartyOffset={setpartyOffsetHandler}
            isPartyLoading={isPartyLoading}
            advisories={advisories}
            advisoryTotal={advisoryTotal}
            setAdvisoryOffset={setadvisoryOffsetHandler}
            isAdvisoryLoading={isAdvisoryLoading}
            setCompanySearchTextHandler={setCompanySearchTextHandler}
            setRoleSearchTextHandler={setRoleSearchTextHandler}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            onSubmit={handleSubmit}
            isLoading={isLoading}
          />
        ) : (
          <IndividualTab
            setCompanySearchTextHandler={setTransactionCompanySearchTextHandler}
            companies={transactionenterprises}
            onSubmit={handleSubmit}
            isLoading={isLoading}
            slug={data.slug}
          />
        )}
      </div>
    </Modal>
  );
};

export default InviteModal;
