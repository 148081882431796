import React, { useState, useEffect, useMemo } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Modal } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import {
  createDataRoomRequest,
  createFolderRequest,
  getRoomVisibilitiesRequest,
  updateDataRoomRequest,
  updateFolderRequest,
} from "../../../../../../../redux/slicers/transactiondataroom";
import {
  inputFieldRule,
  replaceValInString,
  toastAlert,
} from "../../../../../../../services/utils";
import {
  ButtonComponent,
  CustomSelectDropdown,
  ProfileImage,
} from "../../../../../../../components";
import { TRANSACTION_DATAROOM_DETAIL_ROUTE } from "../../../../../../../constants";
import "./styles.scss";
import { setUserDetailDrawer } from "../../../../../../../redux/slicers/general";

function CreateDataroomModal({
  handleClose,
  preview,
  transactionslug,
  isEdit,
  roomId,
  title,
  data,
  placeholder = "Data Room Name",
  handleSuccess = () => {},
  isFolder,
  isParentFolder,
  isDetail = true,
}) {
  //STATES
  const [selectedParties, setselectedParties] = useState([]);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [isDrodownVisible, setDrodownVisible] = useState(false);

  // REDUX DATA
  const { currentVisibility } = useSelector(
    (state) => state.transactiondataroom
  );
  const { data: userdetail } = useSelector((state) => state.user);

  // CUSTOM DISPATCH
  const [createRoom, createLoading] = useCustomDispatch(createDataRoomRequest);
  const [createFolder, folderLoading] = useCustomDispatch(createFolderRequest);
  const [updateRoom, updateLoading] = useCustomDispatch(updateDataRoomRequest);
  const [updateFolder, updateFolderLoading] =
    useCustomDispatch(updateFolderRequest);
  const [getVisibility, visibilitiesLoader] = useCustomDispatch(
    getRoomVisibilitiesRequest
  );

  //CONST VALS
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id, room, tab, dataid, folder } = useParams();
  const isLoading =
    createLoading || updateLoading || folderLoading || updateFolderLoading;
  const transactionmembers = useMemo(
    () => currentVisibility?.filter((obj) => obj.id !== userdetail.user_id),
    [currentVisibility]
  );
  const dispatch = useDispatch();

  // HELPERS
  const requestHelper = (request, payload, pathParams, queryParams) => {
    request({
      payload,
      pathParams,
      queryParams,
      otherkeys: { isDetail: isDetail },
      logic({ data, message }) {
        toastAlert(message);
        closeHandler();
        !isFolder && isDetail && navigateHandler(data.slug);
        handleSuccess();
      },
    });
  };
  const getVisibilitiesHelper = () => {
    if (isEdit) {
    }
    const queryParams = {
      isTransaction: dataid ? false : true,
      isBreakOutRoom: false,
      isDataRoom: dataid && folder === undefined ? true : false,
      isDataRoomFolder: folder ? true : false,
      isMilestone: false,
      isActive: true,
    };
    const pathParams = folder ?? dataid ?? id;
    getVisibility({
      queryParams,
      pathParams,
    });
  };
  // HOOKS
  useEffect(() => {
    if (isEdit && preview) {
      const visibilities = data.visibilities.map((item) => {
        return item.id;
      });
      form.setFieldsValue({
        name: data.name,
      });
      setselectedParties(visibilities);
    }
    if (preview) getVisibilitiesHelper();
  }, [isEdit, preview]);

  //HANDLERS
  const navigateHandler = (slug) => {
    const vals = {
      ":dataid": slug,
      ":id": id,
      ":tab": tab,
      ":room": room,
    };
    navigate(replaceValInString(TRANSACTION_DATAROOM_DETAIL_ROUTE, vals));
  };
  const closeHandler = () => {
    handleClose();
    form.resetFields();
    setselectedParties([]);
    setButtonDisabled(true);
    setDrodownVisible(false);
  };

  const drodownVisibleHandler = () => {
    setDrodownVisible(!isDrodownVisible);
  };

  const setButtonDisabledHandler = () => {
    setButtonDisabled(false);
  };

  const handleSelectMemberToggle = (id) => {
    setButtonDisabledHandler();
    const isExist = selectedParties.some((x) => x === id);
    if (isExist) {
      const tempdata = [...selectedParties].filter((x) => x !== id);
      setselectedParties(tempdata);
      return;
    }
    setselectedParties([...selectedParties, id]);
  };

  const handleSelectAll = (temp) => {
    setButtonDisabledHandler();
    const tempData = [];
    transactionmembers.forEach((element) => {
      tempData.push(element.id);
    });
    if (temp) {
      return tempData;
    }
    setselectedParties(tempData);
    setDrodownVisible(false);
  };

  const handleFormFinish = (values) => {
    values["visibilities"] =
      selectedParties.length > 0 ? selectedParties : handleSelectAll(true);
    values["isVisibleToAll"] =
      values["visibilities"].length === transactionmembers.length;

    isFolder
      ? (values[isParentFolder ? "parentId" : "dataRoomId"] = roomId)
      : (values["transactionSlug"] = transactionslug);
    if (isEdit) {
      requestHelper(isFolder ? updateFolder : updateRoom, values, data.slug);
    } else {
      requestHelper(isFolder ? createFolder : createRoom, values);
    }
  };

  //CUSTOM COMPONENTS
  const partyMemberItem = (data, index) => {
    const userdetail = transactionmembers.find((x) => x.id === data);
    return (
      <div className="member-box" key={index}>
        <ProfileImage
          username={userdetail?.username}
          image={userdetail?.image}
          color={userdetail?.profilebg}
          size={10}
          onClick={() => {
            dispatch(
              setUserDetailDrawer({
                visibility: true,
                id: userdetail.id,
              })
            );
          }}
        />
        <span className="user-detail">
          <b>{userdetail?.username}</b>
        </span>
        <span
          className="close-btn"
          onClick={() => {
            handleSelectMemberToggle(data);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>
    );
  };

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      getContainer={false}
      footer={null}
      width={490}
      className="create-room-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>
              {isEdit ? "Edit" : "Create"} {title}
            </h3>
            <p>{isEdit ? "Edit" : "Add"} Details Below</p>
          </div>
          <Form
            form={form}
            className="form"
            onFinish={handleFormFinish}
            onFieldsChange={setButtonDisabledHandler}
          >
            <Form.Item
              name={"name"}
              rules={inputFieldRule({
                name: "Name",
                isMax: true,
                max: 100,
              })}
            >
              <Input placeholder={placeholder} autoFocus />
            </Form.Item>
            {!isEdit && (
              <>
                <CustomSelectDropdown
                  options={transactionmembers}
                  visibleToggle={drodownVisibleHandler}
                  visible={isDrodownVisible}
                  selectedUsers={selectedParties}
                  handleSelectAll={() => handleSelectAll(false)}
                  handleUserToggle={handleSelectMemberToggle}
                />
                {selectedParties.length >= 1 &&
                  selectedParties.length < transactionmembers?.length && (
                    <div className="selected-partymembers">
                      {selectedParties.map((item, index) =>
                        partyMemberItem(item, index)
                      )}
                    </div>
                  )}
              </>
            )}
            <div className="create-button">
              <Form.Item>
                <ButtonComponent
                  disabled={isButtonDisabled}
                  isLoading={isLoading}
                  text={isEdit ? "Update" : "Create"}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
}

export default CreateDataroomModal;
