import qs from "qs";
import ApiHandler from "../services/ApiHandler";
import { getChatAccessToken, getCurrentAccessToken } from "../services/utils";

export const API_TIMEOUT = 30000;
export const ABORT_REQUEST_MESSAGE = "Network failed. Aborted request.";

// export const BASE_URL = process.env.REACT_APP_BACKEND_NGROK_URL;
// export const BASE_URL = process.env.REACT_APP_BACKEND_DEV_URL;
export const BASE_URL = process.env.REACT_APP_BACKEND_PROD_URL;
// export const BASE_URL = process.env.REACT_APP_BACKEND_STAGING_URL;
// export const BASE_URL = "https://16f7-39-51-71-134.ngrok-free.app/";
export const CHAT_URL = process.env.REACT_APP_CHAT_URL;
// export const CHAT_URL = "https://8f3e-180-178-138-114.ngrok-free.app/";

const AUTH_PREFIX = `api/auth/`;
const API_PREFIX = `api/`;

export const ERROR_SOMETHING_WENT_WRONG =
  "Something went wrong, Please try again later";
export const ERROR_API_NOT_FOUND = "Api not found, Please try again later";

export const ERROR_NETWORK_NOT_AVAILABLE =
  "Please check your internet connection.";

export const ERROR_ACCOUNT_BLOCKED =
  "Either your account is blocked or deleted";

export const ERROR_TOKEN_EXPIRE = "Session Expired, Please login again!";

export const REQUEST_TYPE = {
  GET: "get",
  POST: "post",
  DELETE: "delete",
  PUT: "put",
  PATCH: "patch",
};

// GENERAL
export const UPLOAD_IMAGE = {
  route: `${API_PREFIX}auth/uploadfile`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const GET_REVIEWS = {
  route: `${API_PREFIX}web/review`,
  access_token_required: false,
  type: REQUEST_TYPE.GET,
};

export const GET_ACTIVITY_LOGS = {
  route: `${API_PREFIX}users/activityLogs/all`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_DASHBOARD_ACTIVITY_LOGS = {
  route: `${API_PREFIX}users/activityLogs/all/dash`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_NOTIFCATIONS = {
  route: `${API_PREFIX}users/notification`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const OPEN_NOTIFCATION = {
  route: `${API_PREFIX}users/notification/open`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const READ_NOTIFCATION = {
  route: `${API_PREFIX}users/notification/read`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const READ_ALL_NOTIFCATION = {
  route: `${API_PREFIX}users/notification/all/read`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};
export const GET_USER_DETAIL = {
  route: `${API_PREFIX}users/profile`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_COMPANY_DETAIL = {
  route: `${API_PREFIX}company`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_PENNDING_REQUEST_DETAIL = {
  route: `${API_PREFIX}users/transaction/request`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_APPROVAL_REQUEST_DETAIL = {
  route: `${API_PREFIX}users/transaction/req`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_DASHBOARD_STATS = {
  route: `${API_PREFIX}users/transaction/getTransactionCount`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CONTACT_FORM_SUBMISSION = {
  route: `${API_PREFIX}web/contact-us`,
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};

export const TRANSACTION_ADMIN_RESPONSE = {
  route: `${API_PREFIX}users/transaction/approve`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const PARTY_ADMIN_RESPONSE = {
  route: `${API_PREFIX}users/transaction/approveByParty`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

// AUTH
export const ADD_DEVICE_TOKEN = {
  route: `${API_PREFIX}users/addDevicetoken`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};
export const SIGN_UP = {
  route: `${AUTH_PREFIX}signup`,
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};

export const SIGN_UP_OTP = {
  route: `${AUTH_PREFIX}resentOtp`,
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};
export const CONFIRM_OTP = {
  route: `${AUTH_PREFIX}confirmOtp`,
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};

export const SEND_OTP = {
  route: `${AUTH_PREFIX}sendOtp`,
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};
export const VERIFY_OTP = {
  route: `${AUTH_PREFIX}verifyOtp`,
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};
export const RESET_PASSWORD = {
  route: `${AUTH_PREFIX}resetPassword`,
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};
export const CHANGE_PASSWORD = {
  route: `${AUTH_PREFIX}changePassword`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};
export const VERIFY_USER = {
  route: `${AUTH_PREFIX}verifyUser`,
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};

export const INVITATION = {
  route: `${AUTH_PREFIX}invite`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};
export const SIGN_IN = {
  route: `${AUTH_PREFIX}signin`,
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};
export const LOGOUT = {
  route: `${AUTH_PREFIX}logout`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

// USER

export const GET_PROFILE_DETAIL = {
  route: `${API_PREFIX}users/profile`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const UPDATE_PROFILE = {
  route: `${API_PREFIX}users`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const UPDATE_2FA = {
  route: `${API_PREFIX}users/profile/twofactor`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const UPDATE_PROFLE_VISIBILITY = {
  route: `${API_PREFIX}users/profile/privacy`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const CREATE_ADDRESS = {
  route: `${API_PREFIX}company/address`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_ADDRESS = {
  route: `${API_PREFIX}company/address`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const DELETE_ADDRESS = {
  route: `${API_PREFIX}company/address`,
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const SEND_PHONE_OTP = {
  route: `${API_PREFIX}users/sendOtp`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const CONFIRM_PHONE_OTP = {
  route: `${API_PREFIX}users/confirmOtp`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};
export const CHECK_CURRENT_PASSWORD = {
  route: `${API_PREFIX}users/verifyPassword`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

// COMPANY
export const GET_COMPANIES_LIST = {
  route: `${API_PREFIX}company`,
  access_token_required: false,
  type: REQUEST_TYPE.GET,
};

export const GET_ADDRESSES = {
  route: `${API_PREFIX}company/address/all`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_TEAM_MEMBERS = {
  route: `${API_PREFIX}team/members`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_PENDING_REQUESTS = {
  route: `${API_PREFIX}team/members/request`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const ADD_TEAM_MEMBER = {
  route: `${API_PREFIX}team/members/invite`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_TEAM_MEMBER = {
  route: `${API_PREFIX}team/members`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const APPROVE_TEAM_MEMBER = {
  route: `${API_PREFIX}team/members/request`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const REMOVE_TEAM_MEMBER = {
  route: `${API_PREFIX}team/members/request`,
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_INVITATION_DETAIL = {
  route: `${API_PREFIX}team/members/invite`,
  access_token_required: false,
  type: REQUEST_TYPE.GET,
};

export const GET_TRANSACTION_INVITATION_DETAIL = {
  route: `${API_PREFIX}users/transaction/invites`,
  access_token_required: false,
  type: REQUEST_TYPE.GET,
};

export const GET_COMPANY_MEMBERS = {
  route: `${API_PREFIX}users`,
  access_token_required: false,
  type: REQUEST_TYPE.GET,
};

export const GET_LOGGED_IN_USER_COMPANY = {
  route: `${API_PREFIX}users/company`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_COMPANY_ADMIN_LIST = {
  route: `${API_PREFIX}company/admin/all`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const REMOVE_SELF_FROM_ADMIN = {
  route: `${API_PREFIX}team/leave`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const CHECK_LAST_ENTERPRISE_ADMIN = {
  route: `${API_PREFIX}team/check-admin`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

// TRANSACTIONS
export const GET_TRANSACTIONS = {
  route: `${API_PREFIX}users/transaction`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_TRANSACTION = {
  route: `${API_PREFIX}users/transaction`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_TRANSACTION = {
  route: `${API_PREFIX}users/transaction`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const GET_TRANSACTIONS_TYPE = {
  route: `${API_PREFIX}users/transactionType`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};
export const GET_TRANSACTION_QUESTION = {
  route: `${API_PREFIX}users/question`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_TRANSACTIONS_PARTIES = {
  route: `${API_PREFIX}users/party`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_TRANSACTIONS_ADVISORIES = {
  route: `${API_PREFIX}users/advisor`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CHECK_TRANSACTION_EXISTENCE = {
  route: `${API_PREFIX}users/transaction/check-transaction`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CHANGE_TRANSACTION_STATUS = {
  route: `${API_PREFIX}users/transaction/status`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

//TRANSACTION DETAILS

export const GET_TRANSACTION_DETAILS = {
  route: `${API_PREFIX}users/transaction`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_TRANSACTION_PARTIES = {
  route: `${API_PREFIX}users/transaction/party`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const INVITE_PARTY_TRANSACTION = {
  route: `${API_PREFIX}users/transaction/invites`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const TRANSACTION_INVITATION_RESPONSE = {
  route: `${API_PREFIX}users/transaction/invites`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const GET_WORKING_GROUPS = {
  route: `${API_PREFIX}users/transaction/companies/member`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_BREAKOUT_ROOM = {
  route: `${API_PREFIX}users/room`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_BREAKOUT_ROOM = {
  route: `${API_PREFIX}users/room`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const GET_TRANSACTION_ROOMS_LIST = {
  route: `${API_PREFIX}users/room/getRooms`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_ROOMS_WITH_MEMBERS = {
  route: `${API_PREFIX}users/room/get/all`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_BREAKOUT_ROOMS_LIST = {
  route: `${API_PREFIX}users/room`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_TRANSACTION_ENTERPRISES = {
  route: `${API_PREFIX}users/transaction/companies`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_TRANSACTION_ENTERPRISE_MEMBERS = {
  route: `${API_PREFIX}users/transaction/company/member`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const LEAVE_ROOM = {
  route: `${API_PREFIX}users/room/leave`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const REMOVE_PARTICIPANT = {
  route: `${API_PREFIX}users/room/member`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const CHANGE_ADMIN = {
  route: `${API_PREFIX}users/room/member`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const MAKE_BREAKOUT_ROOM_ADMIN = {
  route: `${API_PREFIX}users/room/ad`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const REMOVE_BREAKOUT_ROOM_ADMIN = {
  route: `${API_PREFIX}users/room/ad`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const CHECK_ADMIN = {
  route: `${API_PREFIX}users/room/member`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CHECK_TRANSACTION_ADMIN = {
  route: `${API_PREFIX}users/transaction/check`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const ADD_MEMBER_TO_ROOM = {
  route: `${API_PREFIX}users/room/member`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const GET_ROOM_MEMBERS = {
  route: `${API_PREFIX}users/room/member/list`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_ALL_ROOM_MEMBERS = {
  route: `${API_PREFIX}users/room/getAll/members`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_ALL_TRANSACTION_ADMINS = {
  route: `${API_PREFIX}users/transaction/admin/all`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CHANGE_TRANSACTION_ADMIN_STATUS = {
  route: `${API_PREFIX}users/transaction/ad`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const REJECT_REMOVAL = {
  route: `${API_PREFIX}users/room/remove/res`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const ACCEPT_REMOVAL = {
  route: `${API_PREFIX}users/room/remove/ac`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const RESPOND_REJOIN = {
  route: `${API_PREFIX}users/room/admin/res`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const USER_COMPANY_MEMBERS = {
  route: `${API_PREFIX}users/transaction/company/member`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_PERMISSIONS = {
  route: `${API_PREFIX}users/transaction/permission`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const UPDATE_PERMISSIONS = {
  route: `${API_PREFIX}users/transaction/permission`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const GET_REQUESTS = {
  route: `${API_PREFIX}users/transaction/approveListOfUser`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

//CHECKLIST

export const GET_CHECKLIST_LISTING = {
  route: `${API_PREFIX}users/checklist/all`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_TRANSACTION_CHECKLIST = {
  route: `${API_PREFIX}users/checklist/all/list`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_CHECKLIST_TYPES = {
  route: `${API_PREFIX}users/checklist/type/all`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_CHECKLIST_DETAILS = {
  route: `${API_PREFIX}users/checklist`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_CHECKLIST = {
  route: `${API_PREFIX}users/checklist`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_CHECKLIST = {
  route: `${API_PREFIX}users/checklist`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const DELETE_CHECKLIST = {
  route: `${API_PREFIX}users/checklist`,
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const RESTORE_CHECKLIST = {
  route: `${API_PREFIX}users/checklist/restoreChecklist`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const ADD_DOC_TO_CHECKLIST = {
  route: `${API_PREFIX}users/checklist/addDocument`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const REMOVE_CHECKLIST_DOC = {
  route: `${API_PREFIX}users/checklist/removeDocument`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const ADD_CHECKLIST_VISIBILITY = {
  route: `${API_PREFIX}users/checklist/addVisibility`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const REMOVE_CHECKLIST_VISIBILITY = {
  route: `${API_PREFIX}users/checklist/removeVisibility`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const ADD_CHECKLIST_RESPONSIBILITY = {
  route: `${API_PREFIX}users/checklist/addResponsibility`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const REMOVE_CHECKLIST_RESPONSIBILITY = {
  route: `${API_PREFIX}users/checklist/removeResponsibility`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const CHANGE_CHECKLIST_STATUS = {
  route: `${API_PREFIX}users/checklist/status`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const GET_CHECKLIST_SUGGESTIONS = {
  route: `${API_PREFIX}users/checklist/suggestions`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

//MILESTONES

export const GET_MILESTONES = {
  route: `${API_PREFIX}users/milestone`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_MILESTONE_DETAIL = {
  route: `${API_PREFIX}users/milestone`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_MILESTONE = {
  route: `${API_PREFIX}users/milestone/create`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_MILESTONE = {
  route: `${API_PREFIX}users/milestone/updateMilestone`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const CHANGE_MILESTONE_STATUS = {
  route: `${API_PREFIX}users/milestone/status`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const ASSIGN_MILESTONE_PARTY = {
  route: `${API_PREFIX}users/milestone/addResponsibility`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const ASSIGN_MILESTONE_VISIBILITY = {
  route: `${API_PREFIX}users/milestone/addVisibility`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const REMOVE_MILESTONE_PARTY = {
  route: `${API_PREFIX}users/milestone/removeResponsibility`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const REMOVE_MILESTONE_VISIBILITY = {
  route: `${API_PREFIX}users/milestone/removeVisibility`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const DELETE_MILESTONE = {
  route: `${API_PREFIX}users/milestone`,
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

//DATA ROOM

export const GET_DATAROOMS = {
  route: `${API_PREFIX}users/dataroom/all`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_DATAROOM_DETAIL = {
  route: `${API_PREFIX}users/dataroom`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_DATAROOM = {
  route: `${API_PREFIX}users/dataroom`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_DATAROOM = {
  route: `${API_PREFIX}users/dataroom`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const DELETE_DATAROOM = {
  route: `${API_PREFIX}users/dataroom`,
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_DATAROOM_NOTES = {
  route: `${API_PREFIX}users/dataroom/notes`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_DATAROOM_NOTE = {
  route: `${API_PREFIX}users/dataroom/notes`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_DATAROOM_NOTE = {
  route: `${API_PREFIX}users/dataroom/notes`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const DELETE_DATAROOM_NOTE = {
  route: `${API_PREFIX}users/dataroom/notes`,
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_ALL_DATAROOMS_FOLDERS = {
  route: `${API_PREFIX}users/folder/all/path`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};
export const GET_FOLDERS = {
  route: `${API_PREFIX}users/folder/all`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_FOLDER_DETAIL = {
  route: `${API_PREFIX}users/folder`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_FOLDER = {
  route: `${API_PREFIX}users/folder`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_FOLDER = {
  route: `${API_PREFIX}users/folder`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const MOVE_FOLDER = {
  route: `${API_PREFIX}users/folder`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const DELETE_FOLDER = {
  route: `${API_PREFIX}users/folder`,
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_DOCUMENTS = {
  route: `${API_PREFIX}users/dataroom/file/all`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_DOCUMENT = {
  route: `${API_PREFIX}users/dataroom/file`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_DOCUMENT = {
  route: `${API_PREFIX}users/dataroom/file`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const MOVE_DOCUMENT = {
  route: `${API_PREFIX}users/dataroom/file`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const DELETE_DOCUMENT = {
  route: `${API_PREFIX}users/dataroom/file`,
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_LINKS = {
  route: `${API_PREFIX}users/transaction/link`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_LINK = {
  route: `${API_PREFIX}users/transaction/link`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_LINK = {
  route: `${API_PREFIX}users/transaction/link`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const DELETE_LINK = {
  route: `${API_PREFIX}users/transaction/link`,
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_NOTES = {
  route: `${API_PREFIX}users/transaction/link/notes/all`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_NOTE = {
  route: `${API_PREFIX}users/transaction/link/notes`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_NOTE = {
  route: `${API_PREFIX}users/transaction/link/notes`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const DELETE_NOTE = {
  route: `${API_PREFIX}users/transaction/link/notes`,
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_VISIBILITIES = {
  route: `${API_PREFIX}users/list`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_REMAINING_VISIBILITIES = {
  route: `${API_PREFIX}users/dataroom/list`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const ADD_VISIBILITIES = {
  route: `${API_PREFIX}users/dataroom/visibility`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const REMOVE_VISIBILITY = {
  route: `${API_PREFIX}users/dataroom/visibility`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const GET_REMAINING_FOLDER_VISIBILITIES = {
  route: `${API_PREFIX}users/folder/list`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const ADD_FOLDER_VISIBILITIES = {
  route: `${API_PREFIX}users/folder/visibility`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const REMOVE_FOLDER_VISIBILITY = {
  route: `${API_PREFIX}users/folder/visibility`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const MAKE_DATAROOM_ADMIN = {
  route: `${API_PREFIX}users/dataroom/ad`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const CHECK_LAST_DATAROOM_ADMIN = {
  route: `${API_PREFIX}users/dataroom/check`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const LEAVE_DATAROOM = {
  route: `${API_PREFIX}users/dataroom/leave`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const REMOVE_DATAROOM_ADMIN = {
  route: `${API_PREFIX}users/dataroom/ad`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const GET_DATAROOM_PERMISSIONS = {
  route: `${API_PREFIX}users/permission/userPermissions`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_USER_PERMISSIONS = {
  route: `${API_PREFIX}users/dataroom/dataroomUser/list`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const UPDATE_DATAROOM_PERMISSION = {
  route: `${API_PREFIX}users/dataroom/updateVisibility`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

//DOCUMENTS
export const GET_DOCUMENTS_LISTING = {
  route: `${API_PREFIX}users/document/all`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_DELETED_DOCUMENTS_LISTING = {
  route: `${API_PREFIX}users/document/all/del`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_DOCUMENT_DETAIL = {
  route: `${API_PREFIX}users/document`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_DOCUMENTS_FILES = {
  route: `${API_PREFIX}users/document`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_DOCUMENTS_FILES_EXHIBITS = {
  route: `${API_PREFIX}users/document/list`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_TRANSACTION_DOCUMENT = {
  route: `${API_PREFIX}users/document`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_TRANSACTION_DOCUMENT = {
  route: `${API_PREFIX}users/document`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const DELETE_TRANSACTION_DOCUMENT = {
  route: `${API_PREFIX}users/document`,
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const CREATE_DOCUMENT_EXHIBIT = {
  route: `${API_PREFIX}users/exhibits`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_DOCUMENT_EXHIBIT = {
  route: `${API_PREFIX}users/exhibits`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const DELETE_DOCUMENT_EXHIBIT = {
  route: `${API_PREFIX}users/exhibits`,
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const CREATE_DOCUMENT_VERSION = {
  route: `${API_PREFIX}users/documents/versions`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_DOCUMENT_VERSION = {
  route: `${API_PREFIX}users/documents/versions`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const DELETE_DOCUMENT_VERSION = {
  route: `${API_PREFIX}users/documents/versions`,
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_ALL_DOCS = {
  route: `${API_PREFIX}users/document/list/all`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};
export const GET_ALL_VERSIONS = {
  route: `${API_PREFIX}users/documents/versions/all`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_ROOM_VERSIONS = {
  route: `${API_PREFIX}users/documents/versions/room`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const SHARE_VERSION = {
  route: `${API_PREFIX}users/documents/versions/share`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const GET_VERSION_HISTORY = {
  route: `${API_PREFIX}users/documents/versions/share/history`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

//RESOURCES

export const GET_RESOURCE_FOLDERS_LIST = {
  route: `${API_PREFIX}users/resource`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_RESOURCE_FILES_LIST = {
  route: `${API_PREFIX}users/resource/file`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_ALL_RESOURCE_FILES = {
  route: `${API_PREFIX}users/resource/file/get/all`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

//CHAT
export const GET_USERS_FOR_CHAT = {
  route: `${API_PREFIX}users/getUserForChat`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const LOAD_CHAT_ROOM = {
  route: `v1/room/chat/load`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const GET_CHAT_LIST = {
  route: `v1/room/list`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_ROOM_MESSAGES = {
  route: `v1/room/chat/messages`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const SEND_MESSAGE = {
  route: `v1/room/message/send-attachment`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const ADD_REACT = {
  route: `v1/send/message/react`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const REMOVE_REACT = {
  route: `v1/send/message/unsend-react`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const GET_CHAT_DETAILS = {
  route: `v1/room/message/list-attachment`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_LINK_PREVIEW = {
  route: `v1/get/link/preview`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const GET_THREADS = {
  route: `v1/room/thread/messages`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_CHAT_GROUP = {
  route: `v1/create/group`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const GET_GROUP_PARTICIPANTS = {
  route: `v1/group/participants`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_REMAINING_USERS_FOR_CHAT = {
  route: `${API_PREFIX}users/getUserForGroupChat`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const ADD_GROUP_MEMBERS = {
  route: `v1/add/group/participants`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const REMOVE_GROUP_MEMBER = {
  route: `v1/remove/group/participants`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const MAKE_GROUP_ADMIN = {
  route: `v1/make/admin/participant`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const REMOVE_GROUP_ADMIN = {
  route: `v1/remove/admin/participant`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const LOAD_TRANSACTION_ROOM = {
  route: `v1/room`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const BLOCK_ROOM = {
  route: `v1/block/chat/participant`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UNBLOCK_ROOM = {
  route: `v1/unblock/chat/participant`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const ARCHIVE_ROOM = {
  route: `v1/room/archived`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UNARCHIVE_ROOM = {
  route: `v1/room/unarchived`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const CHECK_LAST_GROUP_ADMIN = {
  route: `v1/check/admin/participant`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const LEAVE_CHAT = {
  route: `v1/leave/group/participants`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const EDIT_CHAT_GROUP = {
  route: `v1/update/group`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const GET_CHAT_NOTIFICATIONS = {
  route: `v1/notification/settings`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const UPDATE_CHAT_NOTIFICATION = {
  route: `v1/notification/settings`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const REMOVE_CHAT_DEVICE_TOKEN = {
  route: `v1/user/remove/token`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const GET_TRANS_CHATS = {
  route: `${API_PREFIX}users/transaction/chat/rooms`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const callRequest = async ({
  url,
  data = {},
  queryParams,
  pathParams,
  header = {},
  baseURL = BASE_URL,
  cacheBusting = false,
}) => {
  let _header = header;
  if (url.access_token_required) {
    const _access_token =
      baseURL === BASE_URL ? getCurrentAccessToken() : getChatAccessToken();
    if (_access_token) {
      _header = {
        ..._header,
        ...{
          Authorization: `Bearer ${_access_token}`,
        },
        ...{
          "ngrok-skip-browser-warning": true,
        },
      };
    }
  }

  let _url = url.route;
  if (pathParams) {
    _url = `${url.route}/${pathParams}`;
  }
  if (queryParams && !_.isEmpty(queryParams)) {
    _url = `${_url}?${
      queryParams instanceof Object ? qs.stringify(queryParams) : queryParams
    }`;
  }

  if (cacheBusting) _url += `?t${Date.now()}`;

  let response = await ApiHandler(
    url.type,
    _url,
    data,
    _header,
    baseURL,
    url.access_token_required
  );
  response["isAsked"] = response?.requestId ? true : false;
  return response;
};
