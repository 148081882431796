import { AES, enc } from "crypto-js";
import _, {
  cloneDeep,
  every,
  filter,
  find,
  findIndex,
  has,
  includes,
  isEmpty,
  isEqual,
} from "lodash";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import {
  ALERT_POSITIONS,
  ALERT_THEMES,
  ALERT_TIMEOUT,
  ALERT_TYPES,
  DATE_FORMAT1,
  ALLOW_ALPHANUM_REGEX,
  ALLOW_ALPHABETS_REGEX,
  TIME_FORMAT1,
  DATETIME_FORMAT4,
  DATE_FORMAT7,
  CUSTOM_ALL,
} from "../constants";
import { Images } from "../themes";
import DataHandler from "./DataHandler";

// GET CURRENT ACCESS TOKEN FROM USER REDUCER
export const getCurrentAccessToken = () => {
  let token = DataHandler.getStore().getState().user.data.access_token;
  return token;
};

export const getChatAccessToken = () => {
  let token = DataHandler.getStore().getState().user.data.chat_id;
  return token;
};

// GET CURRENT REFRESH TOKEN FROM USER REDUCER
export const getCurrentRefreshToken = () => {
  let token = DataHandler.getStore().getState().user.data.refresh_token;
  return token;
};

// CHECK IF URL IS VALID
export const isValidURL = (url) => {
  const re =
    /^(http|https|fttp):\/\/|[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?$/;
  return re.test(url);
};

// CHECK IF URL IS VALID AND WITH HTTPS SCHEME
export const isValidHttpsURL = (url) => {
  const re =
    /^(https|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
  return re.test(url);
};

export const hideEmail = (email = "tempMail@temp.com") => {
  let temp = email.split("@");
  let emailStart = temp[0]
    .slice(0, temp[0].length / 2)
    .padEnd(temp[0].length, "#");
  let emailEnd = temp[1]
    .slice(temp[1].length / 2)
    .padStart(temp[1].length, "#");
  return emailStart + "@" + emailEnd;
};

// CHECK IF PROVIDED TIME FORMAT IS CORRECT
export const isTimeFormat = (time) => {
  const re =
    /^([1-9]|([012][0-9])|(3[01]))\/([0]{0,1}[1-9]|1[012])\/[0-9]{4} [012]{0,1}[0-9]:[0-6][0-9]$/;
  let bol = re.test(time);
  return bol;
};

// CHECK IF EMAIL IS VALID
export const isEmailValid = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.trim());
};

// CHECK IF PASSWORD LENGTH IS VALID
export const isPasswordValid = (password) => {
  const re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  return re.test(password);
};

// CHECK IF NAME IS VALID
export const isValidName = (name) => {
  return /^[a-zA-Z ]*$/.test(name);
};

// CAPITALIZE FIRST LETTER OF STRING
export const capitalizeFirstLetter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return "";
};

// FORMAT DATE ACCORDING TO PROVIDED FORMAT
export const getFormattedDateTime = (date, format = DATE_FORMAT1) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (date) return moment(date).tz(timezone).format(format);
  return "";
};

// FORMAT DATE ACCORDING TO PROVIDED FORMAT AND RETURN TO DATE OBJECT
export const getDateObjectFromString = (date, format) => {
  if (date) return moment(date, format).toDate();
  return "";
};

// CHECK IF MOBILE NUMBER IS VALID
export const isValidMobileNumber = (str) => {
  if (!str) return false;
  const isnum = /^\d+$/.test(str);

  if (str.length < 15 && str.length > 9 && isnum) {
    return true;
  }
  return false;
};

// CHECK IF MOBILE NUMBER IS OF UK NUMBER FORMAT
export const isValidUKMobileNumber = (str) => {
  if (!str) return false;
  str = str.replace(/ /g, "");
  let mobileNumber = str.replace("+", "");
  if (mobileNumber.charAt(0) == "4" && mobileNumber.charAt(1) == "4") {
    mobileNumber = "0" + mobileNumber.slice(2);
  }
  return /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/.test(
    mobileNumber
  );
};

// CLONE ARRAY
export const cloneDeepItem = (array) => cloneDeep(array);

// FIND OBJECT FROM ARRAY
export const findDataFromArray = (array, mObj) => find(array, mObj);

// CHECK IF ARRAY HAS VALUE
export const isArrayIncludesValue = (array, value) => includes(array, value);

// CHECK IF VALUES ARE EQUAL
export const areValuesEqual = (objA, objB) => isEqual?.(objA, objB);

// CHECK IF VALUE IS EMPTY
export const isEmptyValue = (value) => isEmpty(value);

// EXCLUDE OBJECT FROM ARRAY BY ID
export const excludeIdFromArray = (mArr, id) =>
  filter(mArr, (item) => item.id != id);

// EXCLUDE VALUE FROM ARRAY
export const excludeValueFromArray = (mArr, value) =>
  filter(mArr, (item) => item != value);

// FILTER ARRAY BY FUNCTION
export const filterArray = (array, func) => filter(array, func);

// CHECK IF ARRAY DATA CONTACT ID
export const doesArrayContainsParticularId = (array, mId) => {
  if (find(array, { id: mId })) return true;
  else return false;
};

// CHECK IF STRING HAS ONLY WHITE SPACE
export const isOnlyWhiteSpace = (str) => {
  return !str.trim();
};

// CHECK IF OBJECT HAS PROVIDED KEY
export const hasObjectWithKey = (mObj, key) => has(mObj, key);

// CHECK IF VALUES IS ACCORDING TO FUNCTION CONDITION
export const hasEvery = (mArr, _func) => every(mArr, _func);

// GET OBJECT INDEX IN ARRAY BY ID
export const getIndexOfObjFromArrayByID = (mArr, id) =>
  findIndex(mArr, (item) => item.id == id);

// DELETE OBJECT FROM ARRAY BY ID
export const deleteObjectFromArray = (arr, id) => {
  let arrToReturn = arr.filter((a) => a.id !== id);
  return arrToReturn;
};

// GENERATE RANDOM STRING
export const generateGuid = () => {
  const S4 = () =>
    (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
};

export const getFormattedDateByDay = (date) => {
  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "days").startOf("day");

  let formattedDate;
  if (moment(date).isSame(today, "d")) {
    formattedDate = `Today, ${moment(date).format(TIME_FORMAT1)}`;
  } else if (moment(date).isSame(yesterday, "d")) {
    formattedDate = `Yesterday, ${moment(date).format(TIME_FORMAT1)}`;
  } else {
    formattedDate = moment(date).format(DATETIME_FORMAT4);
  }
  return formattedDate;
};

export const getFormattedDateOnlyByDay = (date) => {
  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "days").startOf("day");

  let formattedDate;
  if (moment(date).isSame(today, "d")) {
    formattedDate = `Today ${moment(date).format(DATE_FORMAT7)}`;
  } else if (moment(date).isSame(yesterday, "d")) {
    formattedDate = `Yesterday ${moment(date).format(DATE_FORMAT7)}`;
  } else {
    formattedDate = moment(date).format(DATE_FORMAT7);
  }
  return formattedDate;
};
export const getFormattedDateByTime = (date, timeFormat = false) => {
  const today = new Date();
  moment.locale("en", {
    relativeTime: {
      past: "%s ago",
      s: "seconds",
      ss: "%d sec",
      m: "1 min",
      mm: "%d min",
      h: "1 hr",
      hh: "%d hr",
    },
  });
  const createdDate = moment(date);
  if (Math.round(createdDate.diff(today, "days", true)) <= -1) {
    return getFormattedDateTime(date, DATE_FORMAT1);
  } else {
    return createdDate.diff(today, "days") >= 0
      ? moment(createdDate).fromNow()
      : getFormattedDateTime(date, TIME_FORMAT1);
  }
};

// CUSTOM ALERT
// https://fkhadra.github.io/react-toastify/introduction/
export const toastAlert = (
  message,
  type = ALERT_TYPES.SUCCESS,
  position = ALERT_POSITIONS.TOP_RIGHT,
  duration = ALERT_TIMEOUT,
  closeOnClick = true,
  pauseOnHover = false,
  theme = ALERT_THEMES.LIGHT,
  draggable = false,
  isProgressBar = false
) => {
  toast[type](message, {
    position: position,
    autoClose: duration,
    hideProgressBar: isProgressBar,
    closeOnClick: closeOnClick,
    pauseOnHover: pauseOnHover,
    draggable: draggable,
    theme: theme,
    toastId: message,
  });
};

export const imageValidation = (file, setError) => {
  let res = true;
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/jpg" ||
    file.type === "image/png";

  if (!isJpgOrPng) {
    setError("You can only upload JPG/PNG file!");
    res = false;
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    res = false;
    setError("Image must smaller than 2MB!");
  }

  return res;
};

export const documentValidation = (
  file,
  checkExtension = true,
  setError = (error) => {
    toastAlert(error, ALERT_TYPES.ERROR);
  }
) => {
  let res = true;
  const isDoc =
    /(\.png|\.jpg|\.jpeg|\.doc|\.docx|\.odt|\.pdf|\.tex|\.txt|\.rtf|\.wps|\.wks|\.wpd|\.ppt|\.pptx|\.xls|\.xlsx)$/i;
  if (checkExtension && isDoc.exec(file)) {
    setError("You can only upload doc file!");
    res = false;
  }
  const isLt15M = file.size / 1024 / 1024 < 15;
  if (!isLt15M) {
    res = false;
    setError("Image must smaller than 15MB!");
  }

  return res;
};

//FORMAT VALUES
export const formatValue = (param) => {
  let value = param ?? 0;
  if (value >= 1000000) {
    if (value % 1000000 === 0) {
      value = value / 1000000 + "mm";
    } else {
      value = parseFloat(value / 1000000).toFixed(1) + "m";
    }
  } else if (value >= 100000) {
    if (value % 1000 === 0) {
      value = value / 1000 + "k";
    } else {
      value = parseFloat(value / 1000).toFixed(1) + "k";
    }
  } else if (value >= 10000) {
    if (value % 1000 === 0) {
      value = value / 10000 + "k";
    } else {
      value = parseFloat(value / 10000).toFixed(1) + "k";
    }
  } else if (value >= 1000) {
    if (value % 1000 === 0) {
      value = value / 1000 + "k";
    } else {
      value = parseFloat(value / 1000).toFixed(1) + "k";
    }
  }
  return value;
};

// CUSTOM ICONS FOR PASSWORD FIELDS
export const customPassIcons = (visible) =>
  visible ? (
    <Images.ShowPass style={{ cursor: "pointer", marginRight: "5px" }} />
  ) : (
    <Images.HidePass style={{ cursor: "pointer", marginRight: "5px" }} />
  );

// REPLACE MULTIPLE VALUES IN STRING BY OBJECT
export const replaceValInString = (string, replacements = {}) => {
  if (!string) return;
  const pattern = new RegExp(Object.keys(replacements).join("|"), "g");
  return string.replace(pattern, (matched) => replacements[matched]);
};
// GET NAME OF NAME
export const getLetterFromName = (string) => {
  let name = string || "";
  const words = name.split(" ");
  let initials = words[0].charAt(0);
  if (words.length > 1) initials = initials + words[1]?.charAt(0);
  return initials;
};
// GET VALUE FROM AUTOCOMPLETE BY TYPE
export const getAutoCompleteValue = ({ value, key = "key", isInt }) => {
  const val = typeof value === "object" ? value[key] : value;
  return isInt && typeof value === "object" ? parseInt(val) : val;
};

// FORM FIELDS RULES
export const inputFieldRule = ({
  name = "Name",
  requiredMessage,
  isRequired = true,
  isWhiteSpace = true,
  isEmail = false,
  emailError = "email address",
  isType = false,
  type,
  isMax = false,
  max = 60,
  isAlphabetsAndNumber = false,
  isAlphabets = false,
  isPhone = false,
}) => {
  const validationRule = [];
  if (isRequired) {
    validationRule.push({
      required: true,
      message: requiredMessage ? requiredMessage : `${name} is required`,
    });
  }
  if (isWhiteSpace) {
    validationRule.push({
      whitespace: true,
      message: requiredMessage ? requiredMessage : `${name} is required`,
    });
  }
  if (isMax) {
    validationRule.push({
      max: max,
      message: `Maximum ${max} characters allowed`,
    });
  }
  if (isEmail) {
    validationRule.push({
      type: "email",
      message: `Invalid ${emailError.toLowerCase()}`,
    });
  }
  if (isType) {
    validationRule.push({
      type,
      message: `Invalid ${name.toLowerCase()}`,
    });
  }
  if (isAlphabetsAndNumber) {
    validationRule.push({
      pattern: ALLOW_ALPHANUM_REGEX,
      message: `Invalid ${name.toLowerCase()}`,
    });
  }
  if (isAlphabets) {
    validationRule.push({
      pattern: ALLOW_ALPHABETS_REGEX,
      message: `Invalid ${name.toLowerCase()}`,
    });
  }
  if (isPhone) {
    validationRule.push({
      min: 4,
      message: "Please enter a valid phone number.",
    });
  }
  return validationRule;
};

export const autoCompleteFieldRule = ({
  value,
  name = "",
  isRequired = true,
  isWhiteSpace = true,
  isEmail = false,
  isMax = false,
  max = 80,
}) => {
  if (isRequired && !value) {
    return Promise.reject(new Error(`${name} is required`));
  }
  if (typeof value === "string") {
    if (isWhiteSpace && isOnlyWhiteSpace(value)) {
      return Promise.reject(new Error(`${name} is required`));
    }
    if (isMax && value.length > max) {
      return Promise.reject(new Error(`Maximum ${max} characters allowed`));
    }
    if (isEmail && !isEmailValid(value)) {
      return Promise.reject(new Error(`Invalid ${name}`));
    }
  }
  return Promise.resolve();
};

export const getActionPermission = (accessKey, permList = null) => {
  let permissions = permList
    ? permList
    : DataHandler.getStore().getState()?.transactiondetail?.data?.permissions;

  const isAccess = permissions?.find(
    (x) => x?.slug === accessKey
  )?.selectedOption;

  return isAccess ?? null;
};

export const getEmojiCode = (e) => {
  let sym = e.unified.split("-");
  let codesArray = [];
  sym.forEach((el) => codesArray.push("0x" + el));

  return String.fromCodePoint(...codesArray);
};

export const encryptMessage = (normalMsg) => {
  const cipherText = AES.encrypt(
    normalMsg,
    process.env.REACT_APP_CRYPTO_SECRET
  );

  return cipherText.toString();
};

export const decryptMessage = (decryptMessage) => {
  try {
    const bytes = AES.decrypt(
      decryptMessage,
      process.env.REACT_APP_CRYPTO_SECRET
    );
    const decrypted = bytes.toString(enc.Utf8);
    return decrypted;
  } catch (error) {
    console.error("decryptMessage --->>>", error);
    return "";
  }
};

export const groupMessagesByDate = (messages) => {
  const groupedMessages = _.groupBy(messages, (message) => {
    return moment(message.createdAt).utc(true).format().split("T")[0];
  });

  const groupedMessagesArray = Object.entries(groupedMessages).map(
    ([date, messages]) => {
      messages.sort((a, b) => {
        const dateA = moment(a.createdAt);
        const dateB = moment(b.createdAt);
        return dateA.diff(dateB);
      });
      return { date, messages };
    }
  );
  // groupedMessagesArray?.sort((x, y) => y.date - x.date);
  groupedMessagesArray.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);

    return dateA - dateB;
  });

  return groupedMessagesArray;
};

export const isValidLink = (text) => {
  const pattern = /^https:\/\/www\.\S+$/;
  return pattern.test(text);
};

export const isContainLinks = (text) => {
  const pattern = /((?:https?|ftp):\/\/[^\s/$.?#].[^\s]*)/g;
  return text?.match(pattern);
};

export function convertUrlsToAnchors(text) {
  var urlPattern = /((?:https?|ftp):\/\/[^\s/$.?#].[^\s]*)/g;

  var convertedText = text?.replace(
    urlPattern,
    '<a href="$&" target="_blank">$&</a>'
  );

  return convertedText;
}

// export const convertUserToMentionUser = (text) => {
//   const mentionRegex = /@\[([^]+)\]\(([^)]+)\)/g;
//   if (text !== "") {
//     const detectedMentions = [...text?.matchAll(mentionRegex)];
//     let cloneMessage = cloneDeep(text);
//     console.log(detectedMentions, "detect");
//     if (detectedMentions?.length > 0) {
//       for (const match of detectedMentions) {
//         const username = match[1];
//         const userId = match[2];

//         if (userId) {
//           cloneMessage = cloneMessage?.replace(
//             text,
//             `<span style="font-style:italic; cursor:pointer" class="message_mention" key="${userId}" >@${username}</span>`
//           );
//         }
//         // cloneMessage = cloneMessage?.replace(
//         //   text,
//         //   `<span style="color: black;font-weight: bold" class="message_mention" key="${userId}">@${username}</span>`
//         // );
//       }

//       return cloneMessage;
//     } else {
//       return cloneMessage;
//     }
//   }
// };

export const convertUserToMentionUser = (text, userId) => {
  const mentionRegex = /@\[([^\]]+)\]\(([^)]+)\)/g;

  if (text !== "") {
    let cloneMessage = text.replace(mentionRegex, (match, username, id) => {
      if (id) {
        return `<span style="font-style:italic; cursor:pointer" class="message_mention" data-user-id="${id}">@${username}</span>`;
      } else {
        return match; // If no userId is found, return the original match
      }
    });

    return cloneMessage;
  } else {
    return text;
  }
};

export const convertMentionToUsers = (inputString) => {
  // Define a regular expression pattern to match user IDs
  const userIdRegex = /\(([a-fA-F0-9-]+)\)/g;

  // Use the match method to find all matches of the pattern in the input string
  const userIdMatches = inputString.match(userIdRegex);

  if (userIdMatches) {
    // Use a Set to ensure uniqueness of user IDs
    const uniqueUserIds = new Set();

    // Add the matched user IDs to the Set
    userIdMatches.forEach((match) => {
      const userId = match.slice(1, -1); // Remove parentheses
      uniqueUserIds.add(userId);
    });

    // Convert the Set back to an array of objects
    const userIdsArray = Array.from(uniqueUserIds).map((userId) => ({
      user_id: userId,
    }));

    return userIdsArray;
  }
  return [];
};

export const formatMentionString = (inputString) => {
  let pattern = /@\[(.*?)\]\(.*?\)/g;

  // Use the replace method to replace the matched substrings with the desired format
  let formattedString = inputString.replace(pattern, "@$1");
  return formattedString;
};

export const isImage = (filename) => {
  const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|svg|webp|tiff|ico)$/i;
  return imageExtensions.test(filename);
};

export const getFileType = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  const imageExtensions = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "svg",
    "webp",
    "tiff",
  ];
  const documentExtensions = [
    "pdf",
    "doc",
    "docx",
    "txt",
    "rtf",
    "odt",
    "xlsx",
    "xls",
    "pptx",
    "ppt",
  ];

  if (imageExtensions.includes(extension)) {
    return "image";
  } else if (documentExtensions.includes(extension)) {
    return "document";
  } else {
    // Return 'other' or handle it differently if needed
    return "other";
  }
};

export const compareFilesByExtension = (a, b) => {
  const typeA = a.type || "other";
  const typeB = b.type || "other";

  // If either type starts with 'image', it's considered an image file
  if (typeA.startsWith("image") && !typeB.startsWith("image")) {
    return -1; // Put 'a' (image) before 'b' (non-image)
  } else if (!typeA.startsWith("image") && typeB.startsWith("image")) {
    return 1; // Put 'b' (image) before 'a' (non-image)
  } else {
    // For non-image files or if both have 'image' in type, sort alphabetically by name
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    return nameA.localeCompare(nameB);
  }
};

export const groupReactionsByEmoji = (reacts) => {
  const groupedReacts = _.groupBy(reacts, (react) => {
    return react.reaction;
  });

  const groupedReactsArray = Object.entries(groupedReacts).map(
    ([emoji, reacts]) => {
      reacts?.sort((a, b) => b?.selfReact - a?.selfReact);
      return { emoji, reacts };
    }
  );
  groupedReactsArray?.sort((x, y) => y?.reacts?.length - x?.reacts?.length);
  return groupedReactsArray;
};

export const getRepliersInfo = (messages) => {
  let users = [];
  messages?.forEach((x) => {
    if (!users?.find((y) => y?.chat_id === x?.sender?.chat_id)) {
      users?.push(x?.sender);
    }
  });
  return users;
};
