import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { replaceValInString } from "../../../../../../services/utils";
import {
  DATA_PER_PAGE_LIMIT,
  TRANSACTION_TABS_ROUTE,
  TRANSACTION_TABS_SLUGS,
} from "../../../../../../constants";
import { ButtonComponent } from "../../../../../../components";
import { DocumentsDataTable } from "../components";
import { useCustomDispatch } from "../../../../../../helpers/useCustomDispatch";
import {
  getDeletedDocumentsRequest,
  getDocumentsRequest,
} from "../../../../../../redux/slicers/transactiondocuments";
import { useSelector } from "react-redux";

const DocumentsDashboard = ({
  isDeleted,
  updateDataHandler,
  isUpdated,
  roomAccess,
  checkUserStatusHandler,
  isEditAllowed,
  isDeleteAllowed,
  isShareAllowed,
}) => {
  // STATES
  const [selectedSortKey, setselectedSortKey] = useState(null);
  const [selectedSortFilter, setselectedSortFilter] = useState(true);
  const [total, setTotal] = useState(null);
  const [offset, setOffset] = useState(0);
  const [isMoreData, setMoreData] = useState(false);

  // CUSTOM DISPATCH
  const [getDocuments, docLoader] = useCustomDispatch(getDocumentsRequest);
  const [getDeletedDocuments, deleteDocsLoader] = useCustomDispatch(
    getDeletedDocumentsRequest
  );

  //CONST VALS
  const navigate = useNavigate();
  const { id, room } = useParams();
  const isLoading = docLoader || deleteDocsLoader;

  // REDUX DATA
  const { documents } = useSelector((state) => state.transactiondocuments);

  // HELPERS
  const getDocumentsHelper = (request, isSearch) => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: 0,
      sort: selectedSortKey,
      order: selectedSortFilter ? "asc" : "desc",
    };
    if (isSearch) {
      queryParams["search"] = isSearch;
      setMoreData(false);
    }
    request({
      queryParams: queryParams,
      pathParams: room,
      logic({ total }) {
        setTotal(total);
      },
    });
  };
  // HOOKS
  useEffect(() => {
    getDocumentsHelper(isDeleted ? getDeletedDocuments : getDocuments);
  }, [isUpdated, offset, isDeleted, selectedSortKey, selectedSortFilter]);

  //HANDLERS
  const setSortKeyHandler = (key) => {
    const isAlreadyExist = selectedSortKey === key;
    setselectedSortKey(key);
    setselectedSortFilter(isAlreadyExist ? !selectedSortFilter : false);
    setMoreData(false);
    setOffset(0);
  };
  const handleNextData = () => {
    setOffset(offset + DATA_PER_PAGE_LIMIT);
    setMoreData(true);
  };
  const handleSearch = (e) => {
    setOffset(0);
    getDocumentsHelper(
      isDeleted ? getDeletedDocuments : getDocuments,
      e.target.value
    );
  };
  const handleDeletedDocs = () => {
    const val = {
      ":tab": TRANSACTION_TABS_SLUGS.DELETED_DOCUMENTS,
      ":id": id,
      ":room": room,
    };
    navigate(replaceValInString(TRANSACTION_TABS_ROUTE, val));
  };

  return (
    <>
      {!isDeleted && (
        <Row gutter={[15, 0]}>
          <Col span={20}>
            <div className="search-box">
              <FontAwesomeIcon icon={faSearch} />
              <input
                type="text"
                onChange={handleSearch}
                placeholder={"Search any Documents..."}
              />
            </div>
          </Col>
          <Col span={4}>
            <ButtonComponent
              text="Deleted Docs"
              className="deleted-btn"
              onClick={handleDeletedDocs}
            />
          </Col>
        </Row>
      )}
      <DocumentsDataTable
        selectedSort={selectedSortKey}
        selectedSortFilter={selectedSortFilter}
        sortData={setSortKeyHandler}
        isMoreData={isMoreData}
        total={total}
        handleFetch={handleNextData}
        data={documents}
        isLoading={isLoading}
        isDeletedView={isDeleted}
        updateDataHandler={updateDataHandler}
        roomAccess={roomAccess}
        checkUserStatusHandler={checkUserStatusHandler}
        isEditAllowed={isEditAllowed}
        isDeleteAllowed={isDeleteAllowed}
        isShareAllowed={isShareAllowed}
      />
    </>
  );
};
export default DocumentsDashboard;
