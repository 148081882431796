import React from "react";
import { Form, Input, Select, Tooltip } from "antd";
import { PassWordsFields, SelectBox } from "../../../components";
import { inputFieldRule } from "../../../services/utils";
import { Images } from "../../../themes";
import countryList from "react-select-country-list";
import PhoneInput from "react-phone-input-2";

const SignupFields = ({
  resetCompanyRelatedFields,
  onselectOptionHandler,
  searchHandler,
  companies,
  disableCompanyRelatedFields,
  companyName,
  setCompanyName,
  isTransactonInvite,
  isEnterpriseInvite,
}) => {
  // CONST VALS
  const isInvite = isEnterpriseInvite || isTransactonInvite;
  const countryOptions = countryList().getData();
  return (
    <>
      <div className="two-fields">
        <Form.Item
          name="firstName"
          className="required-field"
          rules={inputFieldRule({
            name: "First Name",
            isAlphabets: true,
          })}
        >
          <Input
            name="firstName"
            prefix={<Images.User className="input-icon" />}
            placeholder="First Name"
            maxLength={80}
            autoFocus
          />
        </Form.Item>
        <Form.Item
          className="required-field"
          rules={inputFieldRule({
            name: "Last Name",
            isAlphabets: true,
          })}
          name="lastName"
        >
          <Input
            name="lastName"
            prefix={<Images.User className="input-icon" />}
            placeholder="Last Name"
            maxLength={80}
          />
        </Form.Item>
      </div>
      <Form.Item
        className="required-field"
        name="email"
        rules={inputFieldRule({
          name: "Work email",
          isEmail: true,
          emailError: "Work Email Address",
          isWhiteSpace: false,
        })}
      >
        <Input
          disabled={isInvite}
          name="email"
          prefix={<Images.Email className="input-icon" />}
          placeholder="Work Email"
          maxLength={80}
        />
      </Form.Item>
      <Tooltip title="Mobile Number" placement="right">
        <Form.Item
          className="required-field"
          rules={[
            ...inputFieldRule({
              name: "Mobile number",
              isWhiteSpace: false,
              isRequired: true,
              isPhone: true,
            }),
          ]}
          name="phone"
        >
          <PhoneInput
            placeholder="Mobile number"
            country={"us"}
            isValid={(value, country) => {
              if (value.match(/12345/)) {
                return "Invalid value: " + value + ", " + country.name;
              } else if (value.match(/1234/)) {
                return false;
              } else {
                return true;
              }
            }}
            className="required"
          />
        </Form.Item>
      </Tooltip>

      <Form.Item
        rules={inputFieldRule({
          name: "Job Title",
          isRequired: false,
          isAlphabets: true,
          isWhiteSpace: false,
        })}
        name="jobTitle"
      >
        <Input
          name="jobTitle"
          prefix={<Images.Job className="input-icon" />}
          placeholder={"Job Title"}
          maxLength={80}
        />
      </Form.Item>
      <Tooltip title="Work Phone Number" placement="right">
        <Form.Item
          style={{ zIndex: 11 }}
          rules={[
            ...inputFieldRule({
              name: "Work phone number",
              isRequired: false,
              isWhiteSpace: false,
            }),
          ]}
          name="workPhone"
        >
          <PhoneInput
            placeholder="Work phone number"
            isValid={(value, country) => {
              if (value.match(/12345/)) {
                return "Invalid value: " + value + ", " + country.name;
              } else if (value.match(/1234/)) {
                return false;
              } else {
                return true;
              }
            }}
            country={"us"}
          />
        </Form.Item>
      </Tooltip>

      <SelectBox
        disabled={isTransactonInvite}
        options={companies}
        companyName={companyName}
        setCompanyName={setCompanyName}
        resetCompanyRelatedFields={resetCompanyRelatedFields}
        onselectOptionHandler={onselectOptionHandler}
        setSearchText={searchHandler}
        placeholder={"Company Name"}
      />
      <Form.Item
        className="required-field"
        rules={inputFieldRule({ name: "Address" })}
        name="address"
      >
        <Input
          disabled={disableCompanyRelatedFields}
          name="address"
          prefix={<Images.Address className="input-icon" />}
          placeholder="Company Address"
          maxLength={255}
        />
      </Form.Item>
      <div className="two-fields">
        <Form.Item
          className="required-field"
          rules={inputFieldRule({
            name: "State",
            isAlphabets: true,
          })}
          name="state"
        >
          <Input
            disabled={disableCompanyRelatedFields}
            name="state"
            prefix={<Images.State className="input-icon" />}
            placeholder="State"
            maxLength={85}
          />
        </Form.Item>
        <Form.Item
          className="required-field"
          rules={inputFieldRule({
            name: "Postal/Zip code",
            isAlphabetsAndNumber: true,
            isWhiteSpace: false,
          })}
          name="zip"
        >
          <Input
            disabled={disableCompanyRelatedFields}
            name="zip"
            prefix={<Images.Zip className="input-icon" />}
            placeholder="Postal/Zip code"
            type="text"
            maxLength={5}
            onInput={(e) => {
              e.target.value = e.target.value.slice(0, e.target.maxLength);
            }}
          />
        </Form.Item>
      </div>
      <Form.Item
        className="required-field"
        rules={inputFieldRule({
          name: "City",
          isAlphabets: true,
        })}
        name="city"
      >
        <Input
          disabled={disableCompanyRelatedFields}
          name="city"
          prefix={<Images.City className="input-icon" />}
          placeholder="City"
          maxLength={85}
        />
      </Form.Item>
      <Form.Item
        className="required-field"
        rules={inputFieldRule({
          name: "Country",
          isAlphabets: false,
        })}
        name="country"
      >
        <Select
          disabled={disableCompanyRelatedFields}
          name="country"
          placeholder="Country"
          maxLength={85}
          showSearch
          filterOption={(input, option) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
          className="signup-country-select"
          optionLabelProp="label"
        >
          {countryOptions.map((data, index) => (
            <Select.Option value={data.label} key={index} label={data?.label}>
              {data.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <PassWordsFields />
    </>
  );
};

export default SignupFields;
