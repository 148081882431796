import _ from "lodash";
import {
  CHAT_NOTI_CONST,
  DATE_FORMAT1,
  DATE_FORMAT7,
  DEFAULT_PROFILE_COLOR,
} from "../constants";
import {
  decryptMessage,
  formatMentionString,
  getChatAccessToken,
  getFormattedDateByDay,
  getRepliersInfo,
  groupMessagesByDate,
  groupReactionsByEmoji,
  isImage,
} from "../services/utils";
import { manipulateUserData } from "./user";
import moment from "moment";

export const manipulateChatUserData = (data) => {
  if (_.isEmpty(data)) return {};
  manipulateUserData;
  let user = { ...manipulateUserData(data) };
  user["chat_id"] = parseInt(data?.chat_user_id) ?? null;

  return user;
};

export const manipulateChatUsersList = (data) => {
  let users = [];
  data?.forEach((item) => {
    users.push(manipulateChatUserData(item));
  });
  return users;
};

export const manipulateIndividualChatRoomData = (data) => {
  if (_.isEmpty(data)) return {};
  let chat = {};
  let receiver = data?.room_members?.find(
    (x) => x.room_user_id !== getChatAccessToken()
  )?.room_user_Detail;
  let sender = data?.room_members?.find(
    (x) => x.room_user_id === getChatAccessToken()
  )?.room_user_Detail;
  chat["id"] = data?.id ?? null;
  chat["chatName"] = receiver.user_fullname ?? "";
  chat["email"] = receiver.user_email ?? "";
  chat["chatUsername"] = receiver.user_username ?? "";
  chat["userId"] = receiver.id ?? "";
  chat["senderId"] = sender?.id ?? null;
  chat["receiverId"] = receiver?.id ?? null;
  chat["profileId"] = parseInt(receiver?.user_unique_id) ?? null;
  return chat;
};

export const manipulateAttachmentData = (data, sender) => {
  if (_.isEmpty(data)) return {};
  let file = {};

  file["id"] = data?.id ?? null;
  file["url"] = data?.attachment_path ?? null;
  file["isImage"] = isImage(data?.attachment_extension ?? "");
  file["fileName"] = data?.attachment_name ?? "";
  if (sender) {
    let sender = data?.messages?.message_sender;
    file["senderName"] = sender?.user_fullname ?? "";
    file["senderImage"] = sender?.user_image ?? null;
    file["chat_id"] = sender?.id ?? null;
    file["user_id"] = parseInt(sender?.user_unique_id) ?? null;
  }

  return file;
};

export const manipulateAttachmentsList = (data, sender) => {
  if (_.isEmpty(data)) return [];

  let attachments = [];
  data?.forEach((item) => {
    attachments.push(manipulateAttachmentData(item, sender));
  });

  attachments?.sort((a, b) => a?.isImage - b?.isImage);

  return attachments;
};

export const manipulateSenderData = (data) => {
  if (_.isEmpty(data)) return {};
  let groupDetail = data?.user_room?.[0];

  let sender = {};
  sender["id"] = data?.user_unique_id ?? null;
  sender["chat_id"] = data?.id ?? null;
  sender["username"] = data?.user_fullname ?? null;
  sender["email"] = data?.user_email ?? null;
  sender["image"] = data?.user_image ?? null;
  sender["bgColor"] = data?.bgcolor ?? null;
  sender["isActive"] = !data?.user_block_status ?? null;
  sender["isMember"] =
    (!groupDetail?.is_removed && !groupDetail?.is_leaved) ?? false;
  sender["isGroupAdmin"] = groupDetail?.is_admin ?? false;

  return sender;
};

export const manipulateChatMessageData = (data) => {
  if (_.isEmpty(data)) return {};
  let message = {};
  let sender = data?.message_sender;
  let receiver = data?.message_receiver;
  let isHighlight = data?.message_type === "highlight";

  message["id"] = data?.id ?? null;
  message["roomId"] = data?.room_id ?? null;
  message["isSender"] = sender?.id !== getChatAccessToken() ?? false;
  message["receiver"] = receiver?.user_fullname ?? null;
  message["sender"] = sender?.user_fullname ?? null;
  message["date"] = getFormattedDateByDay(data?.createdAt) ?? null;
  message["createdAt"] = data?.createdAt ?? null;
  message["content"] = isHighlight
    ? ""
    : decryptMessage(data?.message_text) ?? "";
  message["highlight"] = isHighlight ? data?.message_text : false ?? false;
  message["attachments"] =
    manipulateAttachmentsList(data?.message_attachments) ?? [];
  message["senderId"] = sender?.id ?? null;
  message["receiverId"] = receiver?.id ?? null;
  message["senderUserId"] = parseInt(sender?.user_unique_id) ?? null;
  message["receiverUserId"] = parseInt(receiver?.user_unique_id) ?? null;
  message["reacts"] = manipulateReactsList(data?.MessageReacts, true) ?? [];
  message["unGroupedReacts"] =
    manipulateReactsList(data?.MessageReacts, false) ?? [];
  message["parentMessageId"] = data?.parentMessageId ?? null;
  message["sender"] = manipulateSenderData(data?.message_sender) ?? {};
  message["formattedMessage"] = isHighlight
    ? data?.message_text
    : formatMentionString(decryptMessage(data?.message_text)) ?? "";
  message["isMedia"] = data?.message_attachments?.length > 0 ?? false;

  if (!data?.parentMessageId) {
    const replies = manipulateChatMessages(data?.childMessages);
    message["childMessages"] = replies ?? [];
    message["replyCount"] = replies?.[1]?.length ?? 0;
    message["repliers"] = getRepliersInfo(replies?.[1]) ?? [];
  }

  return message;
};

export const manipulateChatMessages = (data) => {
  let messages = [];
  data?.forEach((item) => {
    messages.push(manipulateChatMessageData(item));
  });
  return [groupMessagesByDate(messages), messages];
};

export const manipulateReactMessageData = (data) => {};

export const manipulateReactData = (data) => {
  if (_.isEmpty(data)) return {};
  let react = {};

  react["id"] = data?.id ?? null;
  react["reaction"] = data?.message_react_emoji ?? "";
  react["reactorId"] = data?.reactor_id ?? null;
  react["reactorName"] = data?.reactor_details?.user_fullname ?? null;
  react["selfReact"] = data?.reactor_id === getChatAccessToken() ?? false;
  react["messageId"] = data?.message_id ?? false;

  return react;
};

export const manipulateReactsList = (data, group) => {
  let reacts = [];
  data?.forEach((item) => {
    reacts.push(manipulateReactData(item));
  });

  return group ? groupReactionsByEmoji(reacts) : reacts;
};

export const manipulateChatLinkData = (data) => {
  if (_.isEmpty(data)) return {};
  let link = {};
  let sender = data?.message_sender;

  link["id"] = data?.id ?? null;
  link["url"] = decryptMessage(data?.message_text ?? "") ?? "";
  link["senderName"] = sender?.user_fullname ?? "";
  link["senderImage"] = sender?.user_image ?? null;
  link["chat_id"] = sender?.id ?? null;
  link["user_id"] = parseInt(sender?.user_unique_id) ?? null;

  return link;
};

export const manipulateChatLinksList = (data) => {
  let links = [];
  data?.forEach((item) => {
    links.push(manipulateChatLinkData(item));
  });

  return links;
};

export const manipulateChatDetails = (data) => {
  if (_.isEmpty(data)) return {};
  let chat = {};

  chat["id"] = data?.id ?? null;
  chat["attachments"] =
    manipulateAttachmentsList(data?.attachments, true) ?? [];
  chat["links"] = manipulateChatLinksList(data?.links) ?? [];

  return chat;
};

export const manipulateThreadData = (data) => {
  if (_.isEmpty(data)) return {};
  let thread = {};
  let sender = data?.message_sender;

  thread["id"] = data?.id ?? null;
  thread["parentMessage"] = manipulateChatMessageData(data) ?? {};
  thread["childMessages"] = manipulateChatMessages(data?.childMessages) ?? [];

  return thread;
};

export const manipulateRoomMemberData = (data) => {
  if (_.isEmpty(data)) return {};
  let member = {};
  let memberDetail = data?.room_user_Detail;

  member["id"] = memberDetail?.user_unique_id ?? null;
  member["chat_id"] = memberDetail?.id ?? null;
  member["username"] = memberDetail?.user_fullname ?? null;
  member["display"] = memberDetail?.user_fullname ?? null;
  member["email"] = memberDetail?.user_email ?? null;
  member["image"] = memberDetail?.user_image ?? null;
  member["bgColor"] = memberDetail?.bgcolor ?? null;
  member["isActive"] = !memberDetail?.user_block_status ?? null;
  member["isGroupAdmin"] = data?.is_admin ?? null;
  member["isMember"] = (!data?.is_removed && !data?.is_leaved) ?? false;

  return member;
};

export const manipulateRoomMembersList = (data) => {
  let members = [];
  data?.forEach((item) => {
    members.push(manipulateRoomMemberData(item));
  });

  members.sort(function (a, b) {
    if (a["isGroupAdmin"]) {
      return -1;
    } else if (b["isGroupAdmin"]) {
      return 1;
    } else {
      return a.username.localeCompare(b.username);
    }
  });

  return members;
};

export const manipulateChatData = (data) => {
  if (_.isEmpty(data)) return {};
  let chat = {};
  let isGroup = data?.room_type === "group" || data?.room_type === "group-tr";
  let individual = data?.room_members?.find(
    (x) => x.room_user_id !== getChatAccessToken()
  )?.room_user_Detail;
  let receiver = data?.room_members?.find(
    (x) => x.room_user_id === getChatAccessToken()
  )?.room_user_Detail;

  chat["id"] = data?.id ?? null;
  chat["lastMessage"] = manipulateChatMessageData(data?.messages?.[0]) ?? {};
  chat["isGroup"] = isGroup ?? false;
  chat["slug"] = data?.room_slug ?? "";
  chat["name"] =
    (isGroup ? data?.room_name : individual?.user_fullname) ?? "Unnamed";
  chat["unreadCount"] = data?.unreadCount ?? 0;
  chat["receiverId"] = receiver?.id ?? 0;
  chat["createdAt"] = moment(data?.createdAt).format(DATE_FORMAT7) ?? 0;
  if (!isGroup) {
    chat["individual"] = manipulateSenderData(individual) ?? {};
  }
  chat["roomMembers"] = manipulateRoomMembersList(data?.room_members) ?? [];
  chat["membersCount"] = data?.room_members_count ?? null;
  chat["isBlocked"] = data?.is_blocked ?? false;
  chat["blockedBy"] = data?.blocked_by ?? null;
  chat["isArchived"] = data?.is_archived ?? false;
  chat["isAdminLoggedIn"] = data?.is_admin ?? false;
  chat["roomType"] = data?.room_type ?? "";

  return chat;
};

export const manipulateChatsList = (data) => {
  let chats = [];
  data?.forEach((item) => {
    chats.push(manipulateChatData(item));
  });

  return chats;
};
export const manipulateNotificationSettingData = (data) => {
  if (_.isEmpty(data)) return {};
  let setting = {};
  let text = CHAT_NOTI_CONST?.[data?.key];

  setting["key"] = data?.key ?? "";
  setting["value"] = data?.value ?? "";
  setting["text"] = text ?? "";

  return setting;
};

export const manipulateNotificationSettingList = (data) => {
  let settings = [];
  data?.forEach((item) => {
    settings.push(manipulateNotificationSettingData(item));
  });

  return settings;
};

export const manipulateTransactionChatRoomData = (data) => {
  if (_.isEmpty(data)) return {};
  let room = {};
  let roomDetail = data?.room_detail;

  room["name"] = roomDetail?.room_name ?? "";
  room["slug"] = roomDetail?.meta_data?.transaction_room_slug ?? "";

  return room;
};

export const manipulateTransactionChatRoomList = (data) => {
  let rooms = [];
  data?.forEach((item) => {
    rooms.push(manipulateTransactionChatRoomData(item));
  });

  return rooms;
};

export const manipulateTransactionChatData = (data) => {
  if (_.isEmpty(data)) return {};
  let chat = {};

  chat["slug"] = data?.transactionSlug ?? "";
  chat["name"] = data?.transactionName ?? "";
  chat["rooms"] = manipulateTransactionChatRoomList(data?.rooms);

  return chat;
};

export const manipulateTransactionChatList = (data) => {
  let chats = [];
  data?.forEach((item) => {
    chats.push(manipulateTransactionChatData(item));
  });

  return chats;
};
