import React from "react";
import { ClipLoader } from "react-spinners";
import { CardLoader } from "../../../../../../components";
import { Images } from "../../../../../../themes";
import { Emojis, FilePicker } from "../../../components";
import DocumentAttachmentWrapper from "./documentAttachmentWrapper";
import { MentionsInput, Mention } from "react-mentions";
import { CUSTOM_ALL } from "../../../../../../constants";
import { useCustomDispatch } from "../../../../../../helpers/useCustomDispatch";
import { useSelector } from "react-redux";
import { getMentionsRequest } from "../../../../../../redux/slicers/chat";
import { useEffect } from "react";
import { filter } from "lodash";

function ChatInput({
  allFiles,
  inputRef,
  handleRemoveFiles,
  setValue,
  value,
  sendLoader,
  handleAddFiles,
  emojiOpen,
  setEmojiOpen,
  onEmojiClick,
  handleEnter,
  isTyping,
  sendMessageHandler,
  onKeyDown,
  onKeyUp,
  selectedRoom,
  placeholder = "Type here...",
  typer,
  allUsers,
}) {
  const firstName = typer?.split(" ")?.[0];
  const [getMentions] = useCustomDispatch(getMentionsRequest);
  const { mentions } = useSelector((x) => x.chat);
  const { chat_id } = useSelector((x) => x.user.data);
  useEffect(() => {
    if (selectedRoom?.id) {
      getMentions({
        queryParams: {
          limit: 20,
          offset: 0,
          room_id: selectedRoom?.id,
          user_id: chat_id,
          filter: true,
        },
      });
    }
  }, [selectedRoom?.id, selectedRoom?.membersCount]);
  // const allUsers = selectedRoom?.roomMembers?.map((x) => ({
  //   id: x.chat_id,
  //   display: x.username,
  // }));
  // let temp = mentions;
  const getMentionsHelper = (query, callback) => {
    // let temp = [...mentions];
    const filteredArray = mentions.filter((obj) => {
      // Convert both the query string and the object's property to lowercase
      const loweredQuery = query.toLowerCase();
      const loweredName = obj.display.toLowerCase();

      // Check if the object's name contains the query string
      return loweredName.includes(loweredQuery);
    });
    getMentions({
      queryParams: {
        search: query,
        room_id: selectedRoom?.id,
        user_id: chat_id,
        filter: true,
      },
    });

    return filteredArray;
  };
  return (
    <div className="chat-input-container">
      {allFiles.length > 0 && (
        <div className="img-wrapper">
          {allFiles?.map((item, index) =>
            item?.type?.includes("image") ? (
              <div className="img-attachment">
                <img src={URL.createObjectURL(item)} />
                <span className="remove-btn">
                  <Images.CancelImage
                    className="c-p"
                    onClick={() => handleRemoveFiles(index)}
                  />
                </span>
              </div>
            ) : (
              <DocumentAttachmentWrapper
                fileName={item?.name}
                isDownload={false}
                handleRemoveFile={() => handleRemoveFiles(index)}
              />
            )
          )}
        </div>
      )}
      <div className="chat-input-wrapper">
        <MentionsInput
          className="inputBox_inputWrapper-input"
          value={value}
          // onChange={handleChangeContent}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          forceSuggestionsAboveCursor
          inputRef={inputRef}
          // onKeyDown={handleOnEnterPress}
          placeholder={placeholder}
          onKeyDown={(e) => {
            handleEnter(e);
            onKeyDown(e);
          }}
          onKeyUp={onKeyUp}
          // onPaste={handlePaste}
          style={{
            suggestions: {
              list: {
                backgroundColor: "white",
                border: "1px solid rgba(0,0,0,0.15)",
                fontSize: 14,
                maxHeight: 200,
                fontFamily: "Lato",
                fontStyle: "italic",
                overflowY: "auto",
                fontWeight: 500,
              },
              item: {
                padding: "5px 15px",
                borderBottom: "1px solid rgba(0,0,0,0.15)",
                "&focused": {
                  backgroundColor: "rgba(111, 124, 237, 0.1)",
                },
              },
            },
          }}
        >
          <Mention
            trigger="@"
            data={getMentionsHelper}
            // markup="@__display__"
            appendSpaceOnAdd
            displayTransform={(id, display) => `@${display}`}
          />
        </MentionsInput>
        {/* <textarea
          // type=""
          ref={inputRef}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          value={value}
          autoFocus
          // onBlur={() => inputRef.current.focus()}
          placeholder={placeholder}
          onKeyDown={(e) => {
            handleEnter(e);
            onKeyDown(e);
          }}
          rows={1}
          disabled={sendLoader}
          onKeyUp={onKeyUp}
        /> */}
        <div className="chat-options">
          <Emojis
            onEmojiSelect={onEmojiClick}
            open={emojiOpen}
            setOpen={!sendLoader && setEmojiOpen}
          />
          <FilePicker handleAddFiles={!sendLoader && handleAddFiles} />
          {sendLoader ? (
            <ClipLoader size={15} color={"#6F7CED"} />
          ) : (
            <Images.SendMessage
              className="c-p"
              onClick={() => sendMessageHandler("")}
            />
          )}
        </div>
      </div>
      {isTyping && (
        <div className="show-typing-wrapper">
          <p className="typer">{firstName} is typing </p>
          <div className="type-loader">
            <CardLoader color="#858585" height={17} size={5} />
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatInput;
