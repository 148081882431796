import React from "react";
import { Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import {
  getVersionHistoryRequest,
  getVersionHistorySucccess,
} from "../../../../../../../redux/slicers/transactiondocuments";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DocVisibilityBox from "../docVisibilityBox";
import { useState } from "react";

const VersionHistoryModal = ({ preview, handleClose, data }) => {
  //STATES
  const [selected, setSelected] = useState(null);

  //CONST VALS
  const dispatch = useDispatch();

  //CUSTOM DISPATCH
  const [getVersionHistory, historyLoader] = useCustomDispatch(
    getVersionHistoryRequest
  );
  //REDUX DATA
  const { versionHistory } = useSelector((state) => state.transactiondocuments);

  //HOOKS
  useEffect(() => {
    if (preview) {
      const queryParams = {
        isDocument: data?.isDocument,
        versionSlug: data?.slug,
      };
      getVersionHistory({ queryParams });
    }
  }, [preview]);

  //HANDLERS
  const closeHandler = () => {
    handleClose();
    dispatch(getVersionHistorySucccess([]));
  };

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={389}
      className="version-visibility-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>Document visible to</h3>
            <p>All Rooms Details Below</p>
          </div>
          <DocVisibilityBox
            data={versionHistory}
            selected={selected}
            setSelected={setSelected}
            isLoading={historyLoader}
          />
        </div>
      </div>
    </Modal>
  );
};

export default VersionHistoryModal;
