import React, { useState, useEffect } from "react";
import { Form, Input } from "antd";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AuthInviteDetail,
  ButtonComponent,
  TwoFactorAuthModal,
} from "../../../components";
import Header from "../../../components/header";
import {
  ALERT_TYPES,
  ANTD_SCROLL_TO_ERROR_FIELDS_CONFIG,
  DASHBOARD_ROUTE,
  DASHBOARD_TRANSACTION_INVITE_ROUTE,
  FORGETPASS_ROUTE,
  SIGNUP_ROUTE,
  COPYRIGHTSTRING,
} from "../../../constants";
import { useCustomDispatch } from "../../../helpers/useCustomDispatch";
import {
  userSignInRequest,
  removeAuthError,
} from "../../../redux/slicers/user";
import {
  inputFieldRule,
  toastAlert,
  customPassIcons,
} from "../../../services/utils";
import { Images } from "../../../themes";
import { getTransactionInvitationDetail } from "../../../redux/slicers/transactiondetail";
import { v4 as uuidv4 } from "uuid";
import "../styles.scss";

const SignIn = () => {
  // CONST VALS
  const { token } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const isTransactonInvite = pathname.includes("transactioninvite");
  const deviceToken = uuidv4();

  // STATES
  const [loginResponse, setLoginResponse] = useState(false);
  const [inviteInfo, setInviteInfo] = useState([]);
  const [isErrorShown, setErrorShown] = useState(false);

  // CUSTOM DISPATCH
  const [loginReq, loginReqLoader] = useCustomDispatch(userSignInRequest);
  const [removeError] = useCustomDispatch(removeAuthError);
  const [getTransactionInviteDetail, isInviteLoading] = useCustomDispatch(
    getTransactionInvitationDetail
  );

  // REDUX DATA
  const { isError } = useSelector((state) => state.user);

  // HOOKS
  useEffect(() => {
    if (isError && !isErrorShown) {
      toastAlert(isError, ALERT_TYPES.ERROR);
      removeError();
      setErrorShown(true);
    }
  }, [isError]);

  useEffect(() => {
    if (isTransactonInvite) {
      getTransactionInviteDetail({
        pathParams: token,
        logic({ data }) {
          setInviteInfo({
            transactionName: data.transaction.name,
            userName: data.invitedBy.firstName + " " + data.invitedBy.lastName,
            thumb: data.invitedBy.image,
            userid: data.invitedBy.id,
            profilebg: data.invitedBy.bgcolor,
            companyName: data.company.name,
          });
        },
      });
    }
  }, [isTransactonInvite, pathname]);

  // HANDLERS
  const handleFormFinish = (values) => {
    const payload = {
      email: values.email,
      password: values.password,
      deviceToken,
    };

    loginReq({
      payload,
      logic(response) {
        if (response?.data.allowTFA) {
          setLoginResponse(response?.data);
        } else {
          if (isTransactonInvite) {
            navigate(
              DASHBOARD_TRANSACTION_INVITE_ROUTE.replace(":token", token)
            );
            return;
          }
          navigate(DASHBOARD_ROUTE);
        }
      },
    });
  };

  return (
    <>
      <Header />
      <section className="signing-wrapper">
        <div className="form-wrapper">
          <h3 className="auth-title">
            <span>
              Welcome back to
              <div>
                <Images.Logo style={{ width: "37px", height: "37px" }} />{" "}
                DealTru
              </div>
            </span>
          </h3>
          {isTransactonInvite ? (
            <AuthInviteDetail data={inviteInfo} loader={isInviteLoading} />
          ) : (
            <p className="auth-subtitle">Enter Your Details Below</p>
          )}
          <Form
            scrollToFirstError={ANTD_SCROLL_TO_ERROR_FIELDS_CONFIG}
            form={form}
            className="form"
            onFinish={handleFormFinish}
          >
            <Form.Item
              name="email"
              rules={inputFieldRule({
                name: "Email address",
                isEmail: true,
              })}
            >
              <Input
                name="email"
                prefix={<Images.Email className="input-icon" />}
                placeholder="Email address"
                maxLength={80}
                autoFocus
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={inputFieldRule({
                name: "Password",
                isWhiteSpace: false,
              })}
            >
              <Input.Password
                iconRender={customPassIcons}
                prefix={<Images.Pass className="input-icon" />}
                name="password"
                placeholder="Enter your password"
                maxLength={30}
              />
            </Form.Item>
            <div className="forgot-pass">
              <Link to={FORGETPASS_ROUTE}>Forgot Password?</Link>
            </div>
            <Form.Item>
              <ButtonComponent
                className="login-btn"
                isLoading={loginReqLoader}
                text="Login"
              />
            </Form.Item>
          </Form>
          <div className="register-here">
            <p>
              Don’t have an account?{" "}
              <Link to={SIGNUP_ROUTE} style={{ color: "#1C2B48" }}>
                Register here
              </Link>
            </p>
          </div>
        </div>
        <div>
          <p className="auth-copyright">{COPYRIGHTSTRING}</p>
        </div>
        <TwoFactorAuthModal
          modalPreview={!!loginResponse}
          apiData={loginResponse}
          handleClose={() => {
            setLoginResponse(false);
          }}
          deviceToken={deviceToken}
        />
      </section>
    </>
  );
};

export default SignIn;
