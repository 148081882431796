import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { Images } from "../../../../../../../themes";
import {
  ChecklistVisibilityModal,
  CreateChecklistModal,
  DeleteChecklistModal,
} from "../";
import {
  CardLoader,
  CustomInfiniteScroll,
  DisabledTooltip,
  EditDeleteDropdown,
  EmptyBox,
  StatusToggle,
  VisibleToPeople,
} from "../../../../../../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { changeChecklistStatusRequest } from "../../../../../../../redux/slicers/transactionchecklist";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import "./styles.scss";

const ChecklistDataTable = ({
  isLoading,
  isDeleted,
  sortData,
  selectedSort,
  selectedSortFilter,
  data,
  total,
  handleFetch,
  handleDataUpdate,
  isDeleteAllowed,
  isEditAllowed,
}) => {
  const [isRoomDetail, setisRoomDetail] = useState(false);
  const [selectedVisibility, setSelectedVisibility] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [visiblityModal, setvisiblityModal] = useState(false);
  const [updateModal, setupdateModal] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);

  //CUSTOM DISPATCH
  const [changeChecklistStatus, statusLoader] = useCustomDispatch(
    changeChecklistStatusRequest
  );

  // CONST VALS
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const HEIGHT = 440;
  const BOX_HEIGHT = `calc(100vh - ${HEIGHT}px)`;
  const TABLE_COLUMNS = [
    {
      title: "Detail",
      isSort: true,
      key: "name",
    },
    {
      title: "Type",
      isSort: true,
      key: "type",
    },
    {
      title: "Target date",
      isSort: true,
      key: "targetDate",
    },
    ...[
      !isDeleted && {
        title: "Visible",
        isSort: false,
        isIcon: true,
        icon: <Images.Visibility />,
      },
    ],
    {
      title: "Actions",
      isSort: false,
      key: "status",
    },
  ];

  // HANDLERS
  const visiblityModalHandler = (item, isRoom) => {
    setvisiblityModal(true);
    setSelectedVisibility(item);
    setisRoomDetail(isRoom);
  };
  const visiblityModalPreviewHandler = () => {
    setvisiblityModal(!visiblityModal);
  };

  const updateModalHandler = (item) => {
    setupdateModal(true);
    setSelectedData(item);
  };
  const updateModalPreviewHandler = () => {
    setupdateModal(!updateModal);
  };

  const deleteModalHandler = (item) => {
    setdeleteModal(true);
    setSelectedData(item);
  };
  const deleteModalPreviewHandler = () => {
    setdeleteModal(!deleteModal);
  };

  const detailPageNavigator = (slug) => {
    navigate(`${pathname}/${slug}`);
  };

  const checklistStatusChangeHandler = (slug, id) => {
    changeChecklistStatus({
      pathParams: slug,
      otherkeys: { isList: true, id: id },
      logic(res) {
        handleDataUpdate();
      },
    });
  };

  return (
    <>
      <div className="checklist-datatable">
        <div className={`table-head ${isDeleted ? "deleted" : ""}`}>
          {TABLE_COLUMNS.map((item, index) => {
            const isSelected = item.key === selectedSort;
            const selectedCaret = isSelected && !selectedSortFilter;
            return (
              item && (
                <span
                  className={`head-ttl ${isSelected ? "active" : ""}`}
                  key={index}
                >
                  <span
                    className={item.isSort ? "c-p" : ""}
                    onClick={() => {
                      item.isSort && sortData(item.key);
                    }}
                  >
                    {item.title}
                    {item?.isIcon && item?.icon}
                    {item.isSort && (
                      <FontAwesomeIcon
                        icon={selectedCaret ? faCaretUp : faCaretDown}
                        className="sort-icon"
                      />
                    )}
                  </span>
                </span>
              )
            );
          })}
        </div>
        <div className="table-body">
          {isLoading ? (
            <CardLoader height={BOX_HEIGHT} size={18} />
          ) : (
            <CustomInfiniteScroll
              dataLength={data.length}
              total={total}
              next={handleFetch}
              maxHeight={HEIGHT}
              minHeight={BOX_HEIGHT}
            >
              {data.length >= 1 ? (
                data.map((item, index) => (
                  <div
                    className={`body-row c-p ${isDeleted ? "deleted" : ""}`}
                    key={index}
                    onClick={() => {
                      detailPageNavigator(item.slug);
                    }}
                  >
                    <div className="body-col">
                      <div className="detail-col">
                        <div className="title">{item.name}</div>
                        <div className="listed">
                          <span className="label">Listed On</span>
                          <VisibleToPeople
                            size={6}
                            data={item.visibility}
                            maxLength={4}
                            isRoom
                            detailModalHandler={() => {
                              visiblityModalHandler(item.visibility, true);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="body-col">
                      <span className="date">{item.type?.value}</span>
                    </div>
                    <div className="body-col">
                      <span className="date">
                        {item.targetDate ? item.targetDate : "---------------"}
                      </span>
                    </div>
                    {!isDeleted && (
                      <div className="body-col">
                        {item.responsibleParties?.length > 0 ? (
                          <VisibleToPeople
                            data={item.responsibleParties}
                            maxLength={5}
                            isCompany
                            detailModalHandler={() => {
                              visiblityModalHandler(item.responsibleParties);
                            }}
                          />
                        ) : (
                          "---------------"
                        )}
                      </div>
                    )}
                    <div className={`${isDeleted ? "deleted" : ""} body-col`}>
                      {!isDeleted ? (
                        <>
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <DisabledTooltip
                              innerSec={
                                <StatusToggle
                                  check={item.status}
                                  handleStatusChange={() => {
                                    checklistStatusChangeHandler(
                                      item.slug,
                                      item.id
                                    );
                                  }}
                                  confirmLoader={statusLoader}
                                  confirmDescription={
                                    "Are you sure you want to change this checklist's status?"
                                  }
                                  isStatic={!isEditAllowed}
                                />
                              }
                              visible={!isEditAllowed}
                            />
                          </span>
                          <span
                            className="action"
                            style={{ minWidth: 35 }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            {(isDeleteAllowed || isEditAllowed) &&
                              !item.status && (
                                <EditDeleteDropdown
                                  isEditable={isEditAllowed ?? false}
                                  isDelete={isDeleteAllowed ?? false}
                                  onEditClick={() => {
                                    updateModalHandler(item);
                                  }}
                                  onDeleteClick={() => {
                                    deleteModalHandler(item);
                                  }}
                                />
                              )}
                          </span>
                        </>
                      ) : (
                        <span
                          className="restore-thumb"
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteModalHandler(item);
                          }}
                        >
                          <Images.Restore />
                        </span>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <EmptyBox height={BOX_HEIGHT} />
              )}
            </CustomInfiniteScroll>
          )}
        </div>
      </div>
      <ChecklistVisibilityModal
        preview={visiblityModal}
        handleClose={visiblityModalPreviewHandler}
        data={selectedVisibility}
        isRoom={isRoomDetail}
        isCompany={!isRoomDetail}
        title={isRoomDetail ? "Listed Rooms" : "Visible"}
      />
      <CreateChecklistModal
        isUpdate
        data={selectedData}
        preview={updateModal}
        handleClose={updateModalPreviewHandler}
        handleSucess={handleDataUpdate}
      />
      <DeleteChecklistModal
        data={selectedData}
        preview={deleteModal}
        handleClose={deleteModalPreviewHandler}
        handleSuccess={handleDataUpdate}
      />
    </>
  );
};

export default ChecklistDataTable;
