import React, { useState } from "react";
import { Dropdown, Menu } from "antd";
import { Images } from "../../../../../../../themes";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { deleteDocumentRequest } from "../../../../../../../redux/slicers/transactiondataroom";
import { toastAlert } from "../../../../../../../services/utils";
import { useDispatch } from "react-redux";
import { setUserDetailDrawer } from "../../../../../../../redux/slicers/general";
import { DeleteModal, ProfileImage } from "../../../../../../../components";
import { MoveFileModal, UploadDocumentModal } from "..";
import { downloadImage } from "../../../../../../../helpers/downloadImage";
import "./styles.scss";

// NOTE CARD
function Card({
  data,
  optionsDisabled,
  editPreviewHandler,
  deletePreviewHandler,
  movePreviewHandler,
  isGreaterLength,
  isDownloadOnly,
  isEditAllowed,
}) {
  // HANDLERS
  const downloadDocHandler = () => {
    downloadImage(data.url, () => {}, data.url);
  };

  // CONST VALS
  const dispatch = useDispatch();
  const { creatorInfo } = data;
  const DOWNLOAD_ITEM = [
    {
      key: "4",
      label: (
        <span onClick={downloadDocHandler} className="working-groups-options">
          <p>Download</p>
        </span>
      ),
    },
  ];
  const MENU_ITEMS = [
    isEditAllowed
      ? {
          key: "1",
          label: (
            <span
              className="working-groups-options"
              onClick={editPreviewHandler}
            >
              <p>Edit</p>
            </span>
          ),
        }
      : [],
    {
      key: "2",
      label: (
        <span className="working-groups-options" onClick={deletePreviewHandler}>
          <p>Delete</p>
        </span>
      ),
    },
    {
      key: "3",
      label: (
        <span className="working-groups-options" onClick={movePreviewHandler}>
          <p>Move</p>
        </span>
      ),
    },
    ...DOWNLOAD_ITEM,
  ];

  // CUSTOM COMPONENT
  const userMenu = () => (
    <Menu items={isDownloadOnly ? DOWNLOAD_ITEM : MENU_ITEMS} />
  );
  return (
    <div
      className="document-card-wrapper"
      style={{ marginBottom: isGreaterLength ? 100 : 10 }}
    >
      <div className="card-left-wrapper">
        <div className="folder-name-wrapper">
          <Images.AuthDocument />
          <p>{data.name}</p>
        </div>
        <div className="creator-info">
          <ProfileImage
            username={creatorInfo.username}
            image={creatorInfo.image}
            isBlocked={!creatorInfo.isActive}
            color={creatorInfo.profilebg}
            onClick={() => {
              dispatch(
                setUserDetailDrawer({
                  visibility: true,
                  id: creatorInfo.userid ?? creatorInfo.id,
                })
              );
            }}
            size={10}
          />
          <p
            className="c-p"
            onClick={() => {
              dispatch(
                setUserDetailDrawer({
                  visibility: true,
                  id: data.userid,
                })
              );
            }}
          >
            {creatorInfo.username}
          </p>
        </div>
      </div>
      {!optionsDisabled && (
        <div className="options-wrapper">
          <Dropdown
            getPopupContainer={(trigger) => trigger.parentElement}
            trigger={"click"}
            overlay={userMenu()}
          >
            <Images.threeDot />
          </Dropdown>
        </div>
      )}
    </div>
  );
}

function DocumentCard({
  data,
  roomslug,
  parentslug,
  isGreaterLength,
  onDelete,
  isRoomOwner,
  onFileMove,
  isTransactionComplete,
  isEditAllowed,
}) {
  //STATES
  const [editModalPreview, seteditModalPreview] = useState(false);
  const [deleteModalPreview, setDeleteModalPreview] = useState(false);
  const [moveModalPreview, setMoveModalPreview] = useState(false);

  //CUSTOM DISPATCH
  const [deleteDocument, isLoading] = useCustomDispatch(deleteDocumentRequest);

  //HANDLERS
  const editModalPreviewHandler = () => {
    seteditModalPreview(!editModalPreview);
  };

  const deleteModalPreviewHandler = () => {
    setDeleteModalPreview(!deleteModalPreview);
  };
  const moveModalPreviewHandler = () => {
    setMoveModalPreview(!moveModalPreview);
  };

  const handleDelete = () => {
    deleteDocument({
      pathParams: data.slug,
      logic({ message }) {
        toastAlert(message);
        deleteModalPreviewHandler();
        onDelete();
      },
    });
  };
  return (
    <>
      <Card
        data={data}
        isDownloadOnly={
          (!data.isOwner && !isRoomOwner) ||
          isTransactionComplete ||
          !isEditAllowed
        }
        editPreviewHandler={editModalPreviewHandler}
        deletePreviewHandler={deleteModalPreviewHandler}
        movePreviewHandler={moveModalPreviewHandler}
        isGreaterLength={isGreaterLength}
        isEditAllowed={isEditAllowed}
        optionsDisabled={
          (!data?.isOwner && !isRoomOwner) || isTransactionComplete
        }
      />
      <DeleteModal
        preview={deleteModalPreview}
        handleClose={deleteModalPreviewHandler}
        title={`Delete Document`}
        description={`Are you sure you want to delete this document?`}
        innerSec={<Card data={data} optionsDisabled />}
        isLoading={isLoading}
        handleDelete={handleDelete}
      />
      <UploadDocumentModal
        preview={editModalPreview}
        handleClose={editModalPreviewHandler}
        isEdit
        data={data}
      />
      <MoveFileModal
        preview={moveModalPreview}
        handleClose={moveModalPreviewHandler}
        slug={data.slug}
        parentslug={parentslug}
        roomslug={roomslug}
        handleSuccess={onFileMove}
        isFolder={false}
        lastUpdated={data.lastUpdated}
      />
    </>
  );
}

export default DocumentCard;
