import React from "react";
import { Modal, Row, Col } from "antd";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ButtonComponent } from "../../../../../../../components";
import { Images } from "../../../../../../../themes";
const DeleteMilestoneModal = ({
  handleClose,
  data,
  preview,
  handleDelete,
  loader,
}) => {
  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={522}
      className="delete-milestone"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>Delete Milestone</h3>
            <p>Are You Sure To Delete Milestone</p>
          </div>
          <div className="milestone-detail">
            <div className="ttl">
              <span className="thumb">
                <Images.TransactionMilestoneThumb />
              </span>
              <h4>{data?.name}</h4>
            </div>
            <span className={`status ${!data?.status ? "pending" : ""}`}>
              {data?.status ? "Mark as Complete" : "Pending"}
            </span>
            <div className="config">
              <Row gutter={[10, 0]}>
                <Col xs={12}>
                  <span>
                    Start Date: <b>{data?.startDate}</b>
                  </span>
                </Col>
                {data?.endDate && (
                  <Col xs={12} style={{ textAlign: "right" }}>
                    <span>
                      End Date: <b>{data?.endDate}</b>
                    </span>
                  </Col>
                )}

                <Col xs={24}>
                  <span className="note">{data?.notes}</span>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="cancel-btn" onClick={handleClose}>
            Cancel
          </div>
          <ButtonComponent
            onClick={() => handleDelete(data.id)}
            text="Delete"
            className="delete-btn"
            isLoading={loader}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteMilestoneModal;
