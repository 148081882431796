import React from "react";
import { Images } from "../../../../../../../themes";
import { useNavigate } from "react-router-dom";

const TopHeader = () => {
  //  CONST VALS
  const navigate = useNavigate();

  return (
    <div className="room-detail">
      <div className="dtl-wrapper">
        <Images.BackArrow className="c-p" onClick={() => navigate(-1)} />
        <div className="top-heading">
          <h3>Checklist</h3>
          <p>View Details Below</p>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
