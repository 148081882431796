import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardLoader, VisibleToPeople } from "../../../../../../../components";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import {
  getThreadsRequest,
  getTransactionChatsListRequest,
  removeReactMsgRequest,
  sendMessageRequest,
  sendReactMsgRequest,
  setThread,
} from "../../../../../../../redux/slicers/chat";
import SocketIO from "../../../../../../../services/SocketIO";
import {
  compareFilesByExtension,
  convertMentionToUsers,
  encryptMessage,
  getEmojiCode,
  getFormattedDateOnlyByDay,
  isContainLinks,
  isValidLink,
  toastAlert,
} from "../../../../../../../services/utils";
import { Images } from "../../../../../../../themes";
import ChatInput from "../chatInput";
import MessageBox from "../messageBox";
import "./styles.scss";

function Threads({ handleClose, chat, room }) {
  //STATES
  const [value, setValue] = useState("");
  const [emojiOpen, setEmojiOpen] = useState(false);
  const [reactEmojiOpen, setReactEmojiOpen] = useState(false);
  const [allFiles, setAllFiles] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(false);
  const [reactOpen, setReactOpen] = useState(false);

  //CONST VALS
  const dispatch = useDispatch();

  //REFS
  const _allFilesRef = useRef(allFiles);
  const inputRef = useRef(null);

  //REDUX DATA
  const {
    selectedRoom,
    groupedMessages,
    offset,
    threadTyping: typing,
    threadId,
    threads,
    threadTyper,
    chatList,
  } = useSelector((state) => state.chat);
  const { chat_id, name: username } = useSelector((state) => state.user.data);

  //CUSTOM DISPATCH
  const [getThreads, isLoading] = useCustomDispatch(getThreadsRequest);
  const [sendMessage, sendLoader] = useCustomDispatch(sendMessageRequest);
  const [addReact, reactLoader] = useCustomDispatch(sendReactMsgRequest);
  const [removeReact, removeReactLoader] = useCustomDispatch(
    removeReactMsgRequest
  );
  const [getTransChats] = useCustomDispatch(getTransactionChatsListRequest);

  //HANDLERS
  const closeHandler = () => {
    handleClose(null);
  };

  const onEmojiClick = (e) => {
    inputRef.current.focus();
    const { selectionStart } = inputRef.current;
    const newVal =
      value.slice(0, selectionStart) + e.native + value.slice(selectionStart);
    setValue(newVal);
    const newCursor = selectionStart + e.native.length;
    setTimeout(
      () => inputRef.current.setSelectionRange(newCursor, newCursor),
      10
    );
  };

  const sendMessageHandler = (val) => {
    if (value?.trim() !== "" || allFiles?.length > 0) {
      const payload = new FormData();
      const mentionPayload = convertMentionToUsers(value.trim() ?? "");

      payload.append("message_text", encryptMessage(value.trim() ?? ""));
      payload.append("room_id", selectedRoom?.id);
      payload.append("senderID", chat_id);
      payload.append("receiverID", undefined);
      payload.append(
        "message_type",
        isValidLink(value.trim()) ? "url" : "text"
      );
      payload.append("parent_message_id", threadId);
      if (mentionPayload?.length > 0) {
        payload.append("mentioned_users", JSON.stringify(mentionPayload));
      }
      const files = [...allFiles];

      if (files?.length > 0) {
        files?.forEach((file) => {
          payload.append("files", file);
        });
      }

      sendMessage({
        payload: payload,
        logic() {
          setValue("");
          setEmojiOpen(false);
          setAllFiles([]);
          // scrollRef.current?.scrollIntoView({ behavior: "smooth" });
          inputRef.current.focus();
          getTransChats();
        },
      });
    }
  };

  const handleEnter = (e) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13 && !e.shiftKey) {
      // Don't generate a new line
      e.preventDefault();
      //Send message on enter
      sendMessageHandler(e.target.value);
    }
  };

  const handleAddFiles = (files) => {
    let temp = [...allFiles, ...files];
    if (temp.length > 10) {
      toastAlert("Maximum 10 files are allowed.", ALERT_TYPES.ERROR);
    }
    setAllFiles(temp.splice(0, 10)?.sort(compareFilesByExtension));

    if (_allFilesRef?.current) {
      _allFilesRef.current = [...allFiles, ...files];
    }
    inputRef.current.focus();
  };

  const handleRemoveFiles = (index) => {
    let temp = [...allFiles];
    temp.splice(index, 1);
    setAllFiles(temp);
  };

  const sendTypingEvent = (text) => {
    const payload = {
      senderID: chat_id,
      receiverID: selectedRoom?.receiverId,
      roomID: selectedRoom?.id,
      threadId: threadId,
      typer: username,
    };

    SocketIO.typingEvent({
      ...payload,
      type: text,
    });
  };

  const onKeyUp = () => {
    setTimeout(() => {
      sendTypingEvent(false);
    }, 1000);
  };

  const onKeyDown = (e) => {
    const isSpecial = e.key.length > 1;
    if (!isSpecial) {
      sendTypingEvent(true);
    }
  };

  const handleAddReact = (emoji) => {
    const payload = {
      emoji,
      message_id: selectedMessage?.id,
      room_id: selectedMessage?.roomId,
      receiverID: selectedMessage?.receiverId,
      senderID: chat_id,
    };

    addReact({
      payload: payload,
      logic() {
        setEmojiOpen(false);
      },
    });
  };

  const handleRemoveReact = (id) => {
    const payload = {
      reactor_id: chat_id,
      receiverID: selectedMessage?.receiverId,
      react_id: id,
    };

    removeReact({
      payload: payload,
      logic() {
        setEmojiOpen(false);
      },
    });
  };

  const onDefaultReact = (e) => {
    const emoji = getEmojiCode(e);
    handleAddReact(emoji);
  };

  //HOOKS
  useEffect(() => {
    if (threadId && selectedRoom) {
      const queryParams = { message_id: threadId };
      getThreads({ queryParams });
    }
    return;
  }, [threadId, selectedRoom]);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.style.height = "16px";
      inputRef.current.style.maxHeight = "52px";
      const scrollHeight = inputRef.current.scrollHeight;
      inputRef.current.style.height = scrollHeight + "px";
    }
    return;
  }, [inputRef, value]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(setThread(null));
  //   };
  // }, []);
  return (
    <div className="thread-wrapper">
      <div className="thread-header">
        <h3>Threads</h3>
        <Images.CloseIcon className="c-p" onClick={closeHandler} />
      </div>
      {isLoading ? (
        <CardLoader height={"100%"} />
      ) : (
        <>
          <div className="parent-message">
            <MessageBox
              data={threads?.parentMessage}
              chat={chat}
              isThread={true}
              isParent={true}
              emojiOpen={reactEmojiOpen}
              handleAddReact={handleAddReact}
              onEmojiClick={onDefaultReact}
              reactOpen={reactOpen}
              selectedMessage={selectedMessage}
              setEmojiOpen={setReactEmojiOpen}
              setReactOpen={setReactOpen}
              setSelectedMessage={setSelectedMessage}
              handleRemoveReact={handleRemoveReact}
              removeReactLoader={removeReactLoader}
            />
            {threads?.childMessages?.[1]?.length > 0 && (
              <div className="reply-count">
                <VisibleToPeople
                  data={threads?.parentMessage?.repliers}
                  maxLength={2}
                  size={"7px"}
                />
                <p>
                  {threads?.childMessages?.[1]?.length > 9
                    ? "9+"
                    : threads?.childMessages?.[1]?.length}{" "}
                  Repl
                  {threads?.childMessages?.[1]?.length > 1 ? "ies" : "y"}
                </p>
              </div>
            )}
          </div>
          <div className="replies-wrapper">
            <div className="replies-content">
              {threads?.childMessages?.[0]?.map((item, index) => (
                <React.Fragment key={index}>
                  <span className="chat-date">
                    {getFormattedDateOnlyByDay(item.date)}
                  </span>
                  {item?.messages?.map((data, ind) => {
                    const hasLink =
                      isContainLinks(data?.content)?.length > 0 ? true : false;

                    return data?.highlight ? (
                      <span className="highlight" key={ind}>
                        {data?.highlight}
                      </span>
                    ) : (
                      <MessageBox
                        chat={chat}
                        data={data}
                        isParent={false}
                        isThread={true}
                        key={ind}
                        emojiOpen={reactEmojiOpen}
                        handleAddReact={handleAddReact}
                        onEmojiClick={onDefaultReact}
                        reactOpen={reactOpen}
                        selectedMessage={selectedMessage}
                        setEmojiOpen={setReactEmojiOpen}
                        setReactOpen={setReactOpen}
                        setSelectedMessage={setSelectedMessage}
                        handleRemoveReact={handleRemoveReact}
                        removeReactLoader={removeReactLoader}
                      />
                    );
                  })}
                </React.Fragment>
              ))}
            </div>
          </div>
          {!selectedRoom?.isBlocked &&
            !selectedRoom?.isArchived &&
            (selectedRoom?.isGroup
              ? true
              : selectedRoom?.individual?.isActive) && (
              <div className="reply-input">
                <ChatInput
                  allFiles={allFiles}
                  emojiOpen={emojiOpen}
                  handleAddFiles={handleAddFiles}
                  handleEnter={handleEnter}
                  inputRef={inputRef}
                  handleRemoveFiles={handleRemoveFiles}
                  sendLoader={sendLoader}
                  onEmojiClick={onEmojiClick}
                  setEmojiOpen={setEmojiOpen}
                  setValue={setValue}
                  value={value}
                  sendMessageHandler={sendMessageHandler}
                  onKeyUp={onKeyUp}
                  onKeyDown={onKeyDown}
                  isTyping={typing}
                  selectedRoom={room}
                  placeholder="Reply here..."
                  typer={threadTyper}
                />
              </div>
            )}
        </>
      )}
    </div>
  );
}

export default Threads;
