import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CardLoader,
  EmptyBox,
  StatusToggle,
  Tabs,
  CustomInfiniteScroll,
  DisabledTooltip,
} from "../../../components";
import {
  changeTransactionStatusRequest,
  getTransactionsRequest,
} from "../../../redux/slicers/transactions";
import {
  DATA_PER_PAGE_LIMIT,
  EDIT_TRANSACTION_ROUTE,
  PERMISSIONS_ACCESS,
  TRANSACTIONS_STATUS_LIST,
  TRANSACTION_DETAIL_ROUTE,
} from "../../../constants";
import { useCustomDispatch } from "../../../helpers/useCustomDispatch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { Images } from "../../../themes";
import "./styles.scss";
import {
  getActionPermission,
  replaceValInString,
} from "../../../services/utils";
const TABLE_COLUMNS = [
  { title: "Name", key: "name" },
  { title: "Transaction Type", key: "type" },
  { title: "Status", key: "status" },
  { title: "Last Updated", key: "updated" },
  { title: "Created Date", key: "created" },
  { title: "", key: "name" },
];
function Transaction() {
  // CONST VALS
  const navigate = useNavigate();
  const { EDIT_TRANSACTION, UPDATE_TRANSACTION_STATUS } = PERMISSIONS_ACCESS;

  // STATES
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedSortKey, setselectedSortKey] = useState(null);
  const [selectedSortFilter, setselectedSortFilter] = useState(true);
  const [offset, setOffset] = useState(0);
  const [isMoreData, setMoreData] = useState(false);
  const [total, setTotal] = useState(0);

  // REDUX DATA
  const { transactions } = useSelector((state) => state.transactions);

  // CUSTOM DISPATCH
  const [getTransactions, transactionLoader] = useCustomDispatch(
    getTransactionsRequest
  );
  const [changeStatus, statusLoader] = useCustomDispatch(
    changeTransactionStatusRequest
  );

  // HELPERS
  const getTransactionsHelper = (isSearched) => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: offset,
      status: selectedStatus,
      sort: selectedSortKey,
      order: selectedSortFilter ? "asc" : "desc",
    };
    if (isSearched) {
      queryParams["name"] = isSearched;
      setMoreData(false);
    }

    getTransactions({
      queryParams,
      logic: (response) => {
        setTotal(response.total);
      },
    });
  };

  // HOOKS
  useEffect(() => {
    getTransactionsHelper(false);
  }, [selectedStatus, selectedSortKey, selectedSortFilter, offset]);

  // HANDLERS
  const setStatusHandler = (data) => {
    setSelectedStatus(data);
    setMoreData(false);
    setOffset(0);
  };
  const setSortKeyHandler = (key) => {
    const isAlreadyExist = selectedSortKey === key;
    setselectedSortKey(key);
    setselectedSortFilter(isAlreadyExist ? !selectedSortFilter : false);
    setMoreData(false);
    setOffset(0);
  };
  const handleSearch = (val) => {
    setOffset(0);
    getTransactionsHelper(val);
  };
  const handleNextData = () => {
    setOffset(offset + DATA_PER_PAGE_LIMIT);
    setMoreData(true);
  };
  const navigateToTransaction = (transaction, room) => {
    const vals = { ":id": transaction, ":room": room };
    navigate(replaceValInString(TRANSACTION_DETAIL_ROUTE, vals));
  };
  const changeStatusHandler = (slug) => {
    changeStatus({ pathParams: slug });
  };

  return (
    <section className="transactions-wrapper">
      <div className="top-container">
        <div className="top-heading">
          <p>View your active and completed transactions below</p>
          <h3>My Transactions</h3>
        </div>
        <Tabs
          buttonsList={TRANSACTIONS_STATUS_LIST}
          setState={setStatusHandler}
          state={selectedStatus}
        />
      </div>
      <div className="card">
        <div className="search-box">
          <Images.Search />
          <input
            type="text"
            placeholder={"Search any Transactions..."}
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
          />
        </div>
        <div className="table-container">
          <div className="table-container-row border-bottom">
            {TABLE_COLUMNS.map((item, index) => {
              const isSelected = selectedSortKey === item.key;
              const selectedCaret = isSelected && !selectedSortFilter;
              return (
                <li
                  className={`table-title c-p ${isSelected ? "active" : ""}`}
                  key={index}
                  onClick={() => {
                    setSortKeyHandler(item.key);
                  }}
                >
                  {item.title}
                  {index !== TABLE_COLUMNS.length - 1 && (
                    <FontAwesomeIcon
                      icon={selectedCaret ? faCaretUp : faCaretDown}
                      className="sort-icon"
                    />
                  )}
                </li>
              );
            })}
          </div>
          {transactionLoader & !isMoreData ? (
            <CardLoader height={400} size={20} />
          ) : (
            <>
              {transactions?.length >= 1 ? (
                <CustomInfiniteScroll
                  dataLength={transactions?.length}
                  total={total}
                  next={handleNextData}
                  maxHeight={400}
                >
                  {transactions.map((item, i) => {
                    const isOdd = i % 2 == 1;
                    const isEditAllowed = getActionPermission(
                      EDIT_TRANSACTION,
                      item?.permissions
                    );
                    const statusChangeAllowed = getActionPermission(
                      UPDATE_TRANSACTION_STATUS,
                      item?.permissions
                    );
                    return (
                      <div
                        key={i}
                        className={`table-container-row ${
                          isOdd ? "back-gray" : ""
                        }`}
                      >
                        <li
                          className="c-p t-name"
                          onClick={() =>
                            navigateToTransaction(item.slug, item.roomSlug)
                          }
                        >
                          <>
                            {!item.isTransactionAdmin ? (
                              <p className="trans-name">{item.name}</p>
                            ) : (
                              <div className="t-name">
                                <p className="trans-name">{item.name}</p>
                                <div>
                                  <img src={Images.crown} alt="crown" />
                                  <p>Transaction Admin</p>
                                </div>
                              </div>
                            )}
                            {/* <p className="count">{item.notificationCount}</p> */}
                          </>
                        </li>
                        <li>{item.type}</li>
                        <li>
                          <DisabledTooltip
                            innerSec={
                              <StatusToggle
                                check={item.status}
                                confirmDescription={
                                  "Are you sure you want to change this transaction's status?"
                                }
                                confirmLoader={statusLoader}
                                handleStatusChange={() =>
                                  changeStatusHandler(item?.slug)
                                }
                                isStatic={!statusChangeAllowed}
                              />
                            }
                            visible={!statusChangeAllowed}
                          />
                        </li>
                        <li>{item.lastUpdated}</li>
                        <li>{item.createdAt}</li>

                        <DisabledTooltip
                          style={{ width: "40px" }}
                          innerSec={
                            <li
                              className="c-p"
                              onClick={() =>
                                isEditAllowed &&
                                !item.status &&
                                navigate(EDIT_TRANSACTION_ROUTE + item.slug)
                              }
                            >
                              <Images.Edit
                                style={{
                                  opacity: `${item.status ? "0.5" : 1}`,
                                }}
                              />
                            </li>
                          }
                          visible={!isEditAllowed && !item.status}
                        />
                      </div>
                    );
                  })}
                </CustomInfiniteScroll>
              ) : (
                <EmptyBox height={400} size={16} imgwidt={30} />
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default Transaction;
