// @flow
import { createSlice } from "@reduxjs/toolkit";
import { current } from "@reduxjs/toolkit";

const initialState = {
  workingGroups: [],
  data: {},
  members: [],
  rooms: [],
  breakoutrooms: [],
  roomDetail: null,
  roomMembers: [],
  allRoomsMembers: [],
  transactionparties: [],
  transactionenterprises: [],
  transactionenterprisemembers: [],
  transactionAdmins: [],
  roomAccess: "deleted",
  roomAdmin: false,
  companyMembers: [],
  permissions: [],
  requests: [],
};

const TransactionDetailsReducer = createSlice({
  name: "transactiondetail",
  initialState,
  reducers: {
    // GET TRANSACTION DATA
    getTransactionDetailRequest() {},
    getTransactionDetailSuccess(state, action) {
      state.data = action.payload;
    },
    // REMOVE TRANSACTION DATA
    removeTransactionDetailRequest: () => initialState,

    // INVITE PARTY TO TRANSACTION
    invitePartyRequest() {},

    // GET TRANSACTION INVITATION DETAIL
    getTransactionInvitationDetail() {},

    // ACCEPT OR REJECT TRANSACTION INVITE REQUEST
    transactionInviteResponseRequest() {},

    getTransactionEnterprisesRequest() {},
    getTransactionEnterprisesSuccess(state, action) {
      state.transactionenterprises = action.payload;
    },

    // GET TRANSACTION COMPANIES OF PARTY ROLE
    getTransactionPartiesRequest() {},
    getTransactionPartiesSuccess(state, action) {
      state.transactionparties = action.payload;
    },

    // GET TRANSACTION WORKING GROUPS
    getWorkingGroupsRequest() {},
    getWorkingGroupsSuccess(state, action) {
      state.workingGroups = action.payload;
    },

    // GET TRANSACTION MEMBERS
    getTransactionMembersRequest() {},
    getTransactionMembersSuccess(state, action) {
      state.members = action.payload;
    },

    // GET ALL TRANSACTION ROOMS LIST
    getTransactionRoomsRequest() {},
    getTransactionRoomsSuccess(state, action) {
      state.rooms = action.payload;
    },

    // GET ALL TRANSACTION ROOMS LIST WITH MEMBERS
    getRoomsWithMembersRequest() {},
    getRoomsWithMembersSuccess(state, action) {
      state.rooms = action.payload;
    },

    // GET TRANSACTION BREAKOUT ROOMS LIST
    getBreakoutRoomsRequest() {},
    getBreakoutRoomsSuccess(state, action) {
      state.breakoutrooms = action.payload;
    },

    // GET TRANSACTION ENTERPRISES MEMBERS BY COMPANY AND TRANSACTION
    getEnterpriseMembersRequest() {},
    getEnterpriseMembersSuccess(state, action) {
      state.transactionenterprisemembers = action.payload;
    },

    // CREATE BREAKOUT ROOM
    createBreakoutRoomRequest() {},

    // UPDATE BREAKOUT ROOM
    updateBreakoutRoomRequest() {},

    // GET TRANSACTION ROOM DETAIL
    getRoomDetailRequest() {},
    getRoomDetailSuccess(state, { payload }) {
      state.roomDetail = payload;
      state.roomAdmin = payload.isAdmin;
      state.roomAccess = payload.roomStatus;
    },

    // REMOVE MEMBER FROM TRANSACTION
    removeParticipantRequest() {},

    // CHANGE USER ROLE IN ROOM FROM ADMIN TO PARTICPANT OR P TO A
    changeAdminRequest() {},
    makeBreakoutRoomAdminRequest() {},
    removeBreakoutRoomAdminRequest() {},

    // CHECK IF LOGIN USER IS ADMIN OR NOT ON LEAVE ROOM
    checkBreakoutRoomAdminRequest() {},

    // ADD TRANSACTION MEMEBER IN ROOM
    addMemberToRoomRequest() {},

    // GET ALL MEMEBERS OF MULTIPLE ROOM
    getAllRoomsMembersRequest() {},
    getAllRoomsMembersSuccess(state, action) {
      state.allRoomsMembers = action.payload;
    },

    // GET ALL MEMEBERS OF ROOM
    getRoomMembersRequest() {},
    getRoomMembersSuccess(state, action) {
      state.roomMembers = action.payload;
    },

    // LEAVE BREAKOUT ROOM
    leaveRoomRequest() {},

    //GET ALL TRANSACTION ADMINS
    getAllTransactionAdminsListRequest() {},
    getAllTransactionAdminsListSuccess(state, action) {
      state.transactionAdmins = action.payload;
    },

    //CHECK LAST TRANSACTION ADMIN
    checkLastTransactionAdminRequest() {},

    //CHANGE TRANSACTION ADMIN STATUS
    changeTransactionAdminStatusRequest() {},
    changeTransactionAdminStatusSuccess(state, action) {
      const { isRemoveMyself } = action.payload;
      if (isRemoveMyself) state.data.isAdminLoggedIn = false;
    },

    //REJECT REMOVAL
    rejectRemovalRequest() {},
    rejectRemovalSuccess(state) {
      state.roomAccess = "pending";
    },

    //ACCEPT REMOVAL
    acceptRemovalRequest() {},
    acceptRemovalSuccess(state) {
      state.roomAccess = "deleted";
    },

    //RESPOND TO REJOIN REQUEST
    respondToRejoinRequest() {},

    //LOGGED IN USER COMPANY MEMBERS
    getUserCompanyMembersRequest() {},
    getUserCompanyMembersSuccess(state, action) {
      state.companyMembers = action.payload;
    },

    //GET PERMISSIONS
    getUserPermissionsRequest() {},
    getUserPermissionsSuccess(state, action) {
      state.permissions = action.payload;
    },

    //UPDATE PERMISSIONS
    updateUserPermissionsRequest() {},
    updateUserPermissionsSuccess(state, action) {},

    //CHANGE STATUS
    changeTransactionDetailStatusSuccess(state, action) {
      state.data = action.payload;
    },

    //GET CHANGE REQUESTS
    getChangeRequestsListRequest() {},
    getChangeRequestsListSuccess(state, action) {
      state.requests = action.payload;
    },
  },
});

export const {
  getTransactionDetailRequest,
  getTransactionDetailSuccess,
  removeTransactionDetailRequest,
  invitePartyRequest,
  getTransactionInvitationDetail,
  transactionInviteResponseRequest,
  getTransactionEnterprisesRequest,
  getTransactionEnterprisesSuccess,
  getTransactionPartiesRequest,
  getTransactionPartiesSuccess,
  getWorkingGroupsRequest,
  getWorkingGroupsSuccess,
  getTransactionMembersRequest,
  getTransactionMembersSuccess,
  createBreakoutRoomRequest,
  leaveRoomRequest,
  removeParticipantRequest,
  changeAdminRequest,
  getEnterpriseMembersRequest,
  getEnterpriseMembersSuccess,
  getTransactionRoomsRequest,
  getTransactionRoomsSuccess,
  getBreakoutRoomsRequest,
  getBreakoutRoomsSuccess,
  getRoomDetailRequest,
  getRoomDetailSuccess,
  updateBreakoutRoomRequest,
  checkBreakoutRoomAdminRequest,
  addMemberToRoomRequest,
  getAllRoomsMembersRequest,
  getAllRoomsMembersSuccess,
  getRoomMembersRequest,
  getRoomMembersSuccess,
  makeBreakoutRoomAdminRequest,
  removeBreakoutRoomAdminRequest,
  getAllTransactionAdminsListRequest,
  getAllTransactionAdminsListSuccess,
  checkLastTransactionAdminRequest,
  changeTransactionAdminStatusRequest,
  changeTransactionAdminStatusSuccess,
  rejectRemovalRequest,
  rejectRemovalSuccess,
  acceptRemovalRequest,
  acceptRemovalSuccess,
  respondToRejoinRequest,
  getRoomsWithMembersRequest,
  getRoomsWithMembersSuccess,
  getUserCompanyMembersRequest,
  getUserCompanyMembersSuccess,
  getUserPermissionsRequest,
  getUserPermissionsSuccess,
  updateUserPermissionsRequest,
  updateUserPermissionsSuccess,
  changeTransactionDetailStatusSuccess,
  getChangeRequestsListRequest,
  getChangeRequestsListSuccess,
} = TransactionDetailsReducer.actions;

export default TransactionDetailsReducer.reducer;
