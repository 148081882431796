import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useCustomDispatch } from "../../../../../../helpers/useCustomDispatch";
import { getLinksRequest } from "../../../../../../redux/slicers/transactiondataroom";
import { Row, Col } from "antd";
import {
  DataroomNavbar,
  UploadLinkModal,
  DataRoomNotesBox,
  LinkCard,
} from "../components";
import {
  EmptyBox,
  CustomInfiniteScroll,
  CardLoader,
  ActivityLogs,
} from "../../../../../../components";
import { DATA_PER_PAGE_LIMIT } from "../../../../../../constants";

export default function DataRoomLinks({
  transactionslug,
  linkActivityLogsVisible,
  isLinkManageAllowed,
  isTransactionComplete,
}) {
  //STATES
  const [uploadLinksModalPreview, setUploadLinksModalPreview] = useState(false);
  const [isLinkCreated, setLinkCreated] = useState(false);
  const [isNoteCreated, setNoteCreated] = useState(false);
  const [linkSearchText, setLinkSearchText] = useState("");
  const [total, setTotal] = useState(null);
  const [offset, setOffset] = useState(0);
  const [isMoreData, setMoreData] = useState(false);

  // REDUX DATA
  const { links } = useSelector((state) => state.transactiondataroom);

  // CUSTOM DISPATCH
  const [getLinks, linksLoading] = useCustomDispatch(getLinksRequest);

  //CONST VALS
  const navigate = useNavigate();
  const HEIGHT = 340;
  const BOX_HEIGHT = `calc(100vh - ${HEIGHT}px)`;
  const isLoading = linksLoading && !isMoreData;

  // HELPERS
  const getLinksHelper = () => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset,
      search: linkSearchText,
    };
    getLinks({
      queryParams,
      pathParams: transactionslug,
      logic({ total }) {
        setTotal(total);
      },
    });
  };
  // HOOKS
  useEffect(() => {
    getLinksHelper();
  }, [transactionslug, offset, isLinkCreated, linkSearchText]);

  //HANDLERS
  const handleNextData = () => {
    setOffset(offset + DATA_PER_PAGE_LIMIT);
    setMoreData(true);
  };
  const uploadLinkPreviewHandler = () => {
    setUploadLinksModalPreview(!uploadLinksModalPreview);
  };
  const linkCreatedHandler = () => {
    setLinkCreated(!isLinkCreated);
  };
  const noteCreatedHandler = () => {
    setNoteCreated(!isNoteCreated);
  };
  const linkSearchTextHandler = (val) => {
    setLinkSearchText(val);
  };

  return (
    <>
      <Row>
        <Col xs={24} xl={18}>
          <DataroomNavbar
            title="External Data Room Links"
            isBack={true}
            backFunc={() => {
              navigate(-1);
            }}
            searchPlaceholder="Search any Link..."
            searchText={linkSearchText}
            searchHandler={linkSearchTextHandler}
            btnText="Upload Links +"
            btnFunc={uploadLinkPreviewHandler}
            isActionAllowed={isLinkManageAllowed}
          />
          <div className="card mt-3">
            <div className="links-room-wrapper">
              <div className="links-listing-wrapper">
                {isLoading ? (
                  <CardLoader height={BOX_HEIGHT} size={18} />
                ) : (
                  <CustomInfiniteScroll
                    dataLength={links.length}
                    total={total}
                    next={handleNextData}
                    maxHeight={HEIGHT}
                    minHeight={BOX_HEIGHT}
                    infiniteclass="ant-row rowgap-15 gap-10"
                  >
                    {links.length >= 1 ? (
                      links.map((item, index) => (
                        <Col
                          xxl={12}
                          xl={24}
                          key={index}
                          style={{ padding: "0 5px" }}
                        >
                          <LinkCard
                            data={item}
                            transactionslug={transactionslug}
                            noteCreatedHandler={noteCreatedHandler}
                            isLinkManageAllowed={isLinkManageAllowed}
                          />
                        </Col>
                      ))
                    ) : (
                      <EmptyBox height={BOX_HEIGHT} />
                    )}
                  </CustomInfiniteScroll>
                )}
              </div>
              <DataRoomNotesBox
                slug={transactionslug}
                isDataUpdated={isNoteCreated}
                height={410}
                isTransactionComplete={isTransactionComplete}
                isEditAllowed={isLinkManageAllowed}
              />
            </div>
          </div>
        </Col>
        <Col xs={24} xl={6}>
          <ActivityLogs
            title="External data room"
            height={300}
            isWholePage={true}
            transactionslug={transactionslug}
            customqueryParams={{ isLink: true }}
            isAccess={linkActivityLogsVisible}
          />
        </Col>
      </Row>
      <UploadLinkModal
        transactionslug={transactionslug}
        preview={uploadLinksModalPreview}
        handleSuccess={linkCreatedHandler}
        handleClose={uploadLinkPreviewHandler}
      />
    </>
  );
}
