import React, { useEffect, useState } from "react";
import images from "../../../../themes/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import OwlCarousel from "react-owl-carousel";
import { useCustomDispatch } from "../../../../helpers/useCustomDispatch";
import { getReviewsRequest } from "../../../../redux/slicers/general";
import { useSelector } from "react-redux";
import "./styles/reviews.scss";

function Reviews() {
  const { reviewsList } = useSelector((state) => state.general);

  // CUSTOM DISPATCH
  const [getReviewsList] = useCustomDispatch(getReviewsRequest);

  // HELPERS
  const getReviewsHelper = () => {
    const queryParams = {
      limit: 10,
      offset: 0,
    };
    getReviewsList({ queryParams });
  };

  useEffect(() => {
    getReviewsHelper();
  }, []);

  return (
    <section className="review-sec">
      <div className="container">
        <div className="review-header">
          <h1>
            See what Our Partners <span>say</span>
          </h1>
          <div id="review-nav"></div>
        </div>
      </div>

      <div className="container new-container">
        {reviewsList.length > 0 ? (
          <div className="carousel-container">
            <OwlCarousel
              className="reviews-row owl-main owl-theme"
              nav={true}
              navContainer="#review-nav"
              navText={
                reviewsList.length > 0 && [
                  `<img src=${images.backArrow} alt="prev-arrow" />`,
                  `<img src=${images.backArrow} alt="next-arrow" />`,
                ]
              }
              margin={30}
              loop={reviewsList.length > 4}
              autoWidth={true}
              dots={false}
              responsive={{
                600: {
                  items: 1,
                },
                1440: {
                  items: 3,
                },
              }}
            >
              {reviewsList.map((data, index) => {
                return (
                  <div className="review-box" key={`review-${index}`}>
                    <div className="client_info_wrapper">
                      <div className="client_img">
                        <img src={data.profileImg} alt={data.firstName} />
                      </div>
                      <div className="name">
                        <h4>{data.firstName}</h4>
                        <p>{data.role}</p>
                      </div>
                      <div className="colins">
                        <img src={images.colins} alt="" />
                      </div>
                    </div>
                    <div className="review">
                      <p>{data.description}</p>
                    </div>
                    <div className="rating">
                      {new Array(5).fill().map((_, index) => {
                        let color = "#DFDFDF";
                        if (data.ratingCount > index) {
                          color = "#F8BB46";
                        }
                        return (
                          <FontAwesomeIcon
                            icon={faStar}
                            color={color}
                            key={`icon-${index}`}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        ) : (
          <p className="empty-text">No Reviews Found</p>
        )}
      </div>
    </section>
  );
}

export default Reviews;
