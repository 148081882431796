import React from "react";
import { Form, Input, Modal } from "antd";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonComponent } from "../../../../../../../components";
import "./styles.scss";
import {
  inputFieldRule,
  toastAlert,
} from "../../../../../../../services/utils";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { rejectRemovalRequest } from "../../../../../../../redux/slicers/transactiondetail";
import { useParams } from "react-router-dom";
import { ALERT_TYPES } from "../../../../../../../constants";

const RemoveResponseModal = ({ handleClose, preview }) => {
  //CUSTOM DISPATCH
  const [rejectRemoval, isLoading] = useCustomDispatch(rejectRemovalRequest);

  //CONST VALS
  const [form] = Form.useForm();
  const { room } = useParams();

  //HANDLERS
  const closeHandler = () => {
    handleClose();
    form.resetFields();
  };

  const handleSubmit = (val) => {
    const payload = val;
    rejectRemoval({
      payload,
      pathParams: room,
      logic(res) {
        toastAlert(res.message, ALERT_TYPES.SUCCESS);
        closeHandler();
      },
    });
  };
  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={523}
      className="remove-response-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>Remove Participant Response</h3>
            <p>Why should not admin remove you</p>
          </div>
          <Form form={form} onFinish={handleSubmit}>
            <Form.Item
              name={"message"}
              rules={inputFieldRule({ name: "Response", isWhiteSpace: true })}
            >
              <Input.TextArea
                placeholder="Type your response here"
                className="textarea"
              />
            </Form.Item>
            <div className="modal-footer">
              <ButtonComponent text={"Submit"} isLoading={isLoading} />
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveResponseModal;
