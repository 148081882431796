import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Form, Input, Select } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ButtonComponent, Tabs } from "../../../../../../../components";
import { DocumentFileUploader } from "../";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import {
  createExhibitRequest,
  createVersionRequest,
  getDocumentsFilesExhibitsRequest,
} from "../../../../../../../redux/slicers/transactiondocuments";
import { UPLOAD_DOCUMENT_TABS } from "../../../../../../../constants";
import { inputFieldRule } from "../../../../../../../services/utils";
import { Images } from "../../../../../../../themes";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import "./styles.scss";

const UploadDocumentsModal = ({
  selecteddoc,
  preview,
  handleClose,
  handleSuccess,
}) => {
  //STATES
  const [isDataUpdated, setDataUpdated] = useState(false);
  const [uploadFrom, setuploadFrom] = useState(null);
  const [selectedTab, setSelectedTab] = useState(UPLOAD_DOCUMENT_TABS[0].id);

  // REDUX DATA
  const { docexhibits } = useSelector((state) => state.transactiondocuments);

  // CUSTOM DISPATCH
  const [getDocs, docsLoader] = useCustomDispatch(
    getDocumentsFilesExhibitsRequest
  );
  const [createExhibit, exhibitLoader] =
    useCustomDispatch(createExhibitRequest);
  const [createVersion, versionLoader] =
    useCustomDispatch(createVersionRequest);

  //CONST VALS
  const [form] = Form.useForm();
  const { room } = useParams();
  const isExhibitTab = selectedTab === UPLOAD_DOCUMENT_TABS[0].id;
  const isLoading = exhibitLoader || versionLoader;

  // HELPERS
  const requestHelper = (request, payload) => {
    request({
      payload,
      logic() {
        closeHandler();
        handleSuccess();
      },
    });
  };

  //HOOKS
  useEffect(() => {
    clearModalData();
  }, [selectedTab]);

  useEffect(() => {
    preview &&
      getDocs({ pathParams: selecteddoc, queryParams: { roomSlug: room } });
  }, [preview]);

  //HANDLERS
  const setDataUpdatedHandler = () => {
    setDataUpdated(!isDataUpdated);
  };

  const clearModalData = () => {
    form.resetFields();
    setDataUpdatedHandler();
  };

  const setFileWrapper = (file, uploadedfrom) => {
    form.setFieldsValue({ url: file });
    setuploadFrom(uploadedfrom);
  };

  const closeHandler = () => {
    handleClose();
    clearModalData();
  };

  const handleCreate = (val) => {
    const isDocument = docexhibits.find(
      (item) => item.slug === val.slug
    )?.isDocument;
    const payload = {
      ...val,
      roomSlug: room,
      uploadFrom,
      isDocument: isDocument,
    };
    if (isDocument) {
      payload["documentSlug"] = val.slug;
    } else {
      payload["exhibitSlug"] = val.slug;
    }
    requestHelper(isExhibitTab ? createExhibit : createVersion, payload);
  };

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={522}
      className="upload-transaction-document-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>Upload Documents</h3>
            <p>Add Details Below</p>
          </div>
          <Tabs
            buttonsList={UPLOAD_DOCUMENT_TABS}
            setState={setSelectedTab}
            state={selectedTab}
            className="upload-tabs"
          />
          <Form
            form={form}
            className="upload-trans-doc-form"
            onFinish={handleCreate}
          >
            <Row gutter={[21, 0]}>
              <DocumentFileUploader
                handleSucess={setFileWrapper}
                isDataUpdated={isDataUpdated}
                resetUploader={() => {
                  form.resetFields(["url"]);
                }}
              />
              <Col span={12}>
                <Form.Item
                  name="slug"
                  rules={inputFieldRule({
                    name: "Document",
                    isWhiteSpace: false,
                  })}
                >
                  <Select
                    placeholder={
                      isExhibitTab ? "Exhibit to:" : "New Version of:"
                    }
                    className="doc-select-option"
                    loading={docsLoader}
                    optionLabelProp="label"
                  >
                    {docexhibits.map((item, index) => (
                      <Select.Option
                        value={item.slug}
                        label={item.name}
                        key={index}
                      >
                        <div className="exhibit-option">
                          {item?.isDocument ? (
                            <h3>
                              {isExhibitTab ? "New Exhibit" : "New Version"}
                            </h3>
                          ) : (
                            <>
                              <Images.DocThumb />
                              <div className="exhibit-info">
                                <h3>{item.name}</h3>
                                <p>Exhibit</p>
                              </div>
                            </>
                          )}
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="name"
                  rules={inputFieldRule({
                    name: isExhibitTab ? "Exhibit name" : "Version name",
                    isMax: true,
                    max: 120,
                  })}
                >
                  <Input placeholder="Name" />
                </Form.Item>
              </Col>
              {!isExhibitTab && (
                <Col span={24}>
                  <Form.Item
                    name="notes"
                    rules={inputFieldRule({
                      name: "Notes",
                      isMax: true,
                      max: 1000,
                      isRequired: false,
                    })}
                  >
                    <Input.TextArea
                      style={{ height: 70 }}
                      placeholder="Notes"
                      className="form-select textarea"
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <div className="modal-footer">
              <ButtonComponent text="Upload Document" isLoading={isLoading} />
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default UploadDocumentsModal;
