import React, { useState, useEffect } from "react";
import { Drawer } from "antd";
import { ProfileImage } from "../";
import { useDispatch, useSelector } from "react-redux";
import { useCustomDispatch } from "../../helpers/useCustomDispatch";
import {
  getUserDetailRequest,
  getCompanyDetailRequest,
} from "../../redux/slicers/general";
import { closeDrawer } from "../../redux/slicers/general";
import { Images } from "../../themes";
import UserFields from "./userFields";
import CompanyFields from "./companyFields";
import { HorizontalLine, CardLoader } from "../index";
import "./styles.scss";
import {
  loadRoomRequest,
  makeGroupAdminRequest,
  removeGroupAdminRequest,
  removeGroupMembersRequest,
} from "../../redux/slicers/chat";
import { useLocation, useNavigate } from "react-router-dom";
import { replaceValInString } from "../../services/utils";
import { MESSAGES_ROUTE } from "../../constants";
import DeleteModal from "../deleteModal";
import LeaveRoomModal from "../LeaveRoomModal";

const userDetails = {
  title: "Personal Information",
  image: "profilePic",
  name: "username",
  profession: "jobTitle",
  profilebg: "profilebg",
};

const companyDetail = {
  title: "Company Information",
  image: "logo",
  name: "name",
  profession: "website",
  profilebg: "profilebg",
};

const UserDetailDrawer = () => {
  //STATES
  const [removeMemberModalPreview, setRemoveMemberModalPreview] =
    useState(false);
  const [adminModalPreview, setAdminModalPreview] = useState(false);

  // REDUX DATA
  const { visibility, isUserDetail, dataid, isMember, isAdmin, isGroup } =
    useSelector((state) => state.general.sideDrawer);
  const { userdetail, comapnydetail } = useSelector((state) => state.general);
  const { chat_id, user_id } = useSelector((state) => state.user.data);
  const { selectedRoom } = useSelector((state) => state.chat);

  // CUSTOM DISPATCH
  const [getUserDetail, userLoading] = useCustomDispatch(getUserDetailRequest);
  const [getCompanyDetail, companyLoading] = useCustomDispatch(
    getCompanyDetailRequest
  );
  const [loadRoom, loadRoomLoader] = useCustomDispatch(loadRoomRequest);
  const [removeMember, removeLoader] = useCustomDispatch(
    removeGroupMembersRequest
  );
  const [makeAdmin, makeAdminLoader] = useCustomDispatch(makeGroupAdminRequest);
  const [removeAdmin, removeAdminLoader] = useCustomDispatch(
    removeGroupAdminRequest
  );

  // CONST VALS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const detail = isUserDetail ? userDetails : companyDetail;
  const data = isUserDetail ? userdetail : comapnydetail;
  const isLoading = userLoading || companyLoading;
  const isProfileLocked =
    isUserDetail && !data.publicProfile && user_id !== dataid;
  const groupRouteRegex = /^\/messages\/group\/[\w-]+$/;
  const isGroupChat = groupRouteRegex?.test(pathname);
  // HOOKS
  useEffect(() => {
    if (visibility) {
      if (isUserDetail) {
        let queryParams = {};
        if (isGroup) {
          queryParams["room_id"] = selectedRoom?.id;
        }
        getUserDetail({
          pathParams: dataid,
          queryParams,
        });
      } else {
        getCompanyDetail({
          pathParams: dataid,
        });
      }
    }
  }, [visibility]);

  // HANDLERS
  const onClose = () => {
    dispatch(closeDrawer());
  };

  const directMesageHandler = (id) => {
    loadRoom({
      payload: {
        room_type: "individual",
        receiverID: chat_id,
        senderID: id,
      },
      logic(resp) {
        navigate(
          replaceValInString(MESSAGES_ROUTE, {
            ":chat_id": resp?.data?.room_slug,
          })
        );
        onClose();
      },
    });
  };

  const removeMemberHandler = () => {
    removeMember({
      payload: { room_id: selectedRoom?.id, user_id: data?.chat_id },
      logic() {
        removeMemberModalPreviewHandler();
      },
    });
  };

  const removeMemberModalPreviewHandler = () => {
    dispatch(closeDrawer());
    setRemoveMemberModalPreview(!removeMemberModalPreview);
  };
  const adminModalPreviewHandler = () => {
    dispatch(closeDrawer());
    setAdminModalPreview(!adminModalPreview);
  };

  const changeAdminHelper = () => {
    const payload = { room_id: selectedRoom?.id, user_id: data?.chat_id };
    if (userdetail?.isGroupChatAdmin) {
      removeAdmin({
        payload,
        logic() {
          adminModalPreviewHandler();
        },
      });
      return;
    }
    makeAdmin({
      payload,
      logic() {
        adminModalPreviewHandler();
      },
    });
  };

  return (
    <>
      <Drawer
        getContainer={false}
        placement="right"
        onClose={onClose}
        visible={visibility}
        className="userdetail-drawer"
        closeIcon={<Images.DrawerClose />}
      >
        <div className="user-profile-top">
          <h3>{detail.title}</h3>

          {isLoading ? (
            <CardLoader height={500} />
          ) : (
            <>
              {isProfileLocked ? (
                <div className="locked-profile">
                  <img src={Images.LockProfile} alt="" />
                  <h4>Profile Locked</h4>
                </div>
              ) : (
                <>
                  <ProfileImage
                    image={data?.[detail.image]}
                    color={data?.[detail.profilebg]}
                    username={data?.[detail.name]}
                    size={30}
                  />
                  <h3>{data?.[detail.name]}</h3>
                  {isUserDetail && !data?.isActive && (
                    <p className="deactivated">Deactivated</p>
                  )}
                  {!isUserDetail ? (
                    <a href={data?.[detail.profession]} target="_blank">
                      <p>{data?.[detail.profession]}</p>
                    </a>
                  ) : (
                    <p>{data?.[detail.profession]}</p>
                  )}
                </>
              )}
            </>
          )}
        </div>
        {!isProfileLocked && !isLoading && (
          <>
            <HorizontalLine />
            {isUserDetail ? (
              <UserFields
                data={data}
                directMesageHandler={directMesageHandler}
                directLoader={loadRoomLoader}
                user_id={user_id}
                isGroupChat={isGroupChat}
                selectedRoom={selectedRoom}
                handleRemove={removeMemberModalPreviewHandler}
                isMember={userdetail?.isGroupChatMember}
                isAdmin={userdetail?.isGroupChatAdmin}
                handleAdmin={adminModalPreviewHandler}
                isActive={userdetail?.isActive}
              />
            ) : (
              <CompanyFields data={data} />
            )}
          </>
        )}
      </Drawer>
      <DeleteModal
        btnText="Remove"
        preview={removeMemberModalPreview}
        title={"Remove Participant"}
        description={"Are you sure you want to remove this participant?"}
        handleClose={removeMemberModalPreviewHandler}
        innerSec={<></>}
        isLoading={removeLoader}
        handleDelete={removeMemberHandler}
      />
      <LeaveRoomModal
        open={adminModalPreview}
        handleClose={adminModalPreviewHandler}
        title={`${userdetail?.isGroupChatAdmin ? "Remove" : "Make"} Admin`}
        description={`Are you sure you want ${
          userdetail?.isGroupChatAdmin
            ? "to remove selected participant from admin"
            : "to make selected participant an admin"
        }?`}
        btnText={`${userdetail?.isGroupChatAdmin ? "Remove" : "Make"} Admin`}
        tooltip="Selected person will be assigned as an admin."
        isAdmin={false}
        onSubmit={changeAdminHelper}
        isAlreadyAdmin={userdetail?.isGroupChatAdmin}
        btnLoader={makeAdminLoader || removeAdminLoader}
      />
    </>
  );
};
export default UserDetailDrawer;
getUserDetailRequest;
