import LoginBg from "../assets/backgrounds/login.svg";
import backArrow from "../assets/icons/backarrow.svg";
import loginUser from "../assets/icons/loginUser.svg";
import companyLogo from "../assets/icons/company-logo.svg";
import companyImage from "../assets/icons/company-image.svg";
import user0 from "../assets/icons/userImage.svg";
import user1 from "../assets/icons/user.svg";
import user2 from "../assets/images/user-2.svg";
import user3 from "../assets/images/user-3.svg";
import user4 from "../assets/icons/user.svg";
import user5 from "../assets/images/user-2.svg";
import user6 from "../assets/images/user-3.svg";
import user7 from "../assets/icons/user.svg";
import user8 from "../assets/images/user-3.svg";
import resourcesBackground from "../assets/images/resources-background.svg";
import messagesBackground from "../assets/images/messages-background.svg";
import Company1 from "../assets/images/company-1.png";
import Company2 from "../assets/images/company-2.png";
import crown from "../assets/icons/crown.svg";
import suggestProfilePic from "../assets/images/suggestProfilePic.png";
import plusIcon from "../assets/icons/plusIcon.svg";
import ChangeTransactionType from "../assets/images/changeType.png";
// import AdminApproval from "../assets/images/adminApproval.png";
import enterpriseCompany from "../assets/images/enter-comp.png";
import individualsMask from "../assets/backgrounds/indivisuals-mask.svg";
import linkedinImage from "../assets/icons/linkedin.svg";
import Back from "../assets/icons/back.png";
import Forward from "../assets/icons/forward.png";
import ForwardWhite from "../assets/icons/forward-white.svg";
import LockProfile from "../assets/icons/lock_profile.svg";
import NoData from "../assets/icons/no_data.svg";
import NoAccess from "../assets/icons/no_access.svg";
import EditImageIcon from "../assets/icons/edit-image.svg";
import isAdminCrown from "../assets/icons/transaction-detail/isAdminCrown.svg";
import MemberPlus from "../assets/icons/transaction-detail/plus.svg";
import Message2 from "../assets/icons/message.png";
import Resources2 from "../assets/icons/eSetting.png";
import LogoText from "../assets/icons/logoText.svg";
import CompanyDummy from "../assets/images/company.svg";
import heroImage from "../assets/images/heroContent.png";
// import Feature1 from "../assets/images/home/feature-1.svg";
// import Feature2 from "../assets/images/home/feature-2.svg";
// import Feature3 from "../assets/images/home/feature-3.svg";
// import Feature4 from "../assets/images/home/feature-4.svg";
// import Feature5 from "../assets/images/home/feature-5.svg";
// import Feature6 from "../assets/images/home/feature-6.svg";
// import Feature7 from "../assets/images/home/feature-7.svg";
import clientImg1 from "../assets/images/home/client_img1.png";
import clientImg2 from "../assets/images/home/client_img2.png";
import clientImg3 from "../assets/images/home/client_img3.png";
import colins from "../assets/images/home/colins.png";
import backToTop from "../assets/images/home/top_btn.png";

//Tab Images Homepage
import Feature1 from "../assets/images/home/feature-1.png";
import Feature2 from "../assets/images/home/feature-2.png";
import Feature3 from "../assets/images/home/feature-3.png";
import Feature4 from "../assets/images/home/feature-4.png";
import Feature5 from "../assets/images/home/feature-5.png";
import Feature6 from "../assets/images/home/feature-6.png";
import Feature7 from "../assets/images/home/feature-7.png";

import { ReactComponent as City } from "../assets/icons/city.svg";
import { ReactComponent as Company } from "../assets/icons/company.svg";
import { ReactComponent as InviteInfo } from "../assets/icons/transaction-detail/inviteInfo.svg";
import { ReactComponent as Country } from "../assets/icons/country.svg";
import { ReactComponent as Email } from "../assets/icons/email.svg";
import { ReactComponent as HidePass } from "../assets/icons/hide-pass1.svg";
import { ReactComponent as Job } from "../assets/icons/job.svg";
import { ReactComponent as Logo } from "../assets/icons/logoNew.svg";
// import { ReactComponent as LogoText } from "../assets/icons/logoText.svg";
import { ReactComponent as Pass } from "../assets/icons/pass.svg";
import { ReactComponent as PhoneEmail } from "../assets/icons/phone-email.svg";
import { ReactComponent as Phone } from "../assets/icons/phone.svg";
import { ReactComponent as ShowPass } from "../assets/icons/show-pass1.svg";
import { ReactComponent as Visibility } from "../assets/icons/show-pass.svg";
import { ReactComponent as State } from "../assets/icons/state.svg";
import { ReactComponent as IncompleteTr } from "../assets/icons/incomplete-transaction.svg";
import { ReactComponent as User } from "../assets/icons/auth-user.svg";
import { ReactComponent as AuthUser } from "../assets/icons/user-dark.svg";
import { ReactComponent as AuthDocument } from "../assets/icons/auth-document.svg";
import { ReactComponent as Zip } from "../assets/icons/zip.svg";
import { ReactComponent as Address } from "../assets/icons/address.svg";
import { ReactComponent as Plus } from "../assets/icons/plus.svg";
import { ReactComponent as Home } from "../assets/icons/home.svg";
import { ReactComponent as Dashboard } from "../assets/icons/dashboard.svg";
import { ReactComponent as Transaction } from "../assets/icons/transaction.svg";
import { ReactComponent as Message } from "../assets/icons/message.svg";
import { ReactComponent as Resources } from "../assets/icons/resources.svg";
import { ReactComponent as TotalTransaction } from "../assets/icons/total-transaction.svg";
import { ReactComponent as ViewAll } from "../assets/icons/viewall.svg";
import { ReactComponent as Edit } from "../assets/icons/salePurshaseEditIcon.svg";
import { ReactComponent as CircleBadge } from "../assets/icons/circle-badge.svg";
import { ReactComponent as PendingTransaction } from "../assets/icons/pending-transaction.svg";
import { ReactComponent as Flag } from "../assets/icons/flag.svg";
import { ReactComponent as Setting } from "../assets/icons/setting.svg";
import { ReactComponent as PendingBadge } from "../assets/icons/pending-badge.svg";
import { ReactComponent as Linkedin } from "../assets/icons/linkedin.svg";
import { ReactComponent as Facebook } from "../assets/icons/facebook.svg";
import { ReactComponent as Twitter } from "../assets/icons/twitter.svg";
import { ReactComponent as Instagram } from "../assets/icons/instagram.svg";
import { ReactComponent as Telegram } from "../assets/icons/telegram.svg";
import { ReactComponent as TransactionDealRoom } from "../assets/icons/transaction-detail/deal-room.svg";
import { ReactComponent as TransactionChecklist } from "../assets/icons/transaction-detail/checklist.svg";
import { ReactComponent as TransactionDocuments } from "../assets/icons/transaction-documents.svg";
import { ReactComponent as TransactionDataRoom } from "../assets/icons/transaction-detail/data-room.svg";
import { ReactComponent as TransactionMessage } from "../assets/icons/transaction-detail/message.svg";
import { ReactComponent as TransactionMilestone } from "../assets/icons/transaction-detail/milestones.svg";
import { ReactComponent as TransactionSetting } from "../assets/icons/transaction-detail/setting.svg";
import { ReactComponent as TransactionMilestoneThumb } from "../assets/icons/transaction-detail/milestone-thumb.svg";
import { ReactComponent as DocThumb } from "../assets/icons/transaction-detail/doc-thumb.svg";
import { ReactComponent as AttachmentPin } from "../assets/icons/transaction-detail/attachment-pin.svg";
import { ReactComponent as threeDot } from "../assets/icons/threeDot.svg";
import { ReactComponent as ThreeDotV } from "../assets/icons/threeDotV.svg";
import { ReactComponent as Search } from "../assets/icons/search.svg";
import { ReactComponent as Triangle } from "../assets/icons/rectangle.svg";
import { ReactComponent as Question } from "../assets/icons/question.svg";
import { ReactComponent as Notification } from "../assets/icons/notification.svg";
import { ReactComponent as DownArrow } from "../assets/icons/downArrow.svg";
import { ReactComponent as UpArrow } from "../assets/icons/upArrow.svg";
import { ReactComponent as NotificationUser1 } from "../assets/icons/notification-user1.svg";
import { ReactComponent as NotificationUser2 } from "../assets/icons/notification-user2.svg";
import { ReactComponent as NotificationUser3 } from "../assets/icons/notification-user3.svg";
import { ReactComponent as NotificationUser4 } from "../assets/icons/notification-user4.svg";
import { ReactComponent as NotificationUser5 } from "../assets/icons/notification-user5.svg";
import { ReactComponent as Crown } from "../assets/icons/crown.svg";
import { ReactComponent as TransactionsComplete } from "../assets/icons/complete.svg";
import { ReactComponent as transactionTopArrow } from "../assets/icons/transaction-top-arrow.svg";
import { ReactComponent as transactionRightArrow } from "../assets/icons/transaction-rigth-arrow.svg";
import { ReactComponent as Eye } from "../assets/icons/eye.svg";
import { ReactComponent as Delete } from "../assets/icons/delete.svg";
import { ReactComponent as BackArrow } from "../assets/icons/back-arrow.svg";
import { ReactComponent as Upload } from "../assets/icons/upload.svg";
import { ReactComponent as Uploaded } from "../assets/icons/uploaded.svg";
import { ReactComponent as Download } from "../assets/icons/download.svg";
import { ReactComponent as EnterPriseSetting } from "../assets/icons/enterprice-setting.svg";
import { ReactComponent as AddressPlus } from "../assets/icons/address_plus.svg";
import { ReactComponent as DocumentDownload } from "../assets/icons/document-download.svg";
import { ReactComponent as AddPlus } from "../assets/icons/add-plus.svg";
import { ReactComponent as DrawerClose } from "../assets/icons/drawer-close.svg";
import { ReactComponent as Visible } from "../assets/icons/visible.svg";
import { ReactComponent as SuggestionPlus } from "../assets/icons/suggestion-plus.svg";
import { ReactComponent as AddLinkPlus } from "../assets/icons/plusIcon.svg";
import { ReactComponent as InviteRight } from "../assets/icons/checkmarkIcon.svg";
import { ReactComponent as InviteRequest } from "../assets/icons/requestIcon.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/closeIcon.svg";
import { ReactComponent as TableSort } from "../assets/icons/table-sort.svg";
import { ReactComponent as LinkIcon } from "../assets/icons/links.svg";
import { ReactComponent as LinkIconColored } from "../assets/icons/links-purple.svg";
import { ReactComponent as DataRoomIcon } from "../assets/icons/DataRoomIcon.svg";
import { ReactComponent as AddNoteIcon } from "../assets/icons/add-note.svg";
import { ReactComponent as Info } from "../assets/icons/transaction-detail/info.svg";
import { ReactComponent as SkipQuestion } from "../assets/images/SkipQuestion.svg";
import { ReactComponent as TypeWarning } from "../assets/images/TypeWarning.svg";
import { ReactComponent as UserAdd } from "../assets/icons/userAdd.svg";
import { ReactComponent as UserAdded } from "../assets/icons/userAdded.svg";
import { ReactComponent as FolderIcon } from "../assets/icons/folderIcon.svg";
import { ReactComponent as ResourcesLinkIcon } from "../assets/icons/link.svg";
import { ReactComponent as TeamManagement } from "../assets/images/teamManagement.svg";
import { ReactComponent as CrossRed } from "../assets/icons/crossRed.svg";
import { ReactComponent as UserBlock } from "../assets/icons/userBlock.svg";
import { ReactComponent as DocChildThumb } from "../assets/icons/doc-child-thumb.svg";
import { ReactComponent as SelectArrow } from "../assets/icons/selectArrow.svg";
import { ReactComponent as GridView } from "../assets/icons/grid-icon.svg";
import { ReactComponent as ListView } from "../assets/icons/list-icon.svg";
import { ReactComponent as Unlink } from "../assets/icons/link-slash.svg";
import { ReactComponent as Restore } from "../assets/icons/restore.svg";
import { ReactComponent as AdminApproval } from "../assets/images/approval.svg";
import { ReactComponent as ScrollToTop } from "../assets/icons/scrollToTop.svg";
import { ReactComponent as Requests } from "../assets/icons/request.svg";
import { ReactComponent as MilestoneSolid } from "../assets/icons/milestone.svg";
import { ReactComponent as ArrowSolid } from "../assets/icons/arrowSolid.svg";
import { ReactComponent as Permissions } from "../assets/icons/permission.svg";
import { ReactComponent as Emoji } from "../assets/icons/emoji.svg";
import { ReactComponent as Attachment } from "../assets/icons/attachment.svg";
import { ReactComponent as SendMessage } from "../assets/icons/sendMessage.svg";
import { ReactComponent as Conversation } from "../assets/icons/conversation.svg";
import { ReactComponent as CancelImage } from "../assets/icons/cancel-img.svg";
import { ReactComponent as ChatDoc } from "../assets/icons/chatDoc.svg";
import { ReactComponent as DownloadAttachment } from "../assets/icons/downloadDoc.svg";
import { ReactComponent as AddReact } from "../assets/icons/addReact.svg";
import { ReactComponent as React } from "../assets/icons/react.svg";
import { ReactComponent as Thread } from "../assets/icons/thread.svg";
import { ReactComponent as ChatLink } from "../assets/icons/chatLinks.svg";
import { ReactComponent as ChatDocAtt } from "../assets/icons/chatDocAttach.svg";
import { ReactComponent as ChatImg } from "../assets/icons/chatImg.svg";
import { ReactComponent as ExternalLink } from "../assets/icons/externalLink.svg";
import { ReactComponent as ForwardOutlined } from "../assets/icons/forward.svg";
import { ReactComponent as GroupPlus } from "../assets/icons/groupPlus.svg";
import { ReactComponent as AddGroup } from "../assets/icons/add-group.svg";

export default {
  LoginBg,
  backArrow,
  City,
  Company,
  Country,
  Email,
  HidePass,
  Job,
  Logo,
  Pass,
  PhoneEmail,
  Phone,
  ShowPass,
  State,
  User,
  Zip,
  Setting,
  Address,
  Plus,
  Home,
  Dashboard,
  Transaction,
  Message,
  Resources,
  TotalTransaction,
  ViewAll,
  Edit,
  CircleBadge,
  PendingTransaction,
  user1,
  user2,
  user3,
  user4,
  user5,
  user6,
  user7,
  user8,
  Flag,
  PendingBadge,
  Linkedin,
  Facebook,
  Twitter,
  TransactionDocuments,
  TransactionChecklist,
  TransactionDataRoom,
  TransactionDataRoom,
  TransactionDealRoom,
  TransactionMessage,
  TransactionMilestone,
  TransactionSetting,
  TransactionMilestoneThumb,
  DocThumb,
  AttachmentPin,
  Company1,
  Company2,
  threeDot,
  Search,
  Triangle,
  Question,
  Notification,
  loginUser,
  DownArrow,
  UpArrow,
  NotificationUser1,
  NotificationUser2,
  NotificationUser3,
  NotificationUser4,
  NotificationUser5,
  Crown,
  crown,
  resourcesBackground,
  messagesBackground,
  TransactionsComplete,
  transactionTopArrow,
  transactionRightArrow,
  suggestProfilePic,
  plusIcon,
  ChangeTransactionType,
  AdminApproval,
  individualsMask,
  Delete,
  Eye,
  BackArrow,
  Upload,
  Uploaded,
  Download,
  EnterPriseSetting,
  companyLogo,
  AddressPlus,
  linkedinImage,
  AuthUser,
  AuthDocument,
  DocumentDownload,
  AddPlus,
  user0,
  enterpriseCompany,
  Back,
  Forward,
  companyImage,
  DrawerClose,
  Visible,
  SuggestionPlus,
  AddLinkPlus,
  InviteRight,
  InviteRequest,
  CloseIcon,
  TableSort,
  LockProfile,
  NoData,
  Info,
  SkipQuestion,
  TypeWarning,
  IncompleteTr,
  EditImageIcon,
  InviteInfo,
  isAdminCrown,
  MemberPlus,
  LinkIcon,
  ForwardWhite,
  LinkIconColored,
  AddNoteIcon,
  UserAdd,
  UserAdded,
  Message2,
  Resources2,
  DataRoomIcon,
  LogoText,
  FolderIcon,
  ResourcesLinkIcon,
  TeamManagement,
  CompanyDummy,
  CrossRed,
  Visibility,
  UserBlock,
  DocChildThumb,
  SelectArrow,
  GridView,
  ListView,
  Unlink,
  Restore,
  heroImage,
  ScrollToTop,
  Feature1,
  Feature2,
  Feature3,
  Feature4,
  Feature5,
  Feature6,
  Feature7,
  clientImg1,
  clientImg2,
  clientImg3,
  colins,
  backToTop,
  Instagram,
  Telegram,
  NoAccess,
  Requests,
  MilestoneSolid,
  ArrowSolid,
  Permissions,
  ThreeDotV,
  Emoji,
  Attachment,
  SendMessage,
  Conversation,
  CancelImage,
  ChatDoc,
  DownloadAttachment,
  AddReact,
  React,
  Thread,
  ChatLink,
  ChatDocAtt,
  ChatImg,
  ExternalLink,
  ForwardOutlined,
  GroupPlus,
  AddGroup,
};
