import React, { useEffect, useRef, useState } from "react";
import { Images } from "../../themes";
import "./styles.scss";

const FILTER_TAB = [
  {
    value: null,
    text: "All",
  },
  {
    value: false,
    text: "Pending",
  },
  {
    value: true,
    text: "Completed",
  },
];

const CardHeader = ({
  title,
  isLg,
  viewAll,
  placeholder,
  isFilterTab = true,
  isFilter,
  viewAllNavigation,
  style,
  handleSearch,
  isSearch = true,
  searchText,
  fiterTabs = FILTER_TAB,
  selectedFilter = FILTER_TAB[0].value,
  setFilter = () => {},
}) => {
  // STATES
  const [isSearchOpen, setSearchOpen] = useState(false);

  // REFS
  const searchRef = useRef();

  // HOOKS
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchRef]);

  return (
    <div style={style} className="card-header">
      <div className="title">
        <h4 style={{ fontSize: isLg ? 24 : 20 }}>{title}</h4>
        {viewAll && (
          <h5 onClick={viewAllNavigation}>
            View All <Images.ViewAll />
          </h5>
        )}
      </div>
      {isFilterTab && (
        <div className="filter">
          {isFilter ? (
            <div className="search-box">
              <Images.Search />
              <input
                type="text"
                placeholder={placeholder}
                value={searchText}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
              />
            </div>
          ) : (
            <div className="filteration">
              {isSearch && (
                <div
                  className="search-popup"
                  style={{ position: "relative" }}
                  ref={searchRef}>
                  <button
                    onClick={() => {
                      setSearchOpen(!isSearchOpen);
                    }}>
                    <Images.Search />
                  </button>
                  <div
                    className="search-dropdown"
                    style={{ display: isSearchOpen ? "block" : "none" }}>
                    <div className="search-box">
                      <Images.Search />
                      <input
                        type="text"
                        placeholder={placeholder}
                        onChange={(e) => {
                          handleSearch(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              <ul className="filter-tab">
                {fiterTabs.map((item, index) => (
                  <li
                    style={{ fontSize: isLg ? 14 : 12 }}
                    key={index}
                    className={selectedFilter === item.value ? "active" : ""}
                    onClick={() => {
                      setFilter(item.value);
                    }}>
                    {item.text}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CardHeader;
