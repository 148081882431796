import React, { useRef } from "react";
import { Images } from "../../../themes";
import { Reviews } from "../home/components";
import { Col, Row, Form, Input } from "antd";
import { inputFieldRule, toastAlert } from "../../../services/utils";
import PhoneInput from "react-phone-input-2";
import "./styles.scss";
import { contactFormSubmitRequest } from "../../../redux/slicers/general";
import { useCustomDispatch } from "../../../helpers/useCustomDispatch";
import { ButtonComponent } from "../../../components";
import { trimEnd } from "lodash";
import ReCAPTCHA from "react-google-recaptcha";

function ContactUs() {
  //CUSTOM DISPATCH
  const [contactFormSubmit, isLoading] = useCustomDispatch(
    contactFormSubmitRequest
  );
  const [form] = Form.useForm();
  const captchaRef = useRef(null);

  // FORM SUBMIT HANDLER
  const handleSubmit = (val) => {
    const payload = { ...val };

    contactFormSubmit({
      payload,
      logic({ message }) {
        toastAlert(message);
        form.resetFields();
      },
    });
  };
  return (
    <section className="contact-wrapper">
      <div className="form_sec">
        <div className="container">
          <div className="content">
            <h1>
              DealTru Digitizes Transactions <span>Sign Up Today</span>
            </h1>
            <p>
              Please complete contact details below. A member of our team will
              reach out to you.
            </p>
          </div>
          <div className="social_icons">
            <a
              href="https://www.facebook.com/profile.php?id=100094924308905"
              referrerPolicy="no-referrer"
              target="_blank"
            >
              <Images.Facebook />
            </a>
            <a
              href="https://twitter.com/deal_tru"
              referrerPolicy="no-referrer"
              target="_blank"
            >
              <Images.Twitter />
            </a>
            <a
              href="https://www.linkedin.com"
              referrerPolicy="no-referrer"
              target="_blank"
            >
              <Images.Linkedin />
            </a>
            {/* <a
							href="https://www.instagram.com"
							referrerPolicy="no-referrer"
							target="_blank">
							<Images.Instagram />
						</a> */}
          </div>
          <div className="form_wrapper">
            <Form form={form} onFinish={handleSubmit}>
              <Row gutter={[30, 0]}>
                <Col xs={24} lg={12}>
                  <div className="feild_container">
                    <label>First Name *</label>
                    <Form.Item
                      name="firstName"
                      rules={inputFieldRule({
                        name: "First Name",
                        isMax: true,
                        max: 80,
                      })}
                    >
                      <Input className="field" />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} lg={12}>
                  <div className="feild_container">
                    <label>Last Name *</label>
                    <Form.Item
                      name="lastName"
                      rules={inputFieldRule({
                        name: "Last Name",
                        isMax: true,
                        max: 80,
                      })}
                    >
                      <Input className="field" />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} lg={12}>
                  <div className="feild_container">
                    <label>EMAIL *</label>
                    <Form.Item
                      name="email"
                      rules={inputFieldRule({
                        name: "Email",
                        isEmail: true,
                        isMax: true,
                        max: 100,
                      })}
                    >
                      <Input className="field" />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} lg={12}>
                  <div className="feild_container">
                    <label>PHONE *</label>
                    <Form.Item
                      rules={inputFieldRule({
                        name: "Phone number",
                        isWhiteSpace: false,
                        isPhone: true,
                      })}
                      name="phone"
                    >
                      <PhoneInput
                        className="phone-field"
                        placeholder=""
                        country={"us"}
                        isValid={(value, country) => {
                          if (value.match(/12345/)) {
                            return (
                              "Invalid value: " + value + ", " + country.name
                            );
                          } else if (value.match(/1234/)) {
                            return false;
                          } else {
                            return true;
                          }
                        }}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} lg={24}>
                  <div className="feild_container">
                    <label>YOUR MESSAGE *</label>
                    <Form.Item
                      rules={inputFieldRule({
                        name: "Message",
                        isMax: true,
                        max: 1000,
                      })}
                      name="message"
                    >
                      <Input.TextArea placeholder="Add message here for us" />
                    </Form.Item>

                    {/* GOOGLE RECAPTCHA VIEW */}
                    <div className="captcha-wrap">
                      <Form.Item
                        rules={inputFieldRule({
                          name: "Verification",
                          isWhiteSpace: false,
                        })}
                        name="verification"
                      >
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_SITE_KEY}
                          className={"custom-recaptcha"}
                          ref={captchaRef}
                        />
                      </Form.Item>
                    </div>
                    <div className="form_btn">
                      <ButtonComponent text={"Submit"} isLoading={isLoading} />
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        <div className="blur-effect" />
      </div>
      <Reviews />
    </section>
  );
}

export default ContactUs;
