import axios from "axios";

// GENERATE REFRESH TOKEN
export const downloadImage = (filename, setLoader, link) => {
  const url = link;
  const filedata = filename.split("/")[filename.split("/").length - 1];
  const name = filedata.split("?")[0];
  setLoader && setLoader(true);
  axios({
    url: url,
    method: "GET",
    responseType: "blob",
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      setLoader && setLoader(false);
    })
    .catch((e) => {
      console.error(e);
      setLoader && setLoader(false);
    });
};
