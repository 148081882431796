import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { Images } from "../../../../../themes";
import { useCustomDispatch } from "../../../../../helpers/useCustomDispatch";
import { getChatDetailsRequest } from "../../../../../redux/slicers/chat";
import { useSelector } from "react-redux";
import "./styles.scss";
import {
  ButtonComponent,
  CardLoader,
  EmptyBox,
  ProfileImage,
} from "../../../../../components";
import { downloadImage } from "../../../../../helpers/downloadImage";
import { PuffLoader } from "react-spinners";
import { DEFAULT_PROFILE_COLOR } from "../../../../../constants";

const ChatDetailModal = ({
  handleClose,
  preview,
  chat,
  isTransaction = false,
  blockTitle,
  archiveTitle,
  handleClick,
  handleEdit,
}) => {
  //STATES
  const [downloadLoading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  //REDUX DATA
  const { selectedRoom, chatDetails } = useSelector((state) => state.chat);

  //CUSTOM DISPATCH
  const [getDetails, detailsLoader] = useCustomDispatch(getChatDetailsRequest);

  //HANDLERS
  const onDownload = (fileName, url) => {
    downloadImage(fileName, setLoading, url);
  };

  //HOOKS
  useEffect(() => {
    if (preview) {
      getDetails({ queryParams: { room_id: selectedRoom?.id } });
    }
  }, [preview]);

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={549}
      className="chat-details-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <Images.CloseIcon className="close-btn" onClick={handleClose} />
          <div className="info-wrapper">
            <div className="left">
              <ProfileImage
                image={
                  selectedRoom?.isGroup ? null : selectedRoom?.individual?.image
                }
                username={selectedRoom?.name}
                color={
                  selectedRoom?.individual?.bgColor ?? DEFAULT_PROFILE_COLOR
                }
              />
              <div className="room-name-wrapper">
                <h3 className="name">{selectedRoom?.name}</h3>
                {selectedRoom?.isGroup && (
                  <p className="date">Created on: {selectedRoom?.createdAt}</p>
                )}
              </div>
            </div>
            {selectedRoom?.isGroup &&
              !isTransaction &&
              selectedRoom?.isAdminLoggedIn && (
                <Images.Edit className="c-p" onClick={handleEdit} />
              )}
          </div>
          {detailsLoader ? (
            <CardLoader size={15} height={200} />
          ) : (
            <>
              <div className="content-wrapper">
                <h3>Links</h3>
                {chatDetails?.links?.length < 1 ? (
                  <EmptyBox height={130} imgwidth={60} size={12} />
                ) : (
                  <div className="wrapper">
                    {chatDetails?.links?.map((item, index) => (
                      <div className="link-item" key={index}>
                        <div className="left">
                          <Images.ChatLink />
                          <div className="link-info-text">
                            <p>{item?.url}</p>
                            <span>
                              <ProfileImage
                                image={item?.senderImage}
                                username={item?.senderName}
                                size={5}
                              />
                              {item?.senderName}
                            </span>
                          </div>
                        </div>
                        <a href={item?.url} target="_blank">
                          <Images.ExternalLink className="option" />
                        </a>
                      </div>
                    ))}
                  </div>
                )}
              </div>{" "}
              <div className="content-wrapper">
                <h3>Files</h3>
                {chatDetails?.attachments?.length < 1 ? (
                  <EmptyBox height={130} imgwidth={60} size={12} />
                ) : (
                  <div className="wrapper">
                    {chatDetails?.attachments?.map((item, index) => (
                      <div className="link-item" key={index}>
                        <div className="left">
                          {item?.isImage ? (
                            <Images.ChatImg />
                          ) : (
                            <Images.ChatDocAtt />
                          )}
                          <div className="link-info-text">
                            <p>{item?.fileName}</p>
                            <span>
                              <ProfileImage
                                image={item?.senderImage}
                                username={item?.senderName}
                                size={5}
                              />
                              {item?.senderName}
                            </span>
                          </div>
                        </div>
                        {downloadLoading && selected == item ? (
                          <PuffLoader size={15} color="#1C2B48" />
                        ) : (
                          <Images.DownloadAttachment
                            className="option c-p"
                            onClick={() => {
                              setSelected(item);
                              onDownload(item?.fileName, item?.url);
                            }}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
          {!isTransaction &&
            (selectedRoom?.isGroup ? selectedRoom?.isAdminLoggedIn : true) && (
              <div className="block-wrapper">
                <ButtonComponent
                  onClick={handleClick}
                  text={selectedRoom?.isGroup ? archiveTitle : blockTitle}
                  disabled={detailsLoader}
                />
              </div>
            )}
        </div>
      </div>
    </Modal>
  );
};

export default ChatDetailModal;
