import React from "react";

function ChatBlocked({
  isBlockedByOther,
  onUnblock,
  isGroup,
  isAdmin,
  selectedRoom,
}) {
  return (
    <div className="chat-blocked-wrapper">
      <p>
        {isGroup
          ? "This chat has been archived by group admin."
          : !selectedRoom?.individual?.isActive
          ? "This user is blocked by Super Admin"
          : isBlockedByOther
          ? "You have been blocked by this user."
          : "You blocked this user."}
      </p>
      {!isBlockedByOther &&
        (isGroup ? isAdmin : true) &&
        selectedRoom?.individual?.isActive && (
          <div className="unblock-btn c-p" onClick={onUnblock}>
            {isGroup ? "Unarchive" : "Unblock"}
          </div>
        )}
    </div>
  );
}

export default ChatBlocked;
