import React from "react";
import { Form, Input, Modal } from "antd";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonComponent } from "../../../../../../../components";
import "./styles.scss";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import {
  rejectRemovalRequest,
  respondToRejoinRequest,
} from "../../../../../../../redux/slicers/transactiondetail";
import { useParams } from "react-router-dom";
import { useState } from "react";

const RespondModal = ({ handleClose, preview, response, handleSuccess }) => {
  //STATES
  const [selectedOption, setSelectedOption] = useState(false);
  //CUSTOM DISPATCH
  const [respondRejoin, isLoading] = useCustomDispatch(respondToRejoinRequest);

  //CONST VALS
  const { room } = useParams();

  //HANDLERS
  const closeHandler = () => {
    handleClose();
  };

  const handleResponse = (val) => {
    setSelectedOption(val);
    const payload = { isAccept: val, userId: response?.id };
    respondRejoin({
      payload,
      pathParams: room,
      logic(res) {
        closeHandler();
        handleSuccess();
      },
    });
  };

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={523}
      className="respond-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>Participant Response</h3>
            <p>View Details</p>
          </div>
          <p className="response-message">{response?.message}</p>
          <div className="modal-footer">
            <ButtonComponent
              text={"Approve"}
              isLoading={isLoading && selectedOption}
              onClick={() => handleResponse(true)}
            />
            <ButtonComponent
              text={"Suspend"}
              isLoading={isLoading && !selectedOption}
              className="sus-btn"
              onClick={() => handleResponse(false)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RespondModal;
