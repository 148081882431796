import React, { useEffect } from "react";
import { Layout } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Header,
  UserDetailDrawer,
  AdminApprovalModal,
  PendingApprovalModal,
  DashboardInviteModal,
} from "../../components";
import { SideNav } from "./components";
import {
  ALERT_TYPES,
  CREATE_TRANSACTION_ROUTE,
  DATA_PER_PAGE_LIMIT,
  EDIT_TRANSACTION_ROUTE,
  GROUP_MESSAGES_ROUTE,
  HOME_ROUTE,
  MESSAGES_ROUTE,
  TRANSACTION_TABS_ROUTE,
} from "../../constants";
import "./styles.scss";
import {
  addUniqueId,
  fetchChatToken,
  fetchToken,
  requestPermission,
} from "../../firebase";
import { useCustomDispatch } from "../../helpers/useCustomDispatch";
import { getLoggedInUserCompanyRequest } from "../../redux/slicers/company";
import { useDispatch, useSelector } from "react-redux";
import { closeTransactionInviteModal } from "../../redux/slicers/general";
import SocketIO from "../../services/SocketIO";
import {
  getChatAccessToken,
  replaceValInString,
  toastAlert,
} from "../../services/utils";
import {
  manipulateChatMessageData,
  manipulateChatsList,
  manipulateReactData,
} from "../../dataManipulator/chat";
import {
  addReceiveMessageToRoom,
  archiveRoomSuccess,
  blockRoomSuccess,
  editGroupSuccess,
  getChatListRequest,
  getChatListSuccess,
  getGroupParticipantsRequest,
  getSidebarChatListRequest,
  getTransactionChatsListRequest,
  leaveChatSuccess,
  makeGroupAdminSuccess,
  onAdminMade,
  onAdminRemoved,
  removeGroupAdminSuccess,
  removeReactMsgSuccess,
  sendMessageSuccess,
  sendReactMsgSuccess,
  setChatListOffset,
  setMembersOffset,
  setMessageRoute,
  setThreadTyping,
  setTyping,
  unarchiveRoomSuccess,
  unblockRoomSuccess,
  updateGroupMembers,
  updateGroupMembersOnAdd,
} from "../../redux/slicers/chat";

const { Content, Sider } = Layout;
function PrivateSharedLayout({ children }) {
  //CONST VALS
  const { pathname } = useLocation();
  const isDisabled =
    pathname.includes(CREATE_TRANSACTION_ROUTE) ||
    pathname.includes(EDIT_TRANSACTION_ROUTE);
  const [getCompanyData] = useCustomDispatch(getLoggedInUserCompanyRequest);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //REDUX DATA
  const { transactionInvitation } = useSelector((state) => state.general);
  const { chat_id, user_id } = useSelector((state) => state.user.data);
  const { deviceToken } = useSelector((state) => state.user);
  const { uniqueId } = useSelector((state) => state.user.data);
  const userId = chat_id ? chat_id : user_id;
  const { selectedRoom, messageRoute, messages } = useSelector(
    (state) => state.chat
  );

  //CUSTOM DISPATCH
  const [getUsers, isLoading] = useCustomDispatch(getGroupParticipantsRequest);
  const [getChats, chatsLoader] = useCustomDispatch(getChatListRequest);
  const [getSideChats] = useCustomDispatch(getSidebarChatListRequest);
  const [getTransChats] = useCustomDispatch(getTransactionChatsListRequest);

  //HELPERS
  const getUsersHelper = (room) => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: 0,
      room_id: room,
      user_id: chat_id,
      filter: false,
    };
    getUsers({
      queryParams: queryParams,
    });
  };

  const getChatsHelper = () => {
    const queryParams = { limit: DATA_PER_PAGE_LIMIT, offset: 0 };
    getChats({
      queryParams: queryParams,
    });
    getSideChats({
      queryParams: queryParams,
    });
    getTransChats();
  };

  //HANDLERS
  const closeInviteModal = () => {
    dispatch(closeTransactionInviteModal());
  };
  const joinOwnSocketRoom = () => {
    SocketIO.joinRoom(
      `${userId}`,
      (res) => {
        if (res.status) {
          // setIsLoading(false);
          // after joining room listen for new messages and Reactions
          handleMessageRecieved();
          handleReactRecieved();
          handleReceiveHighlight();
          handleRemoveReactEvent();
          handleReceiveTyping();
          handleRecieveGroupAddNotification();
          handleLatestChatListRecieve();
          handleOnParticipantRemove();
          handleOnGroupLeave();
          handleOnBlockUser();
          handleOnUnBlockUser();
          handleOnArchiveRoom();
          handleOnUnarchiveRoom();
          handleOnMakeAdmin();
          handleOnRemoveAdmin();
          handleOnUpdateGroup();
          handleAddGroupMember();
        } else {
          joinOwnSocketRoom();
          toastAlert("Trying to Connect Server", ALERT_TYPES.ERROR);
        }
      },
      { userId: userId, token: deviceToken }
    );
  };

  /**
   * Listen for new messages and dispatch to the messages array
   */
  const handleMessageRecieved = () => {
    SocketIO.onMessageRecieved((res) => {
      // console.log("onMessageRecieved --->>>", res);
      if (res?.auth_user !== getChatAccessToken()) {
        const manipulateRes = manipulateChatMessageData(res);
        dispatch(addReceiveMessageToRoom(manipulateRes));
      }
    });
  };

  /**Listen for new reactions done on messages */
  const handleReactRecieved = () => {
    SocketIO.onReactionRecieved((res) => {
      if (res?.reactor_id !== userId) {
        dispatch(sendReactMsgSuccess(manipulateReactData(res)));
      }
    });
  };

  const handleReceiveHighlight = () => {
    SocketIO.onHighlightRecieved((res) => {
      // dispatch(addReceiveMessageToRoom(res));
      // console.log("onHighlightRecieved --->>>", res);
      // if (res?.room_id === selectedRoom?.id) {
      const manipulateRes = manipulateChatMessageData(res);
      dispatch(addReceiveMessageToRoom(manipulateRes));
      dispatch(setChatListOffset(0));
      getChatsHelper();
      // }
    });
  };

  const handleRemoveReactEvent = () => {
    SocketIO.onRemoveReaction((res) => {
      dispatch(removeReactMsgSuccess(res));
    });
  };

  const handleReceiveTyping = () => {
    SocketIO.listenTypingEvent((res) => {
      // console.log(res, "resTpy");
      if (res.senderID === getChatAccessToken()) {
        return;
      }
      if (res?.threadId) {
        dispatch(
          setThreadTyping({
            id: res?.roomID,
            typing: res?.type,
            sender: res?.senderID,
            threadId: res?.threadId,
            typer: res?.typer,
          })
        );
      } else {
        dispatch(
          setTyping({
            id: res?.roomID,
            typing: res?.type,
            sender: res?.senderID,
            typer: res?.typer,
          })
        );
      }
    });
  };

  const handleRecieveGroupAddNotification = () => {
    SocketIO.onGroupAddYouAsUser((res) => {
      // console.log("handleRecieveGroupAddNotification --->>>", res);
      dispatch(setChatListOffset(0));
      getChatsHelper();
      // dispatch(getChatListSuccess({ data: manipulateChatsList(res) }));
    });
  };

  const handleAddGroupMember = () => {
    SocketIO.onGroupMemberAdd((res) => {
      // console.log("onGroupMemberAdd --->>>", res);
      dispatch(setChatListOffset(0));
      dispatch(setMembersOffset(0));
      getChatsHelper();
      getUsersHelper(res?.room_id);
      dispatch(updateGroupMembersOnAdd(res));

      // dispatch(getChatListSuccess({ data: manipulateChatsList(res) }));
    });
  };

  const handleLatestChatListRecieve = () => {
    SocketIO.latestChatUpdate((res) => {
      // console.log("latestChatUpdate --->>");
      dispatch(setChatListOffset(0));
      getChatsHelper();
      // dispatch(getChatListSuccess({ data: manipulateChatsList(res) }));
    });
  };

  const handleOnParticipantRemove = () => {
    SocketIO.onParticipantRemoved((res) => {
      // console.log("onParticipantRemoved --->>", res);
      dispatch(setMembersOffset(0));
      getUsersHelper(res?.room_id);
      if (res?.room_user_id === chat_id) {
        dispatch(leaveChatSuccess(res));
      }
      dispatch(updateGroupMembers(res));
      // dispatch(getAllRoomsSuccess(getAllRoomsManipulator(res)));
    });
  };

  const handleOnGroupLeave = () => {
    SocketIO.onGroupLeave((res) => {
      // console.log("onGroupLeave --->>", res);
      // dispatch(removeParticipantSuccess(res))
      dispatch(setMembersOffset(0));
      getUsersHelper(res?.room_id);
      dispatch(updateGroupMembers(res));

      // dispatch(getAllRoomsSuccess(getAllRoomsManipulator(res)));
    });
  };

  const handleOnBlockUser = () => {
    SocketIO.onBlockParticipant((res) => {
      // console.log("onBlockParticipant --->>", res);
      dispatch(blockRoomSuccess(res));
    });
  };

  const handleOnUnBlockUser = () => {
    SocketIO.onUnBlockParticipant((res) => {
      // console.log("onUnBlockParticipant --->>", res);
      dispatch(unblockRoomSuccess(res));
    });
  };

  const handleOnArchiveRoom = () => {
    SocketIO.onArchiveRoom((res) => {
      // console.log("onArchiveRoom --->>", res);
      dispatch(archiveRoomSuccess(res));
    });
  };

  const handleOnUnarchiveRoom = () => {
    SocketIO.onUnarchiveRoom((res) => {
      // console.log("onUnarchiveRoom --->>", res);
      dispatch(unarchiveRoomSuccess(res));
    });
  };

  const handleOnMakeAdmin = () => {
    SocketIO.onMakeAdmin((res) => {
      // console.log("onMakeAdmin --->>", res);
      dispatch(setMembersOffset(0));
      getUsersHelper(res?.room_id);
      if (res?.room_user_id === chat_id) {
        dispatch(onAdminMade(res));
      }
    });
  };

  const handleOnRemoveAdmin = () => {
    SocketIO.onRemoveAdmin((res) => {
      // console.log("onRemoveAdmin --->>", res);
      dispatch(setMembersOffset(0));
      getUsersHelper(res?.room_id);
      if (res?.room_user_id === chat_id) {
        dispatch(onAdminRemoved(res));
      }
    });
  };

  const handleOnUpdateGroup = () => {
    SocketIO.onUpdateGroup((res) => {
      // console.log("onUpdateGroup --->>", res);
      dispatch(editGroupSuccess(res));
      // getUsersHelper(res?.room_id);
      // if (res?.room_user_id === chat_id) {
      //   dispatch(removeGroupAdminSuccess(res));
      // }
    });
  };

  //HOOKS
  useEffect(async () => {
    let notifPermission = await requestPermission();
    if (notifPermission) {
      fetchToken();
      fetchChatToken();
    } else {
      addUniqueId(uniqueId);
      console.error("Permission denied");
    }
  }, []);

  useEffect(() => {
    getCompanyData();
  }, [pathname]);

  //SOCKET INIT
  useEffect(() => {
    if (!userId) {
      navigate(HOME_ROUTE);
    }
    // connecting socket
    SocketIO.connect(
      () => {
        joinOwnSocketRoom(); // join own room to recieve your messages
      },
      undefined,
      userId
    );

    return () => {
      SocketIO.disconnect();
    };
  }, []);

  useEffect(() => {
    if (messageRoute) {
      if (messageRoute?.roomType !== "group-tr") {
        navigate(
          replaceValInString(
            messageRoute?.isGroup ? GROUP_MESSAGES_ROUTE : MESSAGES_ROUTE,
            { ":chat_id": messageRoute?.slug }
          )
        );
      } else {
        navigate(
          replaceValInString(TRANSACTION_TABS_ROUTE, {
            ":id": messageRoute?.trSlug,
            ":room": messageRoute?.trRoomSlug,
            ":tab": "chat",
          })
        );
      }
      // if (messageRoute?.threadId) {
      // }
      dispatch(setMessageRoute({ payload: null }));
    }
  }, [messageRoute]);

  // useEffect(() => {
  //   SocketIO.listenTypingEvent((res) => {
  //     if (res?.roomID === selectedRoom?.id) setIsTyping(res?.type ?? false);
  //   });

  //   return () => {
  //     SocketIO.listenTypingEventOff();
  //   };
  // }, []);

  return (
    <div className="layout-wrapper">
      <Header dashboardHeader />
      <section className="dashboard-wrapper">
        <Layout className="layout-dashboard">
          {/* <Drawer
            title={false}
            placement="left"
            closable={false}
            onClose={() => setVisible(false)}
            visible={visible}
            key="left"
            width={250}
            className="drawer-sidebar">
            <Layout className="layout-dashboard">
              {!isDisabled && (
                <Sider
                  trigger={null}
                  width={250}
                  theme="light"
                  className="sider-primary ant-layout-sider-primary th-sidebar"
                  style={
                    pathname === "/create-transaction" && { display: "none" }
                  }>
                  <Sidenav page={page} logout={Logout} />
                </Sider>
              )}
            </Layout>
          </Drawer> */}
          {!isDisabled && (
            <Sider
              breakpoint="lg"
              collapsedWidth="0"
              onCollapse={(collapsed, type) => {}}
              trigger={null}
              width={250}
              theme="light"
              className="sider-primary ant-layout-sider-primary th-sidebar"
            >
              <SideNav />
            </Sider>
          )}
          <Layout>
            <Content className="content-ant">{children}</Content>
          </Layout>
        </Layout>
      </section>
      <PendingApprovalModal />
      <AdminApprovalModal />
      <UserDetailDrawer />
      <DashboardInviteModal
        open={transactionInvitation.visibility}
        notiToken={transactionInvitation.token}
        handleClose={closeInviteModal}
      />
    </div>
  );
}

export default PrivateSharedLayout;
