import React, { useEffect, useState } from "react";
import { Images } from "../../../../../../../themes";
import "./styles.scss";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import {
  getRoomsWithMembersRequest,
  getTransactionRoomsRequest,
} from "../../../../../../../redux/slicers/transactiondetail";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ProfileImage, VisibleToPeople } from "../../../../../../../components";

function CustomRoomSelect({
  selectedRoom,
  setSelectedRoom,
  finalRoom,
  setFinalRoom,
}) {
  //STATES
  const [dropdownOpen, setOpen] = useState(false);

  //CONST VALS
  const { room } = useParams();

  //CUSTOM DISPATCH
  const [getTransactionRooms] = useCustomDispatch(getRoomsWithMembersRequest);

  // REDUX DATA
  const { rooms, data: trans } = useSelector(
    (state) => state.transactiondetail
  );
  //HOOKS
  useEffect(() => {
    const queryParams = { transactionId: trans?.id, roomSlug: room };
    getTransactionRooms({
      queryParams,
    });
  }, [trans?.id]);

  //HANDLERS
  const dropDownHandler = () => {
    setOpen(!dropdownOpen);
    setSelectedRoom(null);
  };

  const handleProceed = (room) => {
    setFinalRoom(room);
    setOpen(false);
    setSelectedRoom(null);
  };

  return (
    <div
      className={`room-select-wrapper c-p ${dropdownOpen ? "select-open" : ""}`}
      data-value={selectedRoom}
    >
      <div className="selector-wrapper" onClick={dropDownHandler}>
        {finalRoom && !dropdownOpen ? (
          <div className="selected-room">
            <ProfileImage
              size={7}
              color={finalRoom.bgColor}
              username={finalRoom.name}
              image={""}
            />
            <h3>{finalRoom.name}</h3>
          </div>
        ) : (
          <h3 className="placeholder">Select Room</h3>
        )}
        <Images.SelectArrow />
      </div>
      {dropdownOpen && (
        <div className="room-dropdown">
          {rooms.map((item, index) => {
            const isEven = index % 2 === 0;
            const selectedCondition = item.id === selectedRoom;
            return selectedCondition ? (
              <div className="selected-room-option" key={index}>
                <div className="option-top">
                  <ProfileImage
                    border={"2px solid #fff"}
                    size={12}
                    color={item.bgColor}
                    username={item.name}
                    image={""}
                  />
                  <h3>{item.name}</h3>
                </div>
                <div className="option-bottom">
                  <p>
                    Are you sure you want to share this document in this room?
                    It will be visible to all of the members in this room
                  </p>
                  <div className="option-visibility">
                    <VisibleToPeople data={item.members} maxLength={2} />
                    <span
                      className="proceed-btn"
                      onClick={() => handleProceed(item)}
                    >
                      Proceed
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={`room-option ${isEven ? "even-option" : ""} `}
                key={index}
                onClick={() => setSelectedRoom(item.id)}
              >
                <ProfileImage
                  image={""}
                  username={item.name}
                  color={item.bgColor}
                  size={12}
                />
                <h3>{item.name}</h3>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default CustomRoomSelect;
