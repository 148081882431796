import React from "react";
import { Col, Form, Modal, Row, Select, Tooltip } from "antd";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonComponent } from "../../../../../../components";
import { Images } from "../../../../../../themes";
import "./styles.scss";
import { useSelector } from "react-redux";
import { inputFieldRule } from "../../../../../../services/utils";

const RemoveAdminModal = ({
  handleClose,
  preview,
  handleSubmit,
  isLoading,
  isAdmin,
  members,
}) => {
  //REDUX DATA
  const { user_id } = useSelector((state) => state.user.data);

  //CONST VALS
  const [form] = Form.useForm();
  const updatedMembers = members?.filter((x) => x.id !== user_id);

  //HANDLERS
  const closeHandler = () => {
    handleClose();
    form.resetFields();
  };
  const handleFormFinish = (values) => {
    handleSubmit(values.selectedAdmin);
    form.resetFields();
  };
  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      getContainer={false}
      width={522}
      className="remove-enterprise-admin-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>Remove Admin</h3>
            <p>Are you sure you want to remove yourself as admin?</p>
          </div>
          {isAdmin ? (
            <Form form={form} onFinish={handleFormFinish}>
              <Row gutter={[10, 12]}>
                <Col xs={24}>
                  <span className="select-form-item">
                    <Form.Item
                      name="selectedAdmin"
                      style={{ width: "100%" }}
                      rules={inputFieldRule({
                        name: "Leave room",
                        requiredMessage:
                          "Please assign an admin before leaving",
                        isWhiteSpace: false,
                      })}
                    >
                      <Select
                        placeholder="Assign Admin For The Enterprise"
                        className="form-select"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        showSearch
                        disabled={updatedMembers.length < 1}
                      >
                        {updatedMembers?.map((item, index) => (
                          <Select.Option value={item.id} key={index}>
                            {item.username}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Tooltip
                      title="Please assign someone else as Enterprise Admin."
                      placement="bottomLeft"
                      overlayClassName="create-breakout-tooltip"
                      color="#F8FAFB"
                    >
                      <Images.Info className="tooltip-icon" />
                    </Tooltip>
                  </span>
                </Col>
              </Row>
              <div className="modal-footer">
                <Form.Item>
                  <ButtonComponent
                    className="submit-btn"
                    text={`Submit`}
                    isLoading={isLoading}
                  />
                </Form.Item>
              </div>
            </Form>
          ) : (
            <div className="modal-footer">
              <ButtonComponent
                text="Remove"
                className="leave-btn"
                onClick={() => {
                  form.resetFields();
                  handleSubmit();
                }}
                isLoading={isLoading}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default RemoveAdminModal;
