import React from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { setIsSelectFirst } from "../../redux/slicers/chat";
import { Images } from "../../themes";
import ButtonComponent from "../ButtonComponent";
import ProfileImage from "../ProfileImage";

function UserFields({
  data,
  directMesageHandler,
  directLoader,
  user_id,
  isGroupChat,
  selectedRoom,
  handleRemove,
  selectedMemberItem,
  isMember,
  isAdmin,
  handleAdmin,
  isActive,
}) {
  const isUserProfile = user_id === data?.id;
  const dispatch = useDispatch();
  return (
    <>
      <p className="user-profile-title">Email</p>
      <div className="user-profile-detail">
        <p>{data.email}</p>
      </div>
      <p className="user-profile-title">Mobile Phone</p>
      <div className="user-profile-detail">
        <PhoneInput value={data.phone} disabled />
      </div>
      {data.workPhone && (
        <>
          <p className="user-profile-title">Work Phone</p>
          <div className="user-profile-detail">
            {/* <p>{data.company.phone}</p> */}
            <PhoneInput value={data.workPhone} disabled />
          </div>
        </>
      )}
      {(data?.facebook || data?.linkedIn || data?.twitter) && (
        <>
          <p className="user-profile-title">Social</p>
          <div className="user-profile-detail">
            {data.facebook && (
              <a href={data?.facebook} target="_blank">
                <Images.Facebook />
              </a>
            )}
            {data.twitter && (
              <a href={data?.twitter} target="_blank">
                <Images.Twitter />
              </a>
            )}
            {data.linkedIn && (
              <a href={data?.linkedIn} target="_blank">
                <Images.Linkedin />
              </a>
            )}
          </div>
        </>
      )}
      <p className="user-profile-title">Company</p>
      <div className="user-profile-detail comp-detail">
        <ProfileImage
          image={data?.company?.logo}
          username={data?.company?.name}
          size={10}
        />
        <p>{data?.company?.name}</p>
      </div>
      <p className="user-profile-title">Work Address</p>
      <div className="user-profile-detail">
        <p>
          {`${data?.workAddress?.address}${
            data?.workAddress?.address2
              ? ", " + data?.workAddress?.address2
              : ""
          }, ${data?.workAddress?.city}, ${data?.workAddress?.state}, ${
            data?.workAddress?.zip
          }, ${data?.workAddress?.country}`}
        </p>
      </div>
      {!isUserProfile && isActive && (
        <div className="chat-options-wrapper">
          <ButtonComponent
            text="Send Direct Message"
            onClick={() => {
              dispatch(setIsSelectFirst(true));

              directMesageHandler(data?.chat_id);
            }}
            isLoading={directLoader}
          />
          {isGroupChat && selectedRoom?.isAdminLoggedIn && isMember && (
            <>
              <ButtonComponent
                text={`${
                  isAdmin ? "Remove Group Chat Admin" : "Make Group Chat Admin"
                }`}
                className={`${isAdmin ? "secondary-red" : "secondary"}`}
                onClick={handleAdmin}
              />
              <ButtonComponent
                text="Remove from Group Chat"
                className="secondary-red"
                onClick={handleRemove}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}

export default UserFields;
