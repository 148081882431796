import _ from "lodash";
import moment from "moment";
import {
  DATE_FORMAT2,
  DATE_FORMAT5,
  DEFAULT_PROFILE_COLOR,
} from "../constants";
import { getFormattedDateTime } from "../services/utils";
import {
  manipulateMilestoneResponsiblePartyGroup,
  manipulateMilestoneResponsiblePartyList,
  manipulateVisibilityList,
  manipulateVisibilityData,
} from "./transactionmilestones";
import { manipulateUserData } from "./user";

// CHECKLIST DOC DATA
export const manipulateChecklistDocData = (data) => {
  let doc = {};
  doc["id"] = data?.id ?? 0;
  doc["name"] = data?.name ?? "";
  doc["slug"] = data?.slug ?? "";
  doc["creatorName"] =
    data?.createdBy?.firstName + " " + data?.createdBy?.lastName ?? "";
  doc["creatorImage"] = data?.createdBy?.image ?? "";
  doc["creatorId"] = data?.createdBy?.id ?? "";
  doc["bgColor"] = data?.createdBy?.bgcolor ?? "";
  doc["isActive"] =
    (data?.createdBy?.isActive && !data?.createdBy?.isBlock) ?? true;
  doc["uploadedFrom"] = data?.versions?.[0]?.uploadFrom ?? "";
  doc["accessible"] = data?.isAccessible ?? false;

  return doc;
};

// CHECKLIST DOC LIST
export const manipulateChecklistDocsList = (data) => {
  let docs = [];
  data?.forEach((item) => {
    docs.push(manipulateChecklistDocData(item));
  });
  return docs;
};

// CHECKLIST VISIBILITY LIST
const manipulateCheckVisibilityList = (data, isTranactionChecklist) => {
  let visibilities = [];
  data?.forEach((item) => {
    visibilities.push(
      manipulateVisibilityData(
        isTranactionChecklist ? item?.visibilityRooms : item
      )
    );
  });
  return visibilities;
};

// CHECKLIST RESPONSIBLE PARTY DATA
const manipulateResponsiblePartyData = (data) => {
  if (_.isEmpty(data)) return {};
  let party = {};

  party["companyid"] = data?.id ?? 0;
  party["companyname"] = data.name ?? "";
  party["companylogo"] = data.logo ?? "";

  return party;
};

// CHECKLIST RESPONSIBLE PARTY LIST
const manipulateResponsiblePartyList = (data, isTranactionChecklist) => {
  const manipulator = isTranactionChecklist
    ? manipulateUserData
    : manipulateResponsiblePartyData;
  let parties = [];
  data?.forEach((item) => {
    parties.push(manipulator(isTranactionChecklist ? item?.users : item));
  });
  return parties;
};

// CHECKLIST DETAIL
export const manipulateChecklistDetail = (data, isRequest) => {
  let checklist = {};
  let responsibles = manipulateMilestoneResponsiblePartyList(
    data?.responsibles
  );
  checklist["id"] = data?.id ?? 0;
  checklist["name"] = data?.name ?? "";
  checklist["notes"] = data?.notes ?? "";
  checklist["isDeleted"] = data?.deletedAt ? true : false;
  checklist["targetDate"] = getFormattedDateTime(
    data?.targetDate,
    DATE_FORMAT5
  );
  checklist["type"] = data?.type ?? {};
  checklist["status"] = data?.status ?? false;
  checklist["slug"] = data?.slug ?? "";
  checklist["documents"] = manipulateChecklistDocsList(data?.documents) ?? [];
  checklist["responsibleParties"] =
    manipulateMilestoneResponsiblePartyGroup(responsibles);
  checklist["visibilities"] = manipulateVisibilityList(data?.visibilities);
  checklist["lastUpdated"] =
    getFormattedDateTime(data?.updatedAt, DATE_FORMAT5) ?? "";
  if (isRequest) {
    checklist["visibility"] = manipulateVisibilityList(data?.visibilities);
    checklist["type"] = data?.type?.value ?? "";
    checklist["targetDate"] =
      data?.targetDate && getFormattedDateTime(data?.targetDate, DATE_FORMAT2);
    checklist["status"] = data?.status ? "Completed" : "Pending";
  }

  return checklist;
};
export const manipulateChecklistData = (data, isTranactionChecklist) => {
  let checklist = {};

  checklist["id"] = data?.id ?? 0;
  checklist["slug"] = data?.slug ?? "";
  checklist["name"] = data?.name ?? "";
  checklist["notes"] = data?.notes ?? "";
  checklist["status"] = data?.status ?? false;
  checklist["type"] = data?.type ?? {};
  checklist["endDate"] = moment(data?.targetDate);
  checklist["isDeleted"] = data?.deletedAt ? true : false;
  checklist["targetDate"] = getFormattedDateTime(
    data?.targetDate,
    DATE_FORMAT2
  );
  checklist["visibility"] = manipulateCheckVisibilityList(
    data?.visibilities,
    isTranactionChecklist
  );
  checklist["responsibleParties"] = manipulateResponsiblePartyList(
    data?.responsibles,
    isTranactionChecklist
  );

  return checklist;
};

// CHECKLIST LIST
export const manipulateChecklistList = (data, isTranactionChecklist) => {
  let checklists = [];
  data?.forEach((item) => {
    checklists.push(manipulateChecklistData(item, isTranactionChecklist));
  });
  return checklists;
};

//CHECKLIST SUGGESTIONS
export const manipulateSuggestionData = (data) => {
  let suggestion = {};

  suggestion["id"] = data?.id ?? 0;
  suggestion["name"] = data?.name ?? "";
  suggestion["notes"] = data?.notes ?? "";
  suggestion["type"] = data?.type ?? "";

  return suggestion;
};

export const manipulateSuggestionsList = (data) => {
  let suggestions = [];

  data?.forEach((item) => {
    suggestions.push(manipulateSuggestionData(item));
  });

  return suggestions;
};
