import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  DEFAULT_PROFILE_COLOR,
  TRANSACTION_DETAIL_ROUTE,
} from "../../constants";
import {
  setUserDetailDrawer,
  setCompanyDetailDrawer,
} from "../../redux/slicers/general";
import { replaceValInString } from "../../services/utils";
import ProfileImage from "../ProfileImage";
import "./styles.scss";

const VisibleToPeople = ({
  data,
  largeSize,
  maxLength = 5,
  borderWidth = 2,
  borderColor = "#fff",
  bgColor = DEFAULT_PROFILE_COLOR,
  detailModalHandler,
  className,
  isRoom,
  isCompany,
  isInvited = false,
  size = 10,
}) => {
  //CONST VALS
  const dispatch = useDispatch();
  const isNotUser = isRoom || isCompany || isInvited;
  const navigate = useNavigate();
  const { id } = useParams();

  //HANDLERS
  const roomNavigationHandler = (slug) => {
    const vals = { ":id": id, ":room": slug };
    navigate(replaceValInString(TRANSACTION_DETAIL_ROUTE, vals));
  };

  const setDetailDrawer = (id, item) => {
    const request = isCompany ? setCompanyDetailDrawer : setUserDetailDrawer;
    if (isRoom) {
      data?.length < 6 && data?.length > 1
        ? detailModalHandler()
        : roomNavigationHandler(id);
      return;
    }
    if (!id) return;
    dispatch(
      request({
        visibility: true,
        id,
        isGroup: true,
      })
    );
  };

  return (
    <>
      <span
        className={`visible-people-list ${
          largeSize ? "large" : ""
        } ${className}`}
      >
        {data?.slice(0, maxLength)?.map((item, i) => (
          <ProfileImage
            key={i}
            isBlocked={!isNotUser && !item?.isActive}
            image={item?.image ?? item?.companylogo}
            color={item?.profilebg ?? item?.bgColor ?? bgColor}
            username={item?.username ?? item?.roomname}
            classname={largeSize && "large"}
            size={!largeSize && size}
            border={`${borderWidth}px solid ${borderColor}`}
            onClick={(e) => {
              e.stopPropagation();
              setDetailDrawer(
                isRoom ? item.slug : item.userid ?? item.id ?? item.companyid,
                item
              );
            }}
          />
        ))}
        {data?.length > maxLength && (
          <span
            onClick={(e) => {
              e.stopPropagation();
              detailModalHandler();
            }}
            style={{
              fontSize: size,
              background: bgColor,
              border: `${borderWidth}px solid ${borderColor}`,
            }}
            className={`view-more ${largeSize ? "large" : ""}`}
          >
            +{data?.length - maxLength}
          </span>
        )}
      </span>
    </>
  );
};

export default VisibleToPeople;
