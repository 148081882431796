// @flow
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  documents: [],
  docexhibits: [],
  transDocs: [],
  data: {},
  files: [],
  versions: [],
  versionHistory: [],
};

const DocumentsReducer = createSlice({
  name: "documents",
  initialState,
  reducers: {
    //CREATE DOCUMENT
    createDocumentRequest() {},
    createDocumentSuccess(state, action) {},

    //UPDATE DOCUMENT
    updateDocumentRequest() {},

    //DELETE DOCUMENT
    deleteDocumentRequest() {},

    //DOCUMENTS LISTING
    getDocumentsRequest() {},
    getDocumentsSuccess(state, action) {
      let temp = [...state.documents];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.documents = action.payload.data;
        return;
      }
      state.documents = temp;
    },

    //DELETED DOCUMENTS LISTING
    getDeletedDocumentsRequest() {},
    getDeletedDocumentsSuccess(state, action) {
      let temp = [...state.documents];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.documents = action.payload.data;
        return;
      }
      state.documents = temp;
    },

    //DOCUMENT DETAIL
    getDocumentDetailRequest() {},
    getDocumentDetailSuccess(state, action) {
      state.data = action.payload;
    },

    //DOCUMENTS AND EXHIBITS LISTING
    getDocumentsFilesExhibitsRequest() {},
    getDocumentsFilesExhibitsSuccess(state, action) {
      state.docexhibits = action.payload;
    },

    //DOCUMENTS LISTING
    getDocumentsFilesRequest() {},
    getDocumentsFilesSuccess(state, action) {
      state.files = action.payload;
    },

    //CREATE EXHIBIT
    createExhibitRequest() {},

    //UPDATE EXHIBIT
    updateExhibitRequest() {},

    //DELETE EXHIBIT
    deleteExhibitRequest() {},

    //CREATE VERSION
    createVersionRequest() {},

    //UPDATE VERSION
    updateVersionRequest() {},

    //DELETE VERSION
    deleteVersionRequest() {},

    //GET ALL TRANSACTION DOCS
    getAlltransactionDocsRequest() {},
    getAlltransactionDocsSuccess(state, action) {
      let temp = [...state.transDocs];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.transDocs = action.payload.data;
        return;
      }
      state.transDocs = temp;
    },

    //GET ALL DOCS VERSIONS
    getAllDocsVersionsRequest() {},
    getAllDocsVersionsSuccess(state, action) {
      state.versions = action.payload;
    },

    //GET VERSIONS BY ROOM
    getAllDocsRoomVersionsRequest() {},
    getAllDocsRoomVersionsSuccess(state, action) {
      state.versions = action.payload;
    },

    //GET VERSIONS BY ROOM
    shareDocRequest() {},
    shareDocSuccess(state, action) {},

    //GET VERSIONS BY ROOM
    getVersionHistoryRequest() {},
    getVersionHistorySucccess(state, action) {
      state.versionHistory = action.payload;
    },
  },
});

export const {
  createDocumentRequest,
  createDocumentSuccess,
  updateDocumentRequest,
  deleteDocumentRequest,
  getDocumentsRequest,
  getDocumentsSuccess,
  getDocumentDetailRequest,
  getDocumentDetailSuccess,
  getDocumentsFilesRequest,
  getDocumentsFilesSuccess,
  getDocumentsFilesExhibitsRequest,
  getDocumentsFilesExhibitsSuccess,
  createExhibitRequest,
  updateExhibitRequest,
  deleteExhibitRequest,
  createVersionRequest,
  updateVersionRequest,
  deleteVersionRequest,
  getAlltransactionDocsRequest,
  getAlltransactionDocsSuccess,
  getAllDocsVersionsRequest,
  getAllDocsVersionsSuccess,
  getAllDocsRoomVersionsRequest,
  getAllDocsRoomVersionsSuccess,
  shareDocRequest,
  shareDocSuccess,
  getVersionHistoryRequest,
  getVersionHistorySucccess,
  getDeletedDocumentsRequest,
  getDeletedDocumentsSuccess,
} = DocumentsReducer.actions;

export default DocumentsReducer.reducer;
