import { Modal } from "antd";
import React from "react";
import { Images } from "../../themes";
import ButtonComponent from "../ButtonComponent";
import "./styles.scss";

function EditQuestionsPrompt({ modalPreview, handleClose, handleSave }) {
  return (
    <Modal
      destroyOnClose
      visible={modalPreview}
      centered
      footer={null}
      width={490}
      className="question-prompt-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <Images.SkipQuestion />
          <h3>We have improved the flow </h3>
          <p>
            We have improved our system to provide you with the best possible
            suggestions. You need to fill up the Question form again.
          </p>
          <span className="question-modal-btn-wrapper">
            <ButtonComponent
              text="Continue"
              className="skip-btn"
              onClick={handleClose}
            />
            <ButtonComponent
              text="Skip & Save"
              className="skip-btn"
              onClick={handleSave}
            />
          </span>
        </div>
      </div>
    </Modal>
  );
}

export default EditQuestionsPrompt;
