import React, { useState, useEffect } from "react";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DATA_PER_PAGE_LIMIT,
  PERMISSIONS_ACCESS,
  ROOM_ACCESS_TYPES,
  TRANSACTION_DETAIL_ROUTE,
} from "../../../../../constants";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getActionPermission,
  getLetterFromName,
  replaceValInString,
} from "../../../../../services/utils";
import { Images } from "../../../../../themes";
import { BreakoutRoomModal } from "../../components";
import {
  CardHeader,
  CardLoader,
  DisabledTooltip,
  LeaveRoomModal,
  ProfileImage,
} from "../../../../../components";
import { useCustomDispatch } from "../../../../../helpers/useCustomDispatch";
import {
  checkBreakoutRoomAdminRequest,
  getBreakoutRoomsRequest,
  leaveRoomRequest,
  getTransactionMembersRequest,
  acceptRemovalRequest,
} from "../../../../../redux/slicers/transactiondetail";
import {
  ActivityLogs,
  Checklist,
  Milestones,
  Documents,
  WorkingGroups,
} from "./partials";
import _ from "lodash";
import {
  setCompanyDetailDrawer,
  setUserDetailDrawer,
} from "../../../../../redux/slicers/general";
import { RemoveResponseModal } from "./components";
import { ClipLoader } from "react-spinners";
import "./styles.scss";

const DealRoom = ({
  roomslug,
  isRoom,
  isLoading,
  getBreakoutRoomDetail,
  transactionslug,
  setInviteModalPreview,
  isAdminLoggedIn,
  roomAccess,
  checkUserStatusHandler,
  checkApiUserStatusHandler,
}) => {
  //STATES
  const [workingDetailView, setworkingDetailView] = useState(true);
  const [leaveRoomModalPreview, setleaveRoomModalPreview] = useState(false);
  const [searchText, setsearchText] = useState("");
  const [isAdminLeave, setIsAdminLeave] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [createBreakoutModalPreview, setCreateBreakoutModalPreview] =
    useState(false);
  const [removeResponseModalPreview, setRemoveResponseModalPreview] =
    useState(false);

  //REDUX DATA
  const {
    data: transactiondetail,
    roomDetail,
    members,
    roomAdmin,
  } = useSelector((state) => state.transactiondetail);
  //CUSTOM DISPATCH
  const [leaveRoom, leaveRoomLoader] = useCustomDispatch(leaveRoomRequest);
  const [checkAdmin] = useCustomDispatch(checkBreakoutRoomAdminRequest);
  const [getBreakoutRoomsList] = useCustomDispatch(getBreakoutRoomsRequest);
  const [acceptRemoval, acceptLoader] = useCustomDispatch(acceptRemovalRequest);
  const [getTransactionMembers] = useCustomDispatch(
    getTransactionMembersRequest
  );

  //CONST VALS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDataLoading = isRoom && isLoading;
  const isRoomAccessedBlock =
    roomAccess === ROOM_ACCESS_TYPES.PENDING ||
    roomAccess === ROOM_ACCESS_TYPES.REMOVED;
  const roomdetail = isRoom ? roomDetail : transactiondetail?.room;
  const deletedRoomView = roomAccess !== ROOM_ACCESS_TYPES.DELETED;
  const {
    INVITE_TO_TRANSACTION,
    EDIT_BREAKOUT_ROOM,
    BREAKOUT_ROOM_ACTIVITY_LOGS,
    TRANSACTION_ACTIVITY_LOGS,
    VIEW_CHECKLIST,
    VIEW_MILESTONE,
    VIEW_DOCS,
    EDIT_CHECKLIST,
    EDIT_MILESTONE,
    DELETE_CHECKLIST,
    DELETE_MILESTONE,
    EDIT_TRANSACTION,
  } = PERMISSIONS_ACCESS;

  const isInviteAllowed = getActionPermission(INVITE_TO_TRANSACTION);
  const isRoomEditAllowed = getActionPermission(EDIT_BREAKOUT_ROOM);
  const activityLogsVisible = getActionPermission(TRANSACTION_ACTIVITY_LOGS);
  const canViewDocs = getActionPermission(VIEW_DOCS);
  const canViewMilestones = getActionPermission(VIEW_MILESTONE);
  const canEditMilestone = getActionPermission(EDIT_MILESTONE);
  const canDeleteMilestone = getActionPermission(DELETE_MILESTONE);
  const canViewChecklists = getActionPermission(VIEW_CHECKLIST);
  const canEditChecklist = getActionPermission(EDIT_CHECKLIST);
  const canDeleteChecklist = getActionPermission(DELETE_CHECKLIST);
  const isTransactionEditAllowed = getActionPermission(EDIT_TRANSACTION);

  // HELPERS
  const getTransactionMembersHelper = () => {
    getTransactionMembers({
      pathParams: transactiondetail?.id,
      queryParams: { room: isRoom ? roomslug : null },
    });
  };

  //HOOKS
  useEffect(() => {
    getTransactionMembersHelper();
  }, []);

  //HANDLERS
  const navigateToTransaction = () => {
    const vals = {
      ":id": transactionslug,
      ":room": transactiondetail.roomSlug,
    };
    navigate(replaceValInString(TRANSACTION_DETAIL_ROUTE, vals));
  };
  const handleLeaveRoomModalPreview = () => {
    checkAdmin({
      pathParams: roomslug,
      logic(status) {
        setIsAdminLeave(!status);
        setleaveRoomModalPreview(true);
      },
    });
  };
  const handleAdminChange = (data) => {
    const payload = {};
    if (data) {
      payload["id"] = data;
    }
    leaveRoom({
      pathParams: roomslug,
      payload,
      logic() {
        setleaveRoomModalPreview(false);
        getBreakoutRoomsListHandler();
        navigateToTransaction();
      },
    });
  };
  const getBreakoutRoomsListHandler = () => {
    const queryParams = {
      transactionId: transactiondetail?.id,
      limit: DATA_PER_PAGE_LIMIT,
      offset: 0,
    };
    getBreakoutRoomsList({ queryParams });
  };
  const removeResponseModalPreviewHandler = () => {
    setRemoveResponseModalPreview(!removeResponseModalPreview);
  };
  const editRoomDetailHandler = () => {
    setSelectedRoom(isRoom ? roomDetail : transactiondetail);
    setCreateBreakoutModalPreview(true);
  };

  //CUSTOM COMPONENTS
  const roomDetailBox = () => (
    <div className="room-detail">
      <span className="thumb" style={{ backgroundColor: roomdetail?.bgColor }}>
        {getLetterFromName(roomdetail?.name)}
      </span>
      <div className="description">
        <span style={{ display: "flex", gap: "5px" }}>
          <h4>{roomdetail?.name}</h4>
          <DisabledTooltip
            innerSec={
              isRoom && !roomAccess ? (
                <Images.Edit
                  onClick={isRoomEditAllowed && editRoomDetailHandler}
                  className="c-p edit"
                />
              ) : (
                <></>
              )
            }
            visible={!isRoomEditAllowed}
          />
        </span>
        {!isRoom ? (
          <p>Visible to Entire Working Group</p>
        ) : (
          <>
            {!roomAccess && (
              <div>
                Visible to:{" "}
                <span
                  className="c-p"
                  onClick={() => {
                    dispatch(
                      setCompanyDetailDrawer({
                        visibility: true,
                        id: roomDetail.company?.[0].id,
                      })
                    );
                  }}
                >
                  {roomDetail?.company?.[0].name}
                </span>
                {roomDetail?.company?.length > 1 && (
                  <>
                    {" | "}
                    <button
                      onClick={(e) => {}}
                      className="btn-transparent view-more-visible"
                    >
                      + {roomDetail.company.length - 1}{" "}
                      <Images.transactionTopArrow className="arrow" />
                      <div className={`dropdown`}>
                        <p>Visible to</p>
                        {roomDetail.company.map((item, index) => (
                          <p
                            key={index}
                            onClick={() => {
                              dispatch(
                                setCompanyDetailDrawer({
                                  visibility: true,
                                  id: item.id,
                                })
                              );
                            }}
                          >
                            {item.name}
                          </p>
                        ))}
                      </div>
                    </button>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
      {isRoom && !roomAccess ? (
        <div className="leave-room-btn">
          <div onClick={handleLeaveRoomModalPreview}>Leave Room</div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );

  const roomStatusOptions = () => {
    return (
      <>
        <Col xs={24} md={18} className="room-status-wrapper">
          <p className="no-access-msg">
            You can't Edit/update to this Breakout Room because you're no longer
            a participant.
          </p>
          {roomAccess === ROOM_ACCESS_TYPES.REMOVED ? (
            <div className="request-options c-p">
              <p className="reject" onClick={removeResponseModalPreviewHandler}>
                Reject
              </p>
              <p
                className="accept"
                onClick={() => acceptRemoval({ pathParams: roomslug })}
              >
                {acceptLoader ? (
                  <ClipLoader size={26} color={"#fff"} />
                ) : (
                  "Accept"
                )}
              </p>
            </div>
          ) : roomAccess === ROOM_ACCESS_TYPES.PENDING ? (
            <p className="wait-msg">Waiting For Response</p>
          ) : (
            ""
          )}
        </Col>
      </>
    );
  };

  return (
    <section
      className={`deal-room ${isRoomAccessedBlock ? "room-disabled" : ""}`}
    >
      {isDataLoading ? (
        <CardLoader height={540} size={20} />
      ) : (
        <>
          {roomDetailBox()}
          <Row>
            {roomAccess && roomStatusOptions()}
            {/* ACTIVITY LOG HEADER */}
            {roomAccess !== ROOM_ACCESS_TYPES.DELETED && (
              <Col xs={24} md={18}>
                <div
                  className="card"
                  style={{
                    borderBottomLeftRadius: "0px",
                    borderBottomRightRadius: "0px",
                    paddingBottom: "0px",
                    paddingTop: "14px",
                    height: "75px",
                  }}
                >
                  <CardHeader
                    isFilter
                    title="Activity logs"
                    placeholder="Search any activity log..."
                    searchText={searchText}
                    handleSearch={setsearchText}
                    isFilterTab={false}
                  />
                </div>
              </Col>
            )}

            {/* WORKING GROUP HEADER */}
            {deletedRoomView && (
              <Col xs={24} md={6}>
                <div
                  className="work-group-header"
                  style={{
                    height: workingDetailView ? "100%" : "auto",
                    borderBottomLeftRadius: workingDetailView ? 0 : 12,
                    borderBottomRightRadius: workingDetailView ? 0 : 12,
                  }}
                >
                  <h5>Working Group</h5>
                  <div className="action">
                    {!workingDetailView && members.length >= 1 && (
                      <ul className="group-list">
                        {members.slice(0, 2).map((item, index) => (
                          <li key={index}>
                            <ProfileImage
                              username={item.username}
                              image={item.image}
                              color={item.profilebg}
                              isBlocked={!item?.isActive}
                              onClick={() => {
                                dispatch(
                                  setUserDetailDrawer({
                                    visibility: true,
                                    id: item.userid ?? item.id,
                                  })
                                );
                              }}
                              size={10}
                            />
                          </li>
                        ))}
                        <li
                          className={`${roomAccess ? "na-p" : ""} view-all`}
                          onClick={() => {
                            checkUserStatusHandler(() => {
                              setworkingDetailView(true);
                            });
                          }}
                        >
                          {members.length >= 3 ? `+${members.length - 2}` : ""}{" "}
                          View All
                        </li>
                      </ul>
                    )}
                    <DisabledTooltip
                      innerSec={
                        <span
                          onClick={() =>
                            isInviteAllowed &&
                            checkUserStatusHandler(() => {
                              setInviteModalPreview(true);
                            })
                          }
                          className={`${
                            roomAccess ? "na-p" : ""
                          } add-group-btn`}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </span>
                      }
                      visible={!isInviteAllowed}
                    />
                    {workingDetailView && (
                      <span
                        className={`${
                          roomAccess ? "na-p" : ""
                        } close-group-btn`}
                        onClick={() => {
                          checkUserStatusHandler(() => {
                            setworkingDetailView(false);
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </span>
                    )}
                  </div>
                </div>
              </Col>
            )}
            <Col xs={24} md={workingDetailView ? 18 : 24} className="trans">
              {roomAccess !== ROOM_ACCESS_TYPES.DELETED && (
                <>
                  {/* ACTIVITY LOGS */}
                  <ActivityLogs
                    isRoom={isRoom}
                    roomslug={roomslug}
                    transactionslug={transactionslug}
                    searchText={searchText}
                    isCurved={workingDetailView}
                    checkApiUserStatusHandler={checkApiUserStatusHandler}
                    isAccess={activityLogsVisible}
                  />
                  {/* CHECKLIST */}
                  <Checklist
                    roomslug={roomslug}
                    canView={canViewChecklists}
                    canEdit={canEditChecklist}
                    canDelete={canDeleteChecklist}
                  />

                  {/* MILESTONES */}
                  <Milestones
                    isRoom={isRoom}
                    transactionslug={transactionslug}
                    roomslug={roomslug}
                    checkUserStatusHandler={checkUserStatusHandler}
                    checkApiUserStatusHandler={checkApiUserStatusHandler}
                    canView={canViewMilestones}
                    canEdit={canEditMilestone}
                    canDelete={canDeleteMilestone}
                  />
                </>
              )}

              {/* DOCUMENTS */}
              <Documents
                roomslug={roomslug}
                roomdetail={roomdetail}
                canView={canViewDocs}
              />
            </Col>
            {/* WORKING GROUPS */}
            {deletedRoomView && (
              <Col
                xs={24}
                md={6}
                style={{
                  display: workingDetailView ? "unset" : "none",
                  pointerEvents: workingDetailView ? "all" : "none",
                }}
                className="trans"
              >
                <WorkingGroups
                  isRoom={isRoom}
                  adminLogin={roomAdmin}
                  roomslug={roomslug}
                  checkUserStatusHandler={checkUserStatusHandler}
                  checkApiUserStatusHandler={checkApiUserStatusHandler}
                  onWorkingGroupChange={getBreakoutRoomDetail}
                  isTransactionAdminLogin={isAdminLoggedIn}
                  canEdit={
                    isRoom ? isRoomEditAllowed : isTransactionEditAllowed
                  }
                />
              </Col>
            )}
          </Row>
        </>
      )}
      <BreakoutRoomModal
        preview={createBreakoutModalPreview}
        title={"Change Name of Room"}
        handleClose={() => {
          setCreateBreakoutModalPreview(false);
          setSelectedRoom(null);
        }}
        isRoom={isRoom}
        selectedData={selectedRoom}
      />
      <LeaveRoomModal
        open={leaveRoomModalPreview}
        handleClose={() => setleaveRoomModalPreview(false)}
        title="Leave Room"
        description="Are You Sure To Leave The Room"
        tooltip="Kindly assign an admin before leaving."
        isAdmin={isAdminLeave}
        onSubmit={handleAdminChange}
        btnLoader={leaveRoomLoader}
      />
      <RemoveResponseModal
        preview={removeResponseModalPreview}
        handleClose={removeResponseModalPreviewHandler}
      />
    </section>
  );
};

export default DealRoom;
