// @flow
import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userdetail: {},
  comapnydetail: {},
  activitylogs: [],
  milestonelogs: [],
  previouslogsdata: null,
  sideDrawer: {
    visibility: false,
    isUserDetail: true,
    dataid: null,
    isAdmin: false,
    isMember: false,
    isGroup: false,
  },
  transactionInvitation: {
    visibility: false,
    token: null,
  },
  pendingApprovalRequest: {
    visibility: false,
    dataid: null,
    data: [],
    isAdmin: false,
  },
  confirmApprovalRequest: {
    visibility: false,
    dataid: null,
    data: {},
    isAdmin: false,
  },
  dashboardStatistics: [],
  reviewsList: [],
  notifications: { list: [], openCount: 0, total: null },
};

const GeneralReducer = createSlice({
  name: "general",
  initialState,
  reducers: {
    //REVIEWS LIST
    getReviewsRequest() {},
    getReviewsSuccess(state, action) {
      state.reviewsList = action.payload;
    },

    //CONTACR FORM SUBMIT
    contactFormSubmitRequest() {},
    contactFormSubmitSuccess(state, action) {},

    // UPLOAD IMAGE
    uploadImageRequest() {},

    // ACTIVITY LOGS
    getActivityLogsRequest(state, action) {
      state.previouslogsdata = action.payload.queryParams;
    },
    getActivityLogsSuccess(state, action) {
      if (action.payload.isMilestone) {
        let temp = [...state.milestonelogs];
        temp.push(...action.payload.data);
        if (!action.payload.isPaginated) {
          state.milestonelogs = action.payload.data;
          return;
        }
        state.milestonelogs = temp;
      } else {
        let temp = [...state.activitylogs];
        temp.push(...action.payload.data);
        if (!action.payload.isPaginated) {
          state.activitylogs = action.payload.data;
          return;
        }
        state.activitylogs = temp;
      }
    },

    // USER DETAIL
    getUserDetailRequest() {},
    getUserDetailSuccess(state, action) {
      state.userdetail = action.payload;
    },

    // COMPANY DETAIL
    getCompanyDetailRequest() {},
    getCompanyDetailSuccess(state, action) {
      state.comapnydetail = action.payload;
    },

    // PROFILE DRAWER
    setUserDetailDrawer(state, action) {
      state.sideDrawer.visibility = action.payload.visibility;
      state.sideDrawer.dataid = action.payload.id;
      state.sideDrawer.isUserDetail = true;
      state.sideDrawer.isAdmin = action.payload.isAdmin ?? false;
      state.sideDrawer.isMember = action.payload.isMember ?? false;
      state.sideDrawer.isGroup = action.payload.isGroup ?? false;
    },
    setCompanyDetailDrawer(state, action) {
      state.sideDrawer.visibility = action.payload.visibility;
      state.sideDrawer.dataid = action.payload.id;
      state.sideDrawer.isUserDetail = false;
    },
    closeDrawer(state) {
      state.sideDrawer.visibility = false;
      state.sideDrawer.dataid = null;
    },

    // APPROVAL REQUEST MODAL
    setPendingApprovalRequestModal(state, action) {
      state.pendingApprovalRequest.visibility = true;
      state.pendingApprovalRequest.dataid = action.payload.id;
      state.pendingApprovalRequest.isAdmin = action.payload.isAdmin;
    },
    closePendingRequestModal(state) {
      state.pendingApprovalRequest.visibility = false;
      state.pendingApprovalRequest.dataid = null;
      state.pendingApprovalRequest.isAdmin = false;
    },
    getPendingRequesDetailRequest() {},
    getPendingRequesDetailSuccess(state, action) {
      state.pendingApprovalRequest.data = action.payload;
    },

    // CONFIRM APPROVAL REQUEST MODAL
    setConfirmApprovalRequestModal(state, action) {
      state.confirmApprovalRequest.visibility = true;
      state.confirmApprovalRequest.dataid = action.payload.id;
      state.confirmApprovalRequest.isAdmin = action.payload.isAdmin;
    },
    closeConfirmApprovalRequestModal(state) {
      state.confirmApprovalRequest.visibility = false;
      state.confirmApprovalRequest.dataid = null;
      state.confirmApprovalRequest.isAdmin = false;
    },
    getConfirmApprovalRequesDetailRequest() {},
    getConfirmApprovalRequesDetailSuccess(state, action) {
      state.confirmApprovalRequest.data = action.payload;
    },

    //DASHBOARD STATISTICS
    getDashboardStatsRequest() {},
    getDashboardStatsSuccess(state, action) {
      state.dashboardStatistics = action.payload;
    },
    //NOTIFICATIONS
    getNotificationsRequest() {},
    getNotificationsSuccess(state, action) {
      state.notifications.openCount = action.payload.isOpen;
      state.notifications.total = action.payload.total;
      let temp = [...state.notifications.list];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.notifications.list = action.payload.data;
        return;
      }
      state.notifications.list = temp;
    },
    addNotification(state, action) {
      state.notifications.total += 1;
      state.notifications.list.unshift(action.payload);
      state.notifications.openCount += 1;
    },
    markAsOpenRequest(state) {
      state.notifications.openCount = 0;
    },
    markAllAsReadRequest(state) {
      state.notifications.openCount = 0;
      let temp = [...state.notifications.list];
      temp.forEach((item) => {
        item.isRead = true;
      });
      state.notifications.list = temp;
    },
    markAsReadRequest(state, action) {
      const notiIndex = _.findIndex(state.notifications.list, {
        id: action.payload.pathParams,
      });
      if (notiIndex >= 0) {
        state.notifications.list[notiIndex].isRead = true;
      }
    },
    markAsReadSuccess(state, action) {
      state.notifications.openCount = action.payload;
    },
    //ASK PERMISSION RESPONSE
    transactionAdminResponseRequest() {},

    partyAdminResponseRequest() {},

    //TRANSACTION INVITATION
    openTransactionInviteModal(state, action) {
      state.transactionInvitation.visibility = true;
      state.transactionInvitation.token = action.payload.token;
    },
    closeTransactionInviteModal(state) {
      state.transactionInvitation.visibility = false;
      state.transactionInvitation.token = null;
    },
    getDashboardActivityLogsRequest(state, action) {
      state.previouslogsdata = action.payload.queryParams;
    },
    getDashboardActivityLogsSuccess(state, action) {
      if (action.payload.isMilestone) {
        let temp = [...state.milestonelogs];
        temp.push(...action.payload.data);
        if (!action.payload.isPaginated) {
          state.milestonelogs = action.payload.data;
          return;
        }
        state.milestonelogs = temp;
      } else {
        let temp = [...state.activitylogs];
        temp.push(...action.payload.data);
        if (!action.payload.isPaginated) {
          state.activitylogs = action.payload.data;
          return;
        }
        state.activitylogs = temp;
      }
    },

    resetGeneralStates(state, action) {
      state.activitylogs = [];
      state.notifications = { list: [], openCount: 0, total: null };
    },
  },
});

export const {
  uploadImageRequest,
  getActivityLogsRequest,
  getActivityLogsSuccess,
  getUserDetailRequest,
  getUserDetailSuccess,
  getCompanyDetailRequest,
  getCompanyDetailSuccess,
  setUserDetailDrawer,
  setCompanyDetailDrawer,
  closeDrawer,
  setPendingApprovalRequestModal,
  closePendingRequestModal,
  getPendingRequesDetailRequest,
  getPendingRequesDetailSuccess,
  setConfirmApprovalRequestModal,
  closeConfirmApprovalRequestModal,
  getConfirmApprovalRequesDetailRequest,
  getConfirmApprovalRequesDetailSuccess,
  getDashboardStatsRequest,
  getDashboardStatsSuccess,
  getNotificationsRequest,
  getNotificationsSuccess,
  addNotification,
  markAsOpenRequest,
  markAllAsReadRequest,
  markAsReadRequest,
  markAsReadSuccess,
  getReviewsRequest,
  getReviewsSuccess,
  contactFormSubmitRequest,
  contactFormSubmitSuccess,
  partyAdminResponseRequest,
  transactionAdminResponseRequest,
  openTransactionInviteModal,
  closeTransactionInviteModal,
  getDashboardActivityLogsRequest,
  getDashboardActivityLogsSuccess,
  resetGeneralStates,
} = GeneralReducer.actions;

export default GeneralReducer.reducer;
