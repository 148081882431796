import React from "react";
import { Form, Input, Modal, Select } from "antd";
import { Images } from "../../../../../themes";
import "./styles.scss";
import { useState } from "react";
import { useCustomDispatch } from "../../../../../helpers/useCustomDispatch";
import {
  addGroupMembersRequest,
  createGroupRequest,
  editGroupRequest,
  getDirectChatUsersRequest,
  getRemainingChatUsersRequest,
} from "../../../../../redux/slicers/chat";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonComponent, ProfileImage } from "../../../../../components";
import { setUserDetailDrawer } from "../../../../../redux/slicers/general";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  inputFieldRule,
  replaceValInString,
} from "../../../../../services/utils";
import { useNavigate } from "react-router-dom";
import { GROUP_MESSAGES_ROUTE } from "../../../../../constants";

const CreateGroupModal = ({
  handleClose,
  preview,
  isAddMember,
  handleSuccess,
  isEdit,
}) => {
  //STATES
  const [search, setSearch] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [openMember, setOpenMember] = useState(false);

  // CONST VALS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  //REDUX DATA
  const { directUsersList, selectedRoom } = useSelector((state) => state.chat);
  const { chat_id } = useSelector((state) => state.user.data);

  //CUSTOM DISPATCH
  const [getUsers, isLoading] = useCustomDispatch(
    isAddMember ? getRemainingChatUsersRequest : getDirectChatUsersRequest
  );
  const [createGroup, createLoader] = useCustomDispatch(createGroupRequest);
  const [addMembers, memberLoader] = useCustomDispatch(addGroupMembersRequest);
  const [editGroup, editLoader] = useCustomDispatch(editGroupRequest);
  const actionLoader = editLoader || memberLoader || createLoader;
  //HANDLERS
  const closeHandler = () => {
    setSearch("");
    form.resetFields();
    handleClose();
    setSelectedMembers([]);
  };

  const getUsersHelper = (search) => {
    const queryParams = { limit: 50, offset: 0 };
    if (isAddMember) {
      queryParams["room_id"] = selectedRoom?.id;
    }
    if (search) {
      queryParams["search"] = search;
    }
    getUsers({
      queryParams: queryParams,
    });
  };

  const handleRemoveMember = (index, isName) => {
    const nextFieldVal = [];
    const tempdata = selectedMembers.filter((x) => x?.chat_id !== index);
    setSelectedMembers(tempdata);
    const updatedmembers = tempdata.map((x) => x.chat_id);
    tempdata.forEach((item) => nextFieldVal.push(item.chat_id));
    form.setFieldsValue({
      members: updatedmembers,
    });
  };

  const handleSubmit = (val) => {
    const participants = val?.members?.map((item) => ({
      user_id: item,
    }));

    if (!isAddMember && !isEdit) {
      const payload = {
        participants: [...participants, { user_id: chat_id }],
        room_name: val.name,
        room_type: "group",
      };
      createGroup({
        payload,
        logic(res) {
          navigate(
            replaceValInString(GROUP_MESSAGES_ROUTE, { ":chat_id": res?.slug })
          );
          closeHandler();
          handleSuccess && handleSuccess();
        },
      });
      return;
    }
    if (!isEdit && isAddMember) {
      const payload = {
        participants,
        room_id: selectedRoom?.id,
      };
      addMembers({
        payload,
        logic() {
          closeHandler();
          handleSuccess && handleSuccess();
        },
      });
      return;
    }

    if (isEdit && !isAddMember) {
      const payload = {
        group_name: val.name,
        room_id: selectedRoom?.id,
      };
      editGroup({
        payload,
        logic() {
          closeHandler();
          handleSuccess && handleSuccess();
        },
      });
      return;
    }
  };

  //HOOKS
  useEffect(() => {
    if (preview) {
      if (isEdit) {
        form?.setFieldValue("name", selectedRoom?.name);
      }
      getUsersHelper(search);
    } else {
      setSearch("");
    }
  }, [search, preview]);

  //CUSTOM COMPONENTS
  const memberDetailBox = (data, index) => {
    return (
      <div className="member-box" key={index}>
        <ProfileImage
          image={data?.image}
          username={data?.username}
          isBlocked={!data?.isActive}
          color={data?.profilebg}
          onClick={() => {
            dispatch(
              setUserDetailDrawer({
                visibility: true,
                id: data.id,
              })
            );
          }}
          size={10}
        />
        <span className="user-details">
          <b>{data?.username}</b>
          <p>{data?.email}</p>
        </span>
        <span
          className="close-btn"
          onClick={() => {
            handleRemoveMember(data?.chat_id);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>
    );
  };

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={522}
      className="create-group-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <Images.CloseIcon className="close-btn" onClick={closeHandler} />
          <div className="title">
            <h3>
              {" "}
              {isAddMember
                ? "Add People"
                : isEdit
                ? "Edit Group"
                : "Group Chat +"}
            </h3>
          </div>
          <Form form={form} onFinish={handleSubmit}>
            {!isAddMember && (
              <Form.Item name={"name"}>
                <Input placeholder="Type Name of Group (Optional)" />
              </Form.Item>
            )}
            {!isEdit && (
              <>
                <Form.Item
                  name="members"
                  rules={inputFieldRule({
                    name: "Atleast one member",
                    isWhiteSpace: false,
                  })}
                >
                  <Select
                    loading={isLoading}
                    mode="multiple"
                    placeholder={"Type Name of Person"}
                    maxTagCount={4}
                    optionLabelProp="label"
                    maxTagTextLength={10}
                    onSearch={(e) => {
                      setSearch(e);
                      setOpenMember(true);
                    }}
                    onBlur={(e) => {
                      setOpenMember(false);
                      setSearch("");
                    }}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    onSelect={(val) => {
                      const userdetail = directUsersList.find(
                        (x) => x.chat_id === val
                      );
                      setSelectedMembers([...selectedMembers, userdetail]);
                      setOpenMember(false);
                      setSearch("");
                    }}
                    onDeselect={(val) => {
                      handleRemoveMember(val);
                      setOpenMember(false);
                      setSearch("");
                    }}
                    open={openMember}
                    onClick={() => {
                      setOpenMember(!openMember);
                    }}
                    onFocus={() => setOpenMember(true)}
                  >
                    {directUsersList?.map((item, index) => (
                      <Select.Option
                        value={item?.chat_id}
                        label={item?.username}
                        key={index}
                      >
                        {/* {item?.username} */}
                        <div className="invite-modal-user-autocomplete-options">
                          <ProfileImage
                            color={item.profilebg}
                            image={item.image}
                            username={item.username}
                            isBlocked={false}
                            size={10}
                            classname="thumb"
                          />
                          <div className="option-user-info">
                            <h3>{item.username}</h3>
                            <p>{item.email}</p>
                          </div>
                        </div>{" "}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <div className="selected-partymembers">
                  {selectedMembers.map((item, index) =>
                    memberDetailBox(item, index)
                  )}
                </div>
              </>
            )}
            <div className="modal-footer">
              <Form.Item>
                <ButtonComponent
                  text={isAddMember ? "Add +" : isEdit ? "Edit" : " Create"}
                  isLoading={actionLoader}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default CreateGroupModal;
