import React, { useState } from "react";
import { ClipLoader } from "react-spinners";
import { downloadImage } from "../../../../../helpers/downloadImage";
import { Images } from "../../../../../themes";
import "./styles.scss";

function FileCard({ title, url }) {
  const [downloadLoader, setDownloadLoader] = useState(false);
  //HANDLERS
  const handleDownload = () => {
    downloadImage(url, setDownloadLoader, url);
  };

  return (
    <div className={`file-card-wrapper c-p`} onClick={handleDownload}>
      <div className="card-left">
        <div className="doc-icon-wrapper">
          <Images.DocThumb />
        </div>

        <div className="file-info">
          <h3>{title}</h3>
          <p>Type: Document Template</p>
        </div>
      </div>
      {downloadLoader ? (
        <ClipLoader color="#6F7CED" />
      ) : (
        <Images.Download className="doc-download" />
      )}
    </div>
  );
}

export default FileCard;
