import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { generateGuid } from "../../services/utils";
import CardLoader from "../cardLoader";
import "./styles.scss";

function CustomInfiniteScroll({
  children,
  total,
  dataLength,
  next,
  minHeight = 240,
  maxHeight = 460,
  isWholePage = true,
  isWholeParent = false,
  isPercent = false,
  className = "",
  infiniteclass = "",
  isLoaderNull,
}) {
  const unique = generateGuid();
  return (
    <div
      id={`scroll-target-${unique}`}
      style={{
        minHeight: minHeight,
        maxHeight: `${
          isWholePage
            ? `calc(100vh - ${maxHeight}px)`
            : isWholeParent
            ? `calc(100% - ${maxHeight}px)`
            : isPercent
            ? maxHeight
            : `${maxHeight}px`
        }`,
        overflowY: "auto",
      }}
      className={`custom-scroll-wrapper ${className}`}
    >
      <InfiniteScroll
        className={infiniteclass}
        dataLength={dataLength}
        next={next}
        hasMore={dataLength < total}
        loader={!isLoaderNull && <CardLoader size={10} height={50} />}
        scrollableTarget={`scroll-target-${unique}`}
      >
        {children}
      </InfiniteScroll>
    </div>
  );
}

export default CustomInfiniteScroll;
