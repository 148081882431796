import React, { useState, useEffect } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "antd";
import { getUserDetailRequest } from "../../redux/slicers/general";
import ButtonComponent from "../ButtonComponent";
import UserApprovalCard from "../userApprovalCard";
import { useCustomDispatch } from "../../helpers/useCustomDispatch";
import CardLoader from "../cardLoader";
import "./styles.scss";

const RemoveMemberRoomModal = ({ handleClose, open, onClick, id, loader }) => {
  //CUSTOM DISPATCH
  const [getUserDetail, userLoading] = useCustomDispatch(getUserDetailRequest);
  const [userData, setUserData] = useState([]);
  //HOOKS
  useEffect(() => {
    if (open) {
      getUserDetail({
        pathParams: id,
        logic(res) {
          setUserData({
            id: res.data.id,
            email: res.data.email,
            username: res.data.firstName + " " + res.data.lastName,
            profilebg: res.data.bgcolor,
            jobTitle: res.data.jobTitle,
            phone: res.data.phone,
            thumb: res.data.image,
          });
        },
      });
    }
  }, [open]);

  return (
    <Modal
      destroyOnClose
      visible={open}
      centered
      footer={null}
      width={490}
      className="remove-member-room-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </div>

          <div className="title">
            <h3>Remove Member</h3>
            <p>Are you sure you want to remove this member?</p>
          </div>
          {userLoading ? (
            <CardLoader height={150} />
          ) : (
            <UserApprovalCard data={userData} style={{ width: "100%" }} />
          )}
          <div className="modal-footer">
            <ButtonComponent
              text={`Remove`}
              className="leave-btn"
              onClick={() => onClick(userData?.id)}
              disabled={userData.length < 1}
              isLoading={loader}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveMemberRoomModal;
