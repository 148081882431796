import React, { useState, useEffect } from "react";
import { Modal, Form, Col, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { ButtonComponent } from "../../../../../../../components";
import { toastAlert } from "../../../../../../../services/utils";
import { DATE_FORMAT3 } from "../../../../../../../constants";
import moment from "moment";
import { useParams } from "react-router-dom";
import AddDocumentModal from "../addDocModal";
import { Images } from "../../../../../../../themes";
import { CheckFields } from "./fields";
import {
  createChecklistRequest,
  updateChecklistRequest,
} from "../../../../../../../redux/slicers/transactionchecklist";
import "./styles.scss";

const CreateChecklistModal = ({
  data,
  isUpdate,
  handleClose,
  handleSucess = () => {},
  preview,
}) => {
  // STATES
  const [isChanged, setChanged] = useState(false);
  const [selectedParties, setselectedParties] = useState([]);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [addDocModalPreview, setAddDocModalPreview] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState([]);

  //CUSTOM DISPATCH
  const [createChecklist, createLoader] = useCustomDispatch(
    createChecklistRequest
  );
  const [updateChecklist, updateLoader] = useCustomDispatch(
    updateChecklistRequest
  );

  // CONST VALS
  const { id } = useParams();
  const [form] = Form.useForm();
  const isDisabled = isUpdate && !isChanged;
  const isSelected = selectedDocs.length > 0;
  const isLoading = createLoader || updateLoader;

  // HELPERS
  const requestHelper = (request, payload, pathParams) => {
    request({
      payload,
      pathParams,
      logic({ message }) {
        closeHandler();
        handleSucess();
        toastAlert(message);
      },
    });
  };

  // HOOKS
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        checklistName: data?.name,
        type: data?.type?.key,
        checklistNotes: data?.notes,
        endDate: isUpdate && data?.targetDate ? moment(data?.targetDate) : null,
      });
    }
  }, [data, preview]);

  // HANDLERS
  const handleSubmit = (val) => {
    const payload = {
      name: val.checklistName,
      type: val.type,
      notes: val.checklistNotes,
      targetDate: val.endDate ? moment(val.endDate).format(DATE_FORMAT3) : null,
    };
    if (!isUpdate) {
      payload["visibleToAll"] = false;
      payload["transactionSlug"] = id;
      payload["responsibleUsersIds"] = val.responsibleParties ?? [];
      payload["visibileRoomIds"] = val.visibility;
    }
    if (selectedDocs.length > 0) {
      payload["documentIds"] = selectedDocs.map((x) => x.id);
    }
    requestHelper(
      isUpdate ? updateChecklist : createChecklist,
      payload,
      isUpdate && data?.slug
    );
  };

  const closeHandler = () => {
    handleClose();
    setselectedParties([]);
    setSelectedRooms([]);
    setSelectedDocs([]);
    form.resetFields();
  };

  const addDocModalPreviewHandler = () => {
    setAddDocModalPreview(!addDocModalPreview);
  };

  const addDocHandler = (item) => {
    setSelectedDocs([...selectedDocs, item]);
  };

  const removeDocHandler = (id) => {
    const isExist = selectedDocs.find((x) => x.id === id);
    let temp = [...selectedDocs];
    let index = temp.indexOf(isExist);
    temp.splice(index, 1);
    setSelectedDocs(temp);
  };
  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={522}
      getContainer={false}
      className="create-checklist"
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        className="modal-wrapper"
        onFieldsChange={() => {
          setChanged(true);
        }}
      >
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <CheckFields
            isUpdate={isUpdate}
            selectedParties={selectedParties}
            setselectedParties={setselectedParties}
            transactionid={id}
            form={form}
            selectedRooms={selectedRooms}
            setSelectedRooms={setSelectedRooms}
          />
          {isSelected && (
            <Row gutter={[10, 0]} className="selected-docs-wraper">
              {selectedDocs.map((item, index) => (
                <Col span={24}>
                  <div className="uploaded-file-wrapper">
                    <div className="left-wrapper">
                      <Images.DocThumb className="doc-thumb" />
                      <div className="file-info">
                        <h3>{item.name}</h3>
                        {/* <p>Size 456kb</p> */}
                      </div>
                    </div>
                    <Images.CrossRed
                      className="c-p"
                      onClick={() => removeDocHandler(item.id)}
                    />
                  </div>
                </Col>
              ))}
            </Row>
          )}
        </div>
        <div className="modal-footer">
          {!isUpdate && (
            <span className="add-doc-btn" onClick={addDocModalPreviewHandler}>
              <p>Add Doc +</p>
            </span>
          )}
          <ButtonComponent
            disabled={isDisabled}
            text={isUpdate ? "Update Item" : "Add Item +"}
            isLoading={isLoading}
          />
        </div>
      </Form>
      <AddDocumentModal
        preview={addDocModalPreview}
        handleClose={addDocModalPreviewHandler}
        selectedDocs={selectedDocs}
        setSelectedDocs={setSelectedDocs}
        addDocHandler={addDocHandler}
        removeDocHandler={removeDocHandler}
        isSelected={isSelected}
      />
    </Modal>
  );
};

export default CreateChecklistModal;
