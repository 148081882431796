import React, { useEffect, useMemo, useState } from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useCustomDispatch } from "../../../helpers/useCustomDispatch";
import { getTransactionInvitationDetail } from "../../../redux/slicers/transactiondetail";
import { userSignUpRequest } from "../../../redux/slicers/user";
import { replaceValInString } from "../../../services/utils";
import {
  AuthInviteDetail,
  ButtonComponent,
  CardLoader,
  Header,
} from "../../../components";
import {
  LOGIN_ROUTE,
  COPYRIGHTSTRING,
  ANTD_SCROLL_TO_ERROR_FIELDS_CONFIG,
  LOGIN_INVITE_ROUTE,
} from "../../../constants";
import {
  getCompaniesListRequest,
  getEnterpriseInvitationDetail,
} from "../../../redux/slicers/company";
import {
  manuplateEnterpriseInvitationData,
  manuplateTransactionInvitationData,
  payloadGenerator,
} from "./helpers";
import EmailVerification from "../emailVerification";
import SignupFields from "./fields";
import "../styles.scss";

const SignUp = () => {
  // CONST VALS
  const { token } = useParams();
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  // STATES
  const [isTransactonInvite, setTransactonInvite] = useState(false);
  const [isEnterpriseInvite, setEnterpriseInvite] = useState(false);
  const [selectedEmail, setselectedEmail] = useState("");
  const [inviteInfo, setInviteInfo] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [searchText, setSearchText] = useState("");
  const [emailVerificationPreview, setEmailVerificationPreview] =
    useState(false);
  const [disableCompanyRelatedFields, setDisableCompanyRelatedFields] =
    useState(false);

  // REDUX DATA
  const { companiesList } = useSelector((state) => state.company);

  // CUSTOM DISPATCH
  const [sendSignUpReq, signUpReqLoader] = useCustomDispatch(userSignUpRequest);
  const [getEnterpriseInviteDetail, isLoading] = useCustomDispatch(
    getEnterpriseInvitationDetail
  );
  const [getTransactionInviteDetail, isInviteLoading] = useCustomDispatch(
    getTransactionInvitationDetail
  );
  const [getCompaniesListReq] = useCustomDispatch(getCompaniesListRequest);

  // HOOKS
  useEffect(() => {
    if (pathname.includes("transactioninvite")) {
      setTransactonInvite(true);
      return;
    }
    pathname.includes("enterpriseinvite") && setEnterpriseInvite(true);
  }, [pathname]);

  useEffect(() => {
    getCompaniesHandler();
  }, []);

  useEffect(() => {
    if (isEnterpriseInvite) {
      getEnterpriseInviteDetail({
        pathParams: token,
        logic(res) {
          manuplateEnterpriseInvitationData(
            res,
            form,
            setDisableCompanyRelatedFields,
            setCompanyName
          );
        },
        error() {
          setEnterpriseInvite(false);
        },
      });
    } else if (isTransactonInvite) {
      getTransactionInviteDetail({
        pathParams: token,
        logic({ data, isAccepted }) {
          if (isAccepted) {
            navigate(LOGIN_ROUTE);
            return;
          }
          if (data?.invitee?.isExists) {
            const vals = {
              ":token": token,
            };
            navigate(replaceValInString(LOGIN_INVITE_ROUTE, vals));
            return;
          }
          manuplateTransactionInvitationData(
            data,
            form,
            setDisableCompanyRelatedFields,
            setCompanyName,
            setInviteInfo
          );
        },
        error() {
          setTransactonInvite(false);
        },
      });
    }
  }, [isEnterpriseInvite, isTransactonInvite]);

  // -- filter companies data key according to autocomplete format
  const companies = useMemo(
    () =>
      companiesList
        .filter((obj) => obj.name)
        .map((obj) => ({ value: obj.name, logo: obj.logo })),
    [companiesList]
  );

  // HANDLERS
  const setEmailVerificationPreviewHandler = () => {
    setEmailVerificationPreview(!emailVerificationPreview);
    window.scrollTo(0, 0);
  };

  const setCompanyHandler = (val) => {
    setCompanyName(val);
  };

  const getCompaniesHandler = () => {
    const params = { offset: 0 };
    getCompaniesListReq({
      queryParams: params,
    });
  };

  const searchHandler = (val) => {
    setSearchText(val);
    onselectOptionHandler(val);
  };

  const onselectOptionHandler = (value) => {
    const companyInfoObj = companiesList.find((obj) => obj.name === value);
    if (companyInfoObj) {
      const companyInfo = companyInfoObj.addresses[0];
      setDisableCompanyRelatedFields(true);
      form.setFieldsValue({
        address: companyInfo?.address,
        state: companyInfo?.state,
        city: companyInfo?.city,
        zip: companyInfo?.zip,
        country: companyInfo?.country ?? null,
      });
    }
  };

  const resetCompanyRelatedFields = (text) => {
    if (disableCompanyRelatedFields) {
      if (companiesList.find((obj) => obj.name === text)) return;
      setDisableCompanyRelatedFields(false);
      form.setFieldsValue({
        address: "",
        state: "",
        city: "",
        zip: "",
        country: "",
      });
    }
  };

  const handleFormFinish = (values) => {
    const payload = { ...payloadGenerator(values, companyName) };
    if (isEnterpriseInvite) {
      payload["isInvited"] = token;
    } else if (isTransactonInvite) {
      payload["transactionInviteRequestId"] = token;
    }
    sendSignUpReq({
      payload,
      logic() {
        setselectedEmail(payload.email);
        setEmailVerificationPreviewHandler();
      },
    });
  };

  return (
    <>
      <Header />
      {emailVerificationPreview ? (
        <EmailVerification
          useremail={selectedEmail}
          fromSignup
          closeHandler={setEmailVerificationPreviewHandler}
        />
      ) : (
        <section className="signing-wrapper">
          <div className="form-wrapper">
            <h3 className="auth-title">
              <span>
                DealTru
                <br /> Create An Account
              </span>
            </h3>

            {isTransactonInvite && (
              <AuthInviteDetail data={inviteInfo} loader={isInviteLoading} />
            )}
            {isLoading ? (
              <CardLoader height={400} />
            ) : (
              <Form
                style={{ marginTop: 40 }}
                form={form}
                autoComplete={"nope"}
                className="form"
                onFinish={handleFormFinish}
                scrollToFirstError={ANTD_SCROLL_TO_ERROR_FIELDS_CONFIG}
              >
                <SignupFields
                  companies={companies}
                  companyName={companyName}
                  isEnterpriseInvite={isEnterpriseInvite}
                  isTransactonInvite={isTransactonInvite}
                  disableCompanyRelatedFields={disableCompanyRelatedFields}
                  onselectOptionHandler={onselectOptionHandler}
                  resetCompanyRelatedFields={resetCompanyRelatedFields}
                  searchHandler={searchHandler}
                  setCompanyName={setCompanyHandler}
                />
                <Form.Item>
                  <ButtonComponent
                    className="login-btn"
                    isLoading={signUpReqLoader}
                    text="Signup"
                  />
                </Form.Item>
              </Form>
            )}
            {!isTransactonInvite && (
              <div className="register-here">
                Have an account?{" "}
                <Link to={LOGIN_ROUTE} style={{ color: "#1C2B48" }}>
                  Login here
                </Link>
              </div>
            )}
          </div>
          <div>
            <p className="copyright-text">{COPYRIGHTSTRING}</p>
          </div>
        </section>
      )}
    </>
  );
};

export default SignUp;
