import { call, fork, put, take, takeEvery } from "redux-saga/effects";
import {
  callRequest,
  INVITE_PARTY_TRANSACTION,
  ADD_MEMBER_TO_ROOM,
  CHANGE_ADMIN,
  CHECK_ADMIN,
  CREATE_BREAKOUT_ROOM,
  GET_TRANSACTION_INVITATION_DETAIL,
  GET_ALL_ROOM_MEMBERS,
  GET_ROOM_MEMBERS,
  GET_TRANSACTION_ENTERPRISES,
  GET_TRANSACTION_PARTIES,
  GET_BREAKOUT_ROOMS_LIST,
  GET_TRANSACTION_ENTERPRISE_MEMBERS,
  GET_TRANSACTION_DETAILS,
  GET_WORKING_GROUPS,
  LEAVE_ROOM,
  REMOVE_PARTICIPANT,
  UPDATE_BREAKOUT_ROOM,
  TRANSACTION_INVITATION_RESPONSE,
  GET_TRANSACTION_ROOMS_LIST,
  REMOVE_BREAKOUT_ROOM_ADMIN,
  MAKE_BREAKOUT_ROOM_ADMIN,
  GET_ALL_TRANSACTION_ADMINS,
  CHECK_TRANSACTION_ADMIN,
  CHANGE_TRANSACTION_ADMIN_STATUS,
  REJECT_REMOVAL,
  ACCEPT_REMOVAL,
  RESPOND_REJOIN,
  GET_ROOMS_WITH_MEMBERS,
  USER_COMPANY_MEMBERS,
  GET_PERMISSIONS,
  UPDATE_PERMISSIONS,
  GET_REQUESTS,
} from "../../config/webService";
import { ALERT_TYPES } from "../../constants";
import {
  manipulateWorkingGroupsList,
  manipulateTransactionData,
  manipulateTransactionMembersList,
  manipulateRoomData,
  manipulateRoomList,
  manipulateCompanyMembersList,
  manipulatePermissionsList,
  manipulateRequestsList,
} from "../../dataManipulator/transactiondetail";
import { manipulateUsersList } from "../../dataManipulator/user";
import { toastAlert } from "../../services/utils";
import {
  addMemberToRoomRequest,
  invitePartyRequest,
  checkBreakoutRoomAdminRequest,
  createBreakoutRoomRequest,
  getTransactionRoomsRequest,
  getTransactionRoomsSuccess,
  getBreakoutRoomsRequest,
  getBreakoutRoomsSuccess,
  getAllRoomsMembersRequest,
  getAllRoomsMembersSuccess,
  getRoomMembersRequest,
  getRoomMembersSuccess,
  getEnterpriseMembersRequest,
  getEnterpriseMembersSuccess,
  getRoomDetailRequest,
  getRoomDetailSuccess,
  getTransactionDetailRequest,
  getTransactionDetailSuccess,
  getWorkingGroupsRequest,
  getWorkingGroupsSuccess,
  leaveRoomRequest,
  changeAdminRequest,
  removeParticipantRequest,
  updateBreakoutRoomRequest,
  getTransactionPartiesRequest,
  getTransactionPartiesSuccess,
  getTransactionInvitationDetail,
  transactionInviteResponseRequest,
  getTransactionEnterprisesRequest,
  getTransactionEnterprisesSuccess,
  getTransactionMembersRequest,
  getTransactionMembersSuccess,
  removeBreakoutRoomAdminRequest,
  makeBreakoutRoomAdminRequest,
  getAllTransactionAdminsListRequest,
  getAllTransactionAdminsListSuccess,
  checkLastTransactionAdminRequest,
  changeTransactionAdminStatusRequest,
  changeTransactionAdminStatusSuccess,
  rejectRemovalRequest,
  rejectRemovalSuccess,
  acceptRemovalRequest,
  acceptRemovalSuccess,
  respondToRejoinRequest,
  getRoomsWithMembersRequest,
  getRoomsWithMembersSuccess,
  getUserCompanyMembersRequest,
  getUserCompanyMembersSuccess,
  getUserPermissionsRequest,
  getUserPermissionsSuccess,
  updateUserPermissionsRequest,
  updateUserPermissionsSuccess,
  getChangeRequestsListRequest,
  getChangeRequestsListSuccess,
} from "../slicers/transactiondetail";

function* getTransactionDetail() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getTransactionDetailRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_TRANSACTION_DETAILS,
        pathParams,
      });
      if (response?.status) {
        yield put(
          getTransactionDetailSuccess(manipulateTransactionData(response.data))
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

function* getTransactionEnterprises() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getTransactionEnterprisesRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_TRANSACTION_ENTERPRISES,
        pathParams,
      });
      if (response.status) {
        yield put(getTransactionEnterprisesSuccess(response.data));
        responseCallback?.(true, response.data);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getTransactionsParties() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getTransactionPartiesRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_TRANSACTION_PARTIES,
        pathParams,
      });
      if (response.status) {
        yield put(getTransactionPartiesSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getTransactionEnterpriseMembers() {
  while (true) {
    const {
      payload: { queryParams, responseCallback },
    } = yield take(getEnterpriseMembersRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_TRANSACTION_ENTERPRISE_MEMBERS,
        queryParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        yield put(
          getEnterpriseMembersSuccess(
            manipulateUsersList(response.data, queryParams.companyId)
          )
        );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

function* inviteParty() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(invitePartyRequest.type);

    try {
      const response = yield call(callRequest, {
        url: INVITE_PARTY_TRANSACTION,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getWorkingGroups() {
  while (true) {
    const {
      payload: { queryParams, pathParams, responseCallback },
    } = yield take(getWorkingGroupsRequest.type);
    const { room } = queryParams;
    try {
      const response = yield call(callRequest, {
        url: GET_WORKING_GROUPS,
        pathParams,
        queryParams,
      });
      if (response.status) {
        yield put(
          getWorkingGroupsSuccess(
            manipulateWorkingGroupsList(response.data, room)
          )
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

function* getTransactionMembers(action) {
  const {
    payload: { queryParams, pathParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_WORKING_GROUPS,
      pathParams,
      queryParams,
    });
    if (response.status) {
      yield put(
        getTransactionMembersSuccess(
          manipulateTransactionMembersList(response.data?.workingGroups)
        )
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

// function* getTransactionMembers() {
//   while (true) {
//     const {
//       payload: { queryParams, pathParams, responseCallback },
//     } = yield take(getTransactionMembersRequest.type);
//     try {
//       const response = yield call(callRequest, {
//         url: GET_WORKING_GROUPS,
//         pathParams,
//         queryParams,
//       });
//       if (response.status) {
//         yield put(
//           getTransactionMembersSuccess(
//             manipulateTransactionMembersList(response.data?.workingGroups)
//           )
//         );
//         responseCallback?.(true, response);
//       } else {
//         responseCallback?.(false, response);
//         response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
//       }
//     } catch (err) {
//       console.error(err);
//       responseCallback?.(false, err);
//     }
//   }
// }

function* createBreakoutRoom() {
  while (true) {
    const {
      payload: { pathParams, payload, responseCallback },
    } = yield take(createBreakoutRoomRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CREATE_BREAKOUT_ROOM,
        data: payload,
        pathParams: pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

function* leaveRoom() {
  while (true) {
    const {
      payload: { pathParams, payload, responseCallback },
    } = yield take(leaveRoomRequest.type);

    try {
      const response = yield call(callRequest, {
        url: LEAVE_ROOM,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* removeParticipant() {
  while (true) {
    const {
      payload: { pathParams, payload, responseCallback },
    } = yield take(removeParticipantRequest.type);

    try {
      const response = yield call(callRequest, {
        url: REMOVE_PARTICIPANT,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* removeAdmin() {
  while (true) {
    const {
      payload: { pathParams, payload, responseCallback },
    } = yield take(changeAdminRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CHANGE_ADMIN,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getTransactionRoomsList() {
  while (true) {
    const {
      payload: { pathParams, queryParams, responseCallback },
    } = yield take(getTransactionRoomsRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_TRANSACTION_ROOMS_LIST,
        pathParams,
        queryParams,
      });
      if (response.status) {
        yield put(
          getTransactionRoomsSuccess(manipulateRoomList(response.data))
        );
        responseCallback?.(true, response.data);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}
function* getBreakOutRoomsList() {
  while (true) {
    const {
      payload: { queryParams, responseCallback },
    } = yield take(getBreakoutRoomsRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_BREAKOUT_ROOMS_LIST,
        queryParams,
      });
      if (response.status) {
        yield put(getBreakoutRoomsSuccess(response.data));
        responseCallback?.(true, response.data);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

function* getBreakOutRoomsDetails() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getRoomDetailRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_BREAKOUT_ROOMS_LIST,
        pathParams,
      });
      if (response.status) {
        yield put(getRoomDetailSuccess(manipulateRoomData(response.data)));
        responseCallback?.(true, response.data);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

function* checkBreakoutRoomAdmin() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(checkBreakoutRoomAdminRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CHECK_ADMIN,
        pathParams,
      });
      if (response) {
        responseCallback?.(true, response.status);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updateBreakoutRoom() {
  while (true) {
    const {
      payload: { pathParams, payload, responseCallback },
    } = yield take(updateBreakoutRoomRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPDATE_BREAKOUT_ROOM,
        data: payload,
        pathParams: pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* addMemberToRoom() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(addMemberToRoomRequest.type);

    try {
      const response = yield call(callRequest, {
        url: ADD_MEMBER_TO_ROOM,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getAllRoomsMembersList() {
  while (true) {
    const {
      payload: { queryParams, responseCallback },
    } = yield take(getAllRoomsMembersRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_ALL_ROOM_MEMBERS,
        queryParams,
      });
      if (response.status) {
        yield put(
          getAllRoomsMembersSuccess(manipulateUsersList(response.data))
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getRoomMembersList() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getRoomMembersRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_ROOM_MEMBERS,
        pathParams,
      });
      if (response.status) {
        yield put(getRoomMembersSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* transactionInviteDetail(action) {
  const {
    payload: { queryParams, pathParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_TRANSACTION_INVITATION_DETAIL,
      pathParams,
      queryParams,
    });
    if (response.status) {
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    console.error(err);
    responseCallback?.(false, err);
  }
}

function* transactionInviteResponse() {
  while (true) {
    const {
      payload: { pathParams, payload, responseCallback },
    } = yield take(transactionInviteResponseRequest.type);

    try {
      const response = yield call(callRequest, {
        url: TRANSACTION_INVITATION_RESPONSE,
        data: payload,
        pathParams: pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* removeBreakoutRoomAdmin() {
  while (true) {
    const {
      payload: { pathParams, payload, responseCallback },
    } = yield take(removeBreakoutRoomAdminRequest.type);

    try {
      const response = yield call(callRequest, {
        url: REMOVE_BREAKOUT_ROOM_ADMIN,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* makeBreakoutRoomAdmin() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(makeBreakoutRoomAdminRequest.type);

    try {
      const response = yield call(callRequest, {
        url: MAKE_BREAKOUT_ROOM_ADMIN,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getTransactionAdminsList() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getAllTransactionAdminsListRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_ALL_TRANSACTION_ADMINS,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        yield put(
          getAllTransactionAdminsListSuccess(
            manipulateUsersList(response.data, [], true)
          )
        );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

function* checkLastTransactionAdmin() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(checkLastTransactionAdminRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CHECK_TRANSACTION_ADMIN,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* changeTransactionAdminStatus() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(changeTransactionAdminStatusRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CHANGE_TRANSACTION_ADMIN_STATUS,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        yield put(changeTransactionAdminStatusSuccess(payload));
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

function* rejectRemoval() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(rejectRemovalRequest.type);

    try {
      const response = yield call(callRequest, {
        url: REJECT_REMOVAL,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        yield put(rejectRemovalSuccess());
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

function* acceptRemoval() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(acceptRemovalRequest.type);

    try {
      const response = yield call(callRequest, {
        url: ACCEPT_REMOVAL,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        yield put(acceptRemovalSuccess());
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

function* respondRejoin() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(respondToRejoinRequest.type);

    try {
      const response = yield call(callRequest, {
        url: RESPOND_REJOIN,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        response.message && toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

function* getRoomsWithMembers() {
  while (true) {
    const {
      payload: { queryParams, responseCallback },
    } = yield take(getRoomsWithMembersRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_ROOMS_WITH_MEMBERS,
        queryParams,
      });
      if (response.status) {
        yield put(
          getRoomsWithMembersSuccess(manipulateRoomList(response.data))
        );
        responseCallback?.(true, response.data);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

function* getPermissions() {
  while (true) {
    const {
      payload: { pathParams, queryParams, responseCallback },
    } = yield take(getUserPermissionsRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_PERMISSIONS,
        pathParams,
        queryParams,
      });
      if (response.status) {
        yield put(
          getUserPermissionsSuccess(manipulatePermissionsList(response.data))
        );
        responseCallback?.(true, response.data);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

function* updatePermissions(action) {
  const {
    payload: { payload, queryParams, pathParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: UPDATE_PERMISSIONS,
      data: payload,
      pathParams,
      queryParams,
    });
    if (response.status) {
      toastAlert(response?.message);
      yield put(updateUserPermissionsSuccess(response.data));
      responseCallback?.(true, response.data);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    console.error(err);
    responseCallback?.(false, err);
  }
}

function* getUserCompanyMembers() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getUserCompanyMembersRequest.type);

    try {
      const response = yield call(callRequest, {
        url: USER_COMPANY_MEMBERS,
        pathParams,
      });
      if (response.status) {
        yield put(
          getUserCompanyMembersSuccess(
            manipulateCompanyMembersList(response.data)
          )
        );
        responseCallback?.(true, response.data);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getRequestList() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getChangeRequestsListRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_REQUESTS,
        pathParams,
      });
      if (response.status) {
        yield put(
          getChangeRequestsListSuccess(manipulateRequestsList(response.data))
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

export default function* root() {
  yield fork(getTransactionDetail);
  yield fork(getTransactionsParties);
  yield fork(inviteParty);
  yield fork(createBreakoutRoom);
  yield fork(getWorkingGroups);
  // yield fork(getTransactionMembers);
  yield takeEvery(getTransactionMembersRequest.type, getTransactionMembers);
  yield fork(leaveRoom);
  yield fork(removeAdmin);
  yield fork(removeParticipant);
  yield fork(getTransactionEnterprises);
  yield fork(getTransactionEnterpriseMembers);
  yield fork(getTransactionRoomsList);
  yield fork(getBreakOutRoomsList);
  yield fork(getBreakOutRoomsDetails);
  yield fork(updateBreakoutRoom);
  yield fork(checkBreakoutRoomAdmin);
  yield fork(addMemberToRoom);
  yield fork(getAllRoomsMembersList);
  yield fork(getRoomMembersList);
  yield takeEvery(getTransactionInvitationDetail.type, transactionInviteDetail);
  yield fork(transactionInviteResponse);
  yield fork(removeBreakoutRoomAdmin);
  yield fork(makeBreakoutRoomAdmin);
  yield fork(getTransactionAdminsList);
  yield fork(checkLastTransactionAdmin);
  yield fork(changeTransactionAdminStatus);
  yield fork(rejectRemoval);
  yield fork(acceptRemoval);
  yield fork(respondRejoin);
  yield fork(getRoomsWithMembers);
  yield fork(getPermissions);
  yield fork(getUserCompanyMembers);
  yield takeEvery(updateUserPermissionsRequest.type, updatePermissions);
  yield fork(getRequestList);
}
