import { BASE_URL } from "../config/webService";
import { userLogOutSuccess } from "../redux/slicers/user";
import axios from "axios";
import DataHandler from "../services/DataHandler";
import { getCurrentRefreshToken, toastAlert } from "../services/utils";
import { ALERT_TYPES } from "../constants";
import { removeChatTokenRequest } from "../redux/slicers/chat";

// GENERATE REFRESH TOKEN
export const refreshAccessToken = async () => {
  // const method = "POST";
  const url = "api/auth/refreshToken";
  const method = "POST";
  const headers = { Authorization: `Bearer ${getCurrentRefreshToken()}` };
  try {
    const response = await axios({
      baseURL: BASE_URL,
      method: method,
      url: url,
      headers: headers,
    });
    return response.data?.accessToken;
  } catch ({ response }) {
    toastAlert(response.data.message, ALERT_TYPES.ERROR);
    DataHandler.getStore().dispatch(
      removeChatTokenRequest({ responseCallback: () => {} })
    );
    DataHandler.getStore().dispatch(userLogOutSuccess());
    return false;
  }
};
