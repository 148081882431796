import React, { useState } from "react";
import { Tooltip } from "antd";
import { Images } from "../../../../../../../themes";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import {
  deleteLinkRequest,
  deleteDataRoomRequest,
} from "../../../../../../../redux/slicers/transactiondataroom";
import {
  VisibleToPeople,
  DeleteModal,
  ProfileImage,
  EditDeleteDropdown,
} from "../../../../../../../components";
import {
  UploadLinkModal,
  AddNoteModal,
  CreateDataroomModal,
  MemberAccessModal,
} from "../";
import { toastAlert } from "../../../../../../../services/utils";
import { setUserDetailDrawer } from "../../../../../../../redux/slicers/general";
import { useDispatch } from "react-redux";
import "./styles.scss";

// LINK CARD
function Card({
  data,
  optionsDisabled,
  editPreviewHandler,
  deletePreviewHandler,
  addNotePreviewHandler,
  isDataRoom,
  membersModalPreviewHandler,
  onCardClick,
  isLinkManageAllowed,
}) {
  //CONST VALS
  const dispatch = useDispatch();
  const isEditable = (data.isOwner || data.isAdmin) && isLinkManageAllowed;
  const { creatorInfo } = data;
  const toolTipCondition = data?.name?.length > 33;

  return (
    <div
      className={`link-card-wrapper ${isDataRoom && "c-p"}`}
      onClick={() => onCardClick && onCardClick(data.slug)}
    >
      <div className="link-card-top">
        <div className="top-left-wrapper">
          {isDataRoom ? <Images.DataRoomIcon /> : <Images.LinkIconColored />}
          <span className="basic-detail">
            <Tooltip
              title={data?.name}
              trigger={`${toolTipCondition && "hover"} `}
            >
              <h3 className={`${toolTipCondition ? "c-p" : ""}`}>
                {data.name}
              </h3>
            </Tooltip>
            {!isDataRoom && (
              <a href={data.url} target="_blank" referrerPolicy="noreference">
                <p>{data.url}</p>
              </a>
            )}
          </span>
        </div>
        {!optionsDisabled && (
          <div
            className="top-right-wrapper"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {!isDataRoom && isLinkManageAllowed && (
              <Images.AddNoteIcon onClick={addNotePreviewHandler} />
            )}
            {isEditable && (
              <EditDeleteDropdown
                onDeleteClick={deletePreviewHandler}
                onEditClick={editPreviewHandler}
              />
            )}
          </div>
        )}
      </div>
      <div className="link-card-bottom">
        <div
          className="bottom-left-wrapper"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ProfileImage
            username={creatorInfo.username}
            color={creatorInfo.profilebg}
            image={creatorInfo.image}
            isBlocked={!creatorInfo.isActive}
            onClick={() => {
              dispatch(
                setUserDetailDrawer({
                  visibility: true,
                  id: creatorInfo.id,
                })
              );
            }}
            size={10}
          />
          <span
            className="c-p"
            onClick={() => {
              dispatch(
                setUserDetailDrawer({
                  visibility: true,
                  id: data.creatorInfo.id,
                })
              );
            }}
            style={{ maxWidth: "calc(100% - 42px)" }}
          >
            {isDataRoom && <p>Created By</p>}
            <h3>{data.creatorInfo?.username}</h3>
          </span>
        </div>
        <div className="bottom-right-wrapper">
          {data?.isVisibleToAll ? (
            <p className="visibility-text">Visible To All</p>
          ) : (
            <VisibleToPeople
              data={data.visibilities}
              maxLength={5}
              detailModalHandler={membersModalPreviewHandler}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function LinkCard({
  data,
  transactionslug,
  noteCreatedHandler,
  isDataRoom,
  onCardClick,
  optionsDisabled,
  setTotal,
  isLinkManageAllowed,
}) {
  // STATES
  const [editModalPreview, seteditModalPreview] = useState(false);
  const [deleteModalPreview, setDeleteModalPreview] = useState(false);
  const [addNoteModalPreview, setAddNoteModalPreview] = useState(false);
  const [membersModalPreview, setMembersModalPreview] = useState(false);

  //CUSTOM DISPATCH
  const [deleteLink, linkLoader] = useCustomDispatch(deleteLinkRequest);
  const [deleteDataRoom, roomLoader] = useCustomDispatch(deleteDataRoomRequest);

  // CONST VALS
  const isLoading = linkLoader || roomLoader;

  // HELPERS
  const requestHelper = (request) => {
    request({
      pathParams: data.slug,
      logic({ message }) {
        toastAlert(message);
        deleteModalPreviewHandler();
        setTotal();
      },
    });
  };
  //HANDLERS
  const editModalPreviewHandler = () => {
    seteditModalPreview(!editModalPreview);
  };
  const deleteModalPreviewHandler = () => {
    setDeleteModalPreview(!deleteModalPreview);
  };
  const addNoteModalPreviewHandler = () => {
    setAddNoteModalPreview(!addNoteModalPreview);
  };
  const handleDelete = () => {
    requestHelper(isDataRoom ? deleteDataRoom : deleteLink);
  };
  const membersModalPreviewHandler = () => {
    setMembersModalPreview(!membersModalPreview);
  };

  return (
    <>
      <Card
        data={data}
        editPreviewHandler={editModalPreviewHandler}
        deletePreviewHandler={deleteModalPreviewHandler}
        addNotePreviewHandler={addNoteModalPreviewHandler}
        isDataRoom={isDataRoom}
        onCardClick={onCardClick}
        optionsDisabled={optionsDisabled}
        membersModalPreviewHandler={membersModalPreviewHandler}
        isLinkManageAllowed={isLinkManageAllowed}
      />
      {isDataRoom ? (
        <CreateDataroomModal
          handleClose={editModalPreviewHandler}
          preview={editModalPreview}
          transactionslug={transactionslug}
          isEdit={true}
          data={data}
          title={"Data Room"}
          placeholder="Data Room Name"
          isDetail={false}
        />
      ) : (
        <UploadLinkModal
          handleClose={editModalPreviewHandler}
          preview={editModalPreview}
          transactionslug={transactionslug}
          isEdit={true}
          data={data}
        />
      )}
      <DeleteModal
        preview={deleteModalPreview}
        handleClose={deleteModalPreviewHandler}
        title={`Delete ${isDataRoom ? "Data Room" : "Link"}`}
        description={`Are you sure you want to delete this ${
          isDataRoom ? "data room" : "link"
        }?`}
        innerSec={<Card data={data} optionsDisabled isDataRoom={isDataRoom} />}
        isLoading={isLoading}
        handleDelete={handleDelete}
        isDataRoom
      />
      <AddNoteModal
        linkid={data.id}
        preview={addNoteModalPreview}
        handleClose={addNoteModalPreviewHandler}
        handleSuccess={noteCreatedHandler}
      />
      <MemberAccessModal
        preview={membersModalPreview}
        handleClose={membersModalPreviewHandler}
        members={data.visibilities}
        isEditable={false}
      />
    </>
  );
}

export default LinkCard;
