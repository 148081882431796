import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  UserApprovalCard,
  CardLoader,
  EmptyBox,
  RemoveTeamMember,
  MemberApprovalModal,
} from "../../../../components";
import { Images } from "../../../../themes";

function EnterpriseRequests({ addresses, pendingRequests, isLoading }) {
  // STATES
  const [selectedData, setselectedData] = useState(null);
  const [approvalModalPreview, setApprovalModalPreview] = useState(false);
  const [removeModalPreview, setremoveModalPreview] = useState(false);

  // CONST VALS
  const navigate = useNavigate();

  // HANDLERS
  const setselectedDataHandler = (data) => {
    setselectedData(data);
  };
  const approvalModalPreviewHandler = () => {
    setApprovalModalPreview(!approvalModalPreview);
  };
  const removeModalPreviewHandler = () => {
    setremoveModalPreview(!removeModalPreview);
  };

  return (
    <>
      <div className="heading-tabs">
        <div className="teamMemberRequestView-heading">
          <Images.BackArrow className="c-p" onClick={() => navigate(-1)} />
          <h4>Team Members Request</h4>
        </div>
      </div>
      <div className="requests-container">
        {isLoading ? (
          <CardLoader height="100%" />
        ) : (
          <>
            {pendingRequests?.length >= 1 ? (
              pendingRequests.map((item, i) => (
                <UserApprovalCard
                  key={i}
                  data={item}
                  setData={setselectedDataHandler}
                  setMemberApprovalModalPreview={approvalModalPreviewHandler}
                  setMemberRemoveModalPreview={removeModalPreviewHandler}
                  buttons
                />
              ))
            ) : (
              <EmptyBox height="100%" />
            )}
          </>
        )}
      </div>
      <MemberApprovalModal
        selectedData={selectedData}
        addresses={addresses}
        modalPreview={approvalModalPreview}
        setRemoveModal={removeModalPreviewHandler}
        handleClose={approvalModalPreviewHandler}
      />
      <RemoveTeamMember
        selectedData={selectedData}
        preview={removeModalPreview}
        handleClose={removeModalPreviewHandler}
      />
    </>
  );
}

export default EnterpriseRequests;
