import React, { useState } from "react";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Menu } from "antd";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import Images from "../../../../../../../themes/images";
import {
  setUserDetailDrawer,
  setCompanyDetailDrawer,
} from "../../../../../../../redux/slicers/general";
import {
  addMemberToRoomRequest,
  checkBreakoutRoomAdminRequest,
  makeBreakoutRoomAdminRequest,
  removeBreakoutRoomAdminRequest,
  removeParticipantRequest,
} from "../../../../../../../redux/slicers/transactiondetail";
import {
  excludeValueFromArray,
  isArrayIncludesValue,
} from "../../../../../../../services/utils";
import {
  CardLoader,
  EmptyBox,
  RemoveMemberRoomModal,
  LeaveRoomModal,
  ProfileImage,
} from "../../../../../../../components";
import { RespondModal } from "../../components";

const RoomWorkingGroups = ({
  groups,
  loader,
  onAddMember,
  isAdminLogin,
  roomAcess,
  checkUserStatusHandler,
  canEdit,
}) => {
  //STATES
  const [selectedTeam, setSelectedTeam] = useState([0]);
  const [selectedUserID, setSelectedUserID] = useState(undefined);
  const [removeMemberModalPreview, setRemoveMemberModalPreview] =
    useState(false);
  const [removeAdminModalPreview, setRemoveAdminModalPreview] = useState(false);
  const [isAlreadyAdmin, setIsAlreadyAdmin] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isLastAdmin, setIsLastAdmin] = useState(false);
  const [responseModalPreview, setResponseModalPreview] = useState(false);
  const [selectedResponse, setSelectedResponse] = useState(null);

  //REDUX DATA
  const { user_id } = useSelector((state) => state.user.data);

  //CUSTOM DISPATCH
  const [checkAdmin] = useCustomDispatch(checkBreakoutRoomAdminRequest);
  const [addMember, addMemberLoading] = useCustomDispatch(
    addMemberToRoomRequest
  );
  const [removeMember, removeMemberLoader] = useCustomDispatch(
    removeParticipantRequest
  );
  const [makeAdmin, createAdminLoader] = useCustomDispatch(
    makeBreakoutRoomAdminRequest
  );
  const [removeAdmin, removeAdminLoader] = useCustomDispatch(
    removeBreakoutRoomAdminRequest
  );

  //CONST VAL
  const { room } = useParams();
  const dispatch = useDispatch();
  const isChangeAdminLoading = createAdminLoader || removeAdminLoader;

  // HELPERS
  const removeMemberHelper = (id) => {
    const payload = { userId: id };
    removeMember({
      pathParams: room,
      payload,
      logic(res) {
        if (res.status) {
          setIsLastAdmin;
          setRemoveMemberModalPreview(false);
          onAddMember();
        }
      },
    });
  };

  const checkLastAdminHelper = () => {
    checkAdmin({
      pathParams: room,
      logic(status) {
        setIsLastAdmin(!status);
        setRemoveAdminModalPreview(true);
      },
    });
  };

  const addMemberHelper = (userId) => {
    const payload = { userId, roomSlug: room };
    addMember({
      payload,
      logic() {
        onAddMember();
      },
    });
  };

  const changeAdminRequestHelper = (request, payload, pathParams) => {
    request({
      payload,
      pathParams,
      logic() {
        setRemoveAdminModalPreview(false);
        onAddMember();
      },
    });
  };

  const changeAdminHelper = (id) => {
    const makeadminpayload = { userId: selectedUserID, roomSlug: room };
    const removeadminpayload = {
      makeAdmin: isLastAdmin,
      userId: isLastAdmin ? id : selectedUserID,
    };
    if (isAlreadyAdmin) {
      changeAdminRequestHelper(removeAdmin, removeadminpayload, room);
      return;
    }
    changeAdminRequestHelper(makeAdmin, makeadminpayload);
  };

  //HANDLERS
  const setSelectedTeamHandler = (index) => {
    let tempData = [...selectedTeam];
    const isExist = isArrayIncludesValue(tempData, index);
    if (isExist) {
      let updateData = excludeValueFromArray(tempData, index);
      setSelectedTeam([...updateData]);
    } else {
      setSelectedTeam([...tempData, index]);
    }
  };

  const removeMemberHander = (userid) => {
    setSelectedUserID(userid);
    setRemoveMemberModalPreview(true);
  };

  const changeAdminRoleHander = (userid, isAdmin) => {
    setSelectedUserID(userid);
    setIsAlreadyAdmin(isAdmin);
    if (isAdmin) {
      checkLastAdminHelper();
      return;
    }
    setIsLastAdmin(false);
    setRemoveAdminModalPreview(true);
  };

  const setDetailDrawer = (id, isCompany) => {
    const request = isCompany ? setCompanyDetailDrawer : setUserDetailDrawer;
    checkUserStatusHandler(() => {
      dispatch(
        request({
          visibility: true,
          id: id,
        })
      );
    });
  };

  const handleResponseModalPreview = (res) => {
    selectedResponse
      ? setSelectedResponse(null)
      : setSelectedResponse({ message: res.removeResponse, id: res.id });
    setResponseModalPreview(!responseModalPreview);
  };

  // CUSTOM COMPONENT
  const userMenu = (item, loggedIn) => (
    <Menu
      items={[
        loggedIn && {
          key: "1",
          label: (
            <span className="working-groups-options">
              <p>Remove</p>
            </span>
          ),
          onClick: () => {
            removeMemberHander(item.id);
          },
        },
        item.isActive && item?.isPermitted
          ? {
              key: "2",
              label: (
                <span className="working-groups-options">
                  <p>{item.isAdmin ? "Remove Admin" : "Make Admin"}</p>
                </span>
              ),
              onClick: () => {
                changeAdminRoleHander(item.id, item.isAdmin);
              },
            }
          : item.isAdmin && {
              key: "2",
              label: (
                <span className="working-groups-options">
                  <p>{"Remove Admin"}</p>
                </span>
              ),
              onClick: () => {
                changeAdminRoleHander(item.id, item.isAdmin);
              },
            },
      ]}
    />
  );

  const companyProfileBox = (data, index, isExist) => (
    <div
      className="company-detail"
      onClick={() => {
        setSelectedTeamHandler(index);
      }}
    >
      <ProfileImage
        image={data.logo}
        username={data.name}
        color={data.profilebg}
        size={12}
        onClick={() => {
          setDetailDrawer(data.id, true);
        }}
      />
      <div className="detail">
        <h4
          onClick={() => {
            setDetailDrawer(data.id, true);
          }}
          className="c-p"
        >
          {data.name}
        </h4>
        <p>{data.role}</p>
      </div>
      <span className="dropdown-caret">
        <FontAwesomeIcon icon={isExist ? faCaretUp : faCaretDown} />
      </span>
    </div>
  );

  const memberProfileBox = (item) => {
    const isMemberAdmin = item?.isAdmin;
    const username = item.firstName + " " + item.lastName;
    return (
      <>
        <span className="user-thumb">
          <ProfileImage
            image={item.image}
            size={11}
            color={item.profilebg}
            isBlocked={!item?.isActive}
            username={username}
            onClick={() => {
              setDetailDrawer(item.id);
            }}
          />
          {isMemberAdmin && item?.isActive && (
            <img src={Images.isAdminCrown} alt="" className="admin-crown" />
          )}
        </span>
        <div className="detail">
          <h4
            onClick={() => {
              setDetailDrawer(item.id);
            }}
            className="c-p"
          >
            {username}
          </h4>
          <p>{item.email}</p>
        </div>
      </>
    );
  };

  const adminAccessmemberBox = (item) => {
    const isMemberLoading = addMemberLoading && item.id === selectedMember;
    return (
      <span className="working-groups-ellipses">
        {item.isMember ? (
          item.roomStatus ? (
            item.removeResponse && (
              <div
                className="response-dot"
                onClick={() => handleResponseModalPreview(item)}
              />
            )
          ) : (
            canEdit && (
              <Dropdown
                getPopupContainer={(trigger) => trigger.parentElement}
                trigger={"click"}
                overlay={userMenu(item, item.id !== user_id)}
              >
                <span className="dots">. . .</span>
              </Dropdown>
            )
          )
        ) : (
          <>
            {isMemberLoading ? (
              <ClipLoader size={10} color={"#1C2B48"} />
            ) : (
              <img
                src={Images.MemberPlus}
                style={{
                  minHeight: "10px",
                  minWidth: "10px",
                }}
                onClick={() => {
                  setSelectedMember(item.id);
                  addMemberHelper(item.id);
                }}
              />
            )}
          </>
        )}
      </span>
    );
  };

  const memberList = (item, i) => {
    return (
      <li
        key={i}
        className={`member-li ${!item.isMember ? "not-member" : ""} ${
          item?.roomStatus && isAdminLogin ? "removed-member" : ""
        }`}
      >
        {memberProfileBox(item)}
        {isAdminLogin && !roomAcess ? adminAccessmemberBox(item) : <></>}
      </li>
    );
  };

  const filteredgruops = groups.filter(
    (x) => x.members.filter((y) => y.isMember).length >= 1
  );
  const groupcompanies = isAdminLogin ? groups : filteredgruops;
  return (
    <div style={{ overflowY: "unset" }} className="team-list">
      {!selectedMember && loader ? (
        <CardLoader size={18} height={350} />
      ) : (
        <>
          {groupcompanies.length !== 0 ? (
            groupcompanies.map((data, index) => {
              const isExist = selectedTeam.includes(index);
              return (
                <div className="team-detail" key={index}>
                  {companyProfileBox(data, index, isExist)}
                  {isExist && (
                    <ul className="member-detail">
                      {data.members.map((item, i) => {
                        const isMemberVisible = isAdminLogin || item.isMember;
                        return isMemberVisible && memberList(item, i);
                      })}
                    </ul>
                  )}
                </div>
              );
            })
          ) : (
            <EmptyBox height={250} imgwidth={80} size={15} />
          )}
        </>
      )}
      <RemoveMemberRoomModal
        open={removeMemberModalPreview}
        handleClose={() => setRemoveMemberModalPreview(false)}
        onClick={removeMemberHelper}
        id={selectedUserID}
        loader={removeMemberLoader}
      />
      <LeaveRoomModal
        open={removeAdminModalPreview}
        handleClose={() => setRemoveAdminModalPreview(false)}
        title={`${isAlreadyAdmin ? "Remove" : "Make"} Admin`}
        description={`Are you sure you want to remove selected participant from admin?`}
        btnText={`${isAlreadyAdmin ? "Remove" : "Make"} Admin`}
        tooltip="Selected person will be assigned as an admin."
        isAdmin={isLastAdmin}
        onSubmit={changeAdminHelper}
        isAlreadyAdmin={isAlreadyAdmin}
        btnLoader={isChangeAdminLoading}
      />
      <RespondModal
        preview={responseModalPreview}
        handleClose={() => handleResponseModalPreview("")}
        response={selectedResponse}
        handleSuccess={onAddMember}
      />
    </div>
  );
};

export default RoomWorkingGroups;
