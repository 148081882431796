import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "antd";
import React from "react";
import { Images } from "../../themes";
import ButtonComponent from "../ButtonComponent";
import "./styles.scss";

function TransactionTypeWarningModal({
  modalPreview,
  handleClose,
  handleOkay,
  text,
  subtext,
}) {
  return (
    <Modal
      destroyOnClose
      visible={modalPreview}
      centered
      footer={null}
      width={490}
      className="skip-modal"
      focusTriggerAfterClose={false}
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <Images.TypeWarning />
          <h3>{text}</h3>
          <p>{subtext}</p>
          <ButtonComponent
            text="Okay"
            className="skip-btn"
            onClick={handleOkay}
          />
        </div>
      </div>
    </Modal>
  );
}

export default TransactionTypeWarningModal;
