import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Images from "../../../../../themes/images";
import {
  DATA_PER_PAGE_LIMIT,
  PERMISSIONS_ACCESS,
  TRANSACTION_DETAIL_ROUTE,
  TRANSACTION_TABS_ROUTE,
  TRANSACTION_TABS_SLUGS,
} from "../../../../../constants";
import { useCustomDispatch } from "../../../../../helpers/useCustomDispatch";
import { getBreakoutRoomsRequest } from "../../../../../redux/slicers/transactiondetail";
import {
  getActionPermission,
  replaceValInString,
} from "../../../../../services/utils";
import BreakoutRoomModal from "../breakoutRoomModal";

const TransactionTopNav = ({
  transactionId,
  transactionSlug,
  roomSlug,
  currentTab,
  isTransactionAdmin,
  roomAccess,
}) => {
  // STATES
  const [selectedDropdown, setselectedDropdown] = useState(false);
  const [roomLimit, setRoomLimit] = useState(3);
  const [createBreakoutModalPreview, setCreateBreakoutModalPreview] =
    useState(false);

  // REDUX DATA
  const { breakoutrooms } = useSelector((state) => state.transactiondetail);

  // CUSTOM DISPATCH

  const [getBreakoutRoomsList] = useCustomDispatch(getBreakoutRoomsRequest);

  // REF
  const dropdownRef = useRef();

  // CONST VAL
  const navigate = useNavigate();
  const roomLimitCondition = roomLimit > 3;
  const OthersCondition = breakoutrooms.length > 3;
  const CurrentRoom = breakoutrooms.find((x) => x.slug === roomSlug);
  const { EDIT_BREAKOUT_ROOM } = PERMISSIONS_ACCESS;
  const isCreateAllowed = getActionPermission(EDIT_BREAKOUT_ROOM);
  const TRANSACTION_TABS = [
    {
      thumb: Images.TransactionDealRoom,
      title: CurrentRoom?.name,
      isDropdown: true,
      dropdownKey: "rooms",
      tempgroups: breakoutrooms,
      dropdownItems: [
        ...(breakoutrooms.length > 3
          ? [
              {
                title: roomLimitCondition ? "See Less -" : "Other Rooms +",
                onClick: () => {
                  !roomLimitCondition ? setRoomLimit(100) : setRoomLimit(3);
                },
                isTransparent: true,
              },
            ]
          : []),
        {
          title: "Breakout Room +",
          onClick: () => {
            if (isCreateAllowed) {
              setCreateBreakoutModalPreview(true);
              setselectedDropdown(false);
            }
          },
          isRounded: OthersCondition && true,

          isTransparent: !OthersCondition && true,
          isNotPermitted: !isCreateAllowed,
        },
      ],
      visible: true,
    },
    {
      thumb: Images.TransactionChecklist,
      title: "Checklist",
      isDisabled: true,
      slug: TRANSACTION_TABS_SLUGS.CHECKLIST,
      pages: [
        TRANSACTION_TABS_SLUGS.CHECKLIST,
        TRANSACTION_TABS_SLUGS.DELETED_CHECKLIST,
      ],
      visible: getActionPermission(PERMISSIONS_ACCESS.VIEW_CHECKLIST),
    },
    {
      thumb: Images.TransactionDocuments,
      title: "Documents",
      slug: TRANSACTION_TABS_SLUGS.DOCUMENTS,
      pages: [
        TRANSACTION_TABS_SLUGS.DOCUMENTS,
        TRANSACTION_TABS_SLUGS.DELETED_DOCUMENTS,
      ],
      visible: getActionPermission(PERMISSIONS_ACCESS.VIEW_DOCS),
    },
    {
      thumb: Images.TransactionMilestone,
      title: "Milestones",
      isDisabled: true,
      slug: TRANSACTION_TABS_SLUGS.MILESTONES,
      pages: [TRANSACTION_TABS_SLUGS.MILESTONES],
      visible: getActionPermission(PERMISSIONS_ACCESS.VIEW_MILESTONE),
    },
    {
      thumb: Images.TransactionMessage,
      title: "Messages",
      isDisabled: true,
      slug: TRANSACTION_TABS_SLUGS.MESSAGES,
      pages: [TRANSACTION_TABS_SLUGS.MESSAGES],
      visible: true,
    },
    {
      thumb: Images.TransactionDataRoom,
      title: "Data Room",
      isDisabled: true,
      slug: TRANSACTION_TABS_SLUGS.DATAROOMS,
      pages: [TRANSACTION_TABS_SLUGS.DATAROOMS],
      visible:
        getActionPermission(PERMISSIONS_ACCESS.VIEW_DATAROOM) ||
        getActionPermission(PERMISSIONS_ACCESS.VIEW_LINK),
    },
    {
      thumb: Images.TransactionSetting,
      title: "Transaction Admin",
      isDisabled: true,
      slug: isTransactionAdmin
        ? TRANSACTION_TABS_SLUGS.ADMIN
        : TRANSACTION_TABS_SLUGS.SETTING,
      pages: [TRANSACTION_TABS_SLUGS.ADMIN, TRANSACTION_TABS_SLUGS.SETTING],
      visible: true,
    },
  ];

  //   HOOKS
  useEffect(() => {
    if (transactionId) {
      const queryParams = {
        transactionId: transactionId,
        limit: DATA_PER_PAGE_LIMIT,
        offset: 0,
      };
      getBreakoutRoomsList({ queryParams });
    }
  }, [transactionId, roomSlug]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setselectedDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  // HANDLERS
  const handleSetRoom = (route, room, tab) => {
    setselectedDropdown(false);
    const vals = { ":id": transactionSlug, ":room": room };
    if (tab) vals[":tab"] = tab;
    navigate(replaceValInString(route, vals));
  };
  const listClickHandler = (isDisabled, isDropdown, slug, isRemoved) => {
    const handler = () => {
      isDropdown
        ? setselectedDropdown(!selectedDropdown)
        : handleSetRoom(TRANSACTION_TABS_ROUTE, roomSlug, slug);
    };
    if (isDisabled) {
      !isRemoved && handler();
      return;
    }
    handler();
  };
  return (
    <>
      <div className="top-bar">
        <ul>
          {TRANSACTION_TABS.map((item, index) => {
            const isDisabled = item.isDisabled ? roomAccess : null;
            const isActive = item.pages?.includes(currentTab);
            return (
              item.visible && (
                <li
                  className={`trans trans-top-bar ${
                    isActive ? "active" : isDisabled ? "disabled" : ""
                  }`}
                  ref={item.dropdownKey === "rooms" ? dropdownRef : null}
                  key={index}
                >
                  <span
                    className="lst-btn"
                    onClick={() => {
                      listClickHandler(
                        item.isDisabled,
                        item.isDropdown,
                        item.slug,
                        isDisabled
                      );
                    }}
                  >
                    <span className="thumb">
                      <item.thumb />
                    </span>
                    <span className="sub-title">{item.title}</span>
                  </span>
                  {item.isDropdown && (
                    <>
                      <FontAwesomeIcon
                        className="dropdown-caret"
                        icon={selectedDropdown ? faCaretUp : faCaretDown}
                      />
                      {selectedDropdown && (
                        <ul className="dropdown-content">
                          {item.dropdownKey === "rooms" && (
                            <div className="dropdown-container">
                              {item.tempgroups
                                .slice(0, roomLimit)
                                .map((item, index) => (
                                  <li
                                    key={index}
                                    className={`${
                                      roomSlug === item.slug ? "active" : ""
                                    } ${item.roomStatus ? "removed" : ""}`}
                                    onClick={() => {
                                      handleSetRoom(
                                        TRANSACTION_DETAIL_ROUTE,
                                        item.slug
                                      );
                                    }}
                                  >
                                    {item.name}
                                  </li>
                                ))}
                            </div>
                          )}
                          {item.dropdownItems.map(
                            (item, index) =>
                              !item.isNotPermitted && (
                                <li
                                  key={index}
                                  style={{
                                    textAlign: "center",
                                  }}
                                  onClick={item?.onClick}
                                  className={
                                    item.isTransparent
                                      ? "transparent"
                                      : item.isRounded
                                      ? "rounded"
                                      : ""
                                  }
                                >
                                  {item.title}
                                </li>
                              )
                          )}
                        </ul>
                      )}
                    </>
                  )}
                </li>
              )
            );
          })}
        </ul>
      </div>

      <BreakoutRoomModal
        preview={createBreakoutModalPreview}
        handleClose={() => {
          setCreateBreakoutModalPreview(false);
        }}
        title="Create Breakout Room"
      />
    </>
  );
};

export default TransactionTopNav;
