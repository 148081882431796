import React from "react";
import { Images } from "../../themes";
import { Input } from "antd";
import PhoneInput from "react-phone-input-2";
import "./styles.scss";

const AccountInput = ({
  title = "Title",
  isClickable,
  isCustom,
  onClick,
  isCustomClickable,
  isRequired,
  autoFocus = false,
  ...rest
}) => {
  const inputSec = () => (
    <>
      <Input
        className="ac-ip-input"
        style={{ cursor: isClickable ? "pointer" : "auto" }}
        {...rest}
        autoFocus={autoFocus}
      />
      {isClickable && (
        <span className="edit-btn">
          <Images.Edit />
        </span>
      )}
    </>
  );
  const phoneInputSec = () => (
    <>
      <PhoneInput
        inputClass={`${isCustomClickable && "c-p"}`}
        country={"us"}
        isValid={(value, country) => {
          if (value.match(/12345/)) {
            return "Invalid value: " + value + ", " + country.name;
          } else if (value.match(/1234/)) {
            return false;
          } else {
            return true;
          }
        }}
        {...rest}
        disableDropdown={isCustomClickable}
      />
      {isCustomClickable && (
        <span className="edit-btn">
          <Images.Edit />
        </span>
      )}
    </>
  );
  return (
    <div className="ac-box-wrapper">
      <div className="ac-ip-title">
        {title}
        {isRequired && <p>*</p>}
      </div>
      {isClickable ? (
        <div
          className="ac-ip-wrapper"
          style={{ cursor: "pointer" }}
          onClick={onClick}>
          {inputSec()}
        </div>
      ) : (
        <div className="ac-ip-wrapper" onClick={isCustomClickable && onClick}>
          {isCustom ? phoneInputSec() : inputSec()}
        </div>
      )}
    </div>
  );
};

export default AccountInput;
