// import { useState } from "react";
import { Menu } from "antd";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ProfileImage } from "../../../../components";
import {
  CREATE_TRANSACTION_ROUTE,
  ENTERPRISE_SIDEBAR_LINKS,
  GROUP_MESSAGES_ROUTE,
  GROUP_MESSAGE_ROUTE,
  MESSAGES_ROUTE,
  MESSAGE_ROUTE,
  SIDEBAR_LINKS,
  TRANSACTION_DETAIL_ROUTE,
  TRANSACTION_ROUTE,
  TRANSACTION_TABS_ROUTE,
} from "../../../../constants";
import { useCustomDispatch } from "../../../../helpers/useCustomDispatch";
import {
  getChatListRequest,
  getSidebarChatListRequest,
  getTransactionChatsListRequest,
  setIsSelectFirst,
} from "../../../../redux/slicers/chat";
import { getSideNavTransactionsRequest } from "../../../../redux/slicers/transactions";
import { replaceValInString } from "../../../../services/utils";
import { Images } from "../../../../themes";
import "./styles.scss";

function SideNav() {
  // STATES
  const [transactionToggle, setTransactionToggle] = useState(false);
  const [messageToggle, setMessageToggle] = useState(false);
  const [chatOptionToggle, setChatOptionToggle] = useState(null);
  const [chatSubOptionToggle, setChatSubOptionToggle] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);

  // CONST VALS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { chat_id: id } = useParams();

  // REDUX DATA
  const { isEnterpriseUser, companyLogo, companyName } = useSelector(
    (state) => state.user.data
  );
  const { sideNavtransaction } = useSelector((state) => state.transactions);
  const {
    chatList,
    selectedRoom,
    singleChat,
    groupChat,
    sidebarGroupChats,
    sidebarSingleChats,
    sidebarChatList,
    transChats,
  } = useSelector((state) => state.chat);
  const selectedChat = singleChat?.find((x) => x?.slug === id);
  const selectedGroupChat = groupChat?.find((x) => x?.slug === id);
  const mergedChats = [
    ...sidebarGroupChats?.slice(0, 5),
    ...sidebarSingleChats?.slice(0, 5),
  ];
  // CUSTOM DISPATCH
  const [getSideNavTransactions] = useCustomDispatch(
    getSideNavTransactionsRequest
  );
  const [getChats] = useCustomDispatch(getSidebarChatListRequest);
  const [getTransChats] = useCustomDispatch(getTransactionChatsListRequest);

  // HELPERS
  const activeClass = (path, className = "active-link") =>
    pathname.includes(path) ? className : "";

  // HOOKS
  useEffect(() => {
    getSideNavTransactions({
      queryParams: { limit: 5 },
    });
    getChats({ queryParams: { limit: 10, offset: 0 } });
    getTransChats();
  }, []);

  // HANDLERS
  const transactionToggleHandler = () => {
    setTransactionToggle(!transactionToggle);
  };
  const messageToggleHandler = () => {
    setMessageToggle(!messageToggle);
  };
  const chatOptionToggleHandler = (val) => {
    setChatSubOptionToggle(null);
    if (val !== chatOptionToggle) {
      setChatOptionToggle(val);
      return;
    }
    setChatOptionToggle(null);
  };
  const chatSubOptionToggleHandler = (val) => {
    if (val !== chatSubOptionToggle) {
      setChatSubOptionToggle(val);
      return;
    }
    setChatSubOptionToggle(null);
  };
  const closeToggles = () => {
    setTransactionToggle(false);
    setMessageToggle(false);
  };
  const navigateHandler = (route) => {
    closeToggles();
    navigate(route);
  };
  const navigateToTransaction = (transaction, room) => {
    closeToggles();
    const vals = { ":id": transaction, ":room": room };
    navigate(replaceValInString(TRANSACTION_DETAIL_ROUTE, vals));
  };
  const sumOfUnread = () => {
    const count = mergedChats?.map((x) => x.unreadCount);
    const sum = count.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
    setUnreadCount(sum);
  };

  //HOOKS
  useEffect(() => {
    if (mergedChats?.length > 0) {
      sumOfUnread();
    }
  }, [mergedChats, sidebarChatList]);

  // CUSTOM COMPONENT
  const myTransactionMenu = (item, index) => (
    <div
      key={index}
      className={`transaction-wrapper  ${activeClass(TRANSACTION_ROUTE)}`}
      onClick={() => {
        navigateHandler(TRANSACTION_ROUTE);
      }}
    >
      <div className={`flex-center sidebar-item trans`}>
        {item.icon}
        <p>{item.title}</p>
        {sideNavtransaction.length > 0 && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              transactionToggleHandler();
            }}
            className="toggle-trans-drop btn-transparent"
          >
            <Images.transactionTopArrow
              style={{
                rotate: transactionToggle ? "0deg" : "180deg",
                color: "rgba(28, 43, 72, 0.8)",
              }}
            />
          </button>
        )}
      </div>
      <div
        style={{ display: transactionToggle ? "flex" : "none" }}
        className="trans-drop-wrapper"
      >
        {sideNavtransaction?.map((a, i) => (
          <button
            onClick={(e) => {
              e.stopPropagation();
              closeToggles();
              navigateToTransaction(a.slug, a.roomSlug);
            }}
            key={i}
            className="btn-transparent trans-drop-item"
          >
            {a.name}
          </button>
        ))}
        {sideNavtransaction.length >= 5 && (
          <button
            className="btn-transparent trans-drop-item see-all"
            onClick={() => navigateHandler(TRANSACTION_ROUTE)}
          >
            <span>See All</span>
            <Images.transactionTopArrow
              style={{
                marginLeft: "100px",
                color: "rgba(28, 43, 72, 0.8)",
                rotate: "90deg",
              }}
            />
          </button>
        )}
      </div>
    </div>
  );
  const enterpriseMenu = (item, index) => (
    <div
      key={index}
      onClick={() => {
        navigateHandler(item.route);
      }}
      className={`flex-center sidebar-item trans ${activeClass(item.route)}`}
    >
      <ProfileImage username={companyName} image={companyLogo} size={10} />
      <span className="sidenav-company-wrapper">
        <p>{companyName}</p>
        {isEnterpriseUser && <p className="admin-label">Enterprise Admin</p>}
      </span>
    </div>
  );
  const messageMenu = (item, index) => (
    <div
      key={index}
      className={`message-nav-box ${activeClass(item.route)}`}
      onClick={() => {
        messageToggleHandler();
        chatOptionToggleHandler(null);
      }}
    >
      <div className={`flex-center sidebar-item trans`}>
        {item.icon}
        <div className="title-wrapp">
          <p>{item.title}</p>
          {unreadCount > 0 && <span className="unread-dot" />}
        </div>
        <button className="dropdown-btn">
          <Images.transactionTopArrow
            style={{
              rotate: messageToggle ? "0deg" : "180deg",
              color: "rgba(28, 43, 72, 0.8)",
            }}
          />
        </button>
      </div>
      <ul
        style={{ display: messageToggle ? "block" : "none" }}
        className="message-dropdown"
        onClick={(e) => e.stopPropagation()}
      >
        <li className="message-dropdown-option c-p">
          <div className="option">
            <Link
              to={MESSAGE_ROUTE}
              onClick={() => {
                messageToggleHandler();
                setChatOptionToggle(null);
              }}
            >
              Direct Messages
            </Link>
            <button
              className="dropdown-btn"
              onClick={() => chatOptionToggleHandler("direct")}
            >
              <Images.transactionTopArrow
                style={{
                  rotate: chatOptionToggle === "direct" ? "0deg" : "180deg",
                  color: "rgba(28, 43, 72, 0.8)",
                }}
                className="c-p carret"
              />
            </button>
          </div>
          {chatOptionToggle === "direct" && (
            <div
              className={`chat-list-wrapper ${activeClass(
                item.route,
                "is-active"
              )}`}
            >
              {sidebarSingleChats?.slice(0, 5)?.map((data, index) => (
                <div
                  className="chat-item"
                  onClick={() => {
                    dispatch(setIsSelectFirst(true));
                    navigate(
                      replaceValInString(MESSAGES_ROUTE, {
                        ":chat_id": data?.slug,
                      })
                    );
                    messageToggleHandler();
                    setChatOptionToggle(null);
                  }}
                  key={index}
                >
                  <ProfileImage
                    image={data?.individual?.image}
                    username={data?.name}
                    color={
                      pathname.includes(item.route)
                        ? "#fff"
                        : data?.individual?.bgColor
                        ? data?.individual?.bgColor
                        : "#6F7CED"
                    }
                    isBlocked={false}
                    size={8}
                  />
                  <p>{data?.name}</p>
                </div>
              ))}
            </div>
          )}
        </li>
        <li className="message-dropdown-option c-p">
          <div className="option">
            <Link
              to={GROUP_MESSAGE_ROUTE}
              onClick={() => {
                messageToggleHandler();
                setChatOptionToggle(null);
              }}
            >
              Group Chat
            </Link>
            <button
              className="dropdown-btn"
              onClick={() => chatOptionToggleHandler("group")}
            >
              <Images.transactionTopArrow
                style={{
                  rotate: chatOptionToggle === "group" ? "0deg" : "180deg",
                  color: "rgba(28, 43, 72, 0.8)",
                }}
                className="c-p carret"
              />
            </button>
          </div>
          {chatOptionToggle === "group" && (
            <div
              className={`chat-list-wrapper ${activeClass(
                item.route,
                "is-active"
              )}`}
            >
              {sidebarGroupChats?.slice(0, 5)?.map((data, index) => (
                <div
                  className="chat-item"
                  onClick={() => {
                    dispatch(setIsSelectFirst(true));
                    navigate(
                      replaceValInString(GROUP_MESSAGES_ROUTE, {
                        ":chat_id": data?.slug,
                      })
                    );
                    messageToggleHandler();
                    setChatOptionToggle(null);
                  }}
                  key={index}
                >
                  <ProfileImage
                    image={data?.image}
                    username={data?.name}
                    color={
                      pathname.includes(item.route) ? "#ffffff" : "#6F7CED"
                    }
                    isBlocked={false}
                    size={8}
                  />
                  <p>{data?.name}</p>
                </div>
              ))}
            </div>
          )}
        </li>
        <li className="message-dropdown-option c-p">
          <div className="option">
            <Link to={""} onClick={() => chatOptionToggleHandler("trans")}>
              Transaction Chats
            </Link>
            <button
              className="dropdown-btn"
              onClick={() => chatOptionToggleHandler("trans")}
            >
              <Images.transactionTopArrow
                style={{
                  rotate: chatOptionToggle === "trans" ? "0deg" : "180deg",
                  color: "rgba(28, 43, 72, 0.8)",
                }}
                className="c-p carret"
              />
            </button>
          </div>
          {chatOptionToggle === "trans" && (
            <div
              className={`chat-list-wrapper ${activeClass(
                item.route,
                "is-active"
              )}`}
            >
              {transChats?.slice(0, 5)?.map((data, index) => (
                <div className="chat-trans-item" key={index}>
                  <div
                    className="option"
                    onClick={() => chatSubOptionToggleHandler(data?.slug)}
                  >
                    <Link to="">{data?.name}</Link>
                    <button className="dropdown-btn">
                      <Images.transactionTopArrow
                        style={{
                          rotate:
                            chatSubOptionToggle === data?.slug
                              ? "0deg"
                              : "180deg",
                          color: "rgba(28, 43, 72, 0.8)",
                        }}
                        className="c-p carret"
                      />
                    </button>
                  </div>
                  {chatSubOptionToggle === data?.slug && (
                    <div
                      className={`chat-list-wrapper ${activeClass(
                        item.route,
                        "is-active"
                      )}`}
                    >
                      {data?.rooms?.slice(0, 5)?.map((room, ind) => (
                        <div
                          className="chat-item"
                          onClick={() => {
                            dispatch(setIsSelectFirst(true));
                            navigate(
                              replaceValInString(TRANSACTION_TABS_ROUTE, {
                                ":id": data?.slug,
                                ":room": room?.slug,
                                ":tab": "chat",
                              })
                            );
                            messageToggleHandler();
                            setChatOptionToggle(null);
                            setChatSubOptionToggle(null);
                          }}
                          key={ind}
                        >
                          <ProfileImage
                            image={null}
                            username={room?.name}
                            color={
                              pathname.includes(item.route)
                                ? "#ffffff"
                                : "#6F7CED"
                            }
                            isBlocked={false}
                            size={8}
                          />
                          <p>{room?.name}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </li>
      </ul>
    </div>
  );
  const MenuContent = () => {
    return (
      <div className="flex-center flex-column nav-item-container">
        {[isEnterpriseUser ? ENTERPRISE_SIDEBAR_LINKS : SIDEBAR_LINKS]
          .flat()
          .map((item, index) =>
            item.isUserCompany ? (
              enterpriseMenu(item, index)
            ) : item.isMyTransaction ? (
              myTransactionMenu(item, index)
            ) : item.isMessage ? (
              messageMenu(item, index)
            ) : (
              <div
                key={index}
                onClick={() => {
                  navigateHandler(item.route);
                }}
                className={`flex-center sidebar-item trans ${activeClass(
                  item.route
                )}`}
              >
                {item.icon}
                <p>{item.title}</p>
              </div>
            )
          )}
      </div>
    );
  };
  return (
    <>
      <div className="brand flex-center">
        <div
          onClick={() => navigate(CREATE_TRANSACTION_ROUTE)}
          className="create-transaction c-p flex-center"
        >
          <p>Create Transaction</p>
          <Images.Plus />
        </div>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <MenuContent />
      </Menu>
    </>
  );
}

export default SideNav;
