import React, { useEffect } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Input, Modal, Select } from "antd";
import { useCustomDispatch } from "../../helpers/useCustomDispatch";
import {
  approveTeamMemberRequest,
  getPendingMembersRequest,
} from "../../redux/slicers/company";
import ButtonComponent from "../ButtonComponent";
import { inputFieldRule, toastAlert } from "../../services/utils";
import "./styles.scss";

const ApproveMemberModal = ({
  handleClose,
  setRemoveModal,
  modalPreview,
  addresses,
  selectedData,
}) => {
  const [approveTeamMember, isLoading] = useCustomDispatch(
    approveTeamMemberRequest
  );
  const [getPendingMembers] = useCustomDispatch(getPendingMembersRequest);
  const [form] = Form.useForm();

  // HOOKS
  const closeHandler = () => {
    handleClose();
    form.resetFields();
  };
  const removeHandler = () => {
    closeHandler();
    setRemoveModal();
  };
  const handleFormFinish = (values) => {
    const payload = {
      addressId: values.branch,
      userId: selectedData.id,
    };
    approveTeamMember({
      payload,
      logic(response) {
        toastAlert(response.message);
        closeHandler();
        getPendingMembers();
      },
    });
  };

  useEffect(() => {
    if (selectedData) {
      form.setFieldsValue({
        name: selectedData.username,
        email: selectedData.email,
        phone: selectedData.phone,
        branch: addresses?.[0]?.id,
      });
    }
  }, [selectedData, modalPreview]);

  return (
    <Modal
      destroyOnClose
      visible={modalPreview}
      centered
      footer={null}
      getContainer={false}
      width={490}
      className="checklist-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>Member Approval</h3>
            <p>Fill The Details Below</p>
          </div>
          <Form form={form} className="form" onFinish={handleFormFinish}>
            <Form.Item name={"name"}>
              <Input name={"Name"} placeholder={"Name"} disabled autoFocus />
            </Form.Item>
            <Form.Item name={"email"}>
              <Input
                Email={"Email"}
                placeholder={"john.smith@domain.com"}
                disabled
              />
            </Form.Item>
            <Form.Item name={"phone"}>
              <Input
                Email={"Phone"}
                placeholder={"+0 123 456 78 90"}
                disabled
              />
            </Form.Item>
            <Form.Item
              name="branch"
              rules={inputFieldRule({
                name: "Branch",
                isWhiteSpace: false,
              })}
            >
              <Select
                className="colored-select"
                placeholder="Select branch"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {addresses?.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <div className="create-button member-approval-buttons">
              <Form.Item>
                <ButtonComponent
                  className="login-btn remove-btn"
                  onClick={removeHandler}
                  text={"Remove"}
                  style={{ marginTop: "0px" }}
                />
              </Form.Item>
              <Form.Item>
                <ButtonComponent
                  className="login-btn"
                  isLoading={isLoading}
                  disabled={isLoading}
                  text={"Approve"}
                  style={{ marginTop: "0px" }}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default ApproveMemberModal;
