import React from "react";
import { Dropdown, Menu } from "antd";
import {
  CardLoader,
  CustomInfiniteScroll,
  EmptyBox,
  ProfileImage,
} from "../../../../../../../components";
import { Images } from "../../../../../../../themes";
import "./styles.scss";
import { getLetterFromName } from "../../../../../../../services/utils";
import { useState } from "react";

function DocVisibilityBox({ data, isLoading, selected, setSelected }) {
  //STATES
  const [open, setOpen] = useState(false);

  //HANDLERS
  const setSelectedHandler = (item) => {
    if (selected === item) {
      setSelected(null);
      setOpen(false);
    } else {
      setSelected(item);
      setOpen(true);
    }
  };

  // CUSTOM COMPONENT
  const userMenu = (item) => {
    const userInfo = item?.sharedBy;
    return (
      <Menu
        items={[
          {
            key: "1",
            disabled: true,
            label: (
              <span className="room-option active">
                Upload From {item?.previousRoom} By
                <ProfileImage
                  size={5}
                  username={userInfo?.username}
                  color={userInfo?.profilebg}
                  image={userInfo?.image}
                />
                {userInfo?.username}
              </span>
            ),
          },
          {
            key: "2",
            disabled: true,
            label: (
              <span className="room-option">
                {item?.previousRoom} - Version {item?.previousVersion} (
                {item?.name}) <br />
                To
                <br />
                {item?.currentRoom} - Version {item?.currentVersion} (
                {item?.name})
              </span>
            ),
          },
        ]}
      />
    );
  };

  return (
    <div
      className="doc-visiblity-wrapper"
      onScroll={() => {
        setSelectedHandler(selected);
      }}
    >
      {isLoading ? (
        <CardLoader size={15} height={178} />
      ) : data?.length > 0 ? (
        data?.map((item, index) => {
          const isActive = selected === item;
          return (
            <div
              className={`doc-visiblity-item ${isActive ? "active" : ""}`}
              key={index}
            >
              <div className="visibility-left-wrapper">
                <ProfileImage
                  image={""}
                  username={item?.currentRoom}
                  color={item?.currentRoomBg}
                  size={12}
                  border={item?.currentRoomBg}
                />
                <Dropdown
                  placement="bottomLeft"
                  visible={open && item === selected}
                  // getPopupContainer={(trigger) => trigger.parentElement}
                  trigger={"click"}
                  overlay={userMenu(item)}
                  overlayClassName="history-dropdown"
                  onVisibleChange={() => setSelectedHandler(item)}
                >
                  <div className="visibility-info">
                    <h3>{item?.currentRoom}</h3>
                    <p className="version-history c-p">
                      V{item?.previousVersion} -{" "}
                      {getLetterFromName(item?.previousRoom)} --- V
                      {item?.currentVersion} -{" "}
                      {getLetterFromName(item?.currentRoom)}
                    </p>
                  </div>
                </Dropdown>
              </div>
              <Images.BackArrow className="visibility-cursor" />
            </div>
          );
        })
      ) : (
        <EmptyBox size={10} imgwidth={84} height={178} />
      )}
    </div>
  );
}

export default DocVisibilityBox;
