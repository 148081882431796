import { combineReducers } from "@reduxjs/toolkit";
import general from "./general";
import user from "./user";
import company from "./company";
import transactions from "./transactions";
import transactiondetail from "./transactiondetail";
import transactionchecklist from "./transactionchecklist";
import transactiondocuments from "./transactiondocuments";
import transactionmilestone from "./transactionmilestone";
import transactiondataroom from "./transactiondataroom";
import resources from "./resources";
import chat from "./chat";

export default combineReducers({
  general,
  user,
  company,
  transactions,
  transactiondetail,
  transactionmilestone,
  transactiondocuments,
  transactionchecklist,
  transactiondataroom,
  resources,
  chat,
});
