import React, { useState } from "react";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, DatePicker, Form, Row, Select } from "antd";
import { ButtonComponent, DisabledTooltip } from "../../../../../../components";
import { Images } from "../../../../../../themes";
import { CreateMilestoneModal } from "./";
import { DATE_FORMAT3 } from "../../../../../../constants";
import { getFormattedDateTime } from "../../../../../../services/utils";

const MilestoneHeader = ({
  transactionslug,
  handleSearch,
  handleApplyFilter,
  createModalHandler,
  createModalPreview,
  dataUpdateHandler,
  isCreateAllowed,
}) => {
  // STATES
  const [filterView, setfilterView] = useState(false);

  // CONST VALS
  const [form] = Form.useForm();

  // HANDLERS
  const handleSubmit = (values) => {
    if (values.endDate) {
      values["endDate"] = getFormattedDateTime(values.endDate, DATE_FORMAT3);
    }
    if (values.startDate) {
      values["startDate"] = getFormattedDateTime(
        values.startDate,
        DATE_FORMAT3
      );
    }
    handleApplyFilter(values);
    setfilterView(false);
  };

  return (
    <>
      <div className="room-detail">
        <div className="left-wrapper">
          <div className="description">
            <h4>Milestones</h4>
            <p>View All Details</p>
          </div>
          <div className="search-box">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              placeholder="Search any milestone..."
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
            />
            <Images.Setting
              className="filter-btn"
              onClick={() => {
                setfilterView(!filterView);
              }}
            />
          </div>
        </div>
        <DisabledTooltip
          innerSec={
            <div className="right-wrapper">
              <button onClick={isCreateAllowed && createModalHandler}>
                Create Milestone +
              </button>
            </div>
          }
          visible={!isCreateAllowed}
        />
        {filterView && (
          <div className="filter-sec">
            <h3>Filter</h3>
            <div
              className="close-btn"
              onClick={() => {
                setfilterView(!filterView);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <Form form={form} onFinish={handleSubmit}>
              <Row gutter={[16, 10]}>
                <Col xs={24} md={12}>
                  <Form.Item name="startDate">
                    <DatePicker
                      placeholder="Start Date"
                      className="form-select"
                      clearIcon={false}
                      format="MM-DD-YYYY"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item name="endDate">
                    <DatePicker
                      placeholder="End Date"
                      className="form-select"
                      clearIcon={false}
                      format="MM-DD-YYYY"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item name="status">
                    <Select
                      placeholder="Select Status"
                      className="form-select"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      <Select.Option value={true}>Completed</Select.Option>
                      <Select.Option value={false}>Pending</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <ButtonComponent text="Apply Filter" />
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </div>
      <CreateMilestoneModal
        transactionslug={transactionslug}
        handleClose={createModalHandler}
        handleSucess={dataUpdateHandler}
        preview={createModalPreview}
      />
    </>
  );
};

export default MilestoneHeader;
