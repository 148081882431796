export const payloadGenerator = (values, companyName) => {
  const payload = {
    email: values.email,
    firstName: values.firstName,
    lastName: values.lastName,
    phone: values.phone,
    jobTitle: values.jobTitle,
    password: values.password,
    confirmPassword: values.confirmPassword,
    company: {
      workPhone: values.workPhone,
      companyName: companyName,
      address: {
        address: values.address,
        state: values.state,
        city: values.city,
        zip: values.zip,
        country: values.country,
      },
    },
  };
  return payload;
};

export const manuplateEnterpriseInvitationData = (
  res,
  form,
  disableFunc,
  setCompany
) => {
  const companydetail = res?.company;
  const addressdetail = companydetail?.addresses[0];
  disableFunc(true);
  setCompany(companydetail?.name);
  form.setFieldsValue({
    companyName: companydetail?.name,
    email: res.email,
    address: addressdetail.address,
    state: addressdetail.state,
    city: addressdetail.city,
    zip: addressdetail.zip,
    country: addressdetail.country,
  });
};
export const manuplateTransactionInvitationData = (
  res,
  form,
  disableFunc,
  setCompany,
  setInvite
) => {
  const companydetail = res?.company;
  const InvitedByCompanydetail = res?.invitedBy?.company;
  const addressdetail = companydetail?.addresses?.[0];
  const isCompanyExist = companydetail?.userCompany?.length >= 1;
  if (isCompanyExist) {
    disableFunc(true);
  }
  setCompany(companydetail?.name);
  setInvite({
    transactionName: res.transaction.name,
    userName: res.invitedBy.firstName + " " + res.invitedBy.lastName,
    thumb: res.invitedBy.image,
    userid: res.invitedBy.id,
    profilebg: res.invitedBy.bgcolor,
    companyName: InvitedByCompanydetail.name,
  });
  form.setFieldsValue({
    companyName: companydetail?.name,
    firstName: res?.invitee?.firstName,
    lastName: res?.invitee?.lastName,
    email: res?.invitee?.email,
    address: addressdetail?.address,
    state: addressdetail?.state,
    city: addressdetail?.city,
    zip: addressdetail?.zip,
    country: addressdetail?.country,
  });
};
