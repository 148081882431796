import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CustomInfiniteScroll,
  CardLoader,
  EmptyBox,
  ProfileImage,
  NoAccessBox,
} from "../";
import { getActivityLogsRequest } from "../../redux/slicers/general";
import {
  ALERT_TYPES,
  DATA_PER_PAGE_LIMIT,
  NO_DATA_FOUND_ERROR,
} from "../../constants";
import { setUserDetailDrawer } from "../../redux/slicers/general";
import { useCustomDispatch } from "../../helpers/useCustomDispatch";
import { MilestonDetailView } from "../../modules/private/transactionDetail/partials/milestones/components";
import { toastAlert } from "../../services/utils";
import "./styles.scss";

export default function ActivityLogs({
  title = "Checklist",
  isWholePage = false,
  isMilestoneDetail,
  isWholeParent = false,
  height,
  roomSlug,
  transactionslug,
  isRoom,
  isDataUpdated = false,
  customqueryParams = {},
  parentFunc,
  roomAccess,
  checkUserStatusHandler,
  isAccess = true,
}) {
  // STATES
  const [total, setTotal] = useState(null);
  const [offset, setOffset] = useState(0);
  const [isMoreData, setMoreData] = useState(false);
  const [isMilestonePreview, setMilestonePreview] = useState(false);
  const [milestoneSlug, setMilestoneSlug] = useState(null);

  //REDUX DATA
  const { activitylogs: alllogs, milestonelogs } = useSelector(
    (state) => state.general
  );

  // CONST VALS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activitylogs = isMilestoneDetail ? milestonelogs : alllogs;
  const { folder } = customqueryParams;
  const BOX_HEIGHT = isWholePage
    ? `calc(100vh - ${height}px)`
    : isWholeParent
    ? `calc(100% - ${height}px)`
    : height;

  //CUSTOM DISPATCH
  const [getActivityLogs, isLoading] = useCustomDispatch(
    getActivityLogsRequest
  );
  //HELPERS
  const getActivityLogsHelper = () => {
    const otherkeys = {};
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset,
      ...customqueryParams,
    };
    if (isMilestoneDetail) {
      otherkeys["isMilestone"] = true;
    }

    if (isRoom) {
      queryParams["room"] = roomSlug;
    } else {
      queryParams["transaction"] = transactionslug;
    }

    getActivityLogs({
      queryParams,
      otherkeys,
      logic(res) {
        setTotal(res.total);
      },
    });
  };

  //HOOKS
  useEffect(() => {
    if (!isMilestonePreview) {
      parentFunc
        ? parentFunc(() => {
            getActivityLogsHelper();
          })
        : getActivityLogsHelper();
    }
  }, [offset, isDataUpdated, roomAccess, roomSlug, folder, isMilestonePreview]);

  // HANDLERS
  const handleNextData = () => {
    setOffset(offset + DATA_PER_PAGE_LIMIT);
    setMoreData(true);
  };
  const setUserDrawerHandler = (userid) => {
    const func = () => {
      dispatch(
        setUserDetailDrawer({
          visibility: true,
          id: userid,
        })
      );
    };
    checkUserStatusHandler ? checkUserStatusHandler(func) : func();
  };
  const navigateLogHandler = ({ isDeleted, isMilestone, route }) => {
    if (isDeleted) {
      toastAlert(NO_DATA_FOUND_ERROR, ALERT_TYPES.ERROR);
      return;
    }
    if (isMilestone) {
      if (!isMilestoneDetail) {
        setMilestonePreview(true);
        setMilestoneSlug(route);
      }
      return;
    }
    navigate(route);
  };
  const setMilestonePreviewHandler = () => {
    setMilestonePreview(!isMilestonePreview);
  };

  return (
    <div className="activity-logs">
      <div className="title-box">
        <h3>Activity</h3>
        <p>{title} activity log</p>
      </div>
      {isAccess ? (
        isLoading & !isMoreData ? (
          <CardLoader size={20} height={BOX_HEIGHT} />
        ) : activitylogs.length >= 1 ? (
          <CustomInfiniteScroll
            dataLength={activitylogs?.length}
            total={total}
            next={handleNextData}
            maxHeight={height}
            minHeight={BOX_HEIGHT}
            className="logs-list"
            isWholePage={isWholePage}
            isWholeParent={isWholeParent}
          >
            {activitylogs.map((item, index) => (
              <li key={index}>
                <ProfileImage
                  username={item.username}
                  color={item.profilebg}
                  image={item.thumb}
                  onClick={() => {
                    setUserDrawerHandler(item.userid);
                  }}
                  isBlocked={!item?.isActive}
                />
                <div className="detail">
                  <span
                    className="title c-p"
                    onClick={() => {
                      setUserDrawerHandler(item.userid);
                    }}
                  >
                    {item.username}
                  </span>
                  <span className="time">{item.date}</span>
                  <span
                    className="activity c-p"
                    onClick={() => {
                      navigateLogHandler(item);
                    }}
                  >
                    {item.detail}
                  </span>
                </div>
              </li>
            ))}
          </CustomInfiniteScroll>
        ) : (
          <EmptyBox height={BOX_HEIGHT} />
        )
      ) : (
        <NoAccessBox height={BOX_HEIGHT} />
      )}
      <MilestonDetailView
        milestoneslug={milestoneSlug}
        modalPreview={isMilestonePreview}
        handleClose={setMilestonePreviewHandler}
      />
    </div>
  );
}
