import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Form, Checkbox } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  ButtonComponent,
  DisabledTooltip,
} from "../../../../../../../components";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import {
  createDocumentRequest,
  updateDocumentRequest,
} from "../../../../../../../redux/slicers/transactiondocuments";
import { useParams } from "react-router-dom";
import { DocFields } from "./fields";
import { CheckFields } from "../../../checklist/components/createModal/fields";
import { getTransactionChecklistsRequest } from "../../../../../../../redux/slicers/transactionchecklist";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import {
  DATE_FORMAT3,
  PERMISSIONS_ACCESS,
} from "../../../../../../../constants";
import "./styles.scss";
import ConfirmDocModal from "../confirmationModal";
import { getActionPermission } from "../../../../../../../services/utils";

const CreateDocumentsModal = ({
  handleClose,
  handleSuccess = () => {},
  preview,
  isUpdate,
  data,
  checklistView,
}) => {
  //STATES
  const [isDataUpdated, setDataUpdated] = useState(false);
  const [uploadFrom, setuploadFrom] = useState(null);
  const [isChecklist, setChecklist] = useState(false);
  const [selectedParties, setselectedParties] = useState([]);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [disableCheckListFields, setDisableChecklistFields] = useState(false);
  const [responsibility, setResponsibility] = useState([]);
  const [payload, setPayload] = useState(null);
  const [confirmationModalPreview, setConfirmationModalPreview] =
    useState(false);

  // CUSTOM DISPATCH
  const [updateDocument, updateLoader] = useCustomDispatch(
    updateDocumentRequest
  );
  const [getChecklistList, checkLoader] = useCustomDispatch(
    getTransactionChecklistsRequest
  );

  //REDUX DATA
  const { transChecklist } = useSelector((state) => state.transactionchecklist);

  //CONST VALS
  const [form] = Form.useForm();
  const { id, room } = useParams();
  const { ADD_CHECKLIST, EDIT_CHECKLIST } = PERMISSIONS_ACCESS;
  const checklistAdd = getActionPermission(ADD_CHECKLIST);
  const checklistEdit = getActionPermission(EDIT_CHECKLIST);

  // HELPERS
  const requestHelper = (request, payload, pathParams) => {
    request({
      payload,
      pathParams,
      logic(res) {
        if (!res.isAsked) {
          handleSuccess();
        }
        closeHandler(true);
      },
    });
  };

  // HOOKS
  useEffect(() => {
    if (isUpdate && data) {
      form.setFieldsValue({
        name: data.name,
        notes: data.notes,
      });
    }
  }, [data, preview]);

  useEffect(() => {
    isChecklist && getChecklistList({ pathParams: id });
  }, [isChecklist]);

  //HANDLERS
  const closeHandler = (newDoc) => {
    handleClose(newDoc);
    form.resetFields();
    setDataUpdatedHandler();
    setselectedParties([]);
    setSelectedRooms([]);
    setChecklist(false);
  };
  const setDataUpdatedHandler = () => {
    setDataUpdated(!isDataUpdated);
  };

  const setFileWrapper = (file, uploadedfrom) => {
    form.setFieldsValue({ url: file });
    setuploadFrom(uploadedfrom);
  };

  const handleConfirmationModalPreview = (payload) => {
    setPayload(payload);
    setConfirmationModalPreview(!confirmationModalPreview);
  };

  const handleSubmit = (val) => {
    const payload = {
      name: val.name,
      notes: val.notes,
    };
    if (isChecklist) {
      const isExist = transChecklist.find(
        (x) => x.name === val.checklistName.value
      );

      payload["checklist"] = {
        name: val.checklistName.value ?? val.checklistName,
        type: val.type,
        targetDate: moment(val.endDate).format(DATE_FORMAT3),
        notes: val.checklistNotes,
        visibleToAll: false,
        visibileRoomIds: val.visibility,
        responsibleUsersIds: val.responsibleParties ?? [],
        isExisted: isExist ? true : false,
        ...(isExist && { id: isExist.id }),
      };
    }

    if (!isUpdate) {
      payload["transactionSlug"] = id;
      payload["roomSlug"] = room;
      payload["url"] = val.url;
      payload["uploadFrom"] = uploadFrom;
    }

    isUpdate
      ? requestHelper(updateDocument, payload, data.slug)
      : handleConfirmationModalPreview(payload);
  };

  const handleSetField = (name, value) => {
    form.setFieldValue(name, value);
    const checklistObj = transChecklist.find((obj) => obj.name === value);

    if (checklistObj) {
      setResponsibility(checklistObj.responsibleParties);
      let visibility = checklistObj.visibility.map((x) => x.id);
      let responsibility = checklistObj.responsibleParties.map((x) => x.id);
      setselectedParties(responsibility);
      setDisableChecklistFields(true);
      form.setFieldsValue({
        type: checklistObj.type,
        endDate: checklistObj.endDate ?? "",
        visibility: visibility,
        responsibleParties: responsibility,
        checklistNotes: checklistObj.notes ?? "",
      });
    }
  };

  const onselectOptionHandler = (value) => {
    const checklistObj = transChecklist.find(
      (obj) => obj.id === parseInt(value.key)
    );
    setResponsibility(checklistObj.responsibleParties);
    let visibility = checklistObj.visibility.map((x) => x.id);
    let responsibility = checklistObj.responsibleParties.map((x) => x.id);
    setselectedParties(responsibility);
    setSelectedRooms(visibility);

    setDisableChecklistFields(true);
    form.setFieldsValue({
      type: checklistObj.type,
      endDate: checklistObj.endDate ?? "",
      visibility: visibility,
      responsibleParties: responsibility,
      checklistNotes: checklistObj.notes ?? "",
    });
  };

  const resetChecklistFields = (text) => {
    if (transChecklist.find((obj) => obj.name === text)) return;
    if (disableCheckListFields) {
      setDisableChecklistFields(false);
      setselectedParties([]);
      setResponsibility([]);
      form.resetFields([
        "type",
        "endDate",
        "visibility",
        "responsibleParties",
        "checklistNotes",
      ]);
    }
  };

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={522}
      className="create-transaction-document-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={() => closeHandler(false)}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>{isUpdate ? "Update" : "Create"} Documents</h3>
            <p>{isUpdate ? "Edit" : "Add"} Details Below</p>
          </div>
          <Form form={form} className="create-doc-form" onFinish={handleSubmit}>
            <Row gutter={[20, 0]}>
              <DocFields
                setFileWrapper={setFileWrapper}
                isDataUpdated={isDataUpdated}
                isUpdate={isUpdate}
                form={form}
              />

              {!checklistView && !isUpdate && (
                <Col span={24} className="checklist-check-col">
                  <DisabledTooltip
                    placement={"bottomLeft"}
                    innerSec={
                      <Checkbox
                        checked={isChecklist}
                        onChange={() => setChecklist(!isChecklist)}
                        disabled={!checklistAdd || !checklistEdit}
                      >
                        Connect to Checklist Item
                      </Checkbox>
                    }
                    visible={!checklistAdd || !checklistEdit}
                  />
                  {isChecklist && (
                    <CheckFields
                      selectedParties={selectedParties}
                      setselectedParties={setselectedParties}
                      transactionid={id}
                      isDoc
                      handleSetField={handleSetField}
                      onChecklistSelect={onselectOptionHandler}
                      removeSetField={resetChecklistFields}
                      responsibility={responsibility}
                      data={transChecklist}
                      disableFields={disableCheckListFields}
                      form={form}
                      selectedRooms={selectedRooms}
                      setSelectedRooms={setSelectedRooms}
                    />
                  )}
                </Col>
              )}
            </Row>
            <div className="modal-footer">
              <ButtonComponent
                text={isUpdate ? "Update Document" : "Add Document +"}
                isLoading={updateLoader}
              />
            </div>
          </Form>
        </div>
      </div>
      <ConfirmDocModal
        preview={confirmationModalPreview}
        data={payload}
        handleClose={() => handleConfirmationModalPreview({})}
        handleSuccess={(item) => {
          handleSuccess(item);
          closeHandler();
        }}
        isAsked={closeHandler}
      />
    </Modal>
  );
};

export default CreateDocumentsModal;
