import React, { useState } from "react";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { ClipLoader } from "react-spinners";
import { Dropdown, Menu } from "antd";
import { Images } from "../../../../../../../themes";
import {
  setUserDetailDrawer,
  setCompanyDetailDrawer,
} from "../../../../../../../redux/slicers/general";
import {
  changeTransactionAdminStatusRequest,
  checkLastTransactionAdminRequest,
} from "../../../../../../../redux/slicers/transactiondetail";
import {
  excludeValueFromArray,
  isArrayIncludesValue,
} from "../../../../../../../services/utils";
import {
  CardLoader,
  EmptyBox,
  LeaveRoomModal,
  ProfileImage,
} from "../../../../../../../components";

const TransactionWorkingGroups = ({
  groups,
  loader,
  isAdminLogin,
  onAddMember,
  canEdit,
}) => {
  //STATES
  const [selectedTeam, setSelectedTeam] = useState([0]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isAlreadyAdmin, setIsAlreadyAdmin] = useState(false);
  const [selectedUserID, setSelectedUserID] = useState(undefined);
  const [isLastAdmin, setIsLastAdmin] = useState(false);
  const [removeAdminModalPreview, setRemoveAdminModalPreview] = useState(false);

  //CONST VAL
  const dispatch = useDispatch();
  const { id } = useParams();

  // REDUX DATA
  const { user_id } = useSelector((state) => state.user.data);

  //CUSTOM DISPATCH
  const [checkAdmin, isCheckLoading] = useCustomDispatch(
    checkLastTransactionAdminRequest
  );
  const [changeTransactionAdminStatus, adminLoader] = useCustomDispatch(
    changeTransactionAdminStatusRequest
  );

  //HELPERS
  const checkLastAdminHelper = () => {
    checkAdmin({
      pathParams: id,
      logic(res) {
        setIsLastAdmin(res.data);
        setRemoveAdminModalPreview(true);
      },
    });
  };

  //HANDLERS
  const setSelectedTeamHandler = (index) => {
    let tempData = [...selectedTeam];
    const isExist = isArrayIncludesValue(tempData, index);
    if (isExist) {
      let updateData = excludeValueFromArray(tempData, index);
      setSelectedTeam([...updateData]);
    } else {
      setSelectedTeam([...tempData, index]);
    }
  };

  const checkLastAdminHandler = (userid, isAdmin, companyId) => {
    setSelectedUserID(userid);
    setIsAlreadyAdmin(isAdmin);
    setSelectedCompany(companyId);
    if (isAdmin) {
      checkLastAdminHelper();
      return;
    }
    setIsLastAdmin(false);
    setRemoveAdminModalPreview(true);
  };

  const changeAdminStatusHandler = (val) => {
    const isLoggedInUser = user_id === selectedUserID;
    const payload = {
      isRemoveMyself: isLoggedInUser,
      userId: val ? val : selectedUserID,
      isAdmin: val ? true : !isAlreadyAdmin,
      isLastAdmin,
    };
    changeTransactionAdminStatus({
      pathParams: id,
      payload,
      logic() {
        setRemoveAdminModalPreview(false);
        onAddMember();
      },
    });
  };

  const setDetailDrawer = (id, isCompany) => {
    const request = isCompany ? setCompanyDetailDrawer : setUserDetailDrawer;
    dispatch(
      request({
        visibility: true,
        id: id,
      })
    );
  };

  // CUSTOM COMPONENT
  const userMenu = ({ id, isTransactionAdmin, companyId }) => (
    <Menu
      items={[
        {
          key: "2",
          label: (
            <span className="working-groups-options">
              <p>{`${isTransactionAdmin ? "Remove" : "Make"} Admin`}</p>
            </span>
          ),
          onClick: () => {
            checkLastAdminHandler(id, isTransactionAdmin, companyId);
          },
        },
      ]}
    />
  );

  const companyProfileBox = (data, index, isExist) => (
    <div
      className={`company-detail ${data.isInvited ? "not-exist" : ""}`}
      onClick={() => {
        setSelectedTeamHandler(index);
      }}
    >
      <ProfileImage
        image={data.logo}
        username={data.name}
        color={data.profilebg}
        size={12}
        onClick={() => {
          !data.isInvited && setDetailDrawer(data.id, true);
        }}
      />
      <div className="detail">
        <h4
          onClick={() => {
            !data.isInvited && setDetailDrawer(data.id, true);
          }}
          className="c-p"
        >
          {data.name}
        </h4>
        <p>{data.role}</p>
      </div>
      <span className="dropdown-caret">
        <FontAwesomeIcon icon={isExist ? faCaretUp : faCaretDown} />
      </span>
    </div>
  );

  const memberProfileBox = (item) => {
    const isMemberAdmin = item?.isTransactionAdmin;
    const username = item.firstName + " " + item.lastName;
    return (
      <>
        <span className="user-thumb">
          <ProfileImage
            image={item.image}
            size={11}
            color={item.profilebg}
            isBlocked={!item?.isActive}
            username={username}
            onClick={() => {
              !item.isInvited && setDetailDrawer(item.id);
            }}
          />
          {isMemberAdmin && item.isActive ? (
            <img src={Images.isAdminCrown} alt="" className="admin-crown" />
          ) : (
            ""
          )}
        </span>
        <div className="detail">
          <h4
            onClick={() => {
              !item.isInvited && setDetailDrawer(item.id);
            }}
            className={!item.isInvited ? "c-p" : ""}
          >
            {username}
          </h4>
          <p>{item.email}</p>
        </div>
      </>
    );
  };

  const adminAccessmemberBox = (item) => {
    const isLoading = isCheckLoading && item.id === selectedUserID;
    return (
      <span className="working-groups-ellipses">
        {isLoading ? (
          <ClipLoader size={10} color={"#1C2B48"} />
        ) : (
          canEdit && (
            <Dropdown
              getPopupContainer={(trigger) => trigger.parentElement}
              trigger={"click"}
              overlay={userMenu(item)}
            >
              <span className="dots">. . .</span>
            </Dropdown>
          )
        )}
      </span>
    );
  };

  const memberList = (item, i, isUserCompany) => {
    const isAdminAccess =
      isAdminLogin &&
      isUserCompany &&
      !item?.isInvited &&
      (item.isActive ? true : item?.isTransactionAdmin);

    return (
      <li key={i} className={`member-li ${item.isInvited ? "not-member" : ""}`}>
        {memberProfileBox(item)}
        {isAdminAccess ? adminAccessmemberBox(item) : ""}
      </li>
    );
  };

  return (
    <div style={{ overflowY: "unset" }} className="team-list">
      {loader ? (
        <CardLoader size={18} height={350} />
      ) : (
        <>
          {groups.length !== 0 ? (
            groups.map((data, index) => {
              const isExist = selectedTeam.includes(index);
              return (
                <div className="team-detail" key={index}>
                  {companyProfileBox(data, index, isExist)}
                  {isExist && (
                    <ul className="member-detail">
                      {data.members.map((item, i) => {
                        return memberList(item, i, data.isUserCompany);
                      })}
                    </ul>
                  )}
                </div>
              );
            })
          ) : (
            <EmptyBox height={250} imgwidth={80} size={15} />
          )}
        </>
      )}
      <LeaveRoomModal
        isTransaction
        open={removeAdminModalPreview}
        handleClose={() => setRemoveAdminModalPreview(false)}
        title={`${isAlreadyAdmin ? "Remove" : "Make"} Admin`}
        description={`Are you sure you want to remove selected participant from admin?`}
        btnText={`${isAlreadyAdmin ? "Remove" : "Make"} Admin`}
        tooltip="Selected person will be assigned as an admin."
        isAdmin={isLastAdmin}
        onSubmit={changeAdminStatusHandler}
        isAlreadyAdmin={isAlreadyAdmin}
        btnLoader={adminLoader}
        companyId={selectedCompany}
      />
    </div>
  );
};

export default TransactionWorkingGroups;
