import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "antd";
import React from "react";
import { ButtonComponent } from "../";
import { useCustomDispatch } from "../../helpers/useCustomDispatch";
import { deleteAddressRequest } from "../../redux/slicers/user";
import { toastAlert } from "../../services/utils";
import "./styles.scss";

const DeleteAddress = ({ handleClose, data, preview }) => {
  const [deleteAddress, isLoading] = useCustomDispatch(deleteAddressRequest);
  const handleSubmit = () => {
    deleteAddress({
      pathParams: data.id,
      logic(res) {
        toastAlert(res.message);
        handleClose();
      },
    });
  };
  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={500}
      className="delete-address"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>Remove Branch</h3>
            <p>Are You Sure To Remove Branch</p>
          </div>
          <div className="address-detail">
            <h4>{data?.name}</h4>
            <p>
              {`${data?.address}${
                data?.address2 ? "," + data?.address2 : ""
              }  ${data?.city}, ${data?.state}, ${data?.zip}, ${data?.country}`}
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <ButtonComponent
            text="Remove"
            isLoading={isLoading}
            className="delete-btn"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAddress;
