import { CarTwoTone } from "@ant-design/icons";
import React, { useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardLoader, ProfileImage } from "../../../../../../components";
import { useCustomDispatch } from "../../../../../../helpers/useCustomDispatch";
import {
  removeReactMsgRequest,
  sendReactMsgRequest,
} from "../../../../../../redux/slicers/chat";
import {
  convertUrlsToAnchors,
  getEmojiCode,
  getFormattedDateOnlyByDay,
  isContainLinks,
} from "../../../../../../services/utils";
import { Images } from "../../../../../../themes";
import { Emojis } from "../../../components";
import DocumentAttachmentWrapper from "./documentAttachmentWrapper";
import ImageAttachment from "./imageAttachment";
import { setUserDetailDrawer } from "../../../../../../redux/slicers/general";
import Reactions from "./reactions";
import LinkPreview from "./linkPreview";
import MessageBox from "./messageBox";

function ChatWrapper({
  groupedMessages,
  chat,
  handleNext,
  loader,
  isMoreData,
  wrapperRef,
  scrollRef,
  handleThread,
  isTransaction,
}) {
  // CONST VALS
  const dispatch = useDispatch();
  //STATES
  const [emojiOpen, setEmojiOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(false);
  const [reactOpen, setReactOpen] = useState(false);

  //REDUX DATA
  const { profilePic, chat_id, user_id, name, profilebg } = useSelector(
    (state) => state.user.data
  );
  const { messages, total } = useSelector((state) => state.chat);
  //CUSTOM DISPATCH
  const [addReact, reactLoader] = useCustomDispatch(sendReactMsgRequest);
  const [removeReact, removeReactLoader] = useCustomDispatch(
    removeReactMsgRequest
  );

  //HANDLERS
  const handleScroll = (e) => {
    const offset = e?.target?.offsetHeight;
    const totalScroll = Math.abs(e?.target?.scrollTop);
    const scrollHeight = e?.target?.scrollHeight;

    const difference = Math.abs(offset + totalScroll - scrollHeight);
    if (difference <= 1) {
      if (messages.length !== total && !loader) {
        handleNext();
      }
    }
  };

  const handleAddReact = (emoji) => {
    const payload = {
      emoji,
      message_id: selectedMessage?.id,
      room_id: selectedMessage?.roomId,
      receiverID: selectedMessage?.receiverId,
      senderID: chat_id,
    };

    addReact({
      payload: payload,
      logic() {
        setEmojiOpen(false);
      },
    });
  };

  const handleRemoveReact = (id) => {
    const payload = {
      reactor_id: chat_id,
      receiverID: selectedMessage?.receiverId,
      react_id: id,
    };

    removeReact({
      payload: payload,
      logic() {
        setEmojiOpen(false);
      },
    });
  };

  const onEmojiClick = (e) => {
    const emoji = getEmojiCode(e);
    handleAddReact(emoji);
  };

  const userDetailHandler = (userid) => {
    dispatch(
      setUserDetailDrawer({
        visibility: true,
        id: userid,
      })
    );
  };

  return (
    <div className="chat-wrapper" onScroll={handleScroll} ref={wrapperRef}>
      <div className="chat-content">
        {loader && isMoreData && <CardLoader size={10} height={10} />}
        {loader && !isMoreData ? (
          <CardLoader height={"100%"} />
        ) : (
          groupedMessages.map((item, index) => (
            <React.Fragment key={index}>
              <span className="chat-date">
                {getFormattedDateOnlyByDay(item.date)}
              </span>
              {item?.messages?.map((data, ind) => {
                const hasLink =
                  isContainLinks(data?.content)?.length > 0 ? true : false;

                return data?.highlight ? (
                  !isTransaction && (
                    <span className="highlight" key={ind}>
                      {data?.highlight}
                    </span>
                  )
                ) : (
                  <MessageBox
                    chat={chat}
                    data={data}
                    emojiOpen={emojiOpen}
                    handleAddReact={handleAddReact}
                    handleThread={handleThread}
                    onEmojiClick={onEmojiClick}
                    reactOpen={reactOpen}
                    selectedMessage={selectedMessage}
                    setEmojiOpen={setEmojiOpen}
                    setReactOpen={setReactOpen}
                    setSelectedMessage={setSelectedMessage}
                    handleRemoveReact={handleRemoveReact}
                    removeReactLoader={removeReactLoader}
                    key={ind}
                  />
                );
              })}
            </React.Fragment>
          ))
        )}
        <div className="scroll-box" ref={scrollRef} />
      </div>
    </div>
  );
}

export default ChatWrapper;
