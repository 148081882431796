import React from "react";
import { Tabs } from "../../../../../components";
import { useLocation, useNavigate } from "react-router-dom";
import TransactionAdminSetting from "./setting";
import TransactionAdminList from "./admin";
import {
  TRANSACTION_ADMIN_OPTIONS,
  TRANSACTION_TABS_SLUGS,
} from "../../../../../constants";
import "./styles.scss";

export default function TransactionAdmin({ isSetting, isAdmin, data }) {
  //CONST VALS
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { SETTING, ADMIN } = TRANSACTION_TABS_SLUGS;
  const selectedRoute = isSetting ? SETTING : ADMIN;

  // HANDLERS
  const navigateRoute = (route) => {
    const pathsplit = pathname.split("/");
    const currentTab = pathsplit[pathsplit.length - 1];
    navigate(pathname.replace(currentTab, route));
  };

  return (
    <section className="trannsaction-admin-wrapper">
      <div className="title-wrapper">
        <div>
          <h3>Transaction {isSetting ? "Settings" : "Admins"}</h3>
          <p>Adjust Transaction {isSetting ? "Settings" : ""}</p>
        </div>
        {isAdmin && (
          <Tabs
            buttonsList={TRANSACTION_ADMIN_OPTIONS}
            setState={navigateRoute}
            state={selectedRoute}
          />
        )}
      </div>
      {isSetting ? (
        <TransactionAdminSetting data={data} />
      ) : (
        <TransactionAdminList />
      )}
    </section>
  );
}
