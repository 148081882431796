// @flow
import { createSlice, current } from "@reduxjs/toolkit";
import { CUSTOM_ALL } from "../../constants";
import {
  getChatAccessToken,
  getRepliersInfo,
  groupMessagesByDate,
  groupReactionsByEmoji,
} from "../../services/utils";

const initialState = {
  usersList: [],
  chatList: [],
  groupChats: [],
  singleChats: [],
  directUsersList: [],
  selectedRoom: {},
  groupedMessages: [],
  rooms: [],
  messages: [],
  total: 0,
  offset: 0,
  roomInfoLoading: false,
  moreMessagesLoading: false,
  typing: false,
  typer: "",
  threadTyping: false,
  threadTyper: "",
  chatDetails: {},
  threadId: null,
  threads: {},
  participants: [],
  mentions: [],
  memberOffset: 0,
  chatListOffset: 0,
  sidebarChatList: [],
  sidebarGroupChats: [],
  sidebarSingleChats: [],
  notificationSettings: [],
  messageRoute: null,
  isSelect: null,
  possibleAdmins: [],
  transChats: [],
};

const ChatReducer = createSlice({
  name: "chat",
  initialState,
  reducers: {
    // COMPANIES LIST
    getChatUsersRequest() {},
    getChatUsersSuccess(state, action) {
      let temp = [...state.usersList];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.usersList = action.payload.data;
        return;
      }
      state.usersList = temp;
    },

    //GET MODAL USERS
    getDirectChatUsersRequest() {},
    getDirectChatUsersSuccess(state, action) {
      let temp = [...state.directUsersList];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.directUsersList = action.payload.data;
        return;
      }
      state.directUsersList = temp;
    },

    //CHAT LIST
    getChatListRequest() {},
    getChatListSuccess(state, action) {
      let temp = [...state.chatList];
      temp.push(...action.payload.data);

      if (!action.payload.isPaginated) {
        let newTemp = [...action.payload.data];
        let newSelected = newTemp?.find(
          (x) => x?.id === state.selectedRoom?.id
        );
        if (newSelected) {
          let index = newTemp?.indexOf(newSelected);
          newSelected["unreadCount"] = 0;
          newTemp[index] = newSelected;
        }

        state.singleChats = newTemp.filter((x) => !x.isGroup);
        state.groupChats = newTemp.filter((x) => x.isGroup);
        state.chatList = newTemp;
        return;
      }
      state.singleChats = temp.filter((x) => !x.isGroup);
      state.groupChats = temp.filter((x) => x.isGroup);
      state.chatList = temp;
    },

    //CHAT LIST
    getSidebarChatListRequest() {},
    getSidebarChatListSuccess(state, action) {
      let temp = [...state.chatList];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.sidebarSingleChats = action.payload.data.filter(
          (x) => !x.isGroup
        );
        state.sidebarGroupChats = action.payload.data.filter((x) => x.isGroup);
        state.sidebarChatList = action.payload.data;
        return;
      }
      state.sidebarSingleChats = temp.filter((x) => !x.isGroup);
      state.sidebarGroupChats = temp.filter((x) => x.isGroup);
      state.sidebarChatList = temp;
    },

    //LOAD ROOM
    loadRoomRequest(state) {
      state.roomInfoLoading = true;
    },
    loadRoomSuccess(state, action) {
      // debugger;
      let temp = [...current(state.chatList)];
      let temp2 = [...current(state.sidebarChatList)];
      let room = temp?.find((x) => x?.id === action?.payload?.id);
      // if (!room) {
      //   temp?.unshift(action.payload);
      // }
      let room2 = temp2?.find((x) => x?.id === action?.payload?.id);
      let selectedRoom = { ...room };
      let selectedRoom2 = { ...room2 };
      let index = temp?.indexOf(room);
      let index2 = temp2?.indexOf(room2);
      selectedRoom["unreadCount"] = 0;
      selectedRoom2["unreadCount"] = 0;
      temp[index] = selectedRoom;
      temp2[index2] = selectedRoom2;
      state.singleChats = temp.filter((x) => !x.isGroup);
      state.groupChats = temp.filter((x) => x.isGroup);
      state.chatList = temp;
      state.sidebarSingleChats = temp2.filter((x) => !x.isGroup);
      state.sidebarGroupChats = temp2.filter((x) => x.isGroup);
      state.sidebarChatList = temp2;
      state.selectedRoom = action.payload;

      // state.groupedMessages = [];
      // state.messages = [];
      state.total = 0;
      state.offset = 0;
      state.roomInfoLoading = false;
      // state.threadId = null;
      state.threads = {};
    },

    addRoomToChatList(state, action) {
      let temp = [...current(state.chatList)];
      if (temp?.length > 0) {
        let room = temp?.find((x) => x?.id === action.payload.id);

        if (room) {
          return;
        }
        temp?.unshift(action.payload);
        state.chatList = temp;
        state.singleChats = temp.filter((x) => !x.isGroup);
        state.groupChats = temp.filter((x) => x.isGroup);
      }
    },

    //GET MESSAGES
    getChatMessagesRequest() {},
    getChatMessagesSuccess(state, action) {
      const payload = action.payload;
      const data = payload.data;
      let temp = [...state.messages];
      temp.push(...data[1]);
      state.total = payload.total;
      if (!payload.isPaginated) {
        state.messages = data[1];
        state.groupedMessages = data[0];
        // state.offset = state.messages.length;
        return;
      }
      state.groupedMessages = groupMessagesByDate(temp);
      state.messages = temp;
      // state.offset = state.messages.length;
    },

    //SET MESSAGES OFFSET
    setMessagesOffsetRequest(state, action) {
      state.offset = action.payload;
    },

    //GET MESSAGES
    sendMessageRequest() {},
    sendMessageSuccess(state, action) {
      // const chats = [...current(state.chatList)];
      // const isRoomExist = chats?.find((x) => x.id === state?.selectedRoom?.id);
      // if (!isRoomExist) {
      //   let newSelected = { ...state.selectedRoom };
      //   newSelected["lastMessage"] = action?.payload;
      //   let newChats = [...chats, newSelected];
      //   state.chatList = newChats;
      //   state.singleChats = newChats.filter((x) => !x.isGroup);
      //   state.groupChats = newChats.filter((x) => x.isGroup);
      // }
      if (action?.payload?.parentMessageId) {
        const allMessages = [...current(state?.messages)];
        const parent = allMessages?.find(
          (x) => x?.id === action?.payload?.parentMessageId
        );
        if (parent) {
          let temp = { ...parent };
          const index = allMessages?.indexOf(parent);
          temp["replyCount"] = temp?.replyCount + 1;
          const { childMessages } = temp;
          const newChildMessages = _.sortBy(
            [...childMessages[1], action.payload],
            ["createdAt"]
          );
          temp["repliers"] = getRepliersInfo(newChildMessages);
          temp["childMessages"] = [
            groupMessagesByDate(newChildMessages),
            newChildMessages,
          ];
          allMessages[index] = temp;
          const sortedMessages = _.sortBy(allMessages, ["createdAt"]);
          const groupedMessages = groupMessagesByDate(sortedMessages);
          state.groupedMessages = groupedMessages;
          state.messages = [...sortedMessages];
        }
        if (action?.payload?.parentMessageId == state?.threadId) {
          const thread = { ...current(state.threads) };
          const { childMessages, parentMessage } = thread;
          const newChildMessages = _.sortBy(
            [...childMessages[1], action.payload],
            ["createdAt"]
          );
          const groupedMessages = groupMessagesByDate(newChildMessages);
          const newRepliers = getRepliersInfo(newChildMessages);
          const tempParent = { ...parentMessage };
          tempParent["repliers"] = newRepliers;

          state.threads = {
            ...thread,
            childMessages: [groupedMessages, newChildMessages],
            parentMessage: tempParent,
          };
        }
        return;
      }
      const allMessages = [...state?.messages, action.payload];
      const sortedMessages = _.sortBy(allMessages, ["createdAt"]);
      const groupedMessages = groupMessagesByDate(allMessages);
      state.groupedMessages = groupedMessages;
      state.messages = [...sortedMessages];
      state.total = state.total + 1;
      // state.offset = state.messages.length;
    },

    //ADD RECEIVED MESSAGE TO ROOM
    addReceiveMessageToRoom(state, action) {
      if (action.payload?.roomId == state.selectedRoom?.id) {
        if (action?.payload?.parentMessageId) {
          const allMessages = [...current(state?.messages)];
          const parent = allMessages?.find(
            (x) => x?.id === action?.payload?.parentMessageId
          );
          if (parent) {
            let temp = { ...parent };
            const index = allMessages?.indexOf(parent);
            temp["replyCount"] = temp?.replyCount + 1;
            const { childMessages } = temp;
            const newChildMessages = _.sortBy(
              [...childMessages[1], action.payload],
              ["createdAt"]
            );
            temp["repliers"] = getRepliersInfo(newChildMessages);
            temp["childMessages"] = [
              groupMessagesByDate(newChildMessages),
              newChildMessages,
            ];
            allMessages[index] = temp;
            const sortedMessages = _.sortBy(allMessages, ["createdAt"]);
            const groupedMessages = groupMessagesByDate(sortedMessages);
            state.groupedMessages = groupedMessages;
            state.messages = [...sortedMessages];
          }
          if (action?.payload?.parentMessageId == state?.threadId) {
            const thread = { ...current(state.threads) };
            const { childMessages, parentMessage } = thread;
            const newChildMessages = _.sortBy(
              [...childMessages[1], action.payload],
              ["createdAt"]
            );
            const groupedMessages = groupMessagesByDate(newChildMessages);
            const newRepliers = getRepliersInfo(newChildMessages);
            const tempParent = { ...parentMessage };
            tempParent["repliers"] = newRepliers;

            state.threads = {
              ...thread,
              childMessages: [groupedMessages, newChildMessages],
              parentMessage: tempParent,
            };
          }
          return;
        }
        const allMessages = [...state?.messages, action.payload];
        const sortedMessages = _.sortBy(allMessages, ["createdAt"]);
        const groupedMessages = groupMessagesByDate(sortedMessages);
        state.groupedMessages = groupedMessages;
        state.messages = [...sortedMessages];
        state.total = state.total + 1;
        // state.offset = state.messages.length;
      }
    },

    //HANDLE TYPING
    setTyping(state, action) {
      if (action.payload.id === state.selectedRoom.id) {
        let members = { ...current(state.selectedRoom) };
        let typer = members?.roomMembers?.find(
          (x) => x?.id === action.payload.sender
        )?.username;
        state.typer = action.payload.typer;
        state.typing = action.payload.typing;
      }
    },
    setThreadTyping(state, action) {
      // const isSender = state.selectedRoom.receiverId === action.payload.sender;
      if (
        action.payload.id === state.selectedRoom.id &&
        action.payload.threadId === state.threadId
      ) {
        let members = { ...current(state.selectedRoom) };
        let typer = members?.roomMembers?.find(
          (x) => x?.id === action.payload.sender
        )?.username;
        state.threadTyper = action.payload.typer;
        state.threadTyping = action.payload.typing;
      }
    },

    //ADD REACT
    sendReactMsgRequest() {},
    sendReactMsgSuccess(state, action) {
      const allCurrentMessages = [...current(state?.messages)];
      const allMessages = [...state?.messages];

      const msgIndex = allCurrentMessages?.findIndex(
        (item) => item?.id == action?.payload?.messageId
      );

      if (msgIndex > -1) {
        if (action?.payload?.messageId === state.threadId) {
          const thread = { ...current(state.threads) };
          const { parentMessage } = thread;
          const tempParent = { ...parentMessage };
          tempParent["reacts"] = groupReactionsByEmoji([
            ...tempParent?.unGroupedReacts,
            action.payload,
          ]);
          tempParent["unGroupedReacts"] = [
            ...tempParent?.unGroupedReacts,
            action.payload,
          ];
          state.threads = {
            ...thread,
            parentMessage: tempParent,
          };
        }
        allMessages[msgIndex].reacts = groupReactionsByEmoji([
          ...allCurrentMessages[msgIndex].unGroupedReacts,
          action.payload,
        ]);
        allMessages[msgIndex].unGroupedReacts = [
          ...allCurrentMessages[msgIndex].unGroupedReacts,
          action.payload,
        ];

        const groupedMessages = groupMessagesByDate(allMessages);

        state.groupedMessages = groupedMessages;
        state.messages = allMessages;
      } else {
        if (state.threadId) {
          const thread = { ...current(state.threads) };
          const { childMessages } = thread;
          const thraedIndex = childMessages[1]?.findIndex(
            (item) => item?.id == action?.payload?.messageId
          );
          if (thraedIndex > -1) {
            const newThreads = [...childMessages[1]];
            const threadItem = { ...newThreads[thraedIndex] };
            threadItem.reacts = groupReactionsByEmoji([
              ...threadItem.unGroupedReacts,
              action.payload,
            ]);
            threadItem.unGroupedReacts = [
              ...threadItem.unGroupedReacts,
              action.payload,
            ];
            newThreads[thraedIndex] = threadItem;
            state.threads = {
              ...thread,
              childMessages: [groupMessagesByDate(newThreads), newThreads],
            };
          }
        }
      }
    },

    //REMOVE REACT
    removeReactMsgRequest() {},
    removeReactMsgSuccess(state, action) {
      const allMessages = [...state?.messages];

      const msgIndex = allMessages?.findIndex(
        (item) => item?.id == action?.payload?.message_id
      );

      if (msgIndex > -1) {
        if (action?.payload?.message_id === state.threadId) {
          const thread = { ...current(state.threads) };
          const { parentMessage } = thread;
          const tempParent = { ...parentMessage };
          const allReactions = [...tempParent?.unGroupedReacts]?.filter(
            (item) => item?.id !== action.payload?.id
          );
          tempParent["reacts"] = groupReactionsByEmoji([...allReactions]);
          tempParent["unGroupedReacts"] = [...allReactions];
          state.threads = {
            ...thread,
            parentMessage: tempParent,
          };
        }
        const allReactions = [...allMessages[msgIndex].unGroupedReacts]?.filter(
          (item) => item?.id !== action.payload?.id
        );
        allMessages[msgIndex].unGroupedReacts = [...allReactions];
        allMessages[msgIndex].reacts = groupReactionsByEmoji([...allReactions]);

        const groupedMessages = groupMessagesByDate(allMessages);

        state.groupedMessages = groupedMessages;
        state.messages = allMessages;
      } else {
        if (state.threadId) {
          const thread = { ...current(state.threads) };
          const { childMessages } = thread;
          const thraedIndex = childMessages[1]?.findIndex(
            (item) => item?.id == action?.payload?.message_id
          );
          if (thraedIndex > -1) {
            const newThreads = [...childMessages[1]];
            const threadItem = { ...newThreads[thraedIndex] };
            const allReactions = [...threadItem.unGroupedReacts]?.filter(
              (item) => item?.id !== action.payload?.id
            );
            threadItem.reacts = groupReactionsByEmoji([...allReactions]);
            threadItem.unGroupedReacts = [...allReactions];
            newThreads[thraedIndex] = threadItem;
            state.threads = {
              ...thread,
              childMessages: [groupMessagesByDate(newThreads), newThreads],
            };
          }
        }
      }
    },

    //GET CHAT DETAILS
    getChatDetailsRequest() {},
    getChatDetailsSuccess(state, action) {
      state.chatDetails = action.payload;
    },

    //GET LINK PREVIEW
    getLinkPreviewRequest() {},

    //REMOVE CHAT DATA
    removeChatDataRequest(state, action) {
      state.usersList = [];
      state.directUsersList = [];
      state.selectedRoom = {};
      state.groupedMessages = [];
      state.rooms = [];
      state.messages = [];
      state.total = 0;
      state.offset = 0;
      state.roomInfoLoading = false;
      state.moreMessagesLoading = false;
      state.chatList = [];
      state.typing = false;
      state.chatDetails = {};
      state.chatList = [];
      state.groupChats = [];
      state.singleChats = [];
      state.threads = {};
      state.threadId = null;
    },

    //SET THREAD
    setThread(state, action) {
      state.threadId = action.payload;
    },

    //GET THREADS
    getThreadsRequest() {},
    getThreadsSuccess(state, action) {
      state.threads = action.payload;
    },

    //CREATE GROUP
    createGroupRequest() {},
    createGroupSuccess(state, action) {
      let temp = [...state.chatList];
      temp.unshift(action.payload);
      state.singleChats = temp.filter((x) => !x.isGroup);
      state.groupChats = temp.filter((x) => x.isGroup);
      state.chatList = temp;
    },

    //GET GROUP MEMBERS
    getGroupParticipantsRequest() {},
    getGroupParticipantsSuccess(state, action) {
      let temp = [...state.participants];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.participants = action.payload.data;
        return;
      }
      state.participants = temp;
    },

    //GET REMAINING CHAT USERS
    getRemainingChatUsersRequest() {},
    getRemainingChatUsersSuccess(state, action) {
      let temp = [...state.directUsersList];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.directUsersList = action.payload.data;
        return;
      }
      state.directUsersList = temp;
    },

    //ADD GROUP MEMBERS
    addGroupMembersRequest() {},
    addGroupMembersSuccess(state, action) {},

    //REMOVE GROUP MEMBERS
    removeGroupMembersRequest() {},
    removeGroupMembersSuccess(state, action) {},

    //MAKE GROUP ADMIN
    makeGroupAdminRequest() {},
    makeGroupAdminSuccess(state, action) {
      if (action.payload?.room_id === state.selectedRoom.id) {
        state.selectedRoom.isAdminLoggedIn = true;
      }
      let temp = [...current(state?.chatList)];
      let currentRoom = temp?.find((x) => x?.id === action?.payload?.room_id);
      let index = temp?.indexOf(currentRoom);
      let clone = { ...currentRoom };
      clone["isAdminLoggedIn"] = true;
      temp[index] = clone;
      state.chatList = temp;
      state.singleChats = temp?.filter((x) => !x.isGroup);
      state.groupChats = temp?.filter((x) => x.isGroup);
    },

    //REMOVE GROUP ADMIN
    removeGroupAdminRequest() {},
    removeGroupAdminSuccess(state, action) {
      let temp = [...current(state?.chatList)];
      let currentRoom = temp?.find((x) => x?.id === action?.payload?.room_id);
      let index = temp?.indexOf(currentRoom);
      let clone = { ...currentRoom };
      clone["isAdminLoggedIn"] = false;
      temp[index] = clone;
      state.chatList = temp;
      state.singleChats = temp?.filter((x) => !x.isGroup);
      state.groupChats = temp?.filter((x) => x.isGroup);
    },

    //ON ADMIN REMOVED HANDLER
    onAdminRemoved(state, action) {
      if (action.payload?.room_id === state.selectedRoom.id) {
        state.selectedRoom.isAdminLoggedIn = false;
      }
    },

    //ON ADMIN MADE HANDLER
    onAdminMade(state, action) {
      if (action.payload?.room_id === state.selectedRoom.id) {
        state.selectedRoom.isAdminLoggedIn = true;
      }
    },

    //GET MENTIONS
    getMentionsRequest() {},
    getMentionsSuccess(state, action) {
      let temp = [...state.mentions];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        if (state?.selectedRoom?.isGroup) {
          state.mentions = [...action.payload.data, CUSTOM_ALL];
        } else {
          state.mentions = [...action.payload.data];
        }
        return;
      }
      state.mentions = temp;
    },

    //GET POSSIBLE ADMINS
    getPossibleAdminsRequest() {},
    getPossibleAdminsSuccess(state, action) {
      let temp = [...state.possibleAdmins];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.possibleAdmins = [...action.payload.data];
        return;
      }
      state.possibleAdmins = temp;
    },

    //LOAD TRANSACTION CHAT ROOM
    loadTransactionRoomRequest() {},
    loadTransactionRoomSuccess(state, action) {
      state.selectedRoom = action.payload;
      // state.groupedMessages = [];
      // state.messages = [];
      state.total = 0;
      state.offset = 0;
      state.roomInfoLoading = false;
      // state.threadId = null;
      state.threads = {};
    },

    //BLOCK ROOM
    blockRoomRequest() {},
    blockRoomSuccess(state, action) {
      if (state.selectedRoom?.id === action?.payload?.id) {
        state.selectedRoom.isBlocked = true;
        state.selectedRoom.blockedBy = action?.payload?.blocked_by;
      }
      let temp = [...current(state?.chatList)];
      let currentRoom = temp?.find((x) => x?.id === action?.payload?.id);
      let index = temp?.indexOf(currentRoom);
      let clone = { ...currentRoom };
      clone["isBlocked"] = true;
      clone["blockedBy"] = action?.payload?.blocked_by;
      temp[index] = clone;
      state.chatList = temp;
      state.singleChats = temp?.filter((x) => !x.isGroup);
      state.groupChats = temp?.filter((x) => x.isGroup);
    },

    //UNBLOCK ROOM
    unblockRoomRequest() {},
    unblockRoomSuccess(state, action) {
      if (state.selectedRoom?.id === action?.payload?.id) {
        state.selectedRoom.isBlocked = false;
        state.selectedRoom.blockedBy = null;
      }
      let temp = [...current(state?.chatList)];
      let currentRoom = temp?.find((x) => x?.id === action?.payload?.id);
      let index = temp?.indexOf(currentRoom);
      let clone = { ...currentRoom };
      clone["isBlocked"] = false;
      clone["blockedBy"] = null;
      temp[index] = clone;
      state.chatList = temp;
      state.singleChats = temp?.filter((x) => !x.isGroup);
      state.groupChats = temp?.filter((x) => x.isGroup);
    },

    //ARCHIVE ROOM
    archiveRoomRequest() {},
    archiveRoomSuccess(state, action) {
      if (state.selectedRoom?.id === action?.payload?.room_id) {
        state.selectedRoom.isArchived = true;
      }
      let temp = [...current(state?.chatList)];
      let currentRoom = temp?.find((x) => x?.id === action?.payload?.room_id);
      let index = temp?.indexOf(currentRoom);
      let clone = { ...currentRoom };
      clone["isArchived"] = true;
      temp[index] = clone;
      state.chatList = temp;
      state.singleChats = temp?.filter((x) => !x.isGroup);
      state.groupChats = temp?.filter((x) => x.isGroup);
    },

    //UNARCHIVE ROOM
    unarchiveRoomRequest() {},
    unarchiveRoomSuccess(state, action) {
      if (state.selectedRoom?.id === action?.payload?.room_id) {
        state.selectedRoom.isArchived = false;
      }
      let temp = [...current(state?.chatList)];
      let currentRoom = temp?.find((x) => x?.id === action?.payload?.room_id);
      let index = temp?.indexOf(currentRoom);
      let clone = { ...currentRoom };
      clone["isArchived"] = false;
      temp[index] = clone;
      state.chatList = temp;
      state.singleChats = temp?.filter((x) => !x.isGroup);
      state.groupChats = temp?.filter((x) => x.isGroup);
    },

    //CHECK LAST ADMIN
    checkLastGroupAdminRequest() {},
    checkLastGroupAdminSuccess(state, action) {},

    //LEAVE CHAT
    leaveChatRequest() {},
    leaveChatSuccess(state, action) {
      let temp = [...current(state?.chatList)];
      let newTemp = temp?.filter((x) => x?.id !== action.payload?.room_id);
      state.offset = 0;
      state.chatList = newTemp;
      state.singleChats = newTemp?.filter((x) => !x.isGroup);
      state.groupChats = newTemp?.filter((x) => x.isGroup);
      state.selectedRoom = state.groupChats?.[0];
    },

    //MEMBERS OFFSET
    setMembersOffset(state, action) {
      state.memberOffset = action.payload;
    },

    //MEMBERS OFFSET
    setChatListOffset(state, action) {
      state.chatListOffset = action.payload;
    },

    //UPDATE GROUP MEMBERS
    updateGroupMembers(state, action) {
      if (action.payload?.room_id === state.selectedRoom.id) {
        state.selectedRoom.membersCount = state.selectedRoom.membersCount - 1;
        state.selectedRoom.roomMembers =
          state.selectedRoom?.roomMembers?.filter(
            (x) => x?.chat_id !== action.payload.room_user_id
          );
      }
      let temp = [...current(state?.chatList)];
      let currentRoom = temp?.find((x) => x?.id === action?.payload?.room_id);
      let index = temp?.indexOf(currentRoom);
      let clone = { ...currentRoom };
      clone["membersCount"] = clone?.membersCount - 1;
      clone["roomMembers"] = clone?.roomMembers?.filter(
        (x) => x?.chat_id !== action.payload.room_user_id
      );

      temp[index] = clone;
      state.chatList = temp;
      state.singleChats = temp?.filter((x) => !x.isGroup);
      state.groupChats = temp?.filter((x) => x.isGroup);
    },
    //UPDATE GROUP MEMBERS ON ADD
    updateGroupMembersOnAdd(state, action) {
      if (action.payload?.room_id === state.selectedRoom.id) {
        state.selectedRoom.membersCount = state.selectedRoom.membersCount + 1;
      }
      let temp = [...current(state?.chatList)];
      let currentRoom = temp?.find((x) => x?.id === action?.payload?.room_id);
      let index = temp?.indexOf(currentRoom);
      let clone = { ...currentRoom };
      clone["membersCount"] = clone?.membersCount + 1;
      clone["roomMembers"] = clone?.roomMembers?.filter(
        (x) => x?.chat_id === action.payload.room_user_id
      );
      temp[index] = clone;
      state.chatList = temp;
      state.singleChats = temp?.filter((x) => !x.isGroup);
      state.groupChats = temp?.filter((x) => x.isGroup);
    },

    //EDIT GROUP
    editGroupRequest() {},
    editGroupSuccess(state, action) {
      if (state.selectedRoom?.id === action?.payload?.id) {
        state.selectedRoom.name = action.payload?.room_name;
      }
      let temp = [...current(state?.chatList)];
      let currentRoom = temp?.find((x) => x?.id === action?.payload?.id);
      let index = temp?.indexOf(currentRoom);
      let clone = { ...currentRoom };
      clone["name"] = action.payload?.room_name;
      temp[index] = clone;
      state.chatList = temp;
      state.singleChats = temp?.filter((x) => !x.isGroup);
      state.groupChats = temp?.filter((x) => x.isGroup);
    },

    //GET CHAT NOTIFICATIONS
    getChatNotificationsRequest() {},
    getChatNotificationsSuccess(state, action) {
      state.notificationSettings = action.payload;
    },

    //UPDATE CHAT NOTIFICATION SETTINGS
    updateChatNotiSettingRequest() {},
    updateChatNotiSettingSuccess(state, action) {
      state.notificationSettings = action.payload;
    },

    //SET MESSAGE ROUTE
    setMessageRoute(state, action) {
      state.messageRoute = action.payload.payload;
    },

    //SET IS SELECT
    setIsSelectFirst(state, action) {
      state.isSelect = action.payload;
    },

    //REMOVE CHAT DEVICE TOKEN
    removeChatTokenRequest() {},
    removeChatTokenSuccess() {},

    //GET TRANSACTION CHATS LIST
    getTransactionChatsListRequest() {},
    getTransactionChatsListSuccess(state, action) {
      state.transChats = action.payload;
    },
  },
});

export const {
  getChatUsersRequest,
  getChatUsersSuccess,

  getDirectChatUsersRequest,
  getDirectChatUsersSuccess,

  loadRoomRequest,
  loadRoomSuccess,

  getChatListRequest,
  getChatListSuccess,

  getChatMessagesRequest,
  getChatMessagesSuccess,

  sendMessageRequest,
  sendMessageSuccess,

  addReceiveMessageToRoom,

  sendReactMsgRequest,
  sendReactMsgSuccess,

  removeReactMsgRequest,
  removeReactMsgSuccess,

  getChatDetailsRequest,
  getChatDetailsSuccess,

  getLinkPreviewRequest,

  setTyping,

  removeChatDataRequest,

  setThread,

  getThreadsRequest,
  getThreadsSuccess,

  createGroupRequest,
  createGroupSuccess,

  getGroupParticipantsRequest,
  getGroupParticipantsSuccess,

  getRemainingChatUsersRequest,
  getRemainingChatUsersSuccess,

  addGroupMembersRequest,
  addGroupMembersSuccess,

  removeGroupMembersRequest,
  removeGroupMembersSuccess,

  makeGroupAdminRequest,
  makeGroupAdminSuccess,

  removeGroupAdminRequest,
  removeGroupAdminSuccess,

  getMentionsRequest,
  getMentionsSuccess,

  loadTransactionRoomRequest,
  loadTransactionRoomSuccess,

  blockRoomRequest,
  blockRoomSuccess,

  unblockRoomRequest,
  unblockRoomSuccess,

  archiveRoomRequest,
  archiveRoomSuccess,

  unarchiveRoomRequest,
  unarchiveRoomSuccess,

  checkLastGroupAdminRequest,
  checkLastGroupAdminSuccess,

  leaveChatRequest,
  leaveChatSuccess,

  setMembersOffset,

  updateGroupMembers,

  getSidebarChatListRequest,
  getSidebarChatListSuccess,

  editGroupRequest,
  editGroupSuccess,

  getChatNotificationsRequest,
  getChatNotificationsSuccess,

  setChatListOffset,

  updateChatNotiSettingRequest,
  updateChatNotiSettingSuccess,

  setMessagesOffsetRequest,

  setMessageRoute,

  setThreadTyping,

  setIsSelectFirst,

  updateGroupMembersOnAdd,

  getPossibleAdminsRequest,
  getPossibleAdminsSuccess,

  removeChatTokenRequest,
  removeChatTokenSuccess,

  onAdminRemoved,

  onAdminMade,

  addRoomToChatList,

  getTransactionChatsListRequest,
  getTransactionChatsListSuccess,
} = ChatReducer.actions;

export default ChatReducer.reducer;
