import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Dashboard, FolderDetails } from "./partials";
import "./styles.scss";

function Resources() {
  const { folder } = useParams();
  // CUSTOM COMPONENETS
  const StepComponent = useMemo(() => {
    if (folder) {
      return <FolderDetails />;
    }
    return <Dashboard />;
  }, [folder]);

  return <section className="resources-container">{StepComponent}</section>;
}

export default Resources;
