import React, { useState, useEffect } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, Modal, Row } from "antd";
import { Images } from "../../../../../../../themes";
import {
  ButtonComponent,
  CustomSelectDropdown,
  ProfileImage,
} from "../../../../../../../components";
import CurrentMembers from "../currentAccessMembers";
import {
  addFolderVisibilityRequest,
  addRoomVisibilityRequest,
  getRemainingFolderVisibilitiesRequest,
  getRemainingRoomVisibilitiesRequest,
} from "../../../../../../../redux/slicers/transactiondataroom";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetailDrawer } from "../../../../../../../redux/slicers/general";
import "./styles.scss";

function EditFolderAccessModal({
  preview,
  handleClose,
  members,
  isFolder,
  isOwner,
}) {
  //STATES
  const [selectedParties, setselectedParties] = useState([]);
  const [selectOpen, setSelectopen] = useState(false);

  //REDUX DATA
  const { remainingVisibility } = useSelector(
    (state) => state.transactiondataroom
  );
  //CONST VALS
  const { form } = Form.useForm();
  const dispatch = useDispatch();
  const { dataid, folder } = useParams();

  //CUSTOM DISPATCH
  const [getRemainingVisibility, visibilitiesLoader] = useCustomDispatch(
    folder
      ? getRemainingFolderVisibilitiesRequest
      : getRemainingRoomVisibilitiesRequest
  );
  const [addVisibility, addVisibilityLoader] = useCustomDispatch(
    folder ? addFolderVisibilityRequest : addRoomVisibilityRequest
  );

  //CUSTOM COMPONENTS
  const partyMemberItem = (data, index) => {
    const userdetail = remainingVisibility.find((x) => x.id === data);
    return (
      <div className="member-box" key={index}>
        <ProfileImage
          username={userdetail.username}
          image={userdetail.image}
          color={userdetail.profilebg}
          onClick={() => {
            dispatch(
              setUserDetailDrawer({
                visibility: true,
                id: userdetail.userid ?? userdetail.id,
              })
            );
          }}
          size={10}
        />
        <span className="user-detail">
          <b>{userdetail?.username}</b>
        </span>
        <span
          className="close-btn"
          onClick={() => {
            handleSelectMemberToggle(data);
          }}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>
    );
  };

  //HOOKS
  useEffect(() => {
    if (preview) {
      getRemainingVisibilityHandler();
    }
  }, [preview]);

  //HANDLERS
  const closeHandler = () => {
    handleClose();
    setselectedParties([]);
    setSelectopen(false);
  };

  const handleSubmit = () => {
    const payload = { visibilities: selectedParties };
    selectedParties.length === remainingVisibility.length
      ? (payload["isVisibleToAll"] = true)
      : (payload["isVisibleToAll"] = false);

    addVisibility({
      payload,
      pathParams: folder ? folder : dataid,
      logic(res) {
        if (res) {
          closeHandler();
        }
      },
    });
  };

  const getRemainingVisibilityHandler = () => {
    getRemainingVisibility({ pathParams: folder ? folder : dataid });
  };

  const handleSelectMemberToggle = (id) => {
    const isExist = selectedParties.some((x) => x === id);
    if (isExist) {
      const tempdata = [...selectedParties].filter((x) => x !== id);
      setselectedParties(tempdata);
      return;
    }
    setselectedParties([...selectedParties, id]);
  };

  const handleSelectAll = (temp) => {
    const tempData = [];
    remainingVisibility.forEach((element) => {
      tempData.push(element.id);
    });
    if (temp) {
      return tempData;
    }
    setselectedParties(tempData);
    setSelectopen(false);
  };

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={820}
      className="edit-access-modal">
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <Row>
            <Col span={16} className="edit-access-left-wrapper">
              <div className="title">
                <h3>Access to {isFolder ? "Folder" : "Data Room"}</h3>
                <p>Add Details Below</p>
              </div>
              <Form form={form} className="edit-visibility-form">
                <CustomSelectDropdown
                  options={remainingVisibility}
                  //   selectedUsers={[]}
                  visible={selectOpen}
                  visibleToggle={() => setSelectopen(!selectOpen)}
                  selectedUsers={selectedParties}
                  handleSelectAll={() => handleSelectAll(false)}
                  handleUserToggle={handleSelectMemberToggle}
                  dropdownClassName="select-dropdown"
                  isLoading={visibilitiesLoader}
                />
                {selectedParties.length >= 1 && (
                  <div className="selected-partymembers">
                    {selectedParties.map((item, index) =>
                      partyMemberItem(item, index)
                    )}
                  </div>
                )}
              </Form>
              <ButtonComponent
                text="Invite"
                className="edit-access-btn"
                onClick={handleSubmit}
                isLoading={addVisibilityLoader}
              />
            </Col>
            <Col span={8} className="cureent-access-wrapper">
              <CurrentMembers
                members={members}
                isEditable
                isOwner={isOwner}
                handleRemoveVisibility={getRemainingVisibilityHandler}
              />
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
}

export default EditFolderAccessModal;
