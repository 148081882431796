import { Col, Row } from "antd";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  DeleteModal,
  DisabledTooltip,
  EmptyBox,
  NoAccessBox,
  ProfileImage,
} from "../../../../../../../../components";
import {
  ALERT_TYPES,
  TRANSACTION_DOCUMENT_DETAIL_ROUTE,
  TRANSACTION_TABS_SLUGS,
} from "../../../../../../../../constants";
import { useCustomDispatch } from "../../../../../../../../helpers/useCustomDispatch";
import { setUserDetailDrawer } from "../../../../../../../../redux/slicers/general";
import { removeChecklistDocRequest } from "../../../../../../../../redux/slicers/transactionchecklist";
import {
  replaceValInString,
  toastAlert,
} from "../../../../../../../../services/utils";
import { Images } from "../../../../../../../../themes";
import { DocNoAccessModal } from "../../../components";
import "./styles.scss";

function DocGrid({ data, isEditable, isEditAllowed, isViewAllowed }) {
  //STATES
  const [unlinkModalPreview, setUnlinkModalPreview] = useState(false);
  const [docAccessModalPreview, setDocAccessModalPreview] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);

  //CUSTOM DISPATCH
  const [unlinkDocument, unlinkLoader] = useCustomDispatch(
    removeChecklistDocRequest
  );

  //CONST VALS
  const { id, room, dataid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //HANDLERS
  const docNavHandler = (item) => {
    if (item?.accessible) {
      const val = {
        ":id": id,
        ":room": room,
        ":tab": TRANSACTION_TABS_SLUGS.DOCUMENTS,
        ":dataid": item?.slug,
      };
      navigate(replaceValInString(TRANSACTION_DOCUMENT_DETAIL_ROUTE, val));
      return;
    }
    docAccessModalPreviewHandler(item);
  };

  const unlinkModalPreviewHandler = (id) => {
    selectedDoc ? setSelectedDoc(null) : setSelectedDoc(id);
    setUnlinkModalPreview(!unlinkModalPreview);
  };

  const handleUnlink = () => {
    unlinkDocument({
      payload: { ids: [selectedDoc], roomSlug: room },
      pathParams: dataid,
      logic(res) {
        unlinkModalPreviewHandler(null);
        toastAlert(res.message, ALERT_TYPES.SUCCESS);
      },
    });
  };

  const docAccessModalPreviewHandler = (item) => {
    selectedDoc ? setSelectedDoc(null) : setSelectedDoc(item);
    setDocAccessModalPreview(!docAccessModalPreview);
  };

  const userDetailHandler = (userid) => {
    dispatch(
      setUserDetailDrawer({
        visibility: true,
        id: userid,
      })
    );
  };

  return isViewAllowed ? (
    data?.length > 0 ? (
      <Row gutter={[25, 24]} className="doc-grid-wrapper">
        {data?.map((item, index) => {
          const isEven = (index + 1) % 2 === 0;
          return (
            <Col xs={24} sm={12} md={12} lg={24} xl={12} key={index}>
              <div
                className={`checklist-doc-card-wrapper ${
                  isEven ? "even" : ""
                } `}
              >
                <div className="card-top">
                  <div className="top-left">
                    <div className="doc-icon">
                      <Images.DocThumb />
                    </div>
                    <div className="doc-info">
                      <h3>{item?.name}</h3>
                      <div className="upload-text">
                        {`Uploaded From ${item?.uploadedFrom} By`}
                        <ProfileImage
                          image={item?.creatorImage}
                          username={item?.creatorName}
                          size={5}
                          color={item?.bgColor}
                          onClick={() => {
                            userDetailHandler(item.creatorId);
                          }}
                          isBlocked={!item?.isActive}
                        />
                        <span className="username">{item?.creatorName}</span>
                      </div>
                    </div>
                  </div>
                  {isEditable && (
                    <DisabledTooltip
                      innerSec={
                        <div
                          className=" unlink c-p"
                          onClick={(e) => {
                            if (isEditAllowed) {
                              e.stopPropagation();
                              unlinkModalPreviewHandler(item.id);
                            }
                          }}
                        >
                          <Images.Unlink />
                        </div>
                      }
                      visible={!isEditAllowed}
                    />
                  )}
                </div>
                <div
                  className="card-bottom c-p"
                  onClick={() => docNavHandler(item)}
                >
                  <h3>View Document</h3>
                </div>
              </div>
            </Col>
          );
        })}
        <DeleteModal
          preview={unlinkModalPreview}
          btnText="Remove"
          title={"Remove Document"}
          description={
            "Are you sure you want to remove this document from this checklist?"
          }
          handleClose={unlinkModalPreviewHandler}
          isLoading={unlinkLoader}
          handleDelete={handleUnlink}
        />
        <DocNoAccessModal
          preview={docAccessModalPreview}
          handleClose={docAccessModalPreviewHandler}
          data={selectedDoc}
        />
      </Row>
    ) : (
      <EmptyBox height={150} size={12} imgwidth={80} />
    )
  ) : (
    <NoAccessBox height={150} size={12} imgwidth={80} />
  );
}

export default DocGrid;
