// @flow
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  folderList: [],
  fileList: [],
  files: [],
  data: [],
};

const ResourcesReducer = createSlice({
  name: "resources",
  initialState,
  reducers: {
    // FOLDERS LIST
    getResourceFoldersListRequest() {},
    getResourceFoldersListSuccess(state, action) {
      let temp = [...state.folderList];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.folderList = action.payload.data;
        return;
      }
      state.folderList = temp;
    },

    //GET FOLDER DETAIL
    getResourceFolderDetailRequest() {},
    getResourceFolderDetailSuccess(state, action) {
      state.data = action.payload;
    },

    // FILES LIST
    getResourceFilesListRequest() {},
    getResourceFilesListSuccess(state, action) {
      let temp = [...state.fileList];
      temp.push(...action.payload.data);
      if (!action.payload.isPaginated) {
        state.fileList = action.payload.data;
        return;
      }
      state.fileList = temp;
    },
    // ALL RESOURCES FILE
    getAllResourcesFilesRequest() {},
    getAllResourcesFilesSuccess(state, action) {
      state.files = action.payload;
    },
  },
});

export const {
  getResourceFoldersListRequest,
  getResourceFoldersListSuccess,
  getResourceFilesListRequest,
  getResourceFilesListSuccess,
  getResourceFolderDetailRequest,
  getResourceFolderDetailSuccess,
  getAllResourcesFilesRequest,
  getAllResourcesFilesSuccess,
} = ResourcesReducer.actions;

export default ResourcesReducer.reducer;
