import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  DeleteModal,
  ProfileImage,
  VisibleToPeople,
} from "../../../../../../components";
import { setUserDetailDrawer } from "../../../../../../redux/slicers/general";
import { Images } from "../../../../../../themes";
import { GroupMembersModal } from "../../../components";
import { Dropdown, Menu } from "antd";
import { useCustomDispatch } from "../../../../../../helpers/useCustomDispatch";
import {
  blockRoomRequest,
  unblockRoomRequest,
} from "../../../../../../redux/slicers/chat";
import { getChatAccessToken } from "../../../../../../services/utils";
import { DEFAULT_PROFILE_COLOR } from "../../../../../../constants";

function ChatInfo({
  chat,
  selectedRoom,
  handleDetail,
  isTransaction,
  removeThreeDot,
  blockModalPreviewHandler,
  leaveRoomModalPreviewHandler,
  groupMemberModalPreviewHandler,
  handleEdit,
  roomBg,
}) {
  //STATES

  //CONST VALS
  const dispatch = useDispatch();

  //HANDLERS

  const userDetailHandler = () => {
    dispatch(
      setUserDetailDrawer({
        visibility: true,
        id: selectedRoom?.profileId,
      })
    );
  };

  //CUSTOM COMPONENTS
  const userMenu = () => (
    <Menu
      items={[
        ...[
          selectedRoom?.isGroup
            ? selectedRoom?.isAdminLoggedIn
              ? {
                  key: "1",
                  label: (
                    <span className="group-member-options">
                      <p>{"Edit"}</p>
                    </span>
                  ),
                  onClick: () => {
                    handleEdit();
                  },
                }
              : null
            : {
                key: "1",
                label: (
                  <span className="group-member-options">
                    <p>
                      {selectedRoom?.isBlocked
                        ? "Unblock from DM"
                        : "Block from DM"}
                    </p>
                  </span>
                ),
                onClick: () => {
                  // changeAdminRoleHander(item.id, item.isAdmin);
                  blockModalPreviewHandler();
                },
              },
        ],
        ...[
          selectedRoom?.isGroup
            ? {
                key: "2",
                label: (
                  <span className="group-member-options">
                    <p>Leave Group Chat</p>
                  </span>
                ),
                onClick: () => {
                  leaveRoomModalPreviewHandler();
                },
              }
            : null,
        ],
      ]}
    />
  );

  return (
    <div className="chat-info">
      <div className="info">
        <ProfileImage
          image={selectedRoom?.isGroup ? null : selectedRoom?.individual?.image}
          username={selectedRoom?.name}
          color={
            roomBg
              ? roomBg
              : selectedRoom?.individual?.bgColor ?? DEFAULT_PROFILE_COLOR
          }
          onClick={handleDetail}
          isBlocked={
            selectedRoom?.isGroup ? false : !selectedRoom?.individual?.isActive
          }
        />
        <div className="room-name-wrapper">
          <h3 onClick={handleDetail}>{selectedRoom?.name}</h3>
          {selectedRoom?.isGroup && (
            <span className="visibility">
              <VisibleToPeople
                data={selectedRoom?.roomMembers}
                size={9}
                detailModalHandler={groupMemberModalPreviewHandler}
                maxLength={5}
              />
              <p className="c-p" onClick={groupMemberModalPreviewHandler}>
                {selectedRoom?.membersCount > 20
                  ? "20 People +"
                  : selectedRoom?.membersCount + " People +"}
              </p>
            </span>
          )}
        </div>
      </div>
      {!isTransaction && !removeThreeDot && (
        <Dropdown
          getPopupContainer={(trigger) => trigger.parentElement}
          trigger={"click"}
          overlay={userMenu()}
          placement="left"
        >
          <Images.ThreeDotV className="c-p" />
        </Dropdown>
      )}
    </div>
  );
}

export default ChatInfo;
