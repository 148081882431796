import React from "react";
import { Col, Modal, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  ButtonComponent,
  DisabledTooltip,
} from "../../../../../../../components";
import "./styles.scss";

const VersionPreviewModal = ({
  preview,
  handleClose,
  data,
  handleEdit,
  isEditAllowed,
  roomAccess = false,
}) => {
  //HANDLERS
  const closeHandler = () => {
    handleClose();
  };

  const onEditClickHandler = () => {
    closeHandler();
    handleEdit();
  };

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={522}
      className="version-preview-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>Version Preview</h3>
            <p>Details Below</p>
          </div>
          <Row gutter={[0, 16]}>
            <Col span={24} className="preview-data">
              <p>{data?.name}</p>
            </Col>
            {data?.notes && (
              <Col span={24} className="preview-data">
                <p>{data?.notes}</p>
              </Col>
            )}
          </Row>
          <div className="modal-footer">
            <DisabledTooltip
              innerSec={
                <ButtonComponent
                  text={"Edit"}
                  onClick={isEditAllowed && onEditClickHandler}
                  disabled={roomAccess}
                />
              }
              visible={!isEditAllowed}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VersionPreviewModal;
