import React, { useState, useEffect } from "react";
import { Col, Form } from "antd";
import { CardLoader } from "../../../../../../../components";
import { SelectDocumentModal } from "../";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import {
  DOC_UPLOAD_TYPES_LIST,
  FILE_TYPES,
} from "../../../../../../../constants";
import {
  documentValidation,
  inputFieldRule,
} from "../../../../../../../services/utils";
import { uploadImageRequest } from "../../../../../../../redux/slicers/general";
import { FileUploader } from "react-drag-drop-files";
import { Images } from "../../../../../../../themes";
import "./styles.scss";

const DocumentFileUploader = ({
  isDataUpdated,
  handleSucess,
  resetUploader,
}) => {
  //STATES
  const [file, setFile] = useState(null);
  const [fileName, setfileName] = useState(null);
  const [fileSize, setFileSize] = useState(null);
  const [selectModalPreview, setSelectModalPreview] = useState(null);
  const [resourceFile, setResourceFile] = useState(null);

  // CUSTOM DISPATCH
  const [uploadImage, uploadLoading] = useCustomDispatch(uploadImageRequest);

  // HOOKS
  useEffect(() => {
    handleRemoveFile();
  }, [isDataUpdated]);

  const setFileSizeHandler = (size) => {
    if (size >= 1000000) {
      setFileSize(`${(size / 1000000).toFixed(2)} mb`);
      return;
    }
    setFileSize(`${(size / 1000).toFixed(2)} kb`);
  };

  const handleRemoveFile = () => {
    setFile(null);
    setfileName(null);
    setResourceFile(null);
    resetUploader && resetUploader();
  };

  const selectModalPreviewHandler = () => {
    setSelectModalPreview(!selectModalPreview);
  };

  const handleChange = (file) => {
    if (documentValidation(file)) {
      setResourceFile(null);
      const data = new FormData();
      data.append("file", file);
      data.append("directory", "users");
      uploadImage({
        payload: data,
        logic({ key }) {
          setFile(key);
          setfileName(file.name);
          handleSucess(key, DOC_UPLOAD_TYPES_LIST.computer);
          setFileSizeHandler(file.size);
        },
      });
    }
  };

  const resourceFileHandler = (val, isResource) => {
    handleRemoveFile();
    setResourceFile(val.file);
    selectModalPreviewHandler();
    handleSucess(
      val.file,
      DOC_UPLOAD_TYPES_LIST[isResource ? "resource" : "documents"]
    );
  };

  return (
    <>
      <Col span={12}>
        <Form.Item
          name={"url"}
          rules={inputFieldRule({
            name: "File",
            isWhiteSpace: false,
          })}
        >
          <div className={`upload-doc-box ${file ? "active" : ""}`}>
            {uploadLoading ? (
              <CardLoader size={14} height="100%" />
            ) : (
              <>
                <FileUploader
                  classes="drop_area"
                  handleChange={handleChange}
                  name="file"
                  types={FILE_TYPES}
                />
                <div className="content">
                  <Images.Upload />
                  <p>{fileName ?? "Drag and Drop File From Computer"}</p>
                </div>
              </>
            )}
          </div>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={"url"}
          rules={inputFieldRule({
            name: "File",
            isWhiteSpace: false,
          })}
        >
          <div
            className={`upload-doc-box c-p ${resourceFile ? "active" : ""}`}
            onClick={selectModalPreviewHandler}
          >
            <div className="content">
              <Images.Upload />
              <p>
                {resourceFile ? resourceFile : "Select file from this Platform"}
              </p>
            </div>
          </div>
        </Form.Item>
      </Col>
      <Col span={24}>
        {(file || resourceFile) && (
          <div className="uploaded-file-wrapper">
            <div className="left-wrapper">
              <Images.DocThumb className="doc-thumb" />
              <div className="file-info">
                <h3>{fileName ?? resourceFile}</h3>
                {/* <p>Size {fileSize ?? "456kb"}</p> */}
              </div>
            </div>
            <Images.CrossRed className="c-p" onClick={handleRemoveFile} />
          </div>
        )}
      </Col>

      <SelectDocumentModal
        preview={selectModalPreview}
        handleClose={selectModalPreviewHandler}
        handleSubmit={resourceFileHandler}
      />
    </>
  );
};

export default DocumentFileUploader;
