import { call, fork, put, take, takeLatest } from "redux-saga/effects";
import {
  callRequest,
  CREATE_TRANSACTION,
  GET_TRANSACTIONS,
  GET_TRANSACTION_QUESTION,
  GET_TRANSACTIONS_PARTIES,
  GET_TRANSACTIONS_ADVISORIES,
  GET_TRANSACTIONS_TYPE,
  UPDATE_TRANSACTION,
  CHECK_TRANSACTION_EXISTENCE,
  CHANGE_TRANSACTION_STATUS,
} from "../../config/webService";
import { ALERT_TYPES } from "../../constants";
import { manipulateTransactionData } from "../../dataManipulator/transactiondetail";
import { manipulateTransactionsList } from "../../dataManipulator/transactions";
import { toastAlert } from "../../services/utils";
import { changeTransactionDetailStatusSuccess } from "../slicers/transactiondetail";
import {
  createTransactionRequest,
  getSideNavTransactionsRequest,
  getSideNavTransactionsSuccess,
  getTransactionsAdvisoryRequest,
  getTransactionsAdvisorySuccess,
  getTransactionsPartiesRequest,
  getTransactionsPartiesSuccess,
  getTransactionsRequest,
  getTransactionsSuccess,
  getTransactionTypeRequest,
  getTransactionTypeSuccess,
  getTransactionQuestionRequest,
  updateTransactionRequest,
  checkTransactionExistenceRequest,
  changeTransactionStatusRequest,
  changeTransactionStatusSuccess,
} from "../slicers/transactions";

function* getTransactions(action) {
  const {
    payload: { queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_TRANSACTIONS,
      queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getTransactionsSuccess({
          isPaginated: isMoreData,
          data: manipulateTransactionsList(response.data),
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* getTransactionQuestion() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getTransactionQuestionRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_TRANSACTION_QUESTION,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

function* getSideNavTransactions() {
  while (true) {
    const {
      payload: { queryParams, responseCallback },
    } = yield take(getSideNavTransactionsRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_TRANSACTIONS,
        queryParams,
      });
      if (response.status) {
        yield put(
          getSideNavTransactionsSuccess(
            manipulateTransactionsList(response.data)
          )
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* createTransaction() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(createTransactionRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CREATE_TRANSACTION,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updateTransaction() {
  while (true) {
    const {
      payload: { pathParams, payload, responseCallback },
    } = yield take(updateTransactionRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPDATE_TRANSACTION,
        data: payload,
        pathParams: pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getTransactionType(action) {
  const {
    payload: { queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      queryParams,
      url: GET_TRANSACTIONS_TYPE,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getTransactionTypeSuccess({
          isPaginated: isMoreData,
          data: response.data,
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* getTransactionsAdvisory(action) {
  const {
    payload: { queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      queryParams,
      url: GET_TRANSACTIONS_ADVISORIES,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getTransactionsAdvisorySuccess({
          isPaginated: isMoreData,
          data: response.data,
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* getTransactionsParties(action) {
  const {
    payload: { queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      queryParams,
      url: GET_TRANSACTIONS_PARTIES,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getTransactionsPartiesSuccess({
          isPaginated: isMoreData,
          data: response.data,
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* checkTransactionExistence() {
  while (true) {
    const {
      payload: { queryParams, responseCallback },
    } = yield take(checkTransactionExistenceRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CHECK_TRANSACTION_EXISTENCE,
        queryParams,
      });
      if (response) {
        responseCallback?.(true, response);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* changeTransactionStatus() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(changeTransactionStatusRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CHANGE_TRANSACTION_STATUS,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        if (!response.isAsked) {
          yield put(
            changeTransactionDetailStatusSuccess(
              manipulateTransactionData(response.data)
            )
          );
          yield put(changeTransactionStatusSuccess(pathParams));
        }
      } else {
        responseCallback?.(false, response);
        toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

export default function* root() {
  // yield fork(getTransactions);
  yield takeLatest(getTransactionsRequest.type, getTransactions);
  yield fork(getSideNavTransactions);
  yield takeLatest(getTransactionTypeRequest.type, getTransactionType);
  yield takeLatest(
    getTransactionsAdvisoryRequest.type,
    getTransactionsAdvisory
  );
  yield takeLatest(getTransactionsPartiesRequest.type, getTransactionsParties);
  yield fork(getTransactionQuestion);
  yield fork(createTransaction);
  yield fork(updateTransaction);
  yield fork(checkTransactionExistence);
  yield fork(changeTransactionStatus);
}
