import React from "react";
import { Images } from "../../themes";
import CardLoader from "../cardLoader";
import ProfileImage from "../ProfileImage";
import "./styles.scss";

export default function AuthInviteDetail({ data, loader }) {
  return (
    <div
      className="login-invite-box"
      style={{ display: `${!loader & (data?.length === 0) ? "none" : ""}` }}
    >
      {loader ? (
        <CardLoader height={100} />
      ) : (
        <>
          <h3>Invitation</h3>
          <div className="details">
            <div className="detail-card">
              <ProfileImage
                image={data.thumb}
                color={data.profilebg}
                username={data.userName}
              />
              <div>
                <p className="text-1">Invited By</p>
                <p className="text-2">{data?.userName}</p>
                <p className="text-3">{data.companyName}</p>
              </div>
            </div>
            <div className="vertical-line"></div>
            <div className="detail-card">
              <Images.AuthDocument />
              <div>
                <p className="text-2">Transaction</p>
                <p className="text-1">{data?.transactionName}</p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
