import React from "react";
import { Images } from "../../themes";
import "./styles.scss";

export default function TransactionDetailCard({
  Icons,
  title,
  number,
  completedTransactions,
}) {
  return (
    <div
      className={`transaction-details-card card ${
        completedTransactions && "transactionsComplete"
      }`}>
      {!completedTransactions && <Icons />}
      <div>
        <p className="title">{title}</p>
        <p className="numbers">{number ?? 0}</p>
      </div>
      {completedTransactions && <Images.TransactionsComplete />}
    </div>
  );
}
