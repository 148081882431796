import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ButtonComponent } from "../../../../components";
import { useCustomDispatch } from "../../../../helpers/useCustomDispatch";
import {
  getAddressesListRequest,
  getLoggedInUserCompanyRequest,
} from "../../../../redux/slicers/company";
import { ENTERPRISE_TEAM_MANAGEMENT_ROUTE } from "../../../../constants";
import { Images } from "../../../../themes";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import EnterpriseSettingProfile from "./companyProfile";
import EnterpriseMembers from "../teamManagement/members";
import "./styles.scss";

function EnterpriseSetting() {
  // CONST VALS
  const navigate = useNavigate();

  // REDUX DATA
  const { addresses, companyProfile } = useSelector((state) => state.company);

  // DISPATCH CALLS
  const [getAddresses] = useCustomDispatch(getAddressesListRequest);
  const [getUserCompany, companyLoader] = useCustomDispatch(
    getLoggedInUserCompanyRequest
  );

  // HOOKS

  useEffect(() => {
    getUserCompany();
    getAddresses();
  }, []);

  // HANDLERS
  const handleViewAll = () => {
    navigate(ENTERPRISE_TEAM_MANAGEMENT_ROUTE);
  };

  return (
    <section className="enterprise-setting-wrapper">
      <div className="title-wrapper">
        <h3>Enterprise Settings</h3>
        <p>Manage Your Team Members</p>
      </div>
      <Row gutter={[20, 20]}>
        <Col xxl={7} xs={24} className="company-profile-wrapper">
          <Row gutter={[20, 20]}>
            <Col xxl={24} xs={12}>
              <EnterpriseSettingProfile
                companyProfile={companyProfile}
                isLoading={companyLoader}
              />
            </Col>
            <Col xxl={24} xs={12}>
              <div className="card future-message">
                <Images.TeamManagement />
                <h3>Our journey doesn't end here, there's more ahead</h3>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xxl={17} xs={24}>
          <div className="card">
            <div className="management-title">
              <h4>Team Management</h4>
              <ButtonComponent
                className="edit-profile-btn"
                text="View all"
                onClick={handleViewAll}
              />
            </div>
            <div className="enterprise-team-management p-0">
              <EnterpriseMembers addresses={addresses} isSettingView />
            </div>
          </div>
        </Col>
      </Row>
    </section>
  );
}

export default EnterpriseSetting;
