import { call, fork, put, takeLatest, take } from "redux-saga/effects";
import {
  callRequest,
  GET_RESOURCE_FOLDERS_LIST,
  GET_RESOURCE_FILES_LIST,
  GET_ALL_RESOURCE_FILES,
} from "../../config/webService";
import { ALERT_TYPES } from "../../constants";
import {
  manipulateResourceFilesList,
  manipulateResourceFolderData,
  manipulateResourceFoldersList,
} from "../../dataManipulator/resources";
import { toastAlert } from "../../services/utils";
import {
  getResourceFilesListRequest,
  getResourceFilesListSuccess,
  getResourceFolderDetailRequest,
  getResourceFolderDetailSuccess,
  getResourceFoldersListRequest,
  getResourceFoldersListSuccess,
  getAllResourcesFilesRequest,
  getAllResourcesFilesSuccess,
} from "../slicers/resources";

function* getResourcesFolders(action) {
  const {
    payload: { queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_RESOURCE_FOLDERS_LIST,
      queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getResourceFoldersListSuccess({
          isPaginated: isMoreData,
          data: manipulateResourceFoldersList(response.data),
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* getFolderDetails() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getResourceFolderDetailRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_RESOURCE_FOLDERS_LIST,
        pathParams,
      });
      if (response.status) {
        yield put(
          getResourceFolderDetailSuccess(
            manipulateResourceFolderData(response.data)
          )
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error(err);
      responseCallback?.(false, err);
    }
  }
}

function* getResourcesFiles(action) {
  const {
    payload: { queryParams, pathParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_RESOURCE_FILES_LIST,
      pathParams,
      queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getResourceFilesListSuccess({
          isPaginated: isMoreData,
          data: manipulateResourceFilesList(response.data),
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* getAllResourcesFiles(action) {
  const {
    payload: { queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_ALL_RESOURCE_FILES,
      queryParams,
    });
    if (response.status) {
      yield put(getAllResourcesFilesSuccess(response.data));
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

export default function* root() {
  yield takeLatest(getResourceFoldersListRequest.type, getResourcesFolders);
  yield takeLatest(getResourceFilesListRequest.type, getResourcesFiles);
  yield takeLatest(getAllResourcesFilesRequest.type, getAllResourcesFiles);
  yield fork(getFolderDetails);
}
