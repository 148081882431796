import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  CardHeader,
  CardLoader,
  EmptyBox,
  NoAccessBox,
} from "../../../../../../components";
import { Images } from "../../../../../../themes";
import { useCustomDispatch } from "../../../../../../helpers/useCustomDispatch";
import { getDocumentsRequest } from "../../../../../../redux/slicers/transactiondocuments";

const Documents = ({ roomslug, roomdetail, canView }) => {
  //CONST VALS
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // REDUX DATA
  const { documents } = useSelector((state) => state.transactiondocuments);

  // CUSTOM DISPATCH
  const [getDocuments, isLoading] = useCustomDispatch(getDocumentsRequest);

  // HELPERS
  const getDocumentsHelper = (isSearch) => {
    const queryParams = {
      limit: 10,
      offset: 0,
      roomSlug: roomslug,
    };
    if (isSearch) {
      queryParams["search"] = isSearch;
    }
    getDocuments({
      queryParams: queryParams,
      pathParams: roomslug,
    });
  };

  // HOOKS
  useEffect(() => {
    getDocumentsHelper();
  }, [roomslug]);

  //HANDLERS
  const handleSearch = (val) => {
    getDocumentsHelper(val);
  };
  const viewAllDocument = () => {
    navigate(`${pathname}/documents`);
  };

  const navigateToDocument = (slug) => {
    navigate(`${pathname}/documents/${slug}`);
  };

  //CUSTOM COMPONENTS
  const documentItems = () => (
    <div className="doc-list">
      {documents.length > 2 && <div id="docPg" className="doc-pagination" />}
      <OwlCarousel
        items={2}
        margin={15}
        navText={["&#x2190", "&#x2192"]}
        className="doc-swiper"
        nav={false}
        navContainer="#docPg"
        key={`media-${documents[0]?.slug}`}
      >
        {documents.map((item, index) => (
          <div
            onClick={() => {
              navigateToDocument(item.slug);
            }}
            className="document-card c-p"
            key={index}
          >
            <div className="header-sec">
              <div className="thumb">
                <Images.DocThumb />
              </div>
              <div className="detail">
                <h3>{item.name}</h3>
                <p>
                  Room: <b>{roomdetail?.name}</b>
                </p>
              </div>
            </div>
            <div className="description-sec">
              <p>{item.notes}</p>
              <div className="attach-box">
                <Images.AttachmentPin />
                <span>
                  {(
                    (item?.exhibitCount > 0 ? "0" : "") + item?.exhibitCount
                  ).slice(-2)}{" "}
                  Exhibit
                  {item?.exhibitCount > 1 ? "s" : ""}
                </span>
              </div>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );

  return (
    <div className="card mt-3">
      <CardHeader
        viewAllNavigation={viewAllDocument}
        isFilter
        title="Documents"
        viewAll={documents.length > 0 && canView}
        placeholder="Search any document..."
        handleSearch={handleSearch}
      />
      {!canView ? (
        <NoAccessBox />
      ) : isLoading ? (
        <CardLoader />
      ) : documents.length >= 1 ? (
        documentItems()
      ) : (
        <EmptyBox />
      )}
    </div>
  );
};

export default Documents;
