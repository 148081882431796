import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import { VisibleToPeople } from "../";
import { PERMISSIONS_ACCESS, PERMISSIONS_CATEGORY } from "../../constants";
import { downloadImage } from "../../helpers/downloadImage";

const ViewBox = ({ data, item }) => {
  const isInvitedUsers = item.label === "Users";
  const isVisibility = item.label === "Visibility" || item.label === "Users";
  const isResponsible = item.label === "Responsible Parties";
  const isAttachment = item.label === "Attachment";
  const isDocuments = item.label === "Documents";
  const isAnswer = item.label === "Answers";
  const isRoomVisibility =
    data.category !== PERMISSIONS_CATEGORY.DATAROOM &&
    data.category !== PERMISSIONS_CATEGORY.BREAKOUT &&
    data.access !== PERMISSIONS_ACCESS.INVITE_TO_TRANSACTION;
  if (isVisibility) {
    return (
      <>
        {typeof item?.value === "boolean" ? (
          <p>Visible To All</p>
        ) : (
          <VisibleToPeople
            className="visibility"
            data={item.value}
            isInvited={isInvitedUsers}
            size={7}
            isRoom={isRoomVisibility}
            borderWidth={1}
            maxLength={30}
          />
        )}
      </>
    );
  }
  if (isResponsible) {
    return (
      <div className="responsible-parties">
        {item.value?.map((val, index) => (
          <div className="responsible-party" key={index}>
            <VisibleToPeople
              className="visibility"
              data={val.users}
              size={6}
              borderWidth={1}
              maxLength={5}
            />
            <span className="name">{val.name}</span>
          </div>
        ))}
      </div>
    );
  }
  if (isAttachment) {
    const [isLoading, setLoading] = useState(false);
    return (
      <button
        className="attachment-box"
        onClick={() => {
          downloadImage(item.value, setLoading, item.value);
        }}
      >
        {isLoading ? <BeatLoader size={4} color="#6f7ced" /> : "Download"}
      </button>
    );
  }
  if (isDocuments) {
    return (
      <ul className="document-list">
        {item.value?.map((val, index) => (
          <li key={index}>{val.name}</li>
        ))}
      </ul>
    );
  }
  if (isAnswer) {
    return (
      <>
        {item.value?.length >= 1 ? (
          <div className="answer-wrapper">
            {item.value?.map((val, index) => (
              <div className="answer-box" key={index}>
                <p>
                  <b>Q: </b>
                  {val.question}
                </p>
                <p>
                  <b>A: </b>
                  {val.answer}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <p>-</p>
        )}
      </>
    );
  }
  return <p>{item.value && item.value !== "" ? item.value : "-"}</p>;
};

export default ViewBox;
