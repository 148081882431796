import React, { useRef } from "react";
import { Images } from "../../themes";
import "./styles.scss";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <footer>
      <div className="container">
        <div className="footer_wrapper">
          <span className="top_btn" onClick={scrollToTop}>
            <img src={Images.backToTop} alt="" />
          </span>
          <div className="social_link_wrapper">
            <div className="logo">
              <img src={Images.LogoText} alt="" />
            </div>
            <div className="social_icons">
              <a
                href="https://www.facebook.com/profile.php?id=100094924308905"
                target="_blank"
              >
                <Images.Facebook />
              </a>
              <a href="https://twitter.com/deal_tru" target="_blank">
                <Images.Twitter />
              </a>
              <a href="https://www.linkedin.com" target="_blank">
                <Images.Linkedin />
              </a>
              {/* <a href="https://www.instagram.com" target="_blank">
                <Images.Instagram />
              </a> */}
            </div>
            <p>Privacy Policy and Terms of Use</p>

            <hr />
          </div>
          <div className="rights_sec">
            <p>©2023 DealTru. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
