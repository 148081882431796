import React, { useEffect, useRef, useState } from "react";
import { Images } from "../../../../themes";
import { Col, Row } from "antd";
import "./styles/features.scss";

function Features() {
  const [activeTab, setActiveTab] = useState(0);
  const [animateImage, setAnimateImage] = useState(false);
  const imageRef = useRef(null);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
    setAnimateImage(true);
  };

  useEffect(() => {
    const imageElement = imageRef.current;
    if (imageElement) {
      const handleAnimationEnd = () => {
        setAnimateImage(false);
      };

      imageElement.addEventListener("animationend", handleAnimationEnd);

      return () => {
        imageElement.removeEventListener("animationend", handleAnimationEnd);
      };
    }
  }, [activeTab]);

  const tabs = [
    {
      label: {
        title: "Room Architecture",
        subTitle:
          "A common room and customizable breakout rooms where groups working on deal collaborate",
      },
      contentImg: Images.Feature1,
    },
    {
      label: {
        title: "Chat",
        subTitle:
          "Get off email and organize deal communications in structured chat channels",
      },
      contentImg: Images.Feature2,
    },
    {
      label: {
        title: "Documents",
        subTitle: "Collaborate on, negotiate and securely exchange documents",
      },
      contentImg: Images.Feature3,
    },
    {
      label: {
        title: "Streamlined Closings",
        subTitle: "Seemless integration with esignature",
      },
      contentImg: Images.Feature4,
    },
    {
      label: {
        title: "Data Rooms",
        subTitle:
          "Exchange data and documents securely with customized Data Rooms",
      },
      contentImg: Images.Feature5,
    },
    {
      label: {
        title: "Template Library",
        subTitle:
          "Templates for common transactions that enterprises can customize to streamline repeat transactions",
      },
      contentImg: Images.Feature6,
    },
    {
      label: {
        title: "AI Acceleration",
        subTitle:
          "Build your own data set to create documents using AI and mark up drafts",
      },
      contentImg: Images.Feature7,
    },
  ];

  return (
    <section className="features-wrapper">
      <div className="container">
        <div className="content">
          <h1>
            Customized Features Transform <span>Transactions</span>
          </h1>
          <div className="features-list">
            <Row gutter={[15, 16]}>
              <Col lg={10} md={24} xs={24}>
                <div className="vertical-tabs">
                  {tabs.map((tab, index) => (
                    <div
                      key={index}
                      className={`vertical-tab ${
                        index === activeTab ? "active" : ""
                      }`}
                      onClick={() => handleTabClick(index)}
                    >
                      <h3>{tab.label.title}</h3>
                      <p>{tab.label.subTitle}</p>
                    </div>
                  ))}
                </div>
              </Col>
              <Col lg={14} md={24} xs={24}>
                <div className={"vertical-tabs-content"}>
                  <img
                    src={tabs[activeTab].contentImg}
                    className={`content-image ${animateImage ? "animate" : ""}`}
                    ref={imageRef}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
