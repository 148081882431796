import React, { useState } from "react";
import { Col, Row } from "antd";
import { Images } from "../../../../themes";
import { FilterCard, FolderCard } from "../components";
import { useNavigate } from "react-router-dom";
import { replaceValInString } from "../../../../services/utils";
import {
  DATA_PER_PAGE_LIMIT,
  RESOURCES_FOLDER_ROUTE,
} from "../../../../constants";
import {
  CardLoader,
  CustomInfiniteScroll,
  EmptyBox,
} from "../../../../components";
import { useCustomDispatch } from "../../../../helpers/useCustomDispatch";
import { getResourceFoldersListRequest } from "../../../../redux/slicers/resources";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getTransactionTypeRequest } from "../../../../redux/slicers/transactions";

function Dashboard() {
  //STATES
  const [filterOpen, setFilterOpen] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [offset, setOffset] = useState(0);
  const [filterOffset, setFilterOffset] = useState(0);
  const [isMoreData, setMoreData] = useState(false);
  const [total, setTotal] = useState(0);
  const [filterTotal, setFilterTotal] = useState(0);
  const [searchtext, setSearchText] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);

  //CONST VALS
  const navigate = useNavigate();

  //CUSTOM DISPATCH
  const [getFoldersList, folderListLoader] = useCustomDispatch(
    getResourceFoldersListRequest
  );
  const [getTransactionTypes, transactionTypesLoader] = useCustomDispatch(
    getTransactionTypeRequest
  );

  //REDUX DATA
  const { folderList } = useSelector((state) => state.resources);
  const { types } = useSelector((state) => state.transactions);

  //HOOKS
  useEffect(() => {
    getFoldersHelper(searchtext !== "");
  }, [searchtext, selectedFilter, offset]);

  useEffect(() => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: filterOffset,
      status: true,
    };
    getTransactionTypes({
      queryParams,
      logic: (res) => {
        setFilterTotal(res.total);
      },
    });
  }, [filterOffset]);
  // HELPERS
  const getFoldersHelper = (isSearched) => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: offset,
    };
    if (isSearched) {
      queryParams["search"] = searchtext;
      setMoreData(false);
    }
    if (selectedFilter.length > 0) {
      queryParams["transactionType"] = selectedFilter.toString();
    }

    getFoldersList({
      queryParams,
      logic: (response) => {
        firstLoad && setFirstLoad(false);
        setTotal(response.total);
      },
    });
  };

  //HANDLERS
  const filterOpenHandler = () => {
    setFilterOpen(!filterOpen);
  };

  const handleFilter = (id) => {
    if (selectedFilter.includes(id)) {
      let temp = [...selectedFilter];
      const index = temp.indexOf(id);
      temp.splice(index, 1);
      setSelectedFilter(temp);
      return;
    }
    setSelectedFilter([...selectedFilter, id]);
  };

  const handleFolderClick = (slug) => {
    const vals = { ":folder": slug };
    navigate(replaceValInString(RESOURCES_FOLDER_ROUTE, vals));
  };

  const handleSearch = (val) => {
    setOffset(0);
    setSearchText(val);
  };

  const handleNextData = (filter) => {
    if (filter) {
      setFilterOffset(filterOffset + DATA_PER_PAGE_LIMIT);
      return;
    }
    setOffset(offset + DATA_PER_PAGE_LIMIT);
    setMoreData(true);
  };

  return (
    <>
      <div className="top-container">
        <div className="top-heading">
          <h3>Resources</h3>
        </div>
      </div>
      <div className="card">
        <div className="search-box">
          <Images.Search />
          <input
            type="text"
            placeholder={"Search any Folder..."}
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
          />
        </div>
        <div className="resource-content">
          {!firstLoad && (
            <div className="filter-container">
              <span className="filter-header">
                <h3>Filter Resources By Transaction Type</h3>{" "}
                <Images.DownArrow
                  className={`c-p ${filterOpen && "arrow-invert"}`}
                  onClick={filterOpenHandler}
                />
              </span>
              <div className={`filtertypes-list ${filterOpen ? "active" : ""}`}>
                {transactionTypesLoader ? (
                  <CardLoader height={150} />
                ) : types.length > 0 ? (
                  <CustomInfiniteScroll
                    dataLength={types.length}
                    maxHeight={160}
                    isWholePage={false}
                    minHeight={160}
                    total={filterTotal}
                    next={() => handleNextData(true)}>
                    <Row gutter={[15, 15]}>
                      {types?.map((item, index) => (
                        <Col xxl={6} xl={8} md={12} xs={24} key={index}>
                          <FilterCard
                            title={item.title}
                            isSelected={selectedFilter.includes(item.id)}
                            onClick={() => handleFilter(item.id)}
                          />
                        </Col>
                      ))}
                    </Row>
                  </CustomInfiniteScroll>
                ) : (
                  <EmptyBox height={200} size={16} imgwidt={30} />
                )}
              </div>
            </div>
          )}
          <div className="folder-container">
            <h3>Folders</h3>
            {folderListLoader && !isMoreData ? (
              <CardLoader />
            ) : folderList.length > 0 ? (
              <CustomInfiniteScroll
                dataLength={folderList?.length}
                maxHeight={490}
                total={total}
                next={() => handleNextData(false)}>
                <Row gutter={[16, 16]} style={{ display: "flex" }}>
                  {folderList.map((item, index) => (
                    <Col
                      // span={4}
                      xxl={4}
                      xl={6}
                      lg={8}
                      md={8}
                      key={index}
                      style={{ minWidth: "20%", display: "flex" }}>
                      <FolderCard
                        title={item.name}
                        fileCount={item.fileCount}
                        onClick={() => handleFolderClick(item.slug)}
                      />
                    </Col>
                  ))}
                </Row>
              </CustomInfiniteScroll>
            ) : (
              <EmptyBox height={250} size={16} imgwidt={30} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
