import React, { useState, useRef, useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ProfileImage, CustomInfiniteScroll, CardLoader, EmptyBox } from "../";
import {
  ACCOUNT_ROUTE,
  ALERT_TYPES,
  CONTACTUS_ROUTE,
  DASHBOARD_ROUTE,
  DATA_PER_PAGE_LIMIT,
  EMAILVERIFICATION_ROUTE,
  FORGETPASS_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  NO_DATA_FOUND_ERROR,
  RESETPASS_ROUTE,
  RESOURCES_ROUTE,
  SIGNUP_ROUTE,
} from "../../constants";
import { useCustomDispatch } from "../../helpers/useCustomDispatch";
import { userLogOutRequest } from "../../redux/slicers/user";
import {
  getNotificationsRequest,
  markAllAsReadRequest,
  markAsOpenRequest,
  markAsReadRequest,
  setConfirmApprovalRequestModal,
  openTransactionInviteModal,
} from "../../redux/slicers/general";
import Images from "../../themes/images";
import "./styles.scss";
import { toastAlert } from "../../services/utils";
import { MilestonDetailView } from "../../modules/private/transactionDetail/partials/milestones/components";
import { removeChatTokenRequest } from "../../redux/slicers/chat";

const Header = ({ publicHeader, dashboardHeader }) => {
  // STATES
  const [offset, setOffset] = useState(0);
  const [isMoreData, setMoreData] = useState(false);
  const [isMilestonePreview, setMilestonePreview] = useState(false);
  const [milestoneSlug, setMilestoneSlug] = useState(null);

  // CONST VALS
  const BOX_HEIGHT = 300;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const activeClass = (path) => (pathname === path ? "active" : "");
  const dispatch = useDispatch();

  // CUSTOM DISPATCH
  const [logoutReq, logoutReqLoader] = useCustomDispatch(userLogOutRequest);
  const [removeChatToken] = useCustomDispatch(removeChatTokenRequest);
  const [markRead] = useCustomDispatch(markAsReadRequest);
  const [markOpen, openLoader] = useCustomDispatch(markAsOpenRequest);
  const [markAllRead, allReadLoader] = useCustomDispatch(markAllAsReadRequest);
  const [getNotifications, isLoading] = useCustomDispatch(
    getNotificationsRequest
  );

  // REDUX DATA
  const { name, profilebg, profilePic } = useSelector(
    (state) => state.user.data
  );
  const {
    list: notifcations,
    total,
    openCount,
  } = useSelector((state) => state.general.notifications);

  // REFS
  const notificationRef = useRef();
  const profileRef = useRef();

  // HOOKS
  useEffect(() => {
    if (dashboardHeader) {
      const queryParams = { limit: DATA_PER_PAGE_LIMIT, offset };
      getNotifications({
        queryParams,
      });
    }
  }, [dashboardHeader, offset]);

  // HANDLERS
  const handleNextData = () => {
    setOffset(offset + DATA_PER_PAGE_LIMIT);
    setMoreData(true);
  };
  const markAsOpen = () => {
    if (!openLoader && openCount >= 1) {
      markOpen();
    }
  };
  const markAllAsRead = () => {
    if (!allReadLoader) {
      markAllRead();
    }
  };
  const navigateNotiHandler = ({
    isDeleted,
    isMilestone = false,
    route,
    isBlocked,
    isInvite,
    token,
  }) => {
    if (isBlocked) {
      return;
    }
    if (isDeleted) {
      toastAlert(NO_DATA_FOUND_ERROR, ALERT_TYPES.ERROR);
      return;
    }
    if (isMilestone) {
      setMilestonePreview(true);
      setMilestoneSlug(route);
      return;
    }
    if (isInvite) {
      dispatch(
        openTransactionInviteModal({
          token: token,
        })
      );

      return;
    }
    navigate(route);
  };
  const setMilestonePreviewHandler = () => {
    setMilestonePreview(!isMilestonePreview);
  };
  const markAsRead = (item) => {
    !item.isRead &&
      markRead({
        pathParams: item.id,
      });
    // notificationRef.current.blur();
    item.isAsk
      ? dispatch(
          setConfirmApprovalRequestModal({
            id: item.requestId,
            isAdmin: item.isAdmin,
          })
        )
      : navigateNotiHandler(item);
  };

  return (
    <div
      id="main-header"
      className={`top-header ${!dashboardHeader && "fixed-header"}`}
    >
      <div className={publicHeader ? "container" : "head-container"}>
        <Link to={HOME_ROUTE} className="logo-container">
          <img src={Images.LogoText} alt="" />
        </Link>
        {dashboardHeader ? (
          <div className="login-dashboard">
            <Images.Question
              onClick={() => navigate(RESOURCES_ROUTE)}
              style={{ marginTop: "-4px" }}
            />
            <div className="line"></div>
            <button
              onClick={markAsOpen}
              ref={notificationRef}
              className="notification-icon c-p u-s-n btn-transparent"
            >
              <Images.Notification />
              {openCount >= 1 && (
                <div className="count">
                  {openCount < 10 ? "0" : ""}
                  {openCount}
                </div>
              )}
              <div className={`notification-drop-down`}>
                <div className="top">
                  <h4>Notifications</h4>
                  {notifcations.length >= 1 && (
                    <p
                      onClick={markAllAsRead}
                      style={{ cursor: allReadLoader ? "auto" : "pointer" }}
                    >
                      Mark all as read
                    </p>
                  )}
                </div>
                <div>
                  {isLoading & !isMoreData ? (
                    <CardLoader size={14} height={BOX_HEIGHT} />
                  ) : notifcations.length >= 1 ? (
                    <CustomInfiniteScroll
                      dataLength={notifcations.length}
                      total={total}
                      isWholePage={false}
                      className="notifications-list"
                      next={handleNextData}
                      maxHeight={BOX_HEIGHT}
                    >
                      {notifcations.map((item, i) => (
                        <div
                          key={i}
                          onClick={() => {
                            markAsRead(item);
                          }}
                          className={`notification-card ${
                            item.isRead ? "seen" : ""
                          }`}
                        >
                          <ProfileImage
                            username={item.username}
                            image={item.thumb}
                            color={item.profilebg}
                            // isBlocked={!item.isActive}
                            size={11}
                          />
                          <div className="content">
                            <span className="name">{item.username}</span>
                            <span className="detail">{item.detail}</span>
                          </div>
                          <p className="last-seen">{item.date}</p>
                        </div>
                      ))}
                    </CustomInfiniteScroll>
                  ) : (
                    <EmptyBox height={BOX_HEIGHT} imgwidth={80} size={12} />
                  )}
                </div>
              </div>
            </button>
            {/* <p>{name}</p> */}
            <button
              ref={profileRef}
              className="profile-dropdown-container c-p btn-transparent u-s-n"
            >
              <ProfileImage
                image={profilePic}
                color={profilebg}
                username={name}
              />
              <Images.DownArrow />
              <div className={`profile-dropdown`}>
                <Images.Triangle className="triangle-up" />
                <p
                  onClick={() => {
                    profileRef?.current.blur();
                    navigate(ACCOUNT_ROUTE);
                  }}
                >
                  My Account
                </p>
                <div></div>
                <p
                  style={{ height: 20 }}
                  onClick={() => {
                    removeChatToken();
                    logoutReq({
                      logic() {
                        navigate(LOGIN_ROUTE);
                      },
                    });
                  }}
                >
                  {!logoutReqLoader && "Logout"}
                  <LoadingOutlined
                    style={{
                      fontSize: 15,
                      left: "5px",
                      display: logoutReqLoader ? "block" : "none",
                    }}
                    spin
                  />
                </p>
              </div>
            </button>
          </div>
        ) : (
          <ul>
            <li
              className={activeClass(HOME_ROUTE)}
              onClick={() => navigate(HOME_ROUTE)}
            >
              Home
            </li>
            <li
              className={activeClass(CONTACTUS_ROUTE)}
              onClick={() => navigate(CONTACTUS_ROUTE)}
            >
              Contact Us
            </li>
            {name ? (
              <li
                className={activeClass(DASHBOARD_ROUTE)}
                onClick={() => navigate(DASHBOARD_ROUTE)}
              >
                Dashboard
              </li>
            ) : (
              <>
                <li
                  className={`${activeClass(LOGIN_ROUTE)} ${activeClass(
                    RESETPASS_ROUTE
                  )} ${activeClass(EMAILVERIFICATION_ROUTE)} ${activeClass(
                    FORGETPASS_ROUTE
                  )}`}
                  onClick={() => navigate(LOGIN_ROUTE)}
                >
                  Login
                </li>
                <li
                  className={activeClass(SIGNUP_ROUTE)}
                  onClick={() => navigate(SIGNUP_ROUTE)}
                >
                  Signup
                </li>
              </>
            )}
          </ul>
        )}
      </div>
      <MilestonDetailView
        milestoneslug={milestoneSlug}
        modalPreview={isMilestonePreview}
        handleClose={setMilestonePreviewHandler}
      />
    </div>
  );
};

export default Header;
