import React from "react";
import { ClipLoader } from "react-spinners";
import { Menu, Dropdown } from "antd";
import { Images } from "../../themes";
import "./styles.scss";

export default function EditDeleteDropdown({
  style,
  isPreview,
  onPreviewClick,
  isShare,
  isEditable = true,
  isDelete = true,
  isDownload,
  isDownloadLoading,
  onDownloadClick = () => {},
  isMove,
  onMoveClick = () => {},
  onEditClick = () => {},
  onDeleteClick = () => {},
  onShareClick = () => {},
}) {
  // CUSTOM COMPONENT
  const userMenu = () => (
    <Menu
      items={[
        ...[
          isPreview && {
            key: "6",
            label: (
              <p className="dot-option-box" onClick={onPreviewClick}>
                Preview
              </p>
            ),
          },
          ...[
            isEditable && {
              key: "1",
              label: (
                <p className="dot-option-box" onClick={onEditClick}>
                  Edit
                </p>
              ),
            },
          ],
          ...[
            isDelete && {
              key: "2",
              label: (
                <p className="dot-option-box" onClick={onDeleteClick}>
                  Delete
                </p>
              ),
            },
          ],
          ...[
            isShare && {
              key: "3",
              label: (
                <p className="dot-option-box" onClick={onShareClick}>
                  Share
                </p>
              ),
            },
          ],
        ],
        ...[
          isMove && {
            key: "4",
            label: (
              <p className="dot-option-box" onClick={onMoveClick}>
                Move
              </p>
            ),
          },
        ],
        ...[
          isDownload && {
            key: "5",
            label: (
              <p
                className="dot-option-box"
                onClick={() => {
                  !isDownloadLoading && onDownloadClick();
                }}
              >
                Download
                {isDownloadLoading && (
                  <ClipLoader
                    color="#6F7CED"
                    size={10}
                    style={{ marginLeft: 5 }}
                  />
                )}
              </p>
            ),
          },
        ],
      ]}
    />
  );
  return (
    <Dropdown
      getPopupContainer={(trigger) => trigger.parentElement}
      trigger={"click"}
      overlay={userMenu()}
    >
      <button style={style} className="btn-transparent three-dot-icon">
        <Images.threeDot />
      </button>
    </Dropdown>
  );
}
