import React, { useState, useEffect } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, Modal, Row, Select, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useCustomDispatch } from "../../helpers/useCustomDispatch";
import {
  getEnterpriseMembersRequest,
  getRoomMembersRequest,
} from "../../redux/slicers/transactiondetail";
import { inputFieldRule } from "../../services/utils";
import { Images } from "../../themes";
import ButtonComponent from "../ButtonComponent";
import "./styles.scss";

const LeaveRoomModal = ({
  handleClose,
  open,
  title,
  description,
  tooltip,
  isAdmin,
  onSubmit,
  btnText,
  isAlreadyAdmin,
  btnLoader,
  isTransaction,
  companyId,
}) => {
  //REDUX DATA
  const { roomMembers, data, transactionenterprisemembers } = useSelector(
    (state) => state.transactiondetail
  );

  //CUSTOM DISPATCH
  const [getCompanyMembers, companyMemberLoading] = useCustomDispatch(
    getEnterpriseMembersRequest
  );
  const [getRoomMembers, roomMemberLoading] = useCustomDispatch(
    getRoomMembersRequest
  );

  //CONST VALS
  const [form] = Form.useForm();
  const { room } = useParams();
  const members = isTransaction ? transactionenterprisemembers : roomMembers;
  const isLoading = companyMemberLoading || roomMemberLoading;

  // HELPERS
  const getMemberHelper = () => {
    if (isTransaction) {
      const queryParams = { transactionId: data?.id, companyId };
      getCompanyMembers({ queryParams });
      return;
    }
    getRoomMembers({ pathParams: room });
  };
  //HOOKS
  useEffect(() => {
    if (open && isAdmin) getMemberHelper();
  }, [isAdmin, open]);

  //HANDLERS
  const closeHandler = () => {
    handleClose();
    form.resetFields();
  };

  const handleSubmit = () => {
    const values = form.getFieldsValue();
    closeHandler();
    onSubmit(values.selectedAdmin);
  };

  return (
    <Modal
      destroyOnClose
      visible={open}
      centered
      footer={null}
      width={450}
      // getContainer={false}
      className="leave-room-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
          {isAdmin ? (
            <Form form={form} onFinish={handleSubmit}>
              <Row gutter={[10, 12]}>
                <Col xs={24}>
                  <span className="select-form-item">
                    <Form.Item
                      name="selectedAdmin"
                      style={{ width: "100%" }}
                      rules={inputFieldRule({
                        name: "Leave room",
                        requiredMessage:
                          "Please assign an admin before leaving",
                        isWhiteSpace: false,
                      })}
                    >
                      <Select
                        placeholder="Assign Admin For The Room"
                        className="form-select"
                        filterOption={(input, option) =>
                          option.children
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        loading={isLoading}
                        showSearch
                        disabled={members.length < 1}
                      >
                        {members?.map((item, index) => (
                          <Select.Option value={item.id} key={index}>
                            {item.firstName} {item.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Tooltip
                      title={tooltip}
                      placement="bottomLeft"
                      overlayClassName="create-breakout-tooltip"
                      color="#F8FAFB"
                    >
                      <Images.Info style={{ cursor: "pointer" }} />
                    </Tooltip>
                  </span>
                </Col>
              </Row>
              <div className="modal-footer">
                <Form.Item>
                  <ButtonComponent text={`Submit`} isLoading={btnLoader} />
                </Form.Item>
              </div>
            </Form>
          ) : (
            <div className="modal-footer">
              <ButtonComponent
                text={btnText ? btnText : "Leave"}
                className={isAlreadyAdmin ? "leave-btn" : "make-btn"}
                onClick={() => onSubmit()}
                isLoading={btnLoader}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default LeaveRoomModal;
