import { Popover } from "antd";
import React, { useState } from "react";
import { Images } from "../../../../../../themes";
import { ClipLoader } from "react-spinners";

function Reactions({
  reactions,
  handleRemoveReaction,
  removeLoader,
  isBlocked,
}) {
  //STATES
  const [selected, setSelected] = useState(false);
  //CONST VALS
  const firstTwo = reactions?.slice(0, 2);
  const totalReacts = reactions?.length;
  const otherReacts = reactions?.slice(2)?.flatMap((item) => item?.reacts);

  //CUSTOM COMPONENTS
  const otherReactsList = (list) => {
    return (
      <div className="other-react-wrapper">
        {list?.map((item, index) => (
          <div className="other-react-item" key={index}>
            <p>
              {item?.reaction} {item?.reactorName}
            </p>
            {item?.selfReact &&
              !isBlocked &&
              (removeLoader && item?.id === selected ? (
                <ClipLoader size={12} color="#1C2B48" />
              ) : (
                <Images.CloseIcon
                  className="close c-p"
                  onClick={() => {
                    setSelected(item?.id);
                    handleRemoveReaction(item?.id);
                  }}
                />
              ))}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="chat-reactions">
      {firstTwo?.map((item, index) => (
        <Popover
          content={otherReactsList(item?.reacts)}
          trigger="click"
          placement="bottom"
          getPopupContainer={(trigger) => trigger.parentElement}
          key={index}
        >
          <div className="react-item c-p" key={index}>
            {item?.emoji}
            {item?.reacts?.length <= 9 ? item?.reacts?.length : "+9"}
          </div>
        </Popover>
      ))}
      {totalReacts > 2 && (
        <Popover
          content={otherReactsList(otherReacts)}
          trigger="click"
          placement="bottom"
          getPopupContainer={(trigger) => trigger.parentElement}
        >
          <div className="react-item c-p">+{totalReacts - 2}</div>
        </Popover>
      )}
    </div>
  );
}

export default Reactions;
