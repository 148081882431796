import React, { useEffect, useState } from "react";
import { useCustomDispatch } from "../../../../../../helpers/useCustomDispatch";
import { getLinkPreviewRequest } from "../../../../../../redux/slicers/chat";
import { CardLoader } from "../../../../../../components";

function LinkPreview({ data }) {
  //STATES
  const [preview, setPreview] = useState({});
  //CUSTOM DISPATCH
  const [getLinkPreview, isLoading] = useCustomDispatch(getLinkPreviewRequest);
  //HOOKS
  useEffect(() => {
    getLinkPreview({
      payload: { message: data },
      logic(res) {
        setPreview({
          title: res?.title,
          url: res?.url,
          image: res?.images?.[0],
        });
      },
    });
  }, []);
  return (
    <a className="link-preview-wrapper" target="_blank" href={preview?.url}>
      {isLoading ? (
        <CardLoader height={68} size={10} />
      ) : (
        <div className="link-preview">
          <div className="preview-img">
            <img src={preview?.image} alt="" />
          </div>
          <div className="preview-info">
            <h3>{preview?.title}</h3>
            <p>{preview?.url}</p>
          </div>
        </div>
      )}
    </a>
  );
}

export default LinkPreview;
