import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { Images } from "../../../../../../../themes";
import {
  CustomInfiniteScroll,
  CardLoader,
  EmptyBox,
} from "../../../../../../../components";
import { tableRowWrapper } from "./partials";
import {
  DeleteDocumentModal,
  CreateDocumentsModal,
  EditExhibitModal,
  ShareDocModal,
  VersionHistoryModal,
} from "../";
import "./styles.scss";

export default function DocumentsDataTable({
  sortData,
  selectedSort,
  selectedSortFilter,
  isLoading,
  isMoreData,
  total,
  data,
  handleFetch,
  isDeletedView,
  updateDataHandler,
  roomAccess,
  checkUserStatusHandler,
  isEditAllowed,
  isDeleteAllowed,
  isShareAllowed,
}) {
  //STATES
  const [selectedDoc, setSelectedDoc] = useState([]);
  const [deleteModalPreview, setDeleteModalPreview] = useState(false);
  const [editModalPreview, seteditModalPreview] = useState(false);
  const [editExVModalPreview, seteditExVModalPreview] = useState(false);
  const [isVersionHandler, setVersionHandler] = useState(false);
  const [isExhibitHandler, setisExhibitHandler] = useState(false);
  const [selectedData, setselectedData] = useState(null);
  const [shareModalPreview, setShareModalPreview] = useState(false);
  const [historyModalPreview, setHistoryModalPreview] = useState(false);
  //CONST VALS
  const HEIGHT = 485;
  const BOX_HEIGHT = `calc(100vh - ${HEIGHT}px)`;
  const TABLE_COLUMNS = [
    {
      title: "Detail",
      isSort: true,
      key: "name",
    },
    {
      title: "Date",
      isSort: true,
      key: "date",
    },
    {
      title: "Uploaded By",
      isSort: true,
      key: "uploadedby",
    },
    {
      title: "Exhibits",
      isSort: false,
      key: "exhibits",
    },
    {
      title: "Version",
      isSort: false,
      key: "version",
    },
    ...(!isDeletedView
      ? [
          {
            title: "Visible To",
            isSort: false,
            isIcon: true,
            icon: <Images.Visibility />,
            key: "visibility",
          },
        ]
      : []),
  ];

  //HANDLERS
  const expandTreeHandler = (key, id) => {
    const isExist = selectedDoc.find((x) => x.id === id);
    if (isExist) {
      let temp = [...selectedDoc];
      const index = temp.indexOf(isExist);
      if (isExist.key === key) {
        temp.splice(index, 1);
        setSelectedDoc(temp);
      } else {
        temp[index] = { id, key };
        setSelectedDoc(temp);
      }
      return;
    }
    setSelectedDoc([...selectedDoc, { id, key }]);
  };
  const editModalHandler = (data, isExhibit, isVersion) => {
    setVersionHandler(isVersion);
    setselectedData(data);
    if (isExhibit) {
      seteditExVModalPreview(true);
      return;
    }
    seteditModalPreview(true);
  };
  const editModalPreviewHandler = () => {
    seteditModalPreview(!editModalPreview);
  };
  const deleteModalHandler = (data, isExhibit, isVersion) => {
    setselectedData(data);
    setVersionHandler(isVersion);
    setisExhibitHandler(isExhibit);
    setDeleteModalPreview(true);
  };
  const shareModalHandler = (data, isExhibit, isVersion) => {
    setselectedData(data);
    setVersionHandler(isVersion);
    setisExhibitHandler(isExhibit);
    setShareModalPreview(true);
  };
  const historyModalHandler = (data, isExhibit, isVersion) => {
    setselectedData(data);
    setVersionHandler(isVersion);
    setisExhibitHandler(isExhibit);
    setHistoryModalPreview(true);
  };

  const deleteModalPreviewHandler = () => {
    setDeleteModalPreview(!deleteModalPreview);
  };
  const shareModalPreviewHandler = () => {
    setShareModalPreview(!shareModalPreview);
  };
  const editExVModalPreviewHandler = () => {
    seteditExVModalPreview(!editExVModalPreview);
  };

  const historyModalPreviewHandler = () => {
    setHistoryModalPreview(!historyModalPreview);
  };

  return (
    <div className={`documents-datatable ${isDeletedView ? "deleted" : ""}`}>
      <div className="table-head">
        {TABLE_COLUMNS.map((item, index) => {
          const isSelected = item?.key === selectedSort;
          const selectedCaret = isSelected && !selectedSortFilter;
          return (
            <span
              className={`head-ttl tbl-col-${index + 1} ${
                isSelected ? "active" : ""
              }`}
              key={index}
            >
              <span
                className={item?.isSort ? "c-p" : ""}
                onClick={() => {
                  item?.isSort && sortData(item?.key);
                }}
              >
                {item?.title}
                {item?.isIcon && item?.icon}
                {item?.isSort && (
                  <FontAwesomeIcon
                    icon={selectedCaret ? faCaretUp : faCaretDown}
                    className="sort-icon"
                  />
                )}
              </span>
            </span>
          );
        })}

        <span className={`head-ttl tbl-col-7 `} />
      </div>
      <div className="table-body">
        {isLoading && !isMoreData ? (
          <CardLoader height={BOX_HEIGHT} size={18} />
        ) : (
          <CustomInfiniteScroll
            dataLength={data.length}
            total={total}
            next={handleFetch}
            maxHeight={HEIGHT}
            minHeight={BOX_HEIGHT}
          >
            {data.length >= 1 ? (
              tableRowWrapper(
                data,
                1,
                selectedDoc,
                expandTreeHandler,
                false,
                isDeletedView,
                deleteModalHandler,
                editModalHandler,
                shareModalHandler,
                historyModalHandler,
                roomAccess,
                checkUserStatusHandler,
                isEditAllowed,
                isDeleteAllowed,
                isShareAllowed
              )
            ) : (
              <EmptyBox height={BOX_HEIGHT} />
            )}
          </CustomInfiniteScroll>
        )}
      </div>
      <DeleteDocumentModal
        data={selectedData}
        preview={deleteModalPreview}
        handleSuccess={updateDataHandler}
        handleClose={deleteModalPreviewHandler}
        isExhibit={isExhibitHandler}
        isVersion={isVersionHandler}
      />
      <CreateDocumentsModal
        isUpdate={true}
        data={selectedData}
        preview={editModalPreview}
        handleSuccess={updateDataHandler}
        handleClose={editModalPreviewHandler}
      />
      <EditExhibitModal
        preview={editExVModalPreview}
        handleClose={editExVModalPreviewHandler}
        handleSuccess={updateDataHandler}
        data={selectedData}
        isVersion={isVersionHandler}
      />
      <ShareDocModal
        preview={shareModalPreview}
        handleClose={shareModalPreviewHandler}
        data={selectedData}
        handleSuccess={updateDataHandler}
      />
      <VersionHistoryModal
        preview={historyModalPreview}
        handleClose={historyModalPreviewHandler}
        data={selectedData}
      />
    </div>
  );
}
