import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import { toastAlert, imageValidation } from "../../services/utils";
import { useCustomDispatch } from "../../helpers/useCustomDispatch";
import { uploadImageRequest } from "../../redux/slicers/general";
import { ALERT_TYPES, USERS_PREFIX } from "../../constants";
import "./styles.scss";
import { Images } from "../../themes";
const ImageUploader = ({
  setImage,
  setPreviewImage,
  isIcon,
  iconClassName,
  directory = USERS_PREFIX,
}) => {
  // CUSTOM DISPATCH
  const [uploadImage, isLoading] = useCustomDispatch(uploadImageRequest);

  // HANDLERS
  const setErrorToggle = (error) => {
    toastAlert(error, ALERT_TYPES.ERROR);
  };
  const handleChange = (e) => {
    if (isLoading) {
      return;
    }
    const file = e.target.files[0];
    if (file) {
      if (imageValidation(file, setErrorToggle)) {
        const data = new FormData();
        data.append("file", file);
        data.append("directory", directory);
        uploadImage({
          payload: data,
          logic({ key, url }) {
            setImage(key);
            setPreviewImage(url);
          },
        });
      }
    }
  };

  return !isIcon ? (
    <div className="upload-box">
      <input
        type="file"
        onChange={handleChange}
        value=""
        accept=".jpg, .jpeg, .png"
      />
      <span className="label">
        {isLoading ? <BeatLoader color="#6F7CED" size={12} /> : "Upload Image"}
      </span>
    </div>
  ) : (
    <div className={`isIcon-uploader ${iconClassName} `}>
      <input
        type="file"
        onChange={handleChange}
        value=""
        accept=".jpg, .jpeg, .png"
      />
      <img src={Images.EditImageIcon} alt="" />
    </div>
  );
};

export default ImageUploader;
