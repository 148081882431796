import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { CardLoader, ProfileImage, ButtonComponent } from "../";
import { useDispatch, useSelector } from "react-redux";
import { useCustomDispatch } from "../../helpers/useCustomDispatch";
import {
  closeConfirmApprovalRequestModal,
  getConfirmApprovalRequesDetailRequest,
  partyAdminResponseRequest,
  setUserDetailDrawer,
  transactionAdminResponseRequest,
} from "../../redux/slicers/general";
import { toastAlert } from "../../services/utils";
import ViewBox from "./viewbox";
import "./styles.scss";

function AdminApprovalModal() {
  //STATES
  const [status, setStatus] = useState(null);

  // REDUX DATA
  const { visibility, dataid, data, isAdmin } = useSelector(
    (state) => state.general.confirmApprovalRequest
  );

  //CUSTOM DISPATCH
  const [getRequestDetail, isLoading] = useCustomDispatch(
    getConfirmApprovalRequesDetailRequest
  );
  const [transactionAdminResponse, transactionAdminLoader] = useCustomDispatch(
    transactionAdminResponseRequest
  );
  const [partyAdminResponse, partyAdminLoader] = useCustomDispatch(
    partyAdminResponseRequest
  );

  // CONST VALS
  const dispatch = useDispatch();
  const { isEdit, userdata, admins } = data;
  const responseLoader = transactionAdminLoader || partyAdminLoader;

  //HELPERS
  const requestHelper = (request, queryParams, pathParams) => {
    request({
      pathParams,
      queryParams,
      logic(response) {
        toastAlert(response.message);
        closeHandler();
      },
    });
  };

  // HOOKS
  useEffect(() => {
    if (dataid) {
      const queryParams = { isAdmin: isAdmin };
      getRequestDetail({
        pathParams: dataid,
        queryParams,
      });
    }
  }, [dataid]);

  //HANDLERS
  const closeHandler = () => {
    dispatch(closeConfirmApprovalRequestModal());
  };

  const handleResponse = (val) => {
    const queryParams = { status: val };
    requestHelper(
      isAdmin ? partyAdminResponse : transactionAdminResponse,
      queryParams,
      dataid
    );
  };

  return (
    <Modal
      destroyOnClose
      visible={visibility}
      centered
      footer={null}
      width={isEdit ? 1131 : 807}
      className="admin-approval-modal">
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          {isLoading ? (
            <CardLoader height={240} />
          ) : (
            <>
              <Row>
                <Col span={isEdit ? 17 : 14} className="left-wrapper">
                  <h3 className="title">Request</h3>
                  <div className="request-creator">
                    <ProfileImage
                      username={userdata?.username}
                      image={userdata?.image}
                      color={userdata?.profilebg}
                      isBlocked={!userdata?.isActive}
                      size={19}
                    />
                    <div className="info">
                      <p>Request By</p>
                      <h4>
                        {userdata?.username} | {userdata?.companyName}
                      </h4>
                    </div>
                  </div>
                  <h2>{data?.title}</h2>
                  <div className={`detail ${data.isDeleted ? "deleted" : ""}`}>
                    {isEdit ? (
                      <Row>
                        <Col span={11}>
                          {data.oldDetail?.map((item, index) => (
                            <div className="data-col edited old" key={index}>
                              {data.thumb && (
                                <div className="thumb old">
                                  <data.thumb />
                                </div>
                              )}
                              <div className="info">
                                <h4>Old {item.label}</h4>
                                <ViewBox data={data} item={item} />
                              </div>
                            </div>
                          ))}
                        </Col>
                        <Col span={2}>
                          <div className="compareline" />
                        </Col>
                        <Col span={9}>
                          {data.detail?.map((item, index) => (
                            <div className="data-col edited" key={index}>
                              {data.thumb && (
                                <div className="thumb">
                                  <data.thumb />
                                </div>
                              )}
                              <div className="info">
                                <h4>New {item.label}</h4>
                                <ViewBox data={data} item={item} />
                              </div>
                            </div>
                          ))}
                        </Col>
                      </Row>
                    ) : (
                      data.detail?.map((item, index) => (
                        <div className="data-col" key={index}>
                          {data.thumb && (
                            <div className="thumb">
                              <data.thumb />
                            </div>
                          )}
                          <div className="info">
                            <h4>{item.label}</h4>
                            <ViewBox data={data} item={item} />
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                  {data?.isResponded ? (
                    <div className="responded">
                      <p>Already Responded</p>
                    </div>
                  ) : (
                    <div className="action-btn-wrapper">
                      <ButtonComponent
                        className="reject"
                        text="Reject"
                        isLoading={!status && responseLoader}
                        onClick={() => {
                          setStatus(false);
                          handleResponse(false);
                        }}
                      />
                      <ButtonComponent
                        text="Approve"
                        isLoading={status && responseLoader}
                        onClick={() => {
                          setStatus(true);
                          handleResponse(true);
                        }}
                      />
                    </div>
                  )}
                </Col>
                <Col span={isEdit ? 7 : 10} className="right-wrapper">
                  <h3 className="title sub">
                    {isAdmin ? "Party Group" : "Transaction"} Admins
                  </h3>
                  <div className="admin-wrapper">
                    {admins?.map((item, index) => (
                      <div className="admin-list-item" key={index}>
                        <div className="left">
                          <ProfileImage
                            image={item.image}
                            username={item.username}
                            color={item.profilebg}
                            isBlocked={!item.isActive}
                            onClick={() =>
                              dispatch(
                                setUserDetailDrawer({
                                  visibility: true,
                                  id: item.id,
                                })
                              )
                            }
                          />
                          <div className="info">
                            <h3>
                              {item.username} | {item.companyName}
                            </h3>
                            <p>{item.email}</p>
                          </div>
                        </div>
                        <span className={`status`} style={item?.status?.style}>
                          {item.status.title}
                        </span>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default AdminApprovalModal;
