import React from "react";
import { ClipLoader } from "react-spinners";
import Images from "../../themes/images";
import EmptyBox from "../emptyBox";
import ProfileImage from "../ProfileImage";
import "./styles.scss";

export default function CustomSelectDropdown({
  options,
  selectedUsers,
  handleSelectAll,
  handleUserToggle,
  visibleToggle,
  visible,
  dropdownClassName,
  isLoading,
}) {
  //CONST VALS
  const visibilityCondition =
    selectedUsers.length >= 1 && selectedUsers.length !== options.length;

  return (
    <div className="custom-select-wrapper">
      <div
        className={`custom-select-input ${
          visible && "custom-select-input-open"
        }`}
        onClick={visibleToggle}
      >
        {visible ? (
          <p className="placeholder-open">
            {visible ? "Invite" : "Visibility"}
          </p>
        ) : (
          <p
            className={`${
              visibilityCondition || options.length == 0
                ? "placeholder-close"
                : "placeholder-open"
            }`}
          >
            {visibilityCondition || options.length == 0
              ? "Visibility"
              : "Visible to All"}
          </p>
        )}
        {isLoading ? (
          <ClipLoader size={10} color={"#1C2B48"} />
        ) : (
          <Images.DownArrow
            className={`select-arrow ${visible && "select-arrow-rotated"}`}
          />
        )}
      </div>
      {!isLoading && visible && (
        <div className="custom-select-dropdown">
          {options.length >= 1 ? (
            <>
              <div className="select-all-option" onClick={handleSelectAll}>
                <p>Visible to All</p>
              </div>
              {options.map((item, index) => {
                const isOdd = index % 2 !== 0;
                return (
                  <div
                    className="custom-select-option c-p"
                    style={{
                      background: `${
                        !isOdd ? "rgba(112, 126, 174, 0.05)" : "#ffffff"
                      }`,
                    }}
                    onClick={() => handleUserToggle(item.id)}
                    key={index}
                  >
                    <span className="select-option-left">
                      <ProfileImage
                        color={item.profilebg}
                        image={item.image}
                        username={item.username}
                        size={12}
                        isBlocked={!item?.isActive}
                      />
                      <div className="name-email">
                        <p className="select-option-name">{item.username}</p>
                        <p className="select-option-email">{item.email}</p>
                      </div>
                    </span>
                    {selectedUsers.includes(item.id) ? (
                      <Images.UserAdded />
                    ) : (
                      <Images.UserAdd />
                    )}
                  </div>
                );
              })}
            </>
          ) : (
            <EmptyBox height={140} imgwidth={60} size={13} />
          )}
        </div>
      )}
    </div>
  );
}
