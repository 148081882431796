import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import { useNavigate } from "react-router-dom";
import {
  CardLoader,
  DisabledTooltip,
  EmptyBox,
  StatusToggle,
  Tabs,
  ViewAllBtn,
} from "../../../../components";
import {
  EDIT_TRANSACTION_ROUTE,
  PERMISSIONS_ACCESS,
  TRANSACTIONS_STATUS_LIST,
  TRANSACTION_DETAIL_ROUTE,
  TRANSACTION_ROUTE,
} from "../../../../constants";
import { Images } from "../../../../themes";
import { useCustomDispatch } from "../../../../helpers/useCustomDispatch";
import {
  changeTransactionStatusRequest,
  getTransactionsRequest,
} from "../../../../redux/slicers/transactions";
import {
  getActionPermission,
  replaceValInString,
} from "../../../../services/utils";

function Transactions({ isDataUpdated }) {
  // CONST VALS
  const navigate = useNavigate();
  const { EDIT_TRANSACTION, UPDATE_TRANSACTION_STATUS } = PERMISSIONS_ACCESS;
  // STATES
  const [selectedTransactionStatus, setSelectedTransactionStatus] =
    useState(null);

  // CUSTOM DISPATCH
  const [getTransactions, transactionLoader] = useCustomDispatch(
    getTransactionsRequest
  );
  const [changeStatus, statusLoader] = useCustomDispatch(
    changeTransactionStatusRequest
  );
  // REDUX DATA
  const { transactions } = useSelector((state) => state.transactions);

  //HELPERS
  const getTransactionsHelper = () => {
    const params = {
      status: selectedTransactionStatus,
      limit: 10,
    };
    getTransactions({
      queryParams: params,
    });
  };

  // HOOKS
  useEffect(() => {
    getTransactionsHelper();
  }, [selectedTransactionStatus, isDataUpdated]);

  // HANDLERS

  const navigateToTransaction = (transaction, room) => {
    const vals = { ":id": transaction, ":room": room };
    navigate(replaceValInString(TRANSACTION_DETAIL_ROUTE, vals));
  };

  const changeStatusHandler = (slug) => {
    changeStatus({
      pathParams: slug,
      logic() {
        getTransactionsHelper();
      },
    });
  };

  return (
    <>
      <div className="card all-transactions">
        <div className="all-transactions-top">
          <ViewAllBtn
            title={"All Transactions"}
            link={TRANSACTION_ROUTE}
            size={24}
            disableViewAll={transactions.length === 0}
          />

          <Tabs
            buttonsList={TRANSACTIONS_STATUS_LIST}
            setState={setSelectedTransactionStatus}
            state={selectedTransactionStatus}
          />
        </div>
        {transactionLoader ? (
          <CardLoader size={14} height={150} />
        ) : (
          <>
            {transactions?.length >= 1 ? (
              <>
                {transactions.length > 3 && (
                  <div id="docPg" className="doc-pagination" />
                )}
                <OwlCarousel
                  className="all-transactions-bottom owl-main owl-theme"
                  nav={true}
                  navContainer="#docPg"
                  navText={[
                    `<img src=${Images.Back} />`,
                    `<img src=${Images.Forward} />`,
                  ]}
                  margin={50}
                  responsive={{
                    0: {
                      items: 2,
                    },
                    1440: {
                      items: 3,
                    },
                  }}
                  dots={false}
                >
                  {transactions.map((item, i) => {
                    const isEditAllowed = getActionPermission(
                      EDIT_TRANSACTION,
                      item?.permissions
                    );
                    const statusChangeAllowed = getActionPermission(
                      UPDATE_TRANSACTION_STATUS,
                      item?.permissions
                    );
                    return (
                      <div
                        key={i}
                        onClick={() =>
                          navigateToTransaction(item.slug, item.roomSlug)
                        }
                        className="salesPurchaseCard card c-p"
                      >
                        <div className="top">
                          <p>{item.name}</p>
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <DisabledTooltip
                              innerSec={
                                <StatusToggle
                                  check={item.status}
                                  confirmDescription={
                                    "Are you sure you want to change this transaction's status?"
                                  }
                                  confirmLoader={statusLoader}
                                  handleStatusChange={() =>
                                    changeStatusHandler(item?.slug)
                                  }
                                  isStatic={!statusChangeAllowed}
                                />
                              }
                              visible={!statusChangeAllowed}
                            />
                          </span>
                          <span
                            className="d-flex"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <DisabledTooltip
                              innerSec={
                                <Images.Edit
                                  style={{
                                    opacity: `${item.status ? "0.5" : 1}`,
                                  }}
                                  onClick={(e) => {
                                    if (isEditAllowed && !item.status) {
                                      e.stopPropagation();
                                      navigate(
                                        EDIT_TRANSACTION_ROUTE + item.slug
                                      );
                                    }
                                  }}
                                />
                              }
                              visible={!isEditAllowed && !item.status}
                            />
                          </span>
                        </div>
                        <div
                          className={`bar ${
                            item.status ? "completed" : "pending"
                          }`}
                        />
                        <p className="typeDatePera">
                          Role Type: <span>{item.role}</span>
                        </p>
                        <p className="typeDatePera">
                          Type of Transaction: <span>{item.type}</span>
                        </p>
                      </div>
                    );
                  })}
                </OwlCarousel>
              </>
            ) : (
              <EmptyBox height={150} imgwidth={80} size={15} />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Transactions;
