import React, { useState } from "react";
import { Tooltip } from "antd";
import {
  EditDeleteDropdown,
  ProfileImage,
} from "../../../../../../../../components";
import { downloadImage } from "../../../../../../../../helpers/downloadImage";
import { Images } from "../../../../../../../../themes";
import {
  EditExhibitModal,
  DeleteDocumentModal,
  ShareDocModal,
  VersionPreviewModal,
} from "../../../components";
import { useDispatch } from "react-redux";
import { setUserDetailDrawer } from "../../../../../../../../redux/slicers/general";

function VersionCard({
  data,
  handleSuccess,
  roomAccess,
  isEditAllowed,
  isShareAllowed,
}) {
  // STATES
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [deleteModalPreview, setDeleteModalPreview] = useState(false);
  const [editModalPreview, seteditModalPreview] = useState(false);
  const [shareModalPreview, setShareModalPreview] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);

  // CONST VALS
  const toolTipCondition = data?.name?.length > 32;
  const dispatch = useDispatch();

  //  HANDLERS
  const editModalPreviewHandler = () => {
    seteditModalPreview(!editModalPreview);
  };
  const deleteModalPreviewHandler = () => {
    setDeleteModalPreview(!deleteModalPreview);
  };
  const shareModalPreviewHandler = () => {
    setShareModalPreview(!shareModalPreview);
  };
  const previewModalHandler = () => {
    setPreviewModal(!previewModal);
  };
  const downloadHandler = () => {
    downloadImage(data.file, setDownloadLoader, data.file);
  };
  const userDetailHandler = (userid) => {
    dispatch(
      setUserDetailDrawer({
        visibility: true,
        id: userid,
      })
    );
  };

  return (
    <>
      <div className="version-card-wrapper c-p">
        <div className="version-card-left">
          <div className="doc-icon">
            <Images.DocThumb />
          </div>
          <div className="version-info">
            <div className="uploaded-text">
              {`Upload From ${data?.uploadedFrom} By `}
              <ProfileImage
                username={data.uploadedBy?.username}
                image={data.uploadedBy?.image}
                isBlocked={!data.uploadedBy?.isActive}
                color={data.uploadedBy?.profilebg}
                size={4}
                onClick={() => {
                  userDetailHandler(data.uploadedBy?.id);
                }}
              />
              <span className="username">
                {" " + data.uploadedBy?.username}
              </span>
            </div>
            <Tooltip
              title={data.name}
              trigger={`${toolTipCondition && "hover"} `}
            >
              <h3 className={`${toolTipCondition ? "c-p" : ""}`}>
                {data.name}
              </h3>
            </Tooltip>
            <p>{data.createdAt}</p>
          </div>
        </div>

        <EditDeleteDropdown
          isPreview
          onPreviewClick={previewModalHandler}
          isShare={!roomAccess && isShareAllowed}
          isEditable={!roomAccess && isEditAllowed ? true : false}
          isDelete={!roomAccess && isEditAllowed ? true : false}
          isDownload
          onDownloadClick={downloadHandler}
          onDeleteClick={deleteModalPreviewHandler}
          onEditClick={editModalPreviewHandler}
          onShareClick={shareModalPreviewHandler}
          isDownloadLoading={downloadLoader}
        />
      </div>
      <DeleteDocumentModal
        data={data}
        preview={deleteModalPreview}
        handleSuccess={handleSuccess}
        handleClose={deleteModalPreviewHandler}
        isVersion
      />
      <EditExhibitModal
        preview={editModalPreview}
        handleClose={editModalPreviewHandler}
        handleSuccess={handleSuccess}
        data={data}
        isVersion
      />
      <ShareDocModal
        data={data}
        preview={shareModalPreview}
        handleClose={shareModalPreviewHandler}
      />
      <VersionPreviewModal
        preview={previewModal}
        handleClose={previewModalHandler}
        data={data}
        handleEdit={editModalPreviewHandler}
        isEditAllowed={isEditAllowed}
        roomAccess={roomAccess}
      />
    </>
  );
}

export default VersionCard;
