import React from "react";
import { Col, Form, Input } from "antd";
import { inputFieldRule } from "../../../../../../../services/utils";
import DocumentFileUploader from "../fileUploader";

export const DocFields = ({
  isDataUpdated,
  setFileWrapper,
  isUpdate,
  form,
}) => {
  return (
    <>
      <Col span={24}>
        <Form.Item
          name="name"
          rules={inputFieldRule({
            name: "Name",
            isMax: true,
            max: 100,
            isRequired: true,
          })}
        >
          <Input placeholder="Document Name" autoFocus />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="notes"
          rules={inputFieldRule({
            name: "Note",
            isMax: true,
            max: 1000,
            isRequired: true,
          })}
        >
          <Input.TextArea placeholder="Notes" className="textarea" />
        </Form.Item>
      </Col>
      {!isUpdate && (
        <DocumentFileUploader
          handleSucess={setFileWrapper}
          isDataUpdated={isDataUpdated}
          resetUploader={() => {
            form.resetFields(["url"]);
          }}
        />
      )}
    </>
  );
};
