import React, { useState } from "react";
import { Images } from "../../../../../../themes";
import { downloadImage } from "../../../../../../helpers/downloadImage";
import { PuffLoader } from "react-spinners";

function DocumentAttachmentWrapper({
  isDownload,
  fileName,
  handleRemoveFile = () => {},
  url,
}) {
  // STATES
  const [isLoading, setLoading] = useState(false);

  //HANDLER
  const onDownload = () => {
    downloadImage(fileName, setLoading, url);
  };

  return (
    <div className="chat-doc-wrapper">
      <Images.ChatDoc className="logo" />
      <p className="fileName">{fileName}</p>
      {isDownload ? (
        <>
          {isLoading ? (
            <span className="option">
              <PuffLoader size={12} />
            </span>
          ) : (
            <Images.DownloadAttachment
              onClick={onDownload}
              className="option c-p"
            />
          )}
        </>
      ) : (
        <Images.CloseIcon className="option c-p" onClick={handleRemoveFile} />
      )}
    </div>
  );
}

export default DocumentAttachmentWrapper;
