import { call, fork, put, take, takeLatest } from "redux-saga/effects";
import {
  callRequest,
  CREATE_TRANSACTION_DOCUMENT,
  UPDATE_TRANSACTION_DOCUMENT,
  DELETE_TRANSACTION_DOCUMENT,
  GET_DOCUMENTS_LISTING,
  GET_DOCUMENT_DETAIL,
  GET_DOCUMENTS_FILES,
  GET_DOCUMENTS_FILES_EXHIBITS,
  CREATE_DOCUMENT_EXHIBIT,
  UPDATE_DOCUMENT_EXHIBIT,
  DELETE_DOCUMENT_EXHIBIT,
  CREATE_DOCUMENT_VERSION,
  UPDATE_DOCUMENT_VERSION,
  DELETE_DOCUMENT_VERSION,
  GET_ALL_DOCS,
  GET_ALL_VERSIONS,
  GET_ROOM_VERSIONS,
  SHARE_VERSION,
  GET_VERSION_HISTORY,
  GET_DELETED_DOCUMENTS_LISTING,
} from "../../config/webService";
import { ALERT_TYPES } from "../../constants";
import {
  manipulateAllVersionsList,
  manipulateDocumentData,
  manipulateDocumentList,
  manipulateVersionData,
  manipulateVersionHistoryList,
} from "../../dataManipulator/transactiondocument";
import { toastAlert } from "../../services/utils";
import {
  createDocumentRequest,
  updateDocumentRequest,
  deleteDocumentRequest,
  getDocumentsRequest,
  getDocumentsSuccess,
  getDocumentDetailRequest,
  getDocumentDetailSuccess,
  getDocumentsFilesRequest,
  getDocumentsFilesSuccess,
  getDocumentsFilesExhibitsRequest,
  getDocumentsFilesExhibitsSuccess,
  createExhibitRequest,
  updateExhibitRequest,
  deleteExhibitRequest,
  createVersionRequest,
  updateVersionRequest,
  deleteVersionRequest,
  getAlltransactionDocsRequest,
  getAlltransactionDocsSuccess,
  getAllDocsVersionsRequest,
  getAllDocsVersionsSuccess,
  getAllDocsRoomVersionsRequest,
  getAllDocsRoomVersionsSuccess,
  shareDocRequest,
  getVersionHistoryRequest,
  getVersionHistorySucccess,
  createDocumentSuccess,
  getDeletedDocumentsSuccess,
  getDeletedDocumentsRequest,
} from "../slicers/transactiondocuments";

function* getDocuments(action) {
  const {
    payload: { pathParams, queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_DOCUMENTS_LISTING,
      pathParams,
      queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getDocumentsSuccess({
          isPaginated: isMoreData,
          data: manipulateDocumentList(response.data),
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* getDeletedDocuments(action) {
  const {
    payload: { pathParams, queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_DELETED_DOCUMENTS_LISTING,
      pathParams,
      queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getDeletedDocumentsSuccess({
          isPaginated: isMoreData,
          data: manipulateDocumentList(response.data),
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* getDocumentsFiles(action) {
  const {
    payload: { responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_DOCUMENTS_FILES,
    });
    if (response.status) {
      yield put(getDocumentsFilesSuccess(response.data));
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* getDocumentsFilesExhibits(action) {
  const {
    payload: { pathParams, queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_DOCUMENTS_FILES_EXHIBITS,
      pathParams,
      queryParams,
    });
    if (response.status) {
      yield put(getDocumentsFilesExhibitsSuccess(response.data));
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* getDocumentDetail() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getDocumentDetailRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_DOCUMENT_DETAIL,
        pathParams,
      });
      if (response.status) {
        yield put(
          getDocumentDetailSuccess(manipulateDocumentData(response.data))
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* createDocument() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(createDocumentRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CREATE_TRANSACTION_DOCUMENT,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
        response.message && toastAlert(response.message, ALERT_TYPES.SUCCESS);
        if (!response.isAsked)
          yield put(
            createDocumentSuccess(manipulateDocumentData(response.data))
          );
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updateDocument() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(updateDocumentRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPDATE_TRANSACTION_DOCUMENT,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        response.message && toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* deleteDocument() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(deleteDocumentRequest.type);

    try {
      const response = yield call(callRequest, {
        url: DELETE_TRANSACTION_DOCUMENT,
        pathParams,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
        response.message && toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* createDocumentExhibit() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(createExhibitRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CREATE_DOCUMENT_EXHIBIT,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
        response.message && toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updateDocumentExhibit() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(updateExhibitRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPDATE_DOCUMENT_EXHIBIT,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        response.message && toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* deleteDocumentExhibit() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(deleteExhibitRequest.type);

    try {
      const response = yield call(callRequest, {
        url: DELETE_DOCUMENT_EXHIBIT,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        response.message && toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* createDocumentVersion() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(createVersionRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CREATE_DOCUMENT_VERSION,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
        response.message && toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updateDocumentVersion() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(updateVersionRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPDATE_DOCUMENT_VERSION,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        response.message && toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* deleteDocumentVersion() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(deleteVersionRequest.type);

    try {
      const response = yield call(callRequest, {
        url: DELETE_DOCUMENT_VERSION,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
        response.message && toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getAlltransactionDocs(action) {
  const {
    payload: { pathParams, queryParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_ALL_DOCS,
      pathParams,
      queryParams,
    });
    const isMoreData = queryParams?.offset >= 1;
    if (response.status) {
      yield put(
        getAlltransactionDocsSuccess({
          isPaginated: isMoreData,
          data: response.data,
        })
      );
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* getAllDocsVersions() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getAllDocsVersionsRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_ALL_VERSIONS,
        pathParams,
      });
      if (response.status) {
        yield put(
          getAllDocsVersionsSuccess(manipulateAllVersionsList(response.data))
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getAllRoomDocsVersions() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getAllDocsRoomVersionsRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_ROOM_VERSIONS,
        pathParams,
      });
      if (response.status) {
        yield put(
          getAllDocsRoomVersionsSuccess(
            manipulateAllVersionsList(response.data)
          )
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* shareDocument() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(shareDocRequest.type);

    try {
      const response = yield call(callRequest, {
        url: SHARE_VERSION,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
        response.message && toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getVersionHistory() {
  while (true) {
    const {
      payload: { queryParams, responseCallback },
    } = yield take(getVersionHistoryRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_VERSION_HISTORY,
        queryParams,
      });
      if (response.status) {
        yield put(
          getVersionHistorySucccess(manipulateVersionHistoryList(response.data))
        );
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

export default function* root() {
  yield takeLatest(getDocumentsRequest.type, getDocuments);
  yield takeLatest(getDeletedDocumentsRequest.type, getDeletedDocuments);
  yield takeLatest(getDocumentsFilesRequest.type, getDocumentsFiles);
  yield takeLatest(
    getDocumentsFilesExhibitsRequest.type,
    getDocumentsFilesExhibits
  );
  yield takeLatest(getAlltransactionDocsRequest.type, getAlltransactionDocs);
  yield fork(getDocumentDetail);
  yield fork(createDocument);
  yield fork(updateDocument);
  yield fork(deleteDocument);
  yield fork(createDocumentExhibit);
  yield fork(updateDocumentExhibit);
  yield fork(deleteDocumentExhibit);
  yield fork(createDocumentVersion);
  yield fork(updateDocumentVersion);
  yield fork(deleteDocumentVersion);
  yield fork(getAllDocsVersions);
  yield fork(getAllRoomDocsVersions);
  yield fork(shareDocument);
  yield fork(getVersionHistory);
}
