import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "antd";
import { UserApprovalCard } from "..";
import ButtonComponent from "../ButtonComponent";
import "./styles.scss";

const ApprovalRequestModal = ({ data, handleClose, modalPreview }) => {
  return (
    <Modal
      destroyOnClose
      visible={modalPreview}
      centered
      footer={null}
      width={490}
      className="approval-request-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={() => handleClose()}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>Approval Request</h3>
            <p>Waiting For Enterprise Admin</p>
          </div>
          <UserApprovalCard data={data} />
          <div className="btn-container">
            <ButtonComponent
              className="login-btn"
              onClick={() => {
                handleClose();
              }}
              text={"Back To Login"}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ApprovalRequestModal;
