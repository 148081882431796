import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Images } from "../../../../../../themes";
import CreateDataroomModal from "./createDataroomModal";
import { DeleteModal, DisabledTooltip } from "../../../../../../components";
import {
  deleteDataRoomRequest,
  deleteFolderRequest,
} from "../../../../../../redux/slicers/transactiondataroom";
import { useCustomDispatch } from "../../../../../../helpers/useCustomDispatch";
import { useNavigate, useParams } from "react-router-dom";
import { toastAlert } from "../../../../../../services/utils";
import DataroomPermissionModal from "./permissionModal";

export default function DataroomNavbar({
  title,
  isBack,
  backFunc,
  btnFunc,
  btnText,
  searchPlaceholder,
  searchText,
  searchHandler,
  isBtn = true,
  isEditable,
  data,
  transactionslug,
  isActionAllowed = true,
  isCreateAllowed,
  isFolder,
  permissionLoader = false,
  isEditAllowed,
}) {
  //STATES
  const [editModalPreview, seteditModalPreview] = useState(false);
  const [deleteModalPreview, setDeleteModalPreview] = useState(false);
  const [permissionModalPreview, setPermissionModalPreview] = useState(false);

  //CUSTOM DISPATCH
  const [deleteDataRoom, roomLoader] = useCustomDispatch(deleteDataRoomRequest);
  const [deleteFolderRoom, folderLoader] =
    useCustomDispatch(deleteFolderRequest);

  //CONST VALS
  const navigate = useNavigate();
  const { folder } = useParams();
  const deleteLoader = folderLoader || roomLoader;

  // HELPERS
  const requestHelper = (request) => {
    request({
      pathParams: folder ? folder : data.slug,
      logic({ message }) {
        toastAlert(message);
        deleteModalPreviewHandler();
        navigate(-1);
      },
    });
  };

  //HANDLERS
  const editModalPreviewHandler = () => {
    seteditModalPreview(!editModalPreview);
  };
  const deleteModalPreviewHandler = () => {
    setDeleteModalPreview(!deleteModalPreview);
  };
  const handleDelete = () => {
    folder ? requestHelper(deleteFolderRoom) : requestHelper(deleteDataRoom);
  };
  const permissionModalPreviewHandler = () => {
    setPermissionModalPreview(!permissionModalPreview);
  };

  return (
    <div className="room-detail">
      <div className={`left-wrapper ${!isBtn ? "full-width" : ""}`}>
        <div className="description">
          {isBack && <Images.BackArrow onClick={backFunc} className="c-p" />}
          <span>
            <h4>{data ? data.name : title}</h4>
            <span className="edit-del">
              <p>View All Details</p>
              {isEditable && isEditAllowed && (
                <>
                  <Images.Edit
                    className="c-p"
                    onClick={editModalPreviewHandler}
                  />
                  <Images.Delete
                    className="c-p filled-icon"
                    onClick={deleteModalPreviewHandler}
                  />
                  {!isFolder && (
                    <Images.Permissions
                      className="c-p filled-icon"
                      onClick={permissionModalPreviewHandler}
                    />
                  )}
                </>
              )}
            </span>
          </span>
        </div>
        <div className="search-box">
          <FontAwesomeIcon icon={faSearch} />
          <input
            type="text"
            value={searchText}
            placeholder={searchPlaceholder}
            onChange={(e) => searchHandler(e.target.value)}
          />
        </div>
      </div>
      {isBtn && (
        <div className="right-wrapper">
          <DisabledTooltip
            innerSec={
              <button onClick={isActionAllowed && btnFunc}>{btnText}</button>
            }
            visible={!isActionAllowed}
          />
        </div>
      )}
      <CreateDataroomModal
        handleClose={editModalPreviewHandler}
        preview={editModalPreview}
        transactionslug={transactionslug}
        isEdit={true}
        data={data}
        title={folder ? "Folder" : "Data Room"}
        placeholder={`${folder ? "Folder" : "Data Room"} Name`}
        isFolder={folder ? true : false}
      />
      <DeleteModal
        preview={deleteModalPreview}
        handleClose={deleteModalPreviewHandler}
        title={`Delete ${folder ? "Folder" : "Data Room"} `}
        description={`Are you sure you want to delete this ${
          folder ? "folder" : "data room"
        }
        ?`}
        isLoading={deleteLoader}
        handleDelete={handleDelete}
        isDataRoom
      />
      <DataroomPermissionModal
        preview={permissionModalPreview}
        handleClose={permissionModalPreviewHandler}
        permissionLoader={permissionLoader}
      />
    </div>
  );
}
