import React, { useState, useEffect, useMemo } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Select, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useCustomDispatch } from "../../../../../../helpers/useCustomDispatch";
import { getCompanyMembersRequest } from "../../../../../../redux/slicers/company";
import {
  autoCompleteFieldRule,
  inputFieldRule,
  toastAlert,
} from "../../../../../../services/utils";
import {
  ButtonComponent,
  CustomUserAutoComplete,
  ProfileImage,
} from "../../../../../../components";
import { Images } from "../../../../../../themes";
import { ALERT_TYPES, DATA_PER_PAGE_LIMIT } from "../../../../../../constants";
import { setUserDetailDrawer } from "../../../../../../redux/slicers/general";

const IndividualTab = ({
  setCompanySearchTextHandler,
  companies,
  onSubmit,
  isLoading,
  slug,
}) => {
  // STATES
  const [isCompanySelected, setSelectedCompany] = useState(null);
  const [isUserSelected, setUserSelected] = useState(null);
  const [selectedIndividuals, setSelectedIndividuals] = useState([]);

  // CUSTOM DISPATCH
  const [getCompanyMembers] = useCustomDispatch(getCompanyMembersRequest);

  //REDUX
  const { companyMembers, isMembersLoading } = useSelector(
    (state) => state.company
  );

  // CONST VALS
  const isMembersOptionsVisible = !isMembersLoading && isCompanySelected;
  const [companyForm] = Form.useForm();
  const [memberForm] = Form.useForm();
  const dispatch = useDispatch();

  //HELPERS
  const getCompanyMembersHelper = (company, val) => {
    const queryParams = {
      offset: 0,
      limit: DATA_PER_PAGE_LIMIT,
      company,
      individual: true,
      transactionName: slug,
      isActive: true,
    };
    if (val) queryParams["search"] = val;
    getCompanyMembers({
      queryParams,
    });
  };

  // HOOKS
  useEffect(() => {
    if (!isUserSelected) {
      memberForm.resetFields(["firstName", "lastName"]);
    }
  }, [isUserSelected]);

  // -- filter company members email key according to selected members
  const companyMembersFiltered = useMemo(
    () =>
      companyMembers.filter((obj) =>
        selectedIndividuals.every((item) => item.email !== obj.email)
      ),

    [companyMembers, selectedIndividuals]
  );

  // HANDLERS

  const handleCompanySearch = (val) => {
    setCompanySearchTextHandler(val);
    setSelectedCompany(null);
  };

  const handleUserSearch = (val) => {
    getCompanyMembersHelper(isCompanySelected, val);
    setUserSelected(null);
    // setmemberError("");
  };

  const handleSetUser = (val, name) => {
    const member = companyMembers?.find((x) => x[name] === val.value);
    setUserSelected(member);
    memberForm.setFieldsValue({
      email: member?.email,
      firstName: member?.firstName,
      lastName: member?.lastName,
    });
  };

  const handleAddMember = (values) => {
    const isMemberExist = selectedIndividuals.some(
      (x) => x.email === values.email
    );
    if (isMemberExist) {
      toastAlert("Member already exists", ALERT_TYPES.ERROR);
      return;
    }
    const memberInfo = companyMembers.find((x) => x.email === values.email);
    const member = {
      ...values,
      profilebg: memberInfo?.profilebg,
      image: null,
      id: memberInfo?.id,
      username: memberInfo?.firstName + " " + memberInfo?.lastName,
    };
    if (memberInfo) member["image"] = memberInfo.thumb;
    setSelectedIndividuals((selectedIndividuals) => [
      member,
      ...selectedIndividuals,
    ]);
    setUserSelected(null);
    memberForm.resetFields();
  };

  const handleRemoveMember = (index) => {
    const tempdata = [...selectedIndividuals];
    tempdata.splice(index, 1);
    setSelectedIndividuals(tempdata);
  };

  const handleSetField = (name, value) => {
    memberForm.setFieldValue(name, value);
  };

  const handleGetMembersByEnterprise = (company) => {
    setSelectedCompany(company);
    getCompanyMembersHelper(company);
  };

  const handleSubmit = () => {
    companyForm
      .validateFields()
      .then(async ({ company }) => {
        if (selectedIndividuals.length <= 0) {
          toastAlert("Minimum 1 individual required", ALERT_TYPES.ERROR);
          // setmemberError("Minimum 1 individual required");
          return;
        }
        const payload = { company, users: selectedIndividuals };
        onSubmit(payload);
      })
      .catch((err) => {
        toastAlert(err, ALERT_TYPES.ERROR);
      });
  };
  // CUSTOM COMPONENTS
  const memberBox = (data, index) => {
    return (
      <div className="member-box" key={index}>
        <ProfileImage
          username={data.firstName + " " + data.lastName}
          image={data.image}
          color={data.profilebg}
          size={10}
          onClick={() => {
            dispatch(
              setUserDetailDrawer({
                visibility: true,
                id: data.id,
              })
            );
          }}
        />
        <span className="user-details">
          <b>
            {data.firstName} {data.lastName}
          </b>
          <p>{data.email}</p>
        </span>
        <span
          className="close-btn"
          onClick={() => {
            handleRemoveMember(index);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>
    );
  };
  return (
    <div className="add-individual-form">
      <Form form={companyForm} className="invite-advised-party-wrapper">
        <Form.Item
          name="company"
          rules={inputFieldRule({ name: "Company", isWhiteSpace: false })}
        >
          <Select
            showSearch
            onSearch={(val) => {
              handleCompanySearch(val);
            }}
            disabled={selectedIndividuals.length >= 1}
            placeholder="Select Enterprise"
            filterOption={(input, option) =>
              option.children.props.children[1].props.children
                ?.toLowerCase()
                ?.includes(input.toLowerCase())
            }
            onSelect={(val, option) => {
              handleGetMembersByEnterprise(option.key);
            }}
            style={{ display: "flex" }}
          >
            {companies.map((item, index) => (
              <Select.Option value={item.name} key={item.id}>
                <span className="company-select-options">
                  <ProfileImage
                    image={item.logo}
                    username={item.name}
                    size={10}
                  />
                  <p>{item.name}</p>
                </span>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Tooltip
          title="If company is not visible in dropdown then you need
          to invite company first.
          "
          placement="bottomLeft"
          overlayClassName="invite-participant-tooltip"
          color="#F8FAFB"
          getPopupContainer={(trigger) => trigger.parentElement}
        >
          <Images.InviteInfo className="invite-info-icon" />
        </Tooltip>
      </Form>
      <Form
        form={memberForm}
        onFinish={handleAddMember}
        disabled={!isCompanySelected}
      >
        <CustomUserAutoComplete
          options={isMembersOptionsVisible ? companyMembersFiltered : []}
          isTriggered
          setSearch={handleUserSearch}
          onOptionSelect={handleSetUser}
          handleSet={handleSetField}
          name="email"
          rules={[
            () => ({
              validator(_, value) {
                return autoCompleteFieldRule({
                  value,
                  isRequired: isCompanySelected,
                  name: "Email address",
                  isEmail: true,
                  isMax: true,
                  max: 150,
                });
              },
            }),
          ]}
          placeholder="Email of the user at Enterprise to Invite"
          labelProp={"email"}
          valueProp={"id"}
        />
        <CustomUserAutoComplete
          options={isMembersOptionsVisible ? companyMembersFiltered : []}
          isTriggered
          disabled={isUserSelected}
          setSearch={handleUserSearch}
          onOptionSelect={handleSetUser}
          handleSet={handleSetField}
          name="firstName"
          rules={[
            () => ({
              validator(_, value) {
                return autoCompleteFieldRule({
                  value,
                  isRequired: isCompanySelected,
                  name: "First name",
                  isMax: true,
                });
              },
            }),
          ]}
          placeholder="First Name of the user at Enterprise to Invite"
          labelProp={"firstName"}
          valueProp={"id"}
        />
        <div className="invite-advised-party-wrapper">
          <CustomUserAutoComplete
            options={isMembersOptionsVisible ? companyMembersFiltered : []}
            isTriggered
            disabled={isUserSelected}
            setSearch={handleUserSearch}
            onOptionSelect={handleSetUser}
            handleSet={handleSetField}
            name="lastName"
            rules={[
              () => ({
                validator(_, value) {
                  return autoCompleteFieldRule({
                    value,
                    isRequired: isCompanySelected,
                    name: "Last name",
                    isMax: true,
                  });
                },
              }),
            ]}
            placeholder="Last Name of the user at Enterprise to Invite"
            labelProp={"lastName"}
            valueProp={"id"}
          />
          <ButtonComponent disabled={!isCompanySelected} text="+" />
          {/* <p className="ant-form-item-explain-error">{memberError}</p> */}
        </div>
      </Form>
      <div className="selected-individuals-wrapper">
        {selectedIndividuals.map((item, index) => memberBox(item, index))}
      </div>
      <div className="Add-individual-button">
        <ButtonComponent
          text={"Add +"}
          isLoading={isLoading}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default IndividualTab;
