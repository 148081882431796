import _ from "lodash";
import { DEFAULT_PROFILE_COLOR } from "../constants";

// ADDRESSES
export const manipulateAddressData = (data) => {
  if (_.isEmpty(data)) return {};
  let address = {};

  address["id"] = data.id ?? 1;
  address["name"] = data.branchName ?? "";
  address["address"] = data.address ?? "";
  address["address2"] = data.address2 ?? null;
  address["state"] = data?.state ?? "";
  address["city"] = data?.city ?? "";
  address["zip"] = data?.zip ?? "";
  address["country"] = data?.country ?? "";
  address["branchName"] = data?.branchName ?? "";
  address["completeAddress"] =
    `${data?.address}, ${data?.address2 ? data?.address2 + ", " : ""}${
      data?.city
    }, ${data?.state}, ${data?.zip}, ${data?.country}.` ?? "";

  return address;
};
export const manipulateAddressList = (data) => {
  let addresses = [];
  data.forEach((item, index) => {
    addresses.push(manipulateAddressData(item));
  });
  return addresses;
};

// TEAM MEMBERS
export const manipulateTeamMemberData = (data) => {
  if (_.isEmpty(data)) return {};
  let member = {};

  member["id"] = data.id ?? 1;
  member["username"] = data.firstName?.trim() + " " + data.lastName?.trim();
  member["firstName"] = data?.firstName ?? "";
  member["lastName"] = data?.lastName ?? "";
  member["thumb"] = data.image;
  member["profilebg"] = data?.bgcolor ?? DEFAULT_PROFILE_COLOR;
  member["branch"] = data.companyAddress?.id;
  member["email"] = data?.email ?? "";
  member["phone"] = data?.phone ?? "";
  member["jobTitle"] = data?.jobTitle ?? "";
  member["branchName"] = data?.branchName ?? "";
  member["status"] = data?.isActive ? "Active" : "Inactive";
  member["active"] = data?.isActive ? 1 : 2;
  member["role"] = data?.isAdmin ? "Admin" : "Member";
  member["isAdmin"] = data?.isAdmin ?? false;
  member["isActive"] = data?.isActive && !data?.isBlock;
  member["isBlocked"] = data?.isBlock ?? false;

  return member;
};
export const manipulateTeamMembersList = (data, sortByMail) => {
  let members = [];
  data.forEach((item, index) => {
    members.push(manipulateTeamMemberData(item));
  });
  // if (sortByMail) {
  //   members.sort(function (a, b) {
  //     return a.email.localeCompare(b.email);
  //   });
  // }
  return members;
};

export const manipulatecompanyProfileData = (data) => {
  let company = {};
  company["facebook"] = data?.facebook ?? "";
  company["twitter"] = data?.twitter ?? "";
  company["linkedIn"] = data?.linkedIn ?? "";
  company["website"] = data?.website ?? "";
  company["logo"] = data?.logo ?? "";
  company["name"] = data?.name ?? "";
  company["phone"] = data?.workPhone ?? "";
  company["id"] = data?.id ?? 0;
  company["address"] = manipulateAddressData(data?.addresses?.[0]) ?? "";
  company["isAdmin"] = data?.isAdmin ?? false;

  return company;
};
