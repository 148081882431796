import React, { useState, useEffect } from "react";
import {
  CustomInfiniteScroll,
  EmptyBox,
  CardLoader,
} from "../../../../../../../components";
import { useSelector } from "react-redux";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { getFoldersRequest } from "../../../../../../../redux/slicers/transactiondataroom";
import FolderCard from "../folderCard";
import { DATA_PER_PAGE_LIMIT } from "../../../../../../../constants";

function DataRoomFolders({
  isDataUpdated,
  slug,
  roomslug,
  searchText,
  minheight,
  maxheight,
  isFolder,
  isRoomOwner,
  onFolderMove,
  isTransactionComplete,
  isEditAllowed,
}) {
  // STATES
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [isMoreData, setMoreData] = useState(false);

  // REDUX DATA
  const { folders } = useSelector((state) => state.transactiondataroom);

  // CUSTOM DISPATCH
  const [getFolders, foldersLoading] = useCustomDispatch(getFoldersRequest);

  //CONST VALS
  const isLoading = foldersLoading && !isMoreData;

  // HELPERS
  const getFoldersHelper = () => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: offset,
      search: searchText,
      isDataRoom: isFolder ? false : true,
    };
    getFolders({
      pathParams: slug,
      queryParams,
      logic({ total }) {
        setTotal(total);
      },
    });
  };

  // HOOKS
  useEffect(() => {
    getFoldersHelper();
  }, [isDataUpdated, slug, searchText, isFolder]);

  //HANDLERS
  const handleNextData = () => {
    setOffset(offset + DATA_PER_PAGE_LIMIT);
    setMoreData(true);
  };
  return (
    <div className="room-detail-folder-wrapper">
      <h3 className="card-title m-0">Folders</h3>
      {isLoading ? (
        <CardLoader height={minheight} />
      ) : (
        <CustomInfiniteScroll
          dataLength={folders.length}
          total={total}
          next={handleNextData}
          maxHeight={maxheight}
          minHeight={0}
          isWholePage={false}
          isPercent={true}
        >
          {folders.length >= 1 ? (
            folders.map((item, index) => (
              <FolderCard
                key={index}
                data={item}
                parentslug={slug}
                roomslug={roomslug}
                isRoomOwner={isRoomOwner}
                isGreaterLength={folders.length === 1}
                onDelete={() => setTotal(total - 1)}
                onFolderMove={onFolderMove}
                isTransactionComplete={isTransactionComplete}
                isEditAllowed={isEditAllowed}
              />
            ))
          ) : (
            <EmptyBox height={minheight} imgwidth={80} size={14} />
          )}
        </CustomInfiniteScroll>
      )}
    </div>
  );
}

export default DataRoomFolders;
