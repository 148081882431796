import React, { useState, useMemo, useEffect } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Form, Modal, Select } from "antd";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import {
  getDataRoomsFoldersRequest,
  moveFolderRequest,
  moveDocumentRequest,
} from "../../../../../../../redux/slicers/transactiondataroom";
import {
  inputFieldRule,
  toastAlert,
} from "../../../../../../../services/utils";
import { ButtonComponent } from "../../../../../../../components";
import "./styles.scss";

function MoveFileModal({
  handleClose,
  preview,
  roomslug,
  slug,
  parentslug,
  isFolder,
  handleSuccess = () => {},
  lastUpdated,
}) {
  //STATES
  const [isButtonDisabled, setButtonDisabled] = useState(true);

  // REDUX DATA
  const { roomsfolders } = useSelector((state) => state.transactiondataroom);

  // CUSTOM DISPATCH
  const [getDataRoomsFolders, isDataLoading] = useCustomDispatch(
    getDataRoomsFoldersRequest
  );
  const [moveFolder, folderLoading] = useCustomDispatch(moveFolderRequest);
  const [moveDocument, documentLoading] =
    useCustomDispatch(moveDocumentRequest);

  //CONST VALS
  const [form] = Form.useForm();
  const isLoading = folderLoading || documentLoading;
  const folders = useMemo(
    () =>
      roomsfolders?.filter(
        (obj) => obj.slug !== slug && obj.slug !== parentslug
      ),
    [roomsfolders, slug, parentslug]
  );
  // HELPERS
  const requestHelper = (request, payload) => {
    request({
      payload,
      pathParams: slug,
      logic({ message }) {
        toastAlert(message);
        closeHandler();
        handleSuccess();
      },
    });
  };

  // HOOKS
  useEffect(() => {
    if (preview) {
      getDataRoomsFolders({
        queryParams: { folder: slug },
        pathParams: roomslug,
      });
    }
  }, [preview]);

  //HANDLERS
  const closeHandler = () => {
    handleClose();
    form.resetFields();
    setButtonDisabled(true);
  };
  const setButtonDisabledHandler = () => {
    setButtonDisabled(false);
  };
  const handleFormFinish = (values) => {
    const payload = {};
    payload["isDataRoom"] = roomsfolders.find(
      (x) => x.uuid === values.id
    )?.isDataRoom;
    payload["id"] = roomsfolders.find((x) => x.uuid === values.id)?.id;
    requestHelper(isFolder ? moveFolder : moveDocument, payload);
  };
  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={490}
      className="move-file-modal">
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>Move {isFolder ? "Folder" : "File"}</h3>
            <p>Last Modified on {lastUpdated}</p>
          </div>
          <Form
            form={form}
            className="form"
            onFinish={handleFormFinish}
            onFieldsChange={setButtonDisabledHandler}>
            <Form.Item
              name="id"
              rules={inputFieldRule({ name: "folder", isWhiteSpace: false })}>
              <Select
                placeholder={`Move ${isFolder ? "Folder" : "File"}`}
                loading={isDataLoading}
                optionLabelProp="label"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }>
                {folders?.map((item, index) => (
                  <Select.Option
                    key={index}
                    value={item.uuid}
                    label={item.name}>
                    <h4 className="file-title">
                      {item.isDataRoom ? `Main Room (${item.name})` : item.name}
                    </h4>
                    <p className="file-path">
                      {item.isDataRoom ? "" : item.path}
                    </p>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <div className="create-button">
              <ButtonComponent
                disabled={isButtonDisabled}
                isLoading={isLoading}
                text={"Move"}
              />
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
}

export default MoveFileModal;
