import {
  Home,
  ContactUs,
  Dashboard,
  SignIn,
  SignUp,
  ForgotPass,
  ResetPassword,
  Transaction,
  Messages,
  Resources,
  Account,
  EditAccount,
  TransactionDetail,
  TransactionAdmin,
  EnterpriseTeamManagement,
  EnterpriseAdmin,
  EnterpriseSetting,
  CreateTransaction,
} from "../modules";
import { replaceValInString } from "../services/utils";
import Images from "../themes/images";

export const ALERT_TIMEOUT = 3000;
export const DEV_ENV = "dev";
export const PROD_ENV = "prod";
export const API_LOG = process.env.REACT_APP_ENV === DEV_ENV;
export const API_TIMEOUT = 30000;
export const CUSTOM_ALL = {
  display: "all",
  email: "",
  fullName: "all",
  id: "all",
  isAdmin: false,
  isLeaved: false,
  isRemoved: false,
};
// DATE FORMATS
const CURRENT_YEAR = new Date().getFullYear();
export const DATE_FORMAT1 = "MMMM DD, YYYY";
export const DATE_FORMAT2 = "DD, MMM YYYY";
export const DATE_FORMAT3 = "YYYY-MM-DD";
export const DATE_FORMAT4 = "DD-MM-YYYY";
export const DATE_FORMAT5 = "MMM D, YYYY";
export const DATE_FORMAT6 = "DD/MM/YY";
export const DATE_FORMAT7 = "DD MMM, YYYY";
export const TIME_FORMAT1 = "hh:mm a";
export const TIME_FORMAT2 = "hh:mm";
export const TIME_FORMAT3 = "hh";
export const DATETIME_FORMAT1 = "DD-MM-YYYY hh:mm a";
export const DATETIME_FORMAT2 = "MMM DD, YYYY hh:mm A zz";
export const DATETIME_FORMAT3 = "MMM DD, YYYY hh:mm";
export const DATETIME_FORMAT4 = "MMM DD, YYYY hh:mm A";

export const DEFAULT_PROFILE_COLOR = "#6f7ced";
export const NO_DATA_FOUND_ERROR = "This item no more exists.";
export const IMAGE_HOST = "https://dowabh634chzq.cloudfront.net/";
export const DEFAULT_COMPANY_LOGO = IMAGE_HOST + "enterprise_default_logo.png";
export const DOCUMENTS_PREFIX = "documents";
export const ENTERPIRSE_PREFIX = "enterprises";
export const USERS_PREFIX = "users";

export const COPYRIGHTSTRING = `© ${CURRENT_YEAR} DealTru. All rights reserved`;

export const PASSWORD = {
  PASSWORD_ICON: <Images.Pass className="input-icon" />,
  CONFIRM_PASS_ICON: <Images.Pass className="input-icon" />,
  CONFIRM_PASS_PLACEHOLDER: "Enter your Confirm Password",
  OLD_PASS_ERROR: "Current Password",
  NEW_PASS_ERROR: "New Password",
  PASSWORD_PLACEHOLDER: "Enter your Password",
  CONFIRM_PASS_NAME: "confirmPassword",
  PASSWORD_NAME: "password",
  CONFIRM_PASS_ERROR: "Confirm Password",
  CONFIRM_NEW_PASS_ERROR: "Confirm New Password",
  PASSWORD_ERROR: "Password",
  INVALID_STRONG_PASSWORD:
    "Password must contain 8 characters including 1 small letter, 1 capital letter, 1 digit and 1 special character!",
};

// CHUNK OF DATA COMING FROM API
export const DATA_PER_PAGE_LIMIT = 20;

// VALIDATION REGEX
export const ALLOW_ALPHABETS_REGEX = new RegExp(/^[a-zA-Z\s]*$/); // allow only alphabets and white spaces
// export const ALLOW_ALPHABETS_REGEX = new RegExp(/^[a-zA-ZÀ-ÿ\s]*$/); // allow only alphabets and latin and white spaces
export const ALLOW_ALPHANUM_REGEX = new RegExp(/^[a-zA-Z0-9]*$/); // allow only alphabets and numbers

// ROLES OF TRANSACTION
export const ROLE_TYPES = ["party", "advisor"];

// TRANSACTION TABS SLUG
export const TRANSACTION_TABS_SLUGS = {
  CHECKLIST: "checklists",
  DELETED_CHECKLIST: "deleted-checklists",
  DOCUMENTS: "documents",
  DELETED_DOCUMENTS: "deleted-documents",
  MILESTONES: "milestones",
  MESSAGES: "chat",
  DATAROOMS: "datarooms",
  SETTING: "transaction-setting",
  ADMIN: "transaction-admin",
};
export const TRANSACTION_LINK_DATAROOM_SLUG = "external-links-room";

// ROOM ACCESS TYPES
export const ROOM_ACCESS_TYPES = {
  DELETED: "deleted",
  REMOVED: "removed",
  PENDING: "pending",
};

// DASHOBOARD PAGES CONFIG
export const ACCESS_TYPES = {
  PUBLIC: "public",
  PRIVATE: "private",
  AUTH: "auth",
  ENTERPRISE_ADMIN: "enterprise-admin",
};

export const ANTD_SCROLL_TO_ERROR_FIELDS_CONFIG = {
  behavior: "smooth",
  block: "center",
  inline: "center",
};

export const DOC_BUTTONS_LIST = [
  { text: "Documents", id: 0 },
  { text: "Deleted Documents", id: 1 },
];

export const DOC_UPLOAD_TYPES_LIST = {
  resource: "Resource",
  computer: "Computer",
  documents: "Documents",
};
export const FILE_TYPES = [
  "doc",
  "docx",
  "odt",
  "pdf",
  "txt",
  "rtf",
  "wps",
  "wkf",
  "wks",
  "wpd",
  "png",
  "jpg",
  "jpeg",
  "ppt",
  "pptx",
  "xls",
  "xlsx",
  "tex",
];
export const TRANSACTION_ROLE_TYPE = [
  { label: "Party To Transaction", value: false },
  { label: "Advisor To Party", value: true },
];

export const SELECT_DOCUMENT_TYPE = [
  { label: "Resources", value: "res" },
  { label: "Documents", value: "doc" },
];

export const TRANSACTIONS_STATUS_LIST = [
  { text: "All", id: null },
  { text: "Pending", id: false },
  { text: "Completed", id: true },
];

export const TRANSACTION_ADMIN_OPTIONS = [
  { text: "Transaction Admin", id: TRANSACTION_TABS_SLUGS.ADMIN },
  { text: "Transaction Settings", id: TRANSACTION_TABS_SLUGS.SETTING },
];

export const TRANSACTIONDETAIL_STATUS_FILTERS = [
  {
    value: null,
    text: "All",
  },
  {
    value: false,
    text: "Pending",
  },
  {
    value: true,
    text: "Completed",
  },
];
export const TRANSACTIONADMIN_PAGE_TABS = [
  { text: "Transaction", id: "transaction" },
  { text: "Breakout Room", id: "breakout" },
  { text: "Milestone", id: "milestone" },
  { text: "Document", id: "document" },
  { text: "Checklist", id: "checklist" },
  { text: "Data Room", id: "dataroom" },
];

export const UPLOAD_DOCUMENT_TABS = [
  { text: "Create New Exhibit", id: 1 },
  { text: "Create new version of", subtext: "Document or Exhibit", id: 2 },
];

export const DOCUMENT_DETAIL_TABS = [
  { text: "Exhibits", id: 1 },
  { text: "Versions", id: 2 },
];

export const STATUS_FILTER_TABS = [
  { text: "Active", id: true },
  { text: "Inactive", id: false },
  { text: "Invited", id: "invited" },
];

export const CHAT_TABS = [
  { text: "Private Chat", id: false },
  { text: "Group Chat", id: true },
];

export const TWO_FACTOR_KEYS = {
  RESET: "reset",
  LOGIN: "login",
  CHANGE: "change",
  TFA: "tfa",
  PHONE: "changePhone",
  WORKPHONE: "changeWorkPhone",
  RESEND: "resend",
};

// ALERT CONFIG
export const ALERT_POSITIONS = {
  TOP_RIGHT: "top-right",
  TOP_LEFT: "top-left",
  TOP_CENTER: "top-center",
  BOTTOM_RIGHT: "bottom-right",
  BOTTOM_LEFT: "bottom-left",
  BOTTOM_CENTER: "bottom-center",
};

export const ALERT_THEMES = {
  DARK: "dark",
  COLORED: "colored",
  LIGHT: "light",
};

export const ALERT_TYPES = {
  INFO: "info",
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  DEFAULT: "default",
};

// AUTH ROUTE
export const HOME_ROUTE = "/";
export const CONTACTUS_ROUTE = "/contact-us";
export const LOGIN_ROUTE = "/login";
export const LOGIN_INVITE_ROUTE = "/login/transactioninvite/:token";
export const SIGNUP_ROUTE = "/signup";
export const SIGNUP_ENTERPRISEINVITE_ROUTE = "/signup/enterpriseinvite/:token";
export const SIGNUP_TRANSACTIONINVITE_ROUTE =
  "/signup/transactioninvite/:token";
export const FORGETPASS_ROUTE = "/forget-password";
export const EMAILVERIFICATION_ROUTE = "/email-verification";
export const RESETPASS_ROUTE = "/reset-password/:token";

// PRIVATE ROUTE
export const DASHBOARD_ROUTE = "/dashboard";
export const DASHBOARD_TRANSACTION_INVITE_ROUTE = `${DASHBOARD_ROUTE}/invite/:token`;
export const MESSAGES_ROUTE = "/messages/:chat_id";
export const MESSAGE_ROUTE = "/messages";
export const GROUP_MESSAGE_ROUTE = "/messages/group";
export const GROUP_MESSAGES_ROUTE = "/messages/group/:chat_id";
export const RESOURCES_ROUTE = "/resources";
export const RESOURCES_FOLDER_ROUTE = "/resources/:folder";
export const ENTERPRISE_SETTING_ROUTE = "/enterprise-setting";
export const ENTERPRISE_ADMIN_ROUTE = "/enterprise-admins";
export const ENTERPRISE_TEAM_MANAGEMENT_ROUTE = "/team-management";
export const ENTERPRISE_PENDINGREQUESTS_ROUTE = "/pending-requests";
export const TRANSACTION_SETTINGS_ROUTE = "/transaction-admin";
export const ACCOUNT_ROUTE = "/account";
export const EDIT_ACCOUNT_ROUTE = "/edit-account";
export const TRANSACTION_ROUTE = "/transactions";
export const CREATE_TRANSACTION_ROUTE = `/create-transaction`;
export const EDIT_TRANSACTION_ROUTE = `/edit-transaction/`;
export const TRANSACTION_DETAIL_ROUTE = "/transaction/:id/:room";
export const TRANSACTION_TABS_ROUTE = `${TRANSACTION_DETAIL_ROUTE}/:tab`;
export const TRANSACTION_DATAROOM_DETAIL_ROUTE = `${TRANSACTION_TABS_ROUTE}/:dataid`;
export const TRANSACTION_DATAROOM_FOLDER_DETAIL_ROUTE = `${TRANSACTION_DATAROOM_DETAIL_ROUTE}/:folder`;
export const TRANSACTION_DOCUMENT_DETAIL_ROUTE = `${TRANSACTION_TABS_ROUTE}/:dataid`;
export const TRANSACTION_CHECKLIST_DETAIL_ROUTE = `${TRANSACTION_TABS_ROUTE}/:dataid`;

// PAGES ROUTES
export const PAGE_ROUTES = [
  // PUBLIC ROUTE
  {
    // title: "",
    route: HOME_ROUTE,
    access: ACCESS_TYPES.PUBLIC,
    component: <Home />,
  },
  {
    title: "Contact Us",
    route: CONTACTUS_ROUTE,
    access: ACCESS_TYPES.PUBLIC,
    component: <ContactUs />,
  },
  // PRIVATE ROUTE
  {
    title: "Dashboard",
    route: [DASHBOARD_ROUTE, DASHBOARD_TRANSACTION_INVITE_ROUTE],
    access: ACCESS_TYPES.PRIVATE,
    component: <Dashboard />,
  },
  {
    title: "My Transactions",
    route: TRANSACTION_ROUTE,
    access: ACCESS_TYPES.PRIVATE,
    component: <Transaction />,
  },
  {
    title: "Messages",
    route: [
      MESSAGES_ROUTE,
      GROUP_MESSAGES_ROUTE,
      GROUP_MESSAGE_ROUTE,
      MESSAGE_ROUTE,
    ],
    access: ACCESS_TYPES.PRIVATE,
    component: <Messages />,
  },
  {
    title: "Resources",
    route: [RESOURCES_ROUTE, RESOURCES_FOLDER_ROUTE],
    access: ACCESS_TYPES.PRIVATE,
    component: <Resources />,
  },
  {
    title: "Transaction Admin Settings",
    route: TRANSACTION_SETTINGS_ROUTE,
    access: ACCESS_TYPES.PRIVATE,
    component: <TransactionAdmin />,
  },
  {
    title: "Enterprise Setting",
    route: ENTERPRISE_SETTING_ROUTE,
    access: ACCESS_TYPES.ENTERPRISE_ADMIN,
    component: <EnterpriseSetting />,
  },
  {
    title: "Enterprise Admin",
    route: ENTERPRISE_ADMIN_ROUTE,
    access: ACCESS_TYPES.PRIVATE,
    component: <EnterpriseAdmin />,
  },
  {
    title: "Team Management",
    route: ENTERPRISE_TEAM_MANAGEMENT_ROUTE,
    access: ACCESS_TYPES.ENTERPRISE_ADMIN,
    component: <EnterpriseTeamManagement />,
  },
  {
    title: "Pending Requests",
    route: ENTERPRISE_PENDINGREQUESTS_ROUTE,
    access: ACCESS_TYPES.ENTERPRISE_ADMIN,
    component: <EnterpriseTeamManagement />,
  },
  {
    title: "Account",
    route: ACCOUNT_ROUTE,
    access: ACCESS_TYPES.PRIVATE,
    component: <Account />,
  },
  {
    title: "Edit Account",
    route: EDIT_ACCOUNT_ROUTE,
    access: ACCESS_TYPES.PRIVATE,
    component: <EditAccount />,
  },
  {
    title: "Transaction Detail",
    route: [
      TRANSACTION_DETAIL_ROUTE,
      TRANSACTION_TABS_ROUTE,
      TRANSACTION_DATAROOM_DETAIL_ROUTE,
      TRANSACTION_DATAROOM_FOLDER_DETAIL_ROUTE,
      TRANSACTION_DOCUMENT_DETAIL_ROUTE,
      TRANSACTION_CHECKLIST_DETAIL_ROUTE,
    ],
    access: ACCESS_TYPES.PRIVATE,
    component: <TransactionDetail />,
  },
  {
    title: "Create Transaction",
    route: CREATE_TRANSACTION_ROUTE,
    access: ACCESS_TYPES.PRIVATE,
    component: <CreateTransaction />,
  },
  {
    title: "Edit Transaction",
    route: EDIT_TRANSACTION_ROUTE + ":slug",
    access: ACCESS_TYPES.PRIVATE,
    component: <CreateTransaction />,
  },
  // AUTH ROUTE
  {
    title: "Login",
    route: [LOGIN_ROUTE, LOGIN_INVITE_ROUTE],
    access: ACCESS_TYPES.AUTH,
    component: <SignIn />,
  },
  {
    title: "Signup",
    route: [
      SIGNUP_ROUTE,
      SIGNUP_ENTERPRISEINVITE_ROUTE,
      SIGNUP_TRANSACTIONINVITE_ROUTE,
    ],
    access: ACCESS_TYPES.AUTH,
    component: <SignUp />,
  },
  {
    title: "Login",
    route: LOGIN_INVITE_ROUTE,
    access: ACCESS_TYPES.AUTH,
    component: <SignIn />,
  },
  {
    title: "Forgot Password",
    route: FORGETPASS_ROUTE,
    access: ACCESS_TYPES.AUTH,
    component: <ForgotPass />,
  },
  {
    title: "Reset Password",
    route: RESETPASS_ROUTE,
    access: ACCESS_TYPES.AUTH,
    component: <ResetPassword />,
  },
];

// PARTICIPANT SIDEBAR LINKS
export const SIDEBAR_LINKS = [
  {
    title: "Dashboard",
    icon: <Images.Dashboard />,
    route: DASHBOARD_ROUTE,
  },
  {
    title: "My Transactions",
    icon: <Images.Transaction />,
    route: TRANSACTION_ROUTE,
    isMyTransaction: true,
  },
  {
    title: "Messages",
    icon: <Images.Message />,
    route: MESSAGE_ROUTE,
    isMessage: true,
  },
  {
    title: "Resources",
    icon: <Images.Resources />,
    route: RESOURCES_ROUTE,
  },
  {
    title: "Transaction Admin",
    icon: <Images.EnterPriseSetting />,
    route: TRANSACTION_SETTINGS_ROUTE,
  },
  {
    title: "",
    route: ENTERPRISE_ADMIN_ROUTE,
    isUserCompany: true,
  },
];

// ENTERPRISE SIDEBAR LINKS
export const ENTERPRISE_SIDEBAR_LINKS = [
  {
    title: "Dashboard",
    icon: <Images.Dashboard />,
    route: DASHBOARD_ROUTE,
  },
  {
    title: "My Transactions",
    icon: <Images.Transaction />,
    route: TRANSACTION_ROUTE,
    isMyTransaction: true,
  },
  {
    title: "Messages",
    icon: <Images.Message />,
    route: MESSAGE_ROUTE,
    isMessage: true,
  },
  {
    title: "Resources",
    icon: <Images.Resources />,
    route: RESOURCES_ROUTE,
  },
  {
    title: "Transaction Admin",
    icon: <Images.EnterPriseSetting />,
    route: TRANSACTION_SETTINGS_ROUTE,
  },
  {
    title: "",
    route: ENTERPRISE_SETTING_ROUTE,
    isUserCompany: true,
  },
];

// ACTIVITY LOGS ROUTES
export const NAVIGATION_ROUTES = {
  BLOCKED: {
    route: null,
    isBlocked: true,
    deletedkeys: [],
  },
  INVITATION: {
    route: null,
    deletedkeys: [],
    isInvite: true,
    // isBlocked: true,
  },
  TRANSACTION_ROOM: {
    route: TRANSACTION_DETAIL_ROUTE,
    deletedkeys: ["transaction", "room"],
    slugkeys: {
      ":id": "transaction.slug",
      ":room": "room.slug",
    },
  },
  MILESTONE: {
    route: "slug",
    deletedkeys: ["transaction", "room", "milestone"],
    isMilestone: true,
    slugkeys: {
      slug: "milestone.slug",
    },
  },
  DATAROOM: {
    route: replaceValInString(TRANSACTION_DATAROOM_DETAIL_ROUTE, {
      ":tab": TRANSACTION_TABS_SLUGS.DATAROOMS,
    }),
    deletedkeys: ["transaction", "dataroom"],
    slugkeys: {
      ":id": "transaction.slug",
      ":room": "room.slug",
      ":dataid": "dataroom.slug",
    },
  },
  DATAROOM_NOTE: {
    route: replaceValInString(TRANSACTION_DATAROOM_DETAIL_ROUTE, {
      ":tab": TRANSACTION_TABS_SLUGS.DATAROOMS,
    }),
    deletedkeys: ["transaction", "dataroom", "DataRoomNotes"],
    slugkeys: {
      ":id": "transaction.slug",
      ":room": "room.slug",
      ":dataid": "dataroom.slug",
    },
  },
  DATAROOM_FILE: {
    route: replaceValInString(TRANSACTION_DATAROOM_DETAIL_ROUTE, {
      ":tab": TRANSACTION_TABS_SLUGS.DATAROOMS,
    }),
    deletedkeys: ["transaction", "dataroom", "DataRoomFiles"],
    slugkeys: {
      ":id": "transaction.slug",
      ":room": "room.slug",
      ":dataid": "dataroom.slug",
    },
  },
  DATAROOM_LINK: {
    route: replaceValInString(TRANSACTION_DATAROOM_DETAIL_ROUTE, {
      ":tab": TRANSACTION_TABS_SLUGS.DATAROOMS,
      ":dataid": TRANSACTION_LINK_DATAROOM_SLUG,
    }),
    deletedkeys: ["transaction", "link"],
    slugkeys: {
      ":id": "transaction.slug",
      ":room": "room.slug",
    },
  },
  DATAROOM_LINK_NOTE: {
    route: replaceValInString(TRANSACTION_DATAROOM_DETAIL_ROUTE, {
      ":tab": TRANSACTION_TABS_SLUGS.DATAROOMS,
      ":dataid": TRANSACTION_LINK_DATAROOM_SLUG,
    }),
    deletedkeys: ["transaction", "linkNotes"],
    slugkeys: {
      ":id": "transaction.slug",
      ":room": "room.slug",
    },
  },
  DATAROOM_FOLDER: {
    route: replaceValInString(TRANSACTION_DATAROOM_FOLDER_DETAIL_ROUTE, {
      ":tab": TRANSACTION_TABS_SLUGS.DATAROOMS,
    }),
    deletedkeys: ["transaction", "dataroom", "dataroomFolder"],
    slugkeys: {
      ":id": "transaction.slug",
      ":room": "room.slug",
      ":dataid": "dataroom.slug",
      ":folder": "dataroomFolder.slug",
    },
  },
  DATAROOM_FOLDER_FILE: {
    route: replaceValInString(TRANSACTION_DATAROOM_FOLDER_DETAIL_ROUTE, {
      ":tab": TRANSACTION_TABS_SLUGS.DATAROOMS,
    }),
    deletedkeys: ["transaction", "dataroom", "dataroomFolder", "DataRoomFiles"],
    slugkeys: {
      ":id": "transaction.slug",
      ":room": "room.slug",
      ":dataid": "dataroom.slug",
      ":folder": "dataroomFolder.slug",
    },
  },
  DATAROOM_FOLDER_NOTE: {
    route: replaceValInString(TRANSACTION_DATAROOM_FOLDER_DETAIL_ROUTE, {
      ":tab": TRANSACTION_TABS_SLUGS.DATAROOMS,
    }),
    deletedkeys: ["transaction", "dataroom", "dataroomFolder", "DataRoomNotes"],
    slugkeys: {
      ":id": "transaction.slug",
      ":room": "room.slug",
      ":dataid": "dataroom.slug",
      ":folder": "dataroomFolder.slug",
    },
  },
  DOCUMENT: {
    route: replaceValInString(TRANSACTION_DATAROOM_DETAIL_ROUTE, {
      ":tab": TRANSACTION_TABS_SLUGS.DOCUMENTS,
    }),
    deletedkeys: ["transaction", "room", "document"],
    slugkeys: {
      ":id": "transaction.slug",
      ":room": "room.slug",
      ":dataid": "document.slug",
    },
  },
  DOCUMENT_EXHIBIT: {
    route: replaceValInString(TRANSACTION_DATAROOM_DETAIL_ROUTE, {
      ":tab": TRANSACTION_TABS_SLUGS.DOCUMENTS,
    }),
    deletedkeys: ["transaction", "room", "document", "documentExhibit"],
    slugkeys: {
      ":id": "transaction.slug",
      ":room": "room.slug",
      ":dataid": "document.slug",
    },
  },
  DOCUMENT_VERSION: {
    route: replaceValInString(TRANSACTION_DATAROOM_DETAIL_ROUTE, {
      ":tab": TRANSACTION_TABS_SLUGS.DOCUMENTS,
    }),
    deletedkeys: ["transaction", "room", "document", "documentVersion"],
    slugkeys: {
      ":id": "transaction.slug",
      ":room": "room.slug",
      ":dataid": "document.slug",
    },
  },
  DOCUMENT_EXHIBIT_VERSION: {
    route: replaceValInString(TRANSACTION_DATAROOM_DETAIL_ROUTE, {
      ":tab": TRANSACTION_TABS_SLUGS.DOCUMENTS,
    }),
    deletedkeys: ["transaction", "room", "document", "documentExhibitVersion"],
    slugkeys: {
      ":id": "transaction.slug",
      ":room": "room.slug",
      ":dataid": "document.slug",
    },
  },
  CHECKLIST: {
    route: replaceValInString(TRANSACTION_DATAROOM_DETAIL_ROUTE, {
      ":tab": TRANSACTION_TABS_SLUGS.CHECKLIST,
    }),
    deletedkeys: ["transaction", "room", "checklist"],
    slugkeys: {
      ":id": "transaction.slug",
      ":room": "room.slug",
      ":dataid": "checklist.slug",
    },
  },
  JOIN_ENTERPRISE: {
    route: ENTERPRISE_PENDINGREQUESTS_ROUTE,
    deletedkeys: [],
  },
  ENTERPRISE_DASHBOARD: {
    route: ENTERPRISE_TEAM_MANAGEMENT_ROUTE,
    adminRoute: ENTERPRISE_SETTING_ROUTE,
    isAdminCondition: true,
    deletedkeys: [],
  },
  ENTERPRISE_ADMIN: {
    route: ENTERPRISE_ADMIN_ROUTE,
    deletedkeys: [],
  },
};

// ACTIVITY LOGS TYPES
export const ACTIVITY_LOGS_TYPES = {
  //ACTIVITY LOGS
  transactionCreated: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  transactionUpdated: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  transactionMarkAsComplete: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  transactionMarkAsPending: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  transactionInvite: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  transactionInviteAccept: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  transactionInviteReject: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  transactionMakeAdmin: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  transactionRemoveAdmin: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  transactionRemoveAdminHimself: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  breakoutRoomCreated: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  breakoutRoomUpdated: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  breakoutRoomLeave: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  breakoutRoomMakeAdmin: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  breakoutRoomRemoveAdmin: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  breakoutRoomRemoveAdminHimself: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  breakoutRoomRemoveMember: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  breakoutRoomAddMember: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  milestoneRemoveVisibility: NAVIGATION_ROUTES.MILESTONE,
  milestoneAddVisibility: NAVIGATION_ROUTES.MILESTONE,
  milestoneRemoveResponsibleParty: NAVIGATION_ROUTES.MILESTONE,
  milestoneAddResponsibleParty: NAVIGATION_ROUTES.MILESTONE,
  milestoneMarkedAsPending: NAVIGATION_ROUTES.MILESTONE,
  milestoneMarkedAsCompleted: NAVIGATION_ROUTES.MILESTONE,
  milestoneDeleted: NAVIGATION_ROUTES.MILESTONE,
  milestoneUpdated: NAVIGATION_ROUTES.MILESTONE,
  milestoneCreated: NAVIGATION_ROUTES.MILESTONE,
  linkCreated: NAVIGATION_ROUTES.DATAROOM_LINK,
  linkUpdated: NAVIGATION_ROUTES.DATAROOM_LINK,
  linkDeleted: NAVIGATION_ROUTES.DATAROOM_LINK,
  linkNoteCreated: NAVIGATION_ROUTES.DATAROOM_LINK_NOTE,
  linkNoteUpdated: NAVIGATION_ROUTES.DATAROOM_LINK_NOTE,
  linkNoteDeleted: NAVIGATION_ROUTES.DATAROOM_LINK_NOTE,
  dataroomCreated: NAVIGATION_ROUTES.DATAROOM,
  dataroomUpdated: NAVIGATION_ROUTES.DATAROOM,
  dataroomDeleted: NAVIGATION_ROUTES.DATAROOM,
  dataroomInvited: NAVIGATION_ROUTES.DATAROOM,
  dataroomRemovedUser: NAVIGATION_ROUTES.DATAROOM,
  dataroomFolderCreated: NAVIGATION_ROUTES.DATAROOM_FOLDER,
  dataroomFolderUpdated: NAVIGATION_ROUTES.DATAROOM_FOLDER,
  dataroomFolderDeleted: NAVIGATION_ROUTES.DATAROOM_FOLDER,
  dataroomFolderInvited: NAVIGATION_ROUTES.DATAROOM_FOLDER,
  dataroomFolderRemoved: NAVIGATION_ROUTES.DATAROOM_FOLDER,
  dataroomFolderMovedToFolder: NAVIGATION_ROUTES.DATAROOM_FOLDER,
  dataroomFolderMovedToDataroom: NAVIGATION_ROUTES.DATAROOM_FOLDER,
  dataroomFileUploaded: NAVIGATION_ROUTES.DATAROOM,
  dataroomFileUpdated: NAVIGATION_ROUTES.DATAROOM,
  dataroomFileDeleted: NAVIGATION_ROUTES.DATAROOM,
  dataroomFileUploaded: NAVIGATION_ROUTES.DATAROOM_FILE,
  dataroomFileUpdated: NAVIGATION_ROUTES.DATAROOM_FILE,
  dataroomFileDeleted: NAVIGATION_ROUTES.DATAROOM_FILE,
  dataroomFolderFileUploaded: NAVIGATION_ROUTES.DATAROOM_FOLDER_FILE,
  dataroomFolderFileUpdated: NAVIGATION_ROUTES.DATAROOM_FOLDER_FILE,
  dataroomFolderFileDeleted: NAVIGATION_ROUTES.DATAROOM_FOLDER_FILE,
  dataroomFolderFileMovedToFolder: NAVIGATION_ROUTES.DATAROOM_FOLDER,
  dataroomFolderFileMovedToDataroom: NAVIGATION_ROUTES.DATAROOM,
  dataroomNoteCreated: NAVIGATION_ROUTES.DATAROOM_NOTE,
  dataroomFolderNoteCreated: NAVIGATION_ROUTES.DATAROOM_FOLDER_NOTE,
  dataroomNoteUpdated: NAVIGATION_ROUTES.DATAROOM_NOTE,
  dataroomFolderNoteUpdated: NAVIGATION_ROUTES.DATAROOM_FOLDER_NOTE,
  dataroomNoteDeleted: NAVIGATION_ROUTES.DATAROOM_NOTE,
  dataroomFolderNoteDeleted: NAVIGATION_ROUTES.DATAROOM_FOLDER_NOTE,
  dataroomAdminCreated: NAVIGATION_ROUTES.DATAROOM,
  dataroomAdminRemoved: NAVIGATION_ROUTES.DATAROOM,
  dataroomAdminOwnRemoved: NAVIGATION_ROUTES.DATAROOM,
  dataroomOwnRemoved: NAVIGATION_ROUTES.DATAROOM,
  dataroomFolderFileMovedFromDataroom: NAVIGATION_ROUTES.DATAROOM_FOLDER,
  dataroomFolderMovedFromDataroom: NAVIGATION_ROUTES.DATAROOM_FOLDER,
  documentCreated: NAVIGATION_ROUTES.DOCUMENT,
  documentUpdated: NAVIGATION_ROUTES.DOCUMENT,
  documentDeleted: NAVIGATION_ROUTES.DOCUMENT,
  documentVersionCreated: NAVIGATION_ROUTES.DOCUMENT_VERSION,
  documentVersionUpdated: NAVIGATION_ROUTES.DOCUMENT_VERSION,
  documentVersionDeleted: NAVIGATION_ROUTES.DOCUMENT_VERSION,
  documentExhibitCreated: NAVIGATION_ROUTES.DOCUMENT_EXHIBIT,
  documentExhibitUpdated: NAVIGATION_ROUTES.DOCUMENT_EXHIBIT,
  documentExhibitDeleted: NAVIGATION_ROUTES.DOCUMENT_EXHIBIT,
  documentExhibitExhibitedCreated: NAVIGATION_ROUTES.DOCUMENT_EXHIBIT,
  documentExhibitExhibitedUpdated: NAVIGATION_ROUTES.DOCUMENT_EXHIBIT,
  documentExhibitExhibitedDeleted: NAVIGATION_ROUTES.DOCUMENT_EXHIBIT,
  documentShared: NAVIGATION_ROUTES.DOCUMENT,
  documentExhibitedVersionCreated: NAVIGATION_ROUTES.DOCUMENT_EXHIBIT_VERSION,
  documentExhibitedVersionUpdated: NAVIGATION_ROUTES.DOCUMENT_EXHIBIT_VERSION,
  documentExhibitedVersionDeleted: NAVIGATION_ROUTES.DOCUMENT_EXHIBIT_VERSION,
  checklistCreated: NAVIGATION_ROUTES.CHECKLIST,
  checklistUpdated: NAVIGATION_ROUTES.CHECKLIST,
  checklistDeleted: NAVIGATION_ROUTES.CHECKLIST,
  checklistAddDocument: NAVIGATION_ROUTES.CHECKLIST,
  checklistRemoveDocument: NAVIGATION_ROUTES.CHECKLIST,
  checklistMarkAsComplete: NAVIGATION_ROUTES.CHECKLIST,
  checklistMarkAsPending: NAVIGATION_ROUTES.CHECKLIST,
  checklistAddVisibility: NAVIGATION_ROUTES.CHECKLIST,
  checklistRemoveVisibility: NAVIGATION_ROUTES.CHECKLIST,
  checklistAddResponsibility: NAVIGATION_ROUTES.CHECKLIST,
  checklistRemoveResponsibility: NAVIGATION_ROUTES.CHECKLIST,
  checklistRestored: NAVIGATION_ROUTES.CHECKLIST,

  //NOTIFICATIONS
  signupForDefaultCompany: NAVIGATION_ROUTES.JOIN_ENTERPRISE,
  makeEnterpriseAdmin: NAVIGATION_ROUTES.ENTERPRISE_ADMIN,
  removeEnterpriseAdmin: NAVIGATION_ROUTES.BLOCKED,
  makeEnterpriseParticipantNotify: NAVIGATION_ROUTES.ENTERPRISE_DASHBOARD,
  removeEnterpriseParticipantNotify: NAVIGATION_ROUTES.BLOCKED,
  transactionAdminInviteTransaction: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  transactionParticipantInviteTransaction: NAVIGATION_ROUTES.INVITATION,
  acceptInviteOfTransaction: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  notifyDocumentCreated: NAVIGATION_ROUTES.DOCUMENT,
  notifyExhibitCreated: NAVIGATION_ROUTES.DOCUMENT_EXHIBIT,
  notifyVersionCreated: NAVIGATION_ROUTES.DOCUMENT_VERSION,
  notifyShareVersion: NAVIGATION_ROUTES.DOCUMENT,
  notifyChecklistCreated: NAVIGATION_ROUTES.CHECKLIST,
  notifyChecklistDeleted: NAVIGATION_ROUTES.CHECKLIST,
  notifyChecklistCompleted: NAVIGATION_ROUTES.CHECKLIST,
  notifyChecklistPending: NAVIGATION_ROUTES.CHECKLIST,
  notifyResponsiblePartyRemoved: NAVIGATION_ROUTES.CHECKLIST,
  notifyTransactionMarkedAsCompleted: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  notifyTransactionMarkedAsPending: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  notifyMakeUserAdmin: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  notifyPersonMadeAdmin: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  notifyRemovedUserAdmin: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  notifyPersonRemovedAdmin: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  notifyRequestedParticipant: NAVIGATION_ROUTES.BLOCKED,
  notifyChangesMadeToRequestedParticipant: NAVIGATION_ROUTES.BLOCKED,
  notifyTransactionAndPartyAdmins: NAVIGATION_ROUTES.BLOCKED,
  notifyMilestoneCreated: NAVIGATION_ROUTES.MILESTONE,
  notifyMilestoneDeleted: NAVIGATION_ROUTES.MILESTONE,
  notifyMilestoneUserAddedResponsible: NAVIGATION_ROUTES.MILESTONE,
  notifyMilestoneUserRemovedResponsible: NAVIGATION_ROUTES.MILESTONE,
  notifyMilestoneMarkAsCompleted: NAVIGATION_ROUTES.MILESTONE,
  notifyMilestoneMarkAsPending: NAVIGATION_ROUTES.MILESTONE,
  notifyResponsiblePartyAddNewRes: NAVIGATION_ROUTES.CHECKLIST,
  notifiyBreakourRoomCreated: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  notifiyAboutNewMemberAddedToBreakoutRoom: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  notifyUserAboutHisAddInBreakoutRoom: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  notifyUserAboutHisRemovalFromBreakRoom: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  notifyTransactionAdminAboutUserRemoval: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  notifyMadeNewAdminToBreakoutRoomMembers: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  notifyBreakoutMemberAboutHimBeenAdmin: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  notifyBreakoutMemberAboutHimBeenRemovedAsAdmin:
    NAVIGATION_ROUTES.TRANSACTION_ROOM,
  notifyAdminRemovedToAllRoomParties: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  notifyMemberWhowantToRejoinRoom: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  notifySuspendedMemberFromRoom: NAVIGATION_ROUTES.BLOCKED,
  notifyRejoinRequestToRoomAdmin: NAVIGATION_ROUTES.TRANSACTION_ROOM,
  notifyRejectedParticipant: NAVIGATION_ROUTES.BLOCKED,
};

// PERMISSIONS

export const PERMISSIONS_CATEGORY = {
  TRANSACTION: "transaction",
  BREAKOUT: "breakout",
  MILESTONE: "milestone",
  DATAROOM: "dataroom",
  DOCUMENT: "document",
  CHECKLIST: "checklist",
};

export const PERMISSIONS_ACCESS = {
  //COMMON ROOM
  EDIT_TRANSACTION: "can_edit_transaction",
  UPDATE_TRANSACTION_STATUS: "can_change_transaction_status",
  INVITE_TO_TRANSACTION: "can_invite_in_transaction",
  TRANSACTION_ACTIVITY_LOGS: "can_view_transaction_activity_logs",
  //BREAKOUT ROOM
  EDIT_BREAKOUT_ROOM: "can_manage_breakout_room",
  //MILESTONE
  VIEW_MILESTONE: "can_view_milestone",
  CREATE_MILESTONE: "can_create_milestone",
  EDIT_MILESTONE: "can_edit_milestone",
  DELETE_MILESTONE: "can_delete_milestone",
  //DATAROOM
  VIEW_DATAROOM: "can_access_data_room",
  CREATE_DATAROOM: "can_create_data_room",
  //LINK
  VIEW_LINK: "can_view_link",
  MANAGE_LINK: "can_manage_link",
  //DOCUMENT
  VIEW_DOCS: "can_view_document",
  ADD_DOCS: "can_add_document",
  EDIT_DOCS: "can_edit_document",
  DELETE_DOCS: "can_delete_document",
  //CHECKLIST
  VIEW_CHECKLIST: "can_view_checklist",
  ADD_CHECKLIST: "can_add_checklist",
  EDIT_CHECKLIST: "can_edit_checklist",
  DELETE_CHECKLIST: "can_delete_checklist",
};

export const VIEW_PERMISSIONS = new Set([
  PERMISSIONS_ACCESS.VIEW_MILESTONE,
  PERMISSIONS_ACCESS.VIEW_DATAROOM,
  PERMISSIONS_ACCESS.VIEW_LINK,
  PERMISSIONS_ACCESS.VIEW_DOCS,
  PERMISSIONS_ACCESS.VIEW_CHECKLIST,
  PERMISSIONS_ACCESS.TRANSACTION_ACTIVITY_LOGS,
  // "can_view_breakout_room_activity_log",
  // "can_view_milestone_activity_log",
  // "can_view_link_activity_logs",
  // "can_view_document_activity_logs",
  // "can_view_checklist_activity_logs",
  PERMISSIONS_ACCESS.UPDATE_TRANSACTION_STATUS,
]);

export const PERMISSIONS = [
  {
    category: PERMISSIONS_CATEGORY.TRANSACTION,
    route: "edit-transaction",
    access: PERMISSIONS_ACCESS.EDIT_TRANSACTION,
    title: "Edit Transaction",
    thumb: Images.Transaction,
  },
  {
    category: PERMISSIONS_CATEGORY.TRANSACTION,
    route: "update-transaction-status",
    access: PERMISSIONS_ACCESS.UPDATE_TRANSACTION_STATUS,
    title: "Edit Transaction Status",
    thumb: Images.Transaction,
  },
  {
    category: PERMISSIONS_CATEGORY.TRANSACTION,
    route: "transaction-invite",
    access: PERMISSIONS_ACCESS.INVITE_TO_TRANSACTION,
    title: "Invite to transaction",
    thumb: Images.Transaction,
  },
  {
    category: PERMISSIONS_CATEGORY.TRANSACTION,
    route: "transaction-logs",
    access: PERMISSIONS_ACCESS.TRANSACTION_ACTIVITY_LOGS,
    title: "",
    thumb: Images.Transaction,
  },
  {
    category: PERMISSIONS_CATEGORY.BREAKOUT,
    route: "edit-breakout-room",
    access: PERMISSIONS_ACCESS.EDIT_BREAKOUT_ROOM,
    title: "Manage Breakout Room",
    thumb: Images.TransactionDealRoom,
  },
  {
    category: PERMISSIONS_CATEGORY.BREAKOUT,
    route: "breakoutroom-logs",
    access: PERMISSIONS_ACCESS.TRANSACTION_ACTIVITY_LOGS,
    title: "",
    thumb: Images.TransactionDealRoom,
  },
  {
    category: PERMISSIONS_CATEGORY.MILESTONE,
    route: TRANSACTION_TABS_SLUGS.MILESTONES,
    access: PERMISSIONS_ACCESS.VIEW_MILESTONE,
    title: "",
    thumb: Images.MilestoneSolid,
  },
  {
    category: PERMISSIONS_CATEGORY.MILESTONE,
    route: "create-milestone",
    access: PERMISSIONS_ACCESS.CREATE_MILESTONE,
    title: "Create Milestone",
    thumb: Images.MilestoneSolid,
  },
  {
    category: PERMISSIONS_CATEGORY.MILESTONE,
    route: "edit-milestone",
    access: PERMISSIONS_ACCESS.EDIT_MILESTONE,
    title: "Edit Milestone",
    thumb: Images.MilestoneSolid,
  },
  {
    category: PERMISSIONS_CATEGORY.MILESTONE,
    route: "delete-milestone",
    access: PERMISSIONS_ACCESS.DELETE_MILESTONE,
    title: "Delete Milestone",
    thumb: Images.MilestoneSolid,
  },
  {
    category: PERMISSIONS_CATEGORY.MILESTONE,
    route: "milestone-logs",
    access: PERMISSIONS_ACCESS.TRANSACTION_ACTIVITY_LOGS,
    title: "",
    thumb: Images.MilestoneSolid,
  },
  {
    category: PERMISSIONS_CATEGORY.DATAROOM,
    route: TRANSACTION_TABS_SLUGS.DATAROOMS,
    access: PERMISSIONS_ACCESS.VIEW_DATAROOM,
    title: "",
    thumb: Images.TransactionDataRoom,
  },
  {
    category: PERMISSIONS_CATEGORY.DATAROOM,
    route: "create-dataroom",
    access: PERMISSIONS_ACCESS.CREATE_DATAROOM,
    title: "Create Data Room",
    thumb: Images.TransactionDataRoom,
  },
  {
    category: PERMISSIONS_CATEGORY.DATAROOM,
    route: "dataroom-logs",
    access: PERMISSIONS_ACCESS.TRANSACTION_ACTIVITY_LOGS,
    title: "",
    thumb: Images.TransactionDataRoom,
  },
  {
    category: PERMISSIONS_CATEGORY.DATAROOM,
    route: TRANSACTION_LINK_DATAROOM_SLUG,
    access: PERMISSIONS_ACCESS.VIEW_LINK,
    title: "",
    thumb: Images.TransactionDataRoom,
  },
  {
    category: PERMISSIONS_CATEGORY.DATAROOM,
    route: "manage-links",
    access: PERMISSIONS_ACCESS.MANAGE_LINK,
    title: "Manage Link Data Room",
    thumb: Images.TransactionDataRoom,
  },
  {
    category: PERMISSIONS_CATEGORY.DATAROOM,
    route: "link-logs",
    access: PERMISSIONS_ACCESS.TRANSACTION_ACTIVITY_LOGS,
    title: "",
    thumb: Images.TransactionDataRoom,
  },
  {
    category: PERMISSIONS_CATEGORY.DOCUMENT,
    route: TRANSACTION_TABS_SLUGS.DOCUMENTS,
    access: PERMISSIONS_ACCESS.VIEW_DOCS,
    title: "",
    thumb: Images.DocThumb,
  },
  {
    category: PERMISSIONS_CATEGORY.DOCUMENT,
    route: "create-document",
    access: PERMISSIONS_ACCESS.ADD_DOCS,
    title: "Create Document",
    thumb: Images.DocThumb,
  },
  {
    category: PERMISSIONS_CATEGORY.DOCUMENT,
    route: "edit-document",
    access: PERMISSIONS_ACCESS.EDIT_DOCS,
    title: "Edit Document",
    thumb: Images.DocThumb,
  },
  {
    category: PERMISSIONS_CATEGORY.DOCUMENT,
    route: "delete-document",
    access: PERMISSIONS_ACCESS.DELETE_DOCS,
    title: "Delete Document",
    thumb: Images.DocThumb,
  },
  {
    category: PERMISSIONS_CATEGORY.DOCUMENT,
    route: "document-logs",
    access: PERMISSIONS_ACCESS.TRANSACTION_ACTIVITY_LOGS,
    title: "",
    thumb: Images.DocThumb,
  },
  {
    category: PERMISSIONS_CATEGORY.CHECKLIST,
    route: TRANSACTION_TABS_SLUGS.CHECKLIST,
    access: PERMISSIONS_ACCESS.VIEW_CHECKLIST,
    title: "",
    thumb: Images.TransactionChecklist,
  },
  {
    category: PERMISSIONS_CATEGORY.CHECKLIST,
    route: "edit-checklist",
    access: PERMISSIONS_ACCESS.EDIT_CHECKLIST,
    title: "Edit Checklist",
    thumb: Images.TransactionChecklist,
  },
  {
    category: PERMISSIONS_CATEGORY.CHECKLIST,
    route: "delete-checklist",
    access: PERMISSIONS_ACCESS.DELETE_CHECKLIST,
    title: "Delete Checklist",
    thumb: Images.TransactionChecklist,
  },
  {
    category: PERMISSIONS_CATEGORY.CHECKLIST,
    route: "create-checklist",
    access: PERMISSIONS_ACCESS.ADD_CHECKLIST,
    title: "Create Checklist",
    thumb: Images.TransactionChecklist,
  },
  {
    category: PERMISSIONS_CATEGORY.CHECKLIST,
    route: "checklist-logs",
    access: PERMISSIONS_ACCESS.TRANSACTION_ACTIVITY_LOGS,
    title: "",
    thumb: Images.TransactionChecklist,
  },
];

export const APPROVAL_REQUEST_REMOVABLE_KEYS = [
  "transactionSlug",
  "isVisibleToAll",
  "visibleToAll",
  "roomSlug",
  "transactionId",
  "documentSlug",
  "isDocument",
  "slug",
  "advisedPartyId",
  "isIndividual",
  "isSkip",
  "documentIds",
  "exhibitSlug",
];

export const APPROVAL_REQUEST_LABELS = {
  [PERMISSIONS_CATEGORY.DATAROOM]: {
    name: "Name",
    visibilities: "Visibility",
    url: "Url",
  },
  [PERMISSIONS_CATEGORY.MILESTONE]: {
    name: "Name",
    endDate: "End Date",
    visibility: "Visibility",
    responsibleParties: "Responsible Parties",
    notes: "Note",
    status: "Status",
  },
  [PERMISSIONS_CATEGORY.CHECKLIST]: {
    name: "Name",
    targetDate: "Target Date",
    type: "Type",
    visibility: "Visibility",
    responsibleParties: "Responsible Parties",
    notes: "Note",
    document: "Documents",
    status: "Status",
  },
  [PERMISSIONS_CATEGORY.DOCUMENT]: {
    name: "Name",
    uploadFrom: "Upload From",
    url: "Attachment",
    notes: "Note",
    totalexhibits: "Total Exhibits",
    totalversions: "Total Versions",
    from: "From",
    to: "To",
    [PERMISSIONS_CATEGORY.CHECKLIST]: {
      name: "Checklist Name",
      targetDate: "Target Date",
      type: "Checklist Type",
      visibility: "Visibility",
      responsibleParties: "Responsible Parties",
      notes: "Checklist Note",
    },
  },
  [PERMISSIONS_CATEGORY.BREAKOUT]: {
    name: "Name",
    memberIds: "Users",
  },
  [PERMISSIONS_CATEGORY.TRANSACTION]: {
    name: "Name",
    status: "Status",
    transactionTypeId: "Transaction Type",
    isAdvisor: "Type",
    roleId: "Role",
    answers: "Answers",
    company: "Company",
    users: "Users",
  },
};

//REQUEST STATUS CONST

export const STATUS_CONST = {
  approved: {
    title: "Accepted",
    style: {
      color: "#569e92",
      backgroundColor: "rgba(63, 200, 170, 0.1)",
      padding: "5px 20px 6px 20px",
    },
  },
  pending: {
    title: "Pending",
    style: {
      color: "#d6ab16",
      backgroundColor: "rgba(214, 171, 22, 0.1)",
      padding: "5px 24px 6px 23px",
    },
  },
  rejected: {
    title: "Rejected",
    style: {
      color: "#f06b6b",
      backgroundColor: "rgba(240, 107, 107, 0.2)",
      padding: "5px 20px 6px 20px",
    },
  },
};

export const CHAT_NOTI_CONST = {
  is_react: "When someone reacts on a message.",
  is_reply: "When someone replies to a message.",
  is_tagged: "When someone tags you.",
  is_message: "When you receive a message.",
  is_add_or_remove: "When you are added or removed from a group.",
  is_appoint_or_revoke_admin:
    "When you are added or removed as admin of a group.",
};
