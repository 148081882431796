import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  DASHBOARD_ROUTE,
  DASHBOARD_TRANSACTION_INVITE_ROUTE,
  LOGIN_INVITE_ROUTE,
  LOGIN_ROUTE,
} from "../constants";
import { useCustomDispatch } from "../helpers/useCustomDispatch";
import { getLoggedInUserCompanyRequest } from "../redux/slicers/company";
import { PrivateSharedLayout, PublicSharedLayout } from "../sharedLayout";

const PublicRoute = ({ children }) => {
  return <PublicSharedLayout>{children}</PublicSharedLayout>;
};

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const { access_token } = useSelector((state) => state.user.data);
  const { token } = useParams();

  useEffect(() => {
    if (!access_token) {
      if (token) {
        navigate(LOGIN_INVITE_ROUTE.replace(":token", token), {
          replace: true,
        });
        return;
      }
      navigate(LOGIN_ROUTE);
    }
  }, [access_token]);
  if (!access_token) return "";
  return <PrivateSharedLayout>{children}</PrivateSharedLayout>;
};

const AuthRoute = ({ children }) => {
  const navigate = useNavigate();
  const { access_token } = useSelector((state) => state.user.data);
  const { token } = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
    if (access_token) {
      if (token && !pathname.includes("signup")) {
        navigate(DASHBOARD_TRANSACTION_INVITE_ROUTE.replace(":token", token), {
          replace: true,
        });
        return;
      }
      navigate(DASHBOARD_ROUTE);
    }
  }, [access_token]);

  return children;
};

const EnterpriseAdminPrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const { isEnterpriseUser } = useSelector((state) => state.user.data);
  const [getCompanyData] = useCustomDispatch(getLoggedInUserCompanyRequest);

  useEffect(() => {
    getCompanyData({
      logic() {
        if (!isEnterpriseUser) {
          navigate(DASHBOARD_ROUTE);
        }
      },
    });
  }, [isEnterpriseUser]);
  if (!isEnterpriseUser) return "";

  return children;
};

export { PublicRoute, PrivateRoute, AuthRoute, EnterpriseAdminPrivateRoute };
