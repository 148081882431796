import React from "react";
import { Modal, Row, Col } from "antd";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  ButtonComponent,
  CardLoader,
  CustomInfiniteScroll,
  DisabledTooltip,
  EmptyBox,
  NoAccessBox,
} from "../../../../../../../components";
import { Images } from "../../../../../../../themes";
import { useState } from "react";
import {
  getActionPermission,
  toastAlert,
} from "../../../../../../../services/utils";
import {
  ALERT_TYPES,
  DATA_PER_PAGE_LIMIT,
  PERMISSIONS_ACCESS,
} from "../../../../../../../constants";
import { CreateDocumentsModal } from "../../../documents/components";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { getAlltransactionDocsRequest } from "../../../../../../../redux/slicers/transactiondocuments";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { addDocToChecklistRequest } from "../../../../../../../redux/slicers/transactionchecklist";
const AddDocumentModal = ({
  handleClose,
  preview,
  selectedDocs,
  removeDocHandler,
  addDocHandler,
  isSelected,
  checklistSlug,
  clearSelected,
}) => {
  //STATES
  const [createModalPreview, setCreateModalPreview] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isMoreData, setMoreData] = useState(null);
  const [total, setTotal] = useState(null);

  //REDUX DATA
  const { transDocs } = useSelector((state) => state.transactiondocuments);

  //CUSTOM DISPATCH
  const [getTransactionDocs, docsLoader] = useCustomDispatch(
    getAlltransactionDocsRequest
  );
  const [addDocToChecklist, addLoader] = useCustomDispatch(
    addDocToChecklistRequest
  );

  //CONST VALS
  const { id, room } = useParams();
  const isDataLoading = docsLoader && !isMoreData;
  const { VIEW_DOCS, ADD_DOCS } = PERMISSIONS_ACCESS;
  const isViewAllowed = getActionPermission(VIEW_DOCS);
  const isAddAllowed = getActionPermission(ADD_DOCS);

  //HOOKS
  useEffect(() => {
    preview && getDocumentsHelper();
  }, [preview, offset]);

  //HELPERS
  const getDocumentsHelper = (isSearch) => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: offset,
    };
    if (checklistSlug) {
      queryParams["checklistSlug"] = checklistSlug;
    }
    if (isSearch) {
      queryParams["search"] = isSearch;
      setMoreData(false);
    }
    getTransactionDocs({
      queryParams,
      pathParams: id,
      logic({ total }) {
        setTotal(total);
      },
    });
  };
  //HANDLERS
  const closeHandler = () => {
    setOffset(0);
    setMoreData(null);
    setTotal(null);
    handleClose();
  };

  const createModalPreviewHandler = (newDoc) => {
    setCreateModalPreview(!createModalPreview);
    newDoc && getDocumentsHelper();
  };

  const handleSubmit = () => {
    if (selectedDocs.length < 1) {
      toastAlert("Please select atleast one document.", ALERT_TYPES.ERROR);
      return;
    }

    if (checklistSlug) {
      const payload = { ids: selectedDocs.map((x) => x.id), roomSlug: room };
      addDocToChecklist({
        payload,
        pathParams: checklistSlug,
        logic(res) {
          toastAlert(res?.message, ALERT_TYPES.SUCCESS);
          closeHandler();
          clearSelected();
        },
      });
      return;
    }

    closeHandler();
  };

  const handleSearch = (e) => {
    setOffset(0);
    getDocumentsHelper(e.target.value);
  };

  const handleNextData = () => {
    setOffset(offset + DATA_PER_PAGE_LIMIT);
    setMoreData(true);
  };

  //CUSTOM COMPONENTS
  const docCard = (data) => {
    const isSelected = selectedDocs.find((x) => x.id === data.id);
    return (
      <div className="doc-card-wrapper" key={data?.id}>
        <div className="left-wrapper">
          <div className="doc-thumb">
            <Images.DocThumb />
          </div>
          <div className="doc-info">
            <h3>{data?.name}</h3>
            <p>
              Room: <b>{data?.room ?? "Common Room"} </b>
            </p>
          </div>
        </div>
        <div className="right-wrapper">
          <div className="doc-child-info">
            <div className="child-wrapper">
              <Images.DocChildThumb />
              <p>{data?.versionCount ?? data?.versions} Versions</p>
            </div>
            <div className="child-wrapper">
              <Images.DocChildThumb />
              <p>{data?.exhibitCount ?? data?.exhibits} Exhibits</p>
            </div>
          </div>
          {isSelected ? (
            <Images.CrossRed
              className="c-p"
              onClick={() => removeDocHandler(data.id)}
            />
          ) : (
            <Images.AddressPlus
              className="doc-plus c-p"
              onClick={() => addDocHandler(data)}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={786}
      className="add-doc-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>Add Document</h3>
            <p>Add Existing Documents</p>
          </div>
          <Row gutter={[20, 10]}>
            <Col span={18}>
              <div className="search-box">
                <FontAwesomeIcon icon={faSearch} />
                <input
                  type="text"
                  placeholder={"Search any Documents..."}
                  onChange={handleSearch}
                />
              </div>
            </Col>
            <Col span={6}>
              <DisabledTooltip
                innerSec={
                  <ButtonComponent
                    text="New Document +"
                    className="new-doc-btn"
                    onClick={() =>
                      isAddAllowed &&
                      isViewAllowed &&
                      createModalPreviewHandler(false)
                    }
                  />
                }
                visible={!isAddAllowed || !isViewAllowed}
              />
            </Col>
            <Col span={24}>
              <h3>Exisitng Documents</h3>
            </Col>
            <Col span={24}>
              {!isViewAllowed ? (
                <NoAccessBox height={400} />
              ) : isDataLoading ? (
                <CardLoader size={17} height={400} />
              ) : (
                <div className="existing-docs-wrapper">
                  <CustomInfiniteScroll
                    total={total}
                    dataLength={transDocs.length}
                    next={handleNextData}
                  >
                    {transDocs.length > 0 ? (
                      transDocs.map((item) => docCard(item))
                    ) : (
                      <EmptyBox height={400} />
                    )}
                  </CustomInfiniteScroll>
                </div>
              )}
            </Col>
            {isSelected && isViewAllowed && (
              <Row gutter={[10, 0]} className="selected-docs-wraper">
                {selectedDocs.map((item, index) => (
                  <Col span={12} key={index}>
                    <div className="uploaded-file-wrapper">
                      <div className="left-wrapper">
                        <Images.DocThumb className="doc-thumb" />
                        <div className="file-info">
                          <h3>{item?.name}</h3>
                          {/* <p>Size 456kb</p> */}
                        </div>
                      </div>
                      <Images.CrossRed
                        className="c-p"
                        onClick={() => removeDocHandler(item.id)}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            )}
          </Row>
        </div>
        <div className="modal-footer">
          <ButtonComponent
            text="Submit"
            onClick={handleSubmit}
            disabled={transDocs.length < 1}
            isLoading={addLoader}
          />
        </div>
      </div>
      <CreateDocumentsModal
        handleClose={createModalPreviewHandler}
        preview={createModalPreview}
        checklistView={true}
        handleSuccess={(item) => {
          setOffset(0);
          addDocHandler(item);
          getDocumentsHelper();
        }}
      />
    </Modal>
  );
};

export default AddDocumentModal;
