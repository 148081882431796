import React, { useState, useEffect } from "react";
import { Switch } from "antd";
import "./styles.scss";
import DeleteModal from "../deleteModal";

const StatusToggle = ({
  check,
  handleStatusChange,
  isStatic = false,
  style,
  confirmDescription,
  confirmLoader,
}) => {
  //CONST VALS
  const [confirmationModalPreview, setConfirmationModalPreview] =
    useState(false);

  //HOOKS
  useEffect(() => {
    if (!confirmLoader && confirmationModalPreview) {
      confirmationModalPreviewHandler();
    }
  }, [confirmLoader]);

  //HANDLERS
  const confirmationModalPreviewHandler = () => {
    setConfirmationModalPreview(!confirmationModalPreview);
  };

  return (
    <div className="status-toggle-wrapper" style={style}>
      <Switch
        checkedChildren="Completed"
        unCheckedChildren="Pending"
        checked={check}
        onClick={() => {
          if (!isStatic) {
            confirmationModalPreviewHandler();
          }
        }}
        disabled={isStatic}
      />
      <DeleteModal
        preview={confirmationModalPreview}
        title={"Change Status"}
        description={confirmDescription}
        handleDelete={handleStatusChange}
        btnText={"Confirm"}
        isLoading={confirmLoader}
        handleClose={confirmationModalPreviewHandler}
      />
    </div>
  );
};

export default StatusToggle;
