import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Input, Select, DatePicker } from "antd";
import moment from "moment";
import {
  inputFieldRule,
  getLetterFromName,
  autoCompleteFieldRule,
} from "../../../../../../../services/utils";
import {
  CustomAutoComplete,
  ProfileImage,
} from "../../../../../../../components";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { setUserDetailDrawer } from "../../../../../../../redux/slicers/general";
import {
  getAllRoomsMembersRequest,
  getRoomsWithMembersRequest,
} from "../../../../../../../redux/slicers/transactiondetail";
import {
  DATA_PER_PAGE_LIMIT,
  DATE_FORMAT2,
} from "../../../../../../../constants";
import { getChecklistTypesRequest } from "../../../../../../../redux/slicers/transactionchecklist";
import { Images } from "../../../../../../../themes";

export const CheckFields = ({
  selectedParties,
  setselectedParties,
  isUpdate,
  isDoc,
  handleSetField,
  onChecklistSelect,
  data,
  removeSetField,
  disableFields,
  responsibility,
  form,
  selectedRooms,
  setSelectedRooms,
}) => {
  // STATES
  const [open, setOpen] = useState(false);
  const [visibilityOpen, setVisibilityOpen] = useState(false);

  // REDUX DATA
  const {
    rooms,
    allRoomsMembers,
    data: trans,
  } = useSelector((state) => state.transactiondetail);
  const { types } = useSelector((state) => state.transactionchecklist);

  // CONST VALS
  const dispatch = useDispatch();
  const members = disableFields ? responsibility : allRoomsMembers;

  // CUSTOM DISPATCH
  const [getRoomMembers, isMembersLoading] = useCustomDispatch(
    getAllRoomsMembersRequest
  );
  const [getTransactionRooms, isRoomsLoading] = useCustomDispatch(
    getRoomsWithMembersRequest
  );
  const [getChecklistTypes] = useCustomDispatch(getChecklistTypesRequest);

  // HELPERS
  const getRoomMembersHelper = (vals) => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: 0,
      room: vals.toString(),
      isActive: true,
    };
    getRoomMembers({
      queryParams,
    });
  };

  //HANDLERS
  const handleRemoveMember = (isRoom, id) => {
    if (isRoom) {
      const tempdata = [...selectedRooms].filter((x) => x !== id);
      setSelectedRooms(tempdata);
      form.setFieldsValue({
        visibility: tempdata,
      });
    } else {
      const tempdata = [...selectedParties].filter((x) => x !== id);
      setselectedParties(tempdata);
      form.setFieldsValue({
        responsibleParties: tempdata,
      });
    }
  };

  // HOOKS
  useEffect(() => {
    const queryParams = {
      limit: DATA_PER_PAGE_LIMIT,
      offset: 0,
      transactionId: trans?.id,
    };
    getTransactionRooms({
      queryParams,
    });
    getChecklistTypes();
  }, []);

  // CUSTOM COMPONENT
  const partyMemberItem = (data, index) => {
    const userdetail = members.find((x) => x.id === data);
    return (
      <div className="member-box" key={index}>
        <ProfileImage
          username={userdetail.username}
          color={userdetail.profilebg}
          image={userdetail.image}
          isBlocked={!userdetail?.isActive}
          onClick={() => {
            dispatch(
              setUserDetailDrawer({
                visibility: true,
                id: userdetail.userid ?? userdetail.id,
              })
            );
          }}
          size={10}
        />
        <b>{userdetail.username}</b>
        <span
          className="close-btn"
          onClick={() => {
            !disableFields && handleRemoveMember(false, data);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>
    );
  };

  const selectedRoomItem = (data, index) => {
    const roomdetail = rooms.find((x) => x.id === data);
    return (
      <div className="member-box" key={index}>
        <ProfileImage
          username={roomdetail.name}
          color={roomdetail.bgColor}
          size={10}
        />
        <b>{roomdetail.name}</b>
        <span
          className="close-btn"
          onClick={() => {
            !disableFields && handleRemoveMember(true, data);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>
    );
  };

  return (
    <div className="check-fields">
      <div className="title">
        <h3>{isUpdate ? "Update" : "Add"} Checklist Item</h3>
        <p>Enter Details Below</p>
      </div>

      <Row gutter={[20, 0]}>
        <Col span={12}>
          {isDoc ? (
            <CustomAutoComplete
              required
              options={data}
              setSearch={removeSetField}
              onOptionSelect={onChecklistSelect}
              handleSet={handleSetField}
              // isFilterDisabled
              name="checklistName"
              rules={[
                () => ({
                  validator(_, value) {
                    return autoCompleteFieldRule({
                      value,
                      name: "Checklist Name",
                      isMax: true,
                      max: 100,
                    });
                  },
                }),
              ]}
              placeholder="Checklist Name"
              labelProp={"name"}
              valueProp={"id"}
            />
          ) : (
            <Form.Item
              name="checklistName"
              rules={inputFieldRule({
                name: "Checklist Name",
                isMax: true,
                max: 100,
              })}
            >
              <Input placeholder="Checklist Name" />
            </Form.Item>
          )}
        </Col>
        <Col span={12}>
          <Form.Item
            name="type"
            rules={inputFieldRule({
              name: "Checklist type",
              isMax: true,
              max: 120,
            })}
          >
            <Select
              placeholder="Type"
              disabled={disableFields}
              getPopupContainer={(trigger) => trigger.parentElement}
              suffixIcon={<Images.SelectArrow />}
            >
              {types.map((item, index) => (
                <Select.Option value={item.key} key={index}>
                  {item.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="endDate"
            rules={inputFieldRule({
              name: "Target date",
              isWhiteSpace: false,
              isRequired: false,
            })}
          >
            <DatePicker
              disabled={disableFields}
              placeholder="Target Date"
              format={DATE_FORMAT2}
              disabledDate={(current) =>
                current.isBefore(moment().subtract(1, "day"))
              }
            />
          </Form.Item>
          {!isUpdate && (
            <>
              <Form.Item
                name="visibility"
                rules={inputFieldRule({
                  name: "Visibility",
                  isWhiteSpace: false,
                })}
              >
                <Select
                  disabled={disableFields}
                  mode="multiple"
                  loading={isRoomsLoading}
                  placeholder="Visibility"
                  className="form-select"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(val) => {
                    setselectedParties([]);
                    form.resetFields(["responsibleParties"]);
                    getRoomMembersHelper(val);
                    setVisibilityOpen(false);
                    setSelectedRooms(val);
                  }}
                  optionLabelProp="label"
                  maxTagCount={4}
                  maxTagTextLength={10}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  suffixIcon={<Images.SelectArrow />}
                  open={visibilityOpen}
                  onClick={() => {
                    setOpen(false);
                    setVisibilityOpen(!visibilityOpen);
                  }}
                  onBlur={() => setVisibilityOpen(false)}
                  allowClear
                >
                  {rooms.map((item, index) => {
                    return (
                      <Select.Option
                        value={item.id}
                        key={index}
                        label={item.name}
                      >
                        <div className="visibility-options">
                          <span
                            role="img"
                            style={{
                              backgroundColor: item.bgColor,
                            }}
                          >
                            {getLetterFromName(item.name)}
                          </span>
                          <p>{item.name}</p>
                        </div>
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {selectedRooms.length >= 1 && (
                <div className="selected-partymembers">
                  {selectedRooms.map((item, index) =>
                    selectedRoomItem(item, index)
                  )}
                </div>
              )}
              <Form.Item
                name="responsibleParties"
                rules={inputFieldRule({
                  name: "Responsible parties",
                  isWhiteSpace: false,
                  isRequired: false,
                })}
              >
                <Select
                  disabled={disableFields}
                  loading={isMembersLoading}
                  mode="multiple"
                  placeholder="Responsible Person/Party(ies)"
                  className="form-select"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  open={open}
                  onClick={() => {
                    setOpen(!open);
                  }}
                  onFocus={() => setOpen(true)}
                  onBlur={() => setOpen(false)}
                  onSearch={() => setOpen(true)}
                  onChange={(e) => {
                    setOpen(false);
                    setselectedParties(e);
                  }}
                  maxTagCount={4}
                  maxTagTextLength={10}
                  optionLabelProp="label"
                  getPopupContainer={
                    isDoc && ((trigger) => trigger.parentElement)
                  }
                  suffixIcon={<Images.SelectArrow />}
                >
                  {members?.map((item, index) => (
                    <Select.Option
                      value={item.id}
                      key={index}
                      label={item.username}
                    >
                      <div className="invite-modal-user-autocomplete-options">
                        <ProfileImage
                          color={item.profilebg}
                          image={item.image}
                          username={item.username}
                          isBlocked={!item?.isActive}
                          size={10}
                          classname="thumb"
                        />
                        <div className="option-user-info">
                          <h3>{item.username}</h3>
                          <p>{item.email}</p>
                        </div>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {selectedParties.length >= 1 && (
                <div className="selected-partymembers">
                  {selectedParties.map((item, index) =>
                    partyMemberItem(item, index)
                  )}
                </div>
              )}
            </>
          )}
          <Form.Item
            name="checklistNotes"
            rules={inputFieldRule({
              name: "Notes",
              isMax: true,
              max: 1000,
              isRequired: false,
            })}
          >
            <Input.TextArea
              disabled={disableFields}
              style={{ height: 70 }}
              placeholder="Add Notes"
              className="form-select textarea"
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};
