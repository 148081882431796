import {
  getMessaging,
  getToken,
  onMessage,
  deleteToken,
} from "firebase/messaging";
import { createBrowserHistory } from "history";
import { initializeApp } from "firebase/app";
import { notification } from "antd";
import { ProfileImage } from "./components";
import {
  addChatDeviceTokenRequest,
  addDeviceTokenRequest,
} from "./redux/slicers/user";
import { addNotification, markAsReadRequest } from "./redux/slicers/general";
import DataHandler from "./services/DataHandler";
import { manipulateNotifcationData } from "./dataManipulator/general";
import {
  manipulateChatData,
  manipulateChatMessageData,
  manipulateRoomMemberData,
  manipulateSenderData,
} from "./dataManipulator/chat";
import { useNavigate } from "react-router-dom";
import { DEFAULT_PROFILE_COLOR, MESSAGE_ROUTE } from "./constants";
import {
  setIsSelectFirst,
  setMessageRoute,
  setThread,
} from "./redux/slicers/chat";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
};
const chatFirebaseConfig = {
  apiKey: process.env.REACT_APP_CHAT_API_KEY,
  authDomain: process.env.REACT_APP_CHAT_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_CHAT_PROJECT_ID,
  storageBucket: process.env.REACT_APP_CHAT_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_CHAT_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_CHAT_ID,
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const chatApp = initializeApp(chatFirebaseConfig, "secondary");
const chatMessaging = getMessaging(chatApp);

class NavigationService {
  constructor() {
    this.history = createBrowserHistory();
  }

  navigate(route) {
    this.history.push(route);
  }
}

const navigationService = new NavigationService();

export const fetchToken = () => {
  getToken(messaging, {
    vapidKey: process.env.REACT_APP_VAPID_KEY,
  })
    .then((currentToken) => {
      if (currentToken) {
        DataHandler.getStore().dispatch(
          addDeviceTokenRequest({
            payload: { deviceToken: currentToken, isAllow: true },
          })
        );
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

export const addUniqueId = (token) => {
  DataHandler.getStore().dispatch(
    addDeviceTokenRequest({ payload: { deviceToken: token, isAllow: false } })
  );
};

export const fetchChatToken = () => {
  getToken(chatMessaging, {
    vapidKey: process.env.REACT_APP_CHAT_VAPID_KEY,
  })
    .then((currentToken) => {
      if (currentToken) {
        DataHandler.getStore().dispatch(
          addChatDeviceTokenRequest({ payload: { deviceToken: currentToken } })
        );
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

onMessage(messaging, (payload) => {
  try {
    const notificationdata = payload.data;
    const parsedata = JSON.parse(notificationdata?.extraData);
    const notidata = manipulateNotifcationData(parsedata);
    notification.open({
      key: notidata.id,
      className: "notification-alert",
      message: (
        <div className="noti-title-wrapper">
          <ProfileImage
            color={notidata.profilebg}
            username={notidata.username}
            image={notidata?.thumb}
            // isBlocked={!notidata.isActive}
            size={11}
          />
          <h4>{notidata.username}</h4>
        </div>
      ),
      description: notificationdata?.body,
      onClick: () => {
        notification.close(notidata.id);
        DataHandler.getStore().dispatch(
          markAsReadRequest({ pathParams: notidata.id })
        );
      },
    });
    DataHandler.getStore().dispatch(addNotification(notidata));
  } catch (error) {
    console.error(error);
  }
});

onMessage(chatMessaging, (payload) => {
  try {
    const notificationdata = payload.data;
    const notiID = payload?.messageId;
    // console.log(payload, "FIRE");
    const roomData = JSON.parse(notificationdata?.room);
    const authUser = JSON.parse(notificationdata?.auth_user);
    let thread = {};
    if (notificationdata?.extra) {
      thread = JSON.parse(notificationdata?.extra);
    }
    const threadData = manipulateChatMessageData(thread);
    const { meta_data } = roomData;
    const room = manipulateChatData(roomData);
    const senderData = manipulateSenderData(authUser);
    const description = notificationdata?.title;
    // console.log({ room, roomData }, { senderData, authUser }, "FIRE");
    // const notidata = manipulateNotifcationData(parsedata);
    notification.open({
      key: notiID,
      className: "notification-alert",
      message: (
        <div className="noti-title-wrapper">
          <ProfileImage
            color={senderData.bgColor ?? DEFAULT_PROFILE_COLOR}
            username={senderData.username}
            image={senderData?.image}
            // isBlocked={!senderData.isActive}
            size={11}
            // onClick={() => navigate(MESSAGE_ROUTE)}
          />
          <h4>{senderData.username}</h4>
        </div>
      ),
      description: description,
      onClick: () => {
        notification.close(notiID);
        // navigate(MESSAGE_ROUTE);
        // const currentUrl = window.location.origin;
        // const path = room?.isGroup
        //   ? `/messages/group/${room?.slug}`
        //   : `/messages/${room?.slug}`;
        // // console.log(currentUrl + path, "FIRE");isAdmin
        // window.location.pathname = path;\

        DataHandler.getStore().dispatch(
          setMessageRoute({
            payload: {
              slug: room?.slug,
              isGroup: room?.isGroup,
              roomType: room?.roomType,
              trSlug: meta_data?.transaction_slug,
              trRoomSlug: meta_data?.transaction_room_slug,
            },
          })
        );
        if (notificationdata?.extra) {
          DataHandler?.getStore().dispatch(setIsSelectFirst(true));
          DataHandler?.getStore().dispatch(setThread(threadData?.id));
        }
      },
    });
    // DataHandler.getStore().dispatch(addNotification(notidata));
  } catch (error) {
    console.error(error);
  }
});

export async function requestPermission() {
  const res = await Notification.requestPermission();
  if (res === "granted") {
    return true;
  } else {
    return false;
  }
}
