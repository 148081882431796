import React, { useState } from "react";
import { Col, Row } from "antd";
import TopHeader from "./header";
import {
  CardLoader,
  DisabledTooltip,
  EmptyBox,
  StatusToggle,
  VisibleToPeople,
} from "../../../../../../../components";
import { Images } from "../../../../../../../themes";
import "./styles.scss";
import { DocGrid, DocTable } from "./partials";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import {
  changeChecklistStatusRequest,
  getChecklistDetailRequest,
} from "../../../../../../../redux/slicers/transactionchecklist";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AddChecklistVisibilityModal,
  AddDocumentModal,
  AddResponsiblePartiesModal,
  ChecklistVisibilityModal,
  CreateChecklistModal,
  DeleteChecklistModal,
  DeleteResponsibleGroupModal,
} from "../../components";

const ChecklistDetail = ({
  isEditAllowed,
  isDeleteAllowed,
  isDocViewAllowed,
}) => {
  //STATES
  const [gridView, setGridView] = useState(false);
  const [addDocModalPreview, setAddDocModalPreview] = useState(false);
  const [updateModalPreview, setUpdateModalPreview] = useState(false);
  const [deleteModalPreview, setDeleteModalPreview] = useState(false);
  const [addResponsibleModalPreview, setAddResponsibleModalPreview] =
    useState(false);
  const [addVisibilityModalPreview, setAddVisibilityModalPreview] =
    useState(false);
  const [visiblityModalPreview, setVisibilityModalPreview] = useState(false);
  const [removeResponsibleModalPreview, setRemoveResponsibleModalPreview] =
    useState(false);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [selectedVisibility, setSelectedVisbility] = useState([]);
  const [selectedParty, setSelectedParty] = useState(null);
  const [isRoom, setIsRoom] = useState(false);
  const [isAllowed, setAllowed] = useState(true);

  //REDUX DATA
  const { data } = useSelector((state) => state.transactionchecklist);

  //CONST VALS
  const { id, dataid } = useParams();
  const isSelected = selectedDocs.length > 0;
  const isEditable = !data?.status && !data?.isDeleted;

  //CUSTOM DISPATCH
  const [getChecklistDetail, detailLoader] = useCustomDispatch(
    getChecklistDetailRequest
  );
  const [changeChecklistStatus, statusLoader] = useCustomDispatch(
    changeChecklistStatusRequest
  );

  //HOOKS
  useEffect(() => {
    getChecklistDetail({
      pathParams: dataid,
      error() {
        setAllowed(false);
      },
    });
  }, []);

  //HANDLERS
  const docViewHandler = () => {
    setGridView(!gridView);
  };

  const addDocModalPreviewHandler = () => {
    setAddDocModalPreview(!addDocModalPreview);
  };

  const updateModalPreviewHandler = () => {
    setUpdateModalPreview(!updateModalPreview);
  };

  const deleteModalPreviewHandler = () => {
    setDeleteModalPreview(!deleteModalPreview);
  };

  const addResponsibleModalPreviewHandler = () => {
    setAddResponsibleModalPreview(!addResponsibleModalPreview);
  };

  const addVisibilityModalPreviewHandler = () => {
    setAddVisibilityModalPreview(!addVisibilityModalPreview);
  };

  const visiblityModalPreviewHandler = () => {
    visiblityModalPreview && setIsRoom(false);
    setVisibilityModalPreview(!visiblityModalPreview);
  };

  const removeResponsibleModalPreviewHandler = () => {
    setRemoveResponsibleModalPreview(!removeResponsibleModalPreview);
  };

  const checklistStatusChangeHandler = () => {
    changeChecklistStatus({ pathParams: data?.slug });
  };

  const addDocHandler = (item) => {
    setSelectedDocs([...selectedDocs, item]);
  };

  const removeDocHandler = (id) => {
    const isExist = selectedDocs.find((x) => x.id === id);
    let temp = [...selectedDocs];
    let index = temp.indexOf(isExist);
    temp.splice(index, 1);
    setSelectedDocs(temp);
  };

  //CUSTOM COMPONENTS
  const responsibilityCard = (item) => {
    const dataLength = item?.users?.length;
    const isEditRespAllowed = isEditable;
    // && data?.responsibleParties?.length > 1;
    return (
      <div
        className="party-card-wrapper c-p"
        onClick={() => {
          setSelectedVisbility(item.users);
          visiblityModalPreviewHandler();
        }}
      >
        <div className="card-left-wrapper">
          <VisibleToPeople data={item?.users} maxLength={2} largeSize />
          <div
            className="company-info"
            style={{
              maxWidth: `calc(100% - ${
                dataLength < 3 ? (dataLength === 1 ? "60px" : "76px") : "95px"
              })`,
            }}
          >
            <h3>{item?.name}</h3>
            <p>
              {item?.users?.length} Participant
              {item.users.length > 1 ? "s" : ""}
            </p>
          </div>
        </div>
        {isEditRespAllowed && (
          <DisabledTooltip
            innerSec={
              <Images.Delete
                className="c-p delete"
                onClick={(e) => {
                  if (isEditAllowed) {
                    e.stopPropagation();
                    setSelectedParty(item);
                    removeResponsibleModalPreviewHandler();
                  }
                }}
              />
            }
            visible={!isEditAllowed}
          />
        )}
      </div>
    );
  };

  return (
    <section className="checklist-detail">
      <TopHeader />
      <div className="card">
        {detailLoader ? (
          <CardLoader />
        ) : (
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <div className="checklist-detail-info-wrapper">
                <div className="detail-info">
                  <h3>{data?.name}</h3>
                  <span className="sub-text">
                    {data?.targetDate && <p>Target Date: {data?.targetDate}</p>}
                    <p>Type: {data?.type?.value}</p>
                  </span>
                </div>
                {isAllowed && (
                  <div className="checklist-options">
                    <DisabledTooltip
                      innerSec={
                        <StatusToggle
                          confirmDescription={
                            "Are you sure you want to change this checklist's status?"
                          }
                          check={data?.status}
                          confirmLoader={statusLoader}
                          handleStatusChange={checklistStatusChangeHandler}
                          isStatic={data?.isDeleted || !isEditAllowed}
                        />
                      }
                      visible={!isEditAllowed}
                    />

                    {isEditable && (
                      <DisabledTooltip
                        innerSec={
                          <Images.Edit
                            className="c-p edit"
                            onClick={isEditAllowed && updateModalPreviewHandler}
                          />
                        }
                        visible={!isEditAllowed}
                      />
                    )}
                    {data?.isDeleted ? (
                      <DisabledTooltip
                        innerSec={
                          <Images.Restore
                            className="c-p edit"
                            onClick={
                              isDeleteAllowed && deleteModalPreviewHandler
                            }
                          />
                        }
                        visible={!isDeleteAllowed}
                      />
                    ) : (
                      isEditable && (
                        <DisabledTooltip
                          innerSec={
                            <Images.Delete
                              className="c-p delete"
                              onClick={
                                isDeleteAllowed && deleteModalPreviewHandler
                              }
                            />
                          }
                          visible={!isDeleteAllowed}
                        />
                      )
                    )}
                  </div>
                )}
              </div>
            </Col>
            <Col span={24}>
              <div className="visibility-section">
                <h3>Visibilty</h3>
                <div className="visibility-wrapper">
                  <VisibleToPeople
                    // size={31}
                    // largeSize

                    isRoom
                    data={data?.visibilities}
                    detailModalHandler={() => {
                      setIsRoom(true);
                      setSelectedVisbility(data?.visibilities);
                      visiblityModalPreviewHandler();
                    }}
                  />
                  {isEditable && isAllowed && (
                    <DisabledTooltip
                      innerSec={
                        <Images.AddPlus
                          className="c-p"
                          onClick={
                            isEditAllowed && addVisibilityModalPreviewHandler
                          }
                        />
                      }
                      visible={!isEditAllowed}
                    />
                  )}
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div className="checklist-notes-wrapper">
                <div className="notes-header">
                  <h3>Note</h3>
                </div>
                <p className="content">{data?.notes}</p>
              </div>
            </Col>

            <Col span={24}>
              <div className="checklist-responsibility-wrapper">
                <div className="responsibility-header">
                  <h3>Responsibility</h3>
                  {isEditable && isAllowed && (
                    <DisabledTooltip
                      innerSec={
                        <Images.AddPlus
                          className="c-p"
                          onClick={
                            isEditAllowed && addResponsibleModalPreviewHandler
                          }
                        />
                      }
                      visible={!isEditAllowed}
                    />
                  )}
                </div>
                {data?.responsibleParties?.length < 1 ? (
                  <EmptyBox
                    height={170}
                    size={12}
                    imgwidth={80}
                    text="No one is assigned"
                  />
                ) : (
                  <Row gutter={[10, 10]} className="parties-wrapper">
                    {data?.responsibleParties?.map((item, index) => (
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={8}
                        xxl={8}
                        span={8}
                        key={index}
                      >
                        {responsibilityCard(item)}
                      </Col>
                    ))}
                  </Row>
                )}
              </div>
            </Col>

            <Col span={24}>
              <div className="checklist-doc-wrapper">
                <div className="doc-header">
                  <h3>Documents</h3>
                  <div className="doc-options">
                    {isEditable && isAllowed && (
                      <DisabledTooltip
                        innerSec={
                          <span
                            className="add-doc-btn c-p"
                            onClick={isEditAllowed && addDocModalPreviewHandler}
                          >
                            Document +
                          </span>
                        }
                        visible={!isEditAllowed}
                      />
                    )}
                    <div className="view-icons">
                      <Images.ListView
                        className={`c-p ${gridView ? "" : "view-active"}`}
                        onClick={docViewHandler}
                      />
                      <Images.GridView
                        className={`c-p ${gridView ? "view-active" : ""}`}
                        onClick={docViewHandler}
                      />
                    </div>
                  </div>
                </div>
                {gridView ? (
                  <DocGrid
                    data={data?.documents}
                    isEditable={isEditable}
                    isEditAllowed={isEditAllowed}
                    isViewAllowed={isDocViewAllowed}
                  />
                ) : (
                  <DocTable
                    data={data?.documents}
                    isEditable={isEditable}
                    isEditAllowed={isEditAllowed}
                    isViewAllowed={isDocViewAllowed}
                  />
                )}
              </div>
            </Col>
          </Row>
        )}
      </div>
      <AddDocumentModal
        preview={addDocModalPreview}
        handleClose={addDocModalPreviewHandler}
        selectedDocs={selectedDocs}
        addDocHandler={addDocHandler}
        removeDocHandler={removeDocHandler}
        isSelected={isSelected}
        checklistSlug={dataid}
        clearSelected={() => setSelectedDocs([])}
      />
      <CreateChecklistModal
        isUpdate
        preview={updateModalPreview}
        data={data}
        handleClose={updateModalPreviewHandler}
      />
      <DeleteChecklistModal
        data={data}
        preview={deleteModalPreview}
        handleClose={deleteModalPreviewHandler}
      />
      <AddResponsiblePartiesModal
        preview={addResponsibleModalPreview}
        handleClose={addResponsibleModalPreviewHandler}
        visibilities={data?.visibilities}
        slug={data?.slug}
        lastUpdated={data?.lastUpdated}
      />
      <AddChecklistVisibilityModal
        preview={addVisibilityModalPreview}
        handleClose={addVisibilityModalPreviewHandler}
        transactionslug={id}
        slug={data?.slug}
      />
      <ChecklistVisibilityModal
        preview={visiblityModalPreview}
        handleClose={visiblityModalPreviewHandler}
        data={selectedVisibility}
        isEdit={isEditable && isEditAllowed}
        isRemoveAll={
          // data?.responsibleParties?.length > 1 &&
          isEditAllowed
        }
        isRoom={isRoom}
        title={`${isEditable ? "Edit" : "View"} ${
          isRoom ? "Visibility" : "Responsible"
        }`}
        description={
          isEditable
            ? `Are You Sure To Remove ${
                isRoom ? "Visibility" : "Responsible Individuals"
              }`
            : "Details Below"
        }
      />
      <DeleteResponsibleGroupModal
        preview={removeResponsibleModalPreview}
        handleClose={removeResponsibleModalPreviewHandler}
        selectedGroup={selectedParty}
      />
    </section>
  );
};

export default ChecklistDetail;
