import React, { useEffect, useRef, useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Input, Modal } from "antd";
import OtpInput from "react-otp-input";
import { PASSWORD, TWO_FACTOR_KEYS } from "../../constants";
import { useCustomDispatch } from "../../helpers/useCustomDispatch";
import {
  sendOtpOnNumberRequest,
  confirmNumberOTPRequest,
  checkCurrentPasswordRequest,
} from "../../redux/slicers/user";
import {
  inputFieldRule,
  toastAlert,
  customPassIcons,
} from "../../services/utils";
import { ButtonComponent } from "..";
import "./styles.scss";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";

const ChangeNumberModal = ({ handleClose, preview, isWorkPhone }) => {
  // STATES
  const [phoneNumber, setPhoneNumber] = useState("");
  const [_otp, setOtp] = useState("");
  const [step, setStep] = useState(1);
  const [enableResendBtn, setEnableResendBtn] = useState(false);
  const [seconds, setSeconds] = useState(60);

  //REDUX DATA
  const { otpId } = useSelector((state) => state.user);

  // CONST VALS
  const [form] = Form.useForm();
  const _otpRef = useRef(null);

  // CUSTOM DISPATCH
  const [sendOtpReq, sendOtpReqLoader] = useCustomDispatch(
    sendOtpOnNumberRequest
  );
  const [confirmOtpReq, confirmOtpReqLoader] = useCustomDispatch(
    confirmNumberOTPRequest
  );
  const [checkCurrentPassReq, checkCurrentPassReqLoader] = useCustomDispatch(
    checkCurrentPasswordRequest
  );
  // HANDLERS
  const closeHandler = () => {
    handleClose();
    setOtp("");
    setStep(1);
    form.resetFields();
  };

  const verifyNumberHandler = (values) => {
    checkCurrentPassReq({
      payload: values,
      logic() {
        setStep(2);
      },
    });
  };

  const sendOtpHandler = (values, isResend) => {
    const payload = {
      key: "phone",
      to: isWorkPhone ? "changeWorkPhone" : "changePhone",
      recipient: values.phone,
    };
    if (isResend) {
      payload["too"] = TWO_FACTOR_KEYS.RESEND;
    }
    sendOtpReq({
      payload,
      logic({ data }) {
        setPhoneNumber(values.phone);
        if (isResend) {
          setSeconds(60);
          setEnableResendBtn(false);
        } else {
          setStep(3);
        }
      },
    });
  };

  const handleConfirmOtp = (values) => {
    const payload = {
      key: "phone",
      to: isWorkPhone ? "changeWorkPhone" : "changePhone",
      recipient: phoneNumber,
      otp: values.otp,
      request_id: otpId,
    };
    confirmOtpReq({
      payload,
      logic(res) {
        toastAlert(res.message);
        closeHandler();
      },
    });
  };

  // HOOKS
  useEffect(() => {
    if (step < 3) return;
    if (seconds === 0) return setEnableResendBtn(true);
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds, step]);

  useEffect(() => {
    if (_otp?.length === 6) {
      handleConfirmOtp({ otp: _otp });
    }
  }, [_otp]);

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      width={522}
      getContainer={false}
      footer={null}
      className="changenumber-modal"
    >
      <div className="close-btn" onClick={closeHandler}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <h2 className="title">
        {step === 3
          ? "Verification"
          : `Change ${isWorkPhone ? "Work" : ""} Phone Number`}
      </h2>
      <p className="sub-title">
        {step === 3
          ? "Please enter 6 digit code sent to you"
          : "Fill The Details Below"}
      </p>

      {step == 1 && (
        <Form
          form={form}
          className="input-form mt-32"
          onFinish={verifyNumberHandler}
        >
          <Form.Item
            name={"password"}
            rules={inputFieldRule({
              name: PASSWORD.PASSWORD_ERROR,
              isWhiteSpace: false,
            })}
          >
            <Input.Password
              name={"password"}
              prefix={PASSWORD.PASSWORD_ICON}
              placeholder={PASSWORD.PASSWORD_PLACEHOLDER}
              iconRender={customPassIcons}
              className="password-field"
              autoFocus
            />
          </Form.Item>
          <div className="submit-wrapper">
            <ButtonComponent
              className="submit-btn"
              text={"Submit"}
              isLoading={checkCurrentPassReqLoader}
            />
          </div>
        </Form>
      )}
      {step == 2 && (
        <Form
          form={form}
          className="input-form mt-32"
          onFinish={sendOtpHandler}
        >
          <Form.Item
            name={"phone"}
            rules={inputFieldRule({
              name: "Phone Number",
              isRequired: true,
              isWhiteSpace: true,
              isType: true,
              type: "tel",
            })}
          >
            <PhoneInput
              placeholder={"Enter Your phone number"}
              isValid={(value, country) => {
                if (value.match(/12345/)) {
                  return "Invalid value: " + value + ", " + country.name;
                } else if (value.match(/1234/)) {
                  return false;
                } else {
                  return true;
                }
              }}
              country={"us"}
            />
          </Form.Item>
          <div className="submit-wrapper">
            <ButtonComponent
              className="submit-btn"
              text={"Submit"}
              isLoading={sendOtpReqLoader}
            />
          </div>
        </Form>
      )}

      {step == 3 && (
        <>
          <div className="formatted-phone">
            <PhoneInput
              disableDropdown
              disabled
              value={phoneNumber}
              disableCountryGuess
            />
          </div>
          <Form form={form} className="input-form" onFinish={handleConfirmOtp}>
            <Form.Item
              rules={inputFieldRule({
                name: "Otp",
                isWhiteSpace: false,
              })}
              name="otp"
            >
              <OtpInput
                ref={_otpRef}
                isInputNum={true}
                shouldAutoFocus={true}
                value={_otp}
                className={`otpCodeInput custom-otp-input`}
                onChange={(e) => setOtp(e)}
                numInputs={6}
                separator={<span></span>}
              />
            </Form.Item>

            <div className="resend-wrapper">
              <span
                onClick={() => {
                  sendOtpHandler({ phone: phoneNumber }, true);
                }}
                style={
                  enableResendBtn
                    ? {}
                    : {
                        pointerEvents: "none",
                        opacity: 0.5,
                      }
                }
                className="resend-text"
              >
                Resend Code
              </span>
              <span>00:{`${seconds}`.padStart(2, 0)}</span>
            </div>

            <div className="submit-wrapper">
              <Form.Item>
                <ButtonComponent
                  className="submit-btn"
                  text={"Submit"}
                  isLoading={confirmOtpReqLoader}
                />
              </Form.Item>
            </div>
          </Form>
        </>
      )}
    </Modal>
  );
};

export default ChangeNumberModal;
