import React, { useState } from "react";
import { Header, Footer } from "../../components";
import "./styles.scss";

function PublicSharedLayout({ children }) {
  return (
    <section className="layout-wrapper">
      <Header publicHeader />
      {children}
      <Footer />
    </section>
  );
}

export default PublicSharedLayout;
