import React, { useState } from "react";
import { AutoComplete, Form } from "antd";
import "./styles.scss";
import ProfileImage from "../ProfileImage";

const CustomUserAutoComplete = React.forwardRef(
  (
    {
      options,
      setSearch,
      label,
      name,
      rules,
      placeholder,
      valueProp,
      labelProp,
      handleSet,
      handleChange,
      onOptionSelect,
      onClick,
      isTriggered,
      disabled,
      isFilterDisabled,
      required,
      autoFocus,
    },
    ref
  ) => {
    //STATES
    const [dropdownOpen, setdropdownOpen] = useState(false);
    //CONST VALS
    const { Option } = AutoComplete;
    return (
      <Form.Item
        label={label}
        rules={rules}
        name={name}
        required={required}
        className="create-form-item "
      >
        <AutoComplete
          open={dropdownOpen}
          autoFocus={autoFocus}
          disabled={disabled}
          onClick={onClick}
          // maxLength={80}
          className="create-form-select "
          onChange={(text) => {
            handleSet(name, text);
            handleChange && handleChange();
          }}
          placeholder={placeholder}
          onSelect={(val, option) => {
            handleSet(name, option);
            onOptionSelect && onOptionSelect(option, name);
            setdropdownOpen(false);
          }}
          filterOption={(input, option) =>
            !isFilterDisabled &&
            option.value.toLowerCase().includes(input.toLowerCase())
          }
          onSearch={(val) => {
            if (val !== "") {
              setdropdownOpen(true);
              setSearch && setSearch(val);
              return;
            }
            setdropdownOpen(false);
          }}
          getPopupContainer={
            isTriggered ? null : (trigger) => trigger.parentElement
          }
          dropdownClassName="ant-drop-down-other"
          ref={ref}
        >
          {options?.map((data) => (
            <Option value={data[labelProp]} key={data[valueProp]}>
              <div className="invite-modal-user-autocomplete-options">
                <ProfileImage
                  color={data.profilebg}
                  image={data.thumb ?? data.logo}
                  username={data.username}
                  size={10}
                  classname="thumb"
                />
                <div className="option-user-info">
                  <h3>{data?.username ?? data[labelProp]}</h3>
                  <p>{data?.email}</p>
                </div>
              </div>
            </Option>
          ))}
        </AutoComplete>
      </Form.Item>
    );
  }
);

export default CustomUserAutoComplete;
