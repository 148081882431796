import _ from "lodash";
import {
  ACTIVITY_LOGS_TYPES,
  DATETIME_FORMAT2,
  DEFAULT_PROFILE_COLOR,
  PERMISSIONS,
  STATUS_CONST,
} from "../constants";
import DataHandler from "../services/DataHandler";
import {
  cloneDeepItem,
  getFormattedDateByTime,
  getFormattedDateTime,
  replaceValInString,
} from "../services/utils";
import {
  compareAndRemoveDuplicates,
  manipulateRequestAdminsData,
  manipulateRequestDetailData,
  manipulateRequestDetailOldData,
} from "./approvalrequest";
import { manipulateUserData } from "./user";

// ACTIVITY LOGS ROUTE
const generateActivityLogRoute = (type, data) => {
  let isDeleted = false;
  let routetemplate = ACTIVITY_LOGS_TYPES[type];
  let logroute = cloneDeepItem(routetemplate);
  let isMilestone = logroute?.isMilestone;
  let isBlocked = logroute?.isBlocked;
  let isEnterpriseAdmin =
    DataHandler.getStore().getState().user.data.isEnterpriseUser;
  let isAdmin = logroute.isAdminCondition;
  let isInvite = logroute.isInvite;
  let token = null;
  if (isInvite) {
    token = data?.extraData?.requestId;
  }

  if (isAdmin) {
    isAdmin = isEnterpriseAdmin;
  }

  logroute?.slugkeys &&
    Object.keys(logroute.slugkeys).forEach(function (key) {
      logroute.slugkeys[key] = logroute.slugkeys[key]
        .split(".")
        .reduce((o, i) => o?.[i], data);
    });

  logroute.deletedkeys.forEach((item) => {
    if (data?.[item]?.["deletedAt"]) {
      isDeleted = true;
    }
  });

  return {
    isMilestone,
    isDeleted,
    isBlocked,
    isInvite,
    token,
    route: logroute?.slugkeys
      ? replaceValInString(logroute.route, logroute.slugkeys)
      : isAdmin
      ? logroute.adminRoute
      : logroute.route,
  };
};

// ACTIVITY LOGS
export const manipulateActivityLogsData = (data) => {
  if (_.isEmpty(data)) return {};
  let activity = {};
  activity["username"] =
    data.user.firstName?.trim() + " " + data.user.lastName?.trim() ?? "";
  activity["isActive"] = (data.user.isActive && !data?.user.isBlock) ?? true;
  activity["userid"] = data.user.id ?? 1;
  activity["detail"] = data.description ?? "";
  activity["thumb"] = data.user.image;
  activity["profilebg"] = data.user.bgcolor ?? DEFAULT_PROFILE_COLOR;
  activity["date"] = getFormattedDateTime(
    data?.createdAt ?? Date.now(),
    DATETIME_FORMAT2
  );
  const routeconfig = generateActivityLogRoute(data.template?.logType, data);

  return { ...activity, ...routeconfig };
};
export const manipulateActivtyLogsList = (data) => {
  let activities = [];
  data.forEach((item) => {
    activities.push(manipulateActivityLogsData(item));
  });
  return activities;
};

// NOTIICATIONS
export const manipulateNotifcationData = (data) => {
  if (_.isEmpty(data)) return {};
  let notification = {};
  notification["id"] = data?.id ?? 0;
  notification["isRead"] = data?.NotificationPivot?.[0]?.is_read ?? false;
  notification["username"] =
    data.user.firstName?.trim() + " " + data.user.lastName?.trim() ?? "";
  notification["isActive"] =
    (data.user.isActive && !data?.user?.isBlock) ?? true;
  notification["userid"] = data.user.id ?? 1;
  notification["detail"] = data.description ?? "";
  notification["thumb"] = data.user.image;
  notification["profilebg"] = data.user.bgcolor ?? DEFAULT_PROFILE_COLOR;
  notification["isAdmin"] = data?.extraData?.isAdmin ?? false;
  notification["requestId"] = data?.extraData?.tempId ?? false;
  notification["isAsk"] = data?.extraData?.tempId ? true : false;
  notification["isOwner"] = data?.extraData?.isOwner ? true : false;
  notification["date"] = getFormattedDateByTime(data?.createdAt ?? Date.now());

  const routeconfig = generateActivityLogRoute(data.template?.logType, data);

  return { ...notification, ...routeconfig };
};
export const manipulateNotificationsList = (data) => {
  let notifications = [];
  let isOpen = data.isOpen ?? 0;
  data?.notification.forEach((item) => {
    notifications.push(manipulateNotifcationData(item));
  });
  return { isOpen, data: notifications };
};

// INVITATION DATA
export const manipulateInvitationData = (data) => {
  if (_.isEmpty(data)) return {};
  let obj = {};
  obj["transactionName"] = data.transaction.name ?? "";
  obj["transactionRole"] = data.transaction.transactionType.title ?? "";
  obj["username"] =
    data.v.firstName?.trim() + " " + data.invitedBy.lastName?.trim() ?? "";
  obj["userid"] = data.invitedBy.id ?? 1;
  obj["thumb"] = data.invitedBy.image;
  obj["companyName"] = data.invitedBy.company.name ?? "";
  obj["companyId"] = data.invitedBy.company.id ?? 1;

  return obj;
};

// REVIEWS
export const manipulateReviewsData = (data) => {
  if (_.isEmpty(data)) return {};
  let review = {};

  review["fullName"] =
    data?.firstName?.trim() + " " + data?.lastName?.trim() ?? "";
  review["firstName"] = data?.firstName ?? "";
  review["reviewID"] = data?.id ?? 1;
  review["ratingCount"] = data?.rating ?? 0;
  review["role"] = data?.role ?? "";
  review["description"] = data?.description ?? "";
  review["profileImg"] = data?.image;
  review["updatedDate"] = getFormattedDateTime(
    data?.updatedAT ?? Date.now(),
    DATETIME_FORMAT2
  );

  return review;
};
export const manipulateReviewsList = (data) => {
  let reviews = [];
  data.forEach((item) => {
    reviews.push(manipulateReviewsData(item));
  });
  return reviews;
};

// PENDING APPROVAL REQUEST
export const manipulatePendingApprovalRequestData = (data) => {
  let admins = [];
  data.forEach((item) => {
    let object = {
      ...manipulateUserData(item),
      companyName: item.company.name,
      status: STATUS_CONST[item?.isPending] ?? STATUS_CONST.pending,
    };
    admins.push(object);
  });

  return admins;
};

// CONNFIRM APPROVAL REQUEST
export const manipulateConfirmApprovalRequestData = (data) => {
  const userdata = {
    ...manipulateUserData(data.requestedUser),
    companyName: data.requestedUser.company.name,
  };
  const isDeleted = data?.isDeleted;
  const isEdit = data?.isEdit && !data?.isDeleted;
  const isResponded = data?.isResponded;
  const permission = PERMISSIONS.find((x) => x.access === data.action) ?? {};
  const admins = manipulateRequestAdminsData(data.admins);
  let oldDetail = {};
  let detail = manipulateRequestDetailData(
    data.details,
    permission?.category,
    permission?.access
  );
  if (isEdit) {
    let tempOldData = manipulateRequestDetailOldData(
      data.oldData,
      detail,
      permission?.category,
      permission?.access
    );
    const [updatedData, updatedOldData] = compareAndRemoveDuplicates(
      detail,
      tempOldData
    );
    detail = updatedData;
    oldDetail = updatedOldData;
  }

  const response = {
    userdata,
    admins,
    ...permission,
    detail,
    oldDetail,
    isDeleted,
    isEdit,
    isResponded,
  };
  if (data?.title) {
    response["title"] = data?.title;
  }
  return response;
};
