import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "antd";
import CurrentMembers from "../currentAccessMembers";
import "./styles.scss";

function MemberAccessModal({
  preview,
  handleClose,
  members,
  isEditable = true,
  isOwner,
}) {
  //HANDLERS
  const closeHandler = () => {
    handleClose();
  };

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={400}
      className="member-access-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>Visibility</h3>
          </div>

          <CurrentMembers
            members={members}
            noTitle
            maxHeight={400}
            isEditable={isEditable}
            isOwner={isOwner}
          />
        </div>
      </div>
    </Modal>
  );
}

export default MemberAccessModal;
