import React, { useEffect } from "react";
import { Modal, Form, Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ButtonComponent } from "../../../../../../../components";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import {
  updateExhibitRequest,
  updateVersionRequest,
} from "../../../../../../../redux/slicers/transactiondocuments";
import { inputFieldRule } from "../../../../../../../services/utils";
import "./styles.scss";

const EditExhibitModal = ({
  preview,
  handleClose,
  handleSuccess,
  isVersion,
  data,
}) => {
  // CUSTOM DISPATCH
  const [updateExhibit, exhibitLoader] =
    useCustomDispatch(updateExhibitRequest);
  const [updateVersion, versionLoader] =
    useCustomDispatch(updateVersionRequest);

  //CONST VALS
  const [form] = Form.useForm();
  const isLoading = exhibitLoader || versionLoader;

  // HELPERS
  const requestHelper = (request, payload) => {
    request({
      payload,
      pathParams: data.slug,
      logic() {
        closeHandler();
        handleSuccess();
      },
    });
  };

  // HOOKS
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
        notes: data?.notes,
      });
    }
  }, [preview, data]);

  //HANDLERS
  const closeHandler = () => {
    handleClose();
    form.resetFields();
  };

  const handleUpdate = (val) => {
    const payload = { ...val };
    if (isVersion) {
      payload["isDocument"] = data?.isDocument;
    }
    requestHelper(isVersion ? updateVersion : updateExhibit, payload);
  };

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={522}
      className="update-exhibit-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>Update {isVersion ? "Version" : "Exhibit"}</h3>
            <p>Edit Details Below</p>
          </div>
          <Form form={form} onFinish={handleUpdate}>
            <Form.Item
              name="name"
              rules={inputFieldRule({
                name: `${isVersion ? "Version" : "Exhibit"} name`,
                isMax: true,
                max: 120,
              })}
            >
              <Input placeholder="Name" />
            </Form.Item>
            {isVersion && (
              <Form.Item
                name="notes"
                rules={inputFieldRule({
                  name: "Notes",
                  isMax: true,
                  max: 1000,
                  isRequired: false,
                })}
              >
                <Input.TextArea
                  style={{ height: 70 }}
                  placeholder="Notes"
                  className="form-select textarea"
                />
              </Form.Item>
            )}

            <div className="modal-footer">
              <ButtonComponent
                text={`Update ${isVersion ? "Version" : "Exhibit"}`}
                isLoading={isLoading}
              />
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default EditExhibitModal;
