import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ViewAllBtn,
  EmptyBox,
  CardLoader,
  CardHeader,
  CustomInfiniteScroll,
  ProfileImage,
} from "../../../../components";
import { MilestonDetailView } from "../../transactionDetail/partials/milestones/components";
import { useCustomDispatch } from "../../../../helpers/useCustomDispatch";
import {
  setUserDetailDrawer,
  getDashboardActivityLogsRequest,
} from "../../../../redux/slicers/general";
import {
  ALERT_TYPES,
  DATA_PER_PAGE_LIMIT,
  NO_DATA_FOUND_ERROR,
} from "../../../../constants";
import { toastAlert } from "../../../../services/utils";

function ActivityLogs() {
  //STATES
  const [total, setTotal] = useState(null);
  const [offset, setOffset] = useState(0);
  const [isMoreData, setMoreData] = useState(false);
  const [isMilestonePreview, setMilestonePreview] = useState(false);
  const [milestoneSlug, setMilestoneSlug] = useState(null);

  //REDUX DATA
  const { isEnterpriseUser } = useSelector((state) => state.user.data);
  const { activitylogs } = useSelector((state) => state.general);

  //CONST VALS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const BOX_HEIGHT = 275;

  // CUSTOM DISPATCH
  const [getActivityLogs, isLoading] = useCustomDispatch(
    getDashboardActivityLogsRequest
  );

  // HELPERS
  const getActivityLogsHelper = () => {
    getActivityLogs();
  };

  //HOOKS
  useEffect(() => {
    if (!isMilestonePreview) getActivityLogsHelper(false);
  }, [offset, isMilestonePreview]);

  //HANDLERS
  const handleNextData = () => {
    setOffset(offset + DATA_PER_PAGE_LIMIT);
    setMoreData(true);
  };
  const navigateLogHandler = ({ isDeleted, isMilestone = false, route }) => {
    if (isDeleted) {
      toastAlert(NO_DATA_FOUND_ERROR, ALERT_TYPES.ERROR);
      return;
    }
    if (isMilestone) {
      setMilestonePreview(true);
      setMilestoneSlug(route);
      return;
    }
    navigate(route);
  };
  const setMilestonePreviewHandler = () => {
    setMilestonePreview(!isMilestonePreview);
  };

  return (
    <>
      <div className="card">
        {isEnterpriseUser ? (
          <ViewAllBtn disableViewAll={true} title={"Activity logs"} size={20} />
        ) : (
          <CardHeader
            isFilter
            title="Activity Logs"
            placeholder="Search activity logs..."
            isFilterTab={false}
          />
        )}

        <div className="activity-logs-container ">
          {isLoading & !isMoreData ? (
            <CardLoader size={14} height={BOX_HEIGHT} />
          ) : (
            <CustomInfiniteScroll
              dataLength={activitylogs?.length}
              total={activitylogs?.length}
              next={handleNextData}
              isWholePage={false}
              maxHeight={BOX_HEIGHT}
              minHeight={BOX_HEIGHT}
            >
              {activitylogs.length >= 1 ? (
                activitylogs.map((item, i) => (
                  <div className="activity-log" key={i}>
                    <ProfileImage
                      image={item.thumb}
                      isBlocked={!item?.isActive}
                      color={item.profilebg}
                      username={item.username}
                      classname="user-thumb"
                      size={12}
                      onClick={() =>
                        dispatch(
                          setUserDetailDrawer({
                            visibility: true,
                            id: item.userid,
                          })
                        )
                      }
                    />
                    <div className="activity-detail">
                      <p
                        className="detail c-p"
                        onClick={() => {
                          navigateLogHandler(item);
                        }}
                      >
                        {item.detail}
                      </p>
                      <p className="date">{item.date}</p>
                    </div>
                  </div>
                ))
              ) : (
                <EmptyBox height={BOX_HEIGHT} imgwidth={80} size={15} />
              )}
            </CustomInfiniteScroll>
          )}
        </div>
      </div>
      <MilestonDetailView
        milestoneslug={milestoneSlug}
        modalPreview={isMilestonePreview}
        handleClose={setMilestonePreviewHandler}
      />
    </>
  );
}

export default ActivityLogs;
