import { Dropdown, Menu } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  DeleteModal,
  ProfileImage,
  LeaveRoomModal,
} from "../../../../../../../components";
import { TRANSACTION_TABS_ROUTE } from "../../../../../../../constants";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { setUserDetailDrawer } from "../../../../../../../redux/slicers/general";
import {
  checkLastAdminRequest,
  leaveDataRoomRequest,
  makeRoomAdminRequest,
  removeDataroomAdminRequest,
  removeFolderVisibilityRequest,
  removeRoomVisibilityRequest,
} from "../../../../../../../redux/slicers/transactiondataroom";
import { replaceValInString } from "../../../../../../../services/utils";
import { Images } from "../../../../../../../themes";
import "./styles.scss";

function CurrentMembers({
  members,
  maxHeight = 460,
  noTitle,
  isEditable,
  isOwner,
  handleRemoveVisibility,
}) {
  //CONST VALS
  const dispatch = useDispatch();
  const isEditAllowed = isEditable;
  const { dataid, folder, id, tab, room } = useParams();
  const navigate = useNavigate();
  //STATES
  const [removeModalPreview, setRemoveModalPreview] = useState(false);
  const [selectedMember, setSelectedMember] = useState([]);
  const [isMakeAdmin, setIsMakeAdmin] = useState(false);
  const [isLeaveRoom, setIsLeaveRoom] = useState(false);
  const [isLastAdmin, setIsLastAdmin] = useState(false);
  const [leaveRoomModalPreview, setLeaveRoomModalPreview] = useState(false);

  //CUSTOM DISPATCH
  const [removeVisibility, removeVisibilityLoader] = useCustomDispatch(
    folder ? removeFolderVisibilityRequest : removeRoomVisibilityRequest
  );
  const [makeAdmin, makeAdminLoader] = useCustomDispatch(makeRoomAdminRequest);
  const [checkLastAdmin] = useCustomDispatch(checkLastAdminRequest);
  const [leaveDataRoom, leaveRoomLoader] =
    useCustomDispatch(leaveDataRoomRequest);
  const [removeDataroomAdmin, removeAdminLoader] = useCustomDispatch(
    removeDataroomAdminRequest
  );

  // REDUX DATA
  const { data: userdetail } = useSelector((state) => state.user);

  //HANDLERS
  const handleRemoveMember = () => {
    const payload = {};
    if (isMakeAdmin) {
      payload["userId"] = selectedMember;
      payload["dataRoomSlug"] = dataid;
      makeAdmin({
        payload,
        logic() {
          removeMemberModalPreviewHandler();
          setIsMakeAdmin(false);
        },
      });
    } else {
      payload["visibilities"] = selectedMember;
      removeVisibility({
        payload,
        pathParams: folder ? folder : dataid,
        logic(res) {
          removeMemberModalPreviewHandler();
          handleRemoveVisibility();
        },
      });
    }
  };

  const checkAdminHandler = () => {
    checkLastAdmin({
      pathParams: dataid,
      logic(res) {
        setIsLastAdmin(res.data);
        leaveRoomModalPreviewHandler();
      },
    });
  };

  const removeMemberModalPreviewHandler = () => {
    setRemoveModalPreview(!removeModalPreview);
  };

  const leaveRoomModalPreviewHandler = () => {
    setLeaveRoomModalPreview(!leaveRoomModalPreview);
  };

  const leaveDataRoomHandler = (val) => {
    const payload = {};
    if (val) {
      payload["userId"] = val ?? "";
    }
    leaveDataRoom({
      pathParams: dataid,
      payload,
      logic() {
        leaveRoomModalPreviewHandler();
        setIsLeaveRoom(false);
        handleLeaveRoom();
      },
    });
  };

  const removeDataRoomAdminHandler = (val) => {
    const payload = { userId: selectedMember, makeAdmin: isLastAdmin };
    if (val) {
      payload["userId"] = val;
    }
    removeDataroomAdmin({
      pathParams: dataid,
      payload,
      logic(res) {
        leaveRoomModalPreviewHandler();
        setIsLeaveRoom(false);
      },
    });
  };

  const handleLeaveRoom = () => {
    const vals = {
      ":id": id,
      ":tab": tab,
      ":room": room,
    };
    navigate(replaceValInString(TRANSACTION_TABS_ROUTE, vals));
  };
  // CUSTOM COMPONENT

  function MemberInfoBox({ data }) {
    //CONST VALS
    const isLoggedinUser = (data.id ?? data.userid) === userdetail.user_id;
    const isAdmin = data.isAdmin;
    const menuItems = [
      ...(isOwner && data.isActive
        ? [
            {
              key: "1",
              label: (
                <span
                  className="working-groups-options"
                  onClick={() => {
                    adminRoleToggle(isAdmin, data.id);
                  }}
                >
                  <p>{isAdmin ? "Remove as Admin" : "Make Admin"}</p>
                </span>
              ),
            },
          ]
        : [
            ...(data?.isAdmin
              ? [
                  {
                    key: "1",
                    label: (
                      <span
                        className="working-groups-options"
                        onClick={() => {
                          adminRoleToggle(isAdmin, data.id);
                        }}
                      >
                        <p>{"Remove as Admin"}</p>
                      </span>
                    ),
                  },
                ]
              : []),
          ]),
      ...(isOwner && !isLoggedinUser
        ? [
            {
              key: "2",
              label: (
                <span
                  className="working-groups-options"
                  onClick={() => {
                    setSelectedMember([data.id]);
                    removeMemberModalPreviewHandler();
                  }}
                >
                  <p>Remove From Room</p>
                </span>
              ),
            },
          ]
        : []),
      ...(isLoggedinUser
        ? [
            {
              key: "3",
              label: (
                <span
                  className="working-groups-options"
                  onClick={() => {
                    setIsLeaveRoom(true);
                    checkAdminHandler();
                  }}
                >
                  <p>Leave Room</p>
                </span>
              ),
            },
          ]
        : []),
    ];

    // HANDLERS
    const adminRoleToggle = (isAdmin, memberid) => {
      setSelectedMember(memberid);
      if (isAdmin) {
        checkAdminHandler();
      } else {
        removeMemberModalPreviewHandler();
        setIsMakeAdmin(true);
      }
    };
    //CUSTOM COMPONENTS
    const userMenu = () => <Menu items={menuItems} />;
    return (
      <div className="current-members-wrapper" key={data.id}>
        <span className="member-info">
          <div className="member-profile-img-wrapper">
            {data.isAdmin && data?.isActive && (
              <img src={Images.isAdminCrown} className="admin-crown" />
            )}
            <ProfileImage
              username={data.username}
              image={data.image}
              color={data.profilebg}
              isBlocked={!data?.isActive}
              onClick={() => {
                dispatch(
                  setUserDetailDrawer({
                    visibility: true,
                    id: data.userid ?? data.id,
                  })
                );
              }}
            />
          </div>
          <div>
            <h3
              className="c-p"
              onClick={() => {
                dispatch(
                  setUserDetailDrawer({
                    visibility: true,
                    id: data.userid ?? data.id,
                  })
                );
              }}
            >
              {data.username}
            </h3>
            <p className="email">{data.email}</p>
          </div>
        </span>
        {isEditAllowed &&
          (!folder ? (
            menuItems.length !== 0 && (
              <Dropdown
                getPopupContainer={(trigger) => trigger.parentElement}
                trigger={"click"}
                overlay={() => userMenu()}
              >
                <Images.threeDot className="c-p" />
              </Dropdown>
            )
          ) : (
            <Images.CloseIcon
              className="remove-btn"
              onClick={() => {
                setSelectedMember([data.id]);
                removeMemberModalPreviewHandler();
              }}
            />
          ))}
      </div>
    );
  }
  return (
    <>
      {!noTitle && <h3 className="section-title">Current Access</h3>}
      <div
        className="members-listing-wrapper"
        style={{ maxHeight: `${maxHeight}px` }}
      >
        {members?.map((item, index) => (
          <MemberInfoBox data={item} key={index} />
        ))}
      </div>
      <DeleteModal
        description={`Are you sure you want to ${
          isMakeAdmin
            ? "make this user an admin"
            : "revoke this member's access?"
        }`}
        title={isMakeAdmin ? "Make Admin" : "Remove Access"}
        preview={removeModalPreview}
        handleClose={() => {
          removeMemberModalPreviewHandler();
          setIsMakeAdmin(false);
        }}
        handleDelete={handleRemoveMember}
        isLoading={removeVisibilityLoader || makeAdminLoader}
        btnText={isMakeAdmin ? "Confirm" : "Remove"}
        width={450}
        btnPrimary={isMakeAdmin}
      />
      <LeaveRoomModal
        isAdmin={isLastAdmin}
        open={leaveRoomModalPreview}
        title={isLeaveRoom ? "Leave Room" : "Remove Admin"}
        description={`Are you sure you want to ${
          isLeaveRoom ? "leave this room" : "remove this admin"
        }?`}
        btnLoader={leaveRoomLoader || removeAdminLoader}
        members={members}
        btnText={isLeaveRoom ? "Leave Room" : "Remove Admin"}
        onSubmit={
          isLeaveRoom ? leaveDataRoomHandler : removeDataRoomAdminHandler
        }
        handleClose={() => {
          setIsLeaveRoom(false);
          leaveRoomModalPreviewHandler();
        }}
        tooltip="Kindly assign an admin before leaving."
      />
    </>
  );
}

export default CurrentMembers;
