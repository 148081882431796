import React from "react";
import { Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";
import {
  ButtonComponent,
  DeleteModal,
  ProfileImage,
} from "../../../../../../../components";
import { useDispatch } from "react-redux";
import {
  setCompanyDetailDrawer,
  setUserDetailDrawer,
} from "../../../../../../../redux/slicers/general";
import { Images } from "../../../../../../../themes";
import { useState } from "react";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import {
  removeChecklistResponsibilityRequest,
  removeChecklistVisibilityRequest,
} from "../../../../../../../redux/slicers/transactionchecklist";
import { useNavigate, useParams } from "react-router-dom";
import {
  replaceValInString,
  toastAlert,
} from "../../../../../../../services/utils";
import {
  ALERT_TYPES,
  TRANSACTION_DETAIL_ROUTE,
} from "../../../../../../../constants";

const ChecklistVisibilityModal = ({
  handleClose,
  data,
  preview,
  title = "Visibility",
  description = "Details Below",
  isEdit,
  isRoom,
  isCompany,
  isRemoveAll,
}) => {
  //STATES
  const [selected, setSelected] = useState([]);
  const [confirmationModalPreview, setConfirmationModalPreview] =
    useState(false);

  //CUSTOM DISPATCH
  const [removeResponsible, removeLoader] = useCustomDispatch(
    removeChecklistResponsibilityRequest
  );
  const [removeVisibility, visibilityLoader] = useCustomDispatch(
    removeChecklistVisibilityRequest
  );

  // CONST VALS
  const dispatch = useDispatch();
  const isNotUser = isRoom || isCompany;
  const isRemoveAllowed = isEdit;
  const { dataid, id } = useParams();
  const navigate = useNavigate();
  const isLoading = removeLoader || visibilityLoader;
  const lastItem = selected?.length !== data?.length - 1 && data?.length > 1;

  //HELPERS
  const requestHelper = (request, payload, removeAll) => {
    request({
      payload,
      pathParams: dataid,
      logic(res) {
        closeHandler();
        toastAlert(res.message, ALERT_TYPES.SUCCESS);
        removeAll && confirmationModalPreviewHandler();
      },
    });
  };

  // HANDLERS
  const closeHandler = () => {
    handleClose();
    setSelected([]);
  };
  const roomNavigationHandler = (slug) => {
    const vals = { ":id": id, ":room": slug };
    navigate(replaceValInString(TRANSACTION_DETAIL_ROUTE, vals));
  };
  const setDetailDrawer = (id) => {
    if (isRoom) {
      roomNavigationHandler(id);
      return;
    }
    const request = isCompany ? setCompanyDetailDrawer : setUserDetailDrawer;
    dispatch(
      request({
        visibility: true,
        id,
      })
    );
  };

  const handleSave = (removeAll) => {
    const payload = { ids: removeAll ? data?.map((x) => x.id) : selected };

    requestHelper(
      isRoom ? removeVisibility : removeResponsible,
      payload,
      removeAll
    );
  };

  const handleRemove = (id) => {
    setSelected([...selected, id]);
  };

  const confirmationModalPreviewHandler = () => {
    setConfirmationModalPreview(!confirmationModalPreview);
  };

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={389}
      className="checklist-visibility-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
          <ul className="checklist-grps">
            {data?.map((item, index) => {
              const bgcolor = isRoom ? item.bgColor : item.profilebg;
              const thumbnail = isCompany ? item.companylogo : item.image;
              const username = isRoom
                ? item.roomname
                : isCompany
                ? item.companyname
                : item.username;
              const isRemoved = selected.find((x) => x === item.id);
              return (
                !isRemoved && (
                  <li key={index}>
                    <div className="content">
                      <ProfileImage
                        isBlocked={!isNotUser && !item?.isActive}
                        image={thumbnail}
                        color={bgcolor}
                        size={12}
                        username={username}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDetailDrawer(
                            isRoom
                              ? item.slug
                              : item.userid ?? item.id ?? item.companyid
                          );
                        }}
                      />
                      <div className="info">
                        <h3
                          className={"c-p"}
                          onClick={(e) => {
                            e.stopPropagation();
                            setDetailDrawer(
                              isRoom
                                ? item.slug
                                : item.userid ?? item.id ?? item.companyid
                            );
                          }}
                        >
                          {username}
                        </h3>
                        {!isRoom && !isCompany && <p>{item?.email}</p>}
                      </div>
                    </div>
                    {isRemoveAllowed && (isRoom ? lastItem : true) && (
                      <Images.CloseIcon
                        className="c-p"
                        onClick={() => handleRemove(item.id)}
                      />
                    )}
                  </li>
                )
              );
            })}
          </ul>
        </div>
        {isEdit && (
          <div className="modal-footer">
            {isRemoveAll && !isRoom && (
              <span
                className="remove-all-btn"
                onClick={confirmationModalPreviewHandler}
              >
                Remove All
              </span>
            )}

            <ButtonComponent
              text="Save"
              isLoading={isLoading}
              style={{ width: "50%" }}
              onClick={() => handleSave(false)}
              disabled={selected.length < 1}
            />
          </div>
        )}
      </div>
      <DeleteModal
        title={"Remove All Parties"}
        description={"Are you sure you want to remove all parties?"}
        preview={confirmationModalPreview}
        handleClose={confirmationModalPreviewHandler}
        btnText={"Remove"}
        handleDelete={() => handleSave(true)}
        isLoading={removeLoader}
      />
    </Modal>
  );
};

export default ChecklistVisibilityModal;
