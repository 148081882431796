import { Row } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardLoader, EmptyBox } from "../../../../../components";
import { useCustomDispatch } from "../../../../../helpers/useCustomDispatch";
import {
  loadTransactionRoomRequest,
  setThread,
} from "../../../../../redux/slicers/chat";
import { ChatDetailModal } from "../../../messages/components";
import { ChatBox } from "../../../messages/partials";
import "./styles.scss";

function TransactionMessages({ isRoom, isLoading }) {
  //STATES
  const [detailModalPreview, setDetailModalPreview] = useState(false);
  //REDUX DATA
  const { data: transactiondetail, roomDetail } = useSelector(
    (state) => state.transactiondetail
  );
  const { selectedRoom } = useSelector((state) => state.chat);
  //CONST VALS
  const roomdetail = isRoom ? roomDetail : transactiondetail?.room;
  const dispatch = useDispatch();

  //CUSTOM DISPATCH
  const [loadRoom, roomLoader] = useCustomDispatch(loadTransactionRoomRequest);

  //HANDLERS
  const detailModalPreviewHandler = () => {
    setDetailModalPreview(!detailModalPreview);
  };

  //HOOKS
  useEffect(() => {
    if (roomdetail?.chatRoomId)
      loadRoom({ queryParams: { roomId: roomdetail?.chatRoomId } });
  }, [roomdetail?.chatRoomId]);

  useEffect(() => {
    return () => {
      dispatch(setThread(null));
    };
  }, []);

  return (
    <section className="transaction-messages">
      <div className="title">
        <h3>Chat Room</h3>
        <p>View Details Below</p>
      </div>

      {isLoading ? (
        <CardLoader height={"calc(100vh - 300px)"} />
      ) : roomdetail?.chatRoomId ? (
        <Row>
          <ChatBox
            selected={selectedRoom}
            isTransaction
            isLoading={roomLoader}
            handleDetail={detailModalPreviewHandler}
            roomBg={roomdetail?.bgColor}
          />
        </Row>
      ) : (
        <EmptyBox height={"calc(100vh - 300px)"} imgwidth={120} size={20} />
      )}

      <ChatDetailModal
        preview={detailModalPreview}
        handleClose={detailModalPreviewHandler}
        chat={selectedRoom}
        isTransaction
      />
    </section>
  );
}

export default TransactionMessages;
