import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Form, Select } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ButtonComponent, CardLoader } from "../../../../../../../components";
import { Images } from "../../../../../../../themes";
import { useCustomDispatch } from "../../../../../../../helpers/useCustomDispatch";
import { SELECT_DOCUMENT_TYPE } from "../../../../../../../constants";
import { getAllResourcesFilesRequest } from "../../../../../../../redux/slicers/resources";
import { useSelector } from "react-redux";
import "./styles.scss";
import { inputFieldRule } from "../../../../../../../services/utils";
import { useParams } from "react-router-dom";
import {
  getAllDocsRoomVersionsRequest,
  getAllDocsVersionsRequest,
} from "../../../../../../../redux/slicers/transactiondocuments";

const SelectDocumentModal = ({
  handleClose,
  preview,
  handleSubmit,
  loader,
}) => {
  //STATES
  const [fileName, setfileName] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState(null);

  //REDUX DATA
  const { files } = useSelector((state) => state.resources);
  const { versions } = useSelector((state) => state.transactiondocuments);
  const { data } = useSelector((state) => state.transactiondetail);

  // CUSTOM DISPATCH
  const [getAllResources, resourceLoader] = useCustomDispatch(
    getAllResourcesFilesRequest
  );

  const [getAllVersions, versionsLoader] = useCustomDispatch(
    getAllDocsRoomVersionsRequest
  );

  //CONST VALS
  const [form] = Form.useForm();
  const { room } = useParams();
  const selectLoader = resourceLoader || versionsLoader;
  const isResource = selectedFileType === SELECT_DOCUMENT_TYPE[0].value;

  //HOOKS
  useEffect(() => {
    if (selectedFileType) {
      isResource
        ? getAllResources({ queryParams: { transactionTypeId: data?.typeid } })
        : getAllVersions({ pathParams: room });
    }
  }, [selectedFileType]);

  //HANDLERS
  const closeHandler = () => {
    handleClose();
    setfileName(null);
    setSelectedFileType(null);
    form.resetFields();
  };
  const handleRemoveFile = () => {
    form.resetFields(["file"]);
    setfileName(null);
  };
  const handleSelectFile = (val) => {
    const payload = { ...val };
    if (!isResource) {
      payload["file"] = versions.find((item) => item.slug === val.file)?.file;
    }
    handleSubmit(payload, isResource);
    closeHandler();
  };

  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={522}
      className="select-document-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>Upload Documents</h3>
            <p>Select Details Below</p>
          </div>
          <Form
            form={form}
            className="create-doc-form"
            onFinish={handleSelectFile}
          >
            <Row gutter={[20, 0]}>
              <Col span={24}>
                <Form.Item
                  name="name"
                  rules={inputFieldRule({
                    name: "Document type",
                    isRequired: true,
                  })}
                >
                  <Select
                    placeholder="Select Document Type"
                    onSelect={(values) => {
                      setSelectedFileType(values);
                      form.resetFields(["file"]);
                      setfileName(null);
                    }}
                  >
                    {SELECT_DOCUMENT_TYPE.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="file"
                  rules={inputFieldRule({
                    name: "Document",
                    isWhiteSpace: false,
                  })}
                >
                  <Select
                    placeholder="Select Document"
                    loading={selectLoader}
                    onSelect={(val, opt) => {
                      setfileName(opt.label);
                    }}
                    disabled={!selectedFileType}
                  >
                    {isResource
                      ? files?.map((item, index) => (
                          <Select.Option
                            value={item?.url}
                            key={index}
                            label={item?.url}
                          >
                            {item?.filename}
                          </Select.Option>
                        ))
                      : versions.map((item, index) => (
                          <Select.Option
                            value={item.slug}
                            label={item.file}
                            key={index}
                            disabled={item.isDocument}
                          >
                            <div className="exhibit-option">
                              {item?.isDocument ? (
                                <h3 style={{ pointerEvents: "none" }}>
                                  {item.name} (Main Document)
                                </h3>
                              ) : (
                                <>
                                  <Images.DocThumb />
                                  <div className="exhibit-info">
                                    <h3>{item.name}</h3>
                                    <p>Version</p>
                                  </div>
                                </>
                              )}
                            </div>
                          </Select.Option>
                        ))}
                  </Select>
                </Form.Item>
              </Col>
              {fileName && (
                <Col span={24}>
                  <div className="uploaded-file-wrapper">
                    <div className="left-wrapper">
                      <Images.DocThumb className="doc-thumb" />
                      <div className="file-info">
                        <h3>{fileName}</h3>
                        {/* <p>Size 456kb</p> */}
                      </div>
                    </div>
                    <Images.CrossRed
                      className="c-p"
                      onClick={handleRemoveFile}
                    />
                  </div>
                </Col>
              )}
            </Row>
            <div className="modal-footer">
              <ButtonComponent text="Select" isLoading={loader} />
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default SelectDocumentModal;
