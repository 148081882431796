import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "antd";
import { ButtonComponent, UserApprovalCard } from "..";
import { useCustomDispatch } from "../../helpers/useCustomDispatch";
import {
  removeTeamMemberRequest,
  getPendingMembersRequest,
} from "../../redux/slicers/company";
import { toastAlert } from "../../services/utils";
import "./styles.scss";

const RemoveTeamMember = ({ handleClose, selectedData, preview }) => {
  const [removeTeamMember, isLoading] = useCustomDispatch(
    removeTeamMemberRequest
  );
  const [getPendingMembers] = useCustomDispatch(getPendingMembersRequest);
  const handleSubmit = () => {
    removeTeamMember({
      pathParams: selectedData.id,
      logic(res) {
        toastAlert(res.message);
        handleClose();
        getPendingMembers();
      },
    });
  };
  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={450}
      className="remove-member"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>Remove Team Member</h3>
            <p>Are You Sure To Remove Team Member</p>
          </div>
          <UserApprovalCard data={selectedData} />
        </div>
        <div className="modal-footer">
          <ButtonComponent
            text="Remove"
            disabled={isLoading}
            isLoading={isLoading}
            className="delete-btn"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default RemoveTeamMember;
