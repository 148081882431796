import React, { useEffect } from "react";
import { Modal, Row, Col, Form, Input, Select } from "antd";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useCustomDispatch } from "../../helpers/useCustomDispatch";
import {
  createAddressRequest,
  updateAddressRequest,
} from "../../redux/slicers/user";
import ButtonComponent from "../ButtonComponent";
import { inputFieldRule, toastAlert } from "../../services/utils";
import countryList from "react-select-country-list";

const AddAddressModal = ({
  handleClose,
  preview,
  isEdit,
  selectedData,
  company,
}) => {
  // CUSTOM DISPATCH
  const [createAdd, createLoader] = useCustomDispatch(createAddressRequest);
  const [updateAdd, updateLoader] = useCustomDispatch(updateAddressRequest);

  // CONST VALS
  const isLoading = createLoader || updateLoader;
  const [form] = Form.useForm();
  const countryOptions = countryList().getData();

  // HELPERS
  const requestHelper = (request, payload, pathParams) => {
    request({
      payload,
      pathParams,
      logic(response) {
        toastAlert(response.message);
        closeHandler();
      },
    });
  };

  // HOOKS
  useEffect(() => {
    form.setFieldsValue({
      branchName: selectedData?.name,
      address: selectedData?.address,
      address2: selectedData?.address2,
      city: selectedData?.city,
      country: selectedData?.country,
      state: selectedData?.state,
      zip: selectedData?.zip,
    });
  }, [selectedData]);

  // HANDLERS
  const closeHandler = () => {
    form.resetFields();
    handleClose();
  };

  const handleSubmit = () => {
    const values = form.getFieldsValue();
    let payload = { ...values };
    payload["companyId"] = company;
    if (isEdit) {
      requestHelper(updateAdd, payload, selectedData.id);
    } else {
      requestHelper(createAdd, payload);
    }
  };
  return (
    <Modal
      destroyOnClose
      visible={preview}
      centered
      footer={null}
      width={522}
      className="add-address"
      getContainer={false}>
      <Form className="modal-wrapper" form={form} onFinish={handleSubmit}>
        <div className="modal-body ">
          <div className="close-btn" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h3>{isEdit ? "Edit" : "Add New"} Office Address</h3>
            <p>Fill The Details Below</p>
          </div>
          <Row gutter={[15, 16]}>
            <Col xs={24}>
              <div className="fields-title">Title</div>
              <Form.Item
                name="branchName"
                rules={inputFieldRule({ name: "Title", isMax: true, max: 60 })}>
                <Input
                  placeholder="Enter title"
                  className="form-select"
                  autoFocus
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <div className="fields-title">Street Address 1</div>
              <Form.Item
                name="address"
                rules={inputFieldRule({
                  name: "Address",
                  isMax: true,
                  max: 200,
                })}>
                <Input
                  placeholder="Enter street address 1"
                  className="form-select"
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <div className="fields-title">Street Address 2</div>
              <Form.Item
                name="address2"
                rules={inputFieldRule({
                  name: "Address",
                  isRequired: false,
                  isMax: true,
                  max: 200,
                })}>
                <Input
                  placeholder="Enter street address 2"
                  className="form-select"
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <div className="fields-title">City</div>
              <Form.Item
                name="city"
                rules={inputFieldRule({
                  name: "City",
                  isMax: true,
                  max: 100,
                })}>
                <Input placeholder="Enter city" className="form-select" />
              </Form.Item>
            </Col>

            <Col xs={12}>
              <div className="fields-title">State</div>
              <Form.Item
                name="state"
                rules={inputFieldRule({
                  name: "State",
                  isMax: true,
                  max: 100,
                })}>
                <Input placeholder="Enter state" className="form-select" />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <div className="fields-title">Postal/Zip Code</div>
              <Form.Item
                name="zip"
                rules={inputFieldRule({
                  name: "Postal/Zip Code",
                  isMax: true,
                  max: 5,
                  isAlphabetsAndNumber: true,
                })}>
                <Input
                  maxLength={5}
                  type={"text"}
                  placeholder="Enter postal/zip code"
                  className="form-select"
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <div className="fields-title">Country</div>
              <Form.Item
                name="country"
                rules={inputFieldRule({
                  name: "Country",
                  isMax: true,
                  max: 100,
                })}>
                <Select
                  options={countryOptions}
                  placeholder="Enter country"
                  maxLength={85}
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }>
                  {countryOptions.map((data, index) => (
                    <Select.Option value={data.label} key={index}>
                      {data.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <ButtonComponent
            text={isEdit ? "Update" : "Add"}
            disabled={isLoading}
            isLoading={isLoading}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default AddAddressModal;
