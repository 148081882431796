import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonComponent,
  Tabs,
  CardLoader,
  EmptyBox,
  CustomInfiniteScroll,
  UpdateMemberModal,
  ProfileImage,
} from "../../../../components";
import { useCustomDispatch } from "../../../../helpers/useCustomDispatch";
import {
  checkLastEnterpriseAdminRequest,
  getPendingMembersRequest,
  getTeamMembersRequest,
  removeSelfAdminRequest,
} from "../../../../redux/slicers/company";
import { STATUS_FILTER_TABS, DATA_PER_PAGE_LIMIT } from "../../../../constants";
import { setUserDetailDrawer } from "../../../../redux/slicers/general";
import { Images } from "../../../../themes";
import { RemoveAdminModal } from "./components";

function EnterpriseMembers({ addresses, isMemberAdded, isSettingView }) {
  // CONST VALS
  const dispatch = useDispatch();
  const INVITED_TAB = "invited";
  const SETTING_VIEW_LIMIT = 10;

  // STATES
  const [status, setStatus] = useState(true);
  const [isMoreData, setMoreData] = useState(false);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [selectedData, setselectedData] = useState(null);
  const [isDataUpdated, setDataUpdated] = useState(false);
  const [updateModalPreview, setUpdateModalPreview] = useState(false);
  const [isLastAdmin, setIsLastAdmin] = useState(false);
  const [removeAdminModalPreview, setRemoveAdminModalPreview] = useState(false);

  // REDUX DATA
  const { teamMembers } = useSelector((state) => state.company);
  const { isEnterpriseUser, user_id } = useSelector((state) => state.user.data);

  // DISPATCH CALLS
  const [getTeamMembers, isLoading] = useCustomDispatch(getTeamMembersRequest);
  const [checkLastAdmin, checkLoader] = useCustomDispatch(
    checkLastEnterpriseAdminRequest
  );
  const [removeAdmin, removeAdminLoader] = useCustomDispatch(
    removeSelfAdminRequest
  );

  // HELPERS
  const getTeamMembersHelper = (isSearched, isStatusChange) => {
    const queryParams = {
      limit: isSettingView ? SETTING_VIEW_LIMIT : DATA_PER_PAGE_LIMIT,
      offset: isStatusChange ? 0 : offset,
    };
    if (isStatusChange || isSearched) {
      setMoreData(false);
    }
    if (status === INVITED_TAB) {
      queryParams["isInvite"] = true;
    } else {
      queryParams["active"] = status;
    }
    if (isSearched) {
      queryParams["search"] = isSearched;
    }
    getTeamMembers({
      queryParams,
      otherkeys: { isSort: true },
      logic: (res) => {
        setTotal(isSettingView ? SETTING_VIEW_LIMIT : res.total);
      },
    });
  };

  // HOOKS
  useEffect(() => {
    setOffset(0);
    getTeamMembersHelper(false, true);
  }, [status]);

  useEffect(() => {
    getTeamMembersHelper(false);
  }, [isDataUpdated, isMemberAdded, offset]);

  // HANDLERS
  const handleSearch = (val) => {
    getTeamMembersHelper(val);
    setOffset(0);
  };

  const setselectedDataHandler = (data) => {
    setselectedData(data);
  };

  const updateModalPreviewHandler = () => {
    setUpdateModalPreview(!updateModalPreview);
  };

  const isDataUpdatedHandler = () => {
    setDataUpdated(!isDataUpdated);
  };

  const handleNextData = () => {
    setOffset(offset + DATA_PER_PAGE_LIMIT);
    setMoreData(true);
  };

  const removeAdminModalPreviewHandler = () => {
    setRemoveAdminModalPreview(!removeAdminModalPreview);
  };

  const checkLastAdminHandler = () => {
    checkLastAdmin({
      logic: (res) => {
        setIsLastAdmin(res);
        removeAdminModalPreviewHandler();
      },
    });
  };

  const removeAdminHandler = (val) => {
    const payload = {
      userId: val ? val : user_id,
      makeAdmin: val ? true : false,
    };
    removeAdmin({
      payload,
      logic() {
        removeAdminModalPreviewHandler();
      },
    });
  };
  return (
    <>
      {!isSettingView && (
        <>
          <div className="heading-tabs">
            <div className="teamMemberRequestView-heading">
              <h4>Team Members</h4>
            </div>
            <Tabs
              buttonsList={STATUS_FILTER_TABS}
              setState={setStatus}
              state={status}
            />
          </div>
          <div
            className={`top-act-wrapper ${
              !isEnterpriseUser ? "full-width" : ""
            }`}
          >
            <div className="search-box">
              <Images.Search />
              <input
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                type="text"
                placeholder={"Search any team member by name or email..."}
              />
            </div>
            {isEnterpriseUser && (
              <ButtonComponent
                className="remove-myself-btn"
                text="Remove My Self From Admin"
                isLoading={checkLoader}
                onClick={checkLastAdminHandler}
                disabled={isLoading}
              />
            )}
          </div>
        </>
      )}
      <div className="table-container">
        <div
          className="table-container-row border-bottom"
          style={{
            justifyContent:
              status === INVITED_TAB ? "flex-start" : "space-between",
          }}
        >
          {status !== INVITED_TAB && <li className="table-title">Name</li>}
          <li
            className="table-title"
            style={{ width: status === INVITED_TAB ? "35%" : 165 }}
          >
            Email
          </li>
          {status === INVITED_TAB && (
            <li className="table-title" style={{ width: "50%" }}>
              Branch
            </li>
          )}
          {status !== INVITED_TAB && (
            <>
              <li className="table-title">Role</li>
              {!isSettingView && <li className="table-title">Cell phone</li>}
              <li className="table-title" style={{ width: 40 }}></li>
            </>
          )}
        </div>
        {isLoading & !isMoreData ? (
          <CardLoader height={290} />
        ) : (
          <>
            {teamMembers.length >= 1 ? (
              <CustomInfiniteScroll
                dataLength={teamMembers.length}
                next={handleNextData}
                total={total}
                maxHeight={240}
                minHeight={290}
              >
                {teamMembers.map((item, i) => {
                  const isOdd = i % 2 == 1;
                  return (
                    <div
                      key={i}
                      className={`table-container-row ${isOdd && "back-gray"}`}
                      style={{
                        justifyContent:
                          status === INVITED_TAB
                            ? "flex-start"
                            : "space-between",
                      }}
                    >
                      {status !== INVITED_TAB && (
                        <li className="image-name">
                          <ProfileImage
                            size={10}
                            image={item.thumb}
                            isBlocked={!item?.isActive}
                            color={item.profilebg}
                            username={item.username}
                            onClick={() =>
                              dispatch(
                                setUserDetailDrawer({
                                  visibility: true,
                                  id: item.id,
                                })
                              )
                            }
                          />
                          <p
                            onClick={() =>
                              dispatch(
                                setUserDetailDrawer({
                                  visibility: true,
                                  id: item.id,
                                })
                              )
                            }
                            className="c-p"
                          >
                            {item.username}
                          </p>
                        </li>
                      )}
                      <li
                        style={{
                          width: status === INVITED_TAB ? "35%" : 180,
                        }}
                      >
                        {item.email}
                      </li>
                      {status === INVITED_TAB && (
                        <li style={{ width: "40%" }}>{item.branchName}</li>
                      )}

                      {status !== INVITED_TAB && (
                        <>
                          <li>{item.role}</li>
                          {!isSettingView && <li>{item.phone}</li>}
                          <li style={{ width: 40 }}>
                            <Images.Edit
                              className="c-p"
                              onClick={() => {
                                setselectedDataHandler(item);
                                updateModalPreviewHandler();
                              }}
                            />
                          </li>
                        </>
                      )}
                    </div>
                  );
                })}
              </CustomInfiniteScroll>
            ) : (
              <EmptyBox height={290} />
            )}
          </>
        )}
      </div>
      <UpdateMemberModal
        selectedData={selectedData}
        addresses={addresses}
        modalPreview={updateModalPreview}
        handleClose={updateModalPreviewHandler}
        handleSuccess={isDataUpdatedHandler}
      />
      <RemoveAdminModal
        preview={removeAdminModalPreview}
        isAdmin={isLastAdmin}
        handleClose={removeAdminModalPreviewHandler}
        members={teamMembers}
        handleSubmit={removeAdminHandler}
        isLoading={removeAdminLoader}
      />
    </>
  );
}

export default EnterpriseMembers;
