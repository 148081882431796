import _ from "lodash";
import {
  DEFAULT_COMPANY_LOGO,
  DEFAULT_PROFILE_COLOR,
  PERMISSIONS,
  VIEW_PERMISSIONS,
} from "../constants";
import DataHandler from "../services/DataHandler";
import { manipulateUserData, manipulateUsersList } from "./user";

// TRANSACTION DETAILS

export const manipulateGroupMemberData = (data, id, isRoom) => {
  if (_.isEmpty(data)) return {};
  let member = {};
  member["id"] = data?.id ?? 0;
  member["firstName"] = data?.firstName ?? "";
  member["lastName"] = data?.lastName ?? "";
  member["username"] = data?.firstName?.trim() + " " + data?.lastName?.trim();
  member["email"] = data?.email ?? "";
  member["image"] = data?.image;
  member["isInvited"] = data?.isInvited ?? false;
  member["isMember"] = data?.rooms?.length === 0 ? false : true;
  member["isAdmin"] = data?.rooms?.[0]?.isAdmin ?? false;
  member["isTransactionAdmin"] = data?.advisories?.[0]?.isAdmin ?? false;
  member["profilebg"] = data?.bgcolor ?? DEFAULT_PROFILE_COLOR;
  member["companyId"] = id ?? 0;
  member["isActive"] = data?.isActive && !data?.isBlock;
  member["roomStatus"] = data?.memberStatus ?? null;
  member["removeResponse"] = data?.removeResponse ?? null;

  if (isRoom) {
    member["isPermitted"] =
      (data?.userPermissions?.[0]?.permissionValue !== "no" ? true : false) ??
      false;
  }

  return member;
};

export const manipulateGroupMembersList = (data, id, isRoom) => {
  let members = [];

  data?.forEach((item) => {
    members.push(manipulateGroupMemberData(item, id, isRoom));
  });

  members.sort(function (a, b) {
    if (a[isRoom ? "isAdmin" : "isTransactionAdmin"]) {
      return -1;
    } else if (b[isRoom ? "isAdmin" : "isTransactionAdmin"]) {
      return 1;
    } else {
      return a.firstName.localeCompare(b.firstName);
    }
  });

  return members;
};

export const manipulateWorkingGroupsData = (data, isRoom) => {
  if (_.isEmpty(data)) return {};
  let usercompany = DataHandler.getStore().getState().user.data.companyName;
  let workingGroups = {};
  const partyrole = data?.userCompany?.[0]?.advisories?.[0]?.party?.name ?? "";
  workingGroups["id"] = data?.id ?? 0;
  workingGroups["name"] = data?.name ?? "";
  workingGroups["logo"] = data?.logo ?? "";
  workingGroups["isUserCompany"] = data?.name === usercompany;
  workingGroups["isInvited"] = data?.isInvited ?? false;
  workingGroups["members"] = manipulateGroupMembersList(
    data?.userCompany,
    data?.id,
    isRoom
  );
  workingGroups["role"] =
    partyrole == "Other"
      ? data?.userCompany?.[0]?.advisories?.[0]?.userDefineRole
      : partyrole;

  return workingGroups;
};

export const manipulateWorkingGroupsInvitationList = (data, groups) => {
  data.forEach((element) => {
    let user_payload = {
      firstName: element?.firstName,
      lastName: element?.lastName,
      email: element?.email,
      isInvited: true,
      isActive: true,
    };
    let company_payload = {
      isInvited: true,
      name: element?.companyName,
      logo: element?.logo,
      userCompany: [user_payload],
      id: element?.companyId,
    };
    const isExist = groups.findIndex((e) => e.id === element?.companyId);
    if (isExist >= 0) {
      groups[isExist]?.userCompany.push(user_payload);
    } else {
      groups.push(company_payload);
    }
  });
};

export const manipulateWorkingGroupsList = (data, isRoom) => {
  let workinggroups = [];
  let groups = [];
  const { workingGroups, invitations } = data;
  workingGroups.forEach((element) => {
    groups.push(element);
  });
  if (!isRoom) {
    manipulateWorkingGroupsInvitationList(invitations, groups);
  }
  groups?.forEach((item) => {
    workinggroups.push(manipulateWorkingGroupsData(item, isRoom));
  });

  let usercompany = DataHandler.getStore().getState().user.data.companyName;
  workinggroups.sort(function (a, b) {
    if (a.name === usercompany) {
      return -1;
    } else if (b.name === usercompany) {
      return 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  });
  return workinggroups;
};

export const manipulateTransactionMembersList = (data) => {
  let members = [];
  data.forEach((company) => {
    company?.userCompany.forEach((member) => {
      members.push(manipulateGroupMemberData(member));
    });
  });
  return members;
};

export const manipulateRoomData = (data) => {
  if (_.isEmpty(data)) return {};
  let room = {};
  room["name"] = data?.name ?? "";
  room["bgColor"] = data?.bgcolor ?? DEFAULT_PROFILE_COLOR;
  room["isAdmin"] = data?.isAdmin;
  room["roomStatus"] = data?.roomStatus ?? null;
  room["isRoom"] = data?.isRoom;
  room["slug"] = data?.slug;
  room["company"] = data?.company ?? [];
  room["id"] = data?.id ?? 0;
  room["members"] = manipulateUsersList(data?.member) ?? [];
  room["chatRoomId"] = data?.chat_room_id ?? null;

  return room;
};

export const manipulateRoomList = (data) => {
  let rooms = [];

  data?.forEach((item) => {
    rooms.push(manipulateRoomData(item));
  });

  return rooms;
};
export const manipulateTransactionData = (data, isRequest) => {
  if (_.isEmpty(data)) return {};
  let transaction = {};
  transaction["room"] = manipulateRoomData(data?.room);
  transaction["roomSlug"] = data?.room?.slug;
  transaction["inviteModified"] = data?.lastUpdate;
  transaction["name"] = data?.transactionName ?? 0;
  transaction["slug"] = data?.transactionSlug ?? "";
  transaction["type"] = data?.transactiontype?.title ?? "";
  transaction["typeid"] = data?.transactiontype?.id ?? "";
  transaction["questions"] = data?.questions ?? [];
  transaction["answers"] = data?.answers ?? [];
  transaction["isEdited"] = data?.isEdited ?? false;
  transaction["isSkip"] = data?.isSkip ?? false;
  transaction["id"] = data?.transactionId ?? 0;
  transaction["isAdminLoggedIn"] = data?.isAdmin ?? false;
  transaction["status"] = data?.status ?? false;
  transaction["permissions"] =
    manipulatePermissionsList(data?.permissions, true, data?.status) ?? [];
  transaction["requests"] = data?.requests ?? false;
  if (isRequest) {
    transaction["status"] = data?.status ? "Completed" : "Pending";
    transaction["transactionTypeId"] = data?.transactiontype?.title;
  }
  return transaction;
};

export const manipulateCompanyMembersList = (data) => {
  let members = [];

  data?.forEach((item) => {
    members.push(manipulateUserData(item));
  });

  return members;
};

export const manipulatePermissionData = (data) => {
  let permission = {};

  permission["text"] = data?.name ?? "";
  permission["slug"] = data?.slug ?? "";
  permission["options"] =
    Object.entries(data?.values).map(([key]) => ({
      key: key?.toLowerCase(),
      value: key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(),
    })) ?? {};
  permission["selectedOption"] =
    Object.keys(data?.values)
      .find((key) => data?.values[key] === true)
      ?.toLowerCase() ?? "yes";

  return permission;
};

export const manipulatePermissionsList = (data, filter, status) => {
  let permissions = [];

  data?.forEach((item) => {
    permissions.push(manipulatePermissionData(item));
  });

  if (filter) {
    const filtered = permissions.filter((x) => x.selectedOption !== "no");
    if (status) {
      const completePermission = filtered.filter((x) =>
        VIEW_PERMISSIONS.has(x.slug)
      );
      return completePermission;
    }
    return filtered;
  }
  return permissions;
};

export const manipulateRequestData = (data) => {
  let request = {};
  const title = PERMISSIONS.find((x) => x.access === data?.action)?.title;
  request["id"] = data?.id ?? 0;
  request["category"] = data?.actionCategory ?? "";
  request["isAdmin"] = data?.isAdmin ?? false;
  request["title"] = title ?? "";
  return request;
};

export const manipulateRequestsList = (data) => {
  let requests = [];
  data?.forEach((item) => {
    requests.push(manipulateRequestData(item));
  });
  return requests;
};
