import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useCustomDispatch } from "../../../../../../helpers/useCustomDispatch";
import {
  getDataRoomDetailRequest,
  getDataroomPermissionsRequest,
  getFolderDetailRequest,
} from "../../../../../../redux/slicers/transactiondataroom";
import { setUserDetailDrawer } from "../../../../../../redux/slicers/general";
import {
  DataroomNavbar,
  DataRoomNotesBox,
  DataRoomFolders,
  DataRoomDocuments,
  CreateDataroomModal,
  EditFolderAccessModal,
  UploadDocumentModal,
} from "../components";
import {
  ActivityLogs,
  ButtonComponent,
  CardLoader,
  DisabledTooltip,
  ProfileImage,
} from "../../../../../../components";
import MemberAccessModal from "../components/memberAccessModal";

export default function DataRoomDetail({
  transactionslug,
  slug,
  roomslug,
  isFolder,
  activityLogsVisible,
  isTransactionComplete,
}) {
  //STATES
  const [isLoading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [isDataUpdated, setDataUpdated] = useState(false);
  const [uploadFileModalPreview, setUploadFileModalPreview] = useState(false);
  const [createFolderModalPreview, setCreateFolderModalPreview] =
    useState(false);
  const [folderAccessModalPreview, setFolderAccessModalPreview] =
    useState(false);
  const [memberAccessmodalPreview, setMemberAccessmodalPreview] =
    useState(false);

  // REDUX DATA
  const { roomdata, folderdata } = useSelector(
    (state) => state.transactiondataroom
  );
  const { status } = useSelector((state) => state.transactiondetail.data);

  // CUSTOM DISPATCH
  const [getRoomDetail, roomLoader] = useCustomDispatch(
    getDataRoomDetailRequest
  );
  const [getFolderDetail, folderLoader] = useCustomDispatch(
    getFolderDetailRequest
  );
  const [getPermssionList, permissionLoader] = useCustomDispatch(
    getDataroomPermissionsRequest
  );

  //CONST VALS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = isFolder ? folderdata : roomdata;
  const detailLoader = roomLoader || folderLoader;
  const isEditAllowed = data?.isEdit;

  // HELPERS
  const getDetailsHelper = (request) => {
    request({
      pathParams: slug,
      callback() {
        setLoading(false);
      },
    });
  };
  // HOOKS
  useEffect(() => {
    getDetailsHelper(isFolder ? getFolderDetail : getRoomDetail);
  }, [slug, isFolder]);

  useEffect(() => {
    getPermssionList();
  }, []);

  //HANDLERS
  const searchTextHandler = (val) => {
    setSearchText(val);
  };
  const dataUpdatedHandler = () => {
    setDataUpdated(!isDataUpdated);
  };
  const createFolderModalPreviewHandler = () => {
    setCreateFolderModalPreview(false);
  };
  const memberAccessmodalPreviewHandler = () => {
    setMemberAccessmodalPreview(!memberAccessmodalPreview);
  };
  const uploadFileModalPreviewHandler = () => {
    setUploadFileModalPreview(!uploadFileModalPreview);
  };
  const folderAccesModalPreviewHandler = () => {
    setFolderAccessModalPreview(!folderAccessModalPreview);
  };

  return (
    <>
      <Row>
        {isLoading ? (
          <Col xs={24}>
            <CardLoader height={"65vh"} />
          </Col>
        ) : (
          <>
            <Col xs={24} xl={18}>
              <DataroomNavbar
                title={data.name}
                data={data}
                isBack={true}
                backFunc={() => {
                  navigate(-1);
                }}
                searchPlaceholder="Search any Folder or File..."
                searchText={searchText}
                searchHandler={searchTextHandler}
                isBtn={false}
                isEditable={
                  (data.isOwner || data.isRoomOwner || data.isAdmin) &&
                  !isTransactionComplete
                }
                transactionslug={transactionslug}
                isTransactionComplete={isTransactionComplete}
                isFolder={isFolder}
                permissionLoader={permissionLoader}
                isEditAllowed={isEditAllowed}
              />
              <div className="card mt-3">
                <div className="room-details-header">
                  <div className="room-header-left">
                    <div className="room-creator-info">
                      <ProfileImage
                        username={data.creatorInfo?.username}
                        image={data.creatorInfo?.image}
                        color={data.creatorInfo?.profilebg}
                        isBlocked={
                          detailLoader ? false : !data.creatorInfo?.isActive
                        }
                        onClick={() => {
                          dispatch(
                            setUserDetailDrawer({
                              visibility: true,
                              id: data.creatorInfo?.id,
                            })
                          );
                        }}
                      />
                      <div className="room-creator-info-text">
                        <p>Created By</p>
                        <h3>{data.creatorInfo?.username}</h3>
                      </div>
                    </div>
                    <div
                      className="data-room-users"
                      style={{
                        height: "auto",
                        borderBottomLeftRadius: 12,
                        borderBottomRightRadius: 12,
                      }}
                    >
                      <h5>{isFolder ? "Folder" : "Data Room"} Users</h5>
                      <div className="action">
                        <ul className="group-list">
                          {data?.visibilities
                            ?.slice(0, 2)
                            .map((item, index) => (
                              <li key={index}>
                                <ProfileImage
                                  username={item.username}
                                  color={item.profilebg}
                                  image={item.image}
                                  isBlocked={!item?.isActive}
                                  onClick={() => {
                                    dispatch(
                                      setUserDetailDrawer({
                                        visibility: true,
                                        id: item.id,
                                      })
                                    );
                                  }}
                                  size={10}
                                />
                              </li>
                            ))}
                          {data?.visibilities?.length > 2 && (
                            <li
                              className="view-all"
                              onClick={memberAccessmodalPreviewHandler}
                            >
                              +{data?.visibilities?.length - 2} View All
                            </li>
                          )}
                        </ul>
                        {(data.isAdmin || data.isOwner || data.isRoomOwner) &&
                          isEditAllowed &&
                          !isTransactionComplete && (
                            <span
                              className="add-group-btn"
                              onClick={folderAccesModalPreviewHandler}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </span>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="room-details-header-btn-wrapper">
                    <DisabledTooltip
                      innerSec={
                        <ButtonComponent
                          text="Create Folder +"
                          className="btn-secondary"
                          onClick={() =>
                            isEditAllowed && setCreateFolderModalPreview(true)
                          }
                          disabled={isTransactionComplete}
                        />
                      }
                      visible={!isEditAllowed}
                    />
                    <DisabledTooltip
                      innerSec={
                        <ButtonComponent
                          text="Upload File +"
                          onClick={
                            isEditAllowed && uploadFileModalPreviewHandler
                          }
                          disabled={isTransactionComplete}
                        />
                      }
                      visible={!isEditAllowed}
                    />
                  </div>
                </div>
                <div className="links-room-wrapper folder-content-wrapper">
                  <div className="data-room-content-wrapper">
                    <DataRoomFolders
                      onFolderMove={dataUpdatedHandler}
                      minheight="140px"
                      maxheight="380px"
                      slug={slug}
                      roomslug={roomslug}
                      isDataUpdated={isDataUpdated}
                      // isRoomOwner={data.isOwner || data?.isAdmin}
                      isRoomOwner={
                        (isFolder
                          ? data.isOwner || data?.isRoomOwner
                          : data?.isOwner) || data?.isAdmin
                      }
                      searchText={searchText}
                      isFolder={isFolder}
                      isTransactionComplete={isTransactionComplete}
                      isEditAllowed={isEditAllowed}
                    />
                    <DataRoomDocuments
                      onFileMove={dataUpdatedHandler}
                      minheight="140px"
                      maxheight="380px"
                      slug={slug}
                      roomslug={roomslug}
                      isDataUpdated={isDataUpdated}
                      isRoomOwner={
                        (isFolder
                          ? data.isOwner || data?.isRoomOwner
                          : data?.isOwner) || data?.isAdmin
                      }
                      searchText={searchText}
                      isFolder={isFolder}
                      isTransactionComplete={isTransactionComplete}
                      isEditAllowed={isEditAllowed}
                    />
                  </div>
                  <DataRoomNotesBox
                    height={550}
                    maxHeight={510}
                    slug={slug}
                    isDataRoom={true}
                    isFolder={isFolder}
                    dataroomid={data?.id}
                    isOwner={data.isOwner || data.isAdmin || data?.isRoomOwner}
                    isTransactionComplete={isTransactionComplete}
                    isEditAllowed={true}
                  />
                </div>
              </div>
            </Col>
            <Col xs={24} xl={6}>
              <div className="dataroom-activity-logs">
                <ActivityLogs
                  title="Data room"
                  height={300}
                  isWholePage={true}
                  customqueryParams={
                    isFolder ? { folder: isFolder } : { dataroom: roomslug }
                  }
                  isAccess={activityLogsVisible}
                />
              </div>
            </Col>
          </>
        )}
        <CreateDataroomModal
          title={"Folder"}
          handleSuccess={dataUpdatedHandler}
          handleClose={createFolderModalPreviewHandler}
          preview={createFolderModalPreview}
          transactionslug={transactionslug}
          placeholder="Folder Name"
          isFolder
          roomId={data.id}
          isParentFolder={isFolder}
        />
        <EditFolderAccessModal
          members={data?.visibilities}
          preview={folderAccessModalPreview}
          handleClose={folderAccesModalPreviewHandler}
          isFolder={isFolder}
          isOwner={data.isAdmin}
        />
        <MemberAccessModal
          members={data?.visibilities}
          preview={memberAccessmodalPreview}
          handleClose={memberAccessmodalPreviewHandler}
          isOwner={data.isAdmin}
          isEditable={data.isOwner || data.isAdmin}
        />
        <UploadDocumentModal
          handleSuccess={dataUpdatedHandler}
          preview={uploadFileModalPreview}
          handleClose={uploadFileModalPreviewHandler}
          isFolder={isFolder}
          roomid={data.id}
        />
      </Row>
    </>
  );
}
